import React, {FC, useMemo} from 'react';
import {GDIconProps} from '../util/types';
import SVG from 'react-inlinesvg';
import {getStaticAssetUrl} from '@/shared/util/get-static-asset-url';

export interface LogoIconProps extends GDIconProps {
  background: 'light' | 'dark';
}

const LogoIcon: FC<LogoIconProps> = ({background, ...props}) => {
  const src = useMemo(() => getStaticAssetUrl('logo', `${background}-bg-logo.svg`), [background]);
  return <SVG src={src} {...props} />;
};

export default LogoIcon;
