import {useInfiniteQuery, UseInfiniteQueryOptions} from 'react-query';
import {
  GrowthGroupFilters,
  GrowthGroupInstancePaginatedResponse,
  GrowthGroupQueryKey,
} from '../../../types/growth-groups';
import {axiosGrowthDay} from '../../../utils/api';
import {makeFilterSearchParams} from '../util/makeFilterSearchParams';

export const UpNextQueryKey = [GrowthGroupQueryKey, 'Up-Next'] as const;
export const UpNextQueryKeyWithFilters = (filters?: GrowthGroupFilters) => [...UpNextQueryKey, filters ?? {}] as const;
const defaultQueryKey = UpNextQueryKeyWithFilters({});

export function useGetNextUpGroups(
  options: Omit<
    UseInfiniteQueryOptions<
      GrowthGroupInstancePaginatedResponse,
      unknown,
      GrowthGroupInstancePaginatedResponse,
      GrowthGroupInstancePaginatedResponse,
      typeof defaultQueryKey
    >,
    'queryFn' | 'queryKey'
  > & {filters?: GrowthGroupFilters} = {}
) {
  const {filters, ...rest} = options;
  const queryKeyWithFilters = UpNextQueryKeyWithFilters(filters);

  return useInfiniteQuery(
    queryKeyWithFilters,
    async ({pageParam = 1}) => {
      const result = await axiosGrowthDay('/groups/instances', {
        params: {
          limit: 24,
          offset: pageParam,
          isParticipant: true,
          sortBy: 'startTime',
          order: 'asc',
          ...makeFilterSearchParams(filters),
        },
      });

      const data = result.data as Omit<GrowthGroupInstancePaginatedResponse, '__currentCountForPagination'>;
      // Data might be filtered to remove cancelled values, use current count for pagination
      return {
        ...data,
        __currentCountForPagination: data.growthGroupInstanceList?.length ?? 0,
      } as GrowthGroupInstancePaginatedResponse;
    },
    {
      getNextPageParam(lastPage, pages) {
        const loaded = pages.reduce((prev, current) => prev + current.__currentCountForPagination, 0);
        const total = lastPage.count ?? 0;

        return loaded < total ? pages.length + 1 : undefined;
      },
      keepPreviousData: true,
      staleTime: 30 * 1000,
      ...rest,
    }
  );
}
