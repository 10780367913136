/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface GiftSubscription
 */
export interface GiftSubscription {
  /**
   *
   * @type {string}
   * @memberof GiftSubscription
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof GiftSubscription
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof GiftSubscription
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof GiftSubscription
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof GiftSubscription
   */
  uuid?: string;
  /**
   *
   * @type {string}
   * @memberof GiftSubscription
   */
  buyerFullName?: string;
  /**
   *
   * @type {string}
   * @memberof GiftSubscription
   */
  buyerEmail?: string;
  /**
   *
   * @type {string}
   * @memberof GiftSubscription
   */
  buyerIanaTimezone?: string;
  /**
   *
   * @type {string}
   * @memberof GiftSubscription
   */
  buyerPhoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof GiftSubscription
   */
  buyerRegion?: string;
  /**
   *
   * @type {string}
   * @memberof GiftSubscription
   */
  buyerCountry?: string;
  /**
   *
   * @type {string}
   * @memberof GiftSubscription
   */
  buyerZipCode?: string;
  /**
   *
   * @type {boolean}
   * @memberof GiftSubscription
   */
  buyerAutomaticTaxEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof GiftSubscription
   */
  consumerEmail?: string;
  /**
   *
   * @type {string}
   * @memberof GiftSubscription
   */
  consumerName?: string;
  /**
   *
   * @type {boolean}
   * @memberof GiftSubscription
   */
  expiryBuyer7DaysReminded?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GiftSubscription
   */
  expiryBuyer30DaysReminded?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GiftSubscription
   */
  expiryConsumer7DaysReminded?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GiftSubscription
   */
  expiryConsumer30DaysReminded?: boolean;
  /**
   *
   * @type {string}
   * @memberof GiftSubscription
   */
  stripeCustomerId?: string;
  /**
   *
   * @type {string}
   * @memberof GiftSubscription
   */
  paymentMethodId?: string;
  /**
   *
   * @type {string}
   * @memberof GiftSubscription
   */
  stripeSubscriptionId?: string;
  /**
   *
   * @type {number}
   * @memberof GiftSubscription
   */
  subscriptionStarts?: number;
  /**
   *
   * @type {number}
   * @memberof GiftSubscription
   */
  subscriptionExpiry?: number;
  /**
   *
   * @type {boolean}
   * @memberof GiftSubscription
   */
  subscriptionCancelled?: boolean;
  /**
   *
   * @type {string}
   * @memberof GiftSubscription
   */
  paymentSystem?: GiftSubscriptionPaymentSystemEnum;
  /**
   *
   * @type {string}
   * @memberof GiftSubscription
   */
  subscriptionLevel?: GiftSubscriptionSubscriptionLevelEnum;
  /**
   *
   * @type {string}
   * @memberof GiftSubscription
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof GiftSubscription
   */
  frequency?: GiftSubscriptionFrequencyEnum;
  /**
   *
   * @type {string}
   * @memberof GiftSubscription
   */
  stripePriceId?: string;
  /**
   *
   * @type {boolean}
   * @memberof GiftSubscription
   */
  override?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GiftSubscription
   */
  redeemed?: boolean;
  /**
   *
   * @type {string}
   * @memberof GiftSubscription
   */
  buyerFirstName?: string;
}

export const GiftSubscriptionPaymentSystemEnum = {
  Card: 'CARD',
  Paypal: 'PAYPAL',
  Apple: 'APPLE',
} as const;

export type GiftSubscriptionPaymentSystemEnum =
  (typeof GiftSubscriptionPaymentSystemEnum)[keyof typeof GiftSubscriptionPaymentSystemEnum];
export const GiftSubscriptionSubscriptionLevelEnum = {
  Basic: 'BASIC',
  Starter: 'STARTER',
  Growth: 'GROWTH',
  Mastery: 'MASTERY',
  Trial: 'TRIAL',
  None: 'NONE',
  Offer: 'OFFER',
  Challenge: 'CHALLENGE',
  Enterprise: 'ENTERPRISE',
  EnterpriseStarter: 'ENTERPRISE_STARTER',
  AllAccess: 'ALL_ACCESS',
} as const;

export type GiftSubscriptionSubscriptionLevelEnum =
  (typeof GiftSubscriptionSubscriptionLevelEnum)[keyof typeof GiftSubscriptionSubscriptionLevelEnum];
export const GiftSubscriptionFrequencyEnum = {
  Month: 'MONTH',
  Year: 'YEAR',
} as const;

export type GiftSubscriptionFrequencyEnum =
  (typeof GiftSubscriptionFrequencyEnum)[keyof typeof GiftSubscriptionFrequencyEnum];
