/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface Team
 */
export interface Team {
  /**
   *
   * @type {string}
   * @memberof Team
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof Team
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof Team
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof Team
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof Team
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof Team
   */
  totalSeats?: number;
  /**
   *
   * @type {string}
   * @memberof Team
   */
  planName?: string;
  /**
   *
   * @type {string}
   * @memberof Team
   */
  planFrequency?: TeamPlanFrequencyEnum;
  /**
   *
   * @type {string}
   * @memberof Team
   */
  planLevel?: TeamPlanLevelEnum;
  /**
   *
   * @type {string}
   * @memberof Team
   */
  subscriptionId?: string;
  /**
   *
   * @type {number}
   * @memberof Team
   */
  subscriptionAmountInCents?: number;
  /**
   *
   * @type {number}
   * @memberof Team
   */
  subscriptionStartDate?: number;
  /**
   *
   * @type {number}
   * @memberof Team
   */
  subscriptionEndDate?: number;
  /**
   *
   * @type {string}
   * @memberof Team
   */
  paymentSystem?: TeamPaymentSystemEnum;
  /**
   *
   * @type {string}
   * @memberof Team
   */
  stripePriceId?: string;
  /**
   *
   * @type {boolean}
   * @memberof Team
   */
  subscriptionCancelled?: boolean;
  /**
   *
   * @type {number}
   * @memberof Team
   */
  subscriptionCancelledDate?: number;
  /**
   *
   * @type {boolean}
   * @memberof Team
   */
  processingInvitation?: boolean;
}

export const TeamPlanFrequencyEnum = {
  Month: 'MONTH',
  Year: 'YEAR',
} as const;

export type TeamPlanFrequencyEnum = (typeof TeamPlanFrequencyEnum)[keyof typeof TeamPlanFrequencyEnum];
export const TeamPlanLevelEnum = {
  Basic: 'BASIC',
  Starter: 'STARTER',
  Growth: 'GROWTH',
  Mastery: 'MASTERY',
  Trial: 'TRIAL',
  None: 'NONE',
  Offer: 'OFFER',
  Challenge: 'CHALLENGE',
  Enterprise: 'ENTERPRISE',
  EnterpriseStarter: 'ENTERPRISE_STARTER',
  AllAccess: 'ALL_ACCESS',
} as const;

export type TeamPlanLevelEnum = (typeof TeamPlanLevelEnum)[keyof typeof TeamPlanLevelEnum];
export const TeamPaymentSystemEnum = {
  Card: 'CARD',
  Paypal: 'PAYPAL',
  Apple: 'APPLE',
} as const;

export type TeamPaymentSystemEnum = (typeof TeamPaymentSystemEnum)[keyof typeof TeamPaymentSystemEnum];
