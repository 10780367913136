import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ILearnLoadingState, ILearnState, ILessonDuration} from './interfaces';
import {stateWithLoading} from '../../shared/redux/util/state-with-loading';
import {resetState} from '../../shared/redux/actions';
import _unset from 'lodash/unset';
import {IStrapiCourse} from '../../shared/services_deprecated/model/strapi/course';
import {getApiActionBuilder} from '../../shared/redux/util/build-api-action';
import {IUserCourse} from '../../shared/services_deprecated/model/user-course';
import {apiActions} from './api.actions';
import {IStrapiCourseLesson} from '../../shared/services_deprecated/model/strapi/course-lesson';
import {IUserCourseNote, IUserCourseProgress} from '../../shared/services_deprecated/model/data-collection';
import {DashboardTypeEnum} from './routes/Dashboard/enums';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';

const persistConfig = {
  key: 'hpx:learn',
  storage: storage,
  whitelist: ['currentTab'],
};

const initialState = stateWithLoading<ILearnState, ILearnLoadingState>({
  cachedLessonsProgress: {},
  lessonDurations: {},
  currentTab: DashboardTypeEnum.HOME,
});

export const learnSlice = createSlice<
  ILearnState,
  {
    updateCurrentTab: CaseReducer<ILearnState, PayloadAction<DashboardTypeEnum>>;
    playLesson: CaseReducer<ILearnState, PayloadAction<number | undefined>>;
    updateCachedLessonsProgress: CaseReducer<ILearnState, PayloadAction<{lessonId: number; progress: number}>>;
    updateLessonDuration: CaseReducer<ILearnState, PayloadAction<ILessonDuration>>;
    unsetLearnState: CaseReducer<ILearnState, PayloadAction<string | string[]>>;
  }
>({
  name: 'learn',
  initialState,
  reducers: {
    updateCurrentTab(state, action) {
      state.currentTab = action.payload;
    },
    playLesson(state, action) {
      state.playingLessonId = action.payload;
    },
    updateCachedLessonsProgress(state, action) {
      const {lessonId, progress} = action.payload;
      state.cachedLessonsProgress[lessonId] = progress;
    },
    updateLessonDuration(state, action) {
      const {lessonId, duration} = action.payload;
      state.lessonDurations[lessonId] = duration;
    },
    unsetLearnState(state, action) {
      const keys = Array.isArray(action.payload) ? action.payload : [action.payload];
      keys.forEach((key) => {
        _unset(state, key);
      });
    },
  },
  extraReducers: (builder) => {
    const buildApiAction = getApiActionBuilder<ILearnState>(builder, apiActions);

    buildApiAction<PayloadAction<IStrapiCourse>>('getCourse', {
      fulfilled: (state, action) => {
        const userCourse = state.course?.userCourse;
        state.course = action.payload;
        if (userCourse && state.course && userCourse.courseId === state.course.id) {
          state.course.userCourse = userCourse;
        }
      },
    });

    buildApiAction<PayloadAction<IUserCourse>>('getUserCourse', {
      fulfilled: (state, action) => {
        if (state.course?.id === action.payload.courseId) {
          state.course.userCourse = action.payload;
        }
      },
    });

    buildApiAction<PayloadAction<IStrapiCourseLesson>>('getLesson', {
      fulfilled: (state, action) => {
        state.lesson = action.payload;
      },
    });

    buildApiAction<
      PayloadAction<
        IUserCourse,
        string,
        {arg: IUserCourseNote & {lessonId: string | number; courseId: string | number}}
      >
    >('saveLessonNote', {
      pending: (state, action) => {
        if (state.course?.userCourse) {
          const note = action.meta.arg;
          state.course.userCourse.notes = note.noteId
            ? state.course.userCourse.notes?.map((existingNote) =>
                existingNote.noteId === note.noteId ? note : existingNote
              )
            : [...(state.course.userCourse.notes ?? []), note];
        }
      },
      fulfilled: (state, action) => {
        if (state.course?.userCourse) {
          state.course.userCourse.notes = action.payload.notes;
        }
      },
    });

    buildApiAction<
      PayloadAction<
        void,
        string,
        {
          arg: {
            courseId: string | number;
            lessonId: string | number;
            noteId: string;
          };
        }
      >
    >('deleteLessonNote', {
      pending: (state, action) => {
        if (state.course?.userCourse) {
          state.course.userCourse.notes =
            state.course.userCourse.notes?.filter((note) => note.noteId !== action.meta.arg.noteId) ?? [];
        }
      },
    });

    buildApiAction<PayloadAction<IUserCourseProgress>>('markLessonAsCompleted', {
      fulfilled: (state, action) => {
        if (state?.course?.userCourse?.lessonProgress) {
          const findLessonInProgress = state.course?.userCourse?.lessonProgress?.findIndex?.(
            (lp) => lp.lessonId === Number(action.payload.lessonId)
          );
          if (findLessonInProgress < 0) {
            state.course.userCourse.lessonProgress.push(action.payload);
          } else {
            state.course.userCourse.lessonProgress[findLessonInProgress] = action.payload;
          }
        }
      },
    });

    buildApiAction<PayloadAction<IUserCourse>>('markCourseAsCompleted', {
      fulfilled: (state, action) => {
        if (state?.course) {
          state.course.userCourse = action.payload;
        }
      },
    });

    builder.addCase(resetState, () => {
      return {
        ...initialState,
      };
    });
  },
});

export const {playLesson, updateCachedLessonsProgress, updateLessonDuration, unsetLearnState, updateCurrentTab} =
  learnSlice.actions;

export default persistReducer(persistConfig, learnSlice.reducer);
