/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {SignupRequestPassword} from './signup-request-password';

/**
 *
 * @export
 * @interface UserSignupProfileRequest
 */
export interface UserSignupProfileRequest {
  /**
   *
   * @type {string}
   * @memberof UserSignupProfileRequest
   */
  token?: string;
  /**
   *
   * @type {string}
   * @memberof UserSignupProfileRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserSignupProfileRequest
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof UserSignupProfileRequest
   */
  playerId?: string;
  /**
   *
   * @type {string}
   * @memberof UserSignupProfileRequest
   */
  phoneNumber?: string;
  /**
   *
   * @type {SignupRequestPassword}
   * @memberof UserSignupProfileRequest
   */
  password?: SignupRequestPassword;
  /**
   *
   * @type {boolean}
   * @memberof UserSignupProfileRequest
   */
  isTeam?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserSignupProfileRequest
   */
  subscriptionPlatform?: UserSignupProfileRequestSubscriptionPlatformEnum;
}

export const UserSignupProfileRequestSubscriptionPlatformEnum = {
  Web: 'WEB',
  Ios: 'IOS',
  Android: 'ANDROID',
} as const;

export type UserSignupProfileRequestSubscriptionPlatformEnum =
  (typeof UserSignupProfileRequestSubscriptionPlatformEnum)[keyof typeof UserSignupProfileRequestSubscriptionPlatformEnum];
