import {Button, Divider} from 'antd';
import React, {FC, Fragment, useState} from 'react';
import classNames from 'classnames';
import MarkdownHtml from '../../MarkdownHtml';
import CheckMarkIcon from './assets/CheckMarkIcon';
import styles from './CheckboxListCard.module.less';
import GDTypography from '../Typography/Typography';
import commonStyles from '../../../style/commonStyles.module.less';
import PlusIcon from './assets/PlusIcon';
import {getAnalyticsActions} from '@/features/analytics/hooks/useAnalyticsActions';

export type CheckboxListProps = {
  items: string[];
  itemsClassName?: string;
  firstIconPlus?: boolean;
  hideFirstDivider?: boolean;
  maxItems?: number;
  showLastDivider?: boolean;
  planSlug?: string;
  hideDivider?: boolean;
};

const CheckboxList: FC<CheckboxListProps> = ({
  items,
  itemsClassName,
  firstIconPlus,
  hideFirstDivider,
  maxItems,
  showLastDivider,
  planSlug,
  hideDivider,
}) => {
  const [restrictPlanInformation, setRestrictPlanInformation] = useState<boolean>(true);

  const handleChangeRestrictPlanInformation = () => {
    if (restrictPlanInformation) {
      getAnalyticsActions().track('Scroll', {
        element_id: 'upsell-description',
        type: 'TEXT',
        parent_id: planSlug ? `${planSlug}-upsell` : 'Upsell',
      });
    }
    setRestrictPlanInformation(!restrictPlanInformation);
  };

  return (
    <div className={classNames(styles.items, itemsClassName)}>
      {items.map((text, index) =>
        maxItems && index >= maxItems && restrictPlanInformation ? (
          showLastDivider && !hideDivider && index === items.length - 1 ? (
            <Divider key={index} className={styles.divider} />
          ) : null
        ) : (
          <Fragment key={index}>
            {(hideFirstDivider && !hideDivider ? index !== 0 : !hideDivider) && <Divider className={styles.divider} />}
            <div className={styles.item}>
              <span className={styles.icon}>{firstIconPlus && index === 0 ? <PlusIcon /> : <CheckMarkIcon />}</span>
              <GDTypography block>
                <MarkdownHtml className={commonStyles.textNormal}>{text ?? ''}</MarkdownHtml>
              </GDTypography>
            </div>
            {showLastDivider && !hideDivider && index === items.length - 1 && <Divider className={styles.divider} />}
          </Fragment>
        )
      )}
      {maxItems && maxItems < items.length && (
        <Button
          type="link"
          className={styles.showMoreButton}
          onClick={handleChangeRestrictPlanInformation}
          block
          size="small"
        >
          {restrictPlanInformation ? 'View More' : 'View Less'}
        </Button>
      )}
    </div>
  );
};

export default CheckboxList;
