import {plansApiMiddlewares, plansApiReducer} from '../features/plans';
import {achievementsMiddleware, achievementsReducer} from '../features/achievements';
import {coinsMiddleware, coinsReducer} from '../features/coins';
import {remindersMiddleware, remindersReducer} from '../features/reminders';
import {growthGroupReducer} from '../features/growthgroups/redux/growthgroup.slice';

export const reducer = {
  ...plansApiReducer,
  ...achievementsReducer,
  ...coinsReducer,
  ...remindersReducer,
  ...growthGroupReducer,
};

export const middlewares = [
  ...plansApiMiddlewares,
  ...achievementsMiddleware,
  ...coinsMiddleware,
  ...remindersMiddleware,
];
