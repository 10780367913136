import useSubscriptionPlans from '@/features/auth/routes/Profile/hooks/useSubscriptionPlans';
import {IOffersNonNested} from '@growthday/ui-core/src/types/offers';
import classNames from 'classnames';
import {keyBy, uniqBy} from 'lodash';
import urlJoin from 'proper-url-join';
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {EnumOffersType, EnumSocialEventsTrigger} from '@growthday/ui-core/src/types/strapi';
import GDButton from '../../../../../../shared/components/ui/Button';
import GDFormItem from '../../../../../../shared/components/ui/Form/Item';
import GDTypography from '../../../../../../shared/components/ui/Typography/Typography';
import {getAnalyticsActions} from '@/features/analytics/hooks/useAnalyticsActions';
import {RecaptchaTerms} from '../../../../components/RecaptchaTerms/RecaptchaTerms';
import {useRecaptcha} from '../../../../hooks/useRecaptcha';
import {IUser, UserSignupType} from '../../../../../../shared/services_deprecated/model/user';
import {UserRequest} from '../../../../../../shared/services_deprecated/model/userRequest';
import commonStyles from '../../../../../../shared/style/commonStyles.module.less';
import displayApiError from '../../../../../../shared/util/display-api-error';
import {Platform} from '../../../../../../shared/util/types';
import StripeCardForm from '../../../../../license/components/StripeCardForm/StripeCardForm';
import {checkLicenses, mapLicenses} from '../../../../../license/hooks/useLicense';
import useStripePayment, {StripeCardSubmitValue} from '../../../../../license/hooks/useStripePayment';
import {LicenseRoutesEnum} from '../../../../../license/interfaces';
import {LicenseEnum} from '@growthday/ui-core/src/features/license/types';
import OrderBump from '../../../../../offers/components/OrderBump';
import useOffers from '../../../../../offers/hooks/useOffers';
import authApiActions from '../../../../auth.actions';
import {toggleTriggerInfo} from '../../../../auth.slice';
import {PaymentSystemEnum} from '../../../../enums';
import useSocialEvents from '../../../../hooks/useSocialEvents';
import isEvergreenUser from '../../../../utils/isEvergreenUser';
import useMyProfile from '../../../Profile/hooks/useMyProfile';
import AmericanExpressIcon from '../../components/icons/AmericanExpressIcon';
import DiscoverIcon from '../../components/icons/DiscoverIcon';
import MasterCardIcon from '../../components/icons/MasterCardIcon';
import VisaIcon from '../../components/icons/VisaIcon';
import SelectedPlan from '../../components/SelectedPlan';
import Step from '../../components/Step';
import Terms from '../../components/Terms/Terms';
import styles from './CreditCard.module.less';
import {getTrialDaysFromSubscriptionPlan} from '../../utils';
import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';
import {useUpdateMeData} from '@growthday/ui-core/src/features/user/hooks/useUpdateMeData';

export type FormValue = StripeCardSubmitValue & {
  selectedOffers: IOffersNonNested[];
};

export const initialValues = {
  selectedOffers: [],
};

const CreditCard = () => {
  const {registrationPlan} = useSubscriptionPlans();
  const history = useHistory();
  const {user} = useUser();
  const signupOfferId = user?.signupOfferId
    ? user?.signupOfferId
    : user?.signupType === UserSignupType.OFFER
      ? (user?.signupId as number)
      : undefined;
  const [stripeLoading, setStripeLoading] = useState(false);
  const {updateMyProfile, refetchMyProfile} = useMyProfile();
  const dispatch = useDispatch();
  const meDataUpdater = useUpdateMeData();
  const {offers, allOffers} = useOffers({
    types: [
      signupOfferId ? EnumOffersType.ORDER_BUMP_SIGNUP_OFFER : EnumOffersType.ORDER_BUMP_SIGNUP_SUBSCRIPTION,
      EnumOffersType.ORDER_BUMP_SIGNUP,
    ],
    plan: registrationPlan ? {level: registrationPlan.level ?? '', interval: registrationPlan.interval} : null,
  });
  const {purchaseOffer} = useStripePayment();
  const {loading: captchaLoading, execute} = useRecaptcha();

  const sendSocialEvents = useSocialEvents();

  useEffect(() => {
    if (user?.signupOfferName) {
      setTimeout(() => {
        getAnalyticsActions().track('Offer View', {
          'Offer Name': user?.signupOfferName,
          'Offer Price': user?.signupOfferName,
        });
      }, 1000);
    }
    dispatch(
      toggleTriggerInfo({
        triggerFlow: user?.signupOfferName ? `${user?.signupOfferName} Onboarding` : 'Plan Onboarding',
        triggeredContentName: user?.signupOfferName || 'Plan',
        triggeredFromSection: 'SIGNUP',
      })
    );
  }, [dispatch, user?.signupOfferName]);

  useEffect(() => {
    if (user?.id) {
      updateMyProfile(
        {
          fullName: user.fullName,
        },
        true
      );
    }
  }, [user?.id, updateMyProfile, user?.fullName]);

  const signupOffer = signupOfferId ? allOffers.find((offer) => offer.id === signupOfferId) : undefined;

  useEffect(() => {
    const plan = registrationPlan;
    const offer = signupOffer;
    const events = signupOffer?.events || registrationPlan?.events;
    if (events?.length && (plan || offer)) {
      const loadEvents = events.filter((event) => event.trigger === EnumSocialEventsTrigger.PAYMENT_LOAD);
      sendSocialEvents(loadEvents, {
        offer,
        plan,
      });
    }
  }, [sendSocialEvents, signupOffer, registrationPlan]);

  const handleSubmit = async (value: FormValue) => {
    try {
      if (signupOffer && user) {
        const offersToBuy: IOffersNonNested[] = uniqBy(
          [signupOffer, ...(value?.selectedOffers ?? [])].filter(Boolean).map((offer) => offer!),
          'id'
        );
        await purchaseOffer(offersToBuy as IOffersNonNested[]);
        await refetchMyProfile();
      } else if (registrationPlan) {
        const userRequest: UserRequest & Omit<IUser, 'roles'> = {
          ...user,
          roles: ['user'],
          subscriptionPlatform: Platform.WEB,
          orderBumpIds: value.selectedOffers?.map((offer) => offer.id) ?? [],
          orderBumps: value.selectedOffers ?? [],
        };
        userRequest.paymentMethodId = value.paymentMethodId;
        const isNotTrial = user?.signupPlanId || user?.signupType === UserSignupType.PLAN;
        userRequest.stripePriceId = isNotTrial
          ? registrationPlan.stripePriceId
          : registrationPlan.stripeWithTrialPriceId;
        userRequest.paymentSystem = PaymentSystemEnum.STRIPE;
        const captchaToken = await execute({
          ...userRequest,
          context: 'Signup Plan Payment',
        });
        const response: any = await dispatch(
          authApiActions.registerPayment({
            ...userRequest,
            plan: registrationPlan,
            offers: value.selectedOffers,
            captchaToken,
          })
        );
        meDataUpdater(undefined, response);
        if (response.error) {
          return;
        }
        if (!isNotTrial) {
          if (checkLicenses(LicenseEnum.GROWTHGROUP_ALL_ACCESS, mapLicenses(registrationPlan.licenses))) {
            getAnalyticsActions().track('Growth Group Free Trial Started');
          }
        }
      }
      setTimeout(() => {
        const rootUrl = '/onboarding';
        let redirectUrl = `${rootUrl}`;
        const canShowUpsells = !(user?.campaignId || user?.isGiftBuyer || isEvergreenUser(user));
        if (signupOffer) {
          redirectUrl = `${rootUrl}?offerId=${signupOffer.id}`;
        } else if (registrationPlan) {
          redirectUrl = `${rootUrl}?planId=${registrationPlan.id}`;
        }
        history.replace(
          canShowUpsells ? urlJoin(`/license/${LicenseRoutesEnum.UPSELL}`, {query: {redirectUrl}}) : redirectUrl
        );
      });
    } catch (e: any) {}
  };

  const freeOffersMap = keyBy(registrationPlan?.freeOffers, 'id');
  const filteredOffers = offers.filter((offer) => offer.id !== signupOfferId && !freeOffersMap[offer.id]);
  const canShowTrial = useMemo(() => {
    if (!registrationPlan) return false;
    const trialDays = getTrialDaysFromSubscriptionPlan(registrationPlan);
    return !user?.signupPlanId && !user?.signupType && Boolean(trialDays);
  }, [registrationPlan, user]);

  return (
    <>
      <Step
        title="Enter your card details"
        terms={<RecaptchaTerms />}
        {...(user?.signupOfferId || user?.signupPlanId || user?.signupType
          ? {
              step: 2,
              totalSteps: 2,
            }
          : {
              step: 3,
            })}
      >
        <div className={styles.cards}>
          <VisaIcon />
          <MasterCardIcon />
          <AmericanExpressIcon />
          <DiscoverIcon />
        </div>
        <StripeCardForm<FormValue>
          onError={displayApiError}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onLoading={setStripeLoading}
          skipUpdatingPaymentMethod={!user?.signupOfferId && user?.signupType !== UserSignupType.OFFER}
        >
          <SelectedPlan showTrial={canShowTrial} offer={signupOffer} plan={registrationPlan} />
          {filteredOffers.length > 0 && (
            <GDFormItem name="selectedOffers">
              <OrderBump offers={filteredOffers} />
            </GDFormItem>
          )}
          <GDTypography center block type="w50" className={classNames(commonStyles.lightText2, commonStyles.mt12)}>
            State and local sales tax will be calculated on your final invoice.
          </GDTypography>
          <GDButton
            size="large"
            htmlType="submit"
            key="submit"
            type="primary"
            className={classNames('gd-submit-button', styles.submitBtn)}
            loading={stripeLoading || captchaLoading}
          >
            {user?.signupOfferId || user?.signupType === UserSignupType.OFFER ? 'Purchase now' : 'Start membership'}
          </GDButton>
        </StripeCardForm>
        <Terms type={user?.signupOfferId || user?.signupType === UserSignupType.OFFER ? 'offer' : 'membership'} />
      </Step>
    </>
  );
};

export default CreditCard;
