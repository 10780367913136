import config from '../config';
import {useEffect, useLayoutEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {getPagePathParams} from '../shared/util/campaignParams';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

// const isTrackingEnabled =
//   isEnvironment(EnvironmentsEnum.PROD, EnvironmentsEnum.UAT, EnvironmentsEnum.DEV) && config.env.gtmId;

// TODO: Freezing issues caused in Dev/Uat env. Enabling only in prod environment
let isTrackingEnabled = false;

export const gtmActions = {
  init(gtmId: string) {
    if (gtmId) {
      /* eslint-disable */
      (function (w, d, s, l, i) {
        // @ts-ignore
        w[l] = w[l] || [];
        // @ts-ignore
        w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        // @ts-ignore
        j.async = true;
        // @ts-ignore
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode?.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', gtmId);
      /* eslint-enable */
    }
  },
};

const useGoogleTagManager = () => {
  const location = useLocation();
  isTrackingEnabled = false;
  useEffect(() => {
    if (isTrackingEnabled && window.dataLayer) {
      const {page_path, page_location} = getPagePathParams();
      window.dataLayer?.push({
        event: 'page_view',
        page: {
          location: page_location,
          path: page_path,
        },
      });
    }
  }, [location.pathname]);
  useLayoutEffect(() => {
    if (isTrackingEnabled) {
      gtmActions.init(config.env.gtmId!);
    }
  }, []);
};

export default useGoogleTagManager;
