export enum StrapiCollectionEnum {
  PROMPT_CATEGORIES = 'prompts-categories',
  PROMPTS = 'prompts',
  DASHBOARD_DAILIES = 'dashboard-dailies',
  DASHBOARD_MONTHLIES = 'dashboard-monthlies',
  DASHBOARD_PROMPT = 'dashboard-prompts',
  DASHBOARD_PROMOTIONS = 'dashboard-promotions',
  BANNER_EVENTS = 'banner-events',
  COURSES = 'courses',
  COURSE_LESSONS = 'course-lessons',
  LIVE_EVENTS = 'events',
  SUBSCRIPTION_PLANS = 'subscription-plans',
  DAILY_JOURNAL_MESSAGES = 'daily-journal-messages',
  ASSESSMENT_CATEGORIES = 'assessment-categories',
  CHALLENGES = 'challenges',
  DAILY_GROWTH_PROMPTS = 'daily-growth-prompts',
  COURSE_BUCKETS = 'course-buckets',
  CONTENT_TAGS = 'content-tags',
  DAILY_FIRE = 'daily-fires',
  NO_MEMBERSHIP_MESSAGES = 'no-membership-messages',
  OFFERS = 'offers',
  MARKETING_CAMPAIGN = 'marketing-campaigns',
  SIGNUP_PAGE = 'signup-page',
  GIFT_SUBSCRIPTIONS = 'gift-subscriptions',
  GROWTH_GROUP_CONTENT = 'growth-group-content',
}
