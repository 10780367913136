import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DailyJournalLoadingState, DailyJournalState} from './interfaces';
import {stateWithLoading} from '../../shared/redux/util/state-with-loading';
import {getApiActionBuilder} from '../../shared/redux/util/build-api-action';
import * as apiActions from './daily-journal.actions';
import _unset from 'lodash/unset';
import coerceArray from '../../shared/util/coerceArray';
import {PromptCategory} from '../../shared/services_deprecated/model/strapi/promptCategory';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';
import {DailyJournalEntryData} from '../../shared/services_deprecated/model/generic/dailyJournal';
import {AchievementInfo} from '@growthday/ui-core/src/types/achievements';

const persistConfig = {
  key: 'hpx:journal',
  storage: storage,
  whitelist: ['entryFromWidget', 'promptFromWidget'],
};

const initialState = stateWithLoading<DailyJournalState, DailyJournalLoadingState>({
  promptCategories: [],
  maxCoins: undefined,
  todayCoins: undefined,
  entryFromWidget: undefined,
  promptFromWidget: undefined,
  achievement: undefined,
  showModalCoin: false,
  hasEarnedCoin: false,
});

export const dailyJournalSlice = createSlice<
  DailyJournalState,
  {
    unsetJournalState: CaseReducer<DailyJournalState, PayloadAction<string | string[]>>;
    updateEntry: CaseReducer<
      DailyJournalState,
      PayloadAction<{entry: DailyJournalEntryData; prompt: number | undefined} | undefined>
    >;
    updateAchievement: CaseReducer<DailyJournalState, PayloadAction<AchievementInfo | null | undefined>>;
    updateShowModalCoin: CaseReducer<DailyJournalState, PayloadAction<boolean>>;
    updateHasEarnedCoin: CaseReducer<DailyJournalState, PayloadAction<boolean>>;
  }
>({
  name: 'dailyJournal',
  initialState,
  reducers: {
    unsetJournalState(state, action) {
      const keys = coerceArray(action.payload);
      keys.forEach((key) => {
        _unset(state, key);
      });
    },
    updateEntry(state, action) {
      state.entryFromWidget = action.payload?.entry;
      state.promptFromWidget = action.payload?.prompt;
    },
    updateAchievement(state, action) {
      state.achievement = action.payload;
    },
    updateShowModalCoin(state, action) {
      state.showModalCoin = action.payload;
    },
    updateHasEarnedCoin(state, action) {
      state.hasEarnedCoin = action.payload;
    },
  },
  extraReducers: (builder) => {
    const buildApiAction = getApiActionBuilder<DailyJournalState>(builder, apiActions);

    buildApiAction<PayloadAction<PromptCategory[]>>('getPromptCategories', {
      fulfilled: (state, action) => {
        state.promptCategories = action.payload;
      },
    });
    buildApiAction<PayloadAction<any>>('getMaxCoinsPerDay', {
      fulfilled: (state, action) => {
        state.maxCoins = action.payload.DAILY_JOURNAL_CREATE;
      },
    });

    buildApiAction<PayloadAction<number>>('getTodayCoins', {
      fulfilled: (state, action) => {
        state.todayCoins = action.payload;
      },
    });
  },
});

export const {unsetJournalState, updateEntry, updateAchievement, updateShowModalCoin, updateHasEarnedCoin} =
  dailyJournalSlice.actions;

export default persistReducer(persistConfig, dailyJournalSlice.reducer);
