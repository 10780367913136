import {axiosGrowthDay} from '../index';
import {StatusResponse} from './interfaces';

export const gdprApi = {
  requestDownloadData: <T = StatusResponse>() => {
    return axiosGrowthDay.post<T>(`/gdpr/data/collect?status=running`).then((res) => res.data);
  },
  checkDownloadData: <T = StatusResponse>() => {
    return axiosGrowthDay.get<T>(`/gdpr/data/collect/status`).then((res) => res.data);
  },
  checkEraseStatusData: <T = StatusResponse>() => {
    return axiosGrowthDay.get<T>(`/gdpr/data/erase/status`).then((res) => res.data);
  },
  requestEraseAllData: <T = StatusResponse>() => {
    return axiosGrowthDay.get<T>(`/gdpr/data/erase/request`).then((res) => res.data);
  },
  eraseAllData: <T = StatusResponse>(token: string) => {
    return axiosGrowthDay.delete<T>(`/gdpr/data/erase/${encodeURIComponent(String(token))}`).then((res) => res.data);
  },
};
