import {createAsyncThunk} from '@reduxjs/toolkit';
import {plansApi} from '../../../../../../shared/api/plans/plans.api';
import displayApiError from '../../../../../../shared/util/display-api-error';
import {IProfileGoal, TGoalMilestoneDtos} from './interfaces';
import {myProfileApi} from './myProfileApi/myProfile.api';
import {uniq} from 'lodash';

export const getProfileData = createAsyncThunk<any>('myProfile/GetProfileData', async () => {
  try {
    const responce = await myProfileApi.getProfileData();
    return responce;
  } catch (err: any) {
    displayApiError(err, 'Failed request');
    throw err;
  }
});

export const updateProfile = createAsyncThunk<any, {bigWhyText: string; focusAreas: string[]}>(
  'myProfile/UpdateProfile',
  async (data) => {
    try {
      const responce = await myProfileApi.updateProfile(data);
      return responce;
    } catch (err: any) {
      displayApiError(err, 'Failed request');
      throw err;
    }
  }
);

export const getProfileGoals = createAsyncThunk<any>('myProfile/getProfileGoals', async () => {
  try {
    const responce = await myProfileApi.getProfileGoals();
    return responce;
  } catch (err: any) {
    displayApiError(err, 'Failed request');
    throw err;
  }
});

export const createNewGoal = createAsyncThunk<any, IProfileGoal>('myProfile/CreateNewGoal', async (data) => {
  try {
    return await myProfileApi.createSomeGoal(data);
  } catch (err: any) {
    displayApiError(err, 'Failed request');
    throw err;
  }
});

export const createNewMilestone = createAsyncThunk(
  'myProfile/CreateNewMilestone',
  async (data: {goalId: string; value: IProfileGoal}) => {
    try {
      return await myProfileApi.createSomeMilestone(data);
    } catch (err: any) {
      displayApiError(err, 'Failed request');
      throw err;
    }
  }
);

export const updateSomeGoal = createAsyncThunk<any, {goalId: string; value: IProfileGoal}>(
  'myProfile/UpdateSomeGoal',
  async (data) => {
    try {
      const modifiedObject = {...data.value};
      const planIds = uniq([...(modifiedObject?.planIds ?? []), ...(modifiedObject?.plans?.map((p) => p.id!) ?? [])]);
      const response = await myProfileApi.updateSomeGoal({
        ...data,
        value: {
          ...data.value,
          goalMilestoneDtos: data.value.goalMilestoneDtos ?? [],
          planIds,
          plans: [],
        },
      });
      response.response.goalMilestoneDtos = data.value.goalMilestoneDtos ?? [];
      return response;
    } catch (err: any) {
      displayApiError(err, 'Failed request');
      throw err;
    }
  }
);

export const updateSomeMilestone = createAsyncThunk<any, {goalId: string; milestoneId: string; value: IProfileGoal}>(
  'myProfile/UpdateSomeMilestone',
  async (data) => {
    try {
      const responce = await myProfileApi.updateSomeMilestone(data);
      return responce;
    } catch (err: any) {
      displayApiError(err, 'Failed request');
      throw err;
    }
  }
);

export const updateMilestoneList = createAsyncThunk<any, {goalId: string; value: TGoalMilestoneDtos[]}>(
  'myProfile/UpdateMilestoneList',
  async (data) => {
    try {
      const response = await myProfileApi.updateMilestoneList(data);
      return response;
    } catch (err: any) {
      displayApiError(err, 'Failed request');
      throw err;
    }
  }
);

export const markGoalAsComplete = createAsyncThunk<any, {goalId: string}>(
  'myProfile/MarkGoalAsComplete',
  async (data) => {
    try {
      const responce = await myProfileApi.markGoalAsCompleted(data);
      return responce;
    } catch (err: any) {
      displayApiError(err, 'Failed request');
      throw err;
    }
  }
);

export const markGoalAsIncomplete = createAsyncThunk<any, {goalId: string}>(
  'myProfile/MarkGoalAsIncomplete',
  async (data) => {
    try {
      const responce = await myProfileApi.markGoalAsIncompleted(data);
      return responce;
    } catch (err: any) {
      displayApiError(err, 'Failed request');
      throw err;
    }
  }
);

export const markMilestoneAsComplete = createAsyncThunk<any, {goalId: string; milestoneId: string}>(
  'myProfile/MarkMilestoneAsComplete',
  async (data) => {
    try {
      const responce = await myProfileApi.markMilestoneAsCompleted(data);
      return responce;
    } catch (err: any) {
      displayApiError(err, 'Failed request');
      throw err;
    }
  }
);

export const markMilestoneAsIncomplete = createAsyncThunk<any, {goalId: string; milestoneId: string}>(
  'myProfile/markMilestoneAsIncomplete',
  async (data) => {
    try {
      const responce = await myProfileApi.markMilestoneAsIncompleted(data);

      return responce;
    } catch (err: any) {
      displayApiError(err, 'Failed request');
      throw err;
    }
  }
);

export const deleteSomeGoal = createAsyncThunk<any, string>('myProfile/DeleteSomeGoal', async (goalId) => {
  try {
    const responce = await myProfileApi.deleteSomeGoal(goalId);
    return responce;
  } catch (err: any) {
    displayApiError(err, 'Failed request');
    throw err;
  }
});

export const deleteSomeMilestone = createAsyncThunk<any, {goalId: string; milestoneId: string}>(
  'myProfile/DeleteSomeMilestone',
  async (data) => {
    try {
      const responce = await myProfileApi.deleteSomeMilestone(data);
      return responce;
    } catch (err: any) {
      displayApiError(err, 'Failed request');
      throw err;
    }
  }
);

export const getAllPlans = createAsyncThunk('auth/GetAllPlans', async () => {
  try {
    const responce = await plansApi.retrievePlan();
    return responce;
  } catch (err: any) {
    displayApiError(err, 'Failed request');
    throw err;
  }
});
