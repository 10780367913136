import {ICommonShare} from './common-share';

/**
 * Model definition for shareWithType
 */
export interface IShareWithType {
  id: number;
  content?: ICommonShare;
  type?: EnumShareWithTypeType;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumShareWithTypeType {
  ACHIEVEMENT_JOURNAL = 'ACHIEVEMENT_JOURNAL',
  ACHIEVEMENT_LIFESCORE = 'ACHIEVEMENT_LIFESCORE',
  JOURNAL_ENTRY = 'JOURNAL_ENTRY',
  JOURNAL_PAGE = 'JOURNAL_PAGE',
  CERTIFICATE = 'CERTIFICATE',
  LIFESCORE_PAGE = 'LIFESCORE_PAGE',
  LIFESCORE_RESULT = 'LIFESCORE_RESULT',
  CHALLENGE_COMPLETE = 'CHALLENGE_COMPLETE',
  CHALLENGE_JOIN = 'CHALLENGE_JOIN',
  DAILY_FIRE = 'DAILY_FIRE',
  COURSE = 'COURSE',
}
