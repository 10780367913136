/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface GrowthGroupUpcomingPackage
 */
export interface GrowthGroupUpcomingPackage {
  /**
   *
   * @type {string}
   * @memberof GrowthGroupUpcomingPackage
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupUpcomingPackage
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupUpcomingPackage
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupUpcomingPackage
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupUpcomingPackage
   */
  uuid?: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupUpcomingPackage
   */
  licenseType?: GrowthGroupUpcomingPackageLicenseTypeEnum;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupUpcomingPackage
   */
  licenseId?: string;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupUpcomingPackage
   */
  allowedLimit?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupUpcomingPackage
   */
  participantsAllowed?: number;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupUpcomingPackage
   */
  cancelled?: boolean;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupUpcomingPackage
   */
  priceId?: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupUpcomingPackage
   */
  subscriptionId?: string;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupUpcomingPackage
   */
  packagePrice?: number;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupUpcomingPackage
   */
  userTimezone?: string;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupUpcomingPackage
   */
  packageStartTime?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupUpcomingPackage
   */
  packageEndTime?: number;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupUpcomingPackage
   */
  paymentSystem?: GrowthGroupUpcomingPackagePaymentSystemEnum;
}

export const GrowthGroupUpcomingPackageLicenseTypeEnum = {
  ProSubscriptionPlan: 'PRO_SUBSCRIPTION_PLAN',
  ProSubscriptionPlanRecurring: 'PRO_SUBSCRIPTION_PLAN_RECURRING',
  ProTrialSubscriptionPlan: 'PRO_TRIAL_SUBSCRIPTION_PLAN',
  GgPackage: 'GG_PACKAGE',
  AllAccessSubscription: 'ALL_ACCESS_SUBSCRIPTION',
  AllAccessTrial: 'ALL_ACCESS_TRIAL',
  StarterSubscription: 'STARTER_SUBSCRIPTION',
  StarterTrialSubscription: 'STARTER_TRIAL_SUBSCRIPTION',
} as const;

export type GrowthGroupUpcomingPackageLicenseTypeEnum =
  (typeof GrowthGroupUpcomingPackageLicenseTypeEnum)[keyof typeof GrowthGroupUpcomingPackageLicenseTypeEnum];
export const GrowthGroupUpcomingPackagePaymentSystemEnum = {
  Card: 'CARD',
  Paypal: 'PAYPAL',
  Apple: 'APPLE',
} as const;

export type GrowthGroupUpcomingPackagePaymentSystemEnum =
  (typeof GrowthGroupUpcomingPackagePaymentSystemEnum)[keyof typeof GrowthGroupUpcomingPackagePaymentSystemEnum];
