import {FC} from 'react';

const CheckMarkIcon: FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 0C3.58881 0 0 3.58881 0 8C0 12.4112 3.58881 16 8 16C12.4112 16 16 12.4112 16 8C15.9997 3.58881 12.4112 0 8 0Z"
        fill="#40C85E"
      />
      <path
        d="M10.55 5.44897L6.81589 9.18342L5.44948 7.817C5.19785 7.56509 4.78968 7.56509 4.53806 7.817C4.28614 8.06863 4.28614 8.4768 4.53806 8.72842L6.36032 10.5507C6.48614 10.6765 6.6513 10.7397 6.81618 10.7397C6.98106 10.7397 7.14593 10.6768 7.27175 10.5507L11.4618 6.36068C11.7134 6.10877 11.7134 5.70088 11.4618 5.44897C11.2098 5.19735 10.802 5.19735 10.55 5.44897Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckMarkIcon;
