import {useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {EnumOffersType} from '@growthday/ui-core/src/types/strapi';
import useDeepCompare from '../../../shared/hooks/useDeepCompare';
import useAuthState from '../../auth/hooks/useAuthState';
import useUserState, {UserStateEnum} from '../../auth/hooks/useUserState';
import offersApiActions from '../offers.actions';
import {filterOffersByIds, filterOffersByType} from '../utils/filterOffers';
import useOffersState from './useOffersState';
import usePurchasedOffers from './usePurchasedOffers';
import {IOffersNonNested} from '@growthday/ui-core/src/types/offers';

export type UseOffersFilter = {
  ids?: number | number[];
  types?: EnumOffersType | EnumOffersType[];
  plan?: {level: string; interval: string} | null;
};

const useOffers = (_filters?: UseOffersFilter) => {
  const userState = useUserState();
  const {accessToken} = useAuthState();
  const dispatch = useDispatch();
  const filters = useDeepCompare(_filters);
  const {canPurchaseOffer} = usePurchasedOffers();

  const {
    offers,
    loading: {getOffers: loading},
  } = useOffersState();

  const refetchOffers = useCallback(() => {
    if (!accessToken || loading || userState === UserStateEnum.UNAUTHENTICATED) return;
    dispatch(offersApiActions.getOffers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userState, accessToken]);

  const eligibleOffers: IOffersNonNested[] = useMemo(
    () => filterOffersByIds(filterOffersByType(offers ?? [], filters?.types), filters?.ids).filter(canPurchaseOffer),
    [canPurchaseOffer, offers, filters]
  );

  return useMemo(
    () => ({refetchOffers, offers: eligibleOffers, loading, allOffers: offers as IOffersNonNested[]}),
    [refetchOffers, eligibleOffers, loading, offers]
  );
};

export default useOffers;
