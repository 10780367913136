import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IGiftSubscriptionState} from './interface';
import {CaseReducer} from '@reduxjs/toolkit/src/createReducer';
import storage from 'redux-persist/lib/storage/session';
import {persistReducer} from 'redux-persist';

const persistConfig = {
  key: 'hpx:gift',
  storage: storage,
  whitelist: ['data'],
};

export const initialState = {
  data: undefined,
};

type Reducers = {
  updateFormValues: CaseReducer<IGiftSubscriptionState, PayloadAction<IGiftSubscriptionState['data']>>;
};

export const giftSubscriptionsSlice = createSlice<IGiftSubscriptionState, Reducers>({
  name: 'giftSubscriptions',
  initialState,
  reducers: {
    updateFormValues(state, action) {
      state.data = action.payload;
    },
  },
});

export default persistReducer(persistConfig, giftSubscriptionsSlice.reducer);
