import {Rule} from 'rc-field-form/lib/interface';
import {isValidPhoneNumber} from 'react-phone-number-input';

const fullNameRequired: Rule = {
  required: true,
  message: 'Please enter your first and last name.',
  type: 'string',
  validator(rule, value) {
    if (value && value.trim().length > 0) {
      return Promise.resolve();
    }
    return Promise.reject('Please enter your first and last name.');
  },
};

const fullNameRegex =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð ,.'-]+$/u;
export const fullNameMaxLength = 256;
const fullNameValid: Rule = {
  validator(rule, value) {
    if (value && !fullNameRegex.test(value)) {
      return Promise.reject('Please enter a valid name.');
    }
    if (value && value.trim().length > fullNameMaxLength) {
      return Promise.reject('Name cannot be longer than 256 characters');
    }
    return Promise.resolve();
  },
};

const emailRequired: Rule = {
  required: true,
  message: 'Please enter a valid email address.',
  type: 'email',
};

const countryRequired: Rule = {
  required: true,
  message: 'Please select your country.',
  type: 'string',
};

const stateRequired: Rule = {
  required: true,
  message: 'Please select your state.',
  type: 'string',
};

const phoneRequired: Rule = {
  required: true,
  message: 'Please enter a valid phone number.',
  type: 'string',
};

const zipCodeRequired: Rule = {
  required: true,
  message: 'Please enter a valid zip code.',
  type: 'string',
};

const timezoneRequired: Rule = {
  required: true,
  message: 'Please select a timezone',
  type: 'string',
};

const zipCodeValid: Rule = () => ({
  validator(rule, value) {
    if (!value || /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/i.test(value)) {
      return Promise.resolve();
    }

    return Promise.reject('Please enter a valid zip code.');
  },
});

const phoneValid: Rule = () => ({
  validator(rule, value) {
    if (!value || isValidPhoneNumber(value)) {
      return Promise.resolve();
    }

    return Promise.reject('Please enter a valid phone number.');
  },
});

const passwordStrength: Rule = () => ({
  validator(rule, value) {
    if (
      !value?.length ||
      value.length < 8 ||
      !/[A-Z]+/.test(value) ||
      !/[a-z]+/.test(value) ||
      !/\d+/.test(value) ||
      !/[!"#$%&'()*+,-./:;<=>?@[\]\\^_`{|}~]+/.test(value)
    ) {
      return Promise.reject(
        'Choose a password with at least 8 characters. Choose a mixture of upper and lower case letters, numbers, and symbols.'
      );
    }
    return Promise.resolve();
  },
});

const cardRequired: Rule = {
  required: true,
  message: 'Please enter a valid card number.',
  type: 'object',
};

export const authValidations = {
  fullNameRequired,
  fullNameValid,
  phoneRequired,
  phoneValid,
  emailRequired,
  passwordStrength,
  cardRequired,
  timezoneRequired,
  zipCodeRequired,
  zipCodeValid,
  countryRequired,
  stateRequired,
};
