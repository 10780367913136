import {useQuery, UseQueryOptions} from 'react-query';
import {KeynotesQueryKey} from '../../features/learn/util/keys';
import {IKeynote} from '../../types/strapi';
import {axiosPublicStrapi} from '../../utils/api';

export const KeynotesDetailQueryKey = [KeynotesQueryKey, 'Detail'] as const;

export const KeynotesDetailQueryKeyWithSlug = (slug?: string) => [...KeynotesDetailQueryKey, slug] as const;

const defaultQueryKey = KeynotesDetailQueryKeyWithSlug();

export function useGetKeynote(
  slug: string,
  options: Omit<
    UseQueryOptions<IKeynote | null, unknown, IKeynote | null, typeof defaultQueryKey>,
    'queryFn' | 'queryKey'
  > = {}
) {
  const queryKeyWithNameUid = KeynotesDetailQueryKeyWithSlug(slug);
  return useQuery(
    queryKeyWithNameUid,
    async () => {
      const params = {
        slug,
      };
      const data = await axiosPublicStrapi
        .get<IKeynote[]>('/keynotes', {
          params,
        })
        .then((res) => res.data);
      return data?.[0] ?? null;
    },
    {
      keepPreviousData: true,
      enabled: Boolean(slug),
      ...options,
    }
  );
}
