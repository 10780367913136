/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface StripeTaxRequest
 */
export interface StripeTaxRequest {
  /**
   *
   * @type {string}
   * @memberof StripeTaxRequest
   */
  priceId?: string;
  /**
   *
   * @type {number}
   * @memberof StripeTaxRequest
   */
  quantity?: number;
  /**
   *
   * @type {string}
   * @memberof StripeTaxRequest
   */
  priceType?: StripeTaxRequestPriceTypeEnum;
}

export const StripeTaxRequestPriceTypeEnum = {
  Plan: 'PLAN',
  Offer: 'OFFER',
} as const;

export type StripeTaxRequestPriceTypeEnum =
  (typeof StripeTaxRequestPriceTypeEnum)[keyof typeof StripeTaxRequestPriceTypeEnum];
