import {FlattenedPlans, Plan} from '../../../types/plans';
import {cloneDeep} from 'lodash';

/**
 * @see {@link FlattenedPlans} {@link Plan}
 * Takes a flatten plan and converts it back to original format. Works recursively
 * @param id - ID of plan to be flattened
 * @param flattenedPlans = Flattened plan map
 */

export function hydratePlan(id: string, flattenedPlans: FlattenedPlans): Plan {
  const parsedTask = flattenedPlans.records[id];
  return {
    ...cloneDeep(parsedTask),
    tasks: parsedTask.tasks.map((task) => hydratePlan(task, flattenedPlans)),
  };
}
