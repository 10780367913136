/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface EmbeddedReminder
 */
export interface EmbeddedReminder {
  /**
   *
   * @type {string}
   * @memberof EmbeddedReminder
   */
  reminderDate?: string;
  /**
   *
   * @type {string}
   * @memberof EmbeddedReminder
   */
  reminderType?: EmbeddedReminderReminderTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof EmbeddedReminder
   */
  reminderSent?: boolean;
}

export const EmbeddedReminderReminderTypeEnum = {
  AWeekBefore: 'A week before',
  _2DaysBefore: '2 days before',
  _1DayBefore: '1 day before',
  OnDueDate: 'On due date',
} as const;

export type EmbeddedReminderReminderTypeEnum =
  (typeof EmbeddedReminderReminderTypeEnum)[keyof typeof EmbeddedReminderReminderTypeEnum];
