import {isNumber, isPlainObject, isString, toLower} from 'lodash';

import urlJoin, {Options} from 'proper-url-join';
import {CloudfrontImageCompressionConfigDynamicConfig, DynamicConfigEnum} from '../../feature-gate/types';
import {CmsMedia} from '../../../types/api';
import {IFile} from '../../../types/strapi';
import {getDynamicConfig} from '../../feature-gate/utils/get-dynamic-config';

export enum ImageSize {
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  Thumbnail = 'thumbnail',
}

export type ImageUrlFromMediaOptions = {
  size?: ImageSize | number;
  format?: string;
  quality?: number;
};

/**
 * Extracts the file extension from a given URL.
 *
 * The function attempts to isolate the extension of the file referenced in the URL. It ignores URL fragments and queries
 * to focus on the main file path. If an extension is found, it's prefixed with a dot; otherwise, `undefined` is returned.
 *
 * @param url - The URL string from which to extract the file extension. Optional.
 * @returns The file extension including the dot (e.g., '.jpg') if found, otherwise `undefined`.
 */
export function getUrlExtension(url?: string) {
  if (!url) {
    return undefined;
  }

  // Splitting the URL at `#` and `?` to ignore any fragment or query part
  const basePath = url.split(/[#?]/)[0];

  // Extracting the extension from the base path
  const match = basePath.match(/\.([0-9a-z]+)(?:[?#]|$)/i);

  // Returning the extension with a dot, or undefined if not found
  return match ? `.${match[1]}` : undefined;
}

/**
 * Validates whether a given URL is a Cloudfront URL that points to an image file of a supported type.
 *
 * The function checks if the provided URL starts with any of the Cloudfront URLs specified in the `rewriteUrls` array
 * of the given configuration. If so, it further checks whether the file extension of the URL is among those designated
 * for image conversion (as listed in `config.defaultExts`). The validation is case-insensitive for file extensions.
 *
 * @param config - The dynamic configuration for Cloudfront image compression.
 * @param url - The URL to be validated.
 * @param ext - The file extension of the URL, which may be `undefined`.
 * @returns `true` if the URL is a valid Cloudfront image URL as per the configuration, otherwise `false`.
 */
export function isValidCloudfrontImageUrl(
  config: CloudfrontImageCompressionConfigDynamicConfig,
  url: string,
  ext: string | undefined
): boolean {
  if (config.rewriteUrls && Array.isArray(config.rewriteUrls)) {
    // Check if the url is a cloudfront url
    for (const rewriteUrl of config.rewriteUrls) {
      if (url?.startsWith(rewriteUrl.imageCloudfrontUrl)) {
        // Check that the file ext is an image type we want to convert
        if (ext && config.defaultExts?.includes(toLower(ext))) {
          return true;
        }
      }
    }
  }

  return false;
}

/**
 * Generates a processed image URL based on provided media information and options.
 *
 * This function handles various types of media input (URL string, CmsMedia object, IFile object) and returns a modified
 * URL that points to an optimized version of the image. The optimization includes rewriting URLs for Cloudfront, applying
 * specified size, format, and quality parameters. The function also incorporates default settings from dynamic configuration
 * for Cloudfront image compression. If the input media is a `.gif` file or if no URL can be determined from the input, the
 * function returns `undefined`.
 *
 * @param mediaOrUrl - The media item or URL to process, which can be of different types or `null`.
 * @param options - Optional parameters to specify the desired image size, format, and quality.
 * @returns The optimized image URL or `undefined` if the URL cannot be determined or processed.
 */
export function imageUrlFromMedia(
  mediaOrUrl?: string | CmsMedia | IFile | null,
  options?: ImageUrlFromMediaOptions
): string | undefined {
  const file = (() => {
    if (isPlainObject(mediaOrUrl) && typeof mediaOrUrl === 'object') {
      /**
       * This section handles the selection of an image format from a media object, based on the given options. It
       * excludes `.gif` files from processing because processed or resized GIFs lose their animation, making it
       * preferable to use the original GIF URLs in such cases.
       */
      if (mediaOrUrl?.ext !== '.gif') {
        const formats = mediaOrUrl?.formats;
        if (options?.size && isString(options.size) && (formats as any)[options.size]?.url) {
          return (formats as any)[options.size];
        }
        if (formats?.medium?.url) {
          return formats?.medium;
        }
        if (formats?.large?.url) {
          return formats?.large;
        }
      }
      if (mediaOrUrl?.url) {
        return mediaOrUrl;
      }
    }
  })();

  let url: string | undefined = file ? file?.url : typeof mediaOrUrl === 'string' ? mediaOrUrl : undefined;
  const ext: string | undefined = file?.ext || getUrlExtension(url);

  if (!url) {
    return undefined;
  }

  const value = getDynamicConfig<CloudfrontImageCompressionConfigDynamicConfig>(
    DynamicConfigEnum.CloudfrontImageCompressionConfig
  );

  if (value.rewriteUrls && Array.isArray(value.rewriteUrls)) {
    for (const rewriteUrl of value.rewriteUrls) {
      if (url.startsWith(rewriteUrl.imageS3Url)) {
        url = url.replace(rewriteUrl.imageS3Url, rewriteUrl.imageCloudfrontUrl);
        break;
      }
    }
  }

  if (isValidCloudfrontImageUrl(value, url, ext)) {
    const query: Options['query'] = {};

    if (options?.size && isNumber(options.size)) {
      query.w = Math.floor(options.size);
    } else if (
      file?.width &&
      value.defaultCompressionRatio &&
      isNumber(value.defaultCompressionRatio) &&
      value.defaultCompressionRatio > 0 &&
      value.defaultCompressionRatio <= 1
    ) {
      query.w = Math.floor(file.width * value.defaultCompressionRatio);
    }

    if (options?.format || value.defaultFormat) {
      query.f = options?.format || value.defaultFormat;

      // Set the quality for the format
      if (options?.quality || value.defaultQuality) {
        query.q = options?.quality || value.defaultQuality;
      }
    }

    if (Object.keys(query).length) {
      url = urlJoin(url, {query});
    }
  }

  return url;
}
