import {AssessmentRecommendation} from '@growthday/ui-core/src/types/api';
import {createAsyncThunk} from '@reduxjs/toolkit';
import message from 'antd/es/message';
import {dashboardApi} from '../../shared/api';
import {lifescoreApi} from '../../shared/api/lifescore/lifescore.api';
import {ScoreType} from '../../shared/components/life-scores/interfaces';
import {LifeScoreHistory} from '../../shared/services_deprecated/model/lifeScoreHistory';
import {ILifeScoresDashboard, ILifeScoresRecommendation} from './interfaces';

export const getRecommendations = createAsyncThunk<
  ILifeScoresRecommendation[] | AssessmentRecommendation[],
  {habitType: string; recommendations?: ILifeScoresRecommendation[] | AssessmentRecommendation[]}
>('lifescores/getRecommendations', async ({habitType, recommendations}) => {
  try {
    if (recommendations) {
      return recommendations;
    }
    const result = await lifescoreApi.getLifeScoreRecommendation(habitType);
    return result as ILifeScoresRecommendation[];
  } catch (err: any) {
    throw err;
  }
});

export const getLifeScoreDashboardData = createAsyncThunk('lifescores/getLifeScoreDashboardData', async () => {
  try {
    const result = await dashboardApi.getLifeScoreDashboardData();
    return result as ILifeScoresDashboard;
  } catch (err: any) {
    message.error('Something went wrong!. Please try again later');
    throw err;
  }
});

export const getAllLifeScoreRecommendation = createAsyncThunk<
  ILifeScoresRecommendation[] | AssessmentRecommendation[],
  {type: ScoreType; recommendationsResults?: AssessmentRecommendation[]}
>('lifescores/getAllLifeScoreRecommendation', async ({type, recommendationsResults}) => {
  try {
    if (recommendationsResults) {
      return recommendationsResults;
    }
    return await lifescoreApi.getAllLifeScoreRecommendation(type);
  } catch (err: any) {
    message.error('Something went wrong!. Please try again later');
    throw err;
  }
});

export const getHistoryInfo = createAsyncThunk<
  LifeScoreHistory,
  {habitType: string; filter: number; historyResults?: LifeScoreHistory}
>('lifescores/getHistoryInfo', async ({habitType, filter, historyResults}) => {
  try {
    if (historyResults) {
      return historyResults;
    }
    const result = await lifescoreApi.getLifeScores(habitType, filter);
    return result as LifeScoreHistory;
  } catch (err: any) {
    message.error('There was an error while building the history. Please try again later.');
    throw err;
  }
});
