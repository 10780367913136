import React, {FC} from 'react';
import Button, {ButtonProps} from 'antd/es/button';
import './style.less';

export type GDButtonProps = {} & ButtonProps;

const GDButton: FC<GDButtonProps> = ({children, ...restProps}) => {
  return <Button {...restProps}>{children}</Button>;
};

export default GDButton;
