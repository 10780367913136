import {sortBy} from 'lodash';
import {IPurchasedOffer} from '../../../features/offers/interfaces';
import {axiosGrowthDay, axiosPublicStrapi} from '../index';
import {IOffersNonNested} from '@growthday/ui-core/src/types/offers';

export const offersApi = {
  offerBySlug(slug: string) {
    return this.offers({slug}).then((res) => res[0]);
  },
  offers(params?: Record<string, any>) {
    return axiosPublicStrapi.get<IOffersNonNested[]>('offers', {params}).then((res) => sortBy(res.data, 'order'));
  },
  purchasedOffers() {
    return axiosGrowthDay.get('purchasedOffers').then((res) => res.data as IPurchasedOffer[]);
  },
};
