import Cookies from 'js-cookie';
import {useQuery} from 'react-query';
import axios from 'axios';
import useCountryState from '@growthday/ui-core/src/hooks/useCountryState';

const REGION_KEY = 'GD_REGION';

type Result = {
  country: string;
  region: string;
};

const useDefaultRegion = () => {
  const {getStates, loading} = useCountryState();
  return useQuery<Result>(
    REGION_KEY,
    async () => {
      try {
        const raw = Cookies.get(REGION_KEY);
        const savedRegion = raw ? JSON.parse(raw) : undefined;
        if (savedRegion?.country && savedRegion?.region) {
          return savedRegion;
        }
      } catch (e) {}
      return axios
        .get('https://get.geojs.io/v1/ip/geo.json')
        .then((res) => res.data)
        .then((res) => {
          const country = res.country_code ?? '';
          const states = getStates(country);
          const region = states.find((state) => state.name === res.region)?.state_code ?? '';
          const result: Result = {country, region};
          if (country) {
            Cookies.set(REGION_KEY, JSON.stringify(result), {expires: 1});
          } else {
            Cookies.remove(REGION_KEY);
          }
          return result;
        });
    },
    {
      enabled: !loading,
    }
  );
};

export default useDefaultRegion;
