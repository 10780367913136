/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {GrowthGroupInstanceTime} from './growth-group-instance-time';

/**
 *
 * @export
 * @interface GrowthGroupUpdateRequest
 */
export interface GrowthGroupUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof GrowthGroupUpdateRequest
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupUpdateRequest
   */
  agenda: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupUpdateRequest
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupUpdateRequest
   */
  time: string;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupUpdateRequest
   */
  duration?: number;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupUpdateRequest
   */
  rrule?: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupUpdateRequest
   */
  rruleString?: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupUpdateRequest
   */
  type: GrowthGroupUpdateRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupUpdateRequest
   */
  slug: string;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupUpdateRequest
   */
  canGuestInvite: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupUpdateRequest
   */
  recording?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupUpdateRequest
   */
  premium?: boolean;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupUpdateRequest
   */
  packageCostInCents?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupUpdateRequest
   */
  instanceCostInCents?: number;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupUpdateRequest
   */
  currency?: string;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupUpdateRequest
   */
  maxParticipantLimit?: number;
  /**
   *
   * @type {Array<GrowthGroupInstanceTime>}
   * @memberof GrowthGroupUpdateRequest
   */
  growthGroupInstanceTimes?: Array<GrowthGroupInstanceTime>;
}

export const GrowthGroupUpdateRequestTypeEnum = {
  Private: 'PRIVATE',
  Public: 'PUBLIC',
} as const;

export type GrowthGroupUpdateRequestTypeEnum =
  (typeof GrowthGroupUpdateRequestTypeEnum)[keyof typeof GrowthGroupUpdateRequestTypeEnum];
