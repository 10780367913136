import {IAchievementBadge} from './achievement-badge';
import {IAchievementCelebration} from './achievement-celebration';
import {IAchievementCertificate} from './achievement-certificate';
import {IAchievementCoin} from './achievement-coin';
import {ICourses} from './courses';

/**
 * Model definition for achievement-course
 */
export interface IAchievementCourse {
  id: number;
  course?: ICourses;
  type?: EnumAchievementCourseType;
  celebration?: IAchievementCelebration;
  certificate?: IAchievementCertificate;
  coin?: IAchievementCoin;
  badge?: IAchievementBadge;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumAchievementCourseType {
  COMPLETION = 'COMPLETION',
}
