import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getApiActionBuilder} from '../../shared/redux/util/build-api-action';
import {stateWithLoading} from '../../shared/redux/util/state-with-loading';
import * as apiActions from './lifescores.actions';
import {ILifeScoresDashboard, ILifeScoresLoadingState, ILifeScoresRecommendation, ILifeScoresState} from './interfaces';
import {LifeScoreHistory} from '../../shared/services_deprecated/model/lifeScoreHistory';
import {AssessmentRecommendation} from '@growthday/ui-core/src/types/api';

export const lifeScoresInitialState = stateWithLoading<ILifeScoresState, ILifeScoresLoadingState>({
  isEdit: false,
  isEditDaily: false,
  isEditWeekly: false,
  isEditMonthly: false,
  loadingApi: true,
  recommendations: [],
  recommendationsPerHabit: [],
  data: null,
  historyData: null,
  currentHistoryMonth: 1,
});

export const lifeScoresSlice = createSlice<
  ILifeScoresState,
  {
    entryExists: CaseReducer<ILifeScoresState, PayloadAction<boolean>>;
    updateLoading: CaseReducer<ILifeScoresState, PayloadAction<boolean>>;
    entryDashboardExists: CaseReducer<ILifeScoresState, PayloadAction<ILifeScoresDashboard>>;
    updateMonthHistory: CaseReducer<ILifeScoresState, PayloadAction<number>>;
  }
>({
  name: 'lifescores',
  initialState: lifeScoresInitialState,
  reducers: {
    entryExists(state, action) {
      state.isEdit = action.payload;
      state.loadingApi = false;
    },
    entryDashboardExists(state, action) {
      state.isEditDaily = action.payload.isEditDaily;
      state.isEditWeekly = action.payload.isEditWeekly;
      state.isEditMonthly = action.payload.isEditMonthly;
      state.loadingApi = false;
    },
    updateLoading(state, action) {
      state.loadingApi = action.payload;
    },
    updateMonthHistory(state, action) {
      state.currentHistoryMonth = action.payload;
    },
  },
  extraReducers: (builder) => {
    const buildApiAction = getApiActionBuilder<ILifeScoresState>(builder, apiActions);

    buildApiAction<PayloadAction<ILifeScoresRecommendation[]>>('getRecommendations', {
      fulfilled: (state, action) => {
        state.recommendations = action.payload;
      },
    });

    buildApiAction<PayloadAction<ILifeScoresDashboard>>('getLifeScoreDashboardData', {
      fulfilled: (state, action) => {
        state.data = action.payload;
      },
    });

    buildApiAction<PayloadAction<ILifeScoresRecommendation[] | AssessmentRecommendation[]>>(
      'getAllLifeScoreRecommendation',
      {
        fulfilled: (state, action) => {
          state.recommendationsPerHabit = action.payload;
        },
      }
    );
    buildApiAction<PayloadAction<LifeScoreHistory>>('getHistoryInfo', {
      fulfilled: (state, action) => {
        state.historyData = action.payload;
      },
    });
  },
});

export const {entryExists, entryDashboardExists, updateLoading, updateMonthHistory} =
  lifeScoresSlice.actions as unknown as {
    entryExists(payload: boolean): PayloadAction<boolean>;
    updateLoading(payload: boolean): PayloadAction<boolean>;
    updateMonthHistory(payload: number): PayloadAction<number>;
    entryDashboardExists(payload: ILifeScoresDashboard): PayloadAction<ILifeScoresDashboard>;
  };

export default lifeScoresSlice.reducer;
