import {useEffect} from 'react';
import useCallbackRef from './useCallbackRef';

export default function useRunOnMount(callback: () => void) {
  const cbRef = useCallbackRef(callback);
  useEffect(() => {
    cbRef.current();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
