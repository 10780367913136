type NavigationPaths = string;
type EventNames = string;

const getNavigationEventName = (pathName: string): string => {
  const eventMap: Record<NavigationPaths, EventNames> = {
    '/': 'Home Page',
    '/journal': 'Journal',
    '/life-scores': 'Life Scores',
    '/plan': 'Plan',
    '/challenges/available': 'Challenge',
    '/learn/dashboard/groups': 'Groups',
    '/learn/dashboard/courses': 'Courses',
    '/community': 'Community',
    '/profile/membership-and-billing': 'Membership and Billing',
    '/search': 'Search Results',
    '/ai/brendon': 'AI Brendon',
    '/teams/invite': 'Team Invite',
    '/profile/teams': 'Teams',
  };
  // This route will include the token id after the invite path, so we check if it contains the invite path
  if (pathName.includes('/teams/invite')) {
    return 'Team Invite';
  }
  return eventMap[pathName] ?? pathName;
};

export default getNavigationEventName;
