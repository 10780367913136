import {createAsyncThunk} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {strapiApi} from '../../shared/api';
import {DataCollectionEnum} from '../../shared/api/dataCollection/enums';
import {AppState} from '../../shared/types';
import {SidebarCalendarData, SidebarCalendarEntries, SidebarCalendarEntriesMap} from './interface';
import {uniq} from 'lodash';
import {IUserCourse} from '../../shared/services_deprecated/model/user-course';
import {IStrapiCourse} from '../../shared/services_deprecated/model/strapi/course';
import convertToUrlParams from '../../shared/util/query/convertToUrlParams';
import {StrapiCollectionEnum} from '../../shared/api/strapi/enums';
import {PromptCategory} from '../../shared/services_deprecated/model/strapi/promptCategory';
import {lifescoreApi} from '../../shared/api/lifescore/lifescore.api';
import {IStrapiChallenge, IUserChallenge} from '../challenges/interfaces';
import {parseToArrayDataJournal} from '../../shared/api/journal/journal.api';
import {DailyJournalEntry} from '../../shared/services_deprecated/model/generic/dailyJournal';
import {reactQueryClient} from '@growthday/ui-core/src/Provider';
import {
  GetTeamAssessmentsListKey,
  queryFnGetTeamAssessmentsList,
} from '@growthday/ui-core/src/features/life-scores/hooks/useGetTeamAssessmentsList';
import {Assessment} from '@growthday/ui-core/src/types/api';

type Args = {
  date: string;
};

export const fetchSidebarCalendarData = createAsyncThunk<
  {entriesMap: SidebarCalendarEntriesMap} & SidebarCalendarData,
  Args,
  {state: AppState}
>('sidebarCalendar/fetchSidebarCalendarData', async (args, thunkAPI) => {
  const {payload: entriesMap} = await thunkAPI.dispatch(getSidebarCalendarEntries(args));
  const {payload: data} = await thunkAPI.dispatch(getSidebarCalendarData(args));
  return {entriesMap: entriesMap as SidebarCalendarEntriesMap, ...(data as SidebarCalendarData)};
});

export const getSidebarCalendarEntries = createAsyncThunk<SidebarCalendarEntriesMap, Args, {state: AppState}>(
  'sidebarCalendar/getSidebarCalendarEntries',
  async (args, thunkAPI) => {
    const state = thunkAPI.getState().sidebarCalendar;
    const authState = thunkAPI.getState().auth.organization?.organizationId ?? '';
    const hasNotScorecardEnabled = thunkAPI.getState().auth.organization?.scorecardSettings?.enabled;

    try {
      const startDate = dayjs(args.date).startOf('M').format('YYYY-MM-DD');
      const endDate = dayjs(args.date).endOf('M').format('YYYY-MM-DD');

      const entries = await lifescoreApi.milestones(startDate, endDate);
      const teamDashboardScoreCardEntries =
        !authState || !hasNotScorecardEnabled
          ? []
          : ((await reactQueryClient.fetchQuery(
              GetTeamAssessmentsListKey(authState, startDate, endDate),
              queryFnGetTeamAssessmentsList({
                organizationId: authState,
                startTime: startDate,
                endTime: endDate,
              }),
              {}
            )) as Assessment[] | undefined);
      const mapped: SidebarCalendarEntries = {
        [DataCollectionEnum.CHALLENGES]: [],
        [DataCollectionEnum.PLANS]: [],
        [DataCollectionEnum.DAILY_JOURNAL]: [],
        [DataCollectionEnum.WEEKLY_HABITS]: [],
        [DataCollectionEnum.MONTHLY_HABITS]: [],
        [DataCollectionEnum.USER_COURSE]: [],
        [DataCollectionEnum.DAILY_HABITS]: [],
        [DataCollectionEnum.COMPANY_SCORECARDS]: [],
      };

      const checkIfisValidEntry = (el: DailyJournalEntry) => {
        if (el.data || el.photosIds) {
          if (Array.isArray(el.data) && el.data.filter(({answer}) => answer).length > 0) {
            return true;
          } else {
            return true;
          }
        }
        return false;
      };

      entries.forEach((day) => {
        if (day.dailyJournals) {
          const formatEntry = day.dailyJournals
            .map((el: DailyJournalEntry) => {
              el.data = parseToArrayDataJournal(el.data);
              return el;
            })
            .filter((entry) => checkIfisValidEntry(entry));
          mapped.DailyJournal = mapped.DailyJournal?.concat(formatEntry);
        }
        if (day.dailyHabits) {
          mapped.DailyHabits = mapped.DailyHabits?.concat(day.dailyHabits);
        }
        if (day.monthlyHabits) {
          mapped.MonthlyHabits = mapped.MonthlyHabits?.concat(day.monthlyHabits);
        }
        if (day.weeklyHabits) {
          mapped.WeeklyHabits = mapped.WeeklyHabits?.concat(day.weeklyHabits);
        }
        if (day.plans) {
          mapped.Plans = mapped.Plans?.concat(day.plans);
        }
        if (day.challenges) {
          mapped.Challenges = mapped.Challenges?.concat(day.challenges);
        }
        if (day.userCourses) {
          mapped.UserCourse = mapped.UserCourse?.concat(day.userCourses);
        }
      });

      if (teamDashboardScoreCardEntries) {
        mapped.CompanyScorecards = mapped.CompanyScorecards?.concat(teamDashboardScoreCardEntries);
      }

      return {
        ...state.entriesMap,
        [args.date]: mapped,
      };
    } catch (err: any) {
      throw err;
    }
  }
);

export const getSidebarCalendarData = createAsyncThunk<SidebarCalendarData, Args, {state: AppState}>(
  'sidebarCalendar/getSidebarCalendarData',
  async (args, thunkAPI) => {
    const state = thunkAPI.getState().sidebarCalendar;
    try {
      // Fetching course ids for existing user courses
      const existingCourseIds = state.strapiCourses.map((course) => course.id);
      const courseIds = uniq(
        (state.entriesMap?.[args.date]?.[DataCollectionEnum.USER_COURSE] ?? [])
          .map((entry: IUserCourse) => entry.courseId)
          .filter((id) => id && !existingCourseIds.includes(id))
      );
      let newStrapiCourses: IStrapiCourse[] = [];
      if (courseIds.length) {
        const queryParams = convertToUrlParams({
          id_in: courseIds,
        });
        newStrapiCourses = await strapiApi.getCollection<IStrapiCourse[]>(
          StrapiCollectionEnum.COURSES,
          `?${queryParams}`
        );
      }

      const existingChallengeIds = state.strapiChallenges.map((challenge) => challenge.id);
      const challengeIds = uniq(
        (state.entriesMap?.[args.date]?.[DataCollectionEnum.CHALLENGES] ?? [])
          .map((entry: IUserChallenge) => entry.challengeId)
          .filter((id) => id && !existingChallengeIds.includes(id))
      );
      let newStrapiChallenges: IStrapiChallenge[] = [];
      if (challengeIds.length) {
        const queryParams = convertToUrlParams({
          id_in: challengeIds,
        });
        newStrapiChallenges = await strapiApi.getCollection<IStrapiChallenge[]>(
          StrapiCollectionEnum.CHALLENGES,
          `?${queryParams}`
        );
      }

      // Fetching growth prompt categories
      let growthPromptCategories: PromptCategory[] = state.growthPromptCategories ?? [];
      if (!growthPromptCategories.length) {
        growthPromptCategories = await strapiApi
          .getCollection<PromptCategory[]>(StrapiCollectionEnum.PROMPT_CATEGORIES)
          .then((obj) =>
            (obj as PromptCategory[]).sort((a, b) =>
              a.order && b.order && a.order > b.order ? 1 : a.order && b.order && b.order > a.order ? -1 : 0
            )
          );
      }

      return {
        strapiCourses: [...state.strapiCourses, ...newStrapiCourses],
        growthPromptCategories,
        strapiChallenges: [...state.strapiChallenges, ...newStrapiChallenges],
      };
    } catch (err: any) {
      throw err;
    }
  }
);
