import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {DownloadState} from './interfaces';
import storage from 'redux-persist/lib/storage/session';
import {persistReducer} from 'redux-persist';
import _forEach from 'lodash/forEach';
import _set from 'lodash/set';

const persistConfig = {
  key: 'hpx:download',
  storage,
};

const initialState: DownloadState = {
  initialized: false,
  accessToken: null,
  queryParams: {},
};

export const downloadSlice = createSlice<
  DownloadState,
  {
    updateDownloadState: CaseReducer<DownloadState, PayloadAction<Partial<DownloadState>>>;
  }
>({
  name: 'download',
  initialState,
  reducers: {
    updateDownloadState(state, action) {
      _forEach(action.payload, (value, key) => {
        _set(state, key, value);
      });
    },
  },
});

export const {updateDownloadState} = downloadSlice.actions;

export default persistReducer(persistConfig, downloadSlice.reducer);
