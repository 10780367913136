import {useAuthData} from '../../hooks/useAuthData';
import {Client, IStompSocket} from '@stomp/stompjs';
import React, {createContext, FC, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import SockJS from 'sockjs-client';
import {TokenWithUser} from '../../types/api';
import {axiosGrowthDay} from '../../utils/api';
import {coreConfig} from '../../utils/core-config';
import {safeJsonParse} from '../../utils/safe-json';
import useStableCallback from '../../hooks/useStableCallback';
import {sockJSOptions} from './options/sockjs-options';

interface WebSocketProps {
  subscribe: <T = any>(key: string, topic: string, callback: (data: T) => void) => void;
  unsubscribe: (key: string, topic: string) => void;
  connected: boolean | undefined;
}

const WebSocketContext = createContext<WebSocketProps>({
  subscribe: () => undefined,
  unsubscribe: () => undefined,
  connected: false,
});

const retrieveToken = () => axiosGrowthDay.get<TokenWithUser>('/auth/ws-token').then((res) => res.data);
let TOKEN_EXPIRY: number | null = null; // To be sent from BE later
const MAX_CONNECT_ATTEMPTS = 5;
const BACKOFF_INTERVAL = 2000;
export const WebSocketProvider: FC = ({children}) => {
  const clientRef = useRef<Client>();
  const subscriptionIdRef = useRef<Map<string, string>>(new Map());
  const subscriptionCallbackRef = useRef<Map<string, Map<string, (data: any) => void>>>(new Map());
  const [connected, setConnected] = useState<boolean>(false);
  const isMountedRef = useRef(false);
  const {isLoggedIn} = useAuthData();

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const clearMaps = useStableCallback(() => {
    subscriptionCallbackRef.current.clear();
    subscriptionIdRef.current.clear();
  });

  const clearState = useStableCallback(() => {
    clearMaps();
    if (isMountedRef.current) {
      setConnected(false);
    }
  });

  const connect = useStableCallback(async function () {
    try {
      let tokenData: TokenWithUser | null = null;
      let currentAttempt = 0;
      const client = new Client({
        onWebSocketClose: (e) => {
          client.reconnectDelay = BACKOFF_INTERVAL * Math.pow(2, currentAttempt);
          // eslint-disable-next-line no-console
          console.debug('Websocket Close: ', e);
          // eslint-disable-next-line no-console
          console.debug(
            `Websocket Reconnect: Exponential back off - next connection attempt in ${client.reconnectDelay}ms`
          );
          clearState();
        },
        onWebSocketError: (e) => {
          // eslint-disable-next-line no-console
          console.debug('Websocket Error: ', e);
          clearState();
        },
        onConnect: () => {
          currentAttempt = 0;
          setConnected(true);
        },
        beforeConnect: async () => {
          currentAttempt++;

          if (currentAttempt > MAX_CONNECT_ATTEMPTS) {
            // eslint-disable-next-line no-console
            console.debug(
              `Websocket Reconnect: Exceeds max attempts (${MAX_CONNECT_ATTEMPTS}), will not try to connect now`
            );
            // It is valid to call deactivate from beforeConnect
            await client.deactivate();
            return;
          }

          if (!tokenData || !TOKEN_EXPIRY || Date.now() > TOKEN_EXPIRY) {
            try {
              tokenData = await retrieveToken();
              TOKEN_EXPIRY = Date.now() + 5 * 60 * 1000;
            } catch (e) {
              // eslint-disable-next-line no-console
              console.debug('Websocket Token Error: ', e);
              await client.deactivate();
              return;
            }
          }
          const token = tokenData?.token;
          if (token) {
            client.webSocketFactory = () =>
              new SockJS(`${coreConfig.apiUrl}/ws?token=${token}`, undefined, sockJSOptions) as IStompSocket;
          }
        },
        reconnectDelay: 0,
      });
      client.activate();
      clientRef.current = client;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.debug('Websocket Try/Catch Error: ', e);
    }
  });

  useEffect(() => {
    if (isLoggedIn) {
      connect();

      return () => {
        clientRef.current?.deactivate();
      };
    }
  }, [isLoggedIn, connect]);

  const subscribe = useCallback(
    function <T = any>(key: string, topic: string, callback: (data: T) => void) {
      if (clientRef.current && connected) {
        if (!subscriptionCallbackRef.current.has(topic)) {
          subscriptionCallbackRef.current.set(topic, new Map([[key, callback]]));
          try {
            const subscription = clientRef.current.subscribe(topic, (message) => {
              if (message.body) {
                const data = safeJsonParse<T>(message.body);
                subscriptionCallbackRef.current.get(topic)?.forEach((entry) => entry?.(data));
              }
            });
            subscriptionIdRef.current.set(topic, subscription.id);
          } catch (e) {}
        } else {
          subscriptionCallbackRef.current.get(topic)?.set(key, callback);
        }
      }
    },
    [connected]
  );

  const unsubscribe = useCallback(
    function (key: string, topic: string) {
      const callbackMap = subscriptionCallbackRef.current.get(topic);
      if (!callbackMap) {
        return;
      }

      callbackMap.delete(key);
      if (!callbackMap.size) {
        const subscriptionId = subscriptionIdRef.current.get(topic);
        if (clientRef.current && subscriptionId && connected) {
          try {
            clientRef.current.unsubscribe(subscriptionId);
          } catch (e) {}
        }
        subscriptionIdRef.current.delete(topic);
        subscriptionCallbackRef.current.delete(topic);
      }
    },
    [connected]
  );

  const values: WebSocketProps = useMemo(
    () => ({connected, subscribe, unsubscribe}),
    [connected, subscribe, unsubscribe]
  );

  return <WebSocketContext.Provider value={values}>{children}</WebSocketContext.Provider>;
};

export const useWebSocketContext = () => useContext(WebSocketContext);
