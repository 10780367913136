import plansApi from '../plans.api';
import {MutationEndpointOptions, Plan, PlanTypesSingular, UpdatePlanInput} from '../../../types/plans';
import {FetchBaseQueryError} from '@reduxjs/toolkit/query';
import {endpoints} from '../endpoints';
import {formatDueAndCompletedDate} from '../utils/formatDueAndCompletedDate';
import {detectDueDateConflict} from '../utils/detectDueDateConflict';
import {remindersApi} from '../../reminders/reminders.api';
import {reminderTags} from '../../reminders/reminders.constant';

function makeUpdateOptions(
  endpointURL: string,
  type: PlanTypesSingular
): MutationEndpointOptions<UpdatePlanInput, Plan> {
  return {
    async queryFn(arg, api, extraOptions, fetchWithBaseQuery) {
      const plansDataStore = plansApi.endpoints.getPlans.select()(api.getState() as any)!;
      if (plansDataStore.error) return {error: plansDataStore.error as FetchBaseQueryError};
      const plansData = plansDataStore.data!;

      const dueDateError = arg.dueDateTimestamp && detectDueDateConflict(arg.id, plansData, arg.dueDateTimestamp, type);
      if (dueDateError)
        return {
          error: {
            status: 'CUSTOM_ERROR',
            data: dueDateError,
            error: 'Due date conflicts found',
          } as FetchBaseQueryError,
        };
      arg.dueDateTimestamp = arg.dueDateTimestamp && formatDueAndCompletedDate(arg.dueDateTimestamp);

      let planID = arg.id;
      while (plansData.records[planID].parentID) {
        planID = plansData.records[planID].parentID;
      }
      const url = planID !== arg.id ? `${endpointURL}/${planID}/${arg.id}` : `${endpointURL}/${arg.id}`;
      const {error, data} = await fetchWithBaseQuery({
        url,
        body: arg,
        method: 'PATCH',
      });
      if (error) return {error};
      return {data: data as Plan};
    },
    async onQueryStarted(arg, api) {
      const patch = api.dispatch(
        plansApi.util.updateQueryData('getPlans', undefined, (draft) => {
          const {dueDateTimestamp, id, ...payload} = arg;
          draft.records[id] = {
            ...draft.records[arg.id],
            ...payload,
          };
          if (dueDateTimestamp) {
            draft.records[arg.id].dueDateTimestamp = formatDueAndCompletedDate(dueDateTimestamp);
          } else if ('dueDateTimestamp' in arg) {
            draft.records[arg.id].dueDateTimestamp = null;
            draft.records[arg.id].reminder = null;
          }
        })
      );

      try {
        api.queryFulfilled
          .then(() => {
            api.dispatch(remindersApi.util.invalidateTags([reminderTags.type, reminderTags.list()]));
          })
          .catch(() => {
            patch.undo();
          });
      } catch {
        patch.undo();
      }
    },
  };
}

export const updatePlanOptions = makeUpdateOptions(endpoints.plans, 'plan');

export const updateTaskOptions = makeUpdateOptions(endpoints.tasks, 'task');

export const updateSubTaskOptions = makeUpdateOptions(endpoints.subtasks, 'subtask');
