import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {stateWithLoading} from '../../shared/redux/util/state-with-loading';
import storage from 'redux-persist/lib/storage/session';
import {persistReducer} from 'redux-persist';
import {IAddEditProps, IProjectsLoadingState, IProjectsState} from './interfaces';
import {PaginatedEntity} from '../../shared/services_deprecated/model/generic/paginatedEntity';
import {getApiActionBuilder} from '../../shared/redux/util/build-api-action';
import * as apiActions from './projects.actions';

const persistConfig = {
  key: 'hpx:projects',
  storage,
  whitelist: ['activePlanId'],
};

export type ActivePlanId =
  | string
  | null
  | undefined
  | {planId: string | null | undefined; taskId: string | null | undefined};

const initialState = stateWithLoading<IProjectsState, IProjectsLoadingState>({
  activePlanId: null,
  activeTaskId: null,
  newPlan: false,
  isEdit: false,
  dateToUpdate: undefined,
  actionToUpdate: undefined,
  shouldUpdateBothActionPlan: undefined,
  completedPlans: undefined,
  isOnEdit: false,
});

export const projectsSlice = createSlice<
  IProjectsState,
  {
    updatePlanSelected: CaseReducer<IProjectsState, PayloadAction<ActivePlanId>>;
    openAddModal: CaseReducer<IProjectsState, PayloadAction<IAddEditProps>>;
    updateDueDatePlanEntitySelected: CaseReducer<IProjectsState, PayloadAction<any>>;
    updateDueDateActionToUpdate: CaseReducer<IProjectsState, PayloadAction<string>>;
    updateCompletedPlans: CaseReducer<IProjectsState, PayloadAction<PaginatedEntity>>;
    updateOnEdit: CaseReducer<IProjectsState, PayloadAction<boolean>>;
  }
>({
  name: 'projects',
  initialState,
  reducers: {
    updatePlanSelected(state, action) {
      if (action.payload) {
        if (typeof action.payload === 'string') {
          state.activePlanId = action.payload;
          state.activeTaskId = null;
        } else {
          state.activePlanId = action.payload?.planId;
          state.activeTaskId = action.payload?.taskId;
        }
      } else {
        state.activePlanId = null;
        state.activeTaskId = null;
      }
    },
    openAddModal(state, action) {
      state.newPlan = action.payload.newPlan;
      state.isEdit = action.payload.isEdit;
    },
    updateDueDatePlanEntitySelected(state, action) {
      state.dateToUpdate = action.payload.date;
      state.shouldUpdateBothActionPlan = action.payload.update;
    },
    updateDueDateActionToUpdate(state, action) {
      state.actionToUpdate = action.payload;
    },
    updateCompletedPlans(state, action) {
      state.completedPlans = action.payload;
    },
    updateOnEdit(state, action) {
      state.isOnEdit = action.payload;
    },
  },
  extraReducers: (builder) => {
    const buildApiAction = getApiActionBuilder<IProjectsState>(builder, apiActions);

    buildApiAction<PayloadAction<PaginatedEntity>>('retrieveCompletedPlans', {
      fulfilled: (state, action) => {
        state.completedPlans = action.payload;
      },
    });
  },
});

export const {
  updatePlanSelected,
  openAddModal,
  updateDueDatePlanEntitySelected,
  updateDueDateActionToUpdate,
  updateCompletedPlans,
  updateOnEdit,
} = projectsSlice.actions as unknown as {
  updatePlanSelected(payload?: ActivePlanId): PayloadAction<boolean>;
  openAddModal(payload: IAddEditProps): PayloadAction<boolean>;
  updateDueDatePlanEntitySelected(payload?: any): PayloadAction<boolean>;
  updateDueDateActionToUpdate(payload?: string): PayloadAction<boolean>;
  updateCompletedPlans(payload?: PaginatedEntity): PayloadAction<boolean>;
  updateOnEdit(payload?: boolean): PayloadAction<boolean>;
};

export default persistReducer(persistConfig, projectsSlice.reducer);
