import {useCallback, useReducer} from 'react';

const useForceUpdate = () => {
  const _ = useReducer(() => ({}), {})[1];
  return useCallback(() => {
    _();
    setTimeout(_);
  }, [_]);
};

export default useForceUpdate;
