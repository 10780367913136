import {
  IChallengeUpsell,
  ICustomChallenge,
  ICustomChallengeTask,
  IStrapiChallenge,
  IUserAssociateTask,
  IUserChallenge,
} from '../../../features/challenges/interfaces';
import {axiosGrowthDay, strapiApi} from '../index';
import _uniqBy from 'lodash/uniqBy';
import {StrapiCollectionEnum} from '../strapi/enums';
import {AchievementInfo} from '@growthday/ui-core/src/types/achievements';

export const userChallengesApi = {
  getChallengeByTitleUid(titleUid: string, query?: string) {
    return strapiApi
      .getPublicCollection<IStrapiChallenge[]>(
        StrapiCollectionEnum.CHALLENGES,
        `?titleUid=${titleUid}${query ? `&${query}` : ``}`
      )
      .then((res) => res[0]);
  },

  getChallenge: (id: number) => {
    return axiosGrowthDay.get<IUserChallenge[]>(`/userChallenges/${id}`).then((res) => res.data);
  },

  getCmsChallenge: (id: number) => {
    return axiosGrowthDay.get<IStrapiChallenge>(`/userChallenges/cms/${id}`).then((res) => res.data);
  },

  getCmsChallengeByTitleUid: (title: string) => {
    return axiosGrowthDay.get<IStrapiChallenge>(`/userChallenges/cms/titleUid/${title}`).then((res) => res.data);
  },

  listActiveChallenges: () => {
    return axiosGrowthDay.get<IUserChallenge[]>(`/userChallenges/active`).then((res) => res.data);
  },

  retrieveInformationsFromStrapi: () => {
    return axiosGrowthDay.get<IStrapiChallenge[]>(`/userChallenges/explore`).then((res) => res.data);
  },

  markCustomChallengeItemAsCompleteUndo(userChallengeId: string, ccId: number, tasks: number[]) {
    return axiosGrowthDay
      .put<IUserChallenge>(`/userChallenges/customChallenge/${userChallengeId}/${ccId}/undo`, tasks)
      .then((res) => res.data);
  },

  getOpenUserChallenges() {
    return axiosGrowthDay
      .get<IUserChallenge[]>(`/userChallenges/open`)
      .then((res) => res.data)
      .then((data) => _uniqBy(data, 'challengeId'));
  },

  getCompletedUserChallenges() {
    return axiosGrowthDay.get<IUserChallenge[]>(`/userChallenges/completed`).then((res) => res.data);
  },
  getRegistrations(challengeIds: number[]) {
    return axiosGrowthDay
      .get<Record<IStrapiChallenge['id'], number>>(
        `/userChallenges/registrations?challengeIds=${challengeIds.join(',')}`
      )
      .then((res) => res.data);
  },
  getChallengeComments(challengeId: number) {
    return axiosGrowthDay.get(`/userChallenges/comments/${challengeId}`).then((res) => res.data?.list ?? []);
  },

  enrollInChallenge(challengeId: number) {
    return axiosGrowthDay.post<IUserChallenge>(`/userChallenges/enroll/${challengeId}`).then((res) => res.data);
  },

  saveNote(userChallengeId: string, note: string | null) {
    return axiosGrowthDay
      .put<IUserChallenge>(`/userChallenges/notes/${userChallengeId}`, note, {
        headers: {
          'Content-Type': 'text/plain',
        },
      })
      .then((res) => res.data);
  },

  leaveChallenge(userChallengeId: string) {
    return axiosGrowthDay.put(`/userChallenges/${userChallengeId}/leave`);
  },

  markCustomChallengeItemAsComplete(
    userChallengeId: string,
    cc: {
      id: ICustomChallenge['id'];
      customChallengeTasks: {id: ICustomChallengeTask['id']}[];
    }
  ) {
    return axiosGrowthDay
      .put<IUserChallenge & {coinsEarnedThisTime?: number; achievement?: AchievementInfo | null}>(
        `/userChallenges/customChallenge/${userChallengeId}`,
        cc
      )
      .then((res) => res.data);
  },

  loadChallengesFromStrapi(filter: string) {
    return axiosGrowthDay.get<IStrapiChallenge[]>(`/userChallenges/${filter}`).then((res) => res.data);
  },

  markChallengesAsRead() {
    return axiosGrowthDay.put(`/userChallenges/read`).then((res) => res.data);
  },

  getUnreadChallenges() {
    return axiosGrowthDay.get(`/userChallenges/unread`).then((res) => res.data);
  },

  associateTaskWithChallenge(
    userChallengeId: string,
    customChallengeId: number,
    customTaskId: number,
    associatedObjectId: string
  ) {
    return axiosGrowthDay
      .put<IUserAssociateTask>(
        `/userChallenges/${userChallengeId}/associated/${customChallengeId}/${customTaskId}/${associatedObjectId}`
      )
      .then((res) => res.data);
  },

  getItemAssociatedToCustomChallenge(userChallengeId: string, customChallengeId: number) {
    return axiosGrowthDay
      .get<IUserAssociateTask[]>(`/userChallenges/${userChallengeId}/associated/${customChallengeId}`)
      .then((res) => res.data);
  },
  deleteItemAssociatedToCustomChallenge(userChallengeId: string, objectId: string) {
    return axiosGrowthDay.delete(`/userChallenges/${userChallengeId}/associated/${objectId}`).then((res) => res.data);
  },

  getUnreadChallengeUpsell(id?: number) {
    return axiosGrowthDay
      .get<IChallengeUpsell[]>(`/userChallenges/upsell${id ? `?challengeId=${id}` : ``}`)
      .then((res) => res.data);
  },

  readChallengeUpsell(id: number, userChallengeId: string) {
    return axiosGrowthDay.post<void>(`/userChallenges/upsell/${id}/${userChallengeId}`).then((res) => res.data);
  },
};
