import {forEach} from 'lodash';
import {HMSPeerMetadata, HMSPeerMetadataParsed, HMSPlatform} from '../../../types/growth-groups/hms';
import {safeJsonParse} from '../../../utils/safe-json';

type Keys = keyof HMSPeerMetadataParsed;
type Values<K extends Keys = Keys> = HMSPeerMetadataParsed[K];

type Formatter<V> = {
  serialize: (input?: V | null) => number;
  deserialize: (input?: number | null) => V;
};
export type MetadataParser<K extends Keys = Keys, V extends Values<K> = Values<K>> = Record<K, Formatter<V>>;

export const metadataParser: MetadataParser = {
  joinedAt: {
    serialize: (input) => Math.floor(((input as number) || 0) / 1000),
    deserialize: (input) => (input || 0) * 1000,
  },
  platform: {
    serialize: (input) => Number(input === HMSPlatform.MOBILE),
    deserialize: (input) => (input ? HMSPlatform.MOBILE : HMSPlatform.WEB),
  },
  isHandRaised: {
    serialize: (input) => Number(input),
    deserialize: (input) => Boolean(input),
  },
};

const keys: Keys[] = ['joinedAt', 'platform', 'isHandRaised'];

export function parsePeerMetadata(metadata?: string | null): HMSPeerMetadataParsed {
  const parsedJson = safeJsonParse<HMSPeerMetadata>(metadata) ?? [];
  return keys.reduce<HMSPeerMetadataParsed>(
    (_metadata, key, currentIndex) => ({
      ..._metadata,
      [key]: metadataParser[key].deserialize(parsedJson[currentIndex]),
    }),
    {}
  );
}

export function createPeerMetadata<K extends Keys = Keys, V extends Values<K> = Values<K>>(
  metadataString?: string | null,
  metadata?: HMSPeerMetadataParsed
) {
  const parsedMetadata = [...(safeJsonParse<HMSPeerMetadata>(metadataString) ?? [])];
  if (metadata) {
    forEach(metadata, (value, key) => {
      parsedMetadata[keys.indexOf(key as K)] = metadataParser[key as K].serialize(value as V);
    });
  }
  return parsedMetadata;
}
