import {useEffect} from 'react';
import useCustomDispatch from '../../../../../shared/hooks/useCustomDispatch';
import authApiActions from '../../../auth.actions';
import useAuthState from '../../../hooks/useAuthState';
import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';

const useGetCoinScores = () => {
  const {asyncDispatch} = useCustomDispatch();
  const {accessToken, coinScores} = useAuthState();
  const {user} = useUser();
  const isCoinsLoaded = !!coinScores;

  useEffect(() => {
    if (!isCoinsLoaded && user?.id && accessToken) {
      asyncDispatch(authApiActions.getNumCoinsPerEvents());
    }
  }, [asyncDispatch, isCoinsLoaded, accessToken, user?.id]);
};

export default useGetCoinScores;
