import {getPlanByLevel} from '../../auth/hooks/useGetPlanByLevel';
import {IStrapiSubscriptionPlan, ISubscriptionPlans} from '../../auth/interfaces';
import {getIntervalFromOfferPlan} from '@/features/auth/utils/plan-interval';
import {IOffersNonNested} from '@growthday/ui-core/src/types/offers';

const getPlanFromOffer = (
  allStrapiSubscriptionPlans?: IStrapiSubscriptionPlan[],
  subscriptionPlans?: ISubscriptionPlans[],
  offer?: Pick<IOffersNonNested, 'changeToPlan'>
) => {
  return offer?.changeToPlan
    ? getPlanByLevel(
        allStrapiSubscriptionPlans,
        subscriptionPlans,
        (plan) =>
          `${plan.level}_${getIntervalFromOfferPlan(offer.changeToPlan)}`.toUpperCase() ===
            offer.changeToPlan?.toUpperCase() && plan.isPurchasable,
        getIntervalFromOfferPlan(offer.changeToPlan)
      )
    : undefined;
};

export default getPlanFromOffer;
