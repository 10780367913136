import {useInfiniteQuery} from 'react-query';
import {notificationsApi} from '../../../shared/api';
import {IInAppPaginatedNotifications} from '../../../shared/services_deprecated/model/inAppNotifications';
import useAuthState from '../../auth/hooks/useAuthState';

export const NOTIFICATIONS_QUERY_KEY = 'notifications';
export const NOTIFICATIONS_PAGE_SIZE = 20;
const isLastPage = (inAppPaginatedNotifications: IInAppPaginatedNotifications) =>
  inAppPaginatedNotifications.count <= inAppPaginatedNotifications.page * inAppPaginatedNotifications.size;

const useGetNotificationsQuery = () => {
  const {accessToken} = useAuthState();
  return useInfiniteQuery(
    NOTIFICATIONS_QUERY_KEY,
    ({pageParam = {page: 1, size: NOTIFICATIONS_PAGE_SIZE}}) => notificationsApi.getNotifications(pageParam),
    {
      getNextPageParam: (lastPage, pages) =>
        isLastPage(lastPage) ? undefined : {page: lastPage.page + 1, size: NOTIFICATIONS_PAGE_SIZE},
      enabled: Boolean(accessToken),
    }
  );
};
export default useGetNotificationsQuery;
