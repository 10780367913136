import {
  AchievementsQueryEndpointOptions,
  InProgressAchievementResponse,
  InProgressPaginationParams,
  storeTag,
} from '../../../types/achievements';

export const getInProgressAchievementsOptions: AchievementsQueryEndpointOptions<
  InProgressPaginationParams,
  InProgressAchievementResponse
> = {
  query: ({page, size}) => `achievement/inprogress?page=${page}&size=${size}`,
  providesTags: [storeTag],
};
