/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {MenuItem} from './menu-item';

/**
 *
 * @export
 * @interface ConfigResponse
 */
export interface ConfigResponse {
  /**
   *
   * @type {Array<string>}
   * @memberof ConfigResponse
   */
  features: Array<ConfigResponseFeaturesEnum>;
  /**
   *
   * @type {Array<MenuItem>}
   * @memberof ConfigResponse
   */
  menuItems: Array<MenuItem>;
}

export const ConfigResponseFeaturesEnum = {
  GrowthGroup: 'GROWTH_GROUP',
  Challenge: 'CHALLENGE',
  Course: 'COURSE',
  Journal: 'JOURNAL',
  LifeScore: 'LIFE_SCORE',
  Plan: 'PLAN',
  Event: 'EVENT',
  DailyFire: 'DAILY_FIRE',
  FeaturedVideo: 'FEATURED_VIDEO',
  GiftSubscription: 'GIFT_SUBSCRIPTION',
  Notification: 'NOTIFICATION',
  AchievementBadge: 'ACHIEVEMENT_BADGE',
  AchievementCertificate: 'ACHIEVEMENT_CERTIFICATE',
  Goal: 'GOAL',
  Referral: 'REFERRAL',
  Community: 'COMMUNITY',
  Feedback: 'FEEDBACK',
  FocusAreas: 'FOCUS_AREAS',
  GdprDataCollect: 'GDPR_DATA_COLLECT',
} as const;

export type ConfigResponseFeaturesEnum = (typeof ConfigResponseFeaturesEnum)[keyof typeof ConfigResponseFeaturesEnum];
