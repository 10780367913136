import {axiosPublicStrapi, axiosStrapi} from '../index';
import {StrapiCollectionEnum} from './enums';

const getCollection = <T = any>(collectionType: StrapiCollectionEnum, filter?: string) => {
  const filtering = filter ? `${filter}` : '';
  return axiosStrapi.get<T>(`/${collectionType}${filtering}`).then((res) => res.data);
};

const getPublicCollection = <T = any>(collectionType: StrapiCollectionEnum, filter?: string) => {
  const filtering = filter ? `${filter}` : '';
  return axiosPublicStrapi.get<T>(`/${collectionType}${filtering}`).then((res) => res.data);
};

export const strapiApi = {
  getCollection,
  getPublicCollection,
};
