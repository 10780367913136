/**
 * Model definition for OK-Cta
 */
export interface IOkCta {
  id: number;
  label?: string;
  onClick?: EnumOkCtaOnClick;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumOkCtaOnClick {
  ok = 'ok',
  cancel = 'cancel',
  closeModal = 'closeModal',
  nextModal = 'nextModal',
}
