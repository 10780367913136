import {HMSRoleType} from './hms';
import {
  GrowthGroupChannelEnum,
  GrowthGroupTypeEnum,
  GrowthGroupInstanceResponse,
  GrowthGroupInstance as APIGrowthGroupInstance,
  Participant as APIParticipants,
} from '../api';
import {IAgendaTemplate} from '../strapi';

export type GrowthGroupInstance = APIGrowthGroupInstance;
export type IParticipants = APIParticipants;
export type IGrowthGroupParticipant = NonNullable<GrowthGroupInstance['topParticipants']>[number];
export type GrowthGroupChannel = GrowthGroupInstance['channel'];
export type GrowthGroupVisibility = GrowthGroupInstance['type'];
export enum GrowthGroupLayout {
  GALLERY = 'GALLERY',
  SPOT_LIGHT = 'SPOT_LIGHT',
}

export interface GrowthGroupInstancePaginatedResponse extends GrowthGroupInstanceResponse {
  __currentCountForPagination: number; // added from UI - in react query
}

export interface GrowthGroupPaginationParameters {
  size: number;
  page: number;
}

export type GrowthGroupStartTimeFilter = 'LIVE_NOW' | 'LIVE_TODAY' | 'THIS_WEEK' | 'THIS_MONTH';

export interface GrowthGroupFilters {
  type?: GrowthGroupChannelEnum;
  duration?: number;
  startTime?: GrowthGroupStartTimeFilter;
  visibility?: GrowthGroupTypeEnum;
  host?: boolean;
  attending?: boolean;
}

export const GrowthGroupQueryKey = 'GrowthGroup';
export const WorkshopQueryKey = 'Workshop';
export type StrapiAgenda = IAgendaTemplate;

export const MinRSVPCount = 0;
export const MaxSpeakerCount = 10;
export const MaxCoHostCount = 4;
export const MaxGrowthGroupsPerCycle = 10;
export const MonthsInGrowthGroupAddonCycle = 1;
export const MaxRecurringGrowthGroups = MaxGrowthGroupsPerCycle * (12 / MonthsInGrowthGroupAddonCycle);

export interface GrowthGroupState {
  loading: boolean;
  token: string;
  layout: GrowthGroupLayout;
  joinedTime: string;
  slug: string;
  showAdmissionModal: boolean;
  backstageBannerActive: boolean;
  showManageAttendeesModal: boolean;
  manageAttendeesModalPosition: ModalPosition;
  groupId: string;
  loadingInstanceId: string | null;
  sessionMetadataInitialized: boolean;
  paymentSidebarOpen: boolean;
}

export const GGRoleChangeFeedbackEventName = 'ROLE_CHANGE_FEEDBACK';
export const GGPinnedUsersEventName = 'PINNED_USERS_FEEDBACK';
export const GGHiddenUsersEventName = 'HIDDEN_USERS_FEEDBACK';
export const NewPinMessageEventName = 'NEW_PIN_MESSAGE';
export const RemovePinMessageEventName = 'REMOVE_PIN_MESSAGE';

export type GGRoleChangeFeedbackMessage = {
  peerUuid: string;
  peerName: string;
  newRole: HMSRoleType;
  oldRole: HMSRoleType;
  accepted: boolean;
};

export interface ModalPosition {
  x: number;
  y: number;
}

export enum RecurrenceOptions {
  THIS = 'THIS',
  THIS_AND_FOLLOWING = 'THIS_AND_FOLLOWING',
  ALL = 'ALL',
}
