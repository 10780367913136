import {ChannelMessage} from '@aws-sdk/client-chime-sdk-messaging';
import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';
import {computed} from 'zustand-computed';
import {MessageMetadata, serializeMessageMetadata} from '../utils/message-metadata';
import {ChatFeaturesEnum} from './chat-store';

export type ChatMessageId = NonNullable<ChannelMessage['MessageId']>;

export interface ChatMessageStoreEntry {
  messageIds: ChatMessageId[];
  messageMap: Record<ChatMessageId, ChannelMessage>;
  hasUnreadMessages: boolean;
  unlistedMessages: Record<ChatMessageId, boolean>;
}
export type ChatMessageStoreValues = {
  [K in ChatFeaturesEnum]: ChatMessageStoreEntry;
};

export interface ChatMessageStoreActions {
  addMessage(key: ChatFeaturesEnum, message: ChannelMessage): void;
  addMessages(key: ChatFeaturesEnum, messages: ChannelMessage[]): void;
  // Simply adds message to the store, but it is not added to the message IDs list
  // Useful for pin message feature - if pinned message is not is store
  addMessageWithoutListing(key: ChatFeaturesEnum, message: ChannelMessage): void;
  updateMessage(key: ChatFeaturesEnum, newMessage: ChannelMessage): void;
  updateMessageContent(
    key: ChatFeaturesEnum,
    messageId: ChatMessageId,
    newMessageContent: NonNullable<ChannelMessage['Content']>,
    newMetadata?: MessageMetadata | null | undefined
  ): void;
  deleteMessage(key: ChatFeaturesEnum, messageId: ChatMessageId): void;
  prependMessages(key: ChatFeaturesEnum, messages: ChannelMessage[]): void;
  setHasUnreadMessages(key: ChatFeaturesEnum, value: boolean): void;
  // If a key is provided, the reset that slice
  // Otherwise reset complete store
  resetStore(key?: ChatFeaturesEnum): void;
}

export type ComputedChatMessageStore = {
  hasMessages: {
    [K in ChatFeaturesEnum]: boolean;
  };
};

export type ChatMessageStore = ChatMessageStoreValues & ChatMessageStoreActions & ComputedChatMessageStore;

const defaultValue: ChatMessageStoreEntry = {
  messageMap: {},
  messageIds: [],
  hasUnreadMessages: false,
  unlistedMessages: {},
};

const initialState: ChatMessageStoreValues = {
  [ChatFeaturesEnum.GROWTH_GROUPS]: defaultValue,
};

const shouldListNewMessage = (message: ChannelMessage, state: ChatMessageStoreValues, key: ChatFeaturesEnum) => {
  if (!message.MessageId) {
    return;
  }

  // Either message not present in message map
  // Or present in message map and also is an unlisted message
  return !(message.MessageId in state[key].messageMap) || message.MessageId in state[key].unlistedMessages;
};

// Store to manage Growth Group chat messages
export const useChatMessageStore = create(
  computed(
    immer<ChatMessageStoreValues & ChatMessageStoreActions>((set) => ({
      ...initialState,
      addMessage(key, message) {
        return set((state) => {
          if (message.MessageId && shouldListNewMessage(message, state, key)) {
            state[key].messageIds.push(message.MessageId);
            state[key].messageMap[message.MessageId] = message;
            delete state[key].unlistedMessages[message.MessageId];
          }
        });
      },
      addMessages(key, messages) {
        return set((state) => {
          messages.forEach((message) => {
            if (message.MessageId && shouldListNewMessage(message, state, key)) {
              state[key].messageIds.push(message.MessageId);
              state[key].messageMap[message.MessageId] = message;
              delete state[key].unlistedMessages[message.MessageId];
            }
          });
        });
      },
      addMessageWithoutListing(key, message) {
        return set((state) => {
          if (message.MessageId) {
            state[key].messageMap[message.MessageId] = message;
            state[key].unlistedMessages[message.MessageId] = true;
          }
        });
      },
      updateMessage(key, newMessage) {
        return set((state) => {
          const messageId = newMessage.MessageId;
          if (messageId && messageId in state[key].messageMap) {
            state[key].messageMap[messageId] = newMessage;
          }
        });
      },
      updateMessageContent(key, messageId, newContent, newMetadata) {
        return set((state) => {
          if (messageId in state[key].messageMap) {
            const message = state[key].messageMap[messageId];
            message.Content = newContent;
            if (newMetadata) {
              message.Metadata = serializeMessageMetadata(newMetadata);
            }
            message.LastEditedTimestamp = new Date();
          }
        });
      },
      deleteMessage(key, messageId) {
        return set((state) => {
          if (messageId) {
            state[key].messageIds = state[key].messageIds.filter((id) => id !== messageId);
            delete state[key].messageMap[messageId];
          }
        });
      },
      prependMessages(key, messages) {
        return set((state) => {
          const filteredMessages = messages.filter((message) => shouldListNewMessage(message, state, key));
          const messageIds = filteredMessages.map((message) => message.MessageId) as ChatMessageId[];
          for (const message of filteredMessages) {
            if (message.MessageId) {
              state[key].messageMap[message.MessageId] = message;
              delete state[key].unlistedMessages[message.MessageId];
            }
          }
          state[key].messageIds = [...messageIds, ...state[key].messageIds];
        });
      },
      setHasUnreadMessages(key, value) {
        return set((state) => {
          state[key].hasUnreadMessages = value;
        });
      },
      resetStore(key) {
        if (!key) {
          set(initialState);
        } else {
          return set((state) => {
            state[key] = defaultValue;
          });
        }
      },
    })),
    function computeStore(store): ComputedChatMessageStore {
      const messageMap = {} as Record<ChatFeaturesEnum, boolean>;
      for (const key of Object.values(ChatFeaturesEnum)) {
        const feature = key as ChatFeaturesEnum;
        messageMap[feature] = store[feature].messageIds.length > 0;
      }

      return {hasMessages: messageMap};
    }
  )
);
