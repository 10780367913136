import {keys} from 'lodash';
import {FC, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import useAuthState from '../../auth/hooks/useAuthState';
import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';

const blacklistedRoutes: Record<string, string> = {
  '/license/change-subscription': '/profile',
  '/community': '/',
  '/profile/referral': '/profile',
  '/profile/gift': '/profile',
  '/campaign': '/',
};

const EnterpriseRedirect: FC = () => {
  const {organization} = useAuthState();
  const {user} = useUser();
  const {pathname, search} = useLocation();
  const history = useHistory();
  const isEnterpriseUser = Boolean(user?.enterpriseUser);
  const isCommunityEnabled = Boolean(organization?.communityEnabled);

  useEffect(() => {
    const route = keys(blacklistedRoutes).find((path) => pathname.startsWith(path));
    if (isEnterpriseUser && route) {
      if (route.endsWith('/community') && isCommunityEnabled) {
        return;
      }
      history.push(`${blacklistedRoutes[route]}${search}`);
    }
  }, [isCommunityEnabled, history, search, pathname, isEnterpriseUser]);
  return null;
};

export default EnterpriseRedirect;
