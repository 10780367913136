import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {MediaPlayerState} from './interface';
import sessionStorage from 'redux-persist/lib/storage/session';
import {persistReducer} from 'redux-persist';

const initialState: MediaPlayerState = {
  playInMini: false,
  currentTime: 0,
  url: '',
  title: '',
  redirectUrl: '',
  disableRedirect: false,
  isPlaying: false,
  audioVolume: 1,
  modalCurrentPlayingTime: 0,
  courseLessons: [],
  playingLessonIdMiniPlayer: 0,
  currentCourseTitleUid: '',
  canAccessNextLessons: false,
  autoplay: false,
  isContinuePlaying: false,
  courseId: null,
};

const persistConfig = {
  key: 'hpx:mediaPlayer',
  storage: sessionStorage,
};

export const mediaPlayerSlice = createSlice({
  name: 'mediaPlayer',
  initialState,
  reducers: {
    pauseMediaPlayerState(state, action: PayloadAction<Partial<MediaPlayerState>>) {
      return {...initialState, position: state.position, ...action.payload};
    },
    resetMediaPlayerState(state) {
      return {...initialState, position: state.position};
    },
    updateMediaPlayerState(state, action: PayloadAction<Partial<MediaPlayerState>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateModalCurrentPlayingTime(state, action: PayloadAction<Partial<number>>) {
      state.modalCurrentPlayingTime = action.payload;
    },
  },
});

export const {updateMediaPlayerState, resetMediaPlayerState, updateModalCurrentPlayingTime, pauseMediaPlayerState} =
  mediaPlayerSlice.actions;

export default persistReducer(persistConfig, mediaPlayerSlice.reducer);
