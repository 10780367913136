import {useFeatureGateUserSetup} from '@/features/feature-gate/hooks/useFeatureGateUserSetup';
import 'antd/lib/style/components.less';
import 'antd/lib/style/index.less';
import {SanitizeSearchParams} from '@/shared/components/SanitizeSearchParams';
import {setEntryPointParams} from '@/shared/util/entryPointParams';
import React, {lazy, Suspense, useLayoutEffect, useMemo, useRef} from 'react';
import {Provider as StoreProvider} from 'react-redux';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import {PersistGate} from 'redux-persist/integration/react';
import './App.less';
import useGetUserMetadata from '@growthday/ui-core/src/features/usermetadata/useGetUserMetadata';
import CoreProvider from '@growthday/ui-core/src/Provider';
import {ProfileTabsEnum} from './features/auth/enums';
import {CaptchaProvider} from './features/auth/hooks/useRecaptcha';
import UnAuthRoute from './features/auth/routes/Profile/components/UnAuthRoute';
import useGetCoinScores from './features/auth/routes/Profile/hooks/useGetCoinScores';
import InvalidResetPasswordToken from './features/auth/routes/resetPassword/InvalidResetPasswordToken';
import useOrganizationQuery from '@growthday/ui-core/src/features/enterprise/hooks/useOrganizationQuery';
import EnterpriseProvider from './features/enterprise/components/EnterpriseProvider';
import EnterpriseRedirect from './features/enterprise/components/EnterpriseRedirect';
import Loading from './shared/components/Loading';
import {use100vh} from './shared/hooks/use100vh';
import useAxiosInterceptors from './shared/hooks/useAxiosInterceptors';
import useDayJs from './shared/hooks/useDayJs';
import useDeepLinking from './shared/hooks/useDeepLinking';
import useFBPixel from '@/marketing/useFbPixel';
import useFirstPromoter from '@/marketing/useFirstPromoter';
import useDatadog from './shared/hooks/useDatadog';
import useUseProof from '@/marketing/useUseProof';
import store, {persistor} from './shared/redux/store';

import {setCampaignParams, updateGlobalPathParams} from './shared/util/campaignParams';
import {setSocialParams} from './shared/util/socialParams';
import {useGetAuthData} from './features/enterprise/api/useGetAuthData';
import {useGetFeatures} from '@/features/feature-disable/hooks/useGetFeatures';
import useAnalyticsActions from '@/features/analytics/hooks/useAnalyticsActions';
import {useSignupRedirect} from '@/features/auth/hooks/useSignupRedirect';

const KeyCloakLogin = lazy(() => import('./features/auth/routes/KeyCloakLogin/KeyCloakLogin'));
const Invitation = lazy(() => import('./features/auth/routes/Invitation/Invitation'));
const Shell = lazy(() => import('./features/shell'));
const ResetPassword = lazy(() => import('./features/auth/routes/resetPassword'));
const DeletedAccount = lazy(() => import('./features/auth/routes/DeleteAccount/DeleteAccount'));
const ForgotPassword = lazy(() => import('./features/auth/routes/ForgotPassword/ForgotPassword'));
const Download = lazy(() => import('./features/download'));
const OneClickPurchaseWrapper = lazy(() => import('@/features/one-click-purchase-mobile/OneClickPurchaseWrapper'));
const Campaign = lazy(() => import('./features/campaign/Campaign'));
const Preview = lazy(() => import('./features/preview/Preview'));
const Logout = lazy(() => import('./features/logout/Logout'));
import ErrorPage from './features/error-handling/components/ErrorPage/ErrorPage';
import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';
import useAuthState from './features/auth/hooks/useAuthState';
import {AxiosError} from 'axios';
import {useCheckUserShouldCompleteProfile} from './features/auth/hooks/useCheckUserShouldCompleteProfile';

function BaseRoutes({isLoggedIn}: {isLoggedIn?: boolean}) {
  const {search} = useLocation();
  useFirstPromoter();
  useUseProof();
  useFBPixel();
  useAnalyticsActions();
  useDatadog();
  useCheckUserShouldCompleteProfile();

  return (
    <Suspense fallback={<Loading />}>
      <EnterpriseProvider>
        {/* This will check for enterprise user and if they have access to that page */}
        <EnterpriseRedirect />
        <Switch>
          <Route path="/preview/:layoutId">
            <Preview />
          </Route>
          <Route path="/buy/gift">
            {isLoggedIn ? (
              <Redirect to={`/profile/${ProfileTabsEnum.GIFT}${search}`} />
            ) : (
              <Redirect to={`/gift/send${search}`} />
            )}
          </Route>
          {/*<Redirect path="/buy/:slug?" to="/campaign/:slug?" />*/}
          {/*<Redirect path="/join/:slug?" to="/campaign/:slug?" />*/}
          {/*<Redirect path="/buy/:slug?" to="/campaign/:slug?" />*/}
          <Route path="/campaign/:slug?">
            <Campaign />
          </Route>
          <Route path="/download">
            <Download />
          </Route>
          <Route path="/mobile-upgrade">
            <OneClickPurchaseWrapper />
          </Route>
          <Route path="/logout">
            <Logout />
          </Route>
          <UnAuthRoute path="/login/reset" exact>
            <ResetPassword />
          </UnAuthRoute>
          <UnAuthRoute path="/login/reset/expired-token">
            <InvalidResetPasswordToken />
          </UnAuthRoute>
          <UnAuthRoute path="/login">
            <KeyCloakLogin />
          </UnAuthRoute>
          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>
          <UnAuthRoute path="/invite">
            <Invitation />
          </UnAuthRoute>
          <UnAuthRoute path="/deleted-account">
            <DeletedAccount />
          </UnAuthRoute>
          <Route path="/">
            <Shell />
          </Route>
        </Switch>
      </EnterpriseProvider>
    </Suspense>
  );
}

function AppProvider() {
  const {pathname, search} = useLocation();
  useFeatureGateUserSetup();

  updateGlobalPathParams();
  useLayoutEffect(() => {
    updateGlobalPathParams();
  }, [pathname, search]);
  useDayJs();
  useAxiosInterceptors();

  const {accessToken} = useAuthState();
  useGetCoinScores();
  const {user, errorMsg: userError, isLoading: userInfoLoading} = useUser();
  const isLoggedIn = !!(accessToken && user);
  const {isLoading: isMetadataLoading, error: metadataError} = useGetUserMetadata(isLoggedIn);
  const {enabled, handleSuccessUpdateDate} = useGetAuthData();
  const {isLoading: isOrganizationLoading} = useOrganizationQuery({enabled, onSuccess: handleSuccessUpdateDate});
  const {isLoading: featuresLoading, error: featuresError} = useGetFeatures();

  const loadingRef = useRef(true);
  if (loadingRef.current && !userInfoLoading && !isOrganizationLoading && !isMetadataLoading && !featuresLoading) {
    loadingRef.current = false;
  }

  const errorMsg = useMemo(
    () => userError || ((metadataError || (featuresError && !userError)) as AxiosError)?.message,
    [userError, metadataError, featuresError]
  );
  //Halt the rendering of additional components in case of an error.
  if (errorMsg)
    return (
      <div className="error-container">
        <ErrorPage>{errorMsg}</ErrorPage>
      </div>
    );

  if (loadingRef.current) {
    return <Loading id="app-provider-loading" />;
  }

  return <BaseRoutes isLoggedIn={isLoggedIn} />;
}

const App = () => {
  const isFirstMountRef = useRef(true);
  if (isFirstMountRef.current) {
    setCampaignParams();
    setSocialParams();
    setEntryPointParams();
    isFirstMountRef.current = false;
  }

  useDeepLinking();

  // cleanup for re rendering prevention during resize
  use100vh();
  const isLoading = useSignupRedirect();

  if (isLoading) {
    return <Loading id="app-loading" />;
  }

  return (
    <>
      <StoreProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <CoreProvider>
            <main className="app-content">
              <AppProvider />
            </main>
          </CoreProvider>
        </PersistGate>
      </StoreProvider>
      <CaptchaProvider />
      <SanitizeSearchParams />
    </>
  );
};

export default App;
