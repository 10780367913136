import {IStrapiSubscriptionPlan} from '@/features/auth/interfaces';
import {axiosPublicStrapi} from '@/shared/api';
import {UseQueryOptions, useQuery} from 'react-query';

export const StrapiSubscriptionPlansKey = ['Strapi', 'StrapiSubscriptionPlans'];
/**
 * Custom hook for fetching Strapi subscription plans using React Query.
 * @param options - Query options for customization (optional). Example {enabled,onSuccess,onError}
 * @returns React Query hook result for fetching Strapi subscription plans.
 */
export function useGetStrapiSubscriptionPlansQuery(
  options: UseQueryOptions<
    IStrapiSubscriptionPlan[],
    unknown,
    IStrapiSubscriptionPlan[],
    typeof StrapiSubscriptionPlansKey
  > = {}
) {
  return useQuery(
    StrapiSubscriptionPlansKey,
    async () => {
      const result = await axiosPublicStrapi('/subscription-plans');
      return result.data as IStrapiSubscriptionPlan[];
    },
    options
  );
}
