import {ScoreData} from '../../../../features/life-scores/interfaces';
import {DailyJournalEntry} from '../../../services_deprecated/model/generic/dailyJournal';
import {MonthlyHabitScoreCard} from '../../../services_deprecated/model/generic/monthlyHabitScoreCard';
import {ScoreCard} from '../../../services_deprecated/model/generic/scoreCard';
import {WeeklyHabitScoreCard} from '../../../services_deprecated/model/generic/weeklyHabitScoreCard';
import {GDCarouselProps} from '../../ui/Carousel/Carousel';

export interface LifeScoreWidget {
  scoresInfo?: ScorecardHabits;
  loading?: boolean;
  showTitle?: boolean;
  showHintAndOverall?: boolean;
  showLockIcons?: boolean;
  carouselProps?: GDCarouselProps;
  id?: string;
  className?: string;
  isFromWidget?: boolean;
}

export interface ScorecardHabits {
  weeklyHabitScores?: WeeklyHabitScoreCard;
  scoreData?: ScoreData;
  latestHabitEntry?: DailyJournalEntry;
  monthlyHabitScores?: MonthlyHabitScoreCard;
  dailyHabitScores?: ScoreCard;
}

export enum ScoreType {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  TEAM_ASSESSMENT = 'teamAssessment',
  ALL = 'All',
}
