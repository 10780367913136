import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {stateWithLoading} from '../../shared/redux/util/state-with-loading';
import {getApiActionBuilder} from '../../shared/redux/util/build-api-action';
import * as apiActions from './feedback.actions';
import _unset from 'lodash/unset';
import coerceArray from '../../shared/util/coerceArray';
import {
  Author,
  FeedbackFormInfo,
  FeedbackLoadingState,
  FeedbackState,
  IFeedbackOption,
  IFeedbackType,
} from './interface';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';

const persistConfig = {
  key: 'hpx:feedback',
  storage: storage,
  whitelist: ['form'],
};

const initialState = stateWithLoading<FeedbackState, FeedbackLoadingState>({
  authors: [],
  form: undefined,
  customFormOption: undefined,
});

export const feedbackSlice = createSlice<
  FeedbackState,
  {
    unsetFeedbackState: CaseReducer<FeedbackState, PayloadAction<string | string[]>>;
    updateFeedbackForm: CaseReducer<FeedbackState, PayloadAction<FeedbackFormInfo | undefined>>;
    updateFeedbackFormType: CaseReducer<FeedbackState, PayloadAction<IFeedbackType | undefined>>;
    updateFeedbackOptionForm: CaseReducer<FeedbackState, PayloadAction<IFeedbackOption | undefined>>;
  }
>({
  name: 'feedback',
  initialState,
  reducers: {
    unsetFeedbackState(state, action) {
      const keys = coerceArray(action.payload);
      keys.forEach((key) => {
        _unset(state, key);
      });
    },
    updateFeedbackForm(state, action) {
      state.form = action.payload;
      if (!action.payload) {
        state.customFormOption = undefined;
      }
    },
    updateFeedbackFormType(state, action) {
      if (action.payload) {
        state.form = {...state.form, type: action.payload} as FeedbackFormInfo;
      }
    },
    updateFeedbackOptionForm(state, action) {
      state.customFormOption = action.payload;
    },
  },
  extraReducers: (builder) => {
    const buildApiAction = getApiActionBuilder<FeedbackState>(builder, apiActions);

    buildApiAction<PayloadAction<Author[]>>('getAuthorsList', {
      fulfilled: (state, action) => {
        state.authors = action.payload;
      },
    });
  },
});

export const {unsetFeedbackState, updateFeedbackFormType, updateFeedbackForm, updateFeedbackOptionForm} =
  feedbackSlice.actions;

export default persistReducer(persistConfig, feedbackSlice.reducer);
