import {useMemo} from 'react';
import {useGetStrapiSubscriptionPlansQuery} from './useGetStrapiSubscriptionPlansQuery';
import {sortBy} from 'lodash';
import {getPlanAnnualAmount} from '@/features/auth/utils/plan-interval';
import {
  getActiveSubscription,
  getRegistrationSubscriptionPlan,
  getSubscriptionsFromStrapiSubscriptions,
  isSubscriptionGiftable,
  isSubscriptionPurchasable,
} from '@/features/license/utils/subscription';
import {UserSignupType} from '@/shared/services_deprecated/model/user';
import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';
import {IStrapiSubscriptionPlan, ISubscriptionPlan, ISubscriptionPlans} from '@/features/auth/interfaces';
import {useSignupPlanStore} from '@/features/auth/routes/Profile/utils/signupPlanStore';

/**
 * Custom hook to manage subscription plans data.
 * @returns An object containing various subscription plan-related data.
 */
const useSubscriptionPlans = () => {
  const {user} = useUser();
  const {data, isLoading} = useGetStrapiSubscriptionPlansQuery();
  const {setPlan, plan} = useSignupPlanStore();

  return useMemo(() => {
    /**
     * Array of all subscription plans fetched from the API, sorted by annual amount.
     */
    const allStrapiSubscriptionPlans = sortBy(data, (plan) => getPlanAnnualAmount(plan)).map((plan) => ({
      ...plan,
      level: plan.level?.toUpperCase() as IStrapiSubscriptionPlan['level'],
    }));

    /**
     * Array of non-enterprise subscription plans.
     */
    const nonEnterprisePlans = allStrapiSubscriptionPlans.filter((plan) => !plan.enterprise);

    /**
     * Array of active subscription plans excluding deprecated and enterprise plans.
     */
    const activePlans = allStrapiSubscriptionPlans.filter((plan) => !plan.enterprise && !plan.deprecated);

    /**
     * Array of active subscription plans.
     */
    const subscriptionPlans = getSubscriptionsFromStrapiSubscriptions(activePlans);

    /**
     * Array of subscription plans including deprecated ones.
     */
    const subscriptionPlansWithDeprecated = getSubscriptionsFromStrapiSubscriptions(nonEnterprisePlans);

    /**
     * The currently active subscription of the user.
     */
    const activeSubscription = getActiveSubscription(
      getSubscriptionsFromStrapiSubscriptions(allStrapiSubscriptionPlans),
      user
    );

    /**
     * A list of the subscriptions that the current user can buy and gift to another person.
     */
    const giftableSubscriptionPlans = subscriptionPlans.reduce(
      (previousPlan: ISubscriptionPlan[], plan: ISubscriptionPlans) => {
        if (isSubscriptionGiftable(plan)) {
          (plan.month.monthlyAmount ?? 0) > 0 && previousPlan.push(plan.month);
          (plan.year.yearlyAmount ?? 0) > 0 && previousPlan.push(plan.year);
          return previousPlan;
        }

        return previousPlan;
      },
      [] as ISubscriptionPlan[]
    );

    /**
     * A list of the purchasable subscriptions for the current user.
     */
    const purchasableSubscriptionPlans = subscriptionPlans.reduce(
      (previousPlan: ISubscriptionPlan[], plan: ISubscriptionPlans) => {
        if (isSubscriptionPurchasable(plan)) {
          (plan.year.yearlyAmount ?? 0) > 0 && previousPlan.push(plan.year);
          (plan.month.monthlyAmount ?? 0) > 0 && previousPlan.push(plan.month);
          return previousPlan;
        }

        return previousPlan;
      },
      [] as ISubscriptionPlan[]
    );

    /**
     * The registration plan of the user.
     */
    const registrationPlan =
      user?.signupPlanId || user?.signupType === UserSignupType.PLAN
        ? getRegistrationSubscriptionPlan(subscriptionPlans, user)!
        : plan;

    return {
      isLoading,
      allStrapiSubscriptionPlans,
      strapiSubscriptionPlans: activePlans,
      subscriptionPlans,
      subscriptionPlansWithDeprecated,
      giftableSubscriptionPlans,
      purchasableSubscriptionPlans,
      activeSubscription,
      registrationPlan,
      setRegistrationPlan: setPlan,
    };
  }, [isLoading, plan, setPlan, data, user]);
};

export default useSubscriptionPlans;
