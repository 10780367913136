import message from 'antd/es/message';
import {useCallback, useMemo} from 'react';
import {IUpdateUser} from '../../../../../shared/services_deprecated/model/user';
import authApiActions from '../../../auth.actions';
import useAuthState from '../../../hooks/useAuthState';
import useCustomDispatch from '../../../../../shared/hooks/useCustomDispatch';
import {unwrapResult} from '@reduxjs/toolkit';
import {useUpdateMeData} from '@growthday/ui-core/src/features/user/hooks/useUpdateMeData';
import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';

const useMyProfile = () => {
  const {dispatch, asyncDispatch} = useCustomDispatch();
  const {accessToken} = useAuthState();
  const {refetch: refetchMyProfile, isLoading} = useUser();
  const meDataUpdater = useUpdateMeData();
  const updateMyProfile = useCallback(
    async (values: IUpdateUser, hideSuccessMessage?: boolean) => {
      if (accessToken) {
        await dispatch(authApiActions.updateUserInfo(values));
        refetchMyProfile();
        if (!hideSuccessMessage) {
          message.success('User updated!');
        }
        dispatch(authApiActions.getNumCoinsPerEvents());
      }
    },
    [accessToken, dispatch, refetchMyProfile]
  );

  const updateAddress = useCallback(
    async (values: IUpdateUser) => {
      const result = await asyncDispatch(authApiActions.updateAddress(values)).then(unwrapResult);
      if (!result.isSuccess) {
        throw new Error('Please enter a valid address');
      }
      meDataUpdater((old) => {
        return {...old, values};
      });
      return result;
    },
    [asyncDispatch, meDataUpdater]
  );

  return useMemo(
    () => ({updateAddress, updateMyProfile, refetchMyProfile, isLoading}),
    [updateAddress, updateMyProfile, refetchMyProfile, isLoading]
  );
};

export default useMyProfile;
