import {IFile} from './file';
import {ISignupComponent} from './signup-component';
import {IWebhooks} from './webhooks';

/**
 * Model definition for Steps
 */
export interface ISteps {
  id: number;
  readableTitle?: string;
  title?: string;
  footerText?: string;
  logo?: IFile;
  order?: number;
  components?: ISignupComponent[];
  component_layout?: EnumStepsComponentLayout;
  stepType?: EnumStepsStepType;
  webhooks: IWebhooks[];
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumStepsComponentLayout {
  ROW = 'ROW',
  COLUMN = 'COLUMN',
}

export enum EnumStepsStepType {
  EMAIL = 'EMAIL',
  PAYMENT = 'PAYMENT',
  UPSELL = 'UPSELL',
  PROFILE = 'PROFILE',
  OTHER = 'OTHER',
}
