import {IDowngradeContent} from './downgrade-content';
import {IFile} from './file';
import {ILicenses} from './licenses';
import {IOffers} from './offers';
import {IOnboardFlow} from './onboard-flow';
import {IOnboarding} from './onboarding';
import {IOnboardingUpsells} from './onboarding-upsells';
import {ISocialEvents} from './social-events';
import {ITooltips} from './tooltips';
import {IWebhooks} from './webhooks';

/**
 * Model definition for subscription-plan
 */
export interface ISubscriptionPlans {
  id: number;
  readableTitle?: string;
  name?: string;
  currency?: string;
  stripeMonthlyPriceId?: string;
  stripeYearlyPriceId?: string;
  bulletPoints?: string;
  monthlyAmount?: number;
  yearlyAmount?: number;
  level?: EnumSubscriptionPlansLevel;
  paypalMonthlyPriceId?: string;
  paypalYearlyPriceId?: string;
  levelWeight?: number;
  trialInDays?: number;
  trialInDaysMonthly?: number;
  description?: string;
  appleMonthlyProductId?: string;
  appleYearlyProductId?: string;
  licenses?: ILicenses;
  withoutTrialStripeMonthlyPriceId?: string;
  withoutTrialStripeYearlyPriceId?: string;
  withoutTrialPaypalMonthlyPriceId?: string;
  withoutTrialPaypalYearlyPriceId?: string;
  withoutTrialAppleMonthlyProductId?: string;
  withoutTrialAppleYearlyProductId?: string;
  signupHeading?: string;
  signupVideoUrl?: string;
  signupDescription?: string;
  signupImage?: IFile;
  slug?: string;
  events: ISocialEvents[];
  webhooks: IWebhooks[];
  tooltips: ITooltips[];
  onboarding?: IOnboarding;
  deprecated?: boolean;
  enterprise?: boolean;
  isCustom?: boolean;
  minimumQuantity?: number;
  freeOffers?: IOffers[];
  downgradeContent?: IDowngradeContent;
  upgradeContent?: IDowngradeContent;
  freeGrowthGroups?: number;
  freeGrowthGroupsYearly?: number;
  freeGrowthGroupsRecurring?: boolean;
  orderBump?: IOffers;
  onboardingUpsells: IOnboardingUpsells[];
  popular?: boolean;
  onboard_flow?: IOnboardFlow;
  partnerId?: string;
  partnerAccountUrl?: string;
  partnerLogo?: IFile;
  partnerMonthlySKUs?: {[key: string]: any};
  partnerYearlySKUs?: {[key: string]: any};
  isPurchasable: boolean;
  isGiftable: boolean;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumSubscriptionPlansLevel {
  BASIC = 'BASIC',
  STARTER = 'STARTER',
  MASTERY = 'MASTERY',
  ALL_ACCESS = 'ALL_ACCESS',
  ENTERPRISE = 'ENTERPRISE',
  ENTERPRISE_STARTER = 'ENTERPRISE_STARTER',
}
