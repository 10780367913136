import {axiosGrowthDay} from '../../../shared/api';

export type noteDetails = {
  noteCategory: 'LESSON' | 'CHALLENGE' | 'LIVE' | 'GROWTH_GROUP';
  noteObjectId: string;
  noteId?: string;
  lessonId?: number;
};

export const userNotesApi = {
  pinSomeNote(value: noteDetails) {
    return axiosGrowthDay.post(`/notes/pinned`, value).then((res) => res);
  },

  unpinSomeNote(value: noteDetails) {
    return axiosGrowthDay.delete(`/notes/pinned`, {data: value}).then((res) => res);
  },

  getNotes() {
    return axiosGrowthDay.get(`/notes`).then((res) => res);
  },

  getPinnedNotes(value: {page: number; size: number}) {
    return axiosGrowthDay.get(`/notes/pinned?page=${value.page}&size=${value.size}`).then((res) => res);
  },

  saveLessonNote(noteData: {courseId: number; lessonId: number; noteContent: string; noteId: string}) {
    return axiosGrowthDay.post(`/userCourse/notes`, noteData);
  },

  saveLiveEventNote(eventId: string, note: string | null) {
    return axiosGrowthDay.put(`/userLiveEvents/notes/${eventId}`, note, {
      headers: {
        'Content-Type': 'text/plain',
      },
    });
  },

  saveChallengeNote(eventId: string, note: string | null) {
    return axiosGrowthDay.put(`/userChallenges/notes/${eventId}`, note, {
      headers: {
        'Content-Type': 'text/plain',
      },
    });
  },

  deleteSomeNote(courseId: string, lessonId: string, noteId: string) {
    return axiosGrowthDay.delete(`/userCourse/notes/${courseId}/${lessonId}/${noteId}`);
  },
};
