import {useInfiniteQuery, UseInfiniteQueryOptions} from 'react-query';
import {axiosGrowthDay} from '../../../utils/api';
import {
  GrowthGroupInstancePaginatedResponse,
  GrowthGroupQueryKey,
  MaxRecurringGrowthGroups,
} from '../../../types/growth-groups';
export const GroupKeyWithSlug = (slug?: string) =>
  !slug ? ([GrowthGroupQueryKey, 'DETAILS'] as const) : ([GrowthGroupQueryKey, 'DETAILS', {slug}] as const);

export function useGetAllGroupsBySlug(
  slug: string,
  options: UseInfiniteQueryOptions<
    GrowthGroupInstancePaginatedResponse,
    unknown,
    GrowthGroupInstancePaginatedResponse,
    GrowthGroupInstancePaginatedResponse,
    ReturnType<typeof GroupKeyWithSlug>
  > = {}
) {
  return useInfiniteQuery(
    GroupKeyWithSlug(slug),
    async ({pageParam = 1}) => {
      const result = await axiosGrowthDay(`/groups/instances/slug/${slug}/instances`, {
        params: {
          limit: MaxRecurringGrowthGroups,
          offset: pageParam,
        },
      });
      return result.data as GrowthGroupInstancePaginatedResponse;
    },
    {
      getNextPageParam(lastPage, pages) {
        const loaded = pages.reduce((prev, current) => prev + (current.growthGroupInstanceList?.length ?? 0), 0);
        const total = lastPage.count ?? 0;

        return loaded < total ? pages.length + 1 : undefined;
      },
      keepPreviousData: true,
      enabled: Boolean(slug),
      staleTime: 30 * 1000,
      ...options,
    }
  );
}
