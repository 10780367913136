import {
  EnumAchievementJournalInterval,
  EnumAchievementLifescoreInterval,
  EnumAchievementMemberInterval,
  EnumAchievementsTypeCategory,
  IAchievementBadge,
  IAchievementCelebration,
  IAchievementCertificate,
  IAchievementJournalTier,
  IAchievementLifescoreTier,
  IAchievementMemberTier,
  IAchievements,
} from './strapi';
import {OmitFromUnion} from '@reduxjs/toolkit/dist/query/tsHelpers';
import {QueryDefinition} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {BaseQueryFn} from '@reduxjs/toolkit/query';
import React from 'react';
import {MutationDefinition} from '@reduxjs/toolkit/dist/query/react';
import {achievementsApiReducerPath} from '../features/achievements/achievements.api';

export type AchievementType = 'BADGE' | 'CERTIFICATE' | 'COIN';
export type IntervalType = 'Daily' | 'Weekly' | 'Monthly';

export const storeTag = 'achievements-data';

export interface AchievementInfo {
  id: string;
  uuid: string;
  rewardType: AchievementType;
  achievementType?: EnumAchievementsTypeCategory;
  achievementName?: string;
  url?: string;
  strapiId: number;
  tierId: number;
  value?: number;
  extraInfo?: Record<string, any>;
  updateTimestamp: number;
  title?: string;
  description?: string;
  typeInfo?: AchievementRewardTypeInfo;
  name?: string;
}

export interface AchievementsResponse {
  allMonthLiveEventAchievements?: AchievementInfo[] | null;
  challengeAchievements?: AchievementInfo[] | null;
  courseAchievements?: AchievementInfo[] | null;
  journalAchievements?: AchievementInfo[] | null;
  lifeScoreAchievements?: AchievementInfo[] | null;
  memberAchievement?: AchievementInfo[] | null;
  firstLiveEventAchievement?: AchievementInfo | null;
  referralAchievement?: AchievementInfo | null;
}

export interface BadgeItem {
  badge: IAchievementBadge;
  count: {value: number};
  completedTimestamp: {value: number};
  typeInfo: AchievementRewardTypeInfo;
  strapiAchievementId: number;
  title: string;
  achievementInfo: AchievementInfo;
}

export type GroupedAchievements = {
  [K in AchievementInfo['rewardType']]?: {
    [AK in keyof AchievementsResponse]: Array<AchievementInfo & {strapi?: IAchievements}>;
  };
};

export type NonTierAchievements = EnumAchievementsTypeCategory.COURSE | EnumAchievementsTypeCategory.EVENT;

export type InProgressAchievement = {
  id: string;
  uuid: string;
  achievementNextCount: number;
  achievementCurrentCount: number;
  extraInfo?: Record<string, any>;
  trigger?: string;
} & (
  | {
      achievementType: Exclude<EnumAchievementsTypeCategory, NonTierAchievements>;
      tierId: number;
    }
  | {
      achievementType: Extract<EnumAchievementsTypeCategory, NonTierAchievements>;
    }
) &
  Pick<AchievementInfo, 'strapiId'>;

export type CertificateItem = IAchievementCertificate & {
  achievementId: string;
  url: AchievementInfo['url'];
  completedTimestamp: number;
  typeInfo: AchievementRewardTypeInfo;
  strapiAchievementId: number;
};

export type AchievementsQueryEndpointOptions<QueryArg, ResultType> = OmitFromUnion<
  QueryDefinition<QueryArg, BaseQueryFn, typeof storeTag, ResultType, typeof achievementsApiReducerPath>,
  'type'
>;

export type AchievementsMutationEndpointOptions<QueryArg, ResultType> = OmitFromUnion<
  MutationDefinition<QueryArg, BaseQueryFn, typeof storeTag, ResultType, typeof achievementsApiReducerPath>,
  'type'
>;

export type AchievementRewardTypeInfo =
  | {
      achievementType: 'achievements.achievement-challenge';
      entityIdentifier?: string;
    }
  | {
      achievementType: 'achievements.achievement-lifescore';
      intervalInfo: {
        type: EnumAchievementLifescoreInterval;
        value: IAchievementLifescoreTier['value'];
      };
    }
  | {
      achievementType: 'achievements.achievement-journal';
      intervalInfo: {
        type: EnumAchievementJournalInterval;
        value: IAchievementJournalTier['value'];
      };
    }
  | {
      achievementType: 'achievements.achievement-course';
      entityIdentifier?: string;
    }
  | {
      achievementType: 'achievements.achievement-event';
    }
  | {
      achievementType: 'achievements.achievement-referral';
    }
  | {
      achievementType: 'achievements.achievement-member';
      intervalInfo: {
        type: EnumAchievementMemberInterval;
        value: IAchievementMemberTier['value'];
      };
    };

export interface InProgressAchievementTile {
  id: string;
  percentage: number;
  title: React.ReactNode;
  caption: React.ReactNode;
  strapiAchievementId: number;
  typeInfo?: AchievementRewardTypeInfo;
  coins: number;
  count: number;
  total: number;
  image: {
    url: string;
    alt?: string;
    aspectRatio?: number;
  };
}

export interface CelebrationModalInfo {
  certificate?: IAchievementCertificate | null;
  badge?: IAchievementBadge | null;
  coinCount?: number;
  nextAchievement?: {
    badge: IAchievementBadge;
    currentCount: number;
    nextCount: number;
    caption: string;
  };
  celebration?: IAchievementCelebration | null;
}

export interface InProgressPaginationParams {
  page: number;
  size: number;
}

export interface InProgressAchievementResponse {
  achievementInProgressList: InProgressAchievement[];
  totalInProgressAchievement: number;
}
