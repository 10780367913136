import {ChatFeaturesEnum} from './chat-store';

export function curriedByKey<T extends Array<any>>(
  key: ChatFeaturesEnum,
  callback: (key: ChatFeaturesEnum, ...args: T) => void
) {
  return function (...args: T) {
    return callback(key, ...args);
  };
}
