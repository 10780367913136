import {useQuery, useQueryClient, UseQueryOptions} from 'react-query';
import {axiosGrowthDay} from '../../../utils/api';
import {GrowthGroupQueryKey} from '../../../types/growth-groups';
import {Participant} from '../../../types/api';
import {useAuthData} from '../../../hooks/useAuthData';
import {useEffect} from 'react';

export const GroupUserParticipantsKey = [GrowthGroupQueryKey, 'UserParticipants'] as const;

export default function useGetUserParticipants(
  options: UseQueryOptions<Participant[], unknown, Participant[], typeof GroupUserParticipantsKey> = {}
) {
  const {isLoggedIn} = useAuthData();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isLoggedIn) {
      queryClient.removeQueries({
        queryKey: GroupUserParticipantsKey,
      });
    }
  }, [isLoggedIn, queryClient]);

  return useQuery(
    GroupUserParticipantsKey,
    async () => {
      const result = await axiosGrowthDay(`/groups/futureParticipants`);
      return result.data as Participant[];
    },
    {
      enabled: isLoggedIn,
      staleTime: 30 * 1000,
      ...options,
    }
  );
}
