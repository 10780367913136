import {EnumSocialEventsType, ISocialEvents} from '@growthday/ui-core/src/types/strapi';
import {IStrapiSubscriptionPlan} from '../interfaces';
import {fbPixelActions} from '@/marketing/useFbPixel';
import {getGtagActions} from '@/features/analytics/hooks/useAnalyticsActions';
import {useCallback} from 'react';
import useDeviceId from './useDeviceId';
import eventNameSlug from '../../../shared/util/eventNameSlug';
import {axiosGrowthDay} from '../../../shared/api';
import {getPlanAnnualAmount} from '@/features/auth/utils/plan-interval';
import {IOffersNonNested} from '@growthday/ui-core/src/types/offers';

const useSocialEvents = () => {
  const deviceId = useDeviceId();
  return useCallback(
    (
      events?: ISocialEvents[] | null,
      options?: {offer?: IOffersNonNested | null; plan?: IStrapiSubscriptionPlan | null}
    ) => {
      if (events?.length && (options?.offer || options?.plan)) {
        const plan = options?.plan;
        const offer = options?.offer;
        if (plan || offer) {
          events.forEach((event) => {
            if (event?.name && event?.type) {
              switch (event.type) {
                case EnumSocialEventsType.FACEBOOK:
                  {
                    const eventId = `${eventNameSlug(event.name)}-${deviceId}-${(plan ? plan.id : offer?.id) ?? ''}`;
                    const eventData = {
                      content_category: plan ? 'plan' : 'offer',
                      content_ids: plan ? [plan.id] : [offer?.kajabiOfferId || offer?.id || ''].filter(Boolean),
                      content_name: plan ? plan.name : offer?.helpText || offer?.title || '',
                      content_type: 'product',
                      currency: 'USD',
                      value: plan ? getPlanAnnualAmount(plan) : offer?.price || 0,
                    };
                    fbPixelActions.track(event.name, eventData, eventId);
                    axiosGrowthDay.post('/facebook-pixel', {
                      ...eventData,
                      deDuplicationKey: eventId,
                      eventName: event.name,
                    });
                  }
                  break;
                case EnumSocialEventsType.GOOGLE:
                  getGtagActions().track('conversion', {
                    send_to: event.name,
                  });
                  break;
              }
            }
          });
        }
      }
    },
    [deviceId]
  );
};

export default useSocialEvents;
