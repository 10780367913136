import {useMemo} from 'react';
import {StatsigUser} from 'statsig-js';
import {getInitialFeatureGateValues} from '../utils/initial-feature-gate-values';
import {useAuthData} from '../../../hooks/useAuthData';
import useDeepCompare from '../../../utils/useDeepCompare';

export function useFeatureGateUser(platform: 'web' | 'mobile') {
  const {user, isLoggedIn} = useAuthData();
  const statsigUser: StatsigUser = useMemo(() => {
    const initialValues = getInitialFeatureGateValues(platform);
    return user && isLoggedIn
      ? {
          userID: user.uuid,
          email: user.email,
          custom: {isLoggedIn: true, platform},
          privateAttributes: {
            level: user.level,
            enterpriseUser: user.enterpriseUser,
            demoUser: user.demoUser,
            kajabiUser: user.kajabiUser,
            signupCampaignId: user.campaignId,
            signupPlanId: user.signupPlanId,
            signupOfferId: user.signupOfferId,
            signupChallengeId: user.signupChallengeId,
          },
        }
      : initialValues.user;
  }, [platform, user, isLoggedIn]);

  return useDeepCompare(statsigUser);
}
