/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface GrowthGroupQueryParam
 */
export interface GrowthGroupQueryParam {
  /**
   *
   * @type {string}
   * @memberof GrowthGroupQueryParam
   */
  type?: GrowthGroupQueryParamTypeEnum;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupQueryParam
   */
  channel?: GrowthGroupQueryParamChannelEnum;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupQueryParam
   */
  duration?: number;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupQueryParam
   */
  date?: string;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupQueryParam
   */
  host?: boolean;
}

export const GrowthGroupQueryParamTypeEnum = {
  Private: 'PRIVATE',
  Public: 'PUBLIC',
} as const;

export type GrowthGroupQueryParamTypeEnum =
  (typeof GrowthGroupQueryParamTypeEnum)[keyof typeof GrowthGroupQueryParamTypeEnum];
export const GrowthGroupQueryParamChannelEnum = {
  Audio: 'AUDIO',
  Video: 'VIDEO',
} as const;

export type GrowthGroupQueryParamChannelEnum =
  (typeof GrowthGroupQueryParamChannelEnum)[keyof typeof GrowthGroupQueryParamChannelEnum];
