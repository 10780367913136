/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {PromptRequest} from './prompt-request';

/**
 *
 * @export
 * @interface JournalRequest
 */
export interface JournalRequest {
  /**
   *
   * @type {PromptRequest}
   * @memberof JournalRequest
   */
  prompt?: PromptRequest;
  /**
   *
   * @type {string}
   * @memberof JournalRequest
   */
  category?: string;
  /**
   *
   * @type {string}
   * @memberof JournalRequest
   */
  data?: string;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof JournalRequest
   */
  photosIds?: {[key: string]: string};
  /**
   *
   * @type {string}
   * @memberof JournalRequest
   */
  type?: JournalRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof JournalRequest
   */
  masterPrimaryId?: string;
  /**
   *
   * @type {string}
   * @memberof JournalRequest
   */
  masterSecondaryId?: string;
  /**
   *
   * @type {string}
   * @memberof JournalRequest
   */
  masterOptionalId?: string;
}

export const JournalRequestTypeEnum = {
  Challenge: 'CHALLENGE',
  Journal: 'JOURNAL',
  Goal: 'GOAL',
  Plan: 'PLAN',
} as const;

export type JournalRequestTypeEnum = (typeof JournalRequestTypeEnum)[keyof typeof JournalRequestTypeEnum];
