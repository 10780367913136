/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface CmsEvents
 */
export interface CmsEvents {
  /**
   *
   * @type {string}
   * @memberof CmsEvents
   */
  type?: CmsEventsTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CmsEvents
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof CmsEvents
   */
  trigger?: CmsEventsTriggerEnum;
}

export const CmsEventsTypeEnum = {
  Facebook: 'FACEBOOK',
  Google: 'GOOGLE',
  Bing: 'BING',
  Appsflyer: 'APPSFLYER',
  Mixpanel: 'MIXPANEL',
} as const;

export type CmsEventsTypeEnum = (typeof CmsEventsTypeEnum)[keyof typeof CmsEventsTypeEnum];
export const CmsEventsTriggerEnum = {
  Signup: 'SIGNUP',
  Trial: 'TRIAL',
  Payment: 'PAYMENT',
  PaymentMonthly: 'PAYMENT_MONTHLY',
  Cancellation: 'CANCELLATION',
  TrialCancellation: 'TRIAL_CANCELLATION',
  SignupLoad: 'SIGNUP_LOAD',
  PaymentLoad: 'PAYMENT_LOAD',
} as const;

export type CmsEventsTriggerEnum = (typeof CmsEventsTriggerEnum)[keyof typeof CmsEventsTriggerEnum];
