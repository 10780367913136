import {IOffersNonNested} from '@growthday/ui-core/src/types/offers';
import {CardElement as StripeCardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import stripeJs from '@stripe/stripe-js';
import {omit, pickBy, uniqBy} from 'lodash';
import {useCallback, useMemo, useRef, useState} from 'react';
import {parsePhoneNumber} from 'react-phone-number-input';
import {useDispatch} from 'react-redux';
import {EnumSocialEventsTrigger, EnumSocialEventsType} from '@growthday/ui-core/src/types/strapi';
import {useRecaptcha} from '../../auth/hooks/useRecaptcha';
import {IUpdateUser, IUpdateUserAddress} from '../../../shared/services_deprecated/model/user';
import authApiActions from '../../auth/auth.actions';
import {ISubscriptionPlan} from '../../auth/interfaces';
import useMyProfile from '../../auth/routes/Profile/hooks/useMyProfile';
import useOffers from '../../offers/hooks/useOffers';
import usePurchasedOffers from '../../offers/hooks/usePurchasedOffers';
import {IPurchasedOffer} from '../../offers/interfaces';
import offersApiActions from '../../offers/offers.actions';
import AddCreditCardModal from '../components/AddCreditCardModal/AddCreditCardModal';
import {
  canPurchaseSubscription,
  isOfferPaymentSystemInCompatible,
  isSubscriptionExpired,
  showIncompatibleOfferPaymentPopup,
  showIncompatiblePaymentPopup,
} from '@/features/license/utils/subscription';
import useSubscriptionPlans from '@/features/auth/routes/Profile/hooks/useSubscriptionPlans';
import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';
import {useUpdateMeData} from '@growthday/ui-core/src/features/user/hooks/useUpdateMeData';
import {fbPixelActions} from '@/marketing/useFbPixel';
import eventNameSlug from '@/shared/util/eventNameSlug';
import {getGtagActions} from '@/features/analytics/hooks/useAnalyticsActions';
import {useIsSCAFlowEnabled} from '@/features/license/hooks/useIsSCAFlowEnabled';

export type StripeCardAddValue = stripeJs.StripeCardElementChangeEvent & IUpdateUser;

export type StripeCardSubmitValue = IUpdateUser & {stripeResult?: stripeJs.PaymentMethodResult};

const useStripePayment = () => {
  const {activeSubscription} = useSubscriptionPlans();
  const {user} = useUser();
  const meDataUpdater = useUpdateMeData();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const {refetchPurchasedOffers} = usePurchasedOffers();
  const isSCAFlowEnabled = useIsSCAFlowEnabled();
  const {refetchOffers} = useOffers();
  const {refetchMyProfile, updateAddress} = useMyProfile();
  const [addCardVisible, setAddCardVisible] = useState(false);
  const purchaseRef = useRef<{
    subscription?: ISubscriptionPlan | null;
    offers?: IOffersNonNested[] | null;
    renewPurchasedOffer?: IPurchasedOffer | null;
  }>();
  const promiseRef = useRef<{resolve: () => void; reject: (err?: string) => void}>();
  const {execute} = useRecaptcha();

  const addPaymentMethod = useCallback(
    async (
      value: StripeCardAddValue,
      skipUpdatingPaymentMethod?: boolean,
      skipUpdatingRegionFields?: boolean
    ): Promise<StripeCardSubmitValue> => {
      if (value.error) {
        throw value.error;
      }
      if (!stripe || !elements) {
        throw new Error('Stripe is not initialized');
      }

      const parsedPhoneNumber = parsePhoneNumber(value.phoneNumber || user?.phoneNumber || '');

      const userAddress: IUpdateUserAddress = {
        iso2: parsedPhoneNumber?.country || '',
        countryCode: parsedPhoneNumber?.countryCallingCode || '',
        phoneNumber: parsedPhoneNumber?.number || '',
        country: value.country || user?.country || '',
        region: value.region || (value.country ? '' : user?.region) || '',
        zipCode: value.zipCode || user?.zipCode || '',
      };

      const captchaToken = await execute({
        ...user,
        ...value,
        context: user?.paymentMethodId ? 'Card Update' : 'Card Add',
      });

      const address = pickBy(
        {
          country: userAddress.country,
          state: userAddress.region,
          postal_code: userAddress.zipCode,
        },
        Boolean
      );

      const stripeResult = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(StripeCardElement)!,
        billing_details: {
          name: value.fullName || user?.fullName || '',
          email: value.email || user?.email || '',
          phone: userAddress.phoneNumber,
          address,
        },
      });

      if (stripeResult.error) {
        if (stripeResult.error.message && /Stripe/.test(stripeResult.error.message)) {
          const [formattedError] = stripeResult.error.message.split(';');
          throw new Error(formattedError);
        }
        throw new Error(stripeResult.error.message);
      }

      const canUpdateRegionFields = (() => {
        if (skipUpdatingRegionFields || !user) {
          return false;
        }
        if (
          userAddress.region !== user.region ||
          userAddress.country !== user.country ||
          userAddress.zipCode !== user.zipCode
        ) {
          return true;
        }
        return !user.phoneNumber && userAddress.phoneNumber !== user.phoneNumber;
      })();

      if (canUpdateRegionFields) {
        try {
          const toUpdate = user?.phoneNumber ? omit(userAddress, 'phoneNumber', 'iso2', 'countryCode') : userAddress;
          await updateAddress(toUpdate);
        } catch (e: any) {
          throw new Error(e.message);
        }
      }

      const paymentMethodId = stripeResult.paymentMethod!.id;

      if (!skipUpdatingPaymentMethod && user) {
        const result: any = await dispatch(
          user.paymentMethodId
            ? authApiActions.updateStripePaymentMethod({paymentMethodId, captchaToken})
            : authApiActions.addStripePaymentMethod({paymentMethodId, captchaToken})
        );

        meDataUpdater((old) => {
          old.paymentMethodId = paymentMethodId;
        });
        if (result.error) {
          throw new Error(result.error.message);
        }
      }

      return {
        ...value,
        ...userAddress,
        paymentMethodId,
        stripeResult,
      } as StripeCardSubmitValue;
    },
    [execute, updateAddress, stripe, elements, user, dispatch, meDataUpdater]
  );

  const purchaseOffer = useCallback(
    async (_offers?: IOffersNonNested[] | null) => {
      const offers = uniqBy(_offers ?? [], 'id');
      if (offers.length) {
        const result: any = await dispatch(offersApiActions.purchaseOffersViaStripe({isSCAFlowEnabled, offers}));
        if (user && offers.length) {
          offers.forEach((offer) => {
            offer.events
              ?.filter((event) => event.trigger === EnumSocialEventsTrigger.PAYMENT)
              ?.forEach((socialEvent) => {
                if (socialEvent?.type && socialEvent?.name) {
                  switch (socialEvent.type) {
                    case EnumSocialEventsType.FACEBOOK:
                      fbPixelActions.track(
                        socialEvent.name,
                        {
                          content_category: 'offer',
                          content_ids: [offer.kajabiOfferId || offer.id || ''].filter(Boolean),
                          content_name: offer.helpText || offer.title,
                          content_type: 'product',
                          currency: offer.currency || 'USD',
                          value: offer.price || 0,
                          'User ID': user?.uuid ?? '',
                          Date: new Date().toISOString(),
                          Location: user?.iso2 ?? '',
                          Email: user?.email ?? '',
                        },
                        `${eventNameSlug(socialEvent.name)}-offer-${user.uuid}-${offer.id ?? ''}`
                      );
                      break;
                    case EnumSocialEventsType.GOOGLE:
                      getGtagActions().track('conversion', {
                        send_to: socialEvent.name,
                      });
                      break;
                  }
                }
              });
          });
        }
        if (result.error) {
          throw new Error(result.error.message);
        }
        refetchPurchasedOffers();
        refetchOffers();
      }
    },
    [dispatch, refetchPurchasedOffers, refetchOffers, user, isSCAFlowEnabled]
  );

  const cancelPurchasedOffer = useCallback(
    async (purchasedOffer: IPurchasedOffer) => {
      if (user) {
        if (isOfferPaymentSystemInCompatible(purchasedOffer)) {
          showIncompatibleOfferPaymentPopup(user, purchasedOffer);
          return Promise.resolve();
        }
        const result: any = await dispatch(offersApiActions.cancelPurchasedOfferViaStripe(purchasedOffer));
        if (result.error) {
          throw new Error(result.error.message);
        }
        refetchPurchasedOffers();
      }
    },
    [user, dispatch, refetchPurchasedOffers]
  );

  const purchaseSubscription = useCallback(
    async (subscription?: ISubscriptionPlan | null, skipRefetch?: boolean, campaignSlug?: string) => {
      if (subscription && user) {
        const updateGiftedSubscription = Boolean(user.giftSubscriptionBuyerEmail && !user.enterpriseUser);
        const result: any = await dispatch(
          authApiActions.updateStripeSubscription({
            activeSubscription,
            newSubscription: subscription,
            updateGiftedSubscription,
            campaignSlug,
            user,
          })
        );
        if (result.error) {
          throw new Error(result.error.message);
        }
        if (!skipRefetch) {
          refetchMyProfile();
        }
      }
    },
    [refetchMyProfile, dispatch, activeSubscription, user]
  );

  const renewSubscription = useCallback(
    async (purchasedOffer?: IPurchasedOffer | null) => {
      if (purchasedOffer) {
        const result: any = await dispatch(offersApiActions.renewPurchasedOfferViaStripe(purchasedOffer));
        if (result.error) {
          throw new Error(result.error.message);
        }
        refetchPurchasedOffers();
        refetchOffers();
      }
    },
    [refetchOffers, refetchPurchasedOffers, dispatch]
  );

  const handlePay = useCallback(async () => {
    setAddCardVisible(false);
    try {
      if (purchaseRef.current?.subscription) {
        await purchaseSubscription(purchaseRef.current.subscription, true);
      }
      if (purchaseRef.current?.offers?.length) {
        await purchaseOffer(purchaseRef.current.offers);
      }
      if (purchaseRef.current?.renewPurchasedOffer) {
        await renewSubscription(purchaseRef.current.renewPurchasedOffer);
      }
      refetchMyProfile();
      promiseRef.current?.resolve?.();
      promiseRef.current = undefined;
    } catch (e: any) {
      promiseRef.current?.reject?.(e);
      promiseRef.current = undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renewSubscription, dispatch, purchaseSubscription, purchaseOffer, purchaseRef.current, promiseRef.current]);

  const renewPurchasedOffer = useCallback(
    async (purchasedOffer: IPurchasedOffer) => {
      if (user) {
        if (isOfferPaymentSystemInCompatible(purchasedOffer)) {
          showIncompatibleOfferPaymentPopup(user, purchasedOffer);
          return Promise.resolve();
        }
        purchaseRef.current = {renewPurchasedOffer: purchasedOffer};
        const promise = new Promise<void>((resolve, reject) => {
          promiseRef.current = {resolve, reject};
        });
        if (!user?.paymentMethodId) {
          setAddCardVisible(true);
        } else {
          await handlePay();
        }
        return promise;
      }
    },
    [handlePay, user]
  );

  const purchase = useCallback(
    async (subscription?: ISubscriptionPlan | null, offers?: IOffersNonNested[] | null) => {
      if (subscription && !canPurchaseSubscription(user) && !isSubscriptionExpired(user)) {
        showIncompatiblePaymentPopup(user!);
        return Promise.reject('Incompatible Payment Type');
      }
      if (!subscription && !offers?.length) {
        return Promise.resolve();
      }
      purchaseRef.current = {offers, subscription};
      const promise = new Promise<void>((resolve, reject) => {
        promiseRef.current = {resolve, reject};
      });
      if (!user?.paymentMethodId) {
        setAddCardVisible(true);
      } else {
        await handlePay();
      }
      return promise;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handlePay, user, purchaseSubscription, purchaseOffer, purchaseRef.current, promiseRef.current]
  );

  const handleCancel = useCallback(() => {
    setAddCardVisible(false);
    promiseRef.current?.reject?.();
    promiseRef.current = undefined;
  }, []);

  const children = useMemo(
    () => (
      <>
        {addCardVisible && (
          <AddCreditCardModal
            visible={addCardVisible}
            onCancel={handleCancel}
            onSubmit={handlePay}
            maskClosable={false}
          />
        )}
      </>
    ),
    [addCardVisible, handlePay, handleCancel]
  );

  const generatePaymentMethod = useCallback(
    async (value: StripeCardAddValue): Promise<StripeCardSubmitValue> => {
      if (value.error) {
        throw value.error;
      }
      if (!stripe || !elements) {
        throw new Error('Stripe is not initialized');
      }

      const stripeResult = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(StripeCardElement)!,
        billing_details: {
          name: value.fullName || '',
          email: value.email || '',
        },
      });

      if (stripeResult.error) {
        if (stripeResult.error.message && /Stripe/.test(stripeResult.error.message)) {
          const [formattedError] = stripeResult.error.message.split(';');
          throw new Error(formattedError);
        }
        throw new Error(stripeResult.error.message);
      }

      const paymentMethodId = stripeResult.paymentMethod!.id;

      return {
        ...value,
        paymentMethodId,
        stripeResult,
      } as StripeCardSubmitValue;
    },
    [stripe, elements]
  );

  return useMemo(
    () => ({
      renewPurchasedOffer,
      cancelPurchasedOffer,
      addPaymentMethod,
      purchaseSubscription,
      purchaseOffer,
      purchase,
      children,
      generatePaymentMethod,
    }),
    [
      renewPurchasedOffer,
      cancelPurchasedOffer,
      children,
      addPaymentMethod,
      purchaseSubscription,
      purchaseOffer,
      purchase,
      generatePaymentMethod,
    ]
  );
};

export default useStripePayment;
