import mem, {memClear} from 'mem';
import {clearRefreshTokenCache, clearStorageRefreshToken} from './refreshToken';
import {clearStorageExpireTime} from '@/features/auth/routes/Profile/utils/expireTime';

export const ACCESS_TOKEN_KEY = 'GD_ACCESS_TOKEN';

export const setAccessToken = (jwt: string) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, jwt);
};

export const memoizedGetAccessToken = () => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (accessToken) {
    return accessToken;
  }
};

export const clearStorage = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  clearStorageRefreshToken();
  clearAccessTokenCache();
  clearStorageExpireTime();
  clearRefreshTokenCache();
};

const memoAccessToken = mem(memoizedGetAccessToken);

export const getAccessToken = () => {
  const accessToken = memoAccessToken();
  if (accessToken) {
    return accessToken;
    // If there is no token, then clear cache and try again (this usually happens during login)
  } else {
    clearAccessTokenCache();
    return memoAccessToken();
  }
};

export const clearAccessTokenCache = () => {
  memClear(memoAccessToken);
};
