import {useInfiniteQuery, UseInfiniteQueryOptions} from 'react-query';
import {GrowthGroupFilters, GrowthGroupInstancePaginatedResponse, WorkshopQueryKey} from '../../../types/growth-groups';
import {axiosGrowthDay} from '../../../utils/api';
import {makeFilterSearchParams} from '../util/makeFilterSearchParams';
import {useMemo} from 'react';
import {sortBy} from 'lodash';

export const WorkshopUpNextQueryKey = [WorkshopQueryKey, 'Up-Next'] as const;
export const WorkshopUpNextQueryKeyWithFilters = (filters?: GrowthGroupFilters) =>
  [...WorkshopUpNextQueryKey, filters ?? {}] as const;
const defaultQueryKey = WorkshopUpNextQueryKeyWithFilters({});

export function useGetNextUpWorkshops(
  options: Omit<
    UseInfiniteQueryOptions<
      GrowthGroupInstancePaginatedResponse,
      unknown,
      GrowthGroupInstancePaginatedResponse,
      GrowthGroupInstancePaginatedResponse,
      typeof defaultQueryKey
    >,
    'queryFn' | 'queryKey'
  > & {filters?: GrowthGroupFilters} = {}
) {
  const {filters, ...rest} = options;
  const queryKeyWithFilters = WorkshopUpNextQueryKeyWithFilters(filters);

  const result = useInfiniteQuery(
    queryKeyWithFilters,
    async ({pageParam = 1}) => {
      const _result = await axiosGrowthDay('/groups/workshops', {
        params: {
          limit: 24,
          offset: pageParam,
          sortBy: 'startTime',
          order: 'asc',
          upcoming: true,
          includeKeynotes: true,
          ...makeFilterSearchParams(filters),
        },
      });

      const data = _result.data as Omit<GrowthGroupInstancePaginatedResponse, '__currentCountForPagination'>;
      // Data might be filtered to remove cancelled values, use current count for pagination
      return {
        ...data,
        __currentCountForPagination: data.growthGroupInstanceList?.length ?? 0,
      } as GrowthGroupInstancePaginatedResponse;
    },
    {
      getNextPageParam(lastPage, pages) {
        const loaded = pages.reduce((prev, current) => prev + current.__currentCountForPagination, 0);
        const total = lastPage.count ?? 0;

        return loaded < total ? pages.length + 1 : undefined;
      },
      keepPreviousData: true,
      staleTime: 30 * 1000,
      ...rest,
    }
  );

  return useMemo(() => {
    const workshops =
      sortBy(result.data?.pages.map((page) => page.growthGroupInstanceList ?? []).flat() ?? [], 'startTime') ?? [];
    return {...result, workshops};
  }, [result]);
}
