/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface UserTour
 */
export interface UserTour {
  /**
   *
   * @type {string}
   * @memberof UserTour
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof UserTour
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof UserTour
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof UserTour
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof UserTour
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof UserTour
   */
  trigger?: UserTourTriggerEnum;
  /**
   *
   * @type {string}
   * @memberof UserTour
   */
  triggerId?: string;
  /**
   *
   * @type {number}
   * @memberof UserTour
   */
  progress?: number;
  /**
   *
   * @type {string}
   * @memberof UserTour
   */
  triggerType?: UserTourTriggerTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof UserTour
   */
  started?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserTour
   */
  completed?: boolean;
}

export const UserTourTriggerEnum = {
  ChallengeLoad: 'ON_CHALLENGE_LOAD',
  ChallengeFirstTaskComplete: 'ON_CHALLENGE_FIRST_TASK_COMPLETE',
  ChallengeDayOneComplete: 'ON_CHALLENGE_DAY_ONE_COMPLETE',
  ChallengeDayTwoComplete: 'ON_CHALLENGE_DAY_TWO_COMPLETE',
  ChallengeDayThreeComplete: 'ON_CHALLENGE_DAY_THREE_COMPLETE',
  ChallengeDayFourComplete: 'ON_CHALLENGE_DAY_FOUR_COMPLETE',
  ChallengeDayFiveComplete: 'ON_CHALLENGE_DAY_FIVE_COMPLETE',
  DashboardLoad: 'ON_DASHBOARD_LOAD',
  EnterpriseDashboardLoad: 'ON_ENTERPRISE_DASHBOARD_LOAD',
} as const;

export type UserTourTriggerEnum = (typeof UserTourTriggerEnum)[keyof typeof UserTourTriggerEnum];
export const UserTourTriggerTypeEnum = {
  Challenge: 'CHALLENGE',
  Plan: 'PLAN',
  Offer: 'OFFER',
  Campaign: 'CAMPAIGN',
  EnterpriseAdmin: 'ENTERPRISE_ADMIN',
} as const;

export type UserTourTriggerTypeEnum = (typeof UserTourTriggerTypeEnum)[keyof typeof UserTourTriggerTypeEnum];
