import Button from 'antd/es/button';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import classNames from 'classnames';
import React, {FC} from 'react';
import {useHistory} from 'react-router';
import useAuthState from '../../../features/auth/hooks/useAuthState';
import classes from '../../../features/shell/components/Header/Header.module.less';
import LogoIcon from '../LogoIcon';
import GDTypography from '../../../shared/components/ui/Typography/Typography';
import Visible from '../../../shared/components/Visible';
import commonStyles from '../../../shared/style/commonStyles.module.less';
import {useLogout} from '../../../features/auth/components/beforeLogout/BeforeLogoutProvider';
import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';
import {useFeatureGate} from '@growthday/ui-core/src/features/feature-gate/hooks/useFeatureGate';
import {FeatureGateEnum} from '@growthday/ui-core/src/features/feature-gate/types';

const EmptyLayoutHeader: FC = () => {
  const {loading} = useAuthState();
  const {user} = useUser();
  const history = useHistory();
  const logout = useLogout();
  const webLoginDeeplinkEnabled = useFeatureGate(FeatureGateEnum.WebLoginDeeplink);
  const handleNavigateToLogin = () => history.push('/login');

  return (
    <Row
      justify="center"
      align="middle"
      className={classNames({
        [classes.container]: true,
        [classes.unauthenticated]: true,
      })}
    >
      <Col flex="0 0 190px">
        <div className={classNames(classes.logoLink, classes.centered)}>
          <LogoIcon background={'light'} className={classNames(classes.logo, 'empty-layout-header-logo')} />
        </div>
      </Col>
      <Col flex="1 1" className={classNames(classes.headerInfoCol, classes.centered)}>
        {user ? (
          <>
            <Button
              loading={loading.logout}
              type="default"
              onClick={logout}
              className={classNames('gd-logout-link', commonStyles.textMedium)}
            >
              Log out
            </Button>
          </>
        ) : (
          <>
            <Visible sm>
              <GDTypography type="w100">Already have an account?</GDTypography>
            </Visible>
            <Button
              type="primary"
              {...(webLoginDeeplinkEnabled ? {href: '/login'} : {onClick: handleNavigateToLogin})}
              className={classNames('gd-login-link', classes.loginButton)}
            >
              Sign In
            </Button>
          </>
        )}
      </Col>
    </Row>
  );
};

export default EmptyLayoutHeader;
