import {FC} from 'react';
import useGetUserMetadata from '@growthday/ui-core/src/features/usermetadata/useGetUserMetadata';
import {OrganizationResponsePaymentFlowEnum} from '@growthday/ui-core/src/types/api';
import Loading from '../../../shared/components/Loading';
import useAuthState from '../../auth/hooks/useAuthState';
import useOrganizationQuery from '@growthday/ui-core/src/features/enterprise/hooks/useOrganizationQuery';
import Terms from '../routes/terms/Terms';
import Payment from '../routes/payment/Payment';
import {useGetAuthData} from '../api/useGetAuthData';
import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';

const EnterpriseProvider: FC = ({children}) => {
  const {accessToken, loggedOut} = useAuthState();
  const {user} = useUser();
  const {data: userMetadata, isLoading: isMetadataLoading} = useGetUserMetadata(Boolean(user && accessToken));
  const {enabled, handleSuccessUpdateDate} = useGetAuthData();
  const {data: organization, isLoading: isOrganizationLoading} = useOrganizationQuery({
    enabled,
    onSuccess: handleSuccessUpdateDate,
  });

  if (isMetadataLoading || isOrganizationLoading) {
    return <Loading />;
  }

  if (user && !loggedOut && userMetadata?.showTransferAccountTerms) {
    return <Terms />;
  }

  if (
    user &&
    (!user.paymentMethodId || user.level === null) &&
    !loggedOut &&
    organization?.paymentFlow === OrganizationResponsePaymentFlowEnum.Employee
  ) {
    return <Payment />;
  }

  return <>{children}</>;
};

export default EnterpriseProvider;
