import {useQueryClient} from 'react-query';
import {useCallback, useMemo} from 'react';
import {GrowthGroupInstancePaginatedResponse} from '../../../types/growth-groups';
import {GrowthGroupInstance, Participant} from '../../../types/api';
import {UpNextQueryKey} from './useGetNextUpGroups';
import {ExploreQueryKey} from './useGetExploreGroups';
import {InfiniteData, QueryKey} from 'react-query/types/core/types';
import {produce} from 'immer';
import {merge, partition} from 'lodash';
import {GroupKeyWithSlug} from './useGetAllGroupsBySlug';
import {WorkshopUpNextQueryKey} from './useGetNextUpWorkshops';
import {GroupParticipantsKey} from '../hooks/useGetGroupParticipants';
import {GroupUserParticipantsKey} from '../hooks/useGetUserParticipants';
import {ParticipantFromSlugQueryKey, ParticipantFromSlugResponse} from './useGetParticipantFromSlug';

/**
 * Type representing the response of an infinite paginated group.
 */

export type InfinitePaginatedGroupResponse = InfiniteData<GrowthGroupInstancePaginatedResponse> | undefined;

/**
 * Custom hook to update growth group instances.
 * @returns {Object} An object containing the queryClient and updater function.
 */
export default function useUpdateGrowthGroupInstance() {
  const queryClient = useQueryClient();

  /**
   * Updates a growth group instance.
   * @param {Pick<GrowthGroupInstance, 'id' | 'slug'>} instance - The growth group instance to update.
   * @param {(old: GrowthGroupInstance) => GrowthGroupInstance} _updater - The updater function.
   */
  const updater = useCallback(
    (
      instance: Pick<GrowthGroupInstance, 'id' | 'slug'>,
      _updater: (old: GrowthGroupInstance) => GrowthGroupInstance
    ) => {
      const callback = (old: InfinitePaginatedGroupResponse): InfinitePaginatedGroupResponse => {
        if (!old) {
          return old;
        }
        return produce(old, (newData) => {
          newData.pages.forEach((page) => {
            page.growthGroupInstanceList?.forEach((oldGroup) => {
              if (oldGroup.id === instance.id) {
                merge(oldGroup, _updater(oldGroup));
              }
            });
          });
        });
      };

      queryClient.setQueriesData<InfinitePaginatedGroupResponse>(
        {queryKey: GroupKeyWithSlug(), inactive: true, active: true, fetching: false},
        callback
      );
      queryClient.setQueriesData<InfinitePaginatedGroupResponse>(
        {queryKey: UpNextQueryKey, inactive: true, active: true, fetching: false},
        callback
      );
      queryClient.setQueriesData<InfinitePaginatedGroupResponse>(
        {queryKey: ExploreQueryKey, inactive: true, active: true, fetching: false},
        callback
      );
      queryClient.setQueriesData<InfinitePaginatedGroupResponse>(
        {queryKey: WorkshopUpNextQueryKey, inactive: true, active: true, fetching: false},
        callback
      );
    },
    [queryClient]
  );
  return useMemo(() => ({queryClient, updater}), [queryClient, updater]);
}

/**
 * Callback function for updating group participants.
 * @param {(old: Participant[]) => void} updater - The updater function.
 * @returns {(old: Participant[] | undefined) => Participant[]} The callback function.
 */
const callbackGroupParticipants =
  (updater: (old: Participant[]) => void) =>
  (old: Participant[] | undefined): Participant[] => {
    if (!old) {
      return [];
    }
    return produce(old, updater);
  };

/**
 * Callback function for updating rsvp list for a given list
 * @param {string} slug - The group slug.
 * @param {(old: Participant[]) => void} updater - The updater function.
 * @returns {(old: Participant[] | undefined) => Participant[]} The callback function.
 */
const callbackUserParticipants =
  (slug: string, updater: (old: Participant[]) => void) =>
  (old: Participant[] | undefined): Participant[] => {
    if (!old) {
      return [];
    }
    const [sameGroupParticipants, differentGroupParticipants] = partition(
      old,
      (participant) => participant.slug === slug
    );
    return [...differentGroupParticipants, ...produce(sameGroupParticipants, updater)];
  };

/**
 * Callback function for updating participant from slug response.
 * @param {string} slug - The group slug.
 * @param {(old: Participant[]) => void} updater - The updater function.
 * @returns {(old: ParticipantFromSlugResponse | undefined) => ParticipantFromSlugResponse} The callback function.
 */
const callbackSlugParticipant =
  (slug: string, updater: (old: Participant[]) => void) =>
  (old: ParticipantFromSlugResponse | undefined): ParticipantFromSlugResponse => {
    if (!old) {
      return null;
    }
    return produce([old], updater)[0];
  };

/**
 * Custom hook to update growth group instance participants.
 * @returns {Object} An object containing the queryClient and updater function.
 */
export function useUpdateGrowthGroupInstanceParticipants() {
  const queryClient = useQueryClient();

  /**
   * Updates growth group instance participants.
   * @param {Pick<GrowthGroupInstance, 'groupId' | 'slug'>} instance - The growth group instance.
   * @param {(old: Participant[]) => void} _updaterGroupParticipants - The updater function for group participants.
   * @param {(old: Participant[]) => void} [_updaterUserParticipants] - The updater function for user participants.
   */
  const updater = useCallback(
    (
      instance: Pick<GrowthGroupInstance, 'groupId' | 'slug'>,
      _updaterGroupParticipants: (old: Participant[]) => void,
      _updaterUserParticipants?: (old: Participant[]) => void
    ) => {
      const groupParticipantQueryKey = GroupParticipantsKey(instance.groupId!, instance.slug) as QueryKey;
      const participantFromSlugQueryKey = ParticipantFromSlugQueryKey(instance.slug);

      if (queryClient.getQueryData(groupParticipantQueryKey)) {
        queryClient.setQueryData<Participant[]>(
          groupParticipantQueryKey,
          callbackGroupParticipants(_updaterGroupParticipants)
        );
      }

      if (queryClient.getQueryData(GroupUserParticipantsKey)) {
        queryClient.setQueryData<Participant[]>(
          GroupUserParticipantsKey,
          callbackUserParticipants(instance.slug!, _updaterUserParticipants || _updaterGroupParticipants)
        );
      }

      if (queryClient.getQueryData(participantFromSlugQueryKey)) {
        queryClient.setQueryData<ParticipantFromSlugResponse>(
          participantFromSlugQueryKey,
          callbackSlugParticipant(instance.slug, _updaterUserParticipants || _updaterGroupParticipants)
        );
      }
    },
    [queryClient]
  );

  return useMemo(() => ({queryClient, updater}), [queryClient, updater]);
}
