import plansApi, {completedPlansTagKey, plansTagKey} from '../plans.api';
import {
  CompleteAndRecoverInput,
  CompleteAndRecoverResponse,
  FlattenedPlans,
  MutationEndpointOptions,
} from '../../../types/plans';
import {FetchBaseQueryError} from '@reduxjs/toolkit/query';
import {endpoints} from '../endpoints';
import {formatDueAndCompletedDate} from '../utils/formatDueAndCompletedDate';
import dayjs from 'dayjs';
import {remindersApi} from '../../reminders/reminders.api';
import {reminderTags} from '../../reminders/reminders.constant';

function updateStore(plansMap: FlattenedPlans, id: string, complete: boolean) {
  const {records, plansArr} = plansMap;
  const isPlan = !records[id].parentID;
  if (isPlan) {
    // Will refetch completed plans via tags
    if (complete) {
      delete records[id];
      const index = plansArr.tasks.findIndex((t) => t === id);
      if (index === -1) return;
      plansArr.tasks.splice(index, 1);
    }
  } else {
    if (complete) {
      records[id].complete = true;
      records[id].completedDate = formatDueAndCompletedDate(dayjs());
    } else {
      records[id].complete = false;
      records[id].completedDate = null;
    }
  }
}

export const completeAndRecover: MutationEndpointOptions<CompleteAndRecoverInput, CompleteAndRecoverResponse> = {
  async queryFn(arg, api, extra, fetchWithBaseQuery) {
    const plansDataStore = plansApi.endpoints.getPlans.select()(api.getState() as any)!;
    if (plansDataStore.error) return {error: plansDataStore.error as FetchBaseQueryError};
    const {records} = plansDataStore.data!;
    const url = arg.parentPlanID
      ? records[records[arg.id].parentID]?.parentID
        ? `${arg.complete ? endpoints.completeSubTask : endpoints.recoverSubTask}/${arg.parentPlanID}/${arg.id}`
        : `${arg.complete ? endpoints.completeTask : endpoints.recoverTask}/${arg.parentPlanID}/${arg.id}`
      : `${arg.complete ? endpoints.completePlan : endpoints.recoverPlan}/${arg.id}`;
    const {error} = await fetchWithBaseQuery({
      url,
      method: 'PUT',
    });
    if (error) return {error};
    return {
      data: {
        isPlan: !records[arg.id] || !records[arg.id].parentID,
        complete: arg.complete,
      },
    };
  },
  // If a plan is marked as complete - refetch completed plans
  // If a plan is recovered - refetch completed plans and plan list
  invalidatesTags: (result) =>
    result && result.isPlan ? (result.complete ? [completedPlansTagKey] : [completedPlansTagKey, plansTagKey]) : [],
  async onQueryStarted(arg, api) {
    const patch = api.dispatch(
      plansApi.util.updateQueryData('getPlans', undefined, (draft) => {
        draft.records[arg.id] && updateStore(draft, arg.id, arg.complete);
      })
    );
    api.queryFulfilled
      .then(() => {
        api.dispatch(remindersApi.util.invalidateTags([reminderTags.type, reminderTags.list()]));
      })
      .catch(patch.undo);
  },
};
