import classNames from 'classnames';
import classes from './ErrorPage.module.less';
import GDTypography from '../../../../shared/components/ui/Typography/Typography';
import React, {FC} from 'react';
import {getStaticAssetUrl} from '@/shared/util/get-static-asset-url';
import {rebrandTextImperatively} from '@/features/feature-disable/hooks/useRebrandText';

const hpXIcon = getStaticAssetUrl('logo', 'logo-error-page.svg');

type ErrorPageProps = {
  errorCode?: number | string;
  className?: string;
  contentClassName?: string;
};

const ErrorPage: FC<ErrorPageProps> = ({errorCode, children, className, contentClassName}) => {
  return (
    <div className={classes.container}>
      <div className={classNames(classes.errorContainer, 'error-page-container', className)}>
        <div className={classNames(classes.errorLeftOutline, 'error-page-left-outline')} />
        <div className={classNames(classes.errorContent, contentClassName)}>
          <GDTypography type="w900" className={classNames(classes.errorCode, 'error-page-code')}>
            {errorCode}
          </GDTypography>
          {children}
        </div>
        <img className={classes.footerIcon} src={hpXIcon} alt={`${rebrandTextImperatively('productName')}`} />
      </div>
    </div>
  );
};

export default ErrorPage;
