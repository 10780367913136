import {SubscriptionIntervalsEnum} from '../../../../../shared/services_deprecated/enums';
import {ISubscriptionPlan} from '../../../../auth/interfaces';

/**
 * Converts a subscription interval to a numeral value.
 *
 * @param interval - The subscription interval as a `SubscriptionIntervalsEnum` value.
 * @returns A numeral representing the subscription interval: 12 for yearly, 1 for monthly.
 */
function intervalToNumeral(interval: SubscriptionIntervalsEnum) {
  return interval.toUpperCase() === SubscriptionIntervalsEnum.YEAR.toUpperCase() ? 12 : 1;
}

/**
 * Compares two subscription plans to determine if new plan can be considered as an upgrade.
 *
 * This function evaluates new subscription plan with active subscription plan based on
 * certain criteria:
 * - If the active subscription is not provided, it returns `true`.
 * - If the active subscription is deprecated and the new subscription is not, it returns `true`.
 * - If both subscriptions have the same level weight, it then compares them based on their intervals
 *   through `intervalToNumeral` function.
 * - If the new subscription has a greater level weight, it returns `true`.
 *
 * @param subscriptionPlan - The `ISubscriptionPlan` to compare.
 * @param activeSubscription - The current active `ISubscriptionPlan`, optional.
 * @param skipInterval - Param to skip interval check for same level plans, optional
 * @returns A boolean value indicating whether the new subscription plan can be considered an an upgrade.
 */
function compareSubscription(
  subscriptionPlan: ISubscriptionPlan,
  activeSubscription?: ISubscriptionPlan,
  skipInterval?: boolean
): boolean | null {
  if (!activeSubscription) return true;
  if (activeSubscription.deprecated && !subscriptionPlan.deprecated) return true;
  if (!skipInterval && subscriptionPlan.levelWeight === activeSubscription.levelWeight) {
    return intervalToNumeral(subscriptionPlan.interval) > intervalToNumeral(activeSubscription.interval);
  }
  return (subscriptionPlan.levelWeight ?? 0) > (activeSubscription.levelWeight ?? 0);
}

export default compareSubscription;
