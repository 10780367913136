import {CustomIconComponentProps} from '@ant-design/icons/lib/components/Icon';

export type GDIconProps = {} & Partial<CustomIconComponentProps>;

export type PlatformInformation = {
  advertisingId?: string;
  appStore?: string;
  appVersionName?: string;
  appsflyerId?: string;
  att?: number;
  bundleIdentifier?: string;
  idfa?: string;
  idfv?: string;
  imei?: string;
  oaid?: string;
  platform?: Platform;
  appsflyerWebId?: string;
  appsflyerAndroidId?: string;
  appsflyerIOSId?: string;
};

export enum Platform {
  WEB = 'WEB',
  ANDROID = 'ANDROID',
  IOS = 'IOS',
}

export enum notesTypes {
  LIVE = 'Live',
  LESSON = 'Lesson',
  CHALLENGE = 'Challenge',
  GROWTH_GROUP = 'Growth_Group',
}
