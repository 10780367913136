import {useQuery, UseQueryOptions} from 'react-query';
import {GrowthGroupQueryKey} from '../../../types/growth-groups';
import {axiosGrowthDay} from '../../../utils/api';
import {GrowthGroupInstance, Participant} from '../../../types/api';
import {useAuthData} from '../../../hooks/useAuthData';

export const ParticipantFromSlugQueryKey = (slug: GrowthGroupInstance['slug'] | null | undefined) => [
  GrowthGroupQueryKey,
  'Participants',
  'Slug',
  slug,
];
export type ParticipantFromSlugResponse = Participant | null;

export function useGetParticipantFromSlug(
  slug: GrowthGroupInstance['slug'] | null | undefined,
  options: Omit<
    UseQueryOptions<
      ParticipantFromSlugResponse,
      unknown,
      ParticipantFromSlugResponse,
      ReturnType<typeof ParticipantFromSlugQueryKey>
    >,
    'queryFn' | 'queryKey'
  > = {}
) {
  const {enabled, ...rest} = options;
  const {isLoggedIn} = useAuthData();
  return useQuery(
    ParticipantFromSlugQueryKey(slug),
    async () => {
      const response = await axiosGrowthDay.get(`/groups/participant`, {
        params: {
          slug,
        },
      });
      return response.data as ParticipantFromSlugResponse;
    },
    {enabled: !!slug && isLoggedIn && enabled, ...rest}
  );
}
