import {axiosGrowthDay, axiosPublicStrapi} from '../index';
import {UserRequest} from '../../services_deprecated/model/userRequest';
import {IUser} from '../../services_deprecated/model/user';
import {TriggerInfo} from '../../../features/auth/interfaces';
import {ISignupPage} from '@growthday/ui-core/src/types/strapi';
import {LoginResponse} from '../../services_deprecated/model/loginResponse';

export const registerApi = {
  register: (request: UserRequest): Promise<LoginResponse> => {
    return axiosGrowthDay.post('/register', request).then((res) => res.data);
  },
  registerPayment: (userRequest: UserRequest, triggerInfo?: TriggerInfo) => {
    const params: Record<string, string | boolean> = {};
    if (triggerInfo) {
      params.trigger = triggerInfo.triggerFlow ?? '';
      params.button = triggerInfo.triggeredFromSection ?? '';
      params.content = triggerInfo.triggeredContentName ?? '';
      params.mixpanel = true;
    }
    return axiosGrowthDay.post<IUser>(`/register/registerPayment`, userRequest, {params}).then((res) => res.data);
  },
  getSignupPageContent: () => {
    return axiosPublicStrapi.get<ISignupPage>('signup-page').then((res) => res.data);
  },
};
