import useGDBreakpoint from '@/shared/hooks/useBreakpoint';
import classNames from 'classnames';
import React, {FC, ReactNode} from 'react';
import GDTypography from '../../../../../../shared/components/ui/Typography/Typography';
import useScrollToTop from '../../../../../../shared/hooks/useScrollToTop';
import styles from './Step.module.less';

export function getOrder(order: string[] = [], input: string) {
  if (order?.length && order?.includes(input)) {
    return order.indexOf(input) + 1;
  }
  return order?.length ? order.length + 1 : undefined;
}

type StepProps = {
  step?: number;
  title: ReactNode;
  preview?: ReactNode;
  titleClassName?: string;
  totalSteps?: number;
  fullWidth?: boolean;
  terms?: ReactNode;
  order?: string[];
};

const Step: FC<StepProps> = ({
  order,
  terms,
  fullWidth,
  totalSteps = 3,
  preview,
  step,
  title,
  titleClassName,
  children,
}) => {
  useScrollToTop({smooth: true});
  useScrollToTop({smooth: true, el: document.querySelector('#scroll-content > *')});
  const breakpoint = useGDBreakpoint();
  const mobileView = !breakpoint.md;

  return (
    <div className={classNames(styles.container, fullWidth && styles.fullWidthContainer)}>
      {!mobileView && preview && <div className={styles.previewContainer}>{preview}</div>}
      <div className={classNames(styles.details, fullWidth && styles.fullWidthDetails)}>
        {step && totalSteps > 1 && (
          <GDTypography
            style={{order: getOrder(order, 'STEPS')}}
            type="w300"
            className={classNames(styles.stepItem, styles.subTitle, fullWidth && styles.fullWidthSubTitle)}
          >
            Step {step} of {totalSteps}
          </GDTypography>
        )}
        <GDTypography
          style={{order: getOrder(order, 'HEADING')}}
          type="w800"
          subType="s"
          className={classNames(titleClassName, styles.stepItem, styles.title, fullWidth && styles.fullWidthTitle)}
        >
          {title}
        </GDTypography>
        {children}
        {mobileView && preview}
      </div>
      {terms && <div className={styles.terms}>{terms}</div>}
    </div>
  );
};

export default Step;
