/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface UserNote
 */
export interface UserNote {
  /**
   *
   * @type {string}
   * @memberof UserNote
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof UserNote
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof UserNote
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof UserNote
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof UserNote
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof UserNote
   */
  entityType?: UserNoteEntityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof UserNote
   */
  entityId?: string;
  /**
   *
   * @type {string}
   * @memberof UserNote
   */
  subEntityId?: string;
  /**
   *
   * @type {string}
   * @memberof UserNote
   */
  content?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserNote
   */
  pinned?: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof UserNote
   */
  notePhotos?: {[key: string]: string};
  /**
   *
   * @type {number}
   * @memberof UserNote
   */
  timeCode?: number;
  /**
   *
   * @type {boolean}
   * @memberof UserNote
   */
  textNote?: boolean;
}

export const UserNoteEntityTypeEnum = {
  GrowthGroup: 'GROWTH_GROUP',
  LiveEvent: 'LIVE_EVENT',
  Course: 'COURSE',
  Challenge: 'CHALLENGE',
} as const;

export type UserNoteEntityTypeEnum = (typeof UserNoteEntityTypeEnum)[keyof typeof UserNoteEntityTypeEnum];
