/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {BaseEntity} from './base-entity';

/**
 *
 * @export
 * @interface ChallengeAssociatedRecord
 */
export interface ChallengeAssociatedRecord {
  /**
   *
   * @type {string}
   * @memberof ChallengeAssociatedRecord
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof ChallengeAssociatedRecord
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof ChallengeAssociatedRecord
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof ChallengeAssociatedRecord
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof ChallengeAssociatedRecord
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof ChallengeAssociatedRecord
   */
  userChallengeId: string;
  /**
   *
   * @type {number}
   * @memberof ChallengeAssociatedRecord
   */
  challengeId?: number;
  /**
   *
   * @type {number}
   * @memberof ChallengeAssociatedRecord
   */
  customChallengeId?: number;
  /**
   *
   * @type {number}
   * @memberof ChallengeAssociatedRecord
   */
  customTaskId?: number;
  /**
   *
   * @type {string}
   * @memberof ChallengeAssociatedRecord
   */
  recordObjectId: string;
  /**
   *
   * @type {string}
   * @memberof ChallengeAssociatedRecord
   */
  type: ChallengeAssociatedRecordTypeEnum;
  /**
   *
   * @type {BaseEntity}
   * @memberof ChallengeAssociatedRecord
   */
  record?: BaseEntity;
}

export const ChallengeAssociatedRecordTypeEnum = {
  Challenge: 'CHALLENGE',
  Journal: 'JOURNAL',
  Goal: 'GOAL',
  Plan: 'PLAN',
} as const;

export type ChallengeAssociatedRecordTypeEnum =
  (typeof ChallengeAssociatedRecordTypeEnum)[keyof typeof ChallengeAssociatedRecordTypeEnum];
