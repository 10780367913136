/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {Achievement} from './achievement';
// May contain unused imports in some cases
// @ts-ignore
import {CmsChallenge} from './cms-challenge';

/**
 *
 * @export
 * @interface UserChallenge
 */
export interface UserChallenge {
  /**
   *
   * @type {string}
   * @memberof UserChallenge
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof UserChallenge
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof UserChallenge
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof UserChallenge
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof UserChallenge
   */
  uuid: string;
  /**
   *
   * @type {number}
   * @memberof UserChallenge
   */
  targetStartTs?: number;
  /**
   *
   * @type {string}
   * @memberof UserChallenge
   */
  status: UserChallengeStatusEnum;
  /**
   *
   * @type {string}
   * @memberof UserChallenge
   */
  challengeExpirationStatus: UserChallengeChallengeExpirationStatusEnum;
  /**
   *
   * @type {number}
   * @memberof UserChallenge
   */
  challengeId?: number;
  /**
   *
   * @type {number}
   * @memberof UserChallenge
   */
  targetEndTs?: number;
  /**
   *
   * @type {number}
   * @memberof UserChallenge
   */
  completedTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof UserChallenge
   */
  noteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof UserChallenge
   */
  type: UserChallengeTypeEnum;
  /**
   *
   * @type {number}
   * @memberof UserChallenge
   */
  remainingDays?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof UserChallenge
   */
  entriesDates?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof UserChallenge
   */
  numberOfEntries?: number;
  /**
   *
   * @type {number}
   * @memberof UserChallenge
   */
  coinsEarned?: number;
  /**
   *
   * @type {string}
   * @memberof UserChallenge
   */
  challengeNote?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserChallenge
   */
  pinned?: boolean;
  /**
   *
   * @type {{ [key: string]: Array<number>; }}
   * @memberof UserChallenge
   */
  userCustomChallenges?: {[key: string]: Array<number>};
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof UserChallenge
   */
  challengeNotePhotos?: {[key: string]: string};
  /**
   *
   * @type {number}
   * @memberof UserChallenge
   */
  utcExpirationTimestamp?: number;
  /**
   *
   * @type {boolean}
   * @memberof UserChallenge
   */
  noteMigrated?: boolean;
  /**
   *
   * @type {CmsChallenge}
   * @memberof UserChallenge
   */
  cmsChallenge?: CmsChallenge;
  /**
   *
   * @type {boolean}
   * @memberof UserChallenge
   */
  userChallengeEnded?: boolean;
  /**
   *
   * @type {number}
   * @memberof UserChallenge
   */
  tasksCompletedCount?: number;
  /**
   *
   * @type {number}
   * @memberof UserChallenge
   */
  coinsEarnedThisTime?: number;
  /**
   *
   * @type {Achievement}
   * @memberof UserChallenge
   */
  achievement?: Achievement;
  /**
   *
   * @type {string}
   * @memberof UserChallenge
   */
  joinDate?: string;
  /**
   *
   * @type {number}
   * @memberof UserChallenge
   */
  currentCoinCount?: number;
  /**
   *
   * @type {boolean}
   * @memberof UserChallenge
   */
  expired?: boolean;
}

export const UserChallengeStatusEnum = {
  InProgress: 'IN_PROGRESS',
  Completed: 'COMPLETED',
  Left: 'LEFT',
  Closed: 'CLOSED',
} as const;

export type UserChallengeStatusEnum = (typeof UserChallengeStatusEnum)[keyof typeof UserChallengeStatusEnum];
export const UserChallengeChallengeExpirationStatusEnum = {
  InProgress: 'IN_PROGRESS',
  Completed: 'COMPLETED',
  Left: 'LEFT',
  Closed: 'CLOSED',
  Na: 'NA',
} as const;

export type UserChallengeChallengeExpirationStatusEnum =
  (typeof UserChallengeChallengeExpirationStatusEnum)[keyof typeof UserChallengeChallengeExpirationStatusEnum];
export const UserChallengeTypeEnum = {
  Journal: 'Journal',
  DailyHabit: 'DailyHabit',
  WeeklyHabit: 'WeeklyHabit',
  MonthlyHabit: 'MonthlyHabit',
  Custom: 'Custom',
} as const;

export type UserChallengeTypeEnum = (typeof UserChallengeTypeEnum)[keyof typeof UserChallengeTypeEnum];
