import {FeatureGateProvider} from '@/features/feature-gate/providers/FeatureGateProvider';
import {HMSRoomProvider} from '@100mslive/react-sdk';
import {i18n} from '@growthday/ui-core/src/locales/i18n';
import React from 'react';
import ReactDOM from 'react-dom';
import {I18nextProvider} from 'react-i18next';
import {BrowserRouter as Router} from 'react-router-dom';
import {LastLocationProvider} from 'react-router-last-location';
import 'ts-replace-all';
import App from './App';

import StripeProvider from './features/license/providers/StripeProvider';
import './index.less';
import '@customization';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <FeatureGateProvider>
      <Router>
        <LastLocationProvider>
          <HMSRoomProvider isHMSStatsOn>
            <StripeProvider>
              <App />
            </StripeProvider>
          </HMSRoomProvider>
        </LastLocationProvider>
      </Router>
    </FeatureGateProvider>
  </I18nextProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
