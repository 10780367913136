import {useEffect, useRef} from 'react';
import {useWebSocketContext} from '../Provider/WebSocketProvider';
import {hashQueryKey, QueryKey} from 'react-query';
import {nanoid} from 'nanoid';

export const useWebSocket = <T = any>(key: QueryKey, topic: string, callback: (data: T) => void, enabled?: boolean) => {
  const {unsubscribe, subscribe, connected} = useWebSocketContext();
  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  const callbackId = useRef('');
  if (!callbackId.current) {
    callbackId.current = `${hashQueryKey(key)}_${nanoid()}`;
  }

  useEffect(() => {
    if (enabled) {
      subscribe<T>(callbackId.current, topic, (data) => {
        callbackRef.current(data);
      });
      return () => {
        // TODO: think about how to make this work
        unsubscribe(callbackId.current, topic);
      };
    }
  }, [enabled, topic, connected, subscribe, unsubscribe]);
};
