/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface PostAssessmentCreationMessage
 */
export interface PostAssessmentCreationMessage {
  /**
   *
   * @type {string}
   * @memberof PostAssessmentCreationMessage
   */
  link?: PostAssessmentCreationMessageLinkEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof PostAssessmentCreationMessage
   */
  messageLines?: Array<string>;
}

export const PostAssessmentCreationMessageLinkEnum = {
  Empty: '',
  DailyScoreHistory: 'DailyScoreHistory',
  HabitBuilder: 'HabitBuilder',
  WeeklyScoreHistory: 'WeeklyScoreHistory',
  Plan: 'Plan',
  MonthlyScoreHistory: 'MonthlyScoreHistory',
} as const;

export type PostAssessmentCreationMessageLinkEnum =
  (typeof PostAssessmentCreationMessageLinkEnum)[keyof typeof PostAssessmentCreationMessageLinkEnum];
