import {useQuery, UseQueryOptions} from 'react-query';
import {keyBy, mapValues} from 'lodash';
import {ConfigResponse, ConfigResponseFeaturesEnum} from '@growthday/ui-core/src/types/api/config-response';
import {axiosGrowthDay} from '@/shared/api';
export const GetFeaturesQueryKey = ['features-toggle'] as const;

export type GetFeaturesReturnType = {
  featureMap: Record<ConfigResponseFeaturesEnum, boolean>;
  menuItems: NonNullable<ConfigResponse['menuItems']>;
};

export type GetFeaturesQueryKeyType = typeof GetFeaturesQueryKey;
export function useGetFeatures(
  options: UseQueryOptions<GetFeaturesReturnType, unknown, GetFeaturesReturnType, GetFeaturesQueryKeyType> = {}
) {
  return useQuery<GetFeaturesReturnType, unknown, GetFeaturesReturnType, GetFeaturesQueryKeyType>(
    GetFeaturesQueryKey,
    async () => {
      const response = await axiosGrowthDay('/config');
      const data = response.data as ConfigResponse;

      const featureMapRecord = mapValues(keyBy(data.features ?? []), () => true);

      // Return false if a key is not present
      // Otherwise return true
      const featureMap = new Proxy(featureMapRecord, {
        get(...args) {
          return Reflect.get(...args) || false;
        },
      }) as GetFeaturesReturnType['featureMap'];

      const menuItems = data.menuItems ?? [];
      // Todo: Move icons to AWS
      // Promise.allSettles does not work on react-native
      // await Promise.allSettled(menuItems.map((item) => imageLoadedPromise(item.icon)));

      return {featureMap, menuItems};
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      ...options,
    }
  );
}
