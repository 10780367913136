import {AchievementInfo} from '@growthday/ui-core/src/types/achievements';
import {createSlice} from '@reduxjs/toolkit';
import {hideAchievementCelebration, showAchievementCelebration} from './achievements.action';

const initialState: {achievement: null | AchievementInfo} = {
  achievement: null,
};

const achievementSlice = createSlice({
  name: 'achievements',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(showAchievementCelebration, (state, action) => {
        state.achievement = action.payload ?? null;
      })
      .addCase(hideAchievementCelebration, (state) => {
        state.achievement = null;
      });
  },
});

export default achievementSlice.reducer;
