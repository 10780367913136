import {IAccess} from './access';
import {IFile} from './file';
import {IKeynoteModerator} from './keynote-moderator';
import {IMedia} from './media';
import {INotification} from './notification';
import {IVideoOverlay} from './video-overlay';

/**
 * Model definition for Keynote
 */
export interface IKeynote {
  id: number;
  title: string;
  type: EnumKeynoteType;
  channel: EnumKeynoteChannel;
  agenda: string;
  date: string;
  time: string;
  duration: number;
  slug: string;
  banner: IFile;
  endTime?: string;
  liveMedia?: IMedia;
  replayMedia?: IMedia;
  state: EnumKeynoteState;
  liveAccess?: IAccess;
  replayAccess?: IAccess;
  keynote_moderators?: IKeynoteModerator[];
  endedBanner?: IFile;
  replayBanner?: IFile;
  eventSlug?: string;
  rsvpNotification?: INotification;
  reminderNotification?: INotification;
  videoOverlay?: IVideoOverlay;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumKeynoteType {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export enum EnumKeynoteChannel {
  VIDEO = 'VIDEO',
}

export enum EnumKeynoteState {
  STARTS_IN_FUTURE = 'STARTS_IN_FUTURE',
  LIVE = 'LIVE',
  ENDED = 'ENDED',
  REPLAY = 'REPLAY',
  REDIRECT_TO_EVENT = 'REDIRECT_TO_EVENT',
}
