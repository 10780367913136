import {ICommonShare} from './common-share';
import {ICustomChallenge} from './custom-challenge';
import {IFile} from './file';
import {ILicenses} from './licenses';
import {ITier} from './tier';
import {ITooltips} from './tooltips';
import {IWebhooks} from './webhooks';

/**
 * Model definition for challenge
 */
export interface IChallenges {
  id: number;
  title?: string;
  description?: string;
  startDate: string;
  endDate: string;
  type?: EnumChallengesType;
  customChallenge: ICustomChallenge[];
  tiers: ITier[];
  shortDescription?: string;
  customIcon?: IFile;
  titleUid?: string;
  evergreenChallenge?: boolean;
  isFree?: boolean;
  signupImage?: IFile;
  licenses?: ILicenses;
  tooltips: ITooltips[];
  challengeDuration?: number;
  dashboardThumbnail?: IFile;
  dashboardThumbnailVertical?: IFile;
  signupDescription?: string;
  signupVideoUrl?: string;
  welcomeVideoUrl?: string;
  welcomeImage?: IFile;
  welcomeDescription?: string;
  welcomeTitle?: string;
  welcomePrimaryButtonText?: string;
  signupHeading?: string;
  completedBadge?: IFile;
  shareContent?: ICommonShare;
  upgradeTitle?: string;
  upgradeSubtitle?: string;
  upgradeDescription?: string;
  upgradeOffer?: EnumChallengesUpgradeOffer;
  upgradeButtonText?: string;
  webhooks: IWebhooks[];
  searchThumbnail?: IFile;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumChallengesType {
  Journal = 'Journal',
  DailyHabit = 'DailyHabit',
  Custom = 'Custom',
  MonthlyHabit = 'MonthlyHabit',
  WeeklyHabit = 'WeeklyHabit',
}

export enum EnumChallengesUpgradeOffer {
  BASIC = 'BASIC',
  MASTERY = 'MASTERY',
  ALL_ACCESS = 'ALL_ACCESS',
}
