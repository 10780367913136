/**
 * Model definition for fields
 */
export interface IFields {
  id: number;
  label?: string;
  type?: EnumFieldsType;
  variableName?: string;
  required?: boolean;
  validationMessage?: string;
  order?: number;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumFieldsType {
  EMAIL = 'EMAIL',
  CONTACT = 'CONTACT',
  PAYMENT = 'PAYMENT',
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  FULL_NAME = 'FULL_NAME',
  PASSWORD = 'PASSWORD',
}
