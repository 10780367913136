import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';
import useAuthState from './useAuthState';
import {useDynamicConfig} from '@growthday/ui-core/src/features/feature-gate/hooks/useDynamicConfig';
import {DynamicConfigEnum, SignupConfigDynamicConfig} from '@growthday/ui-core/src/features/feature-gate/types';

export const useCheckUserShouldCompleteProfile = () => {
  const dynamicConfig = useDynamicConfig<SignupConfigDynamicConfig>(DynamicConfigEnum.SignupConfig);
  const {signupUrl} = dynamicConfig?.config?.value ?? {};
  const {accessToken} = useAuthState();
  const {user} = useUser();
  const shouldCompleteSignup = !user?.partnerId && !user?.fullName && user?.forcePasswordChange;

  if (accessToken && shouldCompleteSignup) {
    window.location.href = signupUrl;
  }
};
