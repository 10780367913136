import {EffectCallback, useEffect} from 'react';
import useStableCallback from '@growthday/ui-core/src/hooks/useStableCallback';

export default function useEffectOnMount(effect: EffectCallback) {
  const stableCallback = useStableCallback(effect);

  useEffect(() => {
    stableCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
