import {NotificationsMenuEmptyIcon} from '@/features/shell/components/icons/NotificationsMenuIcon/NotificationsMenuIcon';
import {Divider} from 'antd';
import React, {FC} from 'react';
import useMenuItems from '../../../features/shell/hooks/useMenuItems';
import {TMenuProps} from '../../../features/shell/components/Menu/Menu.interface';
import styles from '../../../features/shell/components/Menu/Menu.module.less';
import MenuItem from '../../../features/shell/components/Menu/MenuItem';

const EmptyLayoutMenu: FC<TMenuProps> = ({expanded, wrapTitle}) => {
  const isExpanded = expanded && !wrapTitle;
  const {bottomMenuItems, topMenuItems} = useMenuItems();

  return (
    <div className={styles.root}>
      <div className={styles.topCol}>
        {topMenuItems.map((item) => (
          <MenuItem key={item.title} expanded={expanded} wrapTitle={wrapTitle} {...item} disabled />
        ))}
      </div>
      <div className={styles.bottomCol}>
        {bottomMenuItems.map((item) => (
          <MenuItem key={item.title} expanded={isExpanded} wrapTitle={wrapTitle} {...item} disabled />
        ))}
        <Divider className={styles.divider} />
        <MenuItem
          expanded={isExpanded}
          wrapTitle={wrapTitle}
          Icon={NotificationsMenuEmptyIcon}
          title="Notifications"
          disabled
        />
      </div>
    </div>
  );
};

export default EmptyLayoutMenu;
