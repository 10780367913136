/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {PlatformInformation} from './platform-information';

/**
 *
 * @export
 * @interface CodeForTokenRequest
 */
export interface CodeForTokenRequest {
  /**
   *
   * @type {string}
   * @memberof CodeForTokenRequest
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof CodeForTokenRequest
   */
  redirectUri?: string;
  /**
   *
   * @type {number}
   * @memberof CodeForTokenRequest
   */
  utcOffsetInMinutes?: number;
  /**
   *
   * @type {PlatformInformation}
   * @memberof CodeForTokenRequest
   */
  platformInformation?: PlatformInformation;
  /**
   *
   * @type {number}
   * @memberof CodeForTokenRequest
   */
  signupId?: number;
  /**
   *
   * @type {number}
   * @memberof CodeForTokenRequest
   */
  signupChallengeId?: number;
  /**
   *
   * @type {string}
   * @memberof CodeForTokenRequest
   */
  signupType?: CodeForTokenRequestSignupTypeEnum;
  /**
   *
   * @type {number}
   * @memberof CodeForTokenRequest
   */
  signupLiveEventId?: number;
  /**
   *
   * @type {number}
   * @memberof CodeForTokenRequest
   */
  signupOfferId?: number;
  /**
   *
   * @type {string}
   * @memberof CodeForTokenRequest
   */
  trigger?: string;
  /**
   *
   * @type {Set<string>}
   * @memberof CodeForTokenRequest
   */
  roles?: Set<string>;
  /**
   *
   * @type {string}
   * @memberof CodeForTokenRequest
   */
  ianaTimezone?: string;
  /**
   *
   * @type {number}
   * @memberof CodeForTokenRequest
   */
  signupPlanId?: number;
  /**
   *
   * @type {number}
   * @memberof CodeForTokenRequest
   */
  webhookId?: number;
  /**
   *
   * @type {string}
   * @memberof CodeForTokenRequest
   */
  playerId?: string;
}

export const CodeForTokenRequestSignupTypeEnum = {
  Challenge: 'CHALLENGE',
  Offer: 'OFFER',
  LiveEvent: 'LIVE_EVENT',
  Plan: 'PLAN',
} as const;

export type CodeForTokenRequestSignupTypeEnum =
  (typeof CodeForTokenRequestSignupTypeEnum)[keyof typeof CodeForTokenRequestSignupTypeEnum];
