import {
  GrowthGroupInstanceTypeEnum,
  ParticipantRoleEnum,
  Participant,
  GrowthGroupInstance,
  UserStripeConnectSetting,
} from './api';
import {IVideoOverlay} from './strapi/video-overlay';

export interface GrowthGroupInstanceStatusTopic {
  eventType: GrowthGroupStatusTopicEventType;
  id: string;
  data: any;
}

export interface GrowthGroupInstanceInitialStatusTopic {
  statusType: Extract<
    GrowthGroupStatusTopicEventType,
    | GrowthGroupStatusTopicEventType.LIVE_NOW
    | GrowthGroupStatusTopicEventType.LIVE_NOW_FULL
    | GrowthGroupStatusTopicEventType.CREATED
    | GrowthGroupStatusTopicEventType.CANCELLED
    | GrowthGroupStatusTopicEventType.STARTS_SOON
    | GrowthGroupStatusTopicEventType.ENDED
  >;
  id: string;
  data: any;
}

export enum GrowthGroupStatusTopicEventType {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  RSVP = 'RSVP',
  RSVP_DELETED = 'RSVP_DELETED',
  LIVE_NOW = 'LIVE_NOW',
  LIVE_NOW_FULL = 'LIVE_NOW_FULL',
  LIVE_NOW_VACANT = 'LIVE_NOW_VACANT',
  STARTS_SOON = 'STARTS_SOON', //  sent during backstage
  CANCELLED = 'CANCELLED',
  ENDED = 'ENDED',
  ROLES_CHANGED = 'ROLES_CHANGED',
  ADMIT_ALL = 'ADMIT_ALL',
}

export interface GrowthGroupStatusTopicData {
  participantRoles?: Array<{
    role: ParticipantRoleEnum;
    uuid: string;
  }>;
  type: GrowthGroupInstanceTypeEnum | null;
  participant?: Participant;
  startTime?: number;
  actualStartTime?: number;
  joinCount?: number;
  joinLimit?: number;
  actualEndTime?: number;
  backstageOpen?: boolean;
  admitAll?: boolean;
}

type GroupCollectionInstancesBaseInfo = Pick<GrowthGroupInstance, 'id' | 'slug'>;

type GroupStatusFullOrVacantPayload = {joinCount: number; joinLimit: number};

export type GroupCollectionInstancesField<EventType extends GrowthGroupStatusTopicEventType> =
  EventType extends GrowthGroupStatusTopicEventType.UPDATED
    ? Pick<GrowthGroupInstance, 'agenda' | 'title' | 'duration'> & {keynoteVideoOverlay?: IVideoOverlay}
    : EventType extends GrowthGroupStatusTopicEventType.RSVP
      ? {participant: Participant}
      : EventType extends GrowthGroupStatusTopicEventType.RSVP_DELETED
        ? {participant: Pick<Participant, 'uuid'>}
        : EventType extends GrowthGroupStatusTopicEventType.STARTS_SOON
          ? {backstageOpen: boolean} & Pick<GrowthGroupInstance, 'actualStartTime'>
          : EventType extends GrowthGroupStatusTopicEventType.ROLES_CHANGED
            ? {
                participantRoles: Array<Pick<Participant, 'uuid' | 'role'>>;
              }
            : EventType extends GrowthGroupStatusTopicEventType.LIVE_NOW
              ? Pick<GrowthGroupInstance, 'actualStartTime' | 'startTime'>
              : EventType extends GrowthGroupStatusTopicEventType.LIVE_NOW_FULL
                ? GroupStatusFullOrVacantPayload
                : EventType extends GrowthGroupStatusTopicEventType.LIVE_NOW_VACANT
                  ? GroupStatusFullOrVacantPayload
                  : EventType extends GrowthGroupStatusTopicEventType.ADMIT_ALL
                    ? Pick<GrowthGroupInstance, 'admitAll'>
                    : EventType extends GrowthGroupStatusTopicEventType.ENDED
                      ? Pick<GrowthGroupInstance, 'actualEndTime'>
                      : EventType extends GrowthGroupStatusTopicEventType.CANCELLED
                        ? Pick<GrowthGroupInstance, 'actualEndTime'>
                        : never;

export type GroupCollectionInstancesInfo<EventType extends GrowthGroupStatusTopicEventType> =
  GroupCollectionInstancesBaseInfo & GroupCollectionInstancesField<EventType>;

export type GroupCollectionPayload<EventType extends GrowthGroupStatusTopicEventType> = {
  id: string;
  timestamp: string;
  eventType: EventType;
  groupId: Pick<GrowthGroupInstance, 'groupId'>;
  data: {
    instancesData: Array<{
      instances: Array<Pick<GrowthGroupInstance, 'id' | 'slug'>>;
      data: GroupCollectionInstancesInfo<EventType>;
    }>;
  };
};

export type GrowthGroupCollectionStatusTopic = GroupCollectionPayload<GrowthGroupStatusTopicEventType>;

export interface NotificationsTopic {
  timestamp: string;
  id: string;
  data: {
    inAppNotification: IInAppNotifications;
  };
  eventType: NotificationsEventsEnum;
}

export enum NotificationsEventsEnum {
  NOTIFICATION_IN_APP_CREATE = 'NOTIFICATION_IN_APP_CREATE',
}

export interface IInAppNotifications {
  id: string;
  createTimestamp: number;
  updateTimestamp: number;
  uuid: string;
  header?: string;
  body?: string;
  type: INotificationType;
  data?: INotificationData;
  hasBeenRead: boolean;
  image?: string;
}

export interface INotificationData {
  type?: INotificationType;
  notificationId?: string;
  objectId?: string;
  objectType?: INotificationObjectType;
  extraData?: Record<string, string>;
}

export enum INotificationObjectType {
  PLAN = 'PLAN',
  PLAN_ACTION = 'PLAN_ACTION',
  PLAN_SUB_TASK = 'PLAN_SUB_TASK',
  CUSTOM = 'CUSTOM',
}

export enum INotificationType {
  NORMAL = 'NORMAL',
  CHALLENGES = 'CHALLENGES',
  PLANS = 'PLANS',
  JOURNAL = 'JOURNAL',
  EVENTS = 'EVENTS',
  COURSES = 'COURSES',
  LIFE_SCORES = 'LIFE_SCORES',
  REMINDER = 'REMINDER',
  WELCOME = 'WELCOME',
  GROWTH_COIN = 'GROWTH_COIN',
  GIFT_INVITE = 'GIFT_INVITE',
  GROWTHDAY = 'GROWTHDAY',
  GROWTH_GROUPS = 'GROWTH_GROUPS',
  GROWTH_GROUP = 'GROWTH_GROUP',
  GROWTH_GROUPS_RSVP = 'GROWTH_GROUPS_RSVP',
  GROWTH_GROUP_RSVP = 'GROWTH_GROUP_RSVP',
  GROWTH_GROUP_RECURRING_RSVP = 'GROWTH_GROUP_RECURRING_RSVP',
  TRIAL_REMINDER = 'TRIAL_REMINDER',
  GIFT_SUBSCRIPTION_ABANDON = 'GIFT_SUBSCRIPTION_ABANDON',
  GOAL = 'GOAL',
  GOALS = 'GOALS',
  MILESTONE = 'MILESTONE',
  MILESTONES = 'MILESTONES',
  ACHIEVEMENT = 'ACHIEVEMENT',
  ACHIEVEMENTS = 'ACHIEVEMENTS',
  ACHIEVEMENT_BADGE = 'ACHIEVEMENT_BADGE',
  ACHIEVEMENTS_BADGE = 'ACHIEVEMENTS_BADGE',
  ACHIEVEMENT_CERTIFICATE = 'ACHIEVEMENT_CERTIFICATE',
  ACHIEVEMENTS_CERTIFICATE = 'ACHIEVEMENTS_CERTIFICATE',
  ENTERPRISE_SCORECARD = 'ENTERPRISE_SCORECARD',
}

export enum StripeConnectEventType {
  ACCOUNT_CONNECTED = 'ACCOUNT_CONNECTED',
}

export type StripeConnectPayload = {
  eventType: StripeConnectEventType.ACCOUNT_CONNECTED;
  data: UserStripeConnectSetting | null | undefined;
};
