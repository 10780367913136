import {safeJsonParse} from '../../../utils/safe-json';

/**
 * Interface representing metadata fields for a chat message.
 */
export interface MessageMetadata {
  /**
   * The UUID of the message sender.
   */
  senderUuid?: string | null;
  /**
   * The name of the message sender.
   */
  senderName?: string | null;
  /**
   * Flag indicating whether the message contains rich text.
   * This is a kept in metadata instead of checking the message content and rendering as rich text if needed
   * Since we want to keep the chat performant, messages should be marked as rich text while sending / updating
   * This way, we pay one time cost while sending instead of checking and rendering rich text on every peer
   */
  richText?: boolean;
}

/**
 * Parses a JSON string representing message metadata.
 *
 * @param metadata - The JSON string to parse.
 * @returns - The parsed metadata or null if parsing fails.
 *
 * @example
 * const metadata = parseMessageMetadata('{"senderUuid": "123", "senderName": "John"}');
 */
export function parseMessageMetadata(metadata: string | null | undefined) {
  return safeJsonParse(metadata) as MessageMetadata | null;
}

/**
 * Serializes a MessageMetadata object into a JSON string.
 *
 * @param metadata - The metadata to serialize.
 * @returns - The JSON string representing the serialized metadata.
 *
 * @example
 * const metadata = { senderUuid: '123', senderName: 'John' };
 * const serialized = serializeMessageMetadata(metadata);
 */
export function serializeMessageMetadata(metadata: MessageMetadata) {
  return JSON.stringify(metadata);
}
