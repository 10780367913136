import useScript from 'react-script-hook';
import config from '../config';

const useUseProof = () => {
  useScript({
    src: config.env.useproofId ? `https://cdn.useproof.com/proof.js?acc=${config.env.useproofId}` : null,
    checkForExisting: true,
  });
};

export default useUseProof;
