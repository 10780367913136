import {Portal} from '@/components/portal';
import RootRef from '@/components/root-ref';
import classNames from 'classnames';
import {CSSProperties, ReactNode, useEffect} from 'react';
import {Config, PopperOptions, usePopperTooltip} from 'react-popper-tooltip';
import useOptionalState from 'use-optional-state';
import styled from 'styled-components';

type Size = 'small' | 'medium' | 'large';

const Backdrop = styled.div.withConfig<{
  darkMode?: boolean;
  padding?: Size;
  size?: Size;
  fontSize?: Size;
  zIndex?: number;
}>({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['darkMode', 'padding', 'size', 'fontSize'].includes(prop) && defaultValidatorFn(prop),
})`
  --bg-color: #fff;
  --text-color: #000;
  --arrow-size: 5px;

  ${({darkMode}) =>
    darkMode &&
    `
      --bg-color: rgba(0, 0, 0, 0.8);
      --text-color: #fff;
  `}

  background-color: var(--bg-color);
  color: var(--text-color);
  box-shadow:
    0 3px 6px -4px #0000001f,
    0 6px 16px #00000014,
    0 9px 28px 8px #0000000d;
  border-radius: 6px;
  padding: ${({padding}) =>
    padding === 'large' ? '12px 16px' : padding === 'medium' ? `8px 12px` : padding === 'small' ? `4px 8px` : `0px`};
  font-size: ${({fontSize}) => (fontSize === 'large' ? 16 : fontSize === 'medium' ? 14 : 12)}px;
  line-height: 18px;
  width: max-content;
  max-width: ${({size}) => (size === 'large' ? 480 : size === 'medium' ? 320 : 240)}px;
  overflow-wrap: break-word;
  font-weight: 500;
  z-index: ${({zIndex}) => zIndex ?? 1002};

  & > [data-popper-arrow] {
    width: calc(var(--arrow-size) * 2);
    height: calc(var(--arrow-size) * 2);
  }

  &[data-popper-placement^='top'] > [data-popper-arrow] {
    bottom: calc(0px - (var(--arrow-size) * 2));
    border-width: var(--arrow-size) var(--arrow-size) 0 var(--arrow-size);
    border-color: var(--bg-color) transparent transparent transparent;
  }

  &[data-popper-placement^='right'] > [data-popper-arrow] {
    left: calc(0px - (var(--arrow-size) * 2));
    border-width: var(--arrow-size) var(--arrow-size) var(--arrow-size) 0;
    border-color: transparent var(--bg-color) transparent transparent;
  }

  &[data-popper-placement^='bottom'] > [data-popper-arrow] {
    top: calc(0px - (var(--arrow-size) * 2));
    border-width: 0 var(--arrow-size) var(--arrow-size) var(--arrow-size);
    border-color: transparent transparent var(--bg-color) transparent;
  }

  &[data-popper-placement^='left'] > [data-popper-arrow] {
    right: calc(0px - (var(--arrow-size) * 2));
    border-width: var(--arrow-size) 0 var(--arrow-size) var(--arrow-size);
    border-color: transparent transparent transparent var(--bg-color);
  }
`;

const Arrow = styled.span`
  z-index: 1;
  display: inline-flex;
  border-style: solid;
`;

export type OverlayProps = Pick<
  Config,
  | 'closeOnTriggerHidden'
  | 'trigger'
  | 'delayHide'
  | 'delayShow'
  | 'followCursor'
  | 'defaultVisible'
  | 'visible'
  | 'onVisibleChange'
  | 'closeOnOutsideClick'
  | 'interactive'
  | 'offset'
> &
  Pick<PopperOptions, 'placement' | 'strategy'> & {
    closeOnContentClick?: boolean;
    darkMode?: boolean;
    hideArrow?: boolean;
    disabled?: boolean;
    className?: string;
    style?: CSSProperties;
    title?: ReactNode;
    content?: ReactNode;
    padding?: Size;
    size?: Size;
    fontSize?: Size;
    children?: JSX.Element | null;
    zIndex?: number;
  };

export function Overlay({
  // Props
  children,
  title,
  content,
  disabled: _disabled,
  className,
  style,
  darkMode,
  padding,
  size = 'small',
  fontSize = 'small',
  hideArrow,
  closeOnContentClick,
  zIndex = 1002,

  // Popper Options
  placement = 'top',
  strategy = 'fixed',

  // Config
  closeOnTriggerHidden = true,
  trigger,
  delayHide = 50,
  delayShow,
  followCursor,
  defaultVisible,
  visible,
  onVisibleChange,
  closeOnOutsideClick,
  interactive = true,
  offset,
}: OverlayProps) {
  const hasContent = Boolean(content || title);
  const disabled = _disabled || !hasContent;

  const [visibleState, setVisibleState] = useOptionalState({
    initialValue: defaultVisible,
    controlledValue: visible,
    onChange: onVisibleChange,
  });

  useEffect(() => {
    if (disabled && visibleState) {
      setVisibleState(false);
    }
  }, [disabled, visibleState, setVisibleState]);

  const {
    getTooltipProps,
    getArrowProps,
    setTooltipRef,
    setTriggerRef,
    visible: tooltipVisible,
    forceUpdate,
  } = usePopperTooltip(
    {
      closeOnTriggerHidden,
      trigger,
      delayHide,
      delayShow,
      followCursor,
      visible: visibleState,
      onVisibleChange: setVisibleState,
      closeOnOutsideClick,
      interactive,
      offset,
      mutationObserverOptions: {
        attributes: true,
        childList: true,
        subtree: true,
      },
    },
    {
      strategy,
      placement,
    }
  );

  useEffect(() => {
    setTimeout(() => {
      if (tooltipVisible) {
        forceUpdate?.();
      }
    }, 300);
  }, [tooltipVisible, forceUpdate]);

  return (
    <>
      <RootRef disabled={disabled} rootRef={(ref) => setTriggerRef(ref)}>
        {children}
      </RootRef>
      {tooltipVisible && !disabled && (
        <Portal>
          <Backdrop
            zIndex={zIndex}
            size={size}
            fontSize={fontSize}
            padding={padding}
            darkMode={darkMode}
            className={classNames('overlay', className)}
            ref={setTooltipRef}
            onClick={(e) => {
              e.stopPropagation();
              if (closeOnContentClick && visibleState) {
                setVisibleState(false);
              }
            }}
            {...getTooltipProps({style})}
          >
            {!hideArrow && <Arrow {...getArrowProps()} />}
            {title}
            {content}
          </Backdrop>
        </Portal>
      )}
    </>
  );
}
