import {axiosGrowthDay} from '../index';
import {TodoEntry} from '../../services_deprecated/model/generic/todo';
import {PaginatedEntity} from '../../services_deprecated/model/generic/paginatedEntity';
import {Reminder} from '../../services_deprecated/model/generic/reminder';
import {message} from 'antd';
import {BulkEntry} from './interfaces';

export const plansApi = {
  updatePlanOrder: (data: Record<string, number>) => {
    return axiosGrowthDay.post('/plans/updateOrder', data);
  },
  updateTaskNote: (planId: string, taskId: string, notes: string) => {
    return axiosGrowthDay
      .put<TodoEntry>(`/plans/tasks/notes/${planId}/${taskId}`, {
        notes,
      })
      .then((res) => res.data);
  },
  updateNote: (planId: string, notes: string) => {
    return axiosGrowthDay
      .put<TodoEntry>(`/plans/notes/${planId}`, {
        notes,
      })
      .then((res) => res.data);
  },
  updatePlan: (plan: Partial<TodoEntry>) => {
    return axiosGrowthDay.put<TodoEntry>(`/plans/${plan.id}`, plan).then((res) => res.data);
  },
  checkDateValidity: (planId: string, taskDueDate: string) => {
    return axiosGrowthDay.get<boolean>(`/plans/checkTaskDateValidity/${planId}/${taskDueDate}`).then((res) => res.data);
  },
  deletePlan: (planId: string) => {
    return axiosGrowthDay.delete<TodoEntry>(`/plans/${planId}`).then((res) => res.data);
  },
  retrievePlan: () => {
    return axiosGrowthDay.get<TodoEntry[]>(`/plans`).then((res) => res.data);
  },
  getCompletedPlans: (page: number, size: number) => {
    return axiosGrowthDay.get<PaginatedEntity>(`/plans/completed?page=${page}&size=${size}`).then((res) => res.data);
  },
  createPlan: (plan: Partial<TodoEntry>) => {
    return axiosGrowthDay.post('/plans', plan).then((res) => res.data);
  },
  markPlanAsCompleted: (planId: string) => {
    return axiosGrowthDay.put<TodoEntry>(`/plans/complete/${planId}`).then((res) => res.data);
  },
  recoverPlan: (planId: string) => {
    return axiosGrowthDay.put<TodoEntry>(`/plans/recover/${planId}`).then((res) => res.data);
  },
  deletePlanTask: (planId: string, taskId: string) => {
    return axiosGrowthDay.delete<TodoEntry>(`/plans/tasks/${planId}/${taskId}`).then((res) => res.data);
  },
  createTask: (planId: string, entry: TodoEntry) => {
    return axiosGrowthDay
      .post<TodoEntry>(`/plans/tasks/${planId}`, entry)
      .then((res) => res.data)
      .catch((e: any) => message.error('There was an error while trying to create an action'));
  },
  updateTask: (planId: string, taskId: string, entry: TodoEntry) => {
    return axiosGrowthDay
      .put<TodoEntry>(`/plans/tasks/${planId}/${taskId}`, entry)
      .then((res) => res.data)
      .catch((e: any) => message.error('There was an error while trying to update an action'));
  },
  createSubTask: (planId: string, taskId: string, entry: TodoEntry) => {
    return axiosGrowthDay
      .post<TodoEntry>(`/plans/tasks/${planId}/${taskId}`, entry)
      .then((res) => res.data)
      .catch((e: any) => message.error('There was an error while trying to create a task'));
  },
  markTaskCompleted: (planId: string, taskId: string) => {
    return axiosGrowthDay
      .put<TodoEntry>(`/plans/complete/${planId}/${taskId}`)
      .then((res) => res.data)
      .catch((e: any) => message.error('There was an error while trying to mark as completed an action'));
  },
  markTaskUnCompleted: (planId: string, taskId: string) => {
    return axiosGrowthDay
      .put<TodoEntry>(`/plans/recover/${planId}/${taskId}`)
      .then((res) => res.data)
      .catch((e: any) => message.error('There was an error while trying to mark as uncompleted an action'));
  },
  updateTaskReminder: (planId: string, taskId: string, entry: TodoEntry) => {
    return axiosGrowthDay.put<TodoEntry>(`/plans/tasks/reminder/${planId}/${taskId}`, entry).then((res) => res.data);
  },
  updateSubTask: (planId: string, taskId: string, entry: TodoEntry) => {
    return axiosGrowthDay
      .put<TodoEntry>(`/plans/subtasks/${planId}/${taskId}`, entry)
      .then((res) => res.data)
      .catch((e: any) => message.error('There was an error while trying to update a task'));
  },
  markSubTaskUnCompleted: (planId: string, taskId: string) => {
    return axiosGrowthDay
      .put<TodoEntry>(`/plans/recover/subtask/${planId}/${taskId}`)
      .then((res) => res.data)
      .catch((e: any) => message.error('There was an error while trying to mark as uncompleted a task'));
  },
  deletePlanSubTask: (planId: string, taskId: string) => {
    return axiosGrowthDay
      .delete<TodoEntry>(`/plans/subtasks/${planId}/${taskId}`)
      .then((res) => res.data)
      .catch((e: any) => message.error('There was an error while trying to delete a task'));
  },
  markSubTaskCompleted: (planId: string, taskId: string) => {
    return axiosGrowthDay
      .put<TodoEntry>(`/plans/complete/subtask/${planId}/${taskId}`)
      .then((res) => res.data)
      .catch((e: any) => message.error('There was an error while trying to mark as completed a task'));
  },
  updatePlanReminder: (planId: string, reminder: Reminder) => {
    return axiosGrowthDay
      .put<TodoEntry>(`/plans/reminder/${planId}`, reminder)
      .then((res) => res.data)
      .catch((e: any) => message.error('There was an error while trying to set the reminder'));
  },
  updatePlanTaskOrder: (planId: string, data: Record<string, number>) => {
    return axiosGrowthDay.post(`/plans/tasks/updateOrder/${planId}`, data);
  },
  updatePlanSubTaskOrder: (planId: string, taskId: string, data: Record<string, number>) => {
    return axiosGrowthDay.post(`/plans/tasks/updateOrder/${planId}/${taskId}`, data);
  },
  createTaskWithBulkSubTask: (planId: string, data: BulkEntry) => {
    return axiosGrowthDay.post(`/plans/tasks/bulk/${planId}/`, data).then((res) => res.data);
  },
};
