/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {ScorecardSettings} from './scorecard-settings';

/**
 *
 * @export
 * @interface Organization
 */
export interface Organization {
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof Organization
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof Organization
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof Organization
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  organizationSubscribeLink?: string;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  logoUrl?: string;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  invitationVideoUrl?: string;
  /**
   *
   * @type {boolean}
   * @memberof Organization
   */
  dataCompliancePolicyAccepted?: boolean;
  /**
   *
   * @type {number}
   * @memberof Organization
   */
  seats?: number;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  plan?: OrganizationPlanEnum;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  planName?: string;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  planFrequency?: OrganizationPlanFrequencyEnum;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  stripeCustomerId?: string;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  stripePaymentMethodId?: string;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  paymentMethodType?: OrganizationPaymentMethodTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  paymentMethodLastFour?: string;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  paymentMethodExpiry?: string;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  paymentMethodVendor?: string;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  stripeSubscriptionId?: string;
  /**
   *
   * @type {number}
   * @memberof Organization
   */
  subscriptionAmount?: number;
  /**
   *
   * @type {number}
   * @memberof Organization
   */
  subscriptionStartDate?: number;
  /**
   *
   * @type {number}
   * @memberof Organization
   */
  subscriptionEndDate?: number;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  stripePriceId?: string;
  /**
   *
   * @type {boolean}
   * @memberof Organization
   */
  subscriptionCancelled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Organization
   */
  teamAssessmentEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Organization
   */
  automaticTaxEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  region?: string;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  zipCode?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Organization
   */
  domains?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof Organization
   */
  processingInvitation?: boolean;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  paymentFlow?: OrganizationPaymentFlowEnum;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  subscriptionLinkVisibility?: OrganizationSubscriptionLinkVisibilityEnum;
  /**
   *
   * @type {ScorecardSettings}
   * @memberof Organization
   */
  scorecardSettings?: ScorecardSettings;
  /**
   *
   * @type {string}
   * @memberof Organization
   */
  channelPartnerId?: string;
  /**
   *
   * @type {boolean}
   * @memberof Organization
   */
  growthGroupEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Organization
   */
  communityEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Organization
   */
  growthGroupHostingEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Organization
   */
  demoAccount?: boolean;
}

export const OrganizationPlanEnum = {
  Basic: 'BASIC',
  Starter: 'STARTER',
  Growth: 'GROWTH',
  Mastery: 'MASTERY',
  Trial: 'TRIAL',
  None: 'NONE',
  Offer: 'OFFER',
  Challenge: 'CHALLENGE',
  Enterprise: 'ENTERPRISE',
  EnterpriseStarter: 'ENTERPRISE_STARTER',
  AllAccess: 'ALL_ACCESS',
} as const;

export type OrganizationPlanEnum = (typeof OrganizationPlanEnum)[keyof typeof OrganizationPlanEnum];
export const OrganizationPlanFrequencyEnum = {
  Month: 'MONTH',
  Year: 'YEAR',
} as const;

export type OrganizationPlanFrequencyEnum =
  (typeof OrganizationPlanFrequencyEnum)[keyof typeof OrganizationPlanFrequencyEnum];
export const OrganizationPaymentMethodTypeEnum = {
  Card: 'CARD',
  Paypal: 'PAYPAL',
  Apple: 'APPLE',
} as const;

export type OrganizationPaymentMethodTypeEnum =
  (typeof OrganizationPaymentMethodTypeEnum)[keyof typeof OrganizationPaymentMethodTypeEnum];
export const OrganizationPaymentFlowEnum = {
  Organization: 'ORGANIZATION',
  Employee: 'EMPLOYEE',
} as const;

export type OrganizationPaymentFlowEnum =
  (typeof OrganizationPaymentFlowEnum)[keyof typeof OrganizationPaymentFlowEnum];
export const OrganizationSubscriptionLinkVisibilityEnum = {
  Public: 'PUBLIC',
  Private: 'PRIVATE',
} as const;

export type OrganizationSubscriptionLinkVisibilityEnum =
  (typeof OrganizationSubscriptionLinkVisibilityEnum)[keyof typeof OrganizationSubscriptionLinkVisibilityEnum];
