/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {BaseEntity} from './base-entity';

/**
 *
 * @export
 * @interface LinkResponse
 */
export interface LinkResponse {
  /**
   *
   * @type {string}
   * @memberof LinkResponse
   */
  type?: LinkResponseTypeEnum;
  /**
   *
   * @type {Array<BaseEntity>}
   * @memberof LinkResponse
   */
  objectList?: Array<BaseEntity>;
}

export const LinkResponseTypeEnum = {
  Challenge: 'CHALLENGE',
  Journal: 'JOURNAL',
  Goal: 'GOAL',
  Plan: 'PLAN',
} as const;

export type LinkResponseTypeEnum = (typeof LinkResponseTypeEnum)[keyof typeof LinkResponseTypeEnum];
