import React, {FC} from 'react';
import {CustomIconComponentProps} from '@ant-design/icons/lib/components/Icon';
import {componentsPrefixCls} from '../../style/prefix-cls';
import classnames from 'classnames';

export type CustomIconProps = {
  className?: string;
} & Partial<CustomIconComponentProps>;

const iconCreator = function <Props = {}>(
  WrappedComponent: FC<CustomIconProps>
): FC<Props & Partial<CustomIconComponentProps>> {
  const cls = componentsPrefixCls();

  return function ({className, ...restProps}) {
    return <WrappedComponent className={classnames(cls('icon'), className)} {...restProps} />;
  };
};

export default iconCreator;
