import {Elements as StripeElements} from '@stripe/react-stripe-js';
import {FC} from 'react';

import {loadStripe} from '@stripe/stripe-js';
import config from '../../../config';

const stripe = loadStripe(config.env.stripePublishKey);

const options = {fonts: [{cssSrc: 'https://fonts.googleapis.com/css?family=Inter'}]};

const StripeProvider: FC = ({children}) => (
  <StripeElements stripe={stripe} options={options}>
    {children}
  </StripeElements>
);

export default StripeProvider;
