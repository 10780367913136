import {useQuery, useQueryClient, UseQueryOptions} from 'react-query';
import {axiosGrowthDay} from '../../../utils/api';
import {GrowthGroupQueryKey} from '../../../types/growth-groups';
import {Participant} from '../../../types/api';
import {sortBy} from 'lodash';
import {isRole, sortedRoles} from '../util/roles';
import {useAuthData} from '../../../hooks/useAuthData';
import {useEffect} from 'react';

export const GroupParticipantsKey = (groupId: string, slug?: string) =>
  [GrowthGroupQueryKey, 'Participants', groupId, ...(slug ? [{slug}] : [])] as const;

export default function useGetGroupParticipants<T = Participant[]>(
  groupId: string,
  slug: string,
  options: UseQueryOptions<Participant[], unknown, T, ReturnType<typeof GroupParticipantsKey>> = {}
) {
  const {isLoggedIn} = useAuthData();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isLoggedIn) {
      queryClient.removeQueries({
        queryKey: GroupParticipantsKey(groupId),
      });
    }
  }, [isLoggedIn, queryClient, groupId]);

  return useQuery(
    GroupParticipantsKey(groupId, slug),
    async () => {
      const result = await axiosGrowthDay.get<{count?: number; participantList: Participant[]}>(
        `/groups/participants`,
        {
          params: {
            slug,
            offset: 1,
            limit: 10000,
          },
        }
      );
      const participants =
        result.data?.participantList?.filter((participant) => isRole(participant.role, ...sortedRoles)) ?? [];
      return sortBy(participants, (participant) => sortedRoles.findIndex((role) => isRole(participant.role, role)));
    },
    {
      ...options,
      enabled: !!slug && !!groupId && isLoggedIn && options.enabled,
    }
  );
}
