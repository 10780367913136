import * as rudderanalytics from 'rudder-sdk-js';
import {AnalyticsRudderstackDynamicConfig, DynamicConfigEnum} from '@growthday/ui-core/src/features/feature-gate/types';
import {useLayoutEffect} from 'react';
import {getDynamicConfig} from '@growthday/ui-core/src/features/feature-gate/utils/get-dynamic-config';
import {AnalyticsActions} from '@/features/analytics/analyticsActions';
import isEnvironment from '@/config/util/isEnvironment';
import {EnvironmentsEnum} from '@/config/enums';

declare global {
  interface Window {
    rudderanalytics: any[];
  }
}

const initializeRudder = (config: AnalyticsRudderstackDynamicConfig) => {
  window.rudderanalytics = [];

  config.methods.forEach((method: string) => {
    (window.rudderanalytics as Record<string, any>)[method] = ((methodName: string) => {
      return function () {
        window.rudderanalytics.push([methodName].concat(Array.prototype.slice.call(arguments)));
      };
    })(method);
  });

  rudderanalytics.load(
    config.rudderStackKey ?? '',
    config.rudderStackUrl,
    config.rudderStackLoadOptions as rudderanalytics.loadOptions
  );
  rudderanalytics.ready(() => {});
};

const useRudder = () => {
  useLayoutEffect(() => {
    const rudderConfig = getDynamicConfig<AnalyticsRudderstackDynamicConfig>(DynamicConfigEnum.AnalyticsRudderstack);
    const configIsValid = Boolean(rudderConfig?.rudderStackKey && rudderConfig?.rudderStackUrl);

    if (configIsValid) {
      initializeRudder(rudderConfig);
    }
  }, []);
};

export const rudderActions: AnalyticsActions = {
  identify: (id: string, traits: rudderanalytics.apiObject) => {
    setTimeout(() => {
      try {
        rudderanalytics.identify(id, traits);
        logLocalEvent('identify', {id, ...traits});
      } catch (e: any) {}
    });
  },
  alias: (id: string, previousId?: string) => {
    setTimeout(() => {
      try {
        rudderanalytics.alias(id, previousId);
        logLocalEvent('alias', {id});
      } catch (e: any) {}
    });
  },
  group: (groupId: string) => {
    setTimeout(() => {
      try {
        rudderanalytics.group(groupId);
        logLocalEvent('group', {groupId});
      } catch (e: any) {}
    });
  },
  track: (name: string, props: rudderanalytics.apiObject = {}, apiOptions: rudderanalytics.apiOptions) => {
    setTimeout(() => {
      try {
        rudderanalytics.track(name, props, apiOptions);
        logLocalEvent('track', {name, props, apiOptions});
      } catch (e: any) {}
    });
  },
  traits: (traits: rudderanalytics.apiObject) => {
    setTimeout(() => {
      try {
        rudderanalytics.identify(traits);
        logLocalEvent('traits', traits);
      } catch (e: any) {}
    });
  },
  reset: () => {
    setTimeout(() => {
      try {
        rudderanalytics.reset(true);
        logLocalEvent('reset');
      } catch (e: any) {}
    });
  },
  page: (category: string, name: string, props: rudderanalytics.apiObject = {}) => {
    setTimeout(() => {
      try {
        rudderanalytics.page(category, name, props);
        logLocalEvent('page', {category, name, ...props});
      } catch (e: any) {}
    });
  },
};

const logLocalEvent = (type: string, props?: object): void => {
  if (isEnvironment(EnvironmentsEnum.DEV, EnvironmentsEnum.UAT)) {
    console.log(`rudder ${type} event: `, props);
  }
};

export default useRudder;
