import {ITooltipItem} from './tooltip-item';

/**
 * Model definition for Tooltips
 */
export interface ITooltips {
  id: number;
  trigger?: EnumTooltipsTrigger;
  disabled?: boolean;
  items: ITooltipItem[];
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumTooltipsTrigger {
  ON_CHALLENGE_LOAD = 'ON_CHALLENGE_LOAD',
  ON_CHALLENGE_FIRST_TASK_COMPLETE = 'ON_CHALLENGE_FIRST_TASK_COMPLETE',
  ON_CHALLENGE_DAY_ONE_COMPLETE = 'ON_CHALLENGE_DAY_ONE_COMPLETE',
  ON_DASHBOARD_LOAD = 'ON_DASHBOARD_LOAD',
  ON_ENTERPRISE_DASHBOARD_LOAD = 'ON_ENTERPRISE_DASHBOARD_LOAD',
  ON_CHALLENGE_DAY_TWO_COMPLETE = 'ON_CHALLENGE_DAY_TWO_COMPLETE',
  ON_CHALLENGE_DAY_THREE_COMPLETE = 'ON_CHALLENGE_DAY_THREE_COMPLETE',
  ON_CHALLENGE_DAY_FOUR_COMPLETE = 'ON_CHALLENGE_DAY_FOUR_COMPLETE',
  ON_CHALLENGE_DAY_FIVE_COMPLETE = 'ON_CHALLENGE_DAY_FIVE_COMPLETE',
}
