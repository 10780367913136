import {Select} from 'antd';
import {SelectProps} from 'antd/es/select';
import React, {FC, useRef} from 'react';
import useCountryState from '@growthday/ui-core/src/hooks/useCountryState';
import './style.less';

export type GDCountryInputProps = {} & SelectProps<string>;

const GDCountryInput: FC<GDCountryInputProps> = (props) => {
  const {countries} = useCountryState();
  const container = useRef<HTMLDivElement>(null);
  return (
    <div ref={container}>
      <Select
        // @ts-ignore
        autoComplete="new-password"
        showSearch
        optionFilterProp="children"
        size="large"
        {...props}
        getPopupContainer={() => container.current || document.body}
      >
        {countries.map((country) => (
          <Select.Option key={`${country.iso2}-${country.name}`} value={country.iso2}>
            {country.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default GDCountryInput;
