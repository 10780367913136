import {getPlanByComparator} from '@/features/offers/utils/getPlanByComparator';
import {SubscriptionIntervalsEnum} from '@/shared/services_deprecated/enums';
import {useCallback, useMemo} from 'react';
import {SubscriptionLevelsEnum} from '../enums';
import {IStrapiSubscriptionPlan, ISubscriptionPlans} from '../interfaces';
import useSubscriptionPlans from '../routes/Profile/hooks/useSubscriptionPlans';

export function getPlanInterval(plan?: IStrapiSubscriptionPlan, interval?: SubscriptionIntervalsEnum) {
  let planInterval;
  if (interval) {
    planInterval =
      interval === SubscriptionIntervalsEnum.YEAR && plan?.yearlyAmount
        ? SubscriptionIntervalsEnum.YEAR
        : interval === SubscriptionIntervalsEnum.MONTH && plan?.monthlyAmount
          ? SubscriptionIntervalsEnum.MONTH
          : undefined;
  }
  if (!planInterval) {
    planInterval = plan?.yearlyAmount != 0 ? SubscriptionIntervalsEnum.YEAR : SubscriptionIntervalsEnum.MONTH;
  }
  return planInterval;
}

export function getPlanByLevel(
  allStrapiSubscriptionPlans?: IStrapiSubscriptionPlan[],
  subscriptionPlans?: ISubscriptionPlans[],
  levelOrCallback?: SubscriptionLevelsEnum | ((plan: IStrapiSubscriptionPlan) => boolean),
  interval?: SubscriptionIntervalsEnum
) {
  if (levelOrCallback && subscriptionPlans && allStrapiSubscriptionPlans) {
    // find all strapi subscription plans that match the levelOrCallback.
    const allPlan = allStrapiSubscriptionPlans.find((plan) => {
      const planInterval = getPlanInterval(plan, interval);
      const planLevelMatches =
        typeof levelOrCallback === 'function'
          ? levelOrCallback(plan)
          : plan.level === levelOrCallback && (!interval || interval === planInterval);
      const planIsPurchasable = plan.isPurchasable;
      /* istanbul ignore next - && comparison doesn't cover right hand operator */
      return planIsPurchasable && planLevelMatches;
    });

    const allPlanInterval = getPlanInterval(allPlan, interval);
    // filter plans to find the first purchasable plan that matches on level, levelweight, and enterprise fields in strapi.
    /* istanbul ignore if  */
    if (allPlan) {
      const plan =
        getPlanByComparator(
          subscriptionPlans,
          (planMap, planInterval) =>
            planMap.level === allPlan.level &&
            planMap.levelWeight === allPlan.levelWeight &&
            planMap.enterprise === allPlan.enterprise &&
            (!interval
              ? allPlanInterval?.toLowerCase() === planInterval.toLowerCase()
              : planInterval?.toLowerCase() === interval?.toLowerCase())
        ) || undefined;

      if (plan) {
        return plan;
      }

      return (
        (subscriptionPlans &&
          getPlanByComparator(
            subscriptionPlans,
            (planMap, planInterval) =>
              planMap.levelWeight === allPlan.levelWeight &&
              planMap.enterprise === allPlan.enterprise &&
              (!interval || planInterval?.toLowerCase() === interval?.toLowerCase())
          )) ||
        undefined
      );
    }
  }
}

export function useGetPlanByLevel(
  levelProp?: SubscriptionLevelsEnum | ((plan: IStrapiSubscriptionPlan) => boolean),
  interval?: SubscriptionIntervalsEnum
) {
  const {allStrapiSubscriptionPlans, subscriptionPlans} = useSubscriptionPlans();
  const planByLevel = useCallback(
    (
      level?: SubscriptionLevelsEnum | ((plan: IStrapiSubscriptionPlan) => boolean),
      intervalProp?: SubscriptionIntervalsEnum
    ) => getPlanByLevel(allStrapiSubscriptionPlans, subscriptionPlans, level, intervalProp || interval),
    [allStrapiSubscriptionPlans, subscriptionPlans, interval]
  );

  return useMemo(
    () => ({plan: levelProp ? planByLevel(levelProp) : undefined, getPlanByLevel: planByLevel}),
    [planByLevel, levelProp]
  );
}
