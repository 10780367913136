import React, {FC} from 'react';
import classes from './InvalidResetPasswordToken.module.less';
import GDTypography from '../../../../shared/components/ui/Typography/Typography';
import {Link} from 'react-router-dom';
import {getStaticAssetUrl} from '@/shared/util/get-static-asset-url';
import classNames from 'classnames';
import {useRebrandText} from '@/features/feature-disable/hooks/useRebrandText';

const hpXIcon = getStaticAssetUrl('logo', 'logo-error-page.svg');

const InvalidResetPasswordToken: FC = () => {
  const {rebrandTextFromEnv} = useRebrandText();
  return (
    <div className={classes.container}>
      <div className={classNames(classes.errorContainer, 'error-page-container')}>
        <div className={classNames(classes.errorLeftOutline, 'error-page-left-outline')} />
        <div className={classes.errorContent}>
          <GDTypography type="w800" className={classNames(classes.title, 'error-page-not-found-title')}>
            Reset password link has expired, you can request a new one
          </GDTypography>
          <Link to="/login" className={classNames(classes.homeLink, 'error-page-not-found-home-link')}>
            <GDTypography type="w100" subType="s">
              Back to login
            </GDTypography>
          </Link>
        </div>
        <img className={classes.footerIcon} src={hpXIcon} alt={`${rebrandTextFromEnv('productName')} Icon`} />
      </div>
    </div>
  );
};

export default InvalidResetPasswordToken;
