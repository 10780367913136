import {useDynamicConfig} from '@growthday/ui-core/src/features/feature-gate/hooks/useDynamicConfig';
import {useFeatureGate} from '@growthday/ui-core/src/features/feature-gate/hooks/useFeatureGate';
import {
  DynamicConfigEnum,
  FeatureGateEnum,
  GoogleRecaptchaConfigDynamicConfig,
} from '@growthday/ui-core/src/features/feature-gate/types';

export function useIsCaptchaEnabled() {
  const captchaEnabled = useFeatureGate(FeatureGateEnum.GoogleRecaptcha);
  const {
    config: {
      value: {recaptchaKey},
    },
  } = useDynamicConfig<GoogleRecaptchaConfigDynamicConfig>(DynamicConfigEnum.GoogleRecaptchaConfig);
  return {
    isCaptchaEnabled: recaptchaKey && captchaEnabled,
    recaptchaKey,
  };
}
