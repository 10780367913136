/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface UserFilter
 */
export interface UserFilter {
  /**
   *
   * @type {string}
   * @memberof UserFilter
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof UserFilter
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof UserFilter
   */
  level?: UserFilterLevelEnum;
  /**
   *
   * @type {boolean}
   * @memberof UserFilter
   */
  demoUser?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserFilter
   */
  kajabiUser?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserFilter
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof UserFilter
   */
  organizationId?: string;
  /**
   *
   * @type {number}
   * @memberof UserFilter
   */
  offset: number;
  /**
   *
   * @type {number}
   * @memberof UserFilter
   */
  limit: number;
  /**
   *
   * @type {boolean}
   * @memberof UserFilter
   */
  enterpriseUser?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserFilter
   */
  subscriptionCancelled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserFilter
   */
  isEnterpriseUser?: boolean;
}

export const UserFilterLevelEnum = {
  Basic: 'BASIC',
  Starter: 'STARTER',
  Growth: 'GROWTH',
  Mastery: 'MASTERY',
  Trial: 'TRIAL',
  None: 'NONE',
  Offer: 'OFFER',
  Challenge: 'CHALLENGE',
  Enterprise: 'ENTERPRISE',
  EnterpriseStarter: 'ENTERPRISE_STARTER',
  AllAccess: 'ALL_ACCESS',
} as const;

export type UserFilterLevelEnum = (typeof UserFilterLevelEnum)[keyof typeof UserFilterLevelEnum];
