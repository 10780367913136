import Link, {LinkProps} from 'antd/es/typography/Link';
import Paragraph, {ParagraphProps} from 'antd/es/typography/Paragraph';
import Text, {TextProps} from 'antd/es/typography/Text';
import Title, {TitleProps} from 'antd/es/typography/Title';
import classNames from 'classnames';
import {CSSProperties, ReactNode} from 'react';
import classes from './Typography.module.less';

export type GDTypographyType =
  | 'w50'
  | 'w100'
  | 'w200'
  | 'w300'
  | 'w400'
  | 'w500'
  | 'w600'
  | 'w700'
  | 'w800'
  | 'w900'
  | 'w2000';

export type GDTypographyProps = {
  className?: string;
  Component?: typeof Text | typeof Link | typeof Title | typeof Paragraph;
  type?: GDTypographyType;
  subType?: 's' | 'i' | 'is';
  block?: boolean;
  gutterBottom?: boolean;
  center?: boolean;
  children?: ReactNode;
  style?: CSSProperties;
};

export type GDElementTypographyProps = Omit<GDTypographyProps, 'Component'>;

function GDTypography({
  center,
  gutterBottom,
  className,
  Component = Text,
  type = 'w200',
  subType,
  children,
  block,
  ...rest
}: GDTypographyProps) {
  return (
    <Component
      className={classNames(
        classes[type],
        block && classes.block,
        subType && classes[subType],
        className,
        gutterBottom && classes.gutterBottom,
        center && classes.textCenter
      )}
      {...rest}
    >
      {children}
    </Component>
  );
}

GDTypography.Title = function (props: GDElementTypographyProps & Omit<TitleProps, 'type'>) {
  return <GDTypography Component={Title} {...props} />;
};

GDTypography.Text = function (props: GDElementTypographyProps & Omit<TextProps, 'type'>) {
  return <GDTypography Component={Text} {...props} />;
};

GDTypography.Paragraph = function (props: GDElementTypographyProps & Omit<ParagraphProps, 'type'>) {
  return <GDTypography Component={Paragraph} {...props} />;
};

GDTypography.Link = function (props: GDElementTypographyProps & Omit<LinkProps, 'type'>) {
  return <GDTypography Component={Link} {...props} />;
};

export default GDTypography;
