import {useDynamicConfig} from '@growthday/ui-core/src/features/feature-gate/hooks/useDynamicConfig';
import {
  DynamicConfigEnum,
  NavbarMenuItemsConfigDynamicConfig,
} from '@growthday/ui-core/src/features/feature-gate/types';
import {useMemo} from 'react';

export function useDynamicMenuItems(type?: string) {
  const {config} = useDynamicConfig<NavbarMenuItemsConfigDynamicConfig>(DynamicConfigEnum.NavbarMenuItemsConfig);

  return useMemo(() => {
    const menuItems = config?.value?.menuItems ?? [];
    if (type) {
      return menuItems.filter((menuItem) => menuItem.type === type);
    }
    return menuItems;
  }, [type, config]);
}
