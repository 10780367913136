import {CardElement as StripeCardElement, CardElementProps} from '@stripe/react-stripe-js';
import React, {FC, useState} from 'react';
import styles from './CardElement.module.less';
import classNames from 'classnames';

const CardElement: FC<CardElementProps> = ({...props}) => {
  const [focused, setFocused] = useState(false);
  return (
    <StripeCardElement
      className={classNames(styles.root, focused && styles.focused, 'userback-ignore')}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      options={{
        hidePostalCode: true,
        classes: {
          focus: 'focused',
          empty: 'empty',
          invalid: 'invalid',
        },
        style: {
          base: {
            color: '#444',
            fontWeight: '400',
            fontFamily: "'Open Sans', sans-serif",
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            '::placeholder': {
              color: '#bbc0c4',
            },
            ':-webkit-autofill': {
              color: '#888',
            },
          },
          invalid: {
            color: '#E25950',

            '::placeholder': {
              color: '#FFCCA5',
            },
          },
        },
      }}
      {...props}
    />
  );
};

export default CardElement;
