export enum LicenseEnum {
  JOURNAL_ALL_ACCESS = 'JOURNAL_ALL_ACCESS',
  LIFESCORE_ALL_ACCESS = 'LIFESCORE_ALL_ACCESS',
  CHALLENGE_ALL_ACCESS = 'CHALLENGE_ALL_ACCESS',
  EVENT_ALL_ACCESS = 'EVENT_ALL_ACCESS',
  PLAN_ALL_ACCESS = 'PLAN_ALL_ACCESS',
  GOAL_ALL_ACCESS = 'GOAL_ALL_ACCESS',
  VIDEO_COURSE_ALL_ACCESS = 'VIDEO_COURSE_ALL_ACCESS',
  AUDIO_COURSE_ALL_ACCESS = 'AUDIO_COURSE_ALL_ACCESS',
  JOURNAL_LIMITED_ACCESS = 'JOURNAL_LIMITED_ACCESS',
  PLAN_LIMITED_ACCESS = 'PLAN_LIMITED_ACCESS',
  GOAL_LIMITED_ACCESS = 'GOAL_LIMITED_ACCESS',
  GROWTHGROUP_ALL_ACCESS = 'GROWTHGROUP_ALL_ACCESS',
  COMMUNITY_ALL_ACCESS = 'COMMUNITY_ALL_ACCESS',
  WORKSHOP_LIVE_ACCESS = 'WORKSHOP_LIVE_ACCESS',
  EVENT_PAST_ACCESS = 'EVENT_PAST_ACCESS',
  AI_BOT_ACCESS = 'AI_BOT_ACCESS',
}
