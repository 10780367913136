import qs from 'qs';
import {EnumTooltipsTrigger} from '@growthday/ui-core/src/types/strapi';
import {EnumTourTriggerType, TourData} from '../../../features/tour/interface';
import {axiosGrowthDay} from '../index';

export type GetTourDataInput = {
  trigger?: EnumTooltipsTrigger;
  triggerType?: EnumTourTriggerType;
  triggerId?: string | number | null;
};

export const tourApi = {
  getTourData: (input: GetTourDataInput): Promise<TourData[]> => {
    const query = qs.stringify(input);
    return axiosGrowthDay.get(`/userTour?${query}`).then((res) => res.data);
  },
  createTour: (tour: Partial<TourData>): Promise<TourData> => {
    return axiosGrowthDay.post('/userTour', tour).then((res) => res.data);
  },
  updateTourProgress: (tour: Partial<TourData>): Promise<TourData> => {
    return axiosGrowthDay.put('/userTour/progress', tour).then((res) => res.data);
  },
  endTour: (tour: Partial<TourData>): Promise<TourData> => {
    return axiosGrowthDay.put('/userTour/endTour', tour).then((res) => res.data);
  },
};
