import {createSlice} from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';
import {isMobileOnly} from 'react-device-detect';

const initialState = {
  feedbackVisible: false,
  keyboardShortcutsVisible: false,
  canDetectTimezoneChange: true,
  editProfileVisible: false,
  addRelatedPlanVisible: false,
  addFocusAreasVisible: false,
  fullScreen: false,
  browsable: !isMobileOnly,
  hideMobileAppDownload: false,
  siderMinimized: false,
};

const persistConfig = {
  key: 'hpx:shell',
  storage: storage,
  whitelist: ['canDetectTimezoneChange'],
};

export const slideReducer = createSlice({
  name: 'shell',
  initialState,
  reducers: {
    toggleFeedback(state, action) {
      state.feedbackVisible = action.payload;
    },
    toggleSiderMinimize(state, action) {
      state.siderMinimized = action.payload;
    },
    toggleMobileBrowsable(state, action) {
      state.browsable = action.payload;
    },
    toggleKeyboardShortcuts(state, action) {
      state.keyboardShortcutsVisible = action.payload;
    },
    updateCanDetectTimezoneChange(state, action) {
      state.canDetectTimezoneChange = action.payload;
    },
    toggleEditProfile(state, action) {
      state.editProfileVisible = action.payload;
    },
    toggleEditRelatedPlan(state, action) {
      state.addRelatedPlanVisible = action.payload;
    },
    toggleFocusAreas(state, action) {
      state.addFocusAreasVisible = action.payload;
    },
    toggleFullScreen(state, action) {
      state.fullScreen = action.payload;
    },
    toggleHideMobileAppDownload(state, action) {
      state.hideMobileAppDownload = action.payload;
    },
  },
});

export const {
  toggleMobileBrowsable,
  toggleFeedback,
  toggleKeyboardShortcuts,
  updateCanDetectTimezoneChange,
  toggleEditProfile,
  toggleEditRelatedPlan,
  toggleFocusAreas,
  toggleFullScreen,
  toggleSiderMinimize,
  toggleHideMobileAppDownload,
} = slideReducer.actions;

export default persistReducer(persistConfig, slideReducer.reducer);
