export interface StatusResponse {
  status?: StatusGDPREnum;
  eraseToken?: string;
  requestTimestamp?: number;
  sentTimestamp?: number;
  executedTimestamp?: number;
}

export enum StatusGDPREnum {
  EMPTY = 'EMPTY',
  SUBMITTED = 'SUBMITTED',
  RUNNING = 'running',
  READY = 'READY',
  DELETED = 'DELETED',
}
