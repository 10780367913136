import {Overlay, OverlayProps} from '@/components/overlay';
import classNames from 'classnames';

export type TooltipProps = OverlayProps;
export function Tooltip(props: TooltipProps) {
  return (
    <Overlay
      darkMode
      padding="small"
      trigger={['focus', 'hover']}
      zIndex={2000}
      {...props}
      className={classNames('tooltip', props.className)}
    />
  );
}
