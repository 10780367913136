import {GrowthGroupFilters} from '../../../types/growth-groups';
export function makeFilterSearchParams(filters: GrowthGroupFilters = {}): Record<string, any> {
  const searchParams: Record<string, any> = {};

  if (filters.type) {
    searchParams.channel = filters.type;
  }

  if (filters.duration) {
    searchParams.duration = filters.duration;
  }

  if (filters.startTime) {
    switch (filters.startTime) {
      case 'LIVE_NOW':
        searchParams.dateRange = 'LIVE';
        break;
      case 'LIVE_TODAY':
        searchParams.dateRange = 'TODAY';
        break;
      case 'THIS_WEEK':
        searchParams.dateRange = 'WEEK';
        break;
      case 'THIS_MONTH':
        searchParams.dateRange = 'MONTH';
        break;
    }
  }

  if (typeof filters.host === 'boolean') {
    searchParams.isHost = filters.host;
  }

  if (typeof filters.attending === 'boolean') {
    searchParams.isParticipant = filters.attending;
  }

  if (filters.visibility) {
    searchParams.type = filters.visibility;
  }

  return searchParams;
}
