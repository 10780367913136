import {createApi} from '@reduxjs/toolkit/query/react';
import {IAchievements} from '../../types/strapi';
import {axiosBaseQuery} from '../../utils/api';
import {getStrapiAchievementsOptions} from './handlers/getStrapiAchievements';
import {
  AchievementsResponse,
  InProgressAchievementResponse,
  InProgressPaginationParams,
} from '../../types/achievements';
import {getAchievementsOptions} from './handlers/getAchievements';
import {getInProgressAchievementsOptions} from './handlers/getInProgressAchievements';
import {invalidateAchievementsDataOptions} from './handlers/invalidateAchievementsData';

export const achievementsApiReducerPath = 'achievementsApi';
export const storeTag = 'achievements-data';

const achievementsApi = createApi({
  reducerPath: achievementsApiReducerPath,
  baseQuery: axiosBaseQuery(),
  tagTypes: [storeTag],
  endpoints: (builder) => ({
    getInProgressAchievements: builder.query<InProgressAchievementResponse, InProgressPaginationParams>(
      getInProgressAchievementsOptions
    ),
    getAchievements: builder.query<AchievementsResponse, void>(getAchievementsOptions),
    getStrapiAchievements: builder.query<IAchievements[], number[]>(getStrapiAchievementsOptions),
    invalidateAllAchievementData: builder.mutation<string, void>(invalidateAchievementsDataOptions),
  }),
});

export default achievementsApi;
