import mem, {memClear} from 'mem';

const EXPIRE_TIME_KEY = 'GD_EXPIRE_TIME';

export const setExpireTime = (time: string) => {
  localStorage.setItem(EXPIRE_TIME_KEY, time);
};

const memoizedGetExpireTime = () => {
  const expireTime = localStorage.getItem(EXPIRE_TIME_KEY);
  if (expireTime) {
    return expireTime;
  }
};

export const clearStorageExpireTime = () => {
  localStorage.removeItem(EXPIRE_TIME_KEY);
};

export const memoExpireTime = mem(memoizedGetExpireTime);

export const getExpireTimeToken = () => {
  const expireTime = memoExpireTime();
  if (expireTime) {
    return expireTime;
    // If there is no token, then clear cache and try again (this usually happens during login)
  } else {
    clearExpireTimeCache();
    return memoExpireTime();
  }
};

export const clearExpireTimeCache = () => {
  memClear(memoExpireTime);
};
