import {MenuItemIcon} from '@/features/shell/components/Menu/MenuItemIcon';
import {getStaticAssetUrl} from '@/shared/util/get-static-asset-url';
import {imageUrlFromMedia} from '@growthday/ui-core/src/features/media-player/utils/image-url-from-media';
import {useAuthData} from '@growthday/ui-core/src/hooks/useAuthData';
import {useWebSocket} from '@growthday/ui-core/src/hooks/useWebSocket';
import {reactQueryClient} from '@growthday/ui-core/src/Provider';
import {Notifications_Topic} from '@growthday/ui-core/src/Provider/WebSocketProvider/topics';
import {IInAppNotifications, NotificationsTopic} from '@growthday/ui-core/src/types/websocket';
import classNames from 'classnames';
import classnames from 'classnames';
import {produce} from 'immer';
import {WritableDraft} from 'immer/dist/types/types-external';
import {nanoid} from 'nanoid';
import urlJoin from 'proper-url-join';
import React, {FC, useMemo} from 'react';
import {hashQueryKey} from 'react-query';
import commonStyles from '../../../../../shared/style/commonStyles.module.less';
import useGetNotificationsQuery, {
  NOTIFICATIONS_PAGE_SIZE,
  NOTIFICATIONS_QUERY_KEY,
} from '../../../../notifications/hooks/useGetNotificationsQuery';
import styles from './NotificationsMenuIcon.module.less';

export const NotificationsMenuEmptyIcon: FC<{isActive?: boolean}> = ({isActive}) => {
  const url = useMemo(() => imageUrlFromMedia(urlJoin(getStaticAssetUrl('icons', 'notifications.svg'))), []);
  return <MenuItemIcon isActive={isActive} url={url} />;
};

const NotificationsMenuIcon: FC<{isActive?: boolean}> = (props) => {
  const {user} = useAuthData();
  const {data} = useGetNotificationsQuery();
  const unreadNotifications = useMemo(
    () => (data?.pages?.[0]?.list?.filter((elem) => !elem.hasBeenRead) ?? []).length,
    [data?.pages]
  );
  const notificationTopic = Notifications_Topic(user?.uuid ?? '');

  useWebSocket(
    hashQueryKey(['NOTIFICATIONS', 'LISTEN_MESSAGES', notificationTopic]),
    notificationTopic,
    (event: NotificationsTopic) => {
      reactQueryClient.setQueryData<typeof data>(NOTIFICATIONS_QUERY_KEY, (old) => {
        return produce(old, (draft) => {
          if (draft) {
            const latestPage = draft.pages[0]; // Notifications fetched by most recent
            if (latestPage) {
              const totalCount = latestPage.count + 1; // Update total count for all pages

              // Shift all entries by 1 across pages

              // This entry is added to the beginning of the page
              // Changes during iteration
              let entryToAdd: IInAppNotifications | WritableDraft<IInAppNotifications> | null = {
                ...event.data.inAppNotification,
                id: event.data.inAppNotification.id ?? nanoid(5),
              };

              for (const page of draft.pages) {
                page.count = totalCount;
                if (entryToAdd) {
                  page.list.unshift(entryToAdd);
                  if (page.list.length > NOTIFICATIONS_PAGE_SIZE) {
                    entryToAdd = page.list.pop()!;
                  } else {
                    entryToAdd = null;
                  }
                }
              }

              // Create a new page if needed
              if (entryToAdd) {
                const page = draft.pages.length + 1;
                draft.pageParams.push({page, size: NOTIFICATIONS_PAGE_SIZE});
                draft.pages.push({
                  list: [entryToAdd],
                  count: totalCount,
                  page,
                  size: NOTIFICATIONS_PAGE_SIZE,
                });
              }
            }
          }
          return draft;
        });
      });
    },
    !!user?.uuid
  );

  return (
    <div className={classnames(commonStyles.flexCenter, commonStyles.positionRelative)}>
      {unreadNotifications ? (
        <div className={classNames(styles.counter, 'notification-icon-counter')}>
          {unreadNotifications > 9 ? '' : unreadNotifications}
        </div>
      ) : null}
      <NotificationsMenuEmptyIcon {...props} />
    </div>
  );
};

export default NotificationsMenuIcon;
