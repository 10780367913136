import {IUser} from '@/shared/services_deprecated/model/user';
import {ISubscriptionPlan} from '@/features/auth/interfaces';
import {getGtagActions} from '@/features/analytics/hooks/useAnalyticsActions';
import {IOffersNonNested} from '@growthday/ui-core/src/types/offers';

export function getGtagEvents() {
  return {
    // specific tracking actions
    identifyUser(user?: IUser) {
      if (user?.uuid) {
        getGtagActions().identify(user.uuid);
        getGtagActions().alias(user.uuid);
        if (user?.id) {
          getGtagActions().alias(user.uuid, user.id);
        }
        getGtagActions().traits({
          user_id: user.uuid,
          email: user.email,
        });
      }
    },
    accountCreated(user?: IUser) {
      if (user?.id) {
        this.identifyUser(user);
        getGtagActions().track('account_created');
      }
    },
    addUpsell(offer: IOffersNonNested) {
      if (offer) {
        getGtagActions().track('upsell_added', {
          product_name: offer.helpText || offer.title,
          product_id: offer.id,
          order_id: '',
          price: offer.price,
        });
      }
    },
    trialStarted(subscription?: ISubscriptionPlan) {
      if (subscription) {
        getGtagActions().track('trial_started', {
          product_name: subscription.name,
          product_id: subscription.id,
          order_id: '',
          price: subscription.amount,
        });
      }
    },
    purchase(subscription?: ISubscriptionPlan) {
      if (subscription) {
        getGtagActions().track('purchase', {
          product_name: subscription.name,
          product_id: subscription.id,
          order_id: '',
          price: subscription.amount,
        });
      }
    },
  };
}
