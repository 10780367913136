/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface SavedContentRequest
 */
export interface SavedContentRequest {
  /**
   *
   * @type {string}
   * @memberof SavedContentRequest
   */
  contentType?: SavedContentRequestContentTypeEnum;
  /**
   *
   * @type {number}
   * @memberof SavedContentRequest
   */
  recommendationId?: number;
  /**
   *
   * @type {string}
   * @memberof SavedContentRequest
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof SavedContentRequest
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof SavedContentRequest
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof SavedContentRequest
   */
  assessment?: string;
  /**
   *
   * @type {number}
   * @memberof SavedContentRequest
   */
  assessmentScore?: number;
  /**
   *
   * @type {string}
   * @memberof SavedContentRequest
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof SavedContentRequest
   */
  wistiaUrl?: string;
  /**
   *
   * @type {number}
   * @memberof SavedContentRequest
   */
  lessonId?: number;
  /**
   *
   * @type {number}
   * @memberof SavedContentRequest
   */
  courseId?: number;
  /**
   *
   * @type {string}
   * @memberof SavedContentRequest
   */
  titleUid?: string;
  /**
   *
   * @type {string}
   * @memberof SavedContentRequest
   */
  lessonThumbnail?: string;
  /**
   *
   * @type {string}
   * @memberof SavedContentRequest
   */
  wistiaThumbnail?: string;
  /**
   *
   * @type {string}
   * @memberof SavedContentRequest
   */
  assessmentCategoryType?: string;
}

export const SavedContentRequestContentTypeEnum = {
  LifeScore: 'LIFE_SCORE',
} as const;

export type SavedContentRequestContentTypeEnum =
  (typeof SavedContentRequestContentTypeEnum)[keyof typeof SavedContentRequestContentTypeEnum];
