import {useLocalStorage} from '@rehooks/local-storage';
import {v4} from 'uuid';
import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';

const useDeviceId = () => {
  const {user} = useUser();
  const [deviceId] = useLocalStorage('GD_DEVICE_ID', v4());
  return user ? user.uuid : deviceId;
};

export default useDeviceId;
