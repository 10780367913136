import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {stateWithLoading} from '../../shared/redux/util/state-with-loading';
import _unset from 'lodash/unset';
import storage from 'redux-persist/lib/storage/session';
import {persistReducer} from 'redux-persist';
import {
  SidebarCalendarData,
  SidebarCalendarEntriesMap,
  SidebarCalendarLoadingState,
  SidebarCalendarState,
} from './interface';
import dayjs from 'dayjs';
import * as apiActions from './sidebarCalendar.actions';
import {getApiActionBuilder} from '../../shared/redux/util/build-api-action';

const persistConfig = {
  key: 'hpx:sidebarCalendar',
  storage,
  whitelist: ['selectedDate', 'collapsed', 'tab'],
};

const initialState = stateWithLoading<SidebarCalendarState, SidebarCalendarLoadingState>({
  selectedDate: dayjs().startOf('d').toISOString(),
  collapsed: true,
  tab: 0,
  entriesMap: {},
  strapiCourses: [],
  growthPromptCategories: [],
  strapiChallenges: [],
});

export const sidebarCalendarSlice = createSlice<
  SidebarCalendarState,
  {
    unsetSidebarCalendarState: CaseReducer<SidebarCalendarState, PayloadAction>;
    updateSidebarCalendarSelectedDate: CaseReducer<SidebarCalendarState, PayloadAction<string>>;
    updateSidebarCalendarCollapsed: CaseReducer<SidebarCalendarState, PayloadAction<boolean>>;
    updateSidebarCalendarTab: CaseReducer<SidebarCalendarState, PayloadAction<number>>;
  }
>({
  name: 'sidebarCalendar',
  initialState,
  reducers: {
    unsetSidebarCalendarState(state) {
      const keys = ['selectedDate', 'collapsed', 'tab'];
      keys.forEach((key) => {
        _unset(state, key);
      });
    },
    updateSidebarCalendarSelectedDate(state, action) {
      state.selectedDate = action.payload;
    },
    updateSidebarCalendarCollapsed(state, action) {
      state.collapsed = action.payload;
    },
    updateSidebarCalendarTab(state, action) {
      state.tab = action.payload;
    },
  },
  extraReducers: (builder) => {
    const buildApiAction = getApiActionBuilder<SidebarCalendarState>(builder, apiActions);

    buildApiAction<PayloadAction<SidebarCalendarEntriesMap>>('getSidebarCalendarEntries', {
      fulfilled: (state, action) => {
        state.entriesMap = action.payload;
      },
    });

    buildApiAction<PayloadAction<SidebarCalendarData>>('getSidebarCalendarData', {
      fulfilled: (state, action) => {
        state.strapiCourses = action.payload.strapiCourses;
        state.growthPromptCategories = action.payload.growthPromptCategories;
        state.strapiChallenges = action.payload.strapiChallenges;
      },
    });
  },
});

export const {
  unsetSidebarCalendarState,
  updateSidebarCalendarSelectedDate,
  updateSidebarCalendarCollapsed,
  updateSidebarCalendarTab,
} = sidebarCalendarSlice.actions;

export default persistReducer(persistConfig, sidebarCalendarSlice.reducer);
