/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface TeamMemberFilter
 */
export interface TeamMemberFilter {
  /**
   *
   * @type {string}
   * @memberof TeamMemberFilter
   */
  memberStatus?: TeamMemberFilterMemberStatusEnum;
  /**
   *
   * @type {number}
   * @memberof TeamMemberFilter
   */
  offset?: number;
  /**
   *
   * @type {number}
   * @memberof TeamMemberFilter
   */
  limit?: number;
  /**
   *
   * @type {string}
   * @memberof TeamMemberFilter
   */
  sortBy?: TeamMemberFilterSortByEnum;
  /**
   *
   * @type {string}
   * @memberof TeamMemberFilter
   */
  order?: TeamMemberFilterOrderEnum;
  /**
   *
   * @type {string}
   * @memberof TeamMemberFilter
   */
  exportType?: TeamMemberFilterExportTypeEnum;
}

export const TeamMemberFilterMemberStatusEnum = {
  All: 'ALL',
  InvitationPending: 'INVITATION_PENDING',
  Deactivated: 'DEACTIVATED',
  Active: 'ACTIVE',
} as const;

export type TeamMemberFilterMemberStatusEnum =
  (typeof TeamMemberFilterMemberStatusEnum)[keyof typeof TeamMemberFilterMemberStatusEnum];
export const TeamMemberFilterSortByEnum = {
  CreatedOn: 'createdOn',
  Name: 'name',
  Email: 'email',
} as const;

export type TeamMemberFilterSortByEnum = (typeof TeamMemberFilterSortByEnum)[keyof typeof TeamMemberFilterSortByEnum];
export const TeamMemberFilterOrderEnum = {
  Asc: 'asc',
  Desc: 'desc',
} as const;

export type TeamMemberFilterOrderEnum = (typeof TeamMemberFilterOrderEnum)[keyof typeof TeamMemberFilterOrderEnum];
export const TeamMemberFilterExportTypeEnum = {
  Csv: 'CSV',
  Xlsx: 'XLSX',
} as const;

export type TeamMemberFilterExportTypeEnum =
  (typeof TeamMemberFilterExportTypeEnum)[keyof typeof TeamMemberFilterExportTypeEnum];
