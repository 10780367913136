import {useMemo, useState} from 'react';
import plansApi from './plans.api';
import {hydratePlan} from './utils/hydratePlans';

const {useGetPlansQuery: useGetPlansQueryBase, useGetPlanQuery: useGetPlanQueryBase} = plansApi;
export const {
  useDeletePlanMutation,
  useDeleteTaskMutation,
  useDeleteSubTaskMutation,
  useGetCompletedPlansQuery,
  useCreatePlanMutation,
  useCreateTaskMutation,
  useCreateSubTaskMutation,
  useUpdatePlanMutation,
  useUpdateTaskMutation,
  useUpdateSubTaskMutation,
  useCompleteAndRecoverMutation,
  useResolveDueDateConflictMutation,
  useBulkUpsertSubtasksMutation,
  useReorderMutation,
} = plansApi;

export {useGetPlansQueryBase as useGetPlansMap};

export const useGetPlansQuery = () => {
  const result = useGetPlansQueryBase(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const data = useMemo(
    () => result.data && result.data.plansArr.tasks.map((id) => hydratePlan(id, result.data!)),
    [result.data]
  );
  return {...result, data};
};

export const useGetPlanQuery = (id: string) => {
  const {data: _data, ...resultBase} = useGetPlansQueryBase(undefined);

  const data = useMemo(() => {
    if (_data && id in _data.records) return hydratePlan(id, _data);
    return undefined;
  }, [_data, id]);

  const [skip, setSkip] = useState(true);

  const {refetch, ...resultPlanQuery} = useGetPlanQueryBase(id, {
    skip,
  });

  const handleRefetch = () => {
    setSkip(false);
    refetch();
  };

  return {
    ...(skip ? resultBase : resultPlanQuery),
    refetch: handleRefetch,
    data,
  };
};
