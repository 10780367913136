import {IContent} from './content';

/**
 * Model definition for videoOverlay
 */
export interface IVideoOverlay {
  id: number;
  upgradeLevel?: EnumVideoOverlayUpgradeLevel;
  status?: EnumVideoOverlayStatus;
  content?: IContent;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumVideoOverlayUpgradeLevel {
  ALL_ACCESS_MONTH = 'ALL_ACCESS_MONTH',
  ALL_ACCESS_YEAR = 'ALL_ACCESS_YEAR',
}

export enum EnumVideoOverlayStatus {
  NOT_VISIBLE = 'NOT_VISIBLE',
  ONLY_AUDIO = 'ONLY_AUDIO',
  STOP_STREAM = 'STOP_STREAM',
}
