import {createSlice, current, PayloadAction} from '@reduxjs/toolkit';
import * as apiActions from './myProfile.actions';
import _unset from 'lodash/unset';
import {getApiActionBuilder} from '../../../../../../shared/redux/util/build-api-action';
import coerceArray from '../../../../../../shared/util/coerceArray';
import {MyProfileState, IMyProfileLoadingState} from './interfaces';
import {stateWithLoading} from '../../../../../../shared/redux/util/state-with-loading';

const initialState = stateWithLoading<MyProfileState, IMyProfileLoadingState>({
  goals: [],
  bigWhyText: '',
  focusAreas: [],
  plans: [],
  selectedPlan: {
    title: '',
  },
  selectedFocusAreas: [],
  coinsEarnedFromLastAction: 0,
});

export const myProfileSlice = createSlice({
  name: 'myProfile',
  initialState,
  reducers: {
    setSelectedPlan(state, action) {
      state.selectedPlan = action.payload;
    },

    setCoinsEarnedFromLastAction(state) {
      state.coinsEarnedFromLastAction = 0;
    },

    undoSelectedPlan(state) {
      state.selectedPlan = {
        title: '',
      };
    },

    setFocusAreas(state, action) {
      state.selectedFocusAreas = action.payload;
    },

    undoFocusAreas(state) {
      state.selectedFocusAreas = [];
    },

    unsetOnboardingState(state, action) {
      const keys = coerceArray(action.payload);
      keys.forEach((key) => {
        _unset(state, key);
      });
    },
  },
  extraReducers: (builder) => {
    const buildApiAction = getApiActionBuilder<MyProfileState>(builder, apiActions);

    buildApiAction<PayloadAction<any>>('getProfileData', {
      fulfilled: (state, action) => {
        state.bigWhyText = action.payload.data.bigWhyText;
        state.focusAreas = action.payload.data.focusAreas;
      },
    });

    buildApiAction<PayloadAction<any>>('updateProfile', {
      fulfilled: (state, action) => {
        state.bigWhyText = action.payload.data.bigWhyText;
        state.focusAreas = action.payload.data.focusAreas;
        state.coinsEarnedFromLastAction = action.payload.success.data.coinsEarnedThisTime;
      },
    });

    buildApiAction<PayloadAction<any>>('getProfileGoals', {
      fulfilled: (state, action) => {
        state.goals = action.payload;
      },
    });

    buildApiAction<PayloadAction<any>>('createNewGoal', {
      fulfilled: (state, action) => {
        state.goals.splice(0, 0, action.payload.data);
      },
    });

    buildApiAction<PayloadAction<any>>('createNewMilestone', {
      fulfilled: (state, action) => {
        const oldElement = current(state).goals.find((elem) => elem.id === action.payload.id);
        const indexOfOldElement = oldElement && current(state).goals.indexOf(oldElement);
        if (typeof indexOfOldElement === 'number' && indexOfOldElement >= 0) {
          current(state).goals[indexOfOldElement].goalMilestoneDtos
            ? state.goals[indexOfOldElement].goalMilestoneDtos?.push(action.payload.value)
            : (state.goals[indexOfOldElement].goalMilestoneDtos = [action.payload.value]);
          const elementToMove = state.goals[indexOfOldElement];
          state.goals.splice(indexOfOldElement, 1);
          state.goals.splice(0, 0, elementToMove);
        }
      },
    });

    buildApiAction<PayloadAction<any>>('updateSomeGoal', {
      fulfilled: (state, action) => {
        const oldElement = current(state).goals.find((elem) => elem.id === action.payload.response.id);
        const indexOfOldElement = oldElement && current(state).goals.indexOf(oldElement);
        if (typeof indexOfOldElement === 'number' && indexOfOldElement >= 0) {
          state.goals.splice(indexOfOldElement, 1);
          state.goals.splice(0, 0, action.payload.response);
        }
      },
    });

    buildApiAction<PayloadAction<any>>('updateSomeMilestone', {
      fulfilled: (state, action) => {
        const oldElement = current(state).goals.find((elem) => elem.id === action.payload.goalId);
        const indexOfOldElement = oldElement && current(state).goals.indexOf(oldElement);

        let milestonesArray;
        if (indexOfOldElement !== undefined && indexOfOldElement >= 0) {
          const currentMilestonesArray = current(state).goals[indexOfOldElement].goalMilestoneDtos;
          if (currentMilestonesArray) {
            milestonesArray = currentMilestonesArray.map((elem) => {
              if (elem.milestoneId === action.payload.value.milestoneId) {
                return action.payload.value;
              }
              return elem;
            });
          }
        }

        if (typeof indexOfOldElement === 'number' && indexOfOldElement >= 0 && milestonesArray) {
          state.goals[indexOfOldElement].goalMilestoneDtos = milestonesArray;
          const elementToMove = state.goals[indexOfOldElement];
          state.goals.splice(indexOfOldElement, 1);
          state.goals.splice(0, 0, elementToMove);
        }
      },
    });

    buildApiAction<PayloadAction<any>>('updateMilestoneList', {
      fulfilled: (state, action) => {
        const oldElement = current(state).goals.find((elem) => elem.id === action.payload.goalId);
        const indexOfOldElement = oldElement && current(state).goals.indexOf(oldElement);
        if (typeof indexOfOldElement === 'number' && indexOfOldElement >= 0) {
          state.goals[indexOfOldElement].goalMilestoneDtos = action.payload.value;
        }
      },
    });

    buildApiAction<PayloadAction<any>>('markGoalAsComplete', {
      fulfilled: (state, action) => {
        const indexOfOldElement = current(state).goals.findIndex((elem) => elem.id === action.payload.id);
        if (typeof indexOfOldElement === 'number' && indexOfOldElement >= 0) {
          state.goals[indexOfOldElement] = {
            ...current(state).goals[indexOfOldElement],
            complete: true,
          };
        }
      },
    });

    buildApiAction<PayloadAction<any>>('markGoalAsIncomplete', {
      fulfilled: (state, action) => {
        const indexOfOldElement = current(state).goals.findIndex((elem) => elem.id === action.payload.id);
        if (typeof indexOfOldElement === 'number' && indexOfOldElement >= 0) {
          const elementToInsert = state.goals[indexOfOldElement];
          state.goals.splice(indexOfOldElement, 1);
          elementToInsert.complete = false;
          state.goals.splice(0, 0, elementToInsert);
        }
      },
    });

    buildApiAction<PayloadAction<any>>('markMilestoneAsComplete', {
      fulfilled: (state, action) => {
        const oldElement = current(state).goals.find((elem) => elem.id === action.payload.goalId);
        const indexOfOldElement = current(state).goals.indexOf(oldElement!);

        const oldMilestonesArray = current(state).goals[indexOfOldElement!].goalMilestoneDtos;
        const oldMilestone =
          oldMilestonesArray && oldMilestonesArray.find((elem) => elem.milestoneId === action.payload.milestoneId);
        const oldMilestonesArrayWithoutEditableOne =
          oldMilestonesArray && oldMilestonesArray.filter((elem) => elem.milestoneId !== action.payload.milestoneId);

        if (
          typeof indexOfOldElement === 'number' &&
          indexOfOldElement >= 0 &&
          oldMilestonesArrayWithoutEditableOne &&
          oldMilestone
        ) {
          state.goals[indexOfOldElement].goalMilestoneDtos = [
            ...oldMilestonesArrayWithoutEditableOne,
            {...oldMilestone, complete: true},
          ];
        }
      },
    });

    buildApiAction<PayloadAction<any>>('markMilestoneAsIncomplete', {
      fulfilled: (state, action) => {
        const oldElement = current(state).goals.find((elem) => elem.id === action.payload.goalId);
        const indexOfOldElement = current(state).goals.indexOf(oldElement!);

        const oldMilestonesArray = current(state).goals[indexOfOldElement!].goalMilestoneDtos;
        const oldMilestone =
          oldMilestonesArray && oldMilestonesArray.find((elem) => elem.milestoneId === action.payload.milestoneId);
        const oldMilestonesArrayWithoutEditableOne =
          oldMilestonesArray && oldMilestonesArray.filter((elem) => elem.milestoneId !== action.payload.milestoneId);

        if (
          typeof indexOfOldElement === 'number' &&
          indexOfOldElement >= 0 &&
          oldMilestonesArrayWithoutEditableOne &&
          oldMilestone
        ) {
          state.goals[indexOfOldElement].goalMilestoneDtos = [
            ...oldMilestonesArrayWithoutEditableOne,
            {...oldMilestone, complete: false},
          ];
        }
      },
    });

    buildApiAction<PayloadAction<any>>('deleteSomeGoal', {
      fulfilled: (state, action) => {
        const oldElement = current(state).goals.find((elem) => elem.id === action.payload.id);
        if (oldElement) state.goals = current(state).goals.filter((elem) => elem !== oldElement);
      },
    });

    buildApiAction<PayloadAction<any>>('deleteSomeMilestone', {
      fulfilled: (state, action) => {
        const oldElement = current(state).goals.find((elem) => elem.id === action.payload.goalId);
        const indexOfOldElement = oldElement && current(state).goals.indexOf(oldElement);

        const oldMilestonesArray =
          typeof indexOfOldElement === 'number' &&
          indexOfOldElement !== -1 &&
          current(state).goals[indexOfOldElement].goalMilestoneDtos;
        const oldMilestonesArrayWithoutDeletedOne =
          oldMilestonesArray && oldMilestonesArray.filter((elem) => elem.milestoneId !== action.payload.milestoneId);

        if (typeof indexOfOldElement === 'number' && indexOfOldElement >= 0 && oldMilestonesArrayWithoutDeletedOne) {
          state.goals[indexOfOldElement].goalMilestoneDtos = oldMilestonesArrayWithoutDeletedOne;
          const elementToMove = state.goals[indexOfOldElement];
          state.goals.splice(indexOfOldElement, 1);
          state.goals.splice(0, 0, elementToMove);
        }
      },
    });

    buildApiAction<PayloadAction<any>>('getAllPlans', {
      fulfilled: (state, action) => {
        state.plans = action.payload;
      },
    });
  },
});

export const {setSelectedPlan, undoSelectedPlan, setFocusAreas, undoFocusAreas, setCoinsEarnedFromLastAction} =
  myProfileSlice.actions;

export default myProfileSlice.reducer;
