import {ISubscriptionPlans} from './subscription-plans';

/**
 * Model definition for PlanSelect
 */
export interface IPlanSelect {
  id: number;
  heading?: string;
  chipText?: string;
  caption?: string;
  cta?: string;
  footerText?: string;
  frequency: EnumPlanSelectFrequency;
  plan?: ISubscriptionPlans;
  defaultSelected?: boolean;
  name?: string;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumPlanSelectFrequency {
  YEARLY = 'YEARLY',
  MONTHLY = 'MONTHLY',
}
