import {EnvironmentsEnum} from '@/config/enums';
import dev from '@/customization/growthday/environment/environment.dev';
import uat from '@/customization/growthday/environment/environment.uat';
import prod from '@/customization/growthday/environment/environment.prod';
import {Environments} from '@/customization/typings/environment';

export const environments: Environments = {
  [EnvironmentsEnum.DEV]: dev,
  [EnvironmentsEnum.UAT]: uat,
  [EnvironmentsEnum.PROD]: prod,
};
