import {createApi} from '@reduxjs/toolkit/query/react';
import {ICoinsByEvent, ICoinsByPeriod} from '../../types/coins';
import {IGrowthCoins} from '../../types/strapi';
import {axiosBaseQuery, axiosStrapiBaseQuery} from '../../utils/api';

export const coinsApiReducerPath = 'coinsApi';
export const coinsApiStrapiReducerPath = 'coinsApiStrapi';
export const storeTag = 'coins-stat-data';
export const coinsApi = createApi({
  reducerPath: coinsApiReducerPath,
  baseQuery: axiosBaseQuery() as any,
  tagTypes: [storeTag],
  endpoints: (builder) => ({
    getTotalAndQuarterlyCoins: builder.query<ICoinsByPeriod, string>({
      query: () => `growthcoin/quarterly`,
      providesTags: [storeTag],
    }),
    getCoinsByEvent: builder.query<ICoinsByEvent, string>({
      query: () => '/growthcoin/group-by-event',
      providesTags: [storeTag],
    }),
    invalidateCoinStats: builder.mutation<void, undefined>({
      async queryFn() {
        return {data: undefined};
      },
      invalidatesTags: [storeTag],
    }),
  }),
});

export const coinsStrapiApi = createApi({
  reducerPath: coinsApiStrapiReducerPath,
  baseQuery: axiosStrapiBaseQuery() as any,
  endpoints: (builder) => ({
    getCoinsInfo: builder.query<IGrowthCoins, string>({
      query: () => `growth-coins`,
    }),
  }),
});
