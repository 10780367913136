import plansApi from './plans.api';
import {EnhancedStore, Reducer} from '@reduxjs/toolkit';

// Should be filled carefully. Manually typed as const and everything relies on this
export const plansApiReducer = {
  [plansApi.reducerPath]: plansApi.reducer,
};

export type PlansState = {
  [K in keyof PlansReducerMap]: PlansReducerMap[K] extends Reducer<infer S> ? S : never;
};
export type PlansReducerMap = typeof plansApiReducer;
export type PlansStore = EnhancedStore<PlansState>;
export const plansApiMiddlewares = [plansApi.middleware] as const;
export {default} from './plans.api';
export * from '../../types/plans';
export * from './hooks';
