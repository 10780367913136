import {IOffersNonNested} from '@growthday/ui-core/src/types/offers';
import classNames from 'classnames';
import React, {FC, useEffect} from 'react';
import Carousel from '../../../shared/components/ui/Carousel/Carousel';
import CheckboxListCard from '../../../shared/components/ui/CheckboxListCard/CheckboxListCard';
import GDTypography from '../../../shared/components/ui/Typography/Typography';
import {getAnalyticsActions} from '@/features/analytics/hooks/useAnalyticsActions';
import commonStyles from '../../../shared/style/commonStyles.module.less';
import getBulletPointsArray from '../../../shared/util/getBulletPointsArray';
import startCase from 'lodash/startCase';
import xorBy from 'lodash/xorBy';
import useAuthState from '../../auth/hooks/useAuthState';

export type OrderBumpProps = {
  offers: IOffersNonNested[];
  value?: IOffersNonNested[];
  onChange?: (offers: IOffersNonNested[]) => void;
};

const OrderBump: FC<OrderBumpProps> = ({offers, value, onChange}) => {
  const {triggerInfo} = useAuthState();

  useEffect(() => {
    offers.forEach((offer) => {
      setTimeout(() => {
        getAnalyticsActions().track('Offer View', {
          'Offer Name': offer.title,
          'Offer Price': offer.price,
          ...(triggerInfo?.triggerFlow ? {Trigger: triggerInfo?.triggerFlow} : {}),
          ...(triggerInfo?.triggeredFromSection ? {'Locked button': triggerInfo?.triggeredFromSection} : {}),
          ...(triggerInfo?.triggeredContentName ? {'Locked Content': triggerInfo?.triggeredContentName} : {}),
        });
      }, 1000);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerInfo]);

  const handleSelectOffer = (offer: IOffersNonNested) => {
    const newOffers = xorBy(value, [offer], (iteratee) => iteratee.id);
    if (offer.title && !value?.some((o) => o.id === offer.id)) {
      getAnalyticsActions().track('Offer Check', {
        'Offer Name': offer.title,
        'Offer Price': offer.price,
        ...(triggerInfo?.triggerFlow ? {Trigger: triggerInfo?.triggerFlow} : {}),
        ...(triggerInfo?.triggeredFromSection ? {'Locked button': triggerInfo?.triggeredFromSection} : {}),
        ...(triggerInfo?.triggeredContentName ? {'Locked Content': triggerInfo?.triggeredContentName} : {}),
      });
    }
    onChange?.(newOffers);
  };

  return (
    <Carousel slidesPerRow={1}>
      {offers.map((offer) => (
        <CheckboxListCard
          className={classNames('gd-offer-card', `gd-offer-card-${offer.id}`)}
          key={offer.id}
          selected={Boolean(value?.some((o) => o.id === offer.id))}
          onClick={() => handleSelectOffer(offer)}
          subTitle={
            <>
              <GDTypography type="w500" subType="s">
                {offer.title}, ${offer.price}
                <GDTypography type="w100" className={classNames(commonStyles.fadeText, commonStyles.textNormal)}>
                  {' '}
                  / {startCase(offer.interval)}
                </GDTypography>
              </GDTypography>
              <GDTypography block type="w50" className={classNames(commonStyles.fadeText, commonStyles.textNormal)}>
                plus applicable taxes
              </GDTypography>
            </>
          }
          title="Recommended Upgrade"
          items={getBulletPointsArray(offer.description)}
        />
      ))}
    </Carousel>
  );
};

export default OrderBump;
