import {useGetFeatures} from '@/features/feature-disable/hooks/useGetFeatures';
import {useMemo} from 'react';
import {ConfigResponseFeaturesEnum} from '@growthday/ui-core/src/types/api/config-response';

export function useIsFeatureEnabled(feature: ConfigResponseFeaturesEnum | null | undefined) {
  const {data} = useGetFeatures();

  return useMemo(() => !!feature && !!data?.featureMap[feature], [data?.featureMap, feature]);
}

export function useAreFeaturesEnabled(): Record<ConfigResponseFeaturesEnum, boolean> {
  const {data} = useGetFeatures();

  return data?.featureMap ?? ({} as Record<ConfigResponseFeaturesEnum, boolean>);
}
