import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {resetState} from '../../shared/redux/actions';
import {getApiActionBuilder} from '../../shared/redux/util/api-action-builder';
import {stateWithLoading} from '../../shared/redux/util/state-with-loading';
import {IOffersLoadingState, IOffersState, IPurchasedOffer} from './interfaces';
import offersApiActions from './offers.actions';
import {IOffersNonNested} from '@growthday/ui-core/src/types/offers';

const initialState = stateWithLoading<IOffersState, IOffersLoadingState>({
  purchasedOffers: [],
  offers: [],
});

const offersReducer = createSlice<typeof initialState, {}>({
  name: 'offers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const apiBuilder = getApiActionBuilder<IOffersState>(builder, offersApiActions);

    apiBuilder.build<PayloadAction<IPurchasedOffer[]>>(offersApiActions.getPurchasedOffers, {
      fulfilled: (state, action) => {
        state.purchasedOffers = action.payload;
      },
    });

    apiBuilder.build<PayloadAction<IOffersNonNested[]>>(offersApiActions.getOffers, {
      fulfilled: (state, action) => {
        state.offers = action.payload;
      },
    });

    apiBuilder.defaults();

    builder.addCase(resetState, () => {
      return {
        ...initialState,
        offers: [],
        purchasedOffers: [],
        offerThankYou: [],
      };
    });
  },
});

export default offersReducer.reducer;
