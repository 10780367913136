/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface CmsMarketingPlanTrial
 */
export interface CmsMarketingPlanTrial {
  /**
   *
   * @type {number}
   * @memberof CmsMarketingPlanTrial
   */
  duration?: number;
  /**
   *
   * @type {boolean}
   * @memberof CmsMarketingPlanTrial
   */
  skipCreditCardCollection?: boolean;
  /**
   *
   * @type {string}
   * @memberof CmsMarketingPlanTrial
   */
  frequency?: CmsMarketingPlanTrialFrequencyEnum;
}

export const CmsMarketingPlanTrialFrequencyEnum = {
  Day: 'day',
  Month: 'month',
  Year: 'year',
} as const;

export type CmsMarketingPlanTrialFrequencyEnum =
  (typeof CmsMarketingPlanTrialFrequencyEnum)[keyof typeof CmsMarketingPlanTrialFrequencyEnum];
