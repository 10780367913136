/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface HeroCarouselProgress
 */
export interface HeroCarouselProgress {
  /**
   *
   * @type {string}
   * @memberof HeroCarouselProgress
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof HeroCarouselProgress
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof HeroCarouselProgress
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof HeroCarouselProgress
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof HeroCarouselProgress
   */
  uuid?: string;
  /**
   *
   * @type {string}
   * @memberof HeroCarouselProgress
   */
  type?: HeroCarouselProgressTypeEnum;
  /**
   *
   * @type {number}
   * @memberof HeroCarouselProgress
   */
  slideNumber?: number;
}

export const HeroCarouselProgressTypeEnum = {
  Dashboard: 'DASHBOARD',
  Course: 'COURSE',
} as const;

export type HeroCarouselProgressTypeEnum =
  (typeof HeroCarouselProgressTypeEnum)[keyof typeof HeroCarouselProgressTypeEnum];
