import {ChatFeaturesEnum} from './chat-store';
import {ChatMessageId, ChatMessageStore} from './message-store';

export const selectAddMessage = (store: ChatMessageStore) => store.addMessage;

export const selectAddMessageWithoutListing = (store: ChatMessageStore) => store.addMessageWithoutListing;
export const selectMessage = (key: ChatFeaturesEnum, id: ChatMessageId, store: ChatMessageStore) =>
  store[key]?.messageMap[id] ?? null;

export const selectMessageIds = (key: ChatFeaturesEnum, store: ChatMessageStore) => store[key]?.messageIds ?? [];

export const selectMessageMap = (key: ChatFeaturesEnum, store: ChatMessageStore) => store[key]?.messageMap ?? {};

export const selectHasMessages = (key: ChatFeaturesEnum, store: ChatMessageStore) => store.hasMessages[key];

export const selectPrependMessages = (store: ChatMessageStore) => store.prependMessages;

export const selectResetChatMessageStore = (store: ChatMessageStore) => store.resetStore;

export const selectDeleteMessage = (store: ChatMessageStore) => store.deleteMessage;

export const selectUpdateMessage = (store: ChatMessageStore) => store.updateMessage;

export const selectUpdateMessageContent = (store: ChatMessageStore) => store.updateMessageContent;

export const selectHasUnreadMessages = (key: ChatFeaturesEnum, store: ChatMessageStore) =>
  store[key]?.hasUnreadMessages ?? false;

export const selectSetHasUnreadMessages = (store: ChatMessageStore) => store.setHasUnreadMessages;
