/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ScorecardSettings
 */
export interface ScorecardSettings {
  /**
   *
   * @type {boolean}
   * @memberof ScorecardSettings
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof ScorecardSettings
   */
  reportFrequency?: ScorecardSettingsReportFrequencyEnum;
  /**
   *
   * @type {boolean}
   * @memberof ScorecardSettings
   */
  emailReminder?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ScorecardSettings
   */
  pushReminder?: boolean;
}

export const ScorecardSettingsReportFrequencyEnum = {
  Monthly: 'MONTHLY',
  Quarterly: 'QUARTERLY',
} as const;

export type ScorecardSettingsReportFrequencyEnum =
  (typeof ScorecardSettingsReportFrequencyEnum)[keyof typeof ScorecardSettingsReportFrequencyEnum];
