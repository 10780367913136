import {useEffect, useLayoutEffect} from 'react';
import {RumInitConfiguration, datadogRum} from '@datadog/browser-rum';
import config from '@/config';
import {useAuthData} from '@growthday/ui-core/src/hooks/useAuthData';
import {
  AnalyticsDatadogRumConfigDynamicConfig,
  DynamicConfigEnum,
} from '@growthday/ui-core/src/features/feature-gate/types';
import {getDynamicConfig} from '@growthday/ui-core/src/features/feature-gate/utils/get-dynamic-config';
import AppConfig from '@/../package.json';

const useDatadog = (): void => {
  const {user} = useAuthData();

  useLayoutEffect(() => {
    const defaultConfig: RumInitConfiguration = {
      applicationId: '5a96af24-be37-4d3b-8062-f7f621605ab4',
      clientToken: 'pubc6b80144b4007655f36eae77ffdeed18',
      site: 'datadoghq.com',
      service: 'growthdayweb',
      env: config.env.name,
      version: AppConfig.version,
      sessionSampleRate: 0,
      sessionReplaySampleRate: 0,
      traceSampleRate: 0,
      telemetrySampleRate: 0,
      defaultPrivacyLevel: 'mask',
      silentMultipleInit: true,
    };

    const dynamicConfig = getDynamicConfig<AnalyticsDatadogRumConfigDynamicConfig>(
      DynamicConfigEnum.AnalyticsDatadogRumConfig
    );
    const mergedConfig: RumInitConfiguration = {
      ...defaultConfig,
      ...(dynamicConfig as RumInitConfiguration),
    };

    datadogRum.init(mergedConfig);
    datadogRum.startSessionReplayRecording();
  }, []);

  useEffect(() => {
    if (user) {
      return datadogRum.setUser({
        id: user.uuid,
        name: user.fullName,
        email: user.email,
        plan: user.subscriptionPlanName,
        isLoggedIn: true,
      });
    }

    datadogRum.setUserProperty('isLoggedIn', false);
  }, [user]);
};

export function resetDatadogSession() {
  datadogRum.stopSessionReplayRecording();
  datadogRum.stopSession();
  datadogRum.clearUser();
}

export default useDatadog;
