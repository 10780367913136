import {MouseEvent, useMemo} from 'react';
import {TMenuItem} from '../components/Menu/Menu.interface';
import {useLocation} from 'react-router-dom';
import useLicense from '../../license/hooks/useLicense';
import {compact} from 'lodash';
import {useGetFeatures} from '@/features/feature-disable/hooks/useGetFeatures';
import {imageUrlFromMedia} from '@growthday/ui-core/src/features/media-player/utils/image-url-from-media';
import urlJoin from 'proper-url-join';
import {getStaticAssetUrl} from '@/shared/util/get-static-asset-url';
import {useDynamicMenuItems} from '@/features/shell/hooks/useDynamicMenuItems';
import {useIsCommunityEnabled} from '@/features/community/hooks/useIsCommunityEnabled';
import {getAnalyticsActions} from '@/features/analytics/hooks/useAnalyticsActions';

export type UseMenuItemsProps = {
  onFeedback?: (event: MouseEvent<HTMLAnchorElement>) => void;
};

/**
 * useMenuItems hook provides dynamic menu items based on various conditions such as user license, feature availability,
 * and dynamic configuration.
 *
 * @example
 * // Basic usage
 * const { topMenuItems, bottomMenuItems } = useMenuItems();
 *
 * // Customizing menu behavior
 * const handleFeedback = (event: MouseEvent<HTMLAnchorElement>) => {
 *   // Handle feedback click event
 * };
 * const { topMenuItems, bottomMenuItems } = useMenuItems({ onFeedback: handleFeedback });
 */
const useMenuItems = (props?: UseMenuItemsProps) => {
  const {pathname} = useLocation();
  const {canShowCommunity} = useLicense();
  const {data} = useGetFeatures();
  const menuItems = useMemo(() => data?.menuItems ?? [], [data?.menuItems]);
  const dynamicMenuItems = useDynamicMenuItems();
  const isCommunityEnabled = useIsCommunityEnabled();

  // Calculate top menu items based on various conditions
  const topMenuItems: TMenuItem[] = useMemo(() => {
    let list = compact([
      ...menuItems.map(
        (entry) =>
          (entry && {
            icon: imageUrlFromMedia(entry.icon),
            title: entry.title ?? '',
            to: entry.href?.startsWith('/') ? entry.href.slice(1) : entry.href ?? '',
            id: `menu-item-${entry.id ?? ''}`,
            onClick:
              entry.id === 'plan'
                ? () => {
                    getAnalyticsActions().track('Planner Click');
                  }
                : undefined,
          }) as TMenuItem
      ),
      ...dynamicMenuItems.map(
        (entry) =>
          entry && {
            icon: imageUrlFromMedia(entry.icon),
            title: entry.title ?? '',
            to: entry.href?.startsWith('/') ? entry.href.slice(1) : entry.href ?? '',
            id: `menu-item-${entry.id ?? ''}`,
          }
      ),
    ]);

    // Exclude community menu item if it is disabled or the current pathname includes 'enterprise'
    if (!isCommunityEnabled || !canShowCommunity() || pathname.includes('enterprise')) {
      list = list.filter((e) => e.id !== `menu-item-community`);
    }
    return list.filter((item) => item.icon);
  }, [menuItems, dynamicMenuItems, isCommunityEnabled, canShowCommunity, pathname]);

  // Define static bottom menu items
  const bottomMenuItems: TMenuItem[] = [
    {
      // Icon: ReportMenuIcon,
      icon: imageUrlFromMedia(urlJoin(getStaticAssetUrl('icons', 'feedback.svg'))),
      title: 'Feedback',
      onClick: props?.onFeedback,
      id: 'menu-item-feedback',
    },
    {
      // Icon: SettingsMenuIcon,
      icon: imageUrlFromMedia(urlJoin(getStaticAssetUrl('icons', 'settings.svg'))),
      title: 'Settings',
      to: 'profile',
      id: 'menu-item-settings',
    },
  ];

  return {
    topMenuItems,
    bottomMenuItems,
  };
};

export default useMenuItems;
