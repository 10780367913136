import {useCallback} from 'react';
import {GrowthGroupInstance} from '../../../types/api';
import useUpdateGrowthGroupInstance from './useUpdateGrowthGroupInstance';

export default function useMarkGroupAsEnded() {
  const {updater} = useUpdateGrowthGroupInstance();

  return useCallback(
    (id: GrowthGroupInstance['id'], slug: GrowthGroupInstance['slug']) => {
      updater({id, slug}, (old) => {
        old.actualEndTime = Date.now();
        return old;
      });
    },
    [updater]
  );
}
