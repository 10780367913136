/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface GrowthGroupLicense
 */
export interface GrowthGroupLicense {
  /**
   *
   * @type {string}
   * @memberof GrowthGroupLicense
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupLicense
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupLicense
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupLicense
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupLicense
   */
  uuid?: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupLicense
   */
  growthGroupPackageId?: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupLicense
   */
  licenseType?: GrowthGroupLicenseLicenseTypeEnum;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupLicense
   */
  allowedLimit?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupLicense
   */
  consumed?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupLicense
   */
  participantsAllowed?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupLicense
   */
  cycleStartTime?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupLicense
   */
  cycleEndTime?: number;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupLicense
   */
  notRenewChecked?: boolean;
}

export const GrowthGroupLicenseLicenseTypeEnum = {
  ProSubscriptionPlan: 'PRO_SUBSCRIPTION_PLAN',
  ProSubscriptionPlanRecurring: 'PRO_SUBSCRIPTION_PLAN_RECURRING',
  ProTrialSubscriptionPlan: 'PRO_TRIAL_SUBSCRIPTION_PLAN',
  GgPackage: 'GG_PACKAGE',
  AllAccessSubscription: 'ALL_ACCESS_SUBSCRIPTION',
  AllAccessTrial: 'ALL_ACCESS_TRIAL',
  StarterSubscription: 'STARTER_SUBSCRIPTION',
  StarterTrialSubscription: 'STARTER_TRIAL_SUBSCRIPTION',
} as const;

export type GrowthGroupLicenseLicenseTypeEnum =
  (typeof GrowthGroupLicenseLicenseTypeEnum)[keyof typeof GrowthGroupLicenseLicenseTypeEnum];
