import {memoize} from '../../../utils/memoizeWithShallowCompare';
import {ChatFeaturesEnum, ChatStore} from './chat-store';
import {curriedByKey} from './utils';

/**
 * Memoized selector function to get the chat channel based on the provided key.
 */
export const selectChatChannel = memoize((key: ChatFeaturesEnum) => (store: ChatStore) => store.channel[key]);
/**
 * Memoized selector function to get the chat metadata based on the provided key.
 */
export const selectChatMetadata = memoize((key: ChatFeaturesEnum) => (store: ChatStore) => store.metadata[key]);
/**
 * Selector function to get the chat session from the store.
 */
export const selectChatSession = (store: ChatStore) => store.session;

/**
 * Memoized selector function to get the function to set the chat channel based on the provided key.
 */
export const selectSetChatChannel = memoize(
  (key: ChatFeaturesEnum) => (store: ChatStore) => curriedByKey(key, store.setChannel)
);

/**
 * Selector function to get the function to set the chat session.
 */
export const selectSetChatSession = (store: ChatStore) => store.setSession;

/**
 * Memoized selector function to get the initialized status of the chat based on the provided key.
 */
export const selectChatInitialized = memoize((key: ChatFeaturesEnum) => (store: ChatStore) => store.initialized[key]);

/**
 * Selector function to get the function to reset the entire chat store.
 */
export const selectResetChatStore = (store: ChatStore) => store.resetStore;

/**
 * Memoized selector function to get the function to reset the chat store based on the provided key.
 */
export const selectResetChatStoreByKey = memoize(
  (key: ChatFeaturesEnum) => (store: ChatStore) => curriedByKey(key, store.resetStore)
);

/**
 * Selector function for the setter of banned flag
 */
export const selectSetIsBanned = (store: ChatStore) => store.setIsBanned;

/**
 * Selector function for getting the banned status of current user
 */
export const selectIsBanned = (store: ChatStore, key: ChatFeaturesEnum) => !!store.isBanned[key];

/**
 * Selector function to get the messaging client from the chat store.
 */
export const selectMessagingClient = (store: ChatStore) => store.client;

/**
 * Selector function to get the function to set the messaging client.
 */
export const selectSetMessagingClient = (store: ChatStore) => store.setClient;

/**
 * Selector function to get the messaging user ARN from the chat store.
 */
export const selectMessagingUserArn = (store: ChatStore) => store.userArn;

/**
 * Selector function to get the function to set the messaging user ARN.
 */
export const selectSetMessagingUserArn = (store: ChatStore) => store.setUserArn;

/**
 * Memoized selector function to get the next token based on the provided key.
 */
export const selectNextToken = memoize((key: ChatFeaturesEnum) => (store: ChatStore) => store.nextToken[key] ?? null);

/**
 * Memoized selector function to get the function to set the next token based on the provided key.
 */
export const selectSetNextToken = memoize(
  (key: ChatFeaturesEnum) => (store: ChatStore) => curriedByKey(key, store.setNextToken)
);

/**
 * Memoized selector function to get the function to set the chat metadata based on the provided key.
 */
export const selectSetChatMetadata = memoize(
  (key: ChatFeaturesEnum) => (store: ChatStore) => curriedByKey(key, store.setMetadata)
);

/**
 * Selector function to get the membership initialized status from the chat store.
 */
export const SelectMembershipInitialized = (key: ChatFeaturesEnum, store: ChatStore) =>
  store.membershipInitialised[key];

/**
 * Selector function to get the function to set the membership initialized status.
 */
export const SelectSetMembershipInitialized = (store: ChatStore) => store.setMembershipInitialized;
