import {Layout as AntLayout} from 'antd';
import React, {FC} from 'react';
import {StickyContainer} from 'react-sticky';
import ErrorBoundary from '../../../features/error-handling/components/ErrorBoundary/ErrorBoundary';
import styles from '../../../features/shell/Shell.module.less';
import classes from './EmptyLayout.module.less';
import EmptyLayoutHeader from './EmptyLayoutHeader';
import EmptyLayoutSider from './EmptyLayoutSider';

const {Header: AntHeader, Content: AntContent} = AntLayout;

export type EmptyLayoutProps = {
  withBg?: boolean;
};

const EmptyLayout: FC<EmptyLayoutProps> = ({children, withBg}) => (
  <AntLayout className={styles.layout}>
    <AntHeader className={styles.header}>
      <EmptyLayoutHeader />
    </AntHeader>
    <AntLayout className={styles.childLayout}>
      <EmptyLayoutSider />
      <AntContent className={styles.content}>
        {withBg && <div className={classes.bgImg} />}
        <StickyContainer className={styles.stickyContainer}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </StickyContainer>
      </AntContent>
    </AntLayout>
  </AntLayout>
);

export default EmptyLayout;
