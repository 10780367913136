import qs from 'qs';
import {
  InAppNotificationPreferences,
  IInAppPaginatedNotifications,
} from '../../services_deprecated/model/inAppNotifications';
import {axiosGrowthDay} from '../index';

export const notificationsApi = {
  getNotifications(params: {page: number; size: number}) {
    return axiosGrowthDay
      .get<IInAppPaginatedNotifications>(`/notifications?${qs.stringify(params)}`)
      .then((res) => ({...params, ...res.data}));
  },
  readNotifications(notificationId?: string | void) {
    return axiosGrowthDay
      .put<void>(notificationId ? `/notifications/${notificationId}/read` : `/notifications/read`)
      .then((res) => res.data);
  },
  getNotificationPreferences() {
    return axiosGrowthDay.get<InAppNotificationPreferences>(`/notificationPreferences`).then((res) => res.data);
  },
  updateNotificationPreferences(data: Partial<InAppNotificationPreferences>) {
    return axiosGrowthDay.put<InAppNotificationPreferences>(`/notificationPreferences`, data).then((res) => res.data);
  },
};
