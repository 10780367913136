import {ConfigResponseFeaturesEnum} from '@growthday/ui-core/src/types/api';
import {featureEnvMap} from '@customization/rebrand-text/util';
import {useAreFeaturesEnabled} from './useIsFeatureEnabled';

export interface SingleConfigResponseFeaturesEnumConfig {
  entry: ConfigResponseFeaturesEnum;
  plural?: boolean;
  casing?: 'uppercase' | 'lowercase' | 'capitalize';
}

function isSingleConfigResponse(
  entry: SingleConfigResponseFeaturesEnumConfig | ConfigResponseFeaturesEnum
): entry is SingleConfigResponseFeaturesEnumConfig {
  return (entry as SingleConfigResponseFeaturesEnumConfig).entry !== undefined;
}

function getFeaturesNames(features: (ConfigResponseFeaturesEnum | SingleConfigResponseFeaturesEnumConfig)[]): string[] {
  const featuresToShow: string[] = [];
  features.forEach((feature) => {
    if (isSingleConfigResponse(feature)) {
      const featureText = featureEnvMap.get(feature.entry);
      featureText && featuresToShow.push(transformCasing(feature.plural ? featureText?.concat('s') : featureText));
    } else {
      const featureText = featureEnvMap.get(feature);
      featureText && featuresToShow.push(featureText);
    }
  });

  return featuresToShow;
}

const transformCasing = (entry: string, casing?: 'uppercase' | 'lowercase' | 'capitalize') => {
  if (casing === 'uppercase') {
    return entry.toUpperCase();
  } else if (casing === 'lowercase') {
    return entry.toLowerCase();
  }
  return entry.charAt(0).toUpperCase() + entry.slice(1);
};

export const useFilterEnabledFeatures = () => {
  const enabledFeatures = useAreFeaturesEnabled();

  const filterFeaturesEnabled = (
    features: (ConfigResponseFeaturesEnum | SingleConfigResponseFeaturesEnumConfig)[],
    agreggator = 'and'
  ) => {
    const enabledFeaturesList = features.filter((feature) =>
      isSingleConfigResponse(feature) ? enabledFeatures[feature.entry] : enabledFeatures[feature]
    );

    if (!enabledFeaturesList.length) {
      return '';
    }
    const featuresToShow = getFeaturesNames(enabledFeaturesList);

    return featuresToShow.length === 2
      ? featuresToShow.join(` ${agreggator} `)
      : featuresToShow.reduce((prev, curr, currentIndex) => {
          if (prev.length > 0 && currentIndex + 1 < featuresToShow.length) {
            prev += ', ';
          } else {
            prev += ' ';
          }
          if (currentIndex === featuresToShow.length - 1 && currentIndex > 1) {
            prev += `${agreggator} `;
          }
          prev += curr;
          return prev;
        }, '');
  };

  return {
    filterFeaturesEnabled,
  };
};
