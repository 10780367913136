import {useRebrandText} from '@/features/feature-disable/hooks/useRebrandText';
import {IOffersNonNested} from '@growthday/ui-core/src/types/offers';
import Col from 'antd/es/col';
import Row from 'antd/es/row';
import classNames from 'classnames';
import startCase from 'lodash/startCase';
import {FC} from 'react';
import Loading from '@/shared/components/Loading';
import GDTypography from '@/shared/components/ui/Typography/Typography';
import commonStyles from '@/shared/style/commonStyles.module.less';
import {imageUrlFromMedia} from '@growthday/ui-core/src/features/media-player/utils/image-url-from-media';
import {ISubscriptionPlan} from '../../../../interfaces';
import styles from './SelectedPlan.module.less';
import {getTrialDaysFromSubscriptionPlan} from '../../utils';
import SVG from 'react-inlinesvg';
import {getStaticAssetUrl} from '@/shared/util/get-static-asset-url';

type SelectedPlanProps = {
  offer?: IOffersNonNested;
  plan?: ISubscriptionPlan;
  showTrial?: boolean;
  trialIntervalFromStrapi?: string;
};

const logo = getStaticAssetUrl('auth-page', 'logo-subscription-plan-card.svg');

const SelectedPlan: FC<SelectedPlanProps> = ({offer, plan, showTrial, trialIntervalFromStrapi}) => {
  const {rebrandTextFromEnv} = useRebrandText();
  const trialDays = plan && getTrialDaysFromSubscriptionPlan(plan);

  /* If there is a trial configuration from Strapi we should use it, otherwise, we will use the trial days configured on the Plan information */
  const trialDaysFromCampaign = trialIntervalFromStrapi
    ? trialIntervalFromStrapi
    : trialDays
      ? `${trialDays} days`
      : undefined;

  if (!offer && !plan) {
    return <Loading />;
  }
  if (offer) {
    return (
      <div className={styles.container}>
        <Row wrap={false} align="middle">
          <Col className={classNames(commonStyles.flex, commonStyles.alignItemsCenter)} flex="1 1">
            <img alt="" src={imageUrlFromMedia(offer.image || offer.thumbnail)} className={styles.icon} />
          </Col>
          <Col flex="200px">
            <GDTypography block type="w200" className={styles.planName}>
              {offer.title}
            </GDTypography>
            <GDTypography block type="w100" className={styles.planCost}>
              <span className={styles.planAmount}>${offer?.price}</span> / {startCase(offer?.interval)?.toLowerCase()}
            </GDTypography>
            <GDTypography block type="w50" className={commonStyles.fadeText}>
              plus applicable taxes
            </GDTypography>
          </Col>
        </Row>
      </div>
    );
  }
  const productName = rebrandTextFromEnv('productName');
  return (
    <div className={styles.container}>
      <Row wrap={false} align="middle">
        <Col className={classNames(commonStyles.flex, commonStyles.alignItemsCenter)} flex="1 1">
          <SVG src={logo} className={classNames(styles.icon, 'subscription-plan-logo')} />
        </Col>
        <Col flex="200px">
          <GDTypography block type="w200" className={styles.planName}>
            {productName} {plan?.name}
          </GDTypography>
          {showTrial && trialDaysFromCampaign && (
            <GDTypography block type="w200" className={styles.trialHint}>
              Free for {trialDaysFromCampaign}, then
            </GDTypography>
          )}
          <GDTypography block type="w100" className={styles.planCost}>
            <span className={styles.planAmount}>${plan?.amount}</span> / {plan?.interval}
          </GDTypography>
          <GDTypography block type="w50" className={commonStyles.fadeText}>
            plus applicable taxes
          </GDTypography>
        </Col>
      </Row>
    </div>
  );
};

export default SelectedPlan;
