/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ParticipantUpdateRequest
 */
export interface ParticipantUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof ParticipantUpdateRequest
   */
  instanceId: string;
  /**
   *
   * @type {string}
   * @memberof ParticipantUpdateRequest
   */
  role: ParticipantUpdateRequestRoleEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof ParticipantUpdateRequest
   */
  participantUuids: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof ParticipantUpdateRequest
   */
  exceed?: boolean;
}

export const ParticipantUpdateRequestRoleEnum = {
  Host: 'host',
  HostBackstage: 'host-backstage',
  Attendee: 'attendee',
  CoHost: 'co-host',
  CoHostBackstage: 'co-host-backstage',
  Speaker: 'speaker',
  Preview: 'preview',
  WaitingRoom: 'waiting-room',
  Beam: 'beam',
} as const;

export type ParticipantUpdateRequestRoleEnum =
  (typeof ParticipantUpdateRequestRoleEnum)[keyof typeof ParticipantUpdateRequestRoleEnum];
