import {axiosGrowthDay} from '../index';
import {IUpdateUserAddress, IUser, IUpdateUser} from '../../services_deprecated/model/user';

export const meApi = {
  get: () => {
    return axiosGrowthDay.get<IUser>('/me').then((res) => res.data);
  },
  update: (user: IUpdateUser) => {
    return axiosGrowthDay.put<IUser>('/me', user).then((res) => res.data);
  },
  updateAddress: (address: IUpdateUserAddress) => {
    return axiosGrowthDay
      .put<{errorMessage?: string; isSuccess?: boolean}>('/me/address', address)
      .then((res) => res.data);
  },
};
