/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface UserTourUpdateRequest
 */
export interface UserTourUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof UserTourUpdateRequest
   */
  trigger: UserTourUpdateRequestTriggerEnum;
  /**
   *
   * @type {string}
   * @memberof UserTourUpdateRequest
   */
  triggerId: string;
  /**
   *
   * @type {string}
   * @memberof UserTourUpdateRequest
   */
  triggerType: UserTourUpdateRequestTriggerTypeEnum;
}

export const UserTourUpdateRequestTriggerEnum = {
  ChallengeLoad: 'ON_CHALLENGE_LOAD',
  ChallengeFirstTaskComplete: 'ON_CHALLENGE_FIRST_TASK_COMPLETE',
  ChallengeDayOneComplete: 'ON_CHALLENGE_DAY_ONE_COMPLETE',
  ChallengeDayTwoComplete: 'ON_CHALLENGE_DAY_TWO_COMPLETE',
  ChallengeDayThreeComplete: 'ON_CHALLENGE_DAY_THREE_COMPLETE',
  ChallengeDayFourComplete: 'ON_CHALLENGE_DAY_FOUR_COMPLETE',
  ChallengeDayFiveComplete: 'ON_CHALLENGE_DAY_FIVE_COMPLETE',
  DashboardLoad: 'ON_DASHBOARD_LOAD',
  EnterpriseDashboardLoad: 'ON_ENTERPRISE_DASHBOARD_LOAD',
} as const;

export type UserTourUpdateRequestTriggerEnum =
  (typeof UserTourUpdateRequestTriggerEnum)[keyof typeof UserTourUpdateRequestTriggerEnum];
export const UserTourUpdateRequestTriggerTypeEnum = {
  Challenge: 'CHALLENGE',
  Plan: 'PLAN',
  Offer: 'OFFER',
  Campaign: 'CAMPAIGN',
  EnterpriseAdmin: 'ENTERPRISE_ADMIN',
} as const;

export type UserTourUpdateRequestTriggerTypeEnum =
  (typeof UserTourUpdateRequestTriggerTypeEnum)[keyof typeof UserTourUpdateRequestTriggerTypeEnum];
