import React, {CSSProperties, FC, ReactNode} from 'react';
import styles from './CheckboxListCard.module.less';
import commonStyles from '../../../style/commonStyles.module.less';
import GDTypography from '../Typography/Typography';
import classNames from 'classnames';
import ShadowCard from '../ShadowCard/ShadowCard';
import CheckboxList, {CheckboxListProps} from './CheckboxList';

export type CheckboxListCardProps = CheckboxListProps & {
  title: ReactNode;
  titleClassName?: string;
  subTitle?: ReactNode;
  subTitleClassName?: string;
  onClick?: () => void;
  selected?: boolean;
  popular?: boolean;
  className?: string;
  firstIconPlus?: boolean;
  hideCheckbox?: boolean;
  style?: CSSProperties;
  headerTags?: ReactNode;
  headerClassName?: string;
};

const CheckboxListCard: FC<CheckboxListCardProps> = ({
  className,
  title,
  titleClassName,
  subTitle,
  subTitleClassName,
  items,
  itemsClassName,
  onClick,
  selected,
  children,
  popular,
  firstIconPlus,
  hideCheckbox,
  style,
  headerTags,
  headerClassName,
}) => {
  return (
    <ShadowCard
      data-testid={`gd-shadow-card-${title}`}
      style={style}
      tabIndex={0}
      onClick={onClick}
      className={classNames(styles.root, className, {[styles.selected]: selected})}
    >
      <div className={classNames(commonStyles.flex, commonStyles.mb12, styles.cardHeader, headerClassName)}>
        <div className={styles.titleHolder}>
          <div className={styles.checkboxHolder}>
            {!hideCheckbox && <span className={classNames(styles.circle)} />}
            <GDTypography block type="w300" className={titleClassName}>
              {title}
            </GDTypography>
          </div>
          {popular && (
            <GDTypography type="w100" className={styles.popular}>
              Most Popular
            </GDTypography>
          )}
        </div>
        {headerTags}
      </div>
      <div className={subTitleClassName}>{subTitle}</div>
      <CheckboxList firstIconPlus={firstIconPlus} items={items} itemsClassName={itemsClassName} />
      {children}
    </ShadowCard>
  );
};

export default CheckboxListCard;
