/**
 * @fileoverview
 * This file contains utility functions and hooks for sending analytics events related to Growth Groups.
 * @module useGroupMixpanelEvents
 */
import {GrowthGroupInstance} from '@growthday/ui-core/src/types/api';
import useUpdatedRef from '@growthday/ui-core/src/hooks/useUpdatedRef';
import {useCallback, useMemo} from 'react';
import useGrowthGroupsState from '@growthday/ui-core/src/features/growthgroups/hooks/hms/useGrowthGroupsState';
import dayjs from 'dayjs';
import {getAnalyticsActions} from '@/features/analytics/hooks/useAnalyticsActions';
import {IGrowthGroupPackage} from '@growthday/ui-core/src/types/strapi';

/**
 * Returns common properties for Growth Group events.
 * @param {GrowthGroupInstance} groupInstance - The Growth Group instance.
 * @returns - Common properties for Growth Group events.
 */
const getCommonGroupEventProperties = (groupInstance: GrowthGroupInstance) =>
  ({
    Duration: groupInstance.duration,
    Type: groupInstance.type === 'PUBLIC' ? 'Public' : 'Private',
    Style: groupInstance.channel === 'AUDIO' ? 'Audio only' : 'Video',
    Title: groupInstance.title,
    Paid: !!groupInstance.premium,
  }) as const;

/**
 * Sends a 'Group Joined' event.
 * @param {GrowthGroupInstance} groupInstance - The Growth Group instance.
 */
export function sendGroupJoinEvent(groupInstance?: GrowthGroupInstance) {
  if (!groupInstance) return;
  getAnalyticsActions().track('Group Joined', {
    ...getCommonGroupEventProperties(groupInstance),
    'Invite Link': window.isSocial ? 'True' : 'False',
  });
}

/**
 * Sends a 'Group Left' event.
 * @param {GrowthGroupInstance} groupInstance - The Growth Group instance.
 * @param {string} joinedTime - The time the user joined the group.
 */
export function sendGroupLeaveEvent(groupInstance?: GrowthGroupInstance, joinedTime?: string) {
  if (!groupInstance) return;
  getAnalyticsActions().track('Group Left', {
    ...getCommonGroupEventProperties(groupInstance),
    'Invite Link': window.isSocial ? 'True' : 'False',
    "User's Duration": joinedTime ? dayjs().diff(dayjs(joinedTime), 'minutes') : 0,
  });
}

/**
 * Sends a 'Group Hosting Started' event.
 * @param {GrowthGroupInstance} groupInstance - The Growth Group instance.
 */
export function sendGroupHostEvent(groupInstance?: GrowthGroupInstance) {
  if (!groupInstance) return;
  getAnalyticsActions().track('Group Hosting Started', getCommonGroupEventProperties(groupInstance));
}

/**
 * Sends a "Group RSVP'd" event.
 * @param {GrowthGroupInstance} groupInstance - The Growth Group instance.
 */
export function sendGroupRsvpEvent(groupInstance?: GrowthGroupInstance) {
  if (!groupInstance) return;
  getAnalyticsActions().track("Group RSVP'd", {
    ...getCommonGroupEventProperties(groupInstance),
    'Invite Link': window.isSocial ? 'True' : 'False',
  });
}

/**
 * Sends a 'Growth Group Plan Purchased' event.
 * @param {IGrowthGroupPackage} selectedPlanData - Data about the purchased plan.
 */
export function sendGroupPlanPurchasedEvent(selectedPlanData?: IGrowthGroupPackage) {
  if (!selectedPlanData) return;
  getAnalyticsActions().track('Growth Group Plan Purchased', {
    'Plan Name': selectedPlanData.name,
    Attendees: selectedPlanData.maxParticipantsAllowed,
  });
}

/**
 * Sends a 'Growth Group Link Shared' event.
 * @param {GrowthGroupInstance} groupInstance - The Growth Group instance.
 * @param {string} medium - The medium through which the link is shared.
 */
export function sendGroupLinkSharedEvent(groupInstance?: GrowthGroupInstance, medium = '') {
  if (!groupInstance || !medium) return;
  getAnalyticsActions().track('Growth Group Link Shared', {
    ...getCommonGroupEventProperties(groupInstance),
    Media: medium,
  });
}

/**
 * Custom hook that provides memoized functions for sending Growth Group events.
 * @param {GrowthGroupInstance} groupInstanceOption - Optional Growth Group instance.
 * @returns - Memoized functions for sending Growth Group events.
 */
export function useGroupMixpanelEvents(groupInstanceOption?: GrowthGroupInstance) {
  const {joinedTime} = useGrowthGroupsState();

  const groupInstanceOptionRef = useUpdatedRef(groupInstanceOption);
  const joinedTimeRef = useUpdatedRef(joinedTime);

  const sendJoinEvent = useCallback(
    (groupInstanceProp?: GrowthGroupInstance) => {
      const groupInstance = groupInstanceProp || groupInstanceOptionRef.current;
      sendGroupJoinEvent(groupInstance);
    },
    [groupInstanceOptionRef]
  );

  const sendLeaveEvent = useCallback(
    (groupInstanceProp?: GrowthGroupInstance) => {
      const groupInstance = groupInstanceProp || groupInstanceOptionRef.current;
      sendGroupLeaveEvent(groupInstance, joinedTimeRef.current);
    },
    [groupInstanceOptionRef, joinedTimeRef]
  );

  const sendHostEvent = useCallback(
    (groupInstanceProp?: GrowthGroupInstance) => {
      const groupInstance = groupInstanceProp || groupInstanceOptionRef.current;
      sendGroupHostEvent(groupInstance);
    },
    [groupInstanceOptionRef]
  );

  const sendRsvpEvent = useCallback(
    (groupInstanceProp?: GrowthGroupInstance) => {
      const groupInstance = groupInstanceProp || groupInstanceOptionRef.current;
      sendGroupRsvpEvent(groupInstance);
    },
    [groupInstanceOptionRef]
  );

  const sendPlanPurchasedEvent = useCallback((selectedPlanData?: IGrowthGroupPackage) => {
    sendGroupPlanPurchasedEvent(selectedPlanData);
  }, []);

  const sendLinkSharedEvent = useCallback(
    (groupInstanceProp?: GrowthGroupInstance, medium = '') => {
      const groupInstance = groupInstanceProp || groupInstanceOptionRef.current;
      sendGroupLinkSharedEvent(groupInstance, medium);
    },
    [groupInstanceOptionRef]
  );

  return useMemo(
    () => ({
      sendJoinEvent,
      sendLeaveEvent,
      sendHostEvent,
      sendRsvpEvent,
      sendPlanPurchasedEvent,
      sendLinkSharedEvent,
    }),
    [sendJoinEvent, sendLeaveEvent, sendHostEvent, sendRsvpEvent, sendPlanPurchasedEvent, sendLinkSharedEvent]
  );
}
