import {SharedEnvironmentConstants} from '../../typings/environment';

const hasMobileApp = true;

const privacyLinks: Pick<
  SharedEnvironmentConstants['settings']['privacy'],
  'privacyPolicyLink' | 'termsOfServiceLink'
> = {
  privacyPolicyLink: 'https://growthday.com/privacy',
  termsOfServiceLink: 'https://growthday.com/terms',
};

export const GDSharedEnvironmentConstants: SharedEnvironmentConstants = {
  learnLabel: 'Learn',
  groupLabel: 'Growth',
  productName: 'GrowthDay',
  productWebsite: 'https://growthday.com',
  staticAssetUrl: 'https://d1jsz7dbv6fk6u.cloudfront.net',
  supportEmail: 'support@growthday.com',
  buildNumber: process.env.REACT_APP_BUILD_NUMBER ?? '',

  ui: {
    hasMobileApp,
    branding: {
      tagline: `It's a great day`,
      highlight: 'to grow',
      cancelledBanner: {
        tagline: {
          type: 'text',
          content: `It's a great day`,
          highlight: 'to grow',
        },
        title: 'Growth only happens when you’re intentional.',
        subtitle: 'Be intentional, upgrade & grow today.',
      },
    },
    coinName: 'GrowthCoin',
    profile: {
      coinDescription:
        'Over time, we’ll unlock courses and other freebies that you can redeem with your GrowthCoins, so keep saving them up!',
    },
    promptForMobileApp: hasMobileApp,
    subscriptionPlan: {
      defaultOnboardingHeading: 'It’s time to start your growth journey',
      planCardMaxWidth: 356,
    },
    dashboard: {
      heroCarouselFullBleed: false,
      profilePictureOnTop: false,
    },
    aibot: {
      disableRoute: false,
    },
  },
  deepLinkingEnabled: hasMobileApp,
  learn: {
    courseBannerContainedGraphic: false,
    courseTabText: 'Courses',
    showCourseTypeFile: true,
    courseBannerFeaturedText: 'Popular Course',
  },
  growthGroups: {
    sectionTitle: 'Growth Groups',
    carouselTitle: 'My Growth Groups',
    workshopsCarouselTitle: 'Official GrowthDay Workshops',
    workshopsReplayTitle: 'Replays & Past Workshops',
    exploreGroupsTitle: 'Explore',
    admitAttendeeNotification: {
      restCountAvatarBackground: '#A2A7AB',
    },
    workshopLabel: 'Workshop',
    allowPublicPaidGroups: true,
    allowPaidGroupsWithoutAddon: true,
  },
  profile: {
    achievementsDescription:
      'Over time, we’ll unlock courses and other freebies that you can redeem with your GrowthCoins, so keep saving them up!',
    historyCoinsDescription: `Earn rewards when you stay on your growth path! Collect GrowthCoins automatically when you use your growthtools and attend live events. Over time, we'll unlock courses and other freebies that you can purchase with your GrowthCoins, so keep saving them up!`,
  },
  settings: {
    gift: {
      title: 'Give the gift of personal growth!',
      description: `Add your gift recipients email addresses, choose a plan for them, write a gift message, then click the Complete Checkout button. You'll give the gift of growth to others, and you'll also be able to cancel or manage these subscriptions after you complete purchase.`,
    },
    referral: {
      header: 'REFERRAL PROGRAM',
      title: 'Share GrowthDay and Earn!',
      subTitle:
        'Earn commissions for referring your friends, team and family to GrowthDay using your unique referral link below! Share your link to get credit!',
      description: `
**Subject to the terms of our [Referral Agreement](https://www.growthday.com/partners), you can earn the following commissions for these member levels you refer to GrowthDay:**

**25% recurring commission on:**
- GrowthDay Pro Monthly: $29/mo

**25% first order commission on:**
- GrowthDay Pro Annual: $299/year
- GrowthDay Pro Annual: Free Trial then $299/year
- GrowthDay Mastery Annual: $499/year
- GrowthDay Mastery Annual: Free Trial then $499/year`,
      step1: {
        title: 'Enter Your PayPal Email Address So We Can Pay You',
        description: '',
      },
      step2: {
        title: 'Copy your custom referral link',
        description: 'This link allows us to track and credit you with people you have referred',
      },
      step3: {
        title:
          'Earn 25% Commissions on Pro or Mastery GrowthDay Plans When You Promote (Using Your Unique Tracking Link) via Email and Social Media',
        description: `
You'll receive credit for anyone who clicks your referral link, signs up and then goes on to a paid subscription of GrowthDay, as long as that person did not click or sign up through another referrer's link last.
    
Remember, we credit the sale on “last click” so the more frequent you can promote, the better!`,
      },
      step4: {
        title: 'Track the number of people who signed up for GrowthDay with your link and get paid!',
        description: '',
      },
      guidelines: {
        content: `
By participating in the GrowthDay Referral Program, you agree to the following terms outlined in our [Referral Partner Agreement](https://www.growthday.com/partners). For additional information, please visit the [GrowthDay Referral Program FAQs](https://www.growthday.com/referral-faqs).

All commissions less cancels, free trial cancels, and refunds; all commissions subject to the terms of our Referral Program Agreement; all annual subscription commissions on first payment only. Commissions are for our Pro or Mastery plans. There are no commissions on free trials that cancel or fail to lead to a Pro or Mastery annual plan payment, or any other offer, product, or service offered by GrowthDay.  

**Please note:** You are paid via PayPal on or around the 15th of each month, so a PayPal account is required. If you do not have a PayPal account or fail to enter your PayPal email address in your referral program dashboard, we cannot pay you and you forfeit all referral rewards.`,
      },
      navbarHeader: 'Promote GrowthDay!',
    },
    notifications: {
      description: 'Decide how you want to be notified about GrowthDay events and activity.',
    },
    privacy: {
      ...privacyLinks,
      howTo: {
        description:
          '<li>GrowthDay employees can’t read your Journal entries, your notes, or your personal scores. This information is protected with the highest levels of encryption, and we have stringent security processes in place for our personnel.</li><li>No-one in the GrowthDay community, including our coaches, can see any of your activity apart from public comments you make.</li><li>You can post comments to coaches and the GrowthDay community under any name.',
      },
      cancelMembership: {
        description: `You can cancel your GrowthDay membership and save your progress. This means that you can still access your notes, Journal entries, Life Scores, GrowthCoins, and watch history at any time after your payments stop. If you reactivate your membership later then you can pick up from where you left off.`,
      },
      deleteAccount: {
        description: `We'll cancel your membership and send you an email to confirm your final payment date and balance. <b>We'll also delete all the information we hold on you. This action can't be undone, and if you rejoin GrowthDay later you'll need to start again.</b>`,
      },
      guidelines: {
        description: `For more information, read our [Privacy Policy](${privacyLinks.privacyPolicyLink}).`,
      },
    },
    recordings: {
      title: 'Recordings',
      description: 'Recordings of your Growth Group sessions will be available here.',
    },
  },
  login: {
    emailAutofill: false,
  },
};
