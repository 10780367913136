import {SubscriptionLevelsEnum} from '../enums';
import useLicense from '@/features/license/hooks/useLicense';
import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';

/**
 * Enum representing the different states of a user in the application.
 */
export enum UserStateEnum {
  UNAUTHENTICATED, // User is not authenticated
  NO_PAYMENT_INFO, // User is in a trial subscription without payment information
  ONBOARDING, // User is in the process of onboarding
  AUTHENTICATED, // User is authenticated and has completed onboarding
}

/**
 * Checks if the current context is a valid entry point for onboarding.
 *
 * @returns True if the current context is not a social login and the entry point source is the root path ('/').
 */
export function isValidOnboardingEntryPoint() {
  return !window.isSocial && window.entryPointSource === '/' && window.entryPointSource === window.location.pathname;
}

/**
 * Custom hook to determine the current state of the user based on authentication,
 * subscription level, and onboarding status.
 *
 * @returns The current state of the user.
 */
const useUserState = () => {
  const {user} = useUser();
  const {canAccessLifescores} = useLicense();

  if (!user) return UserStateEnum.UNAUTHENTICATED;

  if (user.level === SubscriptionLevelsEnum.TRIAL) return UserStateEnum.NO_PAYMENT_INFO;

  if (!user.onboarded && canAccessLifescores() && isValidOnboardingEntryPoint()) return UserStateEnum.ONBOARDING;

  return UserStateEnum.AUTHENTICATED;
};

export default useUserState;
