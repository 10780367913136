import {StatsigUser} from 'statsig-js';
import checkEnvironment from '../../../utils/environment';
import {coreConfig} from '../../../utils/core-config';
import {StatsigOptions} from 'statsig-react';

export type InitialFeatureGateValuesType = {
  sdkKey: string;
  user: StatsigUser;
  options?: StatsigOptions;
  waitForInitialization?: boolean;
};

export function getInitialFeatureGateValues(platform: 'web' | 'mobile'): InitialFeatureGateValuesType {
  return {
    sdkKey: coreConfig.statsigSdkKey ?? '',
    user: {custom: {isLoggedIn: false, platform}},
    waitForInitialization: true,
    options: {
      api: 'https://featureassets.org/v1/',
      eventLoggingApi: 'https://prodregistryv2.org/v1/',
      environment: {
        tier: (checkEnvironment() || 'dev').toLowerCase(),
      },
    },
  };
}
