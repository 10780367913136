import {IConfigEnv} from '../../../config/config.interface';
import {EnvironmentsEnum} from '../../../config/enums';
import {GDSharedEnvironmentConstants} from './shared-constants';

const env: IConfigEnv = {
  androidAppUrl: 'https://play.google.com/store/apps/details?id=com.growthday&hl=en',
  apiUrl: 'https://api.uat.gday-stack.com',
  appScheme: 'growthday-uat',
  authClientId: 'growth-day-main-client',
  authRealm: 'GrowthDayRealm',
  authUrl: 'https://auth.uat.gday-stack.com',
  communityUrl: 'https://community.uat.gday-stack.com',
  fbPixelId: '3038447133079286',
  firstPromoterId: '4lrh8cqc',
  gTagEventsId: {
    completeRegistrationPlan: 'AW-1010317991/nPb5CKnIueACEKf14OED',
    completeRegistrationOffer: 'AW-1010317991/nPb5CKnIueACEKf14OED',
    completeRegistrationChallenge: 'AW-1010317991/nPb5CKnIueACEKf14OED',
    completeRegistrationGift: 'AW-1010317991/nPb5CKnIueACEKf14OED',
    startTrial: 'AW-1010317991/y371CLiMweACEKf14OED',
    subscribe: 'AW-1010317991/MJLnCLuPweACEKf14OED',
  },
  gTagId: 'AW-1010317991',
  gtmId: 'GTM-WTLSZQ6',
  hyvorWebsiteId: 3885,
  iosAppUrl: 'https://apps.apple.com/us/app/growthday/id1543291315?itsct=apps_box_link&itscg=30200',
  name: EnvironmentsEnum.UAT,
  searchApiUrl: 'https://search-api.uat.gday-stack.com',
  shareUrl: 'https://share.uat.gday-stack.com',
  statsigSdkKey: 'client-tHHR5znssq5oZBoRlmRFbvWyLdaPDwCr7mynTE0BblU',
  stripePublishKey:
    'pk_test_51I1G0AFBHm5Si8uCCQOhyzJi2h23YyNcbNmpJLXzfX5VxPyCqZ2HjGbuHlixznpxhvNVv1kqOiLPBCO9tnhyXaHL00UV49l4LR',
  useproofId: 'VKE8WSTSxrg6pXncelT968M1zV53',
  userback: '11316|42470|WaPCGDTmDHrZwTnbda3eNCUaW',
  ...GDSharedEnvironmentConstants,
};

export default env;
