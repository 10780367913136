import plansApi from '../plans.api';
import {BulkSubtaskUpsertInput, MutationEndpointOptions, Plan} from '../../../types/plans';
import {FetchBaseQueryError} from '@reduxjs/toolkit/query';
import {endpoints} from '../endpoints';
import {v4 as uuid} from 'uuid';
import {compact, flatMap} from 'lodash';
import {Dictionary} from '@reduxjs/toolkit';

export const bulkUpsertSubtaskOptions: MutationEndpointOptions<BulkSubtaskUpsertInput, Plan[]> = {
  async queryFn(arg, api, extra, fetchWithBaseQuery) {
    const plansDataStore = plansApi.endpoints.getPlans.select()(api.getState() as any)!;
    if (plansDataStore.error) return {error: plansDataStore.error as FetchBaseQueryError};
    const {records} = plansDataStore.data!;

    function getURL(input: typeof arg.subtasks[0]) {
      if ('id' in input) {
        // Update
        return {
          url: `${endpoints.subtasks}/${records[arg.parentID].parentID}/${input.id}`,
          method: 'PUT',
        };
      }
      // Create
      return {
        url: `${endpoints.tasks}/${records[arg.parentID].parentID}/${arg.parentID}`,
        method: 'POST',
      };
    }

    const plans: Plan[] = [];
    const subtaskIDs: Dictionary<string> = records[arg.parentID].tasks.reduce(
      (obj, key) => ({...obj, [key]: true}),
      {}
    );

    for (const input of arg.subtasks) {
      const opts = getURL(input);
      const {data, error} = await fetchWithBaseQuery({
        ...opts,
        body: input,
      });

      if (error) return {error};
      if (opts.method !== 'POST') plans.push(data as Plan);
      else {
        const newSubtask = compact(flatMap((data as Plan)?.tasks ?? [], (t) => t.tasks)).find(
          (t) => !(t.id in subtaskIDs)
        );
        if (newSubtask) {
          subtaskIDs[newSubtask.id] = newSubtask.id;
          plans.push(newSubtask);
        }
      }
    }
    return {data: plans};
  },
  async onQueryStarted(arg, api) {
    const tempIDs: Record<string, boolean> = {};
    const patch = api.dispatch(
      plansApi.util.updateQueryData('getPlans', undefined, (draft) => {
        const {records} = draft;
        for (const input of arg.subtasks)
          if ('id' in input)
            records[input.id] = {
              ...records[input.id],
              ...input,
            };
          else {
            const id = uuid();
            tempIDs[id] = true;
            records[id] = {
              id,
              createTimestamp: Date.now(),
              tasks: [],
              parentID: arg.parentID,
              ...input,
            };
          }

        records[arg.parentID].tasks.concat(Object.keys(tempIDs));
      })
    );

    try {
      const {data} = await api.queryFulfilled;
      api.dispatch(
        plansApi.util.updateQueryData('getPlans', undefined, (draft) => {
          const {records} = draft;
          records[arg.parentID].tasks = records[arg.parentID].tasks.filter((id) => !(id in tempIDs));

          for (const subtask of data) {
            if (!(subtask.id in records)) records[arg.parentID].tasks.push(subtask.id);
            records[subtask.id] = {
              ...subtask,
              parentID: arg.parentID,
              tasks: [],
            };
          }
        })
      );
    } catch {
      patch.undo();
    }
  },
};
