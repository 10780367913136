/**
 * Model definition for webhooks
 */
export interface IWebhooks {
  id: number;
  type?: EnumWebhooksType;
  url?: string;
  trigger?: EnumWebhooksTrigger;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumWebhooksType {
  KAJABI = 'KAJABI',
  PROOF = 'PROOF',
}

export enum EnumWebhooksTrigger {
  SIGNUP = 'SIGNUP',
  TRIAL = 'TRIAL',
  PAYMENT = 'PAYMENT',
  PAYMENT_MONTHLY = 'PAYMENT_MONTHLY',
  CANCELLATION = 'CANCELLATION',
  TRIAL_CANCELLATION = 'TRIAL_CANCELLATION',
}
