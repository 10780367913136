import dayjs from 'dayjs';
import {coinsApi, coinsStrapiApi} from './coins.api';

export const {useGetTotalAndQuarterlyCoinsQuery, useGetCoinsByEventQuery, useInvalidateCoinStatsMutation} = coinsApi;

export const {useGetCoinsInfoQuery} = coinsStrapiApi;

export function useGetTotalAndQuarterlyCoins() {
  const {data, ...rest} = useGetTotalAndQuarterlyCoinsQuery('', {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const totalCoins = data?.allTime.count;
  const startDate = dayjs(data?.allTime.since).format('MMM YYYY');
  const quarterlyCoins = data?.quarterly.count;
  const quarterDate = `${dayjs(data?.quarterly.startDate).format('MMM')} - ${dayjs(data?.quarterly.endDate).format(
    'MMM YYYY'
  )}`;

  return {totalCoins, startDate, quarterlyCoins, quarterDate, ...rest};
}

export function useGetCoinsInfo() {
  const {data, ...rest} = useGetCoinsInfoQuery('GetCoinsInfoQuery', {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });

  const title = data?.title;
  const description = data?.description;
  const img = data?.image;

  return {title, description, img, ...rest};
}
