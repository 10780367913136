import {IMarketingLinkedOffers} from './marketing-linked-offers';

/**
 * Model definition for upsells
 */
export interface IUpsells {
  id: number;
  type?: EnumUpsellsType;
  offers: IMarketingLinkedOffers[];
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumUpsellsType {
  FIRST_COURSE_LESSON = 'FIRST_COURSE_LESSON',
  PLAN_DOWNGRADE_BASIC = 'PLAN_DOWNGRADE_BASIC',
  PLAN_DOWNGRADE_MASTERY = 'PLAN_DOWNGRADE_MASTERY',
  PLAN_DOWNGRADE_ALL_ACCESS = 'PLAN_DOWNGRADE_ALL_ACCESS',
  PLAN_CANCEL_BASIC = 'PLAN_CANCEL_BASIC',
  PLAN_CANCEL_MASTERY = 'PLAN_CANCEL_MASTERY',
  PLAN_CANCEL_ALL_ACCESS = 'PLAN_CANCEL_ALL_ACCESS',
}
