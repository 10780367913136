import {axiosGrowthDay} from '../index';
import {PasswordChangeRequest, UserInviteRequest} from './interfaces';
import {CoinsRequest} from '../../services_deprecated/model/coinsRequest';
import {AxiosRequestConfig} from 'axios';
import qs from 'qs';
import {PromoterRewardSummary, PromoterSummary, RewardSummary} from '@growthday/ui-core/src/types/api';

type UpdateProfilePicApiResponse = {
  coinsEarnedThisTime?: number | null;
  currentCoinCount?: number | null;
  fileName?: string;
  isSuccess?: boolean;
  photoId?: string | null;
  url?: string;
};
export const userApi = {
  getUserCoins() {
    return axiosGrowthDay.get<number>(`/users/coins`).then((res) => res.data);
  },
  getUserReferralLink() {
    return axiosGrowthDay.get<{referralLink: string}>(`/users/referralLink`).then((res) => res.data);
  },
  listRewardsPerMonth: () => {
    return axiosGrowthDay.get<RewardSummary[]>(`/users/listRewardsPerMonth`).then((res) => res.data);
  },
  forgotPassword: (email: string, captchaToken: string) => {
    return axiosGrowthDay.post(`/users/forgotPassword?${qs.stringify({email, captchaToken})}`).then((res) => res.data);
  },
  passwordChange: (data: PasswordChangeRequest) => {
    return axiosGrowthDay.post(`/users/passwordChange`, data).then((res) => res.data);
  },
  userSignup: (data: UserInviteRequest) => {
    return axiosGrowthDay.post(`/users/userInvite`, data).then((res) => res.data);
  },
  getCoinsPerDay: (data: CoinsRequest) => {
    return axiosGrowthDay.post(`users/coinsPerDay`, data).then((res) => res.data);
  },
  verifyResetPasswordToken: (token: string) => {
    return axiosGrowthDay.get(`users/verify/${token}`).then((res) => res);
  },
  checkEmailExists: (emails: string[], config?: AxiosRequestConfig): Promise<Record<string, boolean>> => {
    return axiosGrowthDay.post('/users/emailExists', emails, config).then((res) => res.data);
  },
  resetPassword: (userEmail: string) => {
    return axiosGrowthDay.post(`/users/resetPassword/${encodeURIComponent(String(userEmail))}`).then((res) => res.data);
  },
  setNewPassword: (request: PasswordChangeRequest) => {
    return axiosGrowthDay.post(`/users/resetPassword`, request).then((res) => res.data);
  },
  getProfilePic: () => {
    return axiosGrowthDay.get(`/users/profilePic`).then((res) => res.data);
  },
  getProfileUserDetails: () => {
    return axiosGrowthDay.get(`/profile/user`).then((res) => res.data);
  },
  uploadProfilePic: (file: Blob) => {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    };
    return axiosGrowthDay
      .post<UpdateProfilePicApiResponse>(`/users/profilePic`, formData, {headers})
      .then((res) => res.data);
  },
  deleteProfilePic: () => {
    return axiosGrowthDay.put(`/users/removeProfilePic`).then((res) => res.data);
  },

  uploadBackgroundProfilePic: (file: Blob) => {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    };
    return axiosGrowthDay.post(`/users/backgroundImage`, formData, {headers}).then((res) => res.data);
  },
  listRewardsSummary: () => {
    return axiosGrowthDay.get<PromoterRewardSummary>(`/users/listRewardsSummary`).then((res) => res.data);
  },
  getPromoterRewardsSummary: () => {
    return axiosGrowthDay.get<PromoterSummary>(`/users/promoterSummary`).then((res) => res.data);
  },
  updateFirstPromoterRefId: (refId: string) => {
    return axiosGrowthDay.post(`/users/updateRefId/${encodeURIComponent(String(refId))}`).then((res) => res.data);
  },
  deleteBackgroundPic: () => {
    return axiosGrowthDay.delete(`/users/backgroundImage`).then((res) => res.data);
  },
};
