import {axiosGrowthDay} from '../index';
import {IPaymentMethod, TriggerInfo} from '../../../features/auth/interfaces';
import {Platform} from '../../util/types';
import urlJoin from 'proper-url-join';
import stripeConfirmIntent from '@/utils/stripe-confirm-intent';

export const stripeApi = {
  paymentMethod: (): Promise<IPaymentMethod> => {
    return axiosGrowthDay.get('/stripe/paymentMethod').then((res) => res.data);
  },
  updateSubscription: (
    priceId: string,
    triggerInfo?: TriggerInfo,
    campaignSlug?: string,
    flowId?: number
  ): Promise<string> => {
    const params: Record<string, any> = {};
    if (triggerInfo) {
      params.trigger = triggerInfo.triggerFlow ?? '';
      params.button = triggerInfo.triggeredFromSection ?? '';
      params.content = triggerInfo.triggeredContentName ?? '';
      params.mixpanel = true;
    }
    const url = urlJoin(`/stripe/updateSubscription/${Platform.WEB}`, {
      query: {
        campaignSlug: campaignSlug ?? '',
        flowId: flowId ?? '',
      },
    });
    return axiosGrowthDay
      .post(url, priceId, {
        params,
        headers: {
          'Content-Type': 'text/plain',
        },
      })
      .then((res) => res.data);
  },
  updateGiftedSubscription: (priceId: string, triggerInfo?: TriggerInfo): Promise<string> => {
    const params: Record<string, any> = {};
    if (triggerInfo) {
      params.trigger = triggerInfo.triggerFlow ?? '';
      params.button = triggerInfo.triggeredFromSection ?? '';
      params.content = triggerInfo.triggeredContentName ?? '';
      params.mixpanel = true;
    }
    return axiosGrowthDay
      .post(`/stripe/updateSubscriptionByConsumer/${Platform.WEB}`, priceId, {
        params,
        headers: {
          'Content-Type': 'text/plain',
        },
      })
      .then((res) => res.data);
  },
  cancelStripeSubscription: (): Promise<IPaymentMethod> => {
    return axiosGrowthDay.post('/stripe/cancelSubscription').then((res) => res.data);
  },
  updatePaymentMethod: (data: string, captchaToken?: string): Promise<IPaymentMethod> => {
    return axiosGrowthDay.put(`/stripe/paymentMethod?captchaToken=${captchaToken}`, data).then((res) => res.data);
  },
  addPaymentMethod: (data: string, captchaToken?: string): Promise<IPaymentMethod> => {
    return axiosGrowthDay.post(`/stripe/paymentMethod?captchaToken=${captchaToken}`, data).then((res) => res.data);
  },
  async purchaseStripeOffers(offerIds: number[], triggerInfo?: TriggerInfo, isSCAFlowEnabled?: boolean) {
    const params: Record<string, any> = {};
    if (triggerInfo) {
      params.trigger = triggerInfo.triggerFlow ?? '';
      params.button = triggerInfo.triggeredFromSection ?? '';
      params.content = triggerInfo.triggeredContentName ?? '';
      params.mixpanel = true;
    }
    if (isSCAFlowEnabled) {
      const result = await axiosGrowthDay.post(`/stripe/offers/sca/${Platform.WEB}`, {offerIds}, {params});
      if (result?.data?.length) {
        const confirmedIntents = await stripeConfirmIntent(result.data);
        if (confirmedIntents.length) {
          const body = {
            offerIds: confirmedIntents.map((confirmedIntent) => confirmedIntent.itemStrapiId),
            intentRequests: confirmedIntents,
          };
          return axiosGrowthDay.post(`/stripe/offers/sca/${Platform.WEB}`, body, {params});
        } else {
          throw Error('Payment verification failed.');
        }
      }
      return result.data;
    } else {
      return axiosGrowthDay.post(`/stripe/offers/${Platform.WEB}`, offerIds, {params}).then((res) => res.data);
    }
  },
  renewStripePurchasedOffer(purchasedOfferId: string) {
    return axiosGrowthDay.post(`/stripe/offers/${Platform.WEB}/renew`, purchasedOfferId).then((res) => res.data);
  },
  cancelStripePurchasedOffer(purchasedOfferId: string) {
    return axiosGrowthDay.post(`/stripe/offers/${Platform.WEB}/cancel`, purchasedOfferId).then((res) => res.data);
  },
};
