import {useMutation, UseMutationOptions} from 'react-query';
import {axiosGrowthDay} from '../../../utils/api';
import {GrowthGroupInstance} from '../../../types/api';

export type GroupEndPayload = {
  instanceId: GrowthGroupInstance['id'];
  slug: GrowthGroupInstance['slug'];
};

export type GroupEndResponse = GrowthGroupInstance;

export default function useEndGroupMutation(
  options: UseMutationOptions<GroupEndResponse, unknown, GroupEndPayload> = {}
) {
  return useMutation(async (payload) => {
    const result = await axiosGrowthDay('/groups/endInstance', {
      params: {
        instanceId: payload.instanceId,
      },
      method: 'POST',
    });

    return result.data as GroupEndResponse;
  }, options);
}
