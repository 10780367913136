import {FC} from 'react';

const PlusIcon: FC = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 0C3.58881 0 0 3.58881 0 8C0 12.4112 3.58881 16 8 16C12.4112 16 16 12.4112 16 8C15.9997 3.58881 12.4112 0 8 0Z"
        fill="#CFD3D6"
      />
      <path d="M8 4.5V11.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.5 8H11.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default PlusIcon;
