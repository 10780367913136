import config from '@/config';
import useStableCallback from '@growthday/ui-core/src/hooks/useStableCallback';
import {ConfigResponseFeaturesEnum} from '@growthday/ui-core/src/types/api';
import {AllKeys} from '../../../customization/typings/rebrand';
import {
  SingleConfigResponseFeaturesEnumConfig,
  useFilterEnabledFeatures,
} from '@/features/feature-disable/hooks/useFilterEnabledFeatures';
import {useLruCache} from '@/shared/util/LRUCache';
import {get} from 'lodash';
import {memoize} from '@growthday/ui-core/src/utils/memoizeWithShallowCompare';

export const rebrandTextImperatively = memoize((keyConfig: AllKeys) => {
  if (keyConfig) {
    return get(config.env, keyConfig, '');
  }

  return '';
});

export const useRebrandText = () => {
  const {get: getMap, put} = useLruCache();
  const {filterFeaturesEnabled} = useFilterEnabledFeatures();

  const rebrandTextFromEnv = useStableCallback((keyConfig: AllKeys) => {
    if (keyConfig) {
      const getValue = getMap(keyConfig);
      if (!getValue) {
        const valueFromConfigFile = get(config.env, keyConfig, '') as string;
        put(keyConfig, valueFromConfigFile);
        return valueFromConfigFile;
      }
      return getValue;
    }
    return '';
  });

  const checkAndReturnFeaturesEnabled = useStableCallback(
    (features: ConfigResponseFeaturesEnum[] | SingleConfigResponseFeaturesEnumConfig[]) => {
      return filterFeaturesEnabled(features);
    }
  );

  return {
    rebrandTextFromEnv,
    checkAndReturnFeaturesEnabled,
  };
};
