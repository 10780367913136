import {FC, useMemo} from 'react';
import {responsiveArray, ScreenMap} from 'antd/lib/_util/responsiveObserve';
import useGDBreakpoint from '../hooks/useBreakpoint';

const Visible: FC<ScreenMap> = ({children, ...props}) => {
  const breakpoint = useGDBreakpoint();
  const isVisible = useMemo(() => {
    const show = responsiveArray.some((key) => props[key] && breakpoint[key]);
    const hide = responsiveArray.some((key) => props[key] === false && breakpoint[key]);
    return show && !hide;
  }, [props, breakpoint]);
  return isVisible ? <>{children}</> : null;
};

export default Visible;
