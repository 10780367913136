import {useEffect, useRef} from 'react';

export default function useRunOnUnMount(callback: () => void) {
  const cbRef = useRef(callback);
  cbRef.current = callback;
  useEffect(() => {
    return () => {
      cbRef.current();
    };
  }, []);
}
