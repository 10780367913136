import GDTypography from '../../../../shared/components/ui/Typography/Typography';
import styles from './RecaptchaTerms.module.less';

export function RecaptchaTerms() {
  return (
    <GDTypography block type="w50" center className={styles.typography}>
      This site is protected by reCAPTCHA and the Google{' '}
      <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
        Privacy Policy
      </a>{' '}
      and{' '}
      <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">
        Terms of Service
      </a>{' '}
      apply.
    </GDTypography>
  );
}
