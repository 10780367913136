import {
  HMSConfig,
  selectIsConnectedToRoom,
  selectLocalPeerRole,
  useHMSActions,
  useHMSStore,
} from '@100mslive/react-sdk';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import useEffectExceptOnMount from '@/shared/hooks/useEffectExceptOnMount';
import useGrowthGroupsState from '@growthday/ui-core/src/features/growthgroups/hooks/hms/useGrowthGroupsState';
import {GrowthGroupInstance} from '@growthday/ui-core/src/types/api';
import useEndGroupMutation from '@growthday/ui-core/src/features/growthgroups/hooks/useEndGroupMutation';
import {HMSPeerMetadata, HMSPlatform} from '@growthday/ui-core/src/types/growth-groups/hms';
import useMarkGroupAsEnded from '@growthday/ui-core/src/features/growthgroups/hooks/useMarkGroupAsEnded';
import {isMobile} from 'react-device-detect';
import useStableCallback from '@growthday/ui-core/src/hooks/useStableCallback';
import {metadataParser} from '@growthday/ui-core/src/features/growthgroups/util/parsePeerMetadata';
import {useGroupMixpanelEvents} from '@/features/growthgroups/hooks/useGroupMixpanelEvents';
import {FeatureGateEnum} from '@growthday/ui-core/src/features/feature-gate/types';
import {useDestroyChat} from '@growthday/ui-core/src/features/chat/hooks/use-destroy-chat';
import {ChatFeaturesEnum} from '@growthday/ui-core/src/features/chat/store/chat-store';
import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';

const useRoomState = (slugProp?: string) => {
  const hmsActions = useHMSActions();
  const {user} = useUser();
  const {token, setLoading, setLoadingInstanceId, setToken, slug, setState, resetState, groupId, reJoinGroupState} =
    useGrowthGroupsState();
  const isRoomConnected = Boolean(useHMSStore(selectIsConnectedToRoom));
  const isJoined = slugProp === slug && isRoomConnected;
  const localRole = useHMSStore(selectLocalPeerRole);
  const {sendLeaveEvent} = useGroupMixpanelEvents();
  const destroyChat = useDestroyChat(ChatFeaturesEnum.GROWTH_GROUPS);

  useEffectExceptOnMount(() => {
    setLoading(false);
    setLoadingInstanceId(null);
  }, [setLoading, isRoomConnected]);

  const onRoomConnectedCallback = useRef<(() => void) | null>(null);

  useEffect(() => {
    if (isRoomConnected && onRoomConnectedCallback.current) {
      const cb = onRoomConnectedCallback.current;
      onRoomConnectedCallback.current = null;
      cb();
    }
  }, [isRoomConnected]);

  const join = useCallback(
    async (
      _token: string = token,
      config: {
        groupId: string;
        instanceId: string;
        settings?: HMSConfig['settings'];
        slug: string;
      }
    ) => {
      if (groupId !== config.groupId) {
        resetState();
      } else {
        reJoinGroupState({groupId: config.groupId});
      }
      if (_token !== token) {
        // Whenever a new room is joined, reset this state
        setToken(_token);
      }
      if (!isRoomConnected && _token) {
        const metaData: HMSPeerMetadata = [
          metadataParser.joinedAt.serialize(Date.now()),
          metadataParser.platform.serialize(isMobile ? HMSPlatform.MOBILE : HMSPlatform.WEB),
          metadataParser.isHandRaised.serialize(false),
        ];
        setLoading(true);
        await hmsActions.setLocalAudioEnabled(!config.settings?.isAudioMuted);
        await hmsActions.setLocalVideoEnabled(!config.settings?.isVideoMuted);
        hmsActions.initAppData({[FeatureGateEnum.GrowthGroupNerdStats]: {}});
        onRoomConnectedCallback.current = () => {
          setState({slug: config.slug, showAdmissionModal: true, groupId: config.groupId});
        };

        await hmsActions.join({
          authToken: _token,
          userName: user?.fullName?.trim() || '',
          settings: config.settings,
          metaData: JSON.stringify(metaData),
          rememberDeviceSelection: true,
          alwaysRequestPermissions: true,
        });
      }
    },
    [
      token,
      groupId,
      isRoomConnected,
      resetState,
      reJoinGroupState,
      setToken,
      setLoading,
      hmsActions,
      setState,
      user?.fullName,
    ]
  );

  const leave = useCallback(
    async (groupInstance: GrowthGroupInstance) => {
      if (isRoomConnected && slug === groupInstance.slug) {
        setLoading(true);
        await hmsActions.leave();
        await destroyChat();
        resetState();
        setState({
          token: '',
          slug: '',
          loading: false,
          showAdmissionModal: true,
          sessionMetadataInitialized: false,
        });
        sendLeaveEvent(groupInstance);
      }
    },
    [isRoomConnected, slug, setLoading, hmsActions, destroyChat, resetState, setState, sendLeaveEvent]
  );

  const {mutateAsync: endGroupMutation} = useEndGroupMutation();
  const markEnded = useMarkGroupAsEnded();
  const end = useCallback(
    async (groupInstance: GrowthGroupInstance, disableBECall = false) => {
      if (groupInstance.slug !== slug || !isRoomConnected) {
        return;
      }
      if (localRole?.permissions.endRoom) {
        setLoading(true);
        try {
          const promise = disableBECall
            ? Promise.resolve()
            : endGroupMutation({slug: groupInstance.slug, instanceId: groupInstance.id});
          await hmsActions.endRoom(!groupInstance.rrule, 'Room ended by host');
          // Mark group as ended as soon as 100ms room is ended. BE call has edge cases along with 100ms webhooks and
          // is not necessary
          markEnded(groupInstance.id, groupInstance.slug);
          await leave(groupInstance);
          await promise;
        } catch (e) {
          // Fail silently
        }
        setState({
          token: '',
          slug: '',
          loading: false,
          showAdmissionModal: true,
          sessionMetadataInitialized: false,
        });
      }
    },
    [
      slug,
      isRoomConnected,
      localRole?.permissions.endRoom,
      setLoading,
      setState,
      endGroupMutation,
      hmsActions,
      markEnded,
      leave,
    ]
  );

  const leaveOrEnd = useStableCallback(async (group?: GrowthGroupInstance | null | undefined) => {
    try {
      if (localRole?.permissions.endRoom) {
        if (!group) {
          await hmsActions.endRoom(false, 'Room Ended by Host');
        } else {
          await end(group, true);
        }
      } else {
        if (!group) {
          await hmsActions.leave();
        } else {
          await leave(group);
        }
      }
    } catch (e) {
      // Fail silently
    }
  });

  const hasJoinedAnotherCall = Boolean(isRoomConnected && slugProp !== slug);

  return useMemo(
    () => ({leave, join, isJoined, end, leaveOrEnd, hasJoinedAnotherCall}),
    [leave, join, isJoined, end, leaveOrEnd, hasJoinedAnotherCall]
  );
};

export default useRoomState;
