/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {CmsEvents} from './cms-events';
// May contain unused imports in some cases
// @ts-ignore
import {CmsGiftSubscriptionSignup} from './cms-gift-subscription-signup';
// May contain unused imports in some cases
// @ts-ignore
import {CmsMarketingChallenge} from './cms-marketing-challenge';
// May contain unused imports in some cases
// @ts-ignore
import {CmsMarketingEvent} from './cms-marketing-event';
// May contain unused imports in some cases
// @ts-ignore
import {CmsMarketingOffer} from './cms-marketing-offer';
// May contain unused imports in some cases
// @ts-ignore
import {CmsMarketingPlan} from './cms-marketing-plan';
// May contain unused imports in some cases
// @ts-ignore
import {CmsMarketingWebhook} from './cms-marketing-webhook';
// May contain unused imports in some cases
// @ts-ignore
import {CmsOffer} from './cms-offer';
// May contain unused imports in some cases
// @ts-ignore
import {CmsOnboard} from './cms-onboard';
// May contain unused imports in some cases
// @ts-ignore
import {CmsStriverContent} from './cms-striver-content';

/**
 *
 * @export
 * @interface CmsMarketingCampaign
 */
export interface CmsMarketingCampaign {
  /**
   *
   * @type {number}
   * @memberof CmsMarketingCampaign
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CmsMarketingCampaign
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof CmsMarketingCampaign
   */
  slug?: string;
  /**
   *
   * @type {boolean}
   * @memberof CmsMarketingCampaign
   */
  active?: boolean;
  /**
   *
   * @type {string}
   * @memberof CmsMarketingCampaign
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof CmsMarketingCampaign
   */
  endDate?: string;
  /**
   *
   * @type {string}
   * @memberof CmsMarketingCampaign
   */
  type?: CmsMarketingCampaignTypeEnum;
  /**
   *
   * @type {CmsOffer}
   * @memberof CmsMarketingCampaign
   */
  orderBump?: CmsOffer;
  /**
   *
   * @type {CmsOnboard}
   * @memberof CmsMarketingCampaign
   */
  onboarding?: CmsOnboard;
  /**
   *
   * @type {Array<CmsOffer>}
   * @memberof CmsMarketingCampaign
   */
  onboardingUpsell?: Array<CmsOffer>;
  /**
   *
   * @type {CmsGiftSubscriptionSignup}
   * @memberof CmsMarketingCampaign
   */
  content?: CmsGiftSubscriptionSignup;
  /**
   *
   * @type {Array<object>}
   * @memberof CmsMarketingCampaign
   */
  component?: Array<object>;
  /**
   *
   * @type {Array<CmsEvents>}
   * @memberof CmsMarketingCampaign
   */
  events?: Array<CmsEvents>;
  /**
   *
   * @type {Array<CmsMarketingWebhook>}
   * @memberof CmsMarketingCampaign
   */
  webhooks?: Array<CmsMarketingWebhook>;
  /**
   *
   * @type {CmsStriverContent}
   * @memberof CmsMarketingCampaign
   */
  membersContent?: CmsStriverContent;
  /**
   *
   * @type {CmsStriverContent}
   * @memberof CmsMarketingCampaign
   */
  nonMembersContent?: CmsStriverContent;
  /**
   *
   * @type {boolean}
   * @memberof CmsMarketingCampaign
   */
  isFree?: boolean;
  /**
   *
   * @type {CmsMarketingOffer}
   * @memberof CmsMarketingCampaign
   */
  offer?: CmsMarketingOffer;
  /**
   *
   * @type {boolean}
   * @memberof CmsMarketingCampaign
   */
  isActive?: boolean;
  /**
   *
   * @type {CmsMarketingPlan}
   * @memberof CmsMarketingCampaign
   */
  plan?: CmsMarketingPlan;
  /**
   *
   * @type {CmsMarketingChallenge}
   * @memberof CmsMarketingCampaign
   */
  challenge?: CmsMarketingChallenge;
  /**
   *
   * @type {CmsMarketingEvent}
   * @memberof CmsMarketingCampaign
   */
  event?: CmsMarketingEvent;
}

export const CmsMarketingCampaignTypeEnum = {
  Challenge: 'CHALLENGE',
  Plan: 'PLAN',
  Offer: 'OFFER',
  LiveEvent: 'LIVE_EVENT',
} as const;

export type CmsMarketingCampaignTypeEnum =
  (typeof CmsMarketingCampaignTypeEnum)[keyof typeof CmsMarketingCampaignTypeEnum];
