import {Author, IFeedbackSupport} from '../../../features/feedback/interface';
import {axiosStrapi, axiosGrowthDay} from '../index';

export const feedbackApi = {
  authors: () => {
    return axiosStrapi.get<Author[]>('/authors').then((res) => res.data);
  },
  reportIssue: () => {
    return axiosGrowthDay.post<void>('/users/reportIssue/reportIssue');
  },
  reportFeedback: () => {
    return axiosGrowthDay.post<void>('/users/reportIssue/reportFeedback');
  },
  sendSupportRequest: (request: IFeedbackSupport) => {
    return axiosGrowthDay.post<void>('/helpscout', request);
  },
};
