import {rudderActions} from '@/features/analytics/hooks/useRudder';
import useRudder from '@/features/analytics/hooks/useRudder';
import useGTag, {gTagActions} from '@/marketing/useGTag';
import useGoogleTagManager from '@/marketing/useGoogleTagManager';
import {AnalyticsActions, SuperProperties} from '../analyticsActions';
import {useLayoutEffect, useEffect} from 'react';
import {IUser} from '@/shared/services_deprecated/model/user';
import {tail} from 'lodash';
import {PaymentSystemEnum} from '@/features/auth/enums';
import {readablePlanInterval} from '@/features/auth/utils/plan-interval';
import dayjs from 'dayjs';
import {getAnalyticsSubscriptionStatus} from '@/features/license/utils/subscription';
import {useHistory} from 'react-router-dom';
import getNavigationEventName from '../utils/navigationEvents';
import usePurchasedOffers from '@/features/offers/hooks/usePurchasedOffers';
import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';

const isUsingRudderStackForGtag = true;

// Retrieve the analytics actions to use based on feature gate
const useAnalyticsActions = (): void => {
  const history = useHistory();
  const {purchasedOffers} = usePurchasedOffers();
  const {user} = useUser();

  useRudder();
  useGTag();
  useGoogleTagManager();

  useLayoutEffect(() => {
    // Log the first page view if not using Rudderstack
    if (!isUsingRudderStackForGtag) {
      gTagActions.page('', '/');
    }
  }, []);

  // Track super properties on initialization
  useEffect(() => {
    if (!!user && !!user.id && !!user.uuid) {
      getAnalyticsActions().alias(user.uuid);
      getAnalyticsActions().identify(user.uuid, {
        partnerId: user.partnerId,
        ...getAnalyticsSuperProperties(user),
      });
      // Alias all former metrics for this user by id for the uuid.
      getAnalyticsActions().alias(user.uuid, user.id);
    }
  }, [user]);

  // Track purchased offers on initialization
  useEffect(() => {
    if (user && purchasedOffers?.length) {
      getAnalyticsActions().traits({
        Offers: purchasedOffers.map((p) => p.offer.title).toString(),
        'Offer Count': purchasedOffers.length,
      });
    }
  }, [purchasedOffers, user]);

  // Track initial page view
  useLayoutEffect(() => {
    const eventName = getNavigationEventName(window.location.pathname);
    eventName && getAnalyticsActions().page(eventName);
  }, []);

  // Track subsequent page views
  useEffect(() => {
    const unlisten = history.listen((e) => {
      const eventName = getNavigationEventName(e.pathname);
      eventName && getAnalyticsActions().page(eventName);
    });

    return () => {
      unlisten();
    };
  }, [history]);
};

export function getGtagActions(): AnalyticsActions {
  if (isUsingRudderStackForGtag) {
    return rudderActions;
  } else {
    return gTagActions;
  }
}

export function getAnalyticsActions(): AnalyticsActions {
  return rudderActions;
}

export function analyticsIdentify(user: IUser, onboardingSource?: string) {
  if (!user.id && !user.uuid) return;

  const analyticsActions = rudderActions;
  const parts = user.fullName?.split(' ') ?? [];
  const firstName = (parts[0] || '').trim();
  const lastName = (tail(parts).join(' ') || '').trim();

  if (user.uuid && user.id) {
    analyticsActions.alias(user.uuid);
    analyticsActions.identify(user.uuid);
    analyticsActions.alias(user.uuid, user.id);
  }

  analyticsActions.traits({
    PhoneNumber: user.phoneNumber,
    SignupDate: user.createTimestamp,
    'Sign Up Date': dayjs(user.createTimestamp).tz().format('MMM DD, YYYY'),
    'Payment method': getPaymentMethod(user),
    Coins: user.currentCoinCount,
    'subscription Cancelled': user.subscriptionCancelled,
    'User ID': user.id,
    'Badges Count': user.currentBadgeCount,
    'Certificates Count': user.currentCertificateCount,
    firstName,
    lastName,
    name: user.fullName,
    email: user.email,
    ...getAnalyticsSuperProperties(user),
    ...(onboardingSource ? {'Onboarding Source': onboardingSource} : {}),
  });
}

export function getAnalyticsSuperProperties(user: IUser): SuperProperties {
  return {
    'Plan Name': user?.subscriptionPlanName ?? 'No Active Plan',
    'Plan Type': user?.planFrequency ? readablePlanInterval(user.planFrequency) : 'No Active Plan',
    'Plan Level': user?.level,
    Platform: 'WEB',
    'Subscription Status': getAnalyticsSubscriptionStatus(user),
    'Payment method': getPaymentMethod(user),
  };
}

const getPaymentMethod = (user: IUser) => {
  return user.isPartnerUser
    ? 'BODi'
    : user.enterpriseUser
      ? 'Enterprise'
      : user.kajabiUser
        ? 'Kajabi'
        : user.paymentSystem === PaymentSystemEnum.APPLE
          ? 'Apple'
          : user.paymentSystem === PaymentSystemEnum.STRIPE
            ? 'Stripe'
            : user.paymentSystem === PaymentSystemEnum.PAYPAL
              ? 'Paypal'
              : '';
};
export default useAnalyticsActions;
