/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {CmsMarketingPlanTrial} from './cms-marketing-plan-trial';
// May contain unused imports in some cases
// @ts-ignore
import {CmsSubscriptionPlan} from './cms-subscription-plan';

/**
 *
 * @export
 * @interface CmsMarketingPlan
 */
export interface CmsMarketingPlan {
  /**
   *
   * @type {boolean}
   * @memberof CmsMarketingPlan
   */
  trial?: boolean;
  /**
   *
   * @type {string}
   * @memberof CmsMarketingPlan
   */
  frequency?: CmsMarketingPlanFrequencyEnum;
  /**
   *
   * @type {CmsSubscriptionPlan}
   * @memberof CmsMarketingPlan
   */
  plan?: CmsSubscriptionPlan;
  /**
   *
   * @type {CmsMarketingPlanTrial}
   * @memberof CmsMarketingPlan
   */
  planTrial?: CmsMarketingPlanTrial;
}

export const CmsMarketingPlanFrequencyEnum = {
  Month: 'MONTH',
  Year: 'YEAR',
} as const;

export type CmsMarketingPlanFrequencyEnum =
  (typeof CmsMarketingPlanFrequencyEnum)[keyof typeof CmsMarketingPlanFrequencyEnum];
