import {createSlice, current, PayloadAction} from '@reduxjs/toolkit';
import {getApiActionBuilder} from '../../shared/redux/util/build-api-action';
import * as apiActions from './notes.actions';
import {NotesLoadingState, NotesState} from './interface';
import _unset from 'lodash/unset';
import coerceArray from '../../shared/util/coerceArray';
import {stateWithLoading} from '../../shared/redux/util/state-with-loading';
import {deleteGroupNote, updateGroupNote} from './notes.actions';

const initialState = stateWithLoading<NotesState, NotesLoadingState>({
  notes: [],
  pinnedNotes: [],
});

export const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    unsetOnboardingState(state, action) {
      const keys = coerceArray(action.payload);
      keys.forEach((key) => {
        _unset(state, key);
      });
    },
  },
  extraReducers: (builder) => {
    const buildApiAction = getApiActionBuilder<NotesState>(builder, apiActions);

    buildApiAction<PayloadAction<any>>('getAllNotes', {
      fulfilled: (state, action) => {
        state.notes = action.payload.data.noteData;
        state.pinnedNotes = action.payload.data.pinnedNoteData;
      },
    });

    buildApiAction<PayloadAction<any>>('pinOneOfNotes', {
      fulfilled: (state, action) => {
        if (action.payload.noteCategory === 'LESSON') {
          const pinnedNoteContainerIndex = current(state).pinnedNotes.findIndex(
            (elem) => elem.topicId === action.payload.lessonId
          );
          const currentNoteContainerIndex = current(state).notes.findIndex(
            (elem) => elem.topicId === action.payload.lessonId
          );
          const currentNoteContainer = current(state).notes[currentNoteContainerIndex];
          const curentNoteIndex = currentNoteContainer.notes.findIndex((elem) => elem.noteId === action.payload.noteId);

          if (pinnedNoteContainerIndex >= 0) {
            const noteIndex = currentNoteContainer.notes.findIndex((note) => note.noteId === action.payload.noteId);
            const currentPinnedNoteContainer = current(state).pinnedNotes[pinnedNoteContainerIndex];
            state.pinnedNotes[pinnedNoteContainerIndex].notes = [
              ...currentPinnedNoteContainer.notes,
              {...currentNoteContainer.notes[noteIndex]},
            ];
            state.notes[currentNoteContainerIndex].notes[curentNoteIndex] = {
              ...current(state).notes[currentNoteContainerIndex].notes[curentNoteIndex],
              pinned: true,
            };
          } else {
            const newNoteContainer = currentNoteContainer;
            state.pinnedNotes = [
              {
                ...newNoteContainer,
                notes: newNoteContainer.notes.filter((note) => note.noteId === action.payload.noteId),
              },
              ...state.pinnedNotes,
            ];
            state.notes[currentNoteContainerIndex].notes[curentNoteIndex] = {
              ...current(state).notes[currentNoteContainerIndex].notes[curentNoteIndex],
              pinned: true,
            };
          }
        } else {
          const newNoteContainer = current(state).notes.find((elem) => elem.id === action.payload.noteObjectId);
          const newNoteContainerIndex = current(state).notes.findIndex(
            (elem) => elem.id === action.payload.noteObjectId
          );

          if (newNoteContainer) {
            state.pinnedNotes = [newNoteContainer, ...state.pinnedNotes];
            state.notes[newNoteContainerIndex] = {...newNoteContainer, pinned: true};
          }
        }
      },
    });

    buildApiAction<PayloadAction<any>>('unpinOneOfNotes', {
      fulfilled: (state, action) => {
        if (action.payload.noteCategory === 'LESSON') {
          const pinnedNoteContainerIndex = current(state).pinnedNotes.findIndex(
            (elem) => elem.topicId === action.payload.lessonId
          );
          const newPinnedNotesArray = current(state).pinnedNotes;
          const newPinnedNoteContainer = current(state).pinnedNotes[pinnedNoteContainerIndex];
          const newNotesArray = newPinnedNoteContainer.notes.filter((elem) => elem.noteId !== action.payload.noteId);
          const currentNoteContainerIndex = current(state).notes.findIndex(
            (elem) => elem.topicId === action.payload.lessonId
          );
          const currentNoteContainer = current(state).notes[currentNoteContainerIndex];
          const curentNoteIndex = currentNoteContainer.notes.findIndex((elem) => elem.noteId === action.payload.noteId);

          newNotesArray.length
            ? (state.pinnedNotes = [
                {...newPinnedNoteContainer, notes: newNotesArray},
                ...newPinnedNotesArray.filter((elem) => elem.topicId !== action.payload.lessonId),
              ])
            : (state.pinnedNotes = [...newPinnedNotesArray.filter((elem) => elem.topicId !== action.payload.lessonId)]);
          state.notes[currentNoteContainerIndex].notes[curentNoteIndex] = {
            ...current(state).notes[currentNoteContainerIndex].notes[curentNoteIndex],
            pinned: false,
          };
        } else {
          const newNoteContainer = current(state).pinnedNotes.filter((elem) => elem.id !== action.payload.noteObjectId);
          const newDefaultNoteContainer = current(state).notes.find((elem) => elem.id === action.payload.noteObjectId);
          if (newNoteContainer && newDefaultNoteContainer) {
            const newNoteContainerIndex = current(state).notes.findIndex(
              (elem) => elem.id === action.payload.noteObjectId
            );
            state.pinnedNotes = newNoteContainer;
            state.notes[newNoteContainerIndex] = {...newDefaultNoteContainer, pinned: false};
          }
        }
      },
    });

    buildApiAction<PayloadAction<any>>('deleteSomeNote', {
      fulfilled: (state, action) => {
        const noteContainerIndex = current(state).notes.findIndex(
          (elem) => elem.courseId === +action.payload.courseId && elem.topicId === +action.payload.lessonId
        );
        const pinnedNoteContainerIndex = current(state).pinnedNotes.findIndex(
          (elem) => elem.courseId === +action.payload.courseId && elem.topicId === +action.payload.lessonId
        );
        if (state.notes[noteContainerIndex]) {
          state.notes[noteContainerIndex].notes.length - 1
            ? (state.notes[noteContainerIndex].notes = current(state).notes[noteContainerIndex].notes.filter(
                (elem) => elem.noteId !== action.payload.noteId
              ))
            : (state.notes = current(state).notes.filter(
                (elem) =>
                  elem.courseId !== current(state).notes[noteContainerIndex].courseId ||
                  elem.topicId !== state.notes[noteContainerIndex].topicId
              ));
        }
        if (state.pinnedNotes[pinnedNoteContainerIndex]) {
          state.pinnedNotes[pinnedNoteContainerIndex].notes.length - 1
            ? (state.pinnedNotes[pinnedNoteContainerIndex].notes = current(state).pinnedNotes[
                pinnedNoteContainerIndex
              ].notes.filter((elem) => elem.noteId !== action.payload.noteId))
            : (state.pinnedNotes = current(state).pinnedNotes.filter(
                (elem) =>
                  elem.courseId !== state.pinnedNotes[pinnedNoteContainerIndex].courseId ||
                  elem.topicId !== state.pinnedNotes[pinnedNoteContainerIndex].topicId
              ));
        }
      },
    });

    buildApiAction<PayloadAction<any>>('updateLessonNote', {
      fulfilled: (state, action) => {
        const noteContainerIndex = current(state).notes.findIndex(
          (elem) => elem.courseId === +action.payload.courseId && elem.topicId === +action.payload.lessonId
        );
        const pinnedNoteContainerIndex = current(state).pinnedNotes.findIndex(
          (elem) => elem.courseId === +action.payload.courseId && elem.topicId === +action.payload.lessonId
        );
        if (state.notes[noteContainerIndex]) {
          const noteIndex = current(state).notes[noteContainerIndex].notes.findIndex(
            (note) => note.noteId === action.payload.noteId
          );
          if (state.notes[noteContainerIndex].notes[noteIndex])
            state.notes[noteContainerIndex].notes[noteIndex].noteContent = action.payload.noteContent;
        }
        if (state.pinnedNotes[pinnedNoteContainerIndex]) {
          const pinnedNoteIndex = current(state).pinnedNotes[pinnedNoteContainerIndex].notes.findIndex(
            (note) => note.noteId === action.payload.noteId
          );
          if (state.pinnedNotes[pinnedNoteContainerIndex].notes[pinnedNoteIndex])
            state.pinnedNotes[pinnedNoteContainerIndex].notes[pinnedNoteIndex].noteContent = action.payload.noteContent;
        }
      },
    });

    buildApiAction<PayloadAction<any>>(['updateLiveEventNote', 'updateChallengeNote'], {
      fulfilled: (state, action) => {
        if (action.payload?.noteContent) {
          const noteContainerIndex = current(state).notes.findIndex((elem) => elem.id === action.payload.id);
          const pinnedNoteContainerIndex = current(state).pinnedNotes.findIndex(
            (elem) => elem.id === action.payload.id
          );
          if (state.notes[noteContainerIndex]) state.notes[noteContainerIndex].noteContent = action.payload.noteContent;
          if (state.notes[pinnedNoteContainerIndex])
            state.pinnedNotes[pinnedNoteContainerIndex].noteContent = action.payload.noteContent;
        } else {
          state.notes = current(state).notes.filter((elem) => elem.id !== action.payload.id);
          state.pinnedNotes = current(state).pinnedNotes.filter((elem) => elem.id !== action.payload.id);
        }
      },
    });

    builder.addCase(deleteGroupNote, (state, action) => {
      const noteId = action.payload;
      state.notes = state.notes.filter((note) => note.id !== noteId);
      state.pinnedNotes = state.pinnedNotes.filter((note) => note.id !== noteId);
    });

    builder.addCase(updateGroupNote, (state, action) => {
      const {id: noteId, content} = action.payload;
      const noteObject = state.notes.find((note) => note.id === noteId);
      if (noteObject) {
        noteObject.noteContent = content;
      }

      const pinnedObject = state.pinnedNotes.find((note) => note.id === noteId);
      if (pinnedObject) {
        pinnedObject.noteContent = content;
      }
    });
  },
});

export default notesSlice.reducer;
