import {axiosGrowthDay} from '../../../../../../../shared/api';
import {IProfileGoal, TGoalMilestoneDtos} from '../interfaces';

export type noteDetails = {
  lessonId: number;
  noteCategory: 'LESSON' | 'CHALLENGE' | 'LIVE';
  noteId: string;
  noteObjectId: string;
};

export const myProfileApi = {
  getProfileData() {
    return axiosGrowthDay.get(`/profile/user`).then((res) => res);
  },

  updateProfile(data: {bigWhyText: string; focusAreas: string[]}) {
    return axiosGrowthDay.put(`/profile`, data).then((res) => ({data, success: res}));
  },

  getProfileGoals() {
    return axiosGrowthDay.get(`/goal`).then((res) => res.data);
  },

  createSomeGoal(value: IProfileGoal) {
    return axiosGrowthDay.post(`/goal`, value).then((res) => res);
  },

  createSomeMilestone({goalId, value}: {goalId: string; value: IProfileGoal}) {
    return axiosGrowthDay.post(`/goal/${goalId}`, value).then((res) => ({id: goalId, value: res.data}));
  },

  updateSomeGoal({goalId, value}: {goalId: string; value: IProfileGoal}) {
    return axiosGrowthDay
      .put(`/goal/${goalId}`, value)
      .then((res) => ({response: res.data as IProfileGoal, oldData: value}));
  },

  updateSomeMilestone({goalId, milestoneId, value}: {goalId: string; milestoneId: string; value: IProfileGoal}) {
    return axiosGrowthDay
      .put(`/goal/${goalId}/${milestoneId}`, value)
      .then((res) => ({goalId, milestoneId, value: res.data}));
  },

  updateMilestoneList({goalId, value}: {goalId: string; value: TGoalMilestoneDtos[]}) {
    return axiosGrowthDay.put(`/goal/${goalId}/milestone`, value).then((res) => ({goalId, value: res.data}));
  },

  markGoalAsCompleted({goalId}: {goalId: string}) {
    return axiosGrowthDay.put(`/goal/complete/${goalId}`).then((res) => ({id: goalId, success: res.data}));
  },

  markGoalAsIncompleted({goalId}: {goalId: string}) {
    return axiosGrowthDay.put(`/goal/incomplete/${goalId}`).then((res) => ({id: goalId, success: res.data}));
  },

  markMilestoneAsCompleted({goalId, milestoneId}: {goalId: string; milestoneId: string}) {
    return axiosGrowthDay
      .put(`/goal/complete/${goalId}/${milestoneId}`)
      .then((res) => ({goalId, milestoneId, success: res.data}));
  },

  markMilestoneAsIncompleted({goalId, milestoneId}: {goalId: string; milestoneId: string}) {
    return axiosGrowthDay
      .put(`/goal/incomplete/${goalId}/${milestoneId}`)
      .then((res) => ({goalId, milestoneId, success: res.data}));
  },

  deleteSomeGoal(goalId: string) {
    return axiosGrowthDay.delete(`/goal/${goalId}`).then((res) => ({id: goalId, success: res}));
  },

  deleteSomeMilestone({goalId, milestoneId}: {goalId: string; milestoneId: string}) {
    return axiosGrowthDay.delete(`/goal/${goalId}/${milestoneId}`).then((res) => ({goalId, milestoneId, success: res}));
  },

  getNotes(value: {page: number; size: number}) {
    return axiosGrowthDay.get(`/notes?page=${value.page}&size=${value.size}`).then((res) => res);
  },

  saveLiveEventNote(eventId: string, note: string) {
    return axiosGrowthDay.put(`/userLiveEvents/notes/${eventId}`, note, {
      headers: {
        'Content-Type': 'text/plain',
      },
    });
  },

  updateGoalOrder: (data: Record<string, number>) => {
    return axiosGrowthDay.post('/goal/updateOrder', data);
  },

  getChallengeNotes: (userChallengeId: string, customChallengeId: number, masterOptionalId?: number) => {
    return axiosGrowthDay.get(
      `/link/CHALLENGE/${userChallengeId}/${customChallengeId}${masterOptionalId ? '/' + masterOptionalId : ''}`
    );
  },
};
