/**
 * Model definition for CustomComponent
 */
export interface ICustomComponent {
  id: number;
  type?: EnumCustomComponentType;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumCustomComponentType {
  UPSELL = 'UPSELL',
  PLAN_SELECT = 'PLAN_SELECT',
  ORDER_BUMP = 'ORDER_BUMP',
  SIGNUP_TYPES = 'SIGNUP_TYPES',
  CAMPAIGN_DETAILS = 'CAMPAIGN_DETAILS',
  CAMPAIGN_DETAILS_SMALL = 'CAMPAIGN_DETAILS_SMALL',
}
