import {ChatFeaturesEnum, useChatStore} from '../store/chat-store';
import {
  selectChatInitialized,
  selectChatSession,
  SelectMembershipInitialized,
  selectResetChatStoreByKey,
} from '../store/chat-store-selectors';
import {useChatMessageStore} from '../store/message-store';
import {selectResetChatMessageStore} from '../store/message-store-selectors';
import useStableCallback from '../../../hooks/useStableCallback';

export function useDestroyChat(key: ChatFeaturesEnum) {
  const resetChatStore = useChatStore(selectResetChatStoreByKey(key));
  const resetChatMessageStore = useChatMessageStore(selectResetChatMessageStore);
  const session = useChatStore(selectChatSession);
  const initialized = useChatStore(selectChatInitialized(key));
  const membershipInitialised = useChatStore((store) => SelectMembershipInitialized(key, store));

  return useStableCallback(async () => {
    // Cleanup should run if chat is initialised on Chime
    // Or if the membership was initiated on BE - chime join might've failed
    if (!initialized && !membershipInitialised) {
      return;
    }
    try {
      if (session) {
        await session.stop();
      }
      resetChatStore();
      resetChatMessageStore(key);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  });
}
