/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {CmsOffer} from './cms-offer';

/**
 *
 * @export
 * @interface PurchasedOffer
 */
export interface PurchasedOffer {
  /**
   *
   * @type {string}
   * @memberof PurchasedOffer
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof PurchasedOffer
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof PurchasedOffer
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof PurchasedOffer
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof PurchasedOffer
   */
  uuid?: string;
  /**
   *
   * @type {number}
   * @memberof PurchasedOffer
   */
  offerId?: number;
  /**
   *
   * @type {CmsOffer}
   * @memberof PurchasedOffer
   */
  offer?: CmsOffer;
  /**
   *
   * @type {number}
   * @memberof PurchasedOffer
   */
  offerPrice?: number;
  /**
   *
   * @type {number}
   * @memberof PurchasedOffer
   */
  expiry?: number;
  /**
   *
   * @type {string}
   * @memberof PurchasedOffer
   */
  platform?: PurchasedOfferPlatformEnum;
  /**
   *
   * @type {string}
   * @memberof PurchasedOffer
   */
  paymentSystem?: PurchasedOfferPaymentSystemEnum;
  /**
   *
   * @type {string}
   * @memberof PurchasedOffer
   */
  subscriptionId?: string;
  /**
   *
   * @type {number}
   * @memberof PurchasedOffer
   */
  cancelledOn?: number;
  /**
   *
   * @type {boolean}
   * @memberof PurchasedOffer
   */
  giftedByGrowthDay?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PurchasedOffer
   */
  cancelled?: boolean;
}

export const PurchasedOfferPlatformEnum = {
  Web: 'WEB',
  Ios: 'IOS',
  Android: 'ANDROID',
} as const;

export type PurchasedOfferPlatformEnum = (typeof PurchasedOfferPlatformEnum)[keyof typeof PurchasedOfferPlatformEnum];
export const PurchasedOfferPaymentSystemEnum = {
  Card: 'CARD',
  Paypal: 'PAYPAL',
  Apple: 'APPLE',
} as const;

export type PurchasedOfferPaymentSystemEnum =
  (typeof PurchasedOfferPaymentSystemEnum)[keyof typeof PurchasedOfferPaymentSystemEnum];
