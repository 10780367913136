/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {EmbeddedReminderRequest} from './embedded-reminder-request';
// May contain unused imports in some cases
// @ts-ignore
import {GoalMilestoneDto} from './goal-milestone-dto';
// May contain unused imports in some cases
// @ts-ignore
import {Plan} from './plan';

/**
 * Goal DTO object
 * @export
 * @interface GoalDto
 */
export interface GoalDto {
  /**
   * Goal id
   * @type {string}
   * @memberof GoalDto
   */
  id?: string;
  /**
   * Goal title
   * @type {string}
   * @memberof GoalDto
   */
  title: string;
  /**
   * Goal description
   * @type {string}
   * @memberof GoalDto
   */
  description?: string;
  /**
   * Goal due date in epoch long
   * @type {number}
   * @memberof GoalDto
   */
  dueDate?: number;
  /**
   * List of focus areas
   * @type {Array<string>}
   * @memberof GoalDto
   */
  focusAreas?: Array<string>;
  /**
   * List of valid plan ids
   * @type {Array<string>}
   * @memberof GoalDto
   */
  planIds?: Array<string>;
  /**
   * List of linked plans
   * @type {Array<Plan>}
   * @memberof GoalDto
   */
  plans?: Array<Plan>;
  /**
   * List of goal milestones
   * @type {Array<GoalMilestoneDto>}
   * @memberof GoalDto
   */
  goalMilestoneDtos?: Array<GoalMilestoneDto>;
  /**
   *
   * @type {EmbeddedReminderRequest}
   * @memberof GoalDto
   */
  reminder?: EmbeddedReminderRequest;
  /**
   * Flag if goal is complete
   * @type {boolean}
   * @memberof GoalDto
   */
  complete?: boolean;
  /**
   * Order goal
   * @type {number}
   * @memberof GoalDto
   */
  order?: number;
  /**
   * Type of record we can link with goal
   * @type {string}
   * @memberof GoalDto
   */
  type?: GoalDtoTypeEnum;
  /**
   * Linked record id. If we link goal with challenge then we need to send id of the challenge object
   * @type {string}
   * @memberof GoalDto
   */
  masterPrimaryId?: string;
  /**
   * Linked record secondary id that will help us identify the record uniquely
   * @type {string}
   * @memberof GoalDto
   */
  masterSecondaryId?: string;
  /**
   * Linked record optional id. This is only used for challnge
   * @type {string}
   * @memberof GoalDto
   */
  masterOptionalId?: string;
}

export const GoalDtoTypeEnum = {
  Challenge: 'CHALLENGE',
  Journal: 'JOURNAL',
  Goal: 'GOAL',
  Plan: 'PLAN',
} as const;

export type GoalDtoTypeEnum = (typeof GoalDtoTypeEnum)[keyof typeof GoalDtoTypeEnum];
