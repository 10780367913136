/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {CmsMedia} from './cms-media';
// May contain unused imports in some cases
// @ts-ignore
import {Hms} from './hms';

/**
 *
 * @export
 * @interface GrowthGroup
 */
export interface GrowthGroup {
  /**
   *
   * @type {string}
   * @memberof GrowthGroup
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof GrowthGroup
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroup
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroup
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof GrowthGroup
   */
  channel: GrowthGroupChannelEnum;
  /**
   *
   * @type {string}
   * @memberof GrowthGroup
   */
  uuid: string;
  /**
   *
   * @type {Hms}
   * @memberof GrowthGroup
   */
  hms?: Hms;
  /**
   *
   * @type {number}
   * @memberof GrowthGroup
   */
  strapiId?: number;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroup
   */
  isWorkshop?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroup
   */
  hlsGroup?: boolean;
  /**
   *
   * @type {string}
   * @memberof GrowthGroup
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroup
   */
  time: string;
  /**
   *
   * @type {number}
   * @memberof GrowthGroup
   */
  duration?: number;
  /**
   *
   * @type {string}
   * @memberof GrowthGroup
   */
  rrule?: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroup
   */
  rruleString?: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroup
   */
  type: GrowthGroupTypeEnum;
  /**
   *
   * @type {string}
   * @memberof GrowthGroup
   */
  slug: string;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroup
   */
  canGuestInvite?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroup
   */
  recording?: boolean;
  /**
   *
   * @type {CmsMedia}
   * @memberof GrowthGroup
   */
  image?: CmsMedia;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroup
   */
  premium?: boolean;
  /**
   *
   * @type {number}
   * @memberof GrowthGroup
   */
  packageCostInCents?: number;
  /**
   *
   * @type {string}
   * @memberof GrowthGroup
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroup
   */
  groupType?: GrowthGroupGroupTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroup
   */
  free?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroup
   */
  isFree?: boolean;
}

export const GrowthGroupChannelEnum = {
  Audio: 'AUDIO',
  Video: 'VIDEO',
} as const;

export type GrowthGroupChannelEnum = (typeof GrowthGroupChannelEnum)[keyof typeof GrowthGroupChannelEnum];
export const GrowthGroupTypeEnum = {
  Private: 'PRIVATE',
  Public: 'PUBLIC',
} as const;

export type GrowthGroupTypeEnum = (typeof GrowthGroupTypeEnum)[keyof typeof GrowthGroupTypeEnum];
export const GrowthGroupGroupTypeEnum = {
  GrowthGroup: 'GROWTH_GROUP',
  Workshop: 'WORKSHOP',
  Keynote: 'KEYNOTE',
} as const;

export type GrowthGroupGroupTypeEnum = (typeof GrowthGroupGroupTypeEnum)[keyof typeof GrowthGroupGroupTypeEnum];
