import {createAsyncThunk} from '@reduxjs/toolkit';
import {message} from 'antd';
import {OnboardingStep} from './interfaces';
import {StrapiCollectionEnum} from '../../shared/api/strapi/enums';
import {sortBy} from 'lodash';
import {strapiApi} from '../../shared/api';

export const getOnboardingSteps = createAsyncThunk('onboarding/getOnboardingSteps', async () => {
  try {
    const result = await strapiApi.getCollection(StrapiCollectionEnum.ASSESSMENT_CATEGORIES, `?type=Monthly`);
    return sortBy(result, 'order') as OnboardingStep[];
  } catch (err: any) {
    message.error('Something went wrong!. Please try again later');
    throw err;
  }
});
