import {User} from '@growthday/ui-core/src/types/api';
import {IUser} from '@/shared/services_deprecated/model/user';

/**
 * Check if the user is a member of a team
 * @param user - The user object to check
 * @returns True if the user is a member of a team, otherwise false
 */
export function isTeamsUser(user: User | IUser | null | undefined) {
  return !!user?.isTeamUser;
}
