import {IUserChallenge} from '../interfaces';
import {UserChallengeStatusEnum} from '../enums';

const isChallengeFinished = (userChallenge?: IUserChallenge | null) =>
  Boolean(
    userChallenge && [UserChallengeStatusEnum.COMPLETED, UserChallengeStatusEnum.CLOSED].includes(userChallenge.status)
  );

export const isChallengeStatusFinished = (status?: UserChallengeStatusEnum | null) =>
  Boolean(status && [UserChallengeStatusEnum.COMPLETED, UserChallengeStatusEnum.CLOSED].includes(status));

export default isChallengeFinished;
