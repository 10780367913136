/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {PlatformInformation} from './platform-information';

/**
 *
 * @export
 * @interface UserRequest
 */
export interface UserRequest {
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  fullName: string;
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  password?: string;
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  countryCode?: string;
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  iso2?: string;
  /**
   *
   * @type {Set<string>}
   * @memberof UserRequest
   */
  roles?: Set<string>;
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  paymentMethodId?: string;
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  stripePriceId?: string;
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  paymentSystem?: UserRequestPaymentSystemEnum;
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  paypalSubscriptionId?: string;
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  referralCode?: string;
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  zipCode?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof UserRequest
   */
  orderBumpIds?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  ianaTimezone?: string;
  /**
   *
   * @type {number}
   * @memberof UserRequest
   */
  signupOfferId?: number;
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  signupOfferName?: string;
  /**
   *
   * @type {number}
   * @memberof UserRequest
   */
  signupChallengeId?: number;
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  signupChallengeName?: string;
  /**
   *
   * @type {number}
   * @memberof UserRequest
   */
  signupPlanId?: number;
  /**
   *
   * @type {number}
   * @memberof UserRequest
   */
  utcOffsetInMinutes?: number;
  /**
   *
   * @type {PlatformInformation}
   * @memberof UserRequest
   */
  platformInformation?: PlatformInformation;
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  subscriptionPlatform?: UserRequestSubscriptionPlatformEnum;
  /**
   *
   * @type {boolean}
   * @memberof UserRequest
   */
  automaticTaxEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  fromShareUrl?: string;
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  signupType?: UserRequestSignupTypeEnum;
  /**
   *
   * @type {number}
   * @memberof UserRequest
   */
  signupId?: number;
  /**
   *
   * @type {number}
   * @memberof UserRequest
   */
  webhookId?: number;
  /**
   *
   * @type {number}
   * @memberof UserRequest
   */
  campaignId?: number;
  /**
   *
   * @type {number}
   * @memberof UserRequest
   */
  signupMarketingCampaignId?: number;
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  signupGrowthGroupInstanceId?: string;
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  captchaToken?: string;
  /**
   *
   * @type {number}
   * @memberof UserRequest
   */
  subscriptionExpiry?: number;
  /**
   *
   * @type {boolean}
   * @memberof UserRequest
   */
  freeChallengeUser?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserRequest
   */
  giftBuyer?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserRequest
   */
  playerId?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserRequest
   */
  isGiftBuyer?: boolean;
}

export const UserRequestPaymentSystemEnum = {
  Card: 'CARD',
  Paypal: 'PAYPAL',
  Apple: 'APPLE',
} as const;

export type UserRequestPaymentSystemEnum =
  (typeof UserRequestPaymentSystemEnum)[keyof typeof UserRequestPaymentSystemEnum];
export const UserRequestSubscriptionPlatformEnum = {
  Web: 'WEB',
  Ios: 'IOS',
  Android: 'ANDROID',
} as const;

export type UserRequestSubscriptionPlatformEnum =
  (typeof UserRequestSubscriptionPlatformEnum)[keyof typeof UserRequestSubscriptionPlatformEnum];
export const UserRequestSignupTypeEnum = {
  Challenge: 'CHALLENGE',
  Offer: 'OFFER',
  LiveEvent: 'LIVE_EVENT',
  Plan: 'PLAN',
} as const;

export type UserRequestSignupTypeEnum = (typeof UserRequestSignupTypeEnum)[keyof typeof UserRequestSignupTypeEnum];
