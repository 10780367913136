import config from '@/config';
import {ConfigResponseFeaturesEnum} from '@growthday/ui-core/src/types/api';

export const featureEnvMap = new Map<ConfigResponseFeaturesEnum, string>([
  [ConfigResponseFeaturesEnum.GrowthGroup, `${config.env.groupLabel} Groups`],
  [ConfigResponseFeaturesEnum.Challenge, 'Challenges'],
  [ConfigResponseFeaturesEnum.Course, 'Courses'],
  [ConfigResponseFeaturesEnum.Journal, 'Journal'],
  [ConfigResponseFeaturesEnum.LifeScore, 'Life Scores'],
  [ConfigResponseFeaturesEnum.Plan, 'Plans'],
  [ConfigResponseFeaturesEnum.Event, 'Live Events'],
  [ConfigResponseFeaturesEnum.DailyFire, 'Daily Fire'],
  [ConfigResponseFeaturesEnum.Goal, 'Goals'],
]);
