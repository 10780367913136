import uniq from 'lodash/uniq';
import {offersApi, stripeApi} from '../../shared/api';
import {getAnalyticsActions} from '@/features/analytics/hooks/useAnalyticsActions';
import {ApiActionThunk, getApiActionCreator} from '../../shared/redux/util/api-action-builder';
import {AppState} from '../../shared/types';
import {OFFERS_ACTION_PREFIX} from './constants';
import {IPurchasedOffer} from './interfaces';
import {IOffersNonNested} from '@growthday/ui-core/src/types/offers';

export type OffersApiActionsType = {
  getPurchasedOffers: ApiActionThunk<IPurchasedOffer[]>;
  getOffers: ApiActionThunk<IOffersNonNested[]>;
  purchaseOffersViaStripe: ApiActionThunk<void, {offers: IOffersNonNested[]; isSCAFlowEnabled: boolean}>;
  renewPurchasedOfferViaStripe: ApiActionThunk<void, IPurchasedOffer>;
  cancelPurchasedOfferViaStripe: ApiActionThunk<void, IPurchasedOffer>;
};

export const offersApiActionCreator = getApiActionCreator<OffersApiActionsType>(OFFERS_ACTION_PREFIX);

offersApiActionCreator.create({
  getPurchasedOffers: offersApiActionCreator.thunk<IPurchasedOffer[]>(async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      return await offersApi.purchasedOffers();
    } catch (err: any) {
      throw err;
    }
  }),
  getOffers: offersApiActionCreator.thunk<IOffersNonNested[]>(async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      return await offersApi.offers();
    } catch (err: any) {
      throw err;
    }
  }),
  purchaseOffersViaStripe: offersApiActionCreator.thunk<
    void,
    {
      offers: IOffersNonNested[];
      isSCAFlowEnabled: boolean;
    }
  >(async ({offers, isSCAFlowEnabled}, thunkAPI) => {
    try {
      const offerIds = uniq(offers.map((offer) => offer.id));
      const state: AppState = thunkAPI.getState() as AppState;
      const triggerInfo = state.auth.triggerInfo;
      await stripeApi.purchaseStripeOffers(offerIds, triggerInfo, isSCAFlowEnabled);
    } catch (err: any) {
      getAnalyticsActions().track('Offer Purchase Failure', {
        Reason: err,
      });
      throw err;
    }
  }),
  renewPurchasedOfferViaStripe: offersApiActionCreator.thunk<void, IPurchasedOffer>(
    async (purchasedOffer: IPurchasedOffer) => {
      try {
        await stripeApi.renewStripePurchasedOffer(purchasedOffer.id);
      } catch (err: any) {
        getAnalyticsActions().track('Offer Renew Failure', {
          Reason: err,
        });
        throw err;
      }
    }
  ),
  cancelPurchasedOfferViaStripe: offersApiActionCreator.thunk<void, IPurchasedOffer>(
    async (purchasedOffer: IPurchasedOffer) => {
      try {
        await stripeApi.cancelStripePurchasedOffer(purchasedOffer.id);
        getAnalyticsActions().track('Offer Cancel Success', {
          'Offer Name': purchasedOffer.offer.title,
          'Offer Price': purchasedOffer.offer.price,
        });
      } catch (err: any) {
        getAnalyticsActions().track('Offer Cancel Failure', {
          Reason: err,
        });
        throw err;
      }
    }
  ),
});

const offersApiActions = offersApiActionCreator.apiActions;
export default offersApiActions;
