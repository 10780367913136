/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface CustomChallengeTask
 */
export interface CustomChallengeTask {
  /**
   *
   * @type {string}
   * @memberof CustomChallengeTask
   */
  title?: string;
  /**
   *
   * @type {number}
   * @memberof CustomChallengeTask
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CustomChallengeTask
   */
  type?: CustomChallengeTaskTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CustomChallengeTask
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof CustomChallengeTask
   */
  videoUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CustomChallengeTask
   */
  text?: string;
  /**
   *
   * @type {Array<object>}
   * @memberof CustomChallengeTask
   */
  media?: Array<object>;
  /**
   *
   * @type {boolean}
   * @memberof CustomChallengeTask
   */
  sharable?: boolean;
}

export const CustomChallengeTaskTypeEnum = {
  Video: 'Video',
  Audio: 'Audio',
  Image: 'Image',
  Journal: 'Journal',
  Goal: 'Goal',
  Plan: 'Plan',
} as const;

export type CustomChallengeTaskTypeEnum =
  (typeof CustomChallengeTaskTypeEnum)[keyof typeof CustomChallengeTaskTypeEnum];
