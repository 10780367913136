/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import {DynamicConfig, Statsig} from 'statsig-react';
import {DynamicConfigEnum} from '../types';

type DynamicConfigReturn<V extends any, T extends boolean> = T extends true ? DynamicConfig : V;
export function getDynamicConfig<V extends any = any, T extends boolean = false>(
  name: DynamicConfigEnum,
  returnRawResults?: T,
  ignoreOverrides?: boolean
): DynamicConfigReturn<V, T> {
  const config = Statsig.getConfig(name, ignoreOverrides);

  if (returnRawResults) {
    return config as DynamicConfigReturn<V, T>;
  }

  return config.value as DynamicConfigReturn<V, T>;
}
