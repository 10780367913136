import {createAsyncThunk} from '@reduxjs/toolkit';
import {message} from 'antd';
import {feedbackApi} from '../../shared/api/feedback/feedback.api';
import {Author} from './interface';

export const getAuthorsList = createAsyncThunk('feedback/getAuthorsList', async () => {
  try {
    const result = await feedbackApi.authors();
    return result as Author[];
  } catch (err: any) {
    message.error('Something went wrong!. Please try again later');
    throw err;
  }
});

export const reportIssue = createAsyncThunk('feedback/reportIssue', async () => {
  try {
    await feedbackApi.reportIssue();
  } catch (err: any) {}
});

export const reportFeedback = createAsyncThunk('feedback/reportFeedback', async () => {
  try {
    await feedbackApi.reportFeedback();
  } catch (err: any) {}
});
