import React, {FC} from 'react';
import styles from './Terms.module.less';
import GDTypography from '@/shared/components/ui/Typography/Typography';
import config from '@/config';

type Props = {
  type?: 'membership' | 'signup' | 'offer';
};

const Terms: FC<Props> = ({type}) => {
  return (
    <GDTypography block type="w100" className={styles.terms}>
      By{' '}
      {type === 'offer' ? 'purchasing this offer' : type === 'membership' ? 'starting your membership' : 'signing up'},
      you agree to our
      <div>
        <a href={config.env.settings.privacy.termsOfServiceLink} target="_blank" rel="noopener noreferrer">
          terms of service
        </a>{' '}
        and{' '}
        <a href={config.env.settings.privacy.privacyPolicyLink} target="_blank" rel="noopener noreferrer">
          privacy policy
        </a>
        .
      </div>
    </GDTypography>
  );
};

export default Terms;
