// Both present on web & mobile
import {defaultMemoize} from 'reselect'; // Re-exported from react-redux
import {shallowEqual} from 'react-redux';

const MaxCacheSize = 10;

export function memoize<T extends Parameters<typeof defaultMemoize>[0]>(callback: T) {
  return defaultMemoize(callback, {
    // @ts-ignore
    equalityCheck: shallowEqual,
    resultEqualityCheck: shallowEqual,
    maxSize: MaxCacheSize,
  });
}
