import achievementsApi from '../achievements.api';
import {useEffect, useMemo} from 'react';
import {
  EnumAchievementEventType,
  EnumAchievementJournalInterval,
  EnumAchievementLifescoreInterval,
  EnumAchievementMemberInterval,
  EnumAchievementsTypeCategory,
  IAchievementBadge,
  IAchievementCelebration,
  IAchievementCertificate,
  IAchievementChallenge,
  IAchievementCoin,
  IAchievementCourse,
  IAchievementEvent,
  IAchievementJournal,
  IAchievementJournalTier,
  IAchievementLifescore,
  IAchievementLifescoreTier,
  IAchievementMember,
  IAchievementMemberTier,
  IAchievementReferral,
  IAchievementReferralTier,
  IAchievements,
  ITier,
} from '../../../types/strapi';
import {
  AchievementInfo,
  AchievementRewardTypeInfo,
  AchievementsResponse,
  BadgeItem,
  CelebrationModalInfo,
  CertificateItem,
  GroupedAchievements,
  InProgressAchievement,
  InProgressAchievementTile,
} from '../../../types/achievements';
import {compact, get, keyBy, set, sortBy, uniq} from 'lodash';
import {imageUrlFromMedia} from '../../media-player/utils/image-url-from-media';

function capitalizeFirstLetter(str?: string) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : '';
}

export const {
  useGetStrapiAchievementsQuery,
  useGetAchievementsQuery,
  useGetInProgressAchievementsQuery,
  useInvalidateAllAchievementDataMutation,
} = achievementsApi;

type StrapiIterationRewards = {
  coin: IAchievementCoin | undefined | null;
  badge: IAchievementBadge | undefined | null;
  certificate: IAchievementCertificate | undefined | null;
  celebration: IAchievementCelebration | undefined | null;
};
type StrapiIterationCallback<ReturnValue = void> = (
  arg:
    | {
        entry:
          | (IAchievementChallenge & {__component: 'achievements.achievement-challenge'; tier: ITier | null})
          | (IAchievementJournal & {__component: 'achievements.achievement-journal'; tier: IAchievementJournalTier})
          | (IAchievementLifescore & {
              __component: 'achievements.achievement-lifescore';
              tier: IAchievementLifescoreTier;
            })
          | (IAchievementReferral & {__component: 'achievements.achievement-referral'; tier: IAchievementReferralTier});
        reward: Omit<StrapiIterationRewards, 'certificate'>;
        tier?: ITier;
      }
    | {
        entry:
          | (IAchievementEvent & {__component: 'achievements.achievement-event'})
          | (IAchievementCourse & {__component: 'achievements.achievement-course'})
          | (IAchievementMember & {__component: 'achievements.achievement-member'; tier: IAchievementMemberTier});
        reward: StrapiIterationRewards;
      }
) => ReturnValue;

function iterateOverStrapiAchievements(
  strapiAchievement: IAchievements,
  callback: StrapiIterationCallback,
  tierId?: number | null
) {
  for (const entry of strapiAchievement.type) {
    if (entry.__component === 'achievements.achievement-challenge') {
      if (!entry.challenge) {
        continue;
      }
      if (tierId === 0) {
        callback({
          entry: {
            ...entry,
            tier: null,
          },
          reward: {
            badge: {
              id: entry.challenge.id,
              title: entry.challenge.title,
              image: entry.challenge.completedBadge,
            },
            coin: undefined,
            celebration: undefined,
          },
        });
      } else {
        const tier = entry.challenge?.tiers?.find((t) => t.id === tierId);
        if (tier) {
          callback({
            entry: {
              ...entry,
              tier,
            },
            reward: {
              badge: {
                id: tier.id,
                title: tier.name,
                image: tier.completedBadge,
              },
              coin: {
                id: tier.id,
                value: tier.rewardAmount,
              },
              celebration: entry.celebration,
            },
          });
        }
      }
    } else if (
      entry.__component === 'achievements.achievement-journal' ||
      entry.__component === 'achievements.achievement-lifescore' ||
      entry.__component === 'achievements.achievement-referral'
    ) {
      const tier = entry?.tiers.find((t) => t.id === tierId);
      if (tier) {
        return callback({
          entry: {
            ...entry,
            tier,
          },
          reward: {
            badge: tier.badge,
            coin: tier.coin,
            celebration: tier.celebration,
          },
        });
      }
    } else if (
      entry.__component === 'achievements.achievement-course' ||
      entry.__component === 'achievements.achievement-event'
    ) {
      return callback({
        entry,
        reward: {
          badge: entry.badge,
          celebration: entry.celebration,
          certificate: entry.certificate,
          coin: entry.coin,
        },
      });
    } else if (entry.__component === 'achievements.achievement-member') {
      const tier = entry?.tiers.find((t) => t.id === tierId);
      if (tier) {
        return callback({
          entry: {
            ...entry,
            tier,
          },
          reward: {
            badge: tier.badge,
            celebration: tier.celebration,
            certificate: tier.certificate,
            coin: tier.coin,
          },
        });
      }
    }
  }
}

function getInProgressCaption(
  strapiAchievement: IAchievements['type'][number],
  currentCount: number,
  nextCount: number,
  nextTierName = ''
) {
  const actionsToNextLevel = Math.max(nextCount - currentCount, 0);

  switch (strapiAchievement.__component) {
    case 'achievements.achievement-journal':
      return `${actionsToNextLevel} day${actionsToNextLevel > 1 ? 's' : ''} more to achieve next level`;
    case 'achievements.achievement-lifescore':
      return `${actionsToNextLevel} ${
        strapiAchievement.interval === EnumAchievementLifescoreInterval.WEEKLY
          ? 'week'
          : strapiAchievement.interval === EnumAchievementLifescoreInterval.MONTHLY
          ? 'month'
          : strapiAchievement.interval === EnumAchievementLifescoreInterval.DAILY
          ? 'day'
          : ''
      }${actionsToNextLevel > 1 ? 's' : ''} more to achieve next level`;
    case 'achievements.achievement-challenge':
      return `Complete ${actionsToNextLevel} more ${!nextTierName ? 'remaining ' : ''}task${
        actionsToNextLevel > 1 ? 's' : ''
      } ${nextTierName ? 'to get ' + nextTierName : ''}`;
    case 'achievements.achievement-member':
      return `${actionsToNextLevel} ${
        strapiAchievement.interval === EnumAchievementMemberInterval.DAILY
          ? 'day'
          : strapiAchievement.interval === EnumAchievementMemberInterval.MONTHLY
          ? 'month'
          : 'week'
      }${actionsToNextLevel > 1 ? 's' : ''} to go`;
    case 'achievements.achievement-referral':
      return `Refer ${actionsToNextLevel} ${actionsToNextLevel > 1 ? 'people' : 'person'}`;
    case 'achievements.achievement-event':
      return strapiAchievement.type === EnumAchievementEventType.ALL_MONTH_EVENT
        ? `Attend ${actionsToNextLevel} live event${actionsToNextLevel > 1 ? 's' : ''} this month`
        : 'Attend your first live event';
    case 'achievements.achievement-course':
      return `Complete ${actionsToNextLevel} lesson${actionsToNextLevel > 1 ? 's' : ''}`;
  }
  return '';
}

export function useGetGroupedBadges(badges?: GroupedAchievements['BADGE']) {
  return useMemo(() => {
    type BadgeCollection = {
      [K in IAchievements['type'][number]['__component']]?: BadgeItem[];
    };
    const badgeCollection: BadgeCollection = {};
    if (!badges) {
      return Object.entries(badgeCollection);
    }
    // Count occurrence of each badge
    const countMap: Record<number, Record<number, Record<'value', number>>> = {};
    const completedMap: Record<number, Record<number, Record<'value', number>>> = {};
    const addToCollection = (
      key: IAchievements['type'][number]['__component'],
      badge: IAchievementBadge | null | undefined,
      title: string,
      strapiID: number,
      tierID: number,
      completedTimestamp: number,
      typeInfo: AchievementRewardTypeInfo,
      achievementInfo: AchievementInfo
    ) => {
      if (!badge) {
        return;
      }
      if (!(key in badgeCollection)) {
        badgeCollection[key] = [];
      }
      const propertyPath = `${strapiID}.${tierID}.value`;
      const value = get(countMap, propertyPath);
      if (value) {
        set(countMap, propertyPath, value + 1);
        completedTimestamp > get(completedMap, propertyPath) && set(completedMap, propertyPath, completedTimestamp);
      } else {
        set(countMap, propertyPath, 1);
        set(completedMap, propertyPath, completedTimestamp);
        badgeCollection[key]!.push({
          badge,
          count: countMap[strapiID][tierID],
          title,
          completedTimestamp: completedMap[strapiID][tierID],
          strapiAchievementId: strapiID,
          typeInfo,
          achievementInfo,
        });
      }
    };

    for (const k in badges) {
      const key = k as keyof AchievementsResponse;
      const b = badges[key]!;
      for (const badge of b) {
        if (!badge.strapi) {
          continue;
        }
        iterateOverStrapiAchievements(
          badge.strapi,
          ({entry, reward}) => {
            let title = '';
            let typeInfo: AchievementRewardTypeInfo | undefined;
            const rewardBadge = reward.badge;
            switch (entry.__component) {
              case 'achievements.achievement-challenge':
                title = 'Challenges';
                typeInfo = {
                  achievementType: entry.__component,
                  entityIdentifier: entry.challenge?.titleUid,
                };
                if (rewardBadge) {
                  rewardBadge.title = entry.challenge?.title;
                }
                break;
              case 'achievements.achievement-journal':
                title = 'Journal Streak';
                typeInfo = {
                  achievementType: entry.__component,
                  intervalInfo: {
                    type: entry.interval ?? EnumAchievementJournalInterval.DAILY,
                    value: entry.tier.value ?? 0,
                  },
                };
                break;
              case 'achievements.achievement-lifescore':
                title = 'Life Score Streak';
                typeInfo = {
                  achievementType: entry.__component,
                  intervalInfo: {
                    type: entry.interval ?? EnumAchievementLifescoreInterval.DAILY,
                    value: entry.tier.value ?? 0,
                  },
                };
                break;
              case 'achievements.achievement-course':
                title = 'Courses';
                typeInfo = {
                  achievementType: entry.__component,
                  entityIdentifier: entry.course?.titleUid,
                };
                break;
              case 'achievements.achievement-member':
                title = 'Membership Streak';
                typeInfo = {
                  achievementType: entry.__component,
                  intervalInfo: {
                    type: entry.interval ?? EnumAchievementMemberInterval.DAILY,
                    value: entry.tier.value ?? 0,
                  },
                };
                break;
              case 'achievements.achievement-referral':
                title = 'Referrals';
                title = 'Membership Streak';
                typeInfo = {
                  achievementType: entry.__component,
                };
                break;
              case 'achievements.achievement-event':
                if (
                  (key === 'allMonthLiveEventAchievements' && entry.type === EnumAchievementEventType.FIRST_EVENT) ||
                  (key === 'firstLiveEventAchievement' && entry.type === 'ALL_MONTH_EVENT')
                ) {
                  return;
                }
                title = 'Membership Streak';
                typeInfo = {
                  achievementType: entry.__component,
                };
                title = 'Live Events';
                break;
            }
            const achievementInfo = badge as AchievementInfo;
            typeInfo &&
              addToCollection(
                entry.__component,
                rewardBadge,
                title,
                badge.strapiId,
                entry.__component === 'achievements.achievement-course'
                  ? -1
                  : entry.__component === 'achievements.achievement-event'
                  ? entry.type === EnumAchievementEventType.FIRST_EVENT
                    ? -1
                    : 1
                  : badge.tierId,
                badge.updateTimestamp,
                typeInfo,
                achievementInfo
              );
          },
          badge.tierId
        );
      }
    }

    const keyOrder: Record<string, number> = {
      'achievements.achievement-challenge': 1,
      'achievements.achievement-lifescore': 3,
      'achievements.achievement-journal': 2,
    };

    const intervalOrder: Record<
      EnumAchievementJournalInterval | EnumAchievementMemberInterval | EnumAchievementLifescoreInterval | 'default',
      number
    > = {
      DAILY: 1,
      WEEKLY: 1000,
      MONTHLY: 100000,
      default: 100000000,
    };

    // Order by category
    const values = sortBy(Object.entries(badgeCollection), ([title]) => (title in keyOrder ? keyOrder[title] : 99));
    const returnValues: typeof values = [];

    for (const [k, entries] of values) {
      const key = k as keyof BadgeCollection;
      const title = entries[0]?.title ?? '';
      if (
        key === 'achievements.achievement-lifescore' ||
        key === 'achievements.achievement-journal' ||
        key === 'achievements.achievement-member'
      ) {
        returnValues.push([
          title,
          sortBy(entries, (entry) => {
            // type narrowing
            switch (entry.typeInfo.achievementType) {
              case 'achievements.achievement-lifescore':
                return intervalOrder[entry.typeInfo.intervalInfo.type] * (entry.typeInfo.intervalInfo.value ?? 0);
              case 'achievements.achievement-journal':
                return intervalOrder[entry.typeInfo.intervalInfo.type] * (entry.typeInfo.intervalInfo.value ?? 0);
              case 'achievements.achievement-member':
                return intervalOrder[entry.typeInfo.intervalInfo.type] * (entry.typeInfo.intervalInfo.value ?? 0);
              default:
                return intervalOrder.default;
            }
          }),
        ]);
      } else {
        returnValues.push([title, entries]);
      }
    }
    return returnValues;
  }, [badges]);
}

export function useCombinedAchievements(achievements: AchievementsResponse, strapiAchievements?: IAchievements[]) {
  return useMemo(() => {
    const joined: GroupedAchievements = {};
    const strapiMap = keyBy(strapiAchievements, (e) => e.id);
    for (const key in achievements) {
      const entry = achievements[key as keyof AchievementsResponse];
      if (Array.isArray(entry)) {
        for (const e of entry) {
          const targetKey = `${e.rewardType}.${key}`;
          const target = {...e, strapi: strapiMap[e.strapiId]};
          const current = get(joined, targetKey) as AchievementInfo[] | undefined;
          if (current) {
            current.push(target);
          } else {
            set(joined, targetKey, [target]);
          }
        }
      } else if (entry) {
        set(joined, `${entry.rewardType}.${key}`, [{...entry, strapi: strapiMap[entry.strapiId]}]);
      }
    }
    return joined;
  }, [achievements, strapiAchievements]);
}

export function useGetCertificates(certificates?: GroupedAchievements['CERTIFICATE']) {
  return useMemo(() => {
    const list: CertificateItem[] = [];
    if (!certificates) {
      return list;
    }
    for (const k in certificates) {
      const key = k as keyof AchievementsResponse;
      const entry = certificates[key];
      if (!entry) {
        continue;
      }
      for (const rewards of entry) {
        if (!rewards.strapi) {
          continue;
        }
        for (const reward of rewards.strapi.type) {
          if (
            reward.__component === 'achievements.achievement-course' ||
            (reward.__component === 'achievements.achievement-event' &&
              ((key === 'firstLiveEventAchievement' && reward.type === EnumAchievementEventType.FIRST_EVENT) ||
                (key === 'allMonthLiveEventAchievements' && reward.type === EnumAchievementEventType.ALL_MONTH_EVENT)))
          ) {
            reward.certificate &&
              list.push({
                ...reward.certificate,
                url: rewards.url,
                achievementId: rewards.id,
                strapiAchievementId: rewards.strapiId,
                completedTimestamp: rewards.updateTimestamp,
                typeInfo: {
                  achievementType: reward.__component,
                  entityIdentifier: 'course' in reward ? reward.course?.titleUid : undefined,
                },
              });
          }
        }
      }
    }
    return list;
  }, [certificates]);
}

export function useStrapiIdsFromAchievements(achievements: AchievementsResponse) {
  return useMemo(() => {
    const ids: number[] = [];
    for (const key in achievements) {
      const entry = achievements[key as keyof AchievementsResponse];
      if (Array.isArray(entry)) {
        ids.push(...entry.map((e) => e.strapiId));
      } else if (entry) {
        ids.push(entry.strapiId);
      }
    }

    return uniq(ids);
  }, [achievements]);
}

export function useGetCertificateForCourse(courseId: number, showError?: (message: string) => void) {
  const achievementsResponse = useGetAchievementsQuery();
  useEffect(() => {
    if (achievementsResponse.error) {
      showError?.('Could not fetch certificates. Please try again later');
    }
  }, [achievementsResponse.error, showError]);

  const certificateAchievement = useMemo(() => {
    if (!achievementsResponse.data) {
      return null;
    }
    return achievementsResponse.data.courseAchievements?.find(
      (entry) =>
        entry.extraInfo?.courseId &&
        !isNaN(entry.extraInfo.courseId) &&
        parseInt(entry.extraInfo.courseId, 10) === courseId
    );
  }, [achievementsResponse.data, courseId]);
  const achievementObject: AchievementsResponse = useMemo(() => {
    if (!certificateAchievement) {
      return {};
    }

    return {courseAchievements: [certificateAchievement]};
  }, [certificateAchievement]);
  const strapiId = useStrapiIdsFromAchievements(achievementObject);
  const strapiAchievementResponse = useGetStrapiAchievementsQuery(strapiId);
  useEffect(() => {
    if (strapiAchievementResponse.error) {
      showError?.('Could not fetch certificates. Please try again later');
    }
  }, [showError, strapiAchievementResponse.error]);

  return useMemo(() => {
    if (!certificateAchievement || !strapiAchievementResponse.data || !strapiAchievementResponse.data.length) {
      return null;
    }
    const entry = strapiAchievementResponse.data[0];
    for (const e of entry.type) {
      if (e.__component === 'achievements.achievement-course' && e.certificate) {
        return {
          certificate: e.certificate,
          url: certificateAchievement?.url,
          completedTimestamp: certificateAchievement.updateTimestamp,
          achievementId: certificateAchievement.id,
        };
      }
    }

    return null;
  }, [certificateAchievement, strapiAchievementResponse.data]);
}

function calculeteAllMonthEvent(achievement: InProgressAchievement) {
  if (achievement.extraInfo) {
    return achievement.achievementNextCount - achievement.extraInfo.eventIds.length;
  }
  return 0;
}

export function useGetInProgressAchievementTiles(
  achievements: InProgressAchievement[],
  strapiMap: Record<number, IAchievements>
) {
  return useMemo(() => {
    return compact(
      achievements.map((entry) => {
        const calculateCurrentCount =
          entry.trigger === 'ALL_MONTH_EVENT' ? calculeteAllMonthEvent(entry) : entry.achievementCurrentCount;
        const payload: InProgressAchievementTile = {
          strapiAchievementId: entry.strapiId,
          id: entry.id,
          title: '',
          coins: 0,
          percentage: (entry.achievementNextCount ? calculateCurrentCount / entry.achievementNextCount : 0) * 100,
          count: calculateCurrentCount,
          total: entry.achievementNextCount,
          caption: '',
          image: {url: '', alt: ''},
        };
        if (!(entry.strapiId in strapiMap)) {
          return null;
        }

        let dirty = true;
        iterateOverStrapiAchievements(
          strapiMap[entry.strapiId],
          ({entry: strapiAchievement, reward}) => {
            if (!reward.badge) {
              dirty = false;
              return;
            }
            if (reward.badge.image) {
              payload.image.url = imageUrlFromMedia(reward.badge.image) ?? '';
              payload.image.alt = reward.badge.image.alternativeText;
              payload.image.aspectRatio = (reward.badge.image.width ?? 1) / (reward.badge.image.height ?? 1);
            }

            if (reward.coin?.value) {
              payload.coins = reward.coin.value;
            }

            switch (strapiAchievement.__component) {
              case 'achievements.achievement-journal':
                payload.title = `${capitalizeFirstLetter(strapiAchievement.interval)} Journal ${capitalizeFirstLetter(
                  strapiAchievement.type
                )}`;
                payload.caption = getInProgressCaption(
                  strapiAchievement,
                  entry.achievementCurrentCount,
                  entry.achievementNextCount
                );
                payload.typeInfo = {
                  achievementType: strapiAchievement.__component,
                  intervalInfo: {
                    type: strapiAchievement.interval ?? EnumAchievementJournalInterval.DAILY,
                    value: strapiAchievement.tier.value ?? 0,
                  },
                };
                break;
              case 'achievements.achievement-lifescore': {
                const interval = capitalizeFirstLetter(strapiAchievement.interval);
                payload.title = `${interval} Life score ${capitalizeFirstLetter(strapiAchievement.type)}`;
                payload.caption = getInProgressCaption(
                  strapiAchievement,
                  entry.achievementCurrentCount,
                  entry.achievementNextCount
                );
                payload.typeInfo = {
                  achievementType: strapiAchievement.__component,
                  intervalInfo: {
                    type: strapiAchievement.interval ?? EnumAchievementLifescoreInterval.DAILY,
                    value: strapiAchievement.tier.value ?? 0,
                  },
                };
                break;
              }
              case 'achievements.achievement-challenge': {
                payload.title = strapiAchievement.challenge?.title ?? '';
                if (entry.achievementType !== EnumAchievementsTypeCategory.CHALLENGE) {
                  dirty = false;
                  return;
                }
                const tier = strapiAchievement.challenge?.tiers.find((t) => t.id === entry.tierId);
                payload.caption = getInProgressCaption(
                  strapiAchievement,
                  entry.achievementCurrentCount,
                  entry.achievementNextCount,
                  tier?.name
                );
                payload.typeInfo = {
                  achievementType: strapiAchievement.__component,
                  entityIdentifier: strapiAchievement.challenge?.titleUid,
                };
                break;
              }
              case 'achievements.achievement-member':
                payload.title = `Membership ${capitalizeFirstLetter(strapiAchievement.type)}`;
                payload.caption = getInProgressCaption(
                  strapiAchievement,
                  entry.achievementCurrentCount,
                  entry.achievementNextCount
                );
                payload.typeInfo = {
                  achievementType: strapiAchievement.__component,
                  intervalInfo: {
                    type: strapiAchievement.interval ?? EnumAchievementMemberInterval.DAILY,
                    value: strapiAchievement.tier.value ?? 0,
                  },
                };
                break;
              case 'achievements.achievement-referral':
                payload.title = `Referrals`;
                payload.caption = getInProgressCaption(
                  strapiAchievement,
                  entry.achievementCurrentCount,
                  entry.achievementNextCount
                );
                payload.typeInfo = {
                  achievementType: strapiAchievement.__component,
                };
                break;
              case 'achievements.achievement-event':
                payload.typeInfo = {
                  achievementType: strapiAchievement.__component,
                };
                if (strapiAchievement.type !== EnumAchievementEventType.ALL_MONTH_EVENT) {
                  payload.title = `First Live Event`;
                  payload.caption = getInProgressCaption(strapiAchievement, 0, 1);
                } else {
                  payload.title = `Live Event Streak`;
                  payload.caption = getInProgressCaption(
                    strapiAchievement,
                    entry.achievementCurrentCount,
                    entry.achievementNextCount
                  );
                }
                break;
              case 'achievements.achievement-course':
                if (!reward.badge) {
                  dirty = false;
                  return;
                }
                payload.typeInfo = {
                  achievementType: strapiAchievement.__component,
                  entityIdentifier: strapiAchievement.course?.titleUid,
                };
                payload.title = strapiAchievement.course?.title ?? '';
                payload.caption = getInProgressCaption(
                  strapiAchievement,
                  entry.achievementCurrentCount,
                  entry.achievementNextCount
                );
                break;
            }
          },
          'tierId' in entry ? entry.tierId : undefined
        );
        return dirty ? payload : null;
      })
    );
  }, [achievements, strapiMap]);
}

export function useGetCelebrationInfoFromAchievement(strapiAchievement?: IAchievements, tierId?: number) {
  const celebrationInfo: CelebrationModalInfo = {};

  if (!strapiAchievement) {
    return celebrationInfo;
  }

  iterateOverStrapiAchievements(
    strapiAchievement,
    ({entry, reward}) => {
      celebrationInfo.celebration = reward.celebration;
      if ('certificate' in reward) {
        celebrationInfo.certificate = reward.certificate;
      }
      celebrationInfo.coinCount = reward.coin?.value;
      celebrationInfo.badge = reward.badge;

      if (
        entry.__component === 'achievements.achievement-course' ||
        entry.__component === 'achievements.achievement-event' ||
        entry.__component === 'achievements.achievement-challenge'
      ) {
        return;
      }
      if (!entry.tiers || !tierId) {
        return;
      }
      const tiers = sortBy(entry.tiers, (e) => e.value);
      const currentIndex = tiers.findIndex((t) => t.id === tierId);
      if (currentIndex === -1) {
        return;
      }

      if (currentIndex === tiers.length - 1 && tiers[currentIndex]?.badge) {
        const currentCount = tiers[currentIndex].value ?? 0;
        celebrationInfo.nextAchievement = {
          badge: tiers[currentIndex].badge!,
          currentCount,
          nextCount: currentCount,
          caption: 'All tasks completed',
        };
        return;
      }

      const nextTier = entry.tiers[currentIndex + 1];
      if (nextTier.badge) {
        const currentCount = tiers[currentIndex].value ?? 0;
        const nextCount = nextTier.value ?? 0;
        celebrationInfo.nextAchievement = {
          badge: nextTier.badge,
          currentCount,
          nextCount,
          caption: getInProgressCaption(entry, currentCount, nextCount),
        };
      }
    },
    tierId
  );
  return celebrationInfo;
}
