import {IMarketingCampaigns} from './marketing-campaigns';
import {IOnboardModal} from './onboard-modal';
import {ISubscriptionPlans} from './subscription-plans';

/**
 * Model definition for Onboard-flow
 */
export interface IOnboardFlow {
  id: number;
  name: string;
  modals?: IOnboardModal[];
  type: EnumOnboardFlowType;
  campaign?: IMarketingCampaigns;
  plan?: ISubscriptionPlans;
  skipFlow: boolean;
  isActive: boolean;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumOnboardFlowType {
  DEFAULT = 'DEFAULT',
  OFFER = 'OFFER',
  PLAN = 'PLAN',
  CAMPAIGN = 'CAMPAIGN',
  GROWTH_GROUP = 'GROWTH_GROUP',
  FREE_CHALLENGE = 'FREE_CHALLENGE',
  INTENTS_ONLY = 'INTENTS_ONLY',
}
