export enum HMSRoleType {
  HOST = 'host',
  CO_HOST = 'co-host',
  SPEAKER = 'speaker',
  ATTENDEE = 'attendee',
  WAITING_ROOM = 'waiting-room',
}

export enum HMSPlatform {
  WEB = 'web',
  MOBILE = 'mobile',
}

// HMS peer metadata has been a bottleneck for us in scaling.
// We've removed the older implementation where it would be an object
// of key pair value and changed it to array.
// First value of array be the timestamp (in seconds)
// Second value of array be the platform, 0 - Web, 1 - Mobile
// Third value of array be the raised hand, 0 - No, 1 - Yes

// All the other fields are removed and not used either in frontend, backend or mobile

// export interface HMSPeerMetadata {
//   platform?: HMSPlatform;
//   isHandRaised?: boolean;
//   profilePicture?: string;
//   uuid: string;
//   instanceId: string;
//   groupId: string;
//   joinId: string;
//   joinedAt?: number;
// }

export type HMSPeerMetadata = [
  number /* Joined At (in seconds) */,
  number /* Platform, 0 - Web, 1 - Mobile */,
  number /* Raised Hands, 0 - No, 1 - Yes */,
];

export type HMSPeerMetadataParsed = {
  joinedAt?: number /* joinedAt (in seconds) */;
  platform?: HMSPlatform /* Platform, 0 - Web, 1 - Mobile */;
  isHandRaised?: boolean /* Raised Hands, 0 - No, 1 - Yes */;
};

export interface HMSSessionMetadata {
  // contains peer.customerUserIds
  pinned?: string[];
  hidden?: string[];
  hasAdmittedOnce?: boolean;
  admitAll?: boolean;
}
