import {combineReducers} from '@reduxjs/toolkit';
import undoable from 'redux-undo';
import authReducer from '../../features/auth/auth.slice';
import challengesReducer from '../../features/challenges/challenges.slice';
import dailyJournalReducer from '../../features/daily-journal/daily-journal.slice';
import dashboardReducer from '../../features/dashboard/dashboard.slice';
import downloadReducer from '../../features/download/download.slice';
import feedbackReducer from '../../features/feedback/feedback.slice';
import learnSlice from '../../features/learn/slice';
import lifeScoresReducer from '../../features/life-scores/lifescores.slice';
import mediaPlayerReducer from '../../features/media-player_old/mediaPlayer.slice';
import offersReducer from '../../features/offers/offers.slice';
import onboardingReducer from '../../features/onboarding/onboarding.slice';
import projectsReducer from '../../features/projects/projects.slice';
import shellReducer from '../../features/shell/shell.slice';
import sidebarCalendarReducer from '../../features/sidebar-calendar/sidebarCalendar.slice';
import myProfileReducer from '../../features/auth/routes/Profile/routes/MyProfile/myProfile.slice';
import notesReducer from '../../features/notes/notes.slice';
import campaignReducer from '../../features/campaign/campaign.slice';
import {reducer as coreReducer} from '@growthday/ui-core/src/redux/reducer';
import giftSubscriptionsReducer from '../../features/gift-subscriptions/gift-subscription.slice';
import AchievementsReducer from '../../features/auth/routes/Profile/routes/MyProfile/components/Achievements/achievements.slice';

const reducer = combineReducers({
  challenges: undoable(challengesReducer, {limit: 1}),
  learn: learnSlice,
  auth: authReducer,
  lifeScores: lifeScoresReducer,
  projects: projectsReducer,
  onboarding: onboardingReducer,
  dashboard: dashboardReducer,
  sidebarCalendar: sidebarCalendarReducer,
  feedback: feedbackReducer,
  shell: shellReducer,
  mediaPlayer: mediaPlayerReducer,
  offers: offersReducer,
  notes: notesReducer,
  dailyJournal: dailyJournalReducer,
  download: downloadReducer,
  myProfile: myProfileReducer,
  giftSubscriptions: giftSubscriptionsReducer,
  campaign: campaignReducer,
  achievements: AchievementsReducer,
  ...coreReducer,
});

export default reducer;
