const values: Map<string, string> = new Map<string, string>();
const maxEntries: number = 50;

export const useLruCache = () => {
  const get = (key: string): string => {
    const hasKey = values.has(key);
    if (hasKey) {
      // peek the entry, re-insert for LRU strategy
      const entry = values.get(key);
      if (entry) {
        values.delete(key);
        values.set(key, entry);
      }
    }
    return '';
  };

  const put = (key: string, value: string) => {
    if (values.size >= maxEntries) {
      // least-recently used cache eviction strategy
      const keyToDelete = values.keys().next().value;

      values.delete(keyToDelete);
    }

    values.set(key, value);
  };

  return {
    get,
    put,
  };
};
