import {EnvironmentsEnum, getCurrentEnvironmentKey} from '../external';

export type CodeEnvironment = 'Dev' | 'UAT' | 'Production';

export default function checkEnvironment(): CodeEnvironment {
  const key = getCurrentEnvironmentKey();
  switch (key) {
    case EnvironmentsEnum.DEV:
      return 'Dev';
    case EnvironmentsEnum.UAT:
      return 'UAT';
    case EnvironmentsEnum.PROD:
      return 'Production';
    default:
      return 'Dev';
  }
}
