import useAuthState from '../../auth/hooks/useAuthState';
import {LicenseEnum} from '@growthday/ui-core/src/features/license/types';
import pickBy from 'lodash/pickBy';
import uniq from 'lodash/uniq';
import {useCallback, useMemo} from 'react';
import {IStrapiCourse} from '../../../shared/services_deprecated/model/strapi/course';
import {StrapiCourseContentTagsEnum} from '../../learn/enums';
import useOffersState from '../../offers/hooks/useOffersState';
import {isSubscriptionActive} from '../utils/subscription';
import {SubscriptionLevelsEnum} from '../../auth/enums';
import {IStrapiLiveEvent} from '../../../shared/services_deprecated/model/strapi/live-event';
import {ICourses, IEvents, ILicenses, ISubscriptionPlans} from '@growthday/ui-core/src/types/strapi';
import {IStrapiChallenge} from '../../challenges/interfaces';
import {UserSignupType} from '../../../shared/services_deprecated/model/user';
import {isOfferNotExpired} from '../../offers/utils/filterOffers';
import isEvergreenUser from '../../auth/utils/isEvergreenUser';
import coerceArray from '../../../shared/util/coerceArray';
import {GrowthGroupInstance} from '@growthday/ui-core/src/types/api';
import useSubscriptionPlans from '@/features/auth/routes/Profile/hooks/useSubscriptionPlans';
import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';
import {usePartiallyPopulatedCollection} from '@growthday/ui-core/src/apis/usePartiallyPopulatedCollection';
import {CardCourse} from '@growthday/ui-core/src/features/learn/hooks/useGetCardCourses';

const allLicenses: LicenseEnum[] = Object.values(LicenseEnum);

export const mapLicenses = (licenses?: Record<LicenseEnum, boolean | null> | null | ILicenses) =>
  Object.keys(
    pickBy(licenses, (value, key) => value === true && allLicenses.includes(key as LicenseEnum))
  ) as LicenseEnum[];

export const checkLicenses = (
  licensesToCheck: LicenseEnum | LicenseEnum[],
  existingLicenses: LicenseEnum | LicenseEnum[]
) => coerceArray(licensesToCheck).some((license) => coerceArray(existingLicenses).includes(license));

const useLicense = (propLicenses: LicenseEnum | LicenseEnum[] = []) => {
  const {activeSubscription} = useSubscriptionPlans();
  const {organization} = useAuthState();
  const {user} = useUser();
  const {purchasedOffers, offers} = useOffersState();
  const {data: activeSubscriptionWithFreeOffers} = usePartiallyPopulatedCollection<
    Pick<ISubscriptionPlans, 'id' | 'freeOffers'>
  >('subscription-plans', activeSubscription?.id ?? 0, ['freeOffers'], {staleTime: Infinity});

  const activeSubscriptionFreeOffers = activeSubscriptionWithFreeOffers?.freeOffers;
  const activeOffers = useMemo(() => {
    const freeOfferIds = activeSubscriptionFreeOffers?.map((offer) => offer.id) ?? [];
    const freeOffers = offers.filter((offer) => freeOfferIds.includes(offer.id));
    return [...purchasedOffers.filter(isOfferNotExpired).map((purchasedOffer) => purchasedOffer.offer), ...freeOffers];
  }, [activeSubscriptionFreeOffers, offers, purchasedOffers]);

  const purchasedLicenses: LicenseEnum[] = useMemo(() => {
    return uniq([
      ...mapLicenses(activeSubscription?.licenses),
      ...(activeOffers?.reduce<LicenseEnum[]>((res, curr) => [...res, ...mapLicenses(curr?.licenses)], []) ?? []),
    ]);
  }, [activeSubscription, activeOffers]);

  const purchasedCourses = useMemo(
    () =>
      activeOffers?.reduce<number[]>((res, curr) => [...res, ...(curr?.courses?.map(({id}) => id) ?? [])], []) ?? [],
    [activeOffers]
  );

  const canAccess = useCallback(
    (licenses: LicenseEnum | LicenseEnum[] = [], skipEvergreenCheck?: boolean) => {
      return user
        ? (!skipEvergreenCheck && isEvergreenUser(user)) ||
            checkLicenses(coerceArray(licenses), [...purchasedLicenses, ...(coerceArray(propLicenses) ?? [])])
        : true;
    },
    [purchasedLicenses, propLicenses, user]
  );

  const hasActiveSubscription = useCallback(() => isSubscriptionActive(user), [user]);

  const canAccessEvents = useCallback(
    (event?: IStrapiLiveEvent | IEvents) => {
      if (event?.isFree) {
        return true;
      }
      // if (
      //   event &&
      //   event?.isFree &&
      //   user?.signupLiveEventId &&
      //   event.id === user.signupLiveEventId &&
      //   user.level === SubscriptionLevelsEnum.LIVE_EVENT
      // ) {
      //   return true;
      // }
      return canAccess(LicenseEnum.EVENT_PAST_ACCESS);
    },
    // [canAccess, user?.level, user?.signupLiveEventId]
    [canAccess]
  );

  // TODO - Get Clarity - What is the correct access for a keynote? How to pass access.minimumLevelWeight?
  const canAccessKeynotes = useCallback(
    (groupInstance: GrowthGroupInstance | null | undefined) => {
      if (!groupInstance) {
        return false;
      }

      const access = groupInstance.liveAccess;
      if (!access) return canAccess(LicenseEnum.WORKSHOP_LIVE_ACCESS);
      if (access.isFree) {
        return true;
      }

      return !!(
        access.minSubscriptionLevel &&
        activeSubscription?.levelWeight &&
        activeSubscription.levelWeight >= access.minSubscriptionLevel
      );
    },
    [activeSubscription?.levelWeight, canAccess]
  );

  const canAccessAudioCourses = useCallback(() => canAccess(LicenseEnum.AUDIO_COURSE_ALL_ACCESS), [canAccess]);
  const canAccessVideoCourses = useCallback(() => canAccess(LicenseEnum.VIDEO_COURSE_ALL_ACCESS), [canAccess]);
  const canAccessLifescores = useCallback(() => canAccess(LicenseEnum.LIFESCORE_ALL_ACCESS), [canAccess]);
  const canAccessChallenges = useCallback(
    (challenge?: IStrapiChallenge) => {
      if (challenge?.isFree) {
        return true;
      }
      if (
        challenge &&
        user?.signupType === UserSignupType.CHALLENGE &&
        challenge.id === user.signupId &&
        user?.level === SubscriptionLevelsEnum.CHALLENGE
      ) {
        return true;
      }
      return canAccess(LicenseEnum.CHALLENGE_ALL_ACCESS);
    },
    [user?.signupType, user?.signupId, canAccess, user?.level]
  );
  const canAccessPlans = useCallback(
    () => canAccess([LicenseEnum.PLAN_ALL_ACCESS, LicenseEnum.PLAN_LIMITED_ACCESS]),
    [canAccess]
  );
  const hasLimitedAccessToPlans = useCallback(
    () => canAccess([LicenseEnum.PLAN_LIMITED_ACCESS]) && !canAccess([LicenseEnum.PLAN_ALL_ACCESS]),
    [canAccess]
  );
  const canAccessGoals = useCallback(
    () => canAccess([LicenseEnum.GOAL_ALL_ACCESS, LicenseEnum.GOAL_LIMITED_ACCESS]),
    [canAccess]
  );
  const canAccessJournals = useCallback(
    () => canAccess([LicenseEnum.JOURNAL_ALL_ACCESS, LicenseEnum.JOURNAL_LIMITED_ACCESS]),
    [canAccess]
  );
  const canAccessCourse = useCallback(
    (course: IStrapiCourse | ICourses | CardCourse) => {
      if (isEvergreenUser(user)) {
        return true;
      }
      if (purchasedCourses.includes(course.id)) {
        return true;
      }
      if (course.isFree && (isSubscriptionActive(user) || activeOffers.length)) {
        return true;
      }
      const courseOffer = offers?.find((offer) => offer.courses?.some(({id}) => id === course.id));
      if (courseOffer) {
        return activeOffers.some((purchasedOffer) => purchasedOffer.id === courseOffer.id);
      }
      const isAudio = course.content_tags?.some((tag) => tag.tagId === StrapiCourseContentTagsEnum.AUDIO);
      return isAudio ? canAccessAudioCourses() : canAccessVideoCourses();
    },
    [user, activeOffers, offers, canAccessVideoCourses, canAccessAudioCourses, purchasedCourses]
  );
  const canAccessAnyCourse = useCallback(
    () =>
      canAccessAudioCourses() ||
      canAccessVideoCourses() ||
      purchasedCourses.length > 0 ||
      hasActiveSubscription() ||
      isEvergreenUser(user),
    [canAccessAudioCourses, canAccessVideoCourses, purchasedCourses.length, hasActiveSubscription, user]
  );
  const canAccessGrowthGroups = useCallback(
    () =>
      Boolean(
        user?.enterpriseUser
          ? organization?.growthGroupHostingEnabled
          : canAccess(LicenseEnum.GROWTHGROUP_ALL_ACCESS, true)
      ),
    [canAccess, organization?.growthGroupHostingEnabled, user?.enterpriseUser]
  );
  const canShowGrowthGroups = useCallback(
    () => Boolean(!user?.enterpriseUser || organization?.growthGroupEnabled),
    [organization?.growthGroupEnabled, user?.enterpriseUser]
  );
  const canCreateGrowthGroups = useCallback(
    () =>
      Boolean(!user?.enterpriseUser || (organization?.growthGroupEnabled && organization.growthGroupHostingEnabled)),
    [organization?.growthGroupEnabled, organization?.growthGroupHostingEnabled, user?.enterpriseUser]
  );
  const canUpsellForGrowthGroups = useCallback(() => Boolean(!user?.enterpriseUser), [user?.enterpriseUser]);
  const canShowCommunity = useCallback(
    () => Boolean(!user?.enterpriseUser || organization?.communityEnabled),
    [user?.enterpriseUser, organization?.communityEnabled]
  );

  /**
   * A memoized callback function that checks whether the user can access the community.
   * @returns {boolean} Returns true if the user can access the community, otherwise false.
   */
  const canAccessCommunity = useCallback(
    () => canShowCommunity() && canAccess(LicenseEnum.COMMUNITY_ALL_ACCESS),
    [canShowCommunity, canAccess]
  );
  const canAccessWorkshops = useCallback(
    (group: GrowthGroupInstance) => Boolean(group.isFree || canAccess(LicenseEnum.WORKSHOP_LIVE_ACCESS)),
    [canAccess]
  );

  /**
   * A memoized callback function that checks whether the user has access to the AI Bot (Ask-GG, Brendon AI).
   * @returns {boolean} Returns true if the user has access to the AI Bot, otherwise false.
   */
  const canAccessAIBot = useCallback(() => canAccess(LicenseEnum.AI_BOT_ACCESS), [canAccess]);

  return {
    hasActiveSubscription,
    purchasedLicenses,
    purchasedCourses,
    purchasedOffers,
    activeOffers,
    canAccess,
    canAccessCourse,
    canAccessEvents,
    canAccessKeynotes,
    canAccessAudioCourses,
    canAccessVideoCourses,
    canAccessLifescores,
    canAccessChallenges,
    canAccessPlans,
    hasLimitedAccessToPlans,
    canAccessGoals,
    canAccessJournals,
    canAccessAnyCourse,
    canAccessGrowthGroups,
    canShowGrowthGroups,
    canCreateGrowthGroups,
    canUpsellForGrowthGroups,
    canAccessWorkshops,
    canShowCommunity,
    canAccessCommunity,
    canAccessAIBot,
  };
};

export default useLicense;
