import {IFile} from './file';
import {INotification} from './notification';
import {IWorkshopHosts} from './workshop-hosts';

/**
 * Model definition for workshop
 */
export interface IWorkshops {
  id: number;
  title: string;
  type: EnumWorkshopsType;
  channel: EnumWorkshopsChannel;
  agenda: string;
  date: string;
  time: string;
  duration: number;
  slug: string;
  banner: IFile;
  host?: IWorkshopHosts;
  isFree?: boolean;
  rsvpNotification?: INotification;
  reminderNotification?: INotification;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumWorkshopsType {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export enum EnumWorkshopsChannel {
  VIDEO = 'VIDEO',
}
