import {HMSRoleType} from '../../../types/growth-groups/hms';
import {compact} from 'lodash';
import {GrowthGroupInstance, Participant} from '../../../types/api';

export const sortedRoles: HMSRoleType[] = [
  HMSRoleType.HOST,
  HMSRoleType.CO_HOST,
  HMSRoleType.SPEAKER,
  HMSRoleType.ATTENDEE,
  HMSRoleType.WAITING_ROOM,
];

export function isRole(
  roleName?: HMSRoleType | string | null,
  ...roles: Array<HMSRoleType | string | undefined | null>
) {
  if (roleName) {
    return compact(roles).some((role) => roleName.startsWith(role));
  }
  return false;
}

export function isHLSViewer(groupInstance?: GrowthGroupInstance, peerRole?: HMSRoleType | string | null) {
  return Boolean(groupInstance?.hlsGroup && isRole(peerRole, HMSRoleType.ATTENDEE));
}

export function isRoleChanged(
  oldRole?: HMSRoleType | string | null,
  newRole?: HMSRoleType | string | null,
  ...roles: Array<HMSRoleType | string | null | undefined>
) {
  return roles.some((roleToCheck) => !isRole(oldRole, roleToCheck) && isRole(newRole, roleToCheck));
}

export function getRoleCount(
  participants: Participant[] | null | undefined,
  role: HMSRoleType | string | undefined | null
) {
  return (role && participants?.filter((p) => isRole(p.role, role as HMSRoleType)))?.length || 0;
}
