/**
 * Model definition for action
 */
export interface IAction {
  id: number;
  label?: string;
  action?: EnumActionAction;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumActionAction {
  SIGNUP = 'SIGNUP',
  PAYMENT = 'PAYMENT',
  UPSELL_PURCHASE = 'UPSELL_PURCHASE',
}
