/**
 * Model definition for Button
 */
export interface IButton {
  id: number;
  label: string;
  onClick: EnumButtonOnClick;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumButtonOnClick {
  nextStep = 'nextStep',
  endFlow = 'endFlow',
}
