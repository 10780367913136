import {setRef} from '@/utils/set-ref';
import {Component, RefObject} from 'react';
import {findDOMNode} from 'react-dom';

export type RootRefProps<T = HTMLElement> = {
  rootRef?: ((instance: T | null) => void) | RefObject<T>;
  disabled?: boolean;
};

class RootRef<T = HTMLElement> extends Component<RootRefProps<T>> {
  ref: T | null = null;

  get isEnabled() {
    return this.props.rootRef && !this.props.disabled;
  }

  componentDidMount() {
    if (this.isEnabled) {
      this.ref = findDOMNode(this) as unknown as T | null;
      setRef(this.props.rootRef, this.ref);
    }
  }

  componentDidUpdate(prevProps: RootRefProps<T>) {
    if (this.isEnabled) {
      const ref = findDOMNode(this) as unknown as T | null;

      if (prevProps.rootRef !== this.props.rootRef || this.ref !== ref) {
        if (prevProps.rootRef !== this.props.rootRef) {
          setRef(prevProps.rootRef, null);
        }

        this.ref = ref;
        setRef(this.props.rootRef, this.ref);
      }
    }
  }

  componentWillUnmount() {
    if (this.isEnabled) {
      this.ref = null;
      setRef(this.props.rootRef, null);
    }
  }

  render() {
    return this.props.children;
  }
}

export default RootRef;
