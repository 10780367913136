import {ICarouselSlide} from './carousel-slide';

/**
 * Model definition for hero-carousel
 */
export interface IHeroCarousel {
  id: number;
  type?: EnumHeroCarouselType;
  slides: ICarouselSlide[];
  level?: EnumHeroCarouselLevel;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumHeroCarouselType {
  DASHBOARD = 'DASHBOARD',
  COURSE = 'COURSE',
}

export enum EnumHeroCarouselLevel {
  BASIC = 'BASIC',
  STARTER = 'STARTER',
  GROWTH = 'GROWTH',
  MASTERY = 'MASTERY',
  TRIAL = 'TRIAL',
  NONE = 'NONE',
  OFFER = 'OFFER',
  CHALLENGE = 'CHALLENGE',
  ENTERPRISE = 'ENTERPRISE',
  ENTERPRISE_STARTER = 'ENTERPRISE_STARTER',
  ALL_ACCESS = 'ALL_ACCESS',
}
