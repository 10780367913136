/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface CmsMarketingWebhook
 */
export interface CmsMarketingWebhook {
  /**
   *
   * @type {string}
   * @memberof CmsMarketingWebhook
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof CmsMarketingWebhook
   */
  trigger?: CmsMarketingWebhookTriggerEnum;
  /**
   *
   * @type {string}
   * @memberof CmsMarketingWebhook
   */
  type?: CmsMarketingWebhookTypeEnum;
}

export const CmsMarketingWebhookTriggerEnum = {
  Signup: 'SIGNUP',
  Trial: 'TRIAL',
  Payment: 'PAYMENT',
  PaymentMonthly: 'PAYMENT_MONTHLY',
  Cancellation: 'CANCELLATION',
  TrialCancellation: 'TRIAL_CANCELLATION',
  SignupLoad: 'SIGNUP_LOAD',
  PaymentLoad: 'PAYMENT_LOAD',
} as const;

export type CmsMarketingWebhookTriggerEnum =
  (typeof CmsMarketingWebhookTriggerEnum)[keyof typeof CmsMarketingWebhookTriggerEnum];
export const CmsMarketingWebhookTypeEnum = {
  Kajabi: 'KAJABI',
  Proof: 'PROOF',
} as const;

export type CmsMarketingWebhookTypeEnum =
  (typeof CmsMarketingWebhookTypeEnum)[keyof typeof CmsMarketingWebhookTypeEnum];
