import React, {FC} from 'react';
import {Input} from 'antd';
import {PasswordProps} from 'antd/es/input';
import './style.less';
import useBreakpointSize from '../../../../hooks/useBreakpointSize';

export type GDPasswordProps = {} & PasswordProps;

const GDPassword: FC<GDPasswordProps> = ({children, ...restProps}) => {
  const breakpointSize = useBreakpointSize();
  return (
    <Input.Password size={breakpointSize} {...restProps}>
      {children}
    </Input.Password>
  );
};

export default GDPassword;
