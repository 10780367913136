import {useQuery, UseQueryOptions} from 'react-query';
import {TeamAssessment} from '../../../types/api';
import {axiosGrowthDay} from '../../../utils/api';

export type GetTeamAssessmentsListPayload = {
  organizationId: string;
  startTime: string;
  endTime: string;
};

export const GetTeamAssessmentsListKey = (organizationId: string, startTime: string, endTime: string) => {
  return ['SELF_ASSESSMENT_TEAM_LIST_BY_DATE_RANGE', organizationId, startTime, endTime];
};

export default function useGetTeamAssessmentsList(
  payload: GetTeamAssessmentsListPayload,
  options: Omit<
    UseQueryOptions<TeamAssessment[], unknown, TeamAssessment[], ReturnType<typeof GetTeamAssessmentsListKey>>,
    'queryKey' | 'queryFn'
  > = {}
) {
  return useQuery(
    GetTeamAssessmentsListKey(payload.organizationId, payload.startTime, payload.endTime),
    queryFnGetTeamAssessmentsList(payload),
    {
      enabled: Boolean(payload.organizationId),
      ...options,
    }
  );
}

export const queryFnGetTeamAssessmentsList = (payload: GetTeamAssessmentsListPayload) => async () => {
  const config = {
    headers: {
      'organization-id': payload.organizationId,
    },
  };
  return await axiosGrowthDay
    .get<any>(`/assessments/teamAssessment/${payload.startTime}/${payload.endTime}`, config)
    .then((res: any) => {
      return res.data;
    });
};
