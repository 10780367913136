// @ts-nocheck
import isEnvironment from '../config/util/isEnvironment';
import {EnvironmentsEnum} from '../config/enums';
import config from '../config';
import {useEffect, useLayoutEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {IUser} from '../shared/services_deprecated/model/user';
import {getPagePathParams} from '../shared/util/campaignParams';
import {AnalyticsActions} from '../features/analytics/analyticsActions';
import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';

let isTrackingEnabled = false;
let initialized = false;
const verifyInit = () => initialized;

function initGtag(gTagId: string, user?: IUser) {
  initialized = typeof window !== 'undefined' && !!window.gtag;

  if (gTagId) {
    if (!initialized) {
      /* eslint-disable */
      (function (i, s, o, g, r, a, m) {
        // @ts-ignore
        i[r] =
          // @ts-ignore
          i[r] ||
          function () {
            // @ts-ignore
            (i['dataLayer'] = i['dataLayer'] || []).push(arguments);
          };
        // @ts-ignore
        (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
        // @ts-ignore
        a.async = 1;
        // @ts-ignore
        a.src = g;
        // @ts-ignore
        m.parentNode.insertBefore(a, m);
      })(window, document, 'script', `https://www.googletagmanager.com/gtag/js?id=${gTagId}`, 'gtag');
      /* eslint-enable */
    }

    gtag('js', new Date());
    gtag('config', gTagId, {
      ...getPagePathParams(),
      send_page_view: false,
      ...(user
        ? {
            user_id: user.uuid,
            user_email: user.email,
          }
        : {}),
    });

    initialized = true;
  }
}

export const gTagActions: AnalyticsActions = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  page: function (category: string, name: string, props?: any): void {
    if (!verifyInit()) {
      return;
    }

    setTimeout(() => {
      try {
        gtag('event', 'page_view', getPagePathParams());
      } catch (e: any) {}
    });
  },
  track: function (name: string, props?: any): void {
    //track(eventName: Gtag.EventNames | string, eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams) {
    if (!verifyInit()) {
      return;
    }
    setTimeout(() => {
      try {
        if (isEnvironment(EnvironmentsEnum.DEV, EnvironmentsEnum.UAT)) {
          console.log('gtag event', {name, ...props, ...getPagePathParams()});
        }
        gtag('event', name, {...props, ...getPagePathParams()});
      } catch (e: any) {}
    });
  },
  identify: function (): void {
    console.warn('gTagActions.identify, Function not implemented.');
  },
  alias: function (): void {
    console.warn('gTagActions.alias, Function not implemented.');
  },
  group: function (): void {
    console.warn('gTagActions.group, Function not implemented.');
  },
  reset: function (): void {
    console.warn('gTagActions.reset, Function not implemented.');
  },
  traits: function (): void {
    console.warn('gTagActions.traits, Function not implemented.');
  },
};

const useGTag = () => {
  isTrackingEnabled = false;

  const {pathname} = useLocation();
  const {user} = useUser();
  useEffect(() => {
    setTimeout(() => {
      if (isTrackingEnabled) {
        gTagActions.page('', '');
      }
    });
  }, [pathname]);
  useLayoutEffect(() => {
    if (isTrackingEnabled) {
      initGtag(config.env.gTagId!, user);
      gTagActions.page('', '');
    }
  }, [user]);
};

export default useGTag;
