/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface OrganizationUserInstanceFilter
 */
export interface OrganizationUserInstanceFilter {
  /**
   *
   * @type {boolean}
   * @memberof OrganizationUserInstanceFilter
   */
  invitationPending?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationUserInstanceFilter
   */
  paymentPending?: boolean;
  /**
   *
   * @type {string}
   * @memberof OrganizationUserInstanceFilter
   */
  query?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationUserInstanceFilter
   */
  departmentId?: string;
  /**
   *
   * @type {number}
   * @memberof OrganizationUserInstanceFilter
   */
  roleId?: number;
  /**
   *
   * @type {string}
   * @memberof OrganizationUserInstanceFilter
   */
  sortBy?: OrganizationUserInstanceFilterSortByEnum;
  /**
   *
   * @type {string}
   * @memberof OrganizationUserInstanceFilter
   */
  order?: OrganizationUserInstanceFilterOrderEnum;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationUserInstanceFilter
   */
  deactivated?: boolean;
}

export const OrganizationUserInstanceFilterSortByEnum = {
  CreatedOn: 'createdOn',
  Name: 'name',
  Email: 'email',
  RoleName: 'roleName',
} as const;

export type OrganizationUserInstanceFilterSortByEnum =
  (typeof OrganizationUserInstanceFilterSortByEnum)[keyof typeof OrganizationUserInstanceFilterSortByEnum];
export const OrganizationUserInstanceFilterOrderEnum = {
  Asc: 'asc',
  Desc: 'desc',
} as const;

export type OrganizationUserInstanceFilterOrderEnum =
  (typeof OrganizationUserInstanceFilterOrderEnum)[keyof typeof OrganizationUserInstanceFilterOrderEnum];
