import plansApi from '../plans.api';
import {FlattenedPlans, MutationEndpointOptions, PlanWithoutNesting, ResolveDueDateInput} from '../../../types/plans';
import {FetchBaseQueryError} from '@reduxjs/toolkit/query';
import dayjs, {Dayjs} from 'dayjs';
import {endpoints} from '../endpoints';
import {formatDueAndCompletedDate} from '../utils/formatDueAndCompletedDate';

const getUpdateURL = (id: string, records: FlattenedPlans['records']) =>
  records[id].parentID
    ? records[records[id].parentID].parentID
      ? `${endpoints.subtasks}/${records[records[id].parentID].parentID}/${id}`
      : `${endpoints.tasks}/${records[id].parentID}/${id}`
    : `${endpoints.plans}/${id}`;

function isBehind(id: string, records: FlattenedPlans['records'], currentDueDate: Dayjs) {
  const dueDate = records[id].dueDateTimestamp;
  return !!dueDate && dayjs(dueDate).startOf('day') < currentDueDate;
}

function isAhead(id: string, records: FlattenedPlans['records'], currentDueDate: Dayjs) {
  const dueDate = records[id].dueDateTimestamp;
  return !!dueDate && dayjs(dueDate).startOf('day') > currentDueDate;
}

// Return value is not useful
export const resolveDueDateConflictOptions: MutationEndpointOptions<ResolveDueDateInput, unknown> = {
  async queryFn(arg, api, extra, fetchWithBaseQuery) {
    const plansDataStore = plansApi.endpoints.getPlans.select()(api.getState() as any)!;
    if (plansDataStore.error) return {error: plansDataStore.error as FetchBaseQueryError};
    const {records} = plansDataStore.data!;
    const {dueDateTimestamp, id} = arg;
    const currentDueDate = dayjs(dueDateTimestamp).startOf('day');
    const body = {
      dueDateTimestamp: formatDueAndCompletedDate(currentDueDate),
    };
    const update = (_id: string) =>
      fetchWithBaseQuery({
        url: getUpdateURL(_id, records),
        body: {
          ...body,
          title: records[_id].title,
        },
        method: 'PATCH',
      });

    const parentIDs: PlanWithoutNesting['id'][] = [];
    let currentID = id;

    while (records[currentID].parentID) {
      currentID = records[currentID].parentID;
      parentIDs.push(currentID);
    }
    // Update in reverse order. Plan then task then subtask
    // If a parent's due date is behind, update it to given due date.
    for (let i = parentIDs.length - 1; i >= 0; i--) {
      if (isBehind(parentIDs[i], records, currentDueDate)) {
        const {error} = await update(parentIDs[i]);
        if (error) return {error};
      }
    }

    // If a child is ahead, pull it back
    for (const task of records[id].tasks) {
      if (isAhead(task, records, currentDueDate)) {
        const {error} = await update(task);
        if (error) return {error};
      }
      for (const subtask of records[task].tasks)
        if (isAhead(subtask, records, currentDueDate)) {
          const {error} = await update(subtask);
          if (error) return {error};
        }
    }
    return update(id);
  },
  async onQueryStarted(arg, api) {
    await api.queryFulfilled;
    api.dispatch(
      plansApi.util.updateQueryData('getPlans', undefined, (draft) => {
        const currentDueDate = dayjs(arg.dueDateTimestamp).startOf('day');
        const {records} = draft;
        let currentID = arg.id;
        while (records[currentID].parentID) {
          currentID = records[currentID].parentID;
          if (isBehind(currentID, records, currentDueDate))
            records[currentID].dueDateTimestamp = formatDueAndCompletedDate(currentDueDate);
        }
        for (const child of records[arg.id].tasks) {
          if (isAhead(child, records, currentDueDate))
            records[child].dueDateTimestamp = formatDueAndCompletedDate(currentDueDate);
          for (const grandChild of records[child].tasks)
            if (isAhead(grandChild, records, currentDueDate))
              records[grandChild].dueDateTimestamp = formatDueAndCompletedDate(currentDueDate);
        }

        draft.records[arg.id].dueDateTimestamp = formatDueAndCompletedDate(currentDueDate);
      })
    );
  },
};
