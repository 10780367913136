import {DueDateConflictError, FlattenedPlans, PlanTypesSingular, PlanWithoutNesting} from '../../../types/plans';
import dayjs from 'dayjs';

export function detectDueDateConflict(
  id: string | {parentID: string},
  plansMap: FlattenedPlans,
  newDueDate: string,
  type: PlanTypesSingular
): DueDateConflictError | null {
  // Initially, all are consistent
  // Conflicts - subtask is ahead of task
  // Conflicts - subtask is ahead of plan
  // Conflicts - task is ahead of plan
  // Conflicts - Plan is behind task
  // Conflicts - Task is behind subtasks
  const currentDueDate = dayjs(newDueDate).startOf('day');
  const parentIDs: PlanWithoutNesting['id'][] = [];
  let currentID = typeof id === 'string' ? id : id.parentID;
  if (typeof id !== 'string') {
    parentIDs.push(currentID);
  }
  while (plansMap.records[currentID].parentID) {
    currentID = plansMap.records[currentID].parentID;
    parentIDs.push(currentID);
  }

  // Start from topmost level and head down
  // Subtask - plan, Subtask - task
  // or task - plan
  for (let i = parentIDs.length - 1; i >= 0; i--) {
    const parentDueDate = plansMap.records[parentIDs[i]].dueDateTimestamp;
    if (parentDueDate && dayjs(parentDueDate).startOf('day') < currentDueDate) {
      const target = i === parentIDs.length - 1 ? 'plan' : 'task';
      return {
        ahead: true,
        conflictSource: type,
        conflictTarget: target,
      };
    }
  }
  if (typeof id !== 'string') return null;
  for (const child of plansMap.records[id].tasks) {
    const childDueDate = plansMap.records[child].dueDateTimestamp;
    if (!childDueDate) continue;
    const childDueDateValue = dayjs(childDueDate).startOf('day');
    if (childDueDateValue && childDueDateValue > currentDueDate) {
      const target = type === 'plan' ? 'task' : 'subtask';
      return {
        ahead: false,
        conflictSource: type,
        conflictTarget: target,
      };
    }
  }

  return null;
}
