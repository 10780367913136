/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface RecordingUrl
 */
export interface RecordingUrl {
  /**
   *
   * @type {string}
   * @memberof RecordingUrl
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof RecordingUrl
   */
  quality?: RecordingUrlQualityEnum;
  /**
   *
   * @type {number}
   * @memberof RecordingUrl
   */
  size?: number;
}

export const RecordingUrlQualityEnum = {
  _1080P: 'QUALITY_1080P',
  _720P: 'QUALITY_720P',
  _540P: 'QUALITY_540P',
  _480P: 'QUALITY_480P',
  _360P: 'QUALITY_360P',
  _240P: 'QUALITY_240P',
} as const;

export type RecordingUrlQualityEnum = (typeof RecordingUrlQualityEnum)[keyof typeof RecordingUrlQualityEnum];
