import {getInitialFeatureGateValues} from '@growthday/ui-core/src/features/feature-gate/utils/initial-feature-gate-values';
import Loading from '@/shared/components/Loading';
import {PropsWithChildren, useState} from 'react';
import {StatsigProvider} from 'statsig-react';
import {datadogRum} from '@datadog/browser-rum';

const options = {
  gateEvaluationCallback: (key: string, value: boolean) => {
    datadogRum.addFeatureFlagEvaluation(key, value);
  },
};

const initialValues = getInitialFeatureGateValues('web');

export function FeatureGateProvider({children}: PropsWithChildren<{}>) {
  const [user, setUser] = useState(initialValues.user);
  if (initialValues.sdkKey) {
    return (
      <StatsigProvider
        {...initialValues}
        initializingComponent={<Loading />}
        user={user}
        setUser={setUser}
        // @ts-ignore
        // Function `gateEvaluationCallback` was added in Statsig’s SDK v4.34.0,
        // but it hasn't been ported to `StatsigOptions` yet.
        options={{...initialValues.options, ...options}}
        children={children}
        mountKey={'feature-gate-mount-key'}
      />
    );
  }
  return <>{children}</>;
}
