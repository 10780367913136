import {RefObject} from 'react';

export function setRef<T>(
  ref: RefObject<T> | ((instance: T | null) => void) | null | undefined,
  value: T | null
): void {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    (ref as any).current = value;
  }
}
