import {endpoints} from '../endpoints';
import {FetchBaseQueryError} from '@reduxjs/toolkit/query';
import {
  CreatePlanInput,
  CreateTaskInput,
  FlattenedPlans,
  MutationEndpointOptions,
  Plan,
  PlanTypesSingular,
} from '../../../types/plans';
import {compact, flatMap, keyBy, mapValues, sortBy} from 'lodash';
import plansApi from '../plans.api';
import {formatDueAndCompletedDate} from '../utils/formatDueAndCompletedDate';
import {detectDueDateConflict} from '../utils/detectDueDateConflict';

function makeCreateOptions(
  endpointForChange: string,
  type: Extract<PlanTypesSingular, 'plan'>
): MutationEndpointOptions<CreatePlanInput, Plan>;
function makeCreateOptions(
  endpointForChange: string,
  type: Exclude<PlanTypesSingular, 'plan'>
): MutationEndpointOptions<CreateTaskInput, Plan>;
function makeCreateOptions(
  endpointForChange: string,
  type: PlanTypesSingular
): MutationEndpointOptions<CreatePlanInput & {parentID?: string}, Plan> {
  return {
    async queryFn(arg, api, extraOptionas, fetchWithBaseQuery) {
      const plansData = plansApi.endpoints.getPlans.select()(api.getState() as any)!;
      if (plansData.error) return {error: plansData.error as FetchBaseQueryError};
      const plansMap: FlattenedPlans = plansData.data!;
      let url = endpointForChange;
      if (arg.parentID) {
        // Subtask - parent ID is a task, attach plan ID then task ID
        if (plansMap.records[arg.parentID]?.parentID) url += `/${plansMap.records[arg.parentID].parentID}`;
        url += `/${arg.parentID}`;
      }
      if (arg.dueDateTimestamp && arg.parentID) {
        arg.dueDateTimestamp = formatDueAndCompletedDate(arg.dueDateTimestamp);
        const error = detectDueDateConflict({parentID: arg.parentID}, plansMap, arg.dueDateTimestamp, type);
        if (error)
          return {
            error: {
              status: 'CUSTOM_ERROR',
              data: error,
              error: 'Due date conflicts found',
            } as FetchBaseQueryError,
          };
      }
      const body = {...arg};
      delete body.parentID;
      const createResponse = await fetchWithBaseQuery({
        url,
        method: 'POST',
        body,
      });
      if (createResponse.error) return {error: createResponse.error};
      const createdPlan = createResponse.data as Plan;

      // On creating plan, update the order too
      if (type === 'plan') {
        const orderedPlans = sortBy(
          plansMap?.plansArr?.tasks?.map((id) => ({
            id,
            order: plansMap.records[id].order,
          })),
          (p) => p.order && parseInt(p.order, 10)
        );
        const payload = [
          {id: createdPlan.id, order: 0},
          ...orderedPlans.map((p, index) => ({
            ...p,
            order: index + 1,
          })),
        ];

        const updateOrderResponse = await fetchWithBaseQuery({
          url: endpoints.updatePlanOrder,
          method: 'POST',
          body: mapValues(
            keyBy(payload, (p) => p.id),
            (o) => o.order
          ),
        });
        if (updateOrderResponse.error) return {error: updateOrderResponse.error};
      }
      return {data: createdPlan};
    },
    async onQueryStarted(arg, api) {
      // const id = uuid();
      // const plan: PlanWithoutNesting = {
      //   id,
      //   parentID: arg.parentID ?? '',
      //   createTimestamp: Date.now(),
      //   ...arg,
      //   tasks: [],
      //   order: '0',
      // };
      // const patch = api.dispatch(
      //   plansApi.util.updateQueryData('getPlans', undefined, (draft) => {
      //     draft.records[id] = plan;
      //     if (!arg.parentID) {
      //       const sortedPlans = sortBy(
      //         draft.plansArr.tasks,
      //         (_id) => draft.records[_id].order,
      //       );
      //       sortedPlans.unshift(id);
      //       draft.plansArr.tasks = sortedPlans;
      //       draft.plansArr.tasks.forEach((_id, index.tsx) => {
      //         draft.records[_id].order = index.tsx.toString();
      //       });
      //     } else {
      //       draft.records[id].order =
      //         draft.records[arg.parentID].tasks.length.toString(10);
      //       draft.records[arg.parentID].tasks.push(id);
      //     }
      //   }),
      // );

      try {
        const {data} = await api.queryFulfilled;
        api.dispatch(
          plansApi.util.updateQueryData('getPlans', undefined, (draft) => {
            let _plan: Plan;
            if (type === 'plan') _plan = data;
            else if (type === 'task') _plan = data.tasks!.find((t) => !(t.id in draft.records))!;
            else _plan = compact(flatMap(data.tasks!, (entry) => entry.tasks)).find((t) => !(t.id in draft.records))!;

            draft.records[_plan.id] = {
              ..._plan,
              tasks: _plan.tasks?.map((t) => t.id) ?? [],
              parentID: arg.parentID ?? '',
            };
            if (type === 'plan') {
              draft.records[_plan.id].order = '0';
              const orderedPlans = sortBy(
                draft.plansArr.tasks.map((id) => ({
                  id,
                  order: draft.records[id].order,
                })),
                (p) => p.order && parseInt(p.order, 10)
              );
              orderedPlans.forEach(({id}, index) => (draft.records[id].order = `${index + 1}`));
            }
            const arr = arg.parentID ? draft.records[arg.parentID].tasks : draft.plansArr.tasks;
            arr.push(_plan.id);
            // const index.tsx = arr.findIndex((i) => i === id);
            // if (index.tsx !== -1) arr[index.tsx] = _plan.id;
            // delete draft.records[id];
          })
        );
      } catch {
        // patch.undo();
      }
    },
  };
}

export const createPlanOptions = makeCreateOptions(endpoints.plans, 'plan');
export const createTaskOptions = makeCreateOptions(endpoints.tasks, 'task');
export const createSubTaskOptions = makeCreateOptions(endpoints.tasks, 'subtask');
