import {createAsyncThunk} from '@reduxjs/toolkit';
import {message} from 'antd';
import {IUserCourse, IUserCourseProgress} from '@growthday/ui-core/src/types/course';
import {dashboardApi, GetTourDataInput, strapiApi, tourApi, userCourseApi} from '../../shared/api';
import {lifescoreApi} from '../../shared/api/lifescore/lifescore.api';
import {StrapiCollectionEnum} from '../../shared/api/strapi/enums';
import {ScoreType} from '../../shared/components/life-scores/interfaces';
import {IStrapiCourse} from '../../shared/services_deprecated/model/strapi/course';
import {IStrapiCourseLesson} from '../../shared/services_deprecated/model/strapi/course-lesson';
import isLessonComplete from '../learn/routes/Course/utils/is-lesson-complete';
import {ILifeScoresRecommendation} from '../life-scores/interfaces';
import {DailyFireProgress, DashboardRecommendation} from './interfaces';
import {IDailyFires} from '@growthday/ui-core/src/types/strapi';
import {AssessmentRecommendation} from '@growthday/ui-core/src/types/api';

export const getDashboardData = createAsyncThunk('dashboard/getDashboardData', async () => {
  try {
    return await dashboardApi.getDashboardData();
  } catch (err: any) {
    message.error('Something went wrong!. Please try again later');
    throw err;
  }
});

export const getDashboardTourData = createAsyncThunk(
  'dashboard/getDashboardTourData',
  async (input: GetTourDataInput) => {
    try {
      return await tourApi.getTourData(input);
    } catch (err: any) {
      return undefined;
    }
  }
);

export const getRecommendations = createAsyncThunk('dashboard/getRecommendations', async (type?: ScoreType) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const result = await lifescoreApi.getLifeScoreRecommendation(type ?? ScoreType.MONTHLY);
    return result as DashboardRecommendation[];
  } catch (err: any) {
    throw err;
  }
});

export const getDailyFire = createAsyncThunk<IDailyFires>('dashboard/getDailyFire', async () => {
  // eslint-disable-next-line no-useless-catch
  try {
    return await dashboardApi.getDailyFire();
  } catch (err: any) {
    // displayApiError(err, 'Failed fetching daily fire');
    throw err;
  }
});

export const getLastWatchedCourseLesson = createAsyncThunk<IStrapiCourseLesson | null>(
  'dashboard/getLastWatchedCourseLesson',
  async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      const findLastPlayedLesson = (lessons?: IUserCourseProgress[]) => {
        return lessons?.reduce((prev, curr) => {
          if (
            !curr.complete &&
            curr.percentage > 0 &&
            !isLessonComplete(curr.percentage) &&
            curr.updatedTimestamp! > prev.updatedTimestamp!
          ) {
            return curr;
          }
          return prev;
        }, lessons[0]);
      };

      const courseRecommendations = await userCourseApi.userCourseGetRecommendations();

      if (!courseRecommendations || courseRecommendations.length === 0) {
        return null;
      }

      const userCourseAndLesson: {
        courseLesson?: IUserCourseProgress;
        course?: IUserCourse;
      } = courseRecommendations?.reduce(
        (prev, curr) => {
          if (!prev.course) {
            return {
              courseLesson: findLastPlayedLesson(curr.lessonProgress),
              course: curr,
            };
          }
          const lastLessonFromCurrent = findLastPlayedLesson(curr.lessonProgress);
          if (
            !curr.complete &&
            (!prev.courseLesson?.updatedTimestamp ||
              (lastLessonFromCurrent?.updatedTimestamp ?? 0) > prev.courseLesson?.updatedTimestamp)
          ) {
            return {
              courseLesson: lastLessonFromCurrent,
              course: curr,
            };
          }
          return prev;
        },
        {
          courseLesson: courseRecommendations[0].lessonProgress?.length
            ? courseRecommendations[0].lessonProgress[0]
            : undefined,
          course: courseRecommendations[0],
        }
      );

      if (userCourseAndLesson.course && userCourseAndLesson.courseLesson) {
        const [strapiCourse] = await strapiApi.getCollection<IStrapiCourse[]>(
          StrapiCollectionEnum.COURSES,
          `?id=${userCourseAndLesson.course.courseId}`
        );
        const [strapiCourseLesson] = await strapiApi.getCollection<IStrapiCourseLesson[]>(
          StrapiCollectionEnum.COURSE_LESSONS,
          `?id=${userCourseAndLesson.courseLesson.lessonId}`
        );
        strapiCourse.userCourse = userCourseAndLesson.course;
        strapiCourseLesson.course = strapiCourse;
        return strapiCourseLesson;
      }

      return null;
    } catch (err: any) {
      throw err;
    }
  }
);

export const getRecommendation = createAsyncThunk<
  ILifeScoresRecommendation | AssessmentRecommendation,
  AssessmentRecommendation[]
>('dashboard/getRecommendation', async (teamRecommendations: AssessmentRecommendation[]) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const [recommendation] = await lifescoreApi.getLifeScoreRecommendation();
    if (recommendation?.lessonId) {
      const [lesson] = await strapiApi.getCollection<IStrapiCourseLesson[]>(
        StrapiCollectionEnum.COURSE_LESSONS,
        `?id=${recommendation.lessonId}`
      );

      recommendation.lesson = lesson;
    }
    if (teamRecommendations.length > 0 && !recommendation) {
      return teamRecommendations[0];
    }

    return recommendation;
  } catch (err: any) {
    throw err;
  }
});

export const markRecommendationAsInteracted = createAsyncThunk<void, number>(
  'dashboard/markRecommendationAsInteracted',
  (recommendationId) => {
    // eslint-disable-next-line no-useless-catch
    try {
      return lifescoreApi.markAsInteracted(ScoreType.DAILY, recommendationId);
    } catch (err: any) {
      throw err;
    }
  }
);

export const getDailyFireProgress = createAsyncThunk<DailyFireProgress, string>(
  'dashboard/getDailyFireProgress',
  (url: string) => {
    // eslint-disable-next-line no-useless-catch
    try {
      return dashboardApi.getDailyFireProgress(url);
    } catch (err: any) {
      throw err;
    }
  }
);

export const updateDailyFireProgress = createAsyncThunk<DailyFireProgress, {url: string; progress: number}>(
  'dashboard/updateDailyFireProgress',
  ({url, progress}: {url: string; progress: number}) => {
    // eslint-disable-next-line no-useless-catch
    try {
      return dashboardApi.updateDailyFireProgress(url, progress);
    } catch (err: any) {
      throw err;
    }
  }
);
