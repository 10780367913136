/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface KeynoteWorkshopNotificationRequest
 */
export interface KeynoteWorkshopNotificationRequest {
  /**
   *
   * @type {string}
   * @memberof KeynoteWorkshopNotificationRequest
   */
  groupType?: KeynoteWorkshopNotificationRequestGroupTypeEnum;
  /**
   *
   * @type {string}
   * @memberof KeynoteWorkshopNotificationRequest
   */
  notificationType?: KeynoteWorkshopNotificationRequestNotificationTypeEnum;
  /**
   *
   * @type {number}
   * @memberof KeynoteWorkshopNotificationRequest
   */
  strapiId?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof KeynoteWorkshopNotificationRequest
   */
  userEmail?: Array<string>;
}

export const KeynoteWorkshopNotificationRequestGroupTypeEnum = {
  GrowthGroup: 'GROWTH_GROUP',
  Workshop: 'WORKSHOP',
  Keynote: 'KEYNOTE',
} as const;

export type KeynoteWorkshopNotificationRequestGroupTypeEnum =
  (typeof KeynoteWorkshopNotificationRequestGroupTypeEnum)[keyof typeof KeynoteWorkshopNotificationRequestGroupTypeEnum];
export const KeynoteWorkshopNotificationRequestNotificationTypeEnum = {
  Rsvp: 'RSVP',
  Reminder: 'REMINDER',
} as const;

export type KeynoteWorkshopNotificationRequestNotificationTypeEnum =
  (typeof KeynoteWorkshopNotificationRequestNotificationTypeEnum)[keyof typeof KeynoteWorkshopNotificationRequestNotificationTypeEnum];
