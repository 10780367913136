import {MenuItemIcon} from '@/features/shell/components/Menu/MenuItemIcon';
import {ElementType, FC, MouseEvent, useCallback, useMemo} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Typography} from 'antd';
import classNames from 'classnames';
import styles from './MenuItem.module.less';
import {Tooltip} from '@/components/tooltip';

export type MenuItemProps = {
  to?: string;
  id?: string;
  title: string;
  Icon?: ElementType;
  icon?: string;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  expanded?: boolean;
  wrapTitle?: boolean;
  disabled?: boolean;
  activePath?: string;
};

const MenuItem: FC<MenuItemProps> = ({
  disabled,
  id,
  expanded,
  wrapTitle,
  title,
  Icon,
  icon,
  to,
  activePath,
  onClick,
}) => {
  const {pathname} = useLocation();
  const fullPath = useMemo(() => (pathname.startsWith('/') ? pathname.slice(1) : pathname), [pathname]);
  const toFiltered = useMemo(() => {
    if (activePath) {
      return activePath.startsWith('/') ? activePath.slice(1) : activePath;
    }
    return to?.startsWith('/') ? to.slice(1) : to ?? 'no-op';
  }, [activePath, to]);

  const isActive = useMemo(() => {
    if (disabled) {
      return false;
    }
    // Check for home
    if (id === 'menu-item-home') {
      return fullPath === '';
    }

    // Check special condition for GG item
    if (id === 'menu-item-learn' && fullPath.startsWith('group')) {
      return true;
    }

    if (id === 'menu-item-groups' && fullPath.startsWith('group')) {
      return true;
    }

    // Check if url starts with href
    return fullPath.startsWith(toFiltered);
  }, [disabled, fullPath, id, toFiltered]);

  const Component = (typeof to === 'string' ? Link : Typography.Link) as ElementType;

  const renderIcon = useCallback(() => {
    if (icon) {
      return <MenuItemIcon isActive={isActive} url={icon} />;
    }
    if (Icon) {
      return <Icon isActive={isActive} />;
    }
    return null;
  }, [icon, Icon, isActive]);

  return (
    <Tooltip disabled={expanded} title={title} placement="right">
      <Component
        id={id}
        {...(typeof to === 'string' && !disabled ? {to: to.startsWith('/') ? to : `/${to}`} : {})}
        onClick={onClick}
        className={classNames('ant-typography', 'menu-item', styles.root, {
          [styles.active]: isActive,
          [styles.wrapTitle]: wrapTitle,
          [styles.disabled]: disabled,
          active: isActive,
          disabled: disabled,
        })}
      >
        {renderIcon()}
        {expanded && <Typography.Text className={classNames(styles.title)}>{title}</Typography.Text>}
      </Component>
    </Tooltip>
  );
};

export default MenuItem;
