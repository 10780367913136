import {createApi} from '@reduxjs/toolkit/query/react';
import {getCompletedPlans, getPlanOptions, getPlansOptions} from './handlers/fetch';
import {deletePlanOptions, deleteSubTaskOptions, deleteTaskOptions} from './handlers/delete';
import {createPlanOptions, createSubTaskOptions, createTaskOptions} from './handlers/create';
import {updatePlanOptions, updateSubTaskOptions, updateTaskOptions} from './handlers/update';
import {resolveDueDateConflictOptions} from './handlers/resolveDueDateConflict';
import {bulkUpsertSubtaskOptions} from './handlers/bulkUpsertSubtask';
import {completeAndRecover} from './handlers/completeAndRecover';
import {
  BulkSubtaskUpsertInput,
  CompleteAndRecoverInput,
  CompleteAndRecoverResponse,
  CompletedPlansInput,
  CompletedPlansResponse,
  CreatePlanInput,
  CreateSubTaskInput,
  CreateTaskInput,
  DeleteTaskInput,
  FlattenedPlans,
  Plan,
  ResolveDueDateInput,
  UpdatePlanInput,
} from '../../types/plans';
import {reorderOptions} from './handlers/reorder';
import {axiosBaseQuery} from '../../utils/api';

export const plansTagKey = 'plans';
export const completedPlansTagKey = 'completedPlans';
export const plansApiReducerPath = 'plansApi';
// Stores plan in parsed format - minimal redundancy and processing time
const plansApi = createApi({
  reducerPath: plansApiReducerPath,
  tagTypes: [plansTagKey, completedPlansTagKey],
  baseQuery: axiosBaseQuery() as any,
  endpoints: (builder) => ({
    getPlans: builder.query<FlattenedPlans, void>(getPlansOptions),
    getCompletedPlans: builder.query<CompletedPlansResponse, CompletedPlansInput>(getCompletedPlans),
    getPlan: builder.query<Plan, string>(getPlanOptions),
    deletePlan: builder.mutation<void, string>(deletePlanOptions),
    deleteTask: builder.mutation<void, DeleteTaskInput>(deleteTaskOptions),
    deleteSubTask: builder.mutation<void, DeleteTaskInput>(deleteSubTaskOptions),
    createPlan: builder.mutation<Plan, CreatePlanInput>(createPlanOptions),
    createTask: builder.mutation<Plan, CreateTaskInput>(createTaskOptions),
    createSubTask: builder.mutation<Plan, CreateSubTaskInput>(createSubTaskOptions),
    updatePlan: builder.mutation<Plan, UpdatePlanInput>(updatePlanOptions),
    updateTask: builder.mutation<Plan, UpdatePlanInput>(updateTaskOptions),
    updateSubTask: builder.mutation<Plan, UpdatePlanInput>(updateSubTaskOptions),
    resolveDueDateConflict: builder.mutation<unknown, ResolveDueDateInput>(resolveDueDateConflictOptions),
    bulkUpsertSubtasks: builder.mutation<Plan[], BulkSubtaskUpsertInput>(bulkUpsertSubtaskOptions),
    completeAndRecover: builder.mutation<CompleteAndRecoverResponse, CompleteAndRecoverInput>(completeAndRecover),
    reorder: builder.mutation<unknown, Record<string, number>>(reorderOptions),
  }),
});

export default plansApi;
