import {ISubscriptionPlan, ISubscriptionPlans} from '@/features/auth/interfaces';
import {SubscriptionIntervalsEnum} from '@/shared/services_deprecated/enums';
import {validatePriceId} from './validatePriceId';

export function getPlanByComparator(
  plans: ISubscriptionPlans[],
  comparator: (plan: ISubscriptionPlan, interval: SubscriptionIntervalsEnum) => boolean,
  stripePriceId?: string,
  // If it's a history flow, we shouldn't care if the plan is purchasable or not
  // This is needed when we are comparing plans from the past
  isHistoryFlow?: boolean
): ISubscriptionPlan | null {
  for (const interval of [SubscriptionIntervalsEnum.YEAR, SubscriptionIntervalsEnum.MONTH]) {
    const foundPlan = plans.find(
      (p) =>
        comparator(p[interval], interval) &&
        p[interval].amount > 0 &&
        (isHistoryFlow || p[interval].isPurchasable) &&
        (!stripePriceId || validatePriceId(p[interval], stripePriceId))
    )?.[interval];

    if (foundPlan?.amount) {
      return foundPlan;
    }
  }

  return null;
}
