/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {Team} from './team';

/**
 *
 * @export
 * @interface TeamBasicInformation
 */
export interface TeamBasicInformation {
  /**
   *
   * @type {string}
   * @memberof TeamBasicInformation
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof TeamBasicInformation
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof TeamBasicInformation
   */
  role?: TeamBasicInformationRoleEnum;
  /**
   *
   * @type {Team}
   * @memberof TeamBasicInformation
   */
  team?: Team;
}

export const TeamBasicInformationRoleEnum = {
  Admin: 'ADMIN',
  Member: 'MEMBER',
} as const;

export type TeamBasicInformationRoleEnum =
  (typeof TeamBasicInformationRoleEnum)[keyof typeof TeamBasicInformationRoleEnum];
