import {create} from 'zustand';
import {ISubscriptionPlan} from '@/features/auth/interfaces';

interface SignupPlanStore {
  plan: ISubscriptionPlan | undefined;
  setPlan: (plan: ISubscriptionPlan | undefined) => void;
}

export const useSignupPlanStore = create<SignupPlanStore>()((set) => ({
  plan: undefined,
  setPlan: (plan) => set({plan}),
}));
