/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {PlatformInformation} from './platform-information';
// May contain unused imports in some cases
// @ts-ignore
import {Profile} from './profile';
// May contain unused imports in some cases
// @ts-ignore
import {UserChatProfile} from './user-chat-profile';
// May contain unused imports in some cases
// @ts-ignore
import {UserNotificationInfo} from './user-notification-info';
// May contain unused imports in some cases
// @ts-ignore
import {UserPreferences} from './user-preferences';
// May contain unused imports in some cases
// @ts-ignore
import {UserStripeConnectSetting} from './user-stripe-connect-setting';

/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof User
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof User
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof User
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  uuid?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  emailStartsWith?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  registrationReferralCode?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  registrationReferralUserId?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  countryCode?: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  forcePasswordChange?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  isActive?: boolean;
  /**
   *
   * @type {UserNotificationInfo}
   * @memberof User
   */
  notificationInfo?: UserNotificationInfo;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof User
   */
  userProperties?: {[key: string]: string};
  /**
   *
   * @type {Set<string>}
   * @memberof User
   */
  userAttributes?: Set<string>;
  /**
   *
   * @type {number}
   * @memberof User
   */
  currentCoinCount?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  iso2?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  region?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  profileImageKey?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  paymentMethodId?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  stripePriceId?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  stripeCustomerId?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  stripeSubscriptionId?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  paypalSubscriptionId?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  paypalPlanId?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  paymentSystem?: UserPaymentSystemEnum;
  /**
   *
   * @type {string}
   * @memberof User
   */
  firstPromoterCustomerId?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  firstPromoterAuthKey?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  subscriptionPlanName?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  level?: UserLevelEnum;
  /**
   *
   * @type {string}
   * @memberof User
   */
  paypalEmail?: string;
  /**
   *
   * @type {UserPreferences}
   * @memberof User
   */
  preferences?: UserPreferences;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  onboarded?: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  oneSignalPlayerId?: string;
  /**
   *
   * @type {Set<string>}
   * @memberof User
   */
  oneSignalPlayerIds?: Set<string>;
  /**
   *
   * @type {number}
   * @memberof User
   */
  utcOffsetInMinutes?: number;
  /**
   *
   * @type {number}
   * @memberof User
   */
  reportedIssues?: number;
  /**
   *
   * @type {number}
   * @memberof User
   */
  reportedFeedback?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  keycloakUserUuid?: string;
  /**
   *
   * @type {number}
   * @memberof User
   */
  trialReminderCount?: number;
  /**
   *
   * @type {number}
   * @memberof User
   */
  paymentAbandonmentReminderCount?: number;
  /**
   *
   * @type {number}
   * @memberof User
   */
  profileAbandonmentReminderCount?: number;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  optInForResearch?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  demoUser?: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  ianaTimezone?: string;
  /**
   *
   * @type {UserChatProfile}
   * @memberof User
   */
  userChatProfile?: UserChatProfile;
  /**
   *
   * @type {string}
   * @memberof User
   */
  userProfileHash?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  zipCode?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  appleSubscriptionId?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  applePlanId?: string;
  /**
   *
   * @type {number}
   * @memberof User
   */
  subscriptionExpiry?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  planFrequency?: UserPlanFrequencyEnum;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  kajabiUser?: boolean;
  /**
   *
   * @type {number}
   * @memberof User
   */
  trialPeriodEnd?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  paypalNewSubscriptionPlanId?: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  hasAccessToMastermind?: boolean;
  /**
   *
   * @type {number}
   * @memberof User
   */
  signupOfferId?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  signupOfferName?: string;
  /**
   *
   * @type {Profile}
   * @memberof User
   */
  profile?: Profile;
  /**
   *
   * @type {string}
   * @memberof User
   */
  oldSubscription?: string;
  /**
   *
   * @type {PlatformInformation}
   * @memberof User
   */
  platformInformation?: PlatformInformation;
  /**
   *
   * @type {string}
   * @memberof User
   */
  subscriptionPlatform?: UserSubscriptionPlatformEnum;
  /**
   *
   * @type {number}
   * @memberof User
   */
  signupChallengeId?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  signupChallengeName?: string;
  /**
   *
   * @type {number}
   * @memberof User
   */
  signupPlanId?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  giftSubscriptionBuyerEmail?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  giftSubscriptionSignupToken?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  signupType?: UserSignupTypeEnum;
  /**
   *
   * @type {number}
   * @memberof User
   */
  signupId?: number;
  /**
   *
   * @type {number}
   * @memberof User
   */
  signupLiveEventId?: number;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  needPasswordSet?: boolean;
  /**
   *
   * @type {number}
   * @memberof User
   */
  campaignId?: number;
  /**
   *
   * @type {number}
   * @memberof User
   */
  signupSocialEventId?: number;
  /**
   *
   * @type {number}
   * @memberof User
   */
  giftSubscriptionRedemptionCount?: number;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  shakeToFeedback?: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  ipAddress?: string;
  /**
   *
   * @type {number}
   * @memberof User
   */
  currentBadgeCount?: number;
  /**
   *
   * @type {number}
   * @memberof User
   */
  currentCertificateCount?: number;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  joinedFirstEverLiveEvent?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  automaticTaxEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  enterpriseUser?: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  signupGrowthGroupInstanceId?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  fromShareUrl?: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  accessToRecordGrowthGroups?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof User
   */
  cards?: Array<string>;
  /**
   *
   * @type {UserStripeConnectSetting}
   * @memberof User
   */
  stripeConnect?: UserStripeConnectSetting;
  /**
   *
   * @type {number}
   * @memberof User
   */
  flowId?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  partnerId?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  partnerSku?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  partnerRenewalSku?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  partnerSubscriptionId?: string;
  /**
   *
   * @type {number}
   * @memberof User
   */
  partnerSubscriptionPlanId?: number;
  /**
   *
   * @type {number}
   * @memberof User
   */
  partnerSubscriptionStart?: number;
  /**
   *
   * @type {number}
   * @memberof User
   */
  partnerSubscriptionRenewed?: number;
  /**
   *
   * @type {string}
   * @memberof User
   */
  partnerOriginId?: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  teamId?: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  dbPopulated?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  subscriptionCancelled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  giftBuyer?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  isSubscriptionCancelled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  isGiftBuyer?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  isTeamUser?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  isPartnerUser?: boolean;
}

export const UserPaymentSystemEnum = {
  Card: 'CARD',
  Paypal: 'PAYPAL',
  Apple: 'APPLE',
} as const;

export type UserPaymentSystemEnum = (typeof UserPaymentSystemEnum)[keyof typeof UserPaymentSystemEnum];
export const UserLevelEnum = {
  Basic: 'BASIC',
  Starter: 'STARTER',
  Growth: 'GROWTH',
  Mastery: 'MASTERY',
  Trial: 'TRIAL',
  None: 'NONE',
  Offer: 'OFFER',
  Challenge: 'CHALLENGE',
  Enterprise: 'ENTERPRISE',
  EnterpriseStarter: 'ENTERPRISE_STARTER',
  AllAccess: 'ALL_ACCESS',
} as const;

export type UserLevelEnum = (typeof UserLevelEnum)[keyof typeof UserLevelEnum];
export const UserPlanFrequencyEnum = {
  Month: 'MONTH',
  Year: 'YEAR',
} as const;

export type UserPlanFrequencyEnum = (typeof UserPlanFrequencyEnum)[keyof typeof UserPlanFrequencyEnum];
export const UserSubscriptionPlatformEnum = {
  Web: 'WEB',
  Ios: 'IOS',
  Android: 'ANDROID',
} as const;

export type UserSubscriptionPlatformEnum =
  (typeof UserSubscriptionPlatformEnum)[keyof typeof UserSubscriptionPlatformEnum];
export const UserSignupTypeEnum = {
  Challenge: 'CHALLENGE',
  Offer: 'OFFER',
  LiveEvent: 'LIVE_EVENT',
  Plan: 'PLAN',
} as const;

export type UserSignupTypeEnum = (typeof UserSignupTypeEnum)[keyof typeof UserSignupTypeEnum];
