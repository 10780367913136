/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface OrganizationUpcomingSubscription
 */
export interface OrganizationUpcomingSubscription {
  /**
   *
   * @type {string}
   * @memberof OrganizationUpcomingSubscription
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof OrganizationUpcomingSubscription
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof OrganizationUpcomingSubscription
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof OrganizationUpcomingSubscription
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpcomingSubscription
   */
  organizationId?: string;
  /**
   *
   * @type {number}
   * @memberof OrganizationUpcomingSubscription
   */
  seats?: number;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpcomingSubscription
   */
  plan?: OrganizationUpcomingSubscriptionPlanEnum;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpcomingSubscription
   */
  planName?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpcomingSubscription
   */
  planFrequency?: OrganizationUpcomingSubscriptionPlanFrequencyEnum;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpcomingSubscription
   */
  stripeCustomerId?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpcomingSubscription
   */
  stripePaymentMethodId?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpcomingSubscription
   */
  paymentMethodType?: OrganizationUpcomingSubscriptionPaymentMethodTypeEnum;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpcomingSubscription
   */
  stripeSubscriptionId?: string;
  /**
   *
   * @type {number}
   * @memberof OrganizationUpcomingSubscription
   */
  subscriptionAmount?: number;
  /**
   *
   * @type {number}
   * @memberof OrganizationUpcomingSubscription
   */
  subscriptionStartDate?: number;
  /**
   *
   * @type {number}
   * @memberof OrganizationUpcomingSubscription
   */
  subscriptionEndDate?: number;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpcomingSubscription
   */
  stripePriceId?: string;
}

export const OrganizationUpcomingSubscriptionPlanEnum = {
  Basic: 'BASIC',
  Starter: 'STARTER',
  Growth: 'GROWTH',
  Mastery: 'MASTERY',
  Trial: 'TRIAL',
  None: 'NONE',
  Offer: 'OFFER',
  Challenge: 'CHALLENGE',
  Enterprise: 'ENTERPRISE',
  EnterpriseStarter: 'ENTERPRISE_STARTER',
  AllAccess: 'ALL_ACCESS',
} as const;

export type OrganizationUpcomingSubscriptionPlanEnum =
  (typeof OrganizationUpcomingSubscriptionPlanEnum)[keyof typeof OrganizationUpcomingSubscriptionPlanEnum];
export const OrganizationUpcomingSubscriptionPlanFrequencyEnum = {
  Month: 'MONTH',
  Year: 'YEAR',
} as const;

export type OrganizationUpcomingSubscriptionPlanFrequencyEnum =
  (typeof OrganizationUpcomingSubscriptionPlanFrequencyEnum)[keyof typeof OrganizationUpcomingSubscriptionPlanFrequencyEnum];
export const OrganizationUpcomingSubscriptionPaymentMethodTypeEnum = {
  Card: 'CARD',
  Paypal: 'PAYPAL',
  Apple: 'APPLE',
} as const;

export type OrganizationUpcomingSubscriptionPaymentMethodTypeEnum =
  (typeof OrganizationUpcomingSubscriptionPaymentMethodTypeEnum)[keyof typeof OrganizationUpcomingSubscriptionPaymentMethodTypeEnum];
