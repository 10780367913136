import {Select} from 'antd';
import {SelectProps} from 'antd/es/select';
import React, {FC, useMemo, useRef} from 'react';
import useCountryState from '@growthday/ui-core/src/hooks/useCountryState';
import './style.less';

export type GDStateInputProps = {
  country?: string;
} & SelectProps<string>;

const GDStateInput: FC<GDStateInputProps> = ({country, ...props}) => {
  const {getStates} = useCountryState();
  const states = useMemo(() => getStates(country), [country, getStates]);
  const container = useRef<HTMLDivElement>(null);
  return (
    <div ref={container}>
      <Select
        // @ts-ignore
        autoComplete="new-password"
        showSearch
        optionFilterProp="children"
        size="large"
        {...props}
        getPopupContainer={() => container.current || document.body}
      >
        {states.map((state) => (
          <Select.Option key={`${state.state_code}-${state.name}`} value={state.state_code}>
            {state.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default GDStateInput;
