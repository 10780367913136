import useUpdatedRef from '@growthday/ui-core/src/hooks/useUpdatedRef';
import {Children, FC, ReactNode, RefObject, useEffect, useMemo, useRef, useState} from 'react';
import {CarouselProps, CarouselRef} from 'antd/lib/carousel';
import {Button, Carousel} from 'antd';
import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import styles from './Carousel.module.less';
import useGDBreakpoint, {GDBreakpoint, sortGDBreakpoints} from '../../../hooks/useBreakpoint';
import {Settings} from '@ant-design/react-slick';
import classNames from 'classnames';

export type GDCarouselSettings = Partial<Omit<Settings, 'dots' | 'dotsClass'>>;

export type GDCarouselProps = Omit<CarouselProps, 'responsive'> & {
  responsive?: Partial<Record<GDBreakpoint, GDCarouselSettings>>;
  fadeEdges?: boolean;
  containerClassName?: string;
  id?: string;
  headerElement?: ReactNode;
  headerBeforeArrows?: boolean;
};

const GDCarousel: FC<GDCarouselProps> = ({
  id,
  containerClassName,
  responsive,
  fadeEdges = false,
  children,
  headerElement,
  headerBeforeArrows,
  ...props
}) => {
  const carouselRef = useRef<CarouselRef>() as RefObject<CarouselRef>;
  const afterChangeRef = useUpdatedRef(props.afterChange);
  const [currentSlide, setCurrentSlide] = useState(props.initialSlide ?? 0);
  const breakpoint = useGDBreakpoint();

  const responsiveProps: CarouselProps | undefined = useMemo(() => {
    const definedBreakpoints = Object.keys(responsive ?? {}) as GDBreakpoint[];
    const currentBreakpoint = sortGDBreakpoints(definedBreakpoints, 'desc').find((key) => breakpoint[key]) ?? 'xs';
    return responsive?.[currentBreakpoint];
  }, [responsive, breakpoint]);

  const handleNext = () => carouselRef.current?.next();
  const handlePrevious = () => carouselRef.current?.prev();

  const childCount = Children.count(children);
  const slideToShow = responsiveProps?.slidesToShow ?? props.slidesToShow ?? 1;
  const isFirstSlide = currentSlide <= 0;
  const isLastSlide = currentSlide >= childCount - slideToShow;
  const canSlide = childCount > slideToShow;

  const wrappedChildren = useMemo(
    () => Children.toArray(children).map((child, index) => <div key={index}>{child}</div>),
    [children]
  );

  useEffect(() => {
    afterChangeRef.current?.(currentSlide);
  }, [afterChangeRef, currentSlide]);

  return (
    <div className={classNames(containerClassName, styles.root)} id={id}>
      {canSlide && (
        <div className={classNames(styles.carouselActions, 'gd-carousel-actions')}>
          {headerBeforeArrows && headerElement}
          <Button
            disabled={!props.infinite && isFirstSlide}
            className={classNames(styles.button, !props.infinite && isFirstSlide && styles.cursorDefault)}
            type="link"
            shape="circle"
            onClick={handlePrevious}
          >
            <LeftOutlined />
          </Button>
          <Button
            className={classNames(styles.button, !props.infinite && isLastSlide && styles.cursorDefault)}
            disabled={!props.infinite && isLastSlide}
            onClick={handleNext}
            type="link"
            shape="circle"
          >
            <RightOutlined className={styles.rightOutlined} />
          </Button>
          {!headerBeforeArrows && headerElement}
        </div>
      )}
      <Carousel
        ref={carouselRef}
        infinite={false}
        dots={false}
        draggable
        arrows={false}
        useTransform
        useCSS
        swipeToSlide
        {...props}
        {...responsiveProps}
        afterChange={setCurrentSlide}
        className={classNames(
          styles.carousel,
          canSlide && 'slick-can-slide',
          isFirstSlide && 'slick-first-slide',
          isLastSlide && 'slick-last-slide',
          fadeEdges && styles.carouselWithFade,
          props.className
        )}
      >
        {wrappedChildren}
      </Carousel>
    </div>
  );
};

export default GDCarousel;
