import {ISubscriptionPlan} from '@/features/auth/interfaces';

export const validatePriceId = (currentPlan: ISubscriptionPlan, stripePriceId: string) => {
  return (
    currentPlan.stripeMonthlyPriceId === stripePriceId ||
    currentPlan.stripeYearlyPriceId === stripePriceId ||
    currentPlan.withoutTrialStripeMonthlyPriceId === stripePriceId ||
    currentPlan.withoutTrialStripeYearlyPriceId === stripePriceId ||
    // this is to retrocompatibility with old plans
    currentPlan.stripePriceId === stripePriceId
  );
};
