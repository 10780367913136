import {axiosGrowthDay, strapiApi} from '../index';
import {IMarketingCampaigns} from '@growthday/ui-core/src/types/strapi';
import {StrapiCollectionEnum} from '../strapi/enums';
import {
  CampaignSignupInfo,
  CampaignSignupResponse,
  CampaignValidationRequest,
  CampaignValidationResponse,
} from '../../../features/campaign/interfaces';
import {TriggerInfo} from '../../../features/auth/interfaces';
import {Platform} from '../../util/types';
import {SignupInfoResponse} from '@growthday/ui-core/src/types/api';

export const campaignApi = {
  getMarketingCampaignBySlug(slug: string) {
    return strapiApi
      .getPublicCollection<IMarketingCampaigns[]>(StrapiCollectionEnum.MARKETING_CAMPAIGN, `?slug=${slug}`)
      .then((res) => res[0]);
  },
  getMarketingCampaignById(id: number) {
    return strapiApi
      .getPublicCollection<IMarketingCampaigns[]>(StrapiCollectionEnum.MARKETING_CAMPAIGN, `?id=${id}`)
      .then((res) => res[0]);
  },
  marketingCampaignSignUp(request: CampaignSignupInfo, triggerInfo?: TriggerInfo) {
    const params: Record<string, string | boolean> = {};
    if (triggerInfo) {
      params.trigger = triggerInfo.triggerFlow ?? '';
      params.button = triggerInfo.triggeredFromSection ?? '';
      params.content = triggerInfo.triggeredContentName ?? '';
      params.mixpanel = true;
    }
    return axiosGrowthDay
      .post<CampaignSignupResponse>(`/register/marketingSignUp/${Platform.WEB}`, request)
      .then((res) => res.data);
  },
  validateMarketingCampaign(request: CampaignValidationRequest) {
    return axiosGrowthDay
      .post<CampaignValidationResponse>(`/register/marketingValidation`, request)
      .then((res) => res.data);
  },
  getCampaignInfoData(campaignSlug?: string) {
    return axiosGrowthDay('/signup/info', {
      params: {
        campaignSlug: campaignSlug ?? '',
      },
    }).then((res) => res.data as SignupInfoResponse);
  },
};
