import {UseQueryOptions, useQuery} from 'react-query';
import {IUser} from '@/shared/services_deprecated/model/user';
import {axiosGrowthDay} from '../../../utils/api';

// Query keys for user data
export const UserQueryKey = 'user';
export const UserMeDataQueryKey = [UserQueryKey, 'me'];

/**
 * Custom hook to fetch the current user's data.
 *
 * @param options - Query options for customization (optional) example {enabled,onSuccess,onError}.
 * @returns Query result containing the current user's data.
 */
export function useGetMeData(options: UseQueryOptions<IUser, unknown, IUser, typeof UserMeDataQueryKey> = {}) {
  return useQuery(
    UserMeDataQueryKey,
    async () => {
      const result = await axiosGrowthDay.get<IUser>('/me');
      return result.data;
    },
    options
  );
}
