/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {CmsMedia} from './cms-media';
// May contain unused imports in some cases
// @ts-ignore
import {GrowthGroupInstance} from './growth-group-instance';
// May contain unused imports in some cases
// @ts-ignore
import {Hms} from './hms';

/**
 *
 * @export
 * @interface GrowthGroupCreateResponse
 */
export interface GrowthGroupCreateResponse {
  /**
   *
   * @type {string}
   * @memberof GrowthGroupCreateResponse
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupCreateResponse
   */
  channel: GrowthGroupCreateResponseChannelEnum;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupCreateResponse
   */
  uuid: string;
  /**
   *
   * @type {Hms}
   * @memberof GrowthGroupCreateResponse
   */
  hms: Hms;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupCreateResponse
   */
  strapiId?: number;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupCreateResponse
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupCreateResponse
   */
  time: string;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupCreateResponse
   */
  duration?: number;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupCreateResponse
   */
  rrule?: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupCreateResponse
   */
  type: GrowthGroupCreateResponseTypeEnum;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupCreateResponse
   */
  slug: string;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupCreateResponse
   */
  canGuestInvite?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupCreateResponse
   */
  recording?: boolean;
  /**
   *
   * @type {CmsMedia}
   * @memberof GrowthGroupCreateResponse
   */
  image?: CmsMedia;
  /**
   *
   * @type {Array<GrowthGroupInstance>}
   * @memberof GrowthGroupCreateResponse
   */
  instances?: Array<GrowthGroupInstance>;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupCreateResponse
   */
  workshop?: boolean;
}

export const GrowthGroupCreateResponseChannelEnum = {
  Audio: 'AUDIO',
  Video: 'VIDEO',
} as const;

export type GrowthGroupCreateResponseChannelEnum =
  (typeof GrowthGroupCreateResponseChannelEnum)[keyof typeof GrowthGroupCreateResponseChannelEnum];
export const GrowthGroupCreateResponseTypeEnum = {
  Private: 'PRIVATE',
  Public: 'PUBLIC',
} as const;

export type GrowthGroupCreateResponseTypeEnum =
  (typeof GrowthGroupCreateResponseTypeEnum)[keyof typeof GrowthGroupCreateResponseTypeEnum];
