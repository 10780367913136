export type MenuItemIconProps = {
  isActive?: boolean;
  url?: string;
};

export function MenuItemIcon({url, isActive}: MenuItemIconProps) {
  if (!url) {
    return null;
  }

  if (isActive) {
    const activeUrl = url.replace('.svg', '-active.svg');
    return <img src={activeUrl} alt="" />;
  }

  return <img src={url} alt="" />;
}
