/**
 * Model definition for growth-group-webhooks
 */
export interface IGrowthGroupWebhooks {
  id: number;
  url?: string;
  type?: EnumGrowthGroupWebhooksType;
  trigger?: EnumGrowthGroupWebhooksTrigger;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumGrowthGroupWebhooksType {
  KAJABI = 'KAJABI',
  PROOF = 'PROOF',
}

export enum EnumGrowthGroupWebhooksTrigger {
  SIGNUP = 'SIGNUP',
  JOINED = 'JOINED',
  CREATED = 'CREATED',
  STARTED = 'STARTED',
}
