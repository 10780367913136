import {useQueryClient} from 'react-query';
import {useCallback} from 'react';
import {produce} from 'immer';
import {IKeynote} from '../../types/strapi';
import {KeynotesDetailQueryKeyWithSlug} from './useGetKeynote';

export function useKeynoteUpdater() {
  const queryClient = useQueryClient();
  return useCallback(
    (keynote: Pick<IKeynote, 'slug'> | null | undefined, updater: (old: IKeynote) => void) => {
      if (!keynote) {
        return;
      }
      queryClient.setQueriesData<IKeynote | null | undefined>(
        {
          queryKey: KeynotesDetailQueryKeyWithSlug(keynote.slug),
          active: true,
          fetching: false,
        },
        (old) => {
          if (!old) {
            return old;
          }

          return produce(old, updater);
        }
      );
    },
    [queryClient]
  );
}
