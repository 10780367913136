import classNames from 'classnames';
import {noop} from 'lodash';
import {FC, useEffect} from 'react';
import PhoneInput, {Props, DefaultInputComponentProps} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import styles from './PhoneInput.module.less';

export type GDPhoneInputProps = {
  disableCountryDropdown?: boolean;
} & Partial<DefaultInputComponentProps>;

const CountrySelectComponent: FC<Props<DefaultInputComponentProps> & {country?: string}> = ({
  onChange,
  value,
  country,
  children,
}) => {
  useEffect(() => {
    if (country && value !== country) {
      onChange?.(country);
    }
  }, [value, country, onChange]);
  return <>{children}</>;
};

const GDPhoneInput: FC<GDPhoneInputProps> = ({disableCountryDropdown, ...props}) => (
  <div className={classNames(styles.root, 'ant-form-item-control-input-content')}>
    <PhoneInput
      value=""
      onChange={noop}
      limitMaxLength
      countryCallingCodeEditable={false}
      international
      numberInputProps={{
        className: styles.input,
      }}
      className={classNames('ant-input', 'ant-input-lg')}
      countrySelectComponent={
        disableCountryDropdown
          ? (countrySelectProps) => <CountrySelectComponent {...countrySelectProps} country={props.country} />
          : undefined
      }
      {...props}
      defaultCountry={props.defaultCountry || 'US'}
    />
  </div>
);

export default GDPhoneInput;
