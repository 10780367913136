import {useUpdateUser} from 'statsig-react';
import {useEffect} from 'react';
import {useFeatureGateUser} from '@growthday/ui-core/src/features/feature-gate/hooks/useFeatureGateUser';

export function useFeatureGateUserSetup() {
  const updateUser = useUpdateUser();
  const statsigUser = useFeatureGateUser('web');

  useEffect(() => {
    updateUser(statsigUser);
  }, [statsigUser, updateUser]);
}
