/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface UserLiveEvent
 */
export interface UserLiveEvent {
  /**
   *
   * @type {string}
   * @memberof UserLiveEvent
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof UserLiveEvent
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof UserLiveEvent
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof UserLiveEvent
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof UserLiveEvent
   */
  uuid?: string;
  /**
   *
   * @type {string}
   * @memberof UserLiveEvent
   */
  eventNotes?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserLiveEvent
   */
  pinned?: boolean;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof UserLiveEvent
   */
  eventNotePhotos?: {[key: string]: string};
  /**
   *
   * @type {string}
   * @memberof UserLiveEvent
   */
  status?: UserLiveEventStatusEnum;
  /**
   *
   * @type {string}
   * @memberof UserLiveEvent
   */
  eventId?: string;
  /**
   *
   * @type {string}
   * @memberof UserLiveEvent
   */
  eventName?: string;
  /**
   *
   * @type {number}
   * @memberof UserLiveEvent
   */
  progress?: number;
  /**
   *
   * @type {number}
   * @memberof UserLiveEvent
   */
  registrations?: number;
  /**
   *
   * @type {number}
   * @memberof UserLiveEvent
   */
  noteTimestamp?: number;
  /**
   *
   * @type {boolean}
   * @memberof UserLiveEvent
   */
  complete?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserLiveEvent
   */
  completedDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof UserLiveEvent
   */
  watched?: boolean;
  /**
   *
   * @type {Array<object>}
   * @memberof UserLiveEvent
   */
  actions?: Array<object>;
  /**
   *
   * @type {boolean}
   * @memberof UserLiveEvent
   */
  coinsGrantedForParticipation?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserLiveEvent
   */
  noteMigrated?: boolean;
}

export const UserLiveEventStatusEnum = {
  Registered: 'REGISTERED',
  Left: 'LEFT',
  Completed: 'COMPLETED',
} as const;

export type UserLiveEventStatusEnum = (typeof UserLiveEventStatusEnum)[keyof typeof UserLiveEventStatusEnum];
