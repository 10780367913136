import {message} from 'antd';
import classNames from 'classnames';
import React, {FC, useMemo, useState} from 'react';
import EmptyLayout from '../../../../shared/components/EmptyLayout/EmptyLayout';
import GDButton from '../../../../shared/components/ui/Button';
import GDTypography from '../../../../shared/components/ui/Typography/Typography';
import commonStyles from '../../../../shared/style/commonStyles.module.less';
import displayApiError from '../../../../shared/util/display-api-error';
import AuthContainer from '../../../auth/components/AuthContainer/AuthContainer';
import AmericanExpressIcon from '../../../auth/routes/SignUp/components/icons/AmericanExpressIcon';
import DiscoverIcon from '../../../auth/routes/SignUp/components/icons/DiscoverIcon';
import MasterCardIcon from '../../../auth/routes/SignUp/components/icons/MasterCardIcon';
import VisaIcon from '../../../auth/routes/SignUp/components/icons/VisaIcon';
import SelectedPlan from '../../../auth/routes/SignUp/components/SelectedPlan';
import Step from '../../../auth/routes/SignUp/components/Step';
import Terms from '../../../auth/routes/SignUp/components/Terms/Terms';
import {FormValue, initialValues} from '../../../auth/routes/SignUp/routes/CreditCard';
import styles from '../../../auth/routes/SignUp/routes/CreditCard/CreditCard.module.less';
import StripeCardForm from '../../../license/components/StripeCardForm/StripeCardForm';
import useStripePayment from '../../../license/hooks/useStripePayment';
import {getSubscriptionFromStrapiSubscription} from '../../../license/utils/subscription';
import useOrganizationQuery from '@growthday/ui-core/src/features/enterprise/hooks/useOrganizationQuery';
import {useGetAuthData} from '../../api/useGetAuthData';
import {OrganizationResponsePlanFrequencyEnum} from '@growthday/ui-core/src/types/api';
import {SubscriptionIntervalsEnum} from '@/shared/services_deprecated/enums';
import useSubscriptionPlans from '@/features/auth/routes/Profile/hooks/useSubscriptionPlans';
import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';

const Payment: FC = () => {
  const {isLoading: userLoading} = useUser();
  const {allStrapiSubscriptionPlans, isLoading: subscriptionLoading} = useSubscriptionPlans();
  const {enabled, handleSuccessUpdateDate} = useGetAuthData();
  const {data: organization} = useOrganizationQuery({enabled, onSuccess: handleSuccessUpdateDate});

  const registrationPlan = useMemo(() => {
    const interval = (organization?.planFrequency ?? OrganizationResponsePlanFrequencyEnum.Year).toLowerCase();
    const plan = allStrapiSubscriptionPlans.find(
      (plan) =>
        (interval === OrganizationResponsePlanFrequencyEnum.Month.toLowerCase()
          ? plan.stripeMonthlyPriceId
          : plan.stripeYearlyPriceId) === organization?.stripePriceId
    );
    return plan ? getSubscriptionFromStrapiSubscription(plan)[interval as SubscriptionIntervalsEnum] : undefined;
  }, [allStrapiSubscriptionPlans, organization]);
  const [stripeLoading, setStripeLoading] = useState(false);
  const {purchaseSubscription} = useStripePayment();

  const handleSubmit = async () => {
    try {
      if (registrationPlan) {
        await purchaseSubscription(registrationPlan);
      }
    } catch (e: any) {
      e.message && message.error(e.message);
    }
  };
  return (
    <EmptyLayout>
      <AuthContainer className={styles.container} hideSignupCol>
        <Step step={2} title="Enter your card details" totalSteps={2}>
          <div className={styles.cards}>
            <VisaIcon />
            <MasterCardIcon />
            <AmericanExpressIcon />
            <DiscoverIcon />
          </div>
          <StripeCardForm<FormValue>
            onError={displayApiError}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            onLoading={setStripeLoading}
          >
            <SelectedPlan showTrial={false} plan={registrationPlan} />
            <GDTypography center block type="w50" className={classNames(commonStyles.lightText2, commonStyles.mt12)}>
              State and local sales tax will be calculated on your final invoice.
            </GDTypography>
            <GDButton
              size="large"
              htmlType="submit"
              key="submit"
              type="primary"
              className={classNames('gd-submit-button', styles.submitBtn)}
              loading={stripeLoading || userLoading || subscriptionLoading}
            >
              Start membership
            </GDButton>
          </StripeCardForm>
          <Terms type="membership" />
        </Step>
      </AuthContainer>
    </EmptyLayout>
  );
};

export default Payment;
