import {
  IBulletListItem,
  IChallenges,
  ICourses,
  IMarketingLinkedOffers,
  IMarketingOffer,
  IOffers,
  IOnboarding,
  ITooltips,
  IUpsells,
} from './strapi';
import {NestedObjectByDepth} from './utility/nestedObjectByDepth';

export const DEFAULT_OFFERS_DEPTH = 2;

export type IOffersNonNested = Omit<
  IOffers,
  'bulletItems' | 'canonical' | 'challenge' | 'courses' | 'onboarding' | 'tooltips'
> & {
  canonical?: NestedObjectByDepth<IOffers, 1>;
  challenge?: NestedObjectByDepth<IChallenges, 1>;
  courses?: NestedObjectByDepth<ICourses, 1>[];
  onboarding?: NestedObjectByDepth<IOnboarding, 1>;
  bulletItems?: NestedObjectByDepth<IBulletListItem, 1>[];
  tooltips?: NestedObjectByDepth<ITooltips, 1>[];
};

export type IMarketingLinkedNonNestedOffers = Omit<IMarketingLinkedOffers, 'offer'> & {
  offer?: IOffersNonNested;
};

export type IUpsellsNonNestedOffers = Omit<IUpsells, 'offers'> & {
  offers: IMarketingLinkedNonNestedOffers[];
};

export type IMarketingNonNestedOffer = Omit<IMarketingOffer, 'offer'> & {
  offer?: IOffersNonNested;
  orderBump?: IOffersNonNested;
  onboardingUpsells: IMarketingLinkedNonNestedOffers[];
};
