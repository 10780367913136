import Input, {InputProps} from 'antd/es/input';
import React, {forwardRef, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes} from 'react';
import useBreakpointSize from '../../../../hooks/useBreakpointSize';
import GDPassword from './Password';
import './style.less';

export type GDInputProps = {} & InputProps;
export type GDInputType = {
  Password: typeof GDPassword;
  TextArea: typeof Input.TextArea;
} & ForwardRefExoticComponent<PropsWithoutRef<GDInputProps> & RefAttributes<Input>>;

const GDInput = forwardRef<Input, GDInputType>(({children, ...restProps}, ref) => {
  const breakpointSize = useBreakpointSize();
  return (
    <Input ref={ref} size={breakpointSize} {...restProps}>
      {children}
    </Input>
  );
}) as GDInputType;

GDInput.Password = GDPassword;
GDInput.TextArea = Input.TextArea;

export default GDInput;
