// @ts-ignore
import dJSON from 'dirty-json';
import {DailyJournalEntry} from '../../services_deprecated/model/generic/dailyJournal';
import {axiosGrowthDay} from '../index';
import {DailyJournalEntryWithCoins, ImagesIdsInterface, LinkAttributes} from './interfaces';

export const journalApi = {
  getJournalById: (id: string) => {
    return axiosGrowthDay.get<DailyJournalEntry>(`/journals/${id}`).then((res) => {
      if (res.data) {
        res.data.data = parseToArrayDataJournal(res.data.data);
      }
      return res.data;
    });
  },
  createJournal: (journalEntry: DailyJournalEntry, linkAssociation?: LinkAttributes) => {
    const body = linkAssociation ? {...journalEntry, ...linkAssociation} : journalEntry;
    return axiosGrowthDay.post<DailyJournalEntryWithCoins>(`/journals`, body).then((res) => res.data);
  },
  updateJournal: (journalEntry: DailyJournalEntry, linkAssociation?: LinkAttributes) => {
    const body = linkAssociation ? {...journalEntry, ...linkAssociation} : journalEntry;
    return axiosGrowthDay.put<DailyJournalEntry>(`/journals/${journalEntry.id}`, body).then((res) => {
      if (res.data) {
        res.data.data = parseToArrayDataJournal(res.data.data);
      }
      return res.data;
    });
  },
  deleteJournal: (id: string) => {
    return axiosGrowthDay.delete(`/journals/${id}`).then((res) => res.data);
  },
  getJournalByCategories: (category: string) => {
    // TODO implement pagination later
    return axiosGrowthDay.get(`/journals/journal-creation-list/${category}?page=1&size=500`).then((res) => {
      if (res.data?.list) {
        const values = res.data?.list as DailyJournalEntry[];
        values.forEach((entry) => {
          entry.data = parseToArrayDataJournal(entry.data);
        });
        return values;
      } else {
        return [];
      }
    });
  },
  uploadJournalPicture: <T = any>(collection?: string, objectId?: string, file?: FormData) => {
    return axiosGrowthDay
      .post<T>(`/journals/photo/DailyJournal/${collection}/${objectId}`, file)
      .then((res) => res.data);
  },
  deleteJournalPictures: (objectId: string, images: ImagesIdsInterface) => {
    return axiosGrowthDay
      .delete<DailyJournalEntryWithCoins>(`/journals/photo/DailyJournal/${objectId}`, {data: images})
      .then((res) => res.data);
  },
  getJournalPicture: (journalId: string) => {
    return axiosGrowthDay
      .get<Record<string, string>>(`/journals/photo/DailyJournal/${journalId}`)
      .then((res) => res.data);
  },
};

export const parseToArrayDataJournal = (data?: any) => {
  let returnValue = data;
  if (typeof data === 'string') {
    if (data.startsWith('[{') && data.endsWith('}]') && data.includes('question') && data.includes('answer')) {
      try {
        returnValue = JSON.parse(data!);
      } catch (e: any) {
        try {
          returnValue = dJSON.parse(data!);
        } catch (e: any) {}
      }
    }
  }
  return returnValue;
};
