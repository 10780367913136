declare global {
  /**
   * Extends the global Window interface with an optional `entryPointSource` property.
   * This property, if present, holds a string representing the source of the entry point in the application.
   */
  interface Window {
    entryPointSource?: string;
  }
}

/**
 * The storage key used for storing entry point parameters in sessionStorage.
 */
export const ENTRY_POINT_STORAGE_KEY = 'entry-point-params';

/**
 * Sets the entry point parameters in the window object and sessionStorage.
 * This function retrieves the previously stored entry point source from sessionStorage.
 * If there is no stored value, or if the stored value is the root path ('/') and the current
 * window location is different, it updates the sessionStorage with the current window location.
 * Additionally, it sets the `entryPointSource` property on the window object with the current or stored entry point source.
 */
export const setEntryPointParams = () => {
  let storedEntryPointSource = sessionStorage.getItem(ENTRY_POINT_STORAGE_KEY);

  if (
    !storedEntryPointSource ||
    (storedEntryPointSource === '/' && window.location.pathname !== storedEntryPointSource)
  ) {
    storedEntryPointSource = window.location.pathname;
    sessionStorage.setItem(ENTRY_POINT_STORAGE_KEY, storedEntryPointSource);
  }

  window.entryPointSource = storedEntryPointSource;
};
