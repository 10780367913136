import message from 'antd/es/message';
import {LoginResponse} from '@/shared/services_deprecated/model/loginResponse';
import {IPaymentMethod, ISignUpInfo, ISubscriptionPlan, IUserAnalytics, KeyCloakLoginRequest} from './interfaces';
import displayApiError from '@/shared/util/display-api-error';
import {
  dashboardApi,
  giftSubscriptionsApi,
  growthDayCoinApi,
  loginApi,
  logoutApi,
  meApi,
  offersApi,
  registerApi,
  stripeApi,
  userApi,
  userChallengesApi,
} from '@/shared/api';
import {ApiActionThunk, getApiActionCreator} from '@/shared/redux/util/api-action-builder';
import {AUTH_ACTION_PREFIX} from './constants';
import {IUpdateUser, IUpdateUserAddress, IUser, UserSignupType} from '@/shared/services_deprecated/model/user';
import {clearStorage} from './routes/Profile/utils';
import {CoinsScores} from '@/shared/util/userConstants';
import {UserRequest} from '@/shared/services_deprecated/model/userRequest';
import {PasswordChangeRequest, UserInviteRequest} from '@/shared/api/user/interfaces';
import {StatusResponse} from '@/shared/api/gdpr/interfaces';
import {
  getGtagActions,
  getAnalyticsActions,
  analyticsIdentify,
  getAnalyticsSuperProperties,
} from '@/features/analytics/hooks/useAnalyticsActions';
import {gdprApi} from '@/shared/api/gdpr/gdpr.api';
import {resetState} from '@/shared/redux/actions';
import {CoinsGroupByResponse} from '@/shared/api/growthDayCoin/interfaces';
import {SubscriptionIntervalsEnum} from '@/shared/services_deprecated/enums';
import {updateErrorMsg} from './auth.slice';
import {resetMediaPlayerState} from '../media-player_old/mediaPlayer.slice';
import {transactionsApi} from '@/shared/api/transactions/transactions.api';
import {unsetSidebarCalendarState} from '../sidebar-calendar/sidebarCalendar.slice';
import {AppState} from '@/shared/types';
import {fbPixelActions} from '@/marketing/useFbPixel';
import {getGtagEvents} from '@/features/analytics/utils/getGtagEvents';
import config from '@/config';
import {IStrapiChallenge} from '../challenges/interfaces';
import {EnumSocialEventsTrigger, EnumSocialEventsType, ISignupPage} from '@growthday/ui-core/src/types/strapi';
import {IStrapiLiveEvent} from '@/shared/services_deprecated/model/strapi/live-event';
import {userLiveEventApi} from '@/shared/api/userLiveEvent/userLiveEvent.api';
import {tail} from 'lodash';
import {ThunkDispatch} from '@reduxjs/toolkit';
import {AnyAction} from 'redux';
import {CampaignSignupInfo, CampaignSignupResponse} from '../campaign/interfaces';
import {campaignApi} from '@/shared/api/campaign/campaign.api';
import eventNameSlug from '@/shared/util/eventNameSlug';
import {sendGroupRsvpEvent} from '@/features/growthgroups/hooks/useGroupMixpanelEvents';
import {GrowthGroupInstance, UserInviteGiftSubscriptionRequest} from '@growthday/ui-core/src/types/api';
import {readablePlanInterval} from '@/features/auth/utils/plan-interval';
import {resetDatadogSession} from '@/shared/hooks/useDatadog';
import {useChatStore} from '@growthday/ui-core/src/features/chat/store/chat-store';
import {useChatMessageStore} from '@growthday/ui-core/src/features/chat/store/message-store';
import {IOffersNonNested} from '@growthday/ui-core/src/types/offers';

export type AuthApiActionsType = {
  loginKeyCloak: ApiActionThunk<LoginResponse, KeyCloakLoginRequest>;
  setKeyCloakCookie: ApiActionThunk;
  logout: ApiActionThunk;
  getGrowthCoinTransactions: ApiActionThunk<CoinsGroupByResponse>;
  updateUserInfo: ApiActionThunk<IUser, IUpdateUser>;
  updateAddress: ApiActionThunk<{errorMessage?: string; isSuccess?: boolean}, IUpdateUserAddress>;
  processForgotPassword: ApiActionThunk<void, {email: string; captchaToken: string}>;
  getNumCoinsPerEvents: ApiActionThunk<CoinsScores>;
  listTransactions: ApiActionThunk;
  updateStripeSubscription: ApiActionThunk<
    void,
    {
      activeSubscription?: ISubscriptionPlan;
      newSubscription: ISubscriptionPlan;
      updateGiftedSubscription?: boolean;
      campaignSlug?: string;
      user: IUser;
    }
  >;
  cancelStripeSubscription: ApiActionThunk;
  register: ApiActionThunk<LoginResponse, UserRequest>;
  redeemGiftSubscription: ApiActionThunk<LoginResponse, UserInviteGiftSubscriptionRequest>;
  registerPayment: ApiActionThunk<
    IUser | void,
    UserRequest & Omit<IUser, 'roles'> & {plan?: ISubscriptionPlan; offers?: IOffersNonNested[]}
  >;
  marketingCampaignSignup: ApiActionThunk<CampaignSignupResponse, CampaignSignupInfo>;
  getAnalytics: ApiActionThunk<IUserAnalytics>;
  getOfferBySlug: ApiActionThunk<IOffersNonNested, string>;
  getSignupPageContent: ApiActionThunk<ISignupPage, void>;
  getChallengeByTitleUid: ApiActionThunk<IStrapiChallenge, string>;
  getLiveEventByNameUid: ApiActionThunk<IStrapiLiveEvent, string>;
  changeEmail: ApiActionThunk<IUser, string>;
  changePassword: ApiActionThunk<void, PasswordChangeRequest>;
  userByInvitation: ApiActionThunk<void, UserInviteRequest>;
  addStripePaymentMethod: ApiActionThunk<IPaymentMethod, {paymentMethodId: string; captchaToken?: string}>;
  updateStripePaymentMethod: ApiActionThunk<IPaymentMethod, {paymentMethodId: string; captchaToken?: string}>;
  getStripePaymentMethod: ApiActionThunk<IPaymentMethod>;
  requestDownloadData: ApiActionThunk<StatusResponse>;
  checkDownloadData: ApiActionThunk<StatusResponse>;
  checkEraseStatus: ApiActionThunk;
  eraseAllData: ApiActionThunk;
};

export const fireReferral = (user?: IUser) => {
  setTimeout(() => {
    try {
      if (window.$FPROM?.trackSignup && user?.email) {
        window.$FPROM.trackSignup({
          email: user.email,
        });
      }
    } catch (e: any) {}
  });
};

export const afterSignup = (user: IUser, signupParams: Partial<ISignUpInfo>) => {
  if (user.id) {
    getGtagEvents().accountCreated(user);
    analyticsIdentify(user, signupParams.trigger);
    const parts = user.fullName?.split(' ') ?? [];
    const firstName = (parts[0] || '').trim();
    const lastName = (tail(parts).join(' ') || '').trim();
    getAnalyticsActions().track('Account Created', {
      Status: 'Success',
      Source: 'Growth',
      Trigger: signupParams.trigger ?? '',
      partnerId: user.partnerId,
      NewFlow: 'false',
    });
    fbPixelActions.track(
      'CompleteRegistration',
      {
        'User ID': user.uuid,
        Date: new Date().toISOString(),
        Location: user.iso2,
        Email: user.email,
      },
      `signup-${user.uuid}`,
      {...user, firstName, lastName}
    );
    if (
      signupParams.signupOfferId ||
      signupParams.signupType === UserSignupType.OFFER ||
      user.signupOfferId ||
      user.signupType === UserSignupType.OFFER
    ) {
      if (config.env.gTagEventsId?.completeRegistrationOffer) {
        getGtagActions().track('conversion', {
          send_to: config.env.gTagEventsId.completeRegistrationOffer,
        });
      }
    } else if (
      signupParams.signupLiveEventId ||
      signupParams.signupType === UserSignupType.LIVE_EVENT ||
      user.signupLiveEventId ||
      user.signupType === UserSignupType.LIVE_EVENT
    ) {
      if (config.env.gTagEventsId?.completeRegistrationLiveEvent) {
        getGtagActions().track('conversion', {
          send_to: config.env.gTagEventsId.completeRegistrationLiveEvent,
        });
      }
    } else if (
      signupParams.signupChallengeId ||
      signupParams.signupType === UserSignupType.CHALLENGE ||
      user.signupChallengeId ||
      user.signupType === UserSignupType.CHALLENGE
    ) {
      getAnalyticsActions().track(' Challenge Join', {
        'Challenge Name': signupParams.signupChallengeName || user.signupChallengeName,
      });
      if (config.env.gTagEventsId?.completeRegistrationChallenge) {
        getGtagActions().track('conversion', {
          send_to: config.env.gTagEventsId.completeRegistrationChallenge,
        });
      }
    } else if (
      signupParams.isGiftBuyer ||
      signupParams.isGiftPurchaser ||
      user.isGiftBuyer ||
      user.giftSubscriptionBuyerEmail
    ) {
      if (config.env.gTagEventsId?.completeRegistrationGift) {
        getGtagActions().track('conversion', {
          send_to: config.env.gTagEventsId.completeRegistrationGift,
        });
      }
    } else {
      if (config.env.gTagEventsId?.completeRegistrationPlan) {
        getGtagActions().track('conversion', {
          send_to: config.env.gTagEventsId.completeRegistrationPlan,
        });
      }
    }

    if (signupParams.signupGrowthGroupInstanceId) {
      sendGroupRsvpEvent({
        duration: (signupParams as any).signupGrowthGroupDuration,
        type: (signupParams as any).signupGrowthGroupType,
        channel: (signupParams as any).signupGrowthGroupStyle,
        title: (signupParams as any).signupGrowthGroupTitle,
      } as GrowthGroupInstance);
    }
  }
  fireReferral(user);
};

const afterSignupError = (err: any, dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  if (err?.response?.data?.errorMessage?.indexOf?.('account exists') > 0) {
    dispatch(updateErrorMsg(err?.response?.data?.errorMessage));
  } else {
    dispatch(updateErrorMsg(undefined));
    displayApiError(err, 'Registration failed');
  }
  getAnalyticsActions().track('Account Created', {
    Status: 'Failure',
    Source: 'Growth',
    Reason: err,
  });
};

export const authApiActionCreator = getApiActionCreator<AuthApiActionsType>(AUTH_ACTION_PREFIX);
authApiActionCreator.create({
  loginKeyCloak: authApiActionCreator.thunk<LoginResponse | void, KeyCloakLoginRequest>(
    async ({isSignup, ...payload}, {dispatch}) => {
      try {
        const response = await loginApi.loginKeyCloak(payload);
        if (isSignup) {
          const user = response.user;
          if (user?.id) {
            afterSignup(user, payload);
          }
        } else {
          if (response.user && response.user.id) {
            getAnalyticsActions().identify(response.user.id, getAnalyticsSuperProperties(response.user));
            getAnalyticsActions().track('Login');
          }
        }
        return response;
      } catch (err: any) {
        if (isSignup) {
          afterSignupError(err, dispatch);
        } else {
          displayApiError(err, 'Failed to login');
        }
        throw err;
      }
    }
  ),
  setKeyCloakCookie: authApiActionCreator.thunk(async () => {
    try {
      await loginApi.setKeyCloakCookie();
    } catch (err: any) {}
  }),
  logout: authApiActionCreator.thunk(async (payload, {dispatch}) => {
    try {
      dispatch(resetMediaPlayerState());
      dispatch(unsetSidebarCalendarState());
      getAnalyticsActions().track('Log Out', {});
      getAnalyticsActions().reset();
      await logoutApi.logout();
      resetDatadogSession();
      useChatStore.getState().resetStore();
      useChatMessageStore.getState().resetStore();
      dispatch(resetState());
    } catch (err: any) {
      message.error('Failed to logout');
      throw err;
    }
  }),
  getGrowthCoinTransactions: authApiActionCreator.thunk<CoinsGroupByResponse>(async () => {
    try {
      return await growthDayCoinApi.groupByEvent();
    } catch (err: any) {
      message.error('Failed fetching coins');
      throw err;
    }
  }),
  updateUserInfo: authApiActionCreator.thunk<IUser, IUpdateUser>(async (user) => {
    try {
      const userUpdated = await meApi.update(user);
      if (userUpdated) {
        analyticsIdentify(userUpdated);
        getAnalyticsActions().track('Profile Update', {});
      }
      return userUpdated;
    } catch (err: any) {
      message.error(err.response.statusText || 'Failed to update user, please try again later');
      throw err;
    }
  }),
  updateAddress: authApiActionCreator.thunk<{errorMessage?: string; isSuccess?: boolean}, IUpdateUserAddress>(
    async (userAddress) => {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await meApi.updateAddress(userAddress);
        getAnalyticsActions().traits({
          PhoneNumber: userAddress.phoneNumber,
        });
        getAnalyticsActions().track('Profile Update', {});
        return result;
      } catch (err: any) {
        throw err;
      }
    }
  ),
  processForgotPassword: authApiActionCreator.thunk<void, {email: string; captchaToken: string}>(
    async ({email, captchaToken}) => {
      // eslint-disable-next-line no-useless-catch
      try {
        await userApi.forgotPassword(email, captchaToken);
      } catch (err: any) {
        throw err;
      }
    }
  ),
  getNumCoinsPerEvents: authApiActionCreator.thunk<CoinsScores>(async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      return await growthDayCoinApi.numCoins();
    } catch (err: any) {
      throw err;
    }
  }),
  listTransactions: authApiActionCreator.thunk(async () => {
    try {
      return await transactionsApi.listTransactions();
    } catch (err: any) {
      message.error('Failed to list transactions');
      throw err;
    }
  }),
  updateStripeSubscription: authApiActionCreator.thunk<
    void,
    {
      activeSubscription?: ISubscriptionPlan;
      newSubscription: ISubscriptionPlan;
      updateGiftedSubscription?: boolean;
      campaignSlug?: string;
      user: IUser;
    }
  >(async ({newSubscription, updateGiftedSubscription, campaignSlug, user}, thunkAPI) => {
    let apiUser: IUser;
    let error: any;
    try {
      const state: AppState = thunkAPI.getState() as AppState;
      const triggerInfo = state.auth.triggerInfo;
      let flowId;
      // We need to retrieve flowId from signup/info endpoint if campaignSlug is present
      if (campaignSlug) {
        const campaignData = await campaignApi.getCampaignInfoData(campaignSlug);
        flowId = campaignData?.flowId;
      }
      updateGiftedSubscription
        ? await stripeApi.updateGiftedSubscription(newSubscription.stripePriceId, triggerInfo)
        : await stripeApi.updateSubscription(newSubscription.stripePriceId, triggerInfo, campaignSlug, flowId);
      fireReferral(user);
      return;
    } catch (e: any) {
      error = e;
      apiUser = await meApi.get();
    }

    if (error && apiUser.stripePriceId !== newSubscription.stripePriceId) {
      getAnalyticsActions().track('Plan Purchase Failure', {
        Reason: error,
      });
      throw error;
    }
  }),
  cancelStripeSubscription: authApiActionCreator.thunk(async () => {
    try {
      await stripeApi.cancelStripeSubscription();
    } catch (err: any) {
      message.error('Failed to cancel subscription');
      throw err;
    }
  }),
  register: authApiActionCreator.thunk<LoginResponse, UserRequest>(async (payload, {dispatch}) => {
    try {
      const response = await registerApi.register(payload);
      const user = response.user;
      if (user?.id) {
        afterSignup(user, payload);
      }
      return response;
    } catch (err: any) {
      afterSignupError(err, dispatch);
      throw err;
    }
  }),
  redeemGiftSubscription: authApiActionCreator.thunk<LoginResponse, UserInviteGiftSubscriptionRequest>(
    async (payload, {dispatch}) => {
      try {
        const response = await giftSubscriptionsApi.redeem(payload);
        const user = response.user;
        if (user?.id) {
          afterSignup(user, {isGiftPurchaser: true, trigger: 'Gift Subscription Redeem Onboarding'});
        }
        return response;
      } catch (err: any) {
        afterSignupError(err, dispatch);
        throw err;
      }
    }
  ),
  marketingCampaignSignup: authApiActionCreator.thunk<CampaignSignupResponse, CampaignSignupInfo>(
    async (request, thunkAPI) => {
      // eslint-disable-next-line no-useless-catch
      try {
        const state = thunkAPI.getState() as AppState;
        const triggerInfo = state.auth.triggerInfo;
        return await campaignApi.marketingCampaignSignUp(request, triggerInfo);
      } catch (err: any) {
        throw err;
      }
    }
  ),
  registerPayment: authApiActionCreator.thunk<
    IUser | void,
    UserRequest & IUser & {plan?: ISubscriptionPlan; offers?: IOffersNonNested[]}
  >(async ({plan, ...user}, thunkAPI) => {
    let apiUser: IUser;
    let error: any;
    const state: AppState = thunkAPI.getState() as AppState;
    try {
      const triggerInfo = state.auth.triggerInfo;
      apiUser = await registerApi.registerPayment(user, triggerInfo);
    } catch (e: any) {
      error = e;
      apiUser = await meApi.get();
    }

    if (apiUser.stripeSubscriptionId) {
      getAnalyticsActions().track('Payment Details added', {
        'Gateway type': 'Stripe',
        NewFlow: 'false',
      });
      if (plan && !apiUser.signupOfferId) {
        const isTrial = !(user?.signupPlanId || user?.signupType === UserSignupType.PLAN);
        const fbData = {
          'Plan Type': plan?.name ?? '',
          'Plan Type Term': plan?.interval
            ? plan.interval.toUpperCase() === SubscriptionIntervalsEnum.MONTH.toUpperCase()
              ? 'Monthly'
              : 'Annual'
            : '',
          Revenue: plan?.amount ?? 0,
          Currency: plan?.currency ?? 'USD',
          'Content ID': plan?.id ?? '',
          predicted_ltv: plan?.amount ?? 0,
          content_category: 'plan',
          content_name: plan?.name ?? '',
          content_type: 'product',
          currency: plan?.currency || 'USD',
          value: plan?.amount || 0,
          content_ids: [plan?.id].filter(Boolean),
          'User ID': user?.uuid ?? '',
          Date: new Date().toISOString(),
          Location: user?.iso2 ?? '',
          Email: user?.email ?? '',
        };

        if (isTrial) {
          getAnalyticsActions().track('Start Trial Success', {
            'Plan Name': plan?.name,
            'Plan Type': readablePlanInterval(plan?.interval || SubscriptionIntervalsEnum.YEAR),
            Price: plan?.amount,
          });
          fbPixelActions.track('StartTrial', fbData, `trial-${user.uuid}-${plan.id}`);
          if (config.env.gTagEventsId?.startTrial) {
            getGtagActions().track('conversion', {
              send_to: config.env.gTagEventsId.startTrial,
            });
          }
        } else {
          fbPixelActions.track('Subscriber', fbData, `plan-${user.uuid}-${plan.id}`);
        }

        const offers = state.offers.offers;
        if (offers && user) {
          const offer = offers.find((ofr) => ofr.id === user.signupSocialEventId);
          offer?.events
            ?.filter((event) => event.trigger === EnumSocialEventsTrigger.PAYMENT)
            ?.forEach((socialEvent) => {
              if (socialEvent?.type === EnumSocialEventsType.FACEBOOK && socialEvent?.name) {
                fbPixelActions.track(
                  socialEvent.name,
                  fbData,
                  `${eventNameSlug(socialEvent.name)}-plan-${user.uuid}-${plan.id}`
                );
              }
            });
        }
      }
      return apiUser;
    } else if (error) {
      getAnalyticsActions().track('Start Trial Failure', {
        Reason: error,
      });
      switch (error.status) {
        case 406:
          message.error('Already exists an account with this email!');
          break;
        default: {
          const errorMessage = error.message;
          if (/Stripe/.test(errorMessage)) {
            const [formattedError] = errorMessage.split(';');
            message.error(formattedError);
          } else {
            message.error(errorMessage || 'Registration failed');
          }
        }
      }
      throw error;
    }
  }),
  getAnalytics: authApiActionCreator.thunk<IUserAnalytics>(async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      return await dashboardApi.getAnalytics();
    } catch (err: any) {
      throw err;
    }
  }),
  getOfferBySlug: authApiActionCreator.thunk<IOffersNonNested, string>(async (slug) => {
    // eslint-disable-next-line no-useless-catch
    try {
      return await offersApi.offerBySlug(slug);
    } catch (err: any) {
      throw err;
    }
  }),
  getSignupPageContent: authApiActionCreator.thunk<ISignupPage>(async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      return await registerApi.getSignupPageContent();
    } catch (err: any) {
      throw err;
    }
  }),
  getChallengeByTitleUid: authApiActionCreator.thunk<IStrapiChallenge, string>(async (titleUid) => {
    // eslint-disable-next-line no-useless-catch
    try {
      return await userChallengesApi.getChallengeByTitleUid(titleUid, 'isFree=true');
    } catch (err: any) {
      throw err;
    }
  }),
  getLiveEventByNameUid: authApiActionCreator.thunk<IStrapiLiveEvent, string>(async (nameUid) => {
    // eslint-disable-next-line no-useless-catch
    try {
      return await userLiveEventApi.getLiveEventByNameUid(nameUid, {isFree: true});
    } catch (err: any) {
      throw err;
    }
  }),
  changeEmail: authApiActionCreator.thunk<IUser, string>(async (email) => {
    try {
      const user = await meApi.update({
        email,
      });

      message.success('Email changed successfully!');

      return user;
    } catch (err: any) {
      displayApiError(err);
      throw err;
    }
  }),
  changePassword: authApiActionCreator.thunk<void, PasswordChangeRequest>(async (request) => {
    try {
      await userApi.passwordChange(request);
      message.success('Password changed successfully!');
    } catch (err: any) {
      displayApiError(err, 'Failed changing password');
      throw err;
    }
  }),
  userByInvitation: authApiActionCreator.thunk<void, UserInviteRequest>(async (user) => {
    try {
      await userApi.userSignup(user);
    } catch (err: any) {
      displayApiError(err, 'There was an error while creating the password');
      throw err;
    }
  }),
  updateStripePaymentMethod: authApiActionCreator.thunk<string, {paymentMethodId: string; captchaToken?: string}>(
    async ({paymentMethodId, captchaToken}) => {
      try {
        await stripeApi.updatePaymentMethod(paymentMethodId, captchaToken);
        return paymentMethodId;
      } catch (err: any) {
        message.error(err?.message || 'Failed to update payment method');
        throw err;
      }
    }
  ),
  getStripePaymentMethod: authApiActionCreator.thunk<IPaymentMethod>(async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      return await stripeApi.paymentMethod();
    } catch (err: any) {
      throw err;
    }
  }),
  addStripePaymentMethod: authApiActionCreator.thunk<string, {paymentMethodId: string; captchaToken?: string}>(
    async ({paymentMethodId, captchaToken}) => {
      try {
        await stripeApi.addPaymentMethod(paymentMethodId, captchaToken);
        getAnalyticsActions().track('Payment Details added', {
          'Gateway type': 'Stripe',
          NewFlow: 'false',
        });
        return paymentMethodId;
      } catch (err: any) {
        message.error(err?.message || 'Failed to add payment method');
        throw err;
      }
    }
  ),
  requestDownloadData: authApiActionCreator.thunk<StatusResponse, void>(async () => {
    try {
      return await gdprApi.requestDownloadData();
    } catch (err: any) {
      displayApiError(err);
      throw err;
    }
  }),
  checkDownloadData: authApiActionCreator.thunk<StatusResponse, void>(async () => {
    try {
      return await gdprApi.checkDownloadData();
    } catch (err: any) {
      displayApiError(err);
      throw err;
    }
  }),
  checkEraseStatus: authApiActionCreator.thunk(async () => {
    try {
      await gdprApi.checkEraseStatusData();
    } catch (err: any) {
      displayApiError(err);
      throw err;
    }
  }),
  eraseAllData: authApiActionCreator.thunk(async () => {
    try {
      const response = await gdprApi.requestEraseAllData();
      if (response.eraseToken) {
        await gdprApi.eraseAllData(response.eraseToken!);
        await logoutApi.logout();
        clearStorage();
        window.location.href = '/deleted-account';
      } else {
        message.error('Failed to delete all data! Please try again later');
      }
    } catch (err: any) {
      displayApiError(err);
      throw err;
    }
  }),
});

const authApiActions = authApiActionCreator.apiActions;

export default authApiActions;
