import qs from 'qs';
import {safeJsonParse} from '@growthday/ui-core/src/utils/safe-json';
import {isPlainObject} from 'lodash';

const SOCIAL_STORAGE_KEY = 'social-params';

declare global {
  interface Window {
    isSocial: boolean;
    socialSource: string;
  }
}

export const setSocialParams = () => {
  let storedData = sessionStorage.getItem(SOCIAL_STORAGE_KEY);

  if (!storedData) {
    const queryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    if (queryParams.isSocial) {
      storedData = JSON.stringify({isSocial: true, source: queryParams.source});
      sessionStorage.setItem(SOCIAL_STORAGE_KEY, storedData);
    }
  }

  if (storedData) {
    const obj = safeJsonParse(storedData);
    if (isPlainObject(obj)) {
      window.isSocial = obj.isSocial;
      window.socialSource = obj.source;
    }
  }
};
