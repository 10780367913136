import {Button, message, Modal} from 'antd';
import {ModalProps} from 'antd/es/modal';
import React, {FC, useState} from 'react';
import GDTypography from '../../../../shared/components/ui/Typography/Typography';
import {StripeCardSubmitValue} from '../../hooks/useStripePayment';
import StripeCardForm, {StripeCardProps} from '../StripeCardForm/StripeCardForm';
import styles from './AddCreditCardModal.module.less';

export type AddCreditCardModalProps = ModalProps & StripeCardProps & {};

const AddCreditCardModal: FC<AddCreditCardModalProps> = ({
  onError,
  onSubmit,
  onLoading,
  children,
  initialValues,
  className,
  ...props
}) => {
  const [stripeLoading, setStripeLoading] = useState(false);

  const formProps: StripeCardProps = {
    onError,
    onSubmit,
    onLoading,
    children,
    initialValues,
    className,
  };

  const handleSubmit = async (value: StripeCardSubmitValue) => {
    message.success('Added successfully');
    setTimeout(() => {
      if (formProps.onSubmit) {
        formProps.onSubmit?.(value);
      } else {
        props.onCancel?.({} as any);
      }
    });
  };

  const handleLoading = (loading: boolean) => {
    setStripeLoading(loading);
    formProps.onLoading?.(loading);
  };

  return (
    <Modal {...props} width={480} className={styles.root} footer={null}>
      <GDTypography block type="w800" subType="s" className={styles.title}>
        Add your card details
      </GDTypography>
      <StripeCardForm {...formProps} onSubmit={handleSubmit} onLoading={handleLoading}>
        <Button
          block
          htmlType="submit"
          key="submit"
          type="primary"
          className={styles.submitBtn}
          loading={stripeLoading}
        >
          Add
        </Button>
      </StripeCardForm>
    </Modal>
  );
};

export default AddCreditCardModal;
