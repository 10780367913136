import {useConfig, ConfigResult, DynamicConfig} from 'statsig-react';
import {DynamicConfigEnum} from '../types';

// Keep interface same as mobile
export function useDynamicConfig<T>(name: DynamicConfigEnum): ConfigurationResult<T> {
  return useConfig(name) as ConfigurationResult<T>;
}

export type ConfigurationResult<T> = Omit<ConfigResult, 'config'> & {
  config: DynamicConfiguration<T>;
};

type DynamicConfiguration<T> = Omit<DynamicConfig, 'value'> & {
  value: T;
};
