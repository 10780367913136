/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {EmbeddedReminderRequest} from './embedded-reminder-request';

/**
 *
 * @export
 * @interface PlanCreateRequest
 */
export interface PlanCreateRequest {
  /**
   *
   * @type {string}
   * @memberof PlanCreateRequest
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof PlanCreateRequest
   */
  notes?: string;
  /**
   *
   * @type {string}
   * @memberof PlanCreateRequest
   */
  dueDateTimestamp?: string;
  /**
   *
   * @type {string}
   * @memberof PlanCreateRequest
   */
  type?: PlanCreateRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PlanCreateRequest
   */
  masterPrimaryId?: string;
  /**
   *
   * @type {string}
   * @memberof PlanCreateRequest
   */
  masterSecondaryId?: string;
  /**
   *
   * @type {string}
   * @memberof PlanCreateRequest
   */
  masterOptionalId?: string;
  /**
   *
   * @type {boolean}
   * @memberof PlanCreateRequest
   */
  undo?: boolean;
  /**
   *
   * @type {EmbeddedReminderRequest}
   * @memberof PlanCreateRequest
   */
  reminder?: EmbeddedReminderRequest;
}

export const PlanCreateRequestTypeEnum = {
  Challenge: 'CHALLENGE',
  Journal: 'JOURNAL',
  Goal: 'GOAL',
  Plan: 'PLAN',
} as const;

export type PlanCreateRequestTypeEnum = (typeof PlanCreateRequestTypeEnum)[keyof typeof PlanCreateRequestTypeEnum];
