import {OmitFromUnion} from '@reduxjs/toolkit/dist/query/tsHelpers';
import {QueryDefinition} from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import {BaseQueryFn, FetchArgs, FetchBaseQueryError, FetchBaseQueryMeta} from '@reduxjs/toolkit/query';
import {MutationDefinition} from '@reduxjs/toolkit/dist/query/react';
import {completedPlansTagKey, plansTagKey} from '../features/plans/plans.api';

export type PlanQueryEndpointOptions<QueryArg, ReturnValue> = OmitFromUnion<
  QueryDefinition<
    QueryArg,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>,
    typeof plansTagKey | typeof completedPlansTagKey,
    ReturnValue
  >,
  'type'
>;

export type MutationEndpointOptions<QueryArg, ReturnValue> = OmitFromUnion<
  MutationDefinition<
    QueryArg,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, Record<string, unknown>, FetchBaseQueryMeta>,
    typeof plansTagKey | typeof completedPlansTagKey,
    ReturnValue
  >,
  'type'
>;

// Todo: Move to automatically generated typings

export interface Reminder {
  reminderDate?: string | number | null;
  reminderSent?: boolean | null;
  reminderType?: string | null;
}

// Plan is declared recursively for simplicity.
// For all purposes, a plan can have tasks which can have subtasks - 3 levels of recursion
// Code can be written assuming these three levels of recursion
export interface Plan {
  id: string;
  title: string;
  updateTimestamp?: number | null;
  createTimestamp: number | null;
  deleteTimestamp?: number | null;
  completedDate?: null | string;
  dueDateTimestamp?: null | string;
  tasks?: Plan[] | null;
  reminder?: Reminder | null;
  notes?: string | null;
  order?: string | null;
  complete?: boolean | null;
  uuid?: string;
  notesPhotosIds?: Record<string, any> | null;
  parentID?: string;
}

// Read as = conflict source is ahead/behind conflict target. Update updateTarget? Change conflict source?
export interface DueDateConflictError {
  ahead: boolean;
  conflictSource: PlanTypesSingular;
  conflictTarget: PlanTypesSingular;
}

export function isDueDateConflictError(input: any): input is {data: DueDateConflictError} {
  if (!input || typeof input !== 'object' || !input.data || typeof input.data !== 'object') return false;
  const planTypes = ['plan', 'task', 'subtask'];
  if (typeof input.data.ahead !== 'boolean') return false;
  if (planTypes.includes(input.data.conflictSource) && planTypes.includes(input.data.conflictTarget)) return true;
  return false;
}

// Plans are transformed before storing in map avoid redundancy and have faster processing
export type PlanWithoutNesting = Omit<Plan, 'tasks'> & {
  tasks: string[];
  parentID: string;
};
export type PlanTypesSingular = 'plan' | 'task' | 'subtask' | 'goal' | 'milestone';
export type PlanTypes = `${PlanTypesSingular}s`;
// Todo: Add type guards to validate data coming from backend and throw error if inconsistent
export type FlattenedPlans = {records: Record<string, PlanWithoutNesting>} & {
  plansArr: {tasks: string[]};
};

export type CompletedPlansResponse = {count: number; list: Plan[]};
export type CompletedPlansInput = {page?: number; size?: number};
export type BulkSubtaskUpsertInput = {
  subtasks: Array<
    | (Pick<Plan, 'id'> & Partial<Pick<Plan, 'title' | 'dueDateTimestamp' | 'notes'>>)
    | (Pick<Plan, 'title'> & Partial<Pick<Plan, 'notes' | 'dueDateTimestamp'>>)
  >;
} & Pick<PlanWithoutNesting, 'parentID'>;

export type CompleteAndRecoverInput = Pick<Plan, 'id'> & {
  complete: NonNullable<Plan['complete']>;
  parentPlanID?: PlanWithoutNesting['parentID'];
};
export type CreatePlanInput = Pick<Plan, 'title'> &
  Partial<Pick<Plan, 'dueDateTimestamp' | 'notes' | 'reminder'>> & {
    type?: 'CHALLENGE';
    masterPrimaryId?: string;
    masterSecondaryId?: number;
    masterOptionalId?: number;
  } & {undo?: boolean};
export type CreateTaskInput = CreatePlanInput & Pick<PlanWithoutNesting, 'parentID'>;
export type CreateSubTaskInput = CreateTaskInput;
export type UpdatePlanInput = Pick<Plan, 'id'> &
  Partial<Pick<Plan, 'title' | 'dueDateTimestamp' | 'notes' | 'reminder' | 'order'>>;
export type DeleteTaskInput = {
  id: Plan['id'];
  parentID: PlanWithoutNesting['parentID'];
};
export type CompleteAndRecoverResponse = {
  isPlan: boolean;
  complete: boolean;
};
type NonNullableRequired<T extends Record<string, any>> = {
  [K in keyof T]-?: NonNullable<T[K]>;
};

export type ResolveDueDateInput = NonNullableRequired<Pick<Plan, 'id' | 'dueDateTimestamp'>>;

// Todo: add constraints and validation functions
// Constraints on plan
export const PlanMaxTitleLength = 150;
