import {memoize} from './memoizeWithShallowCompare';

export function safeJsonParseWithoutMemoize<T = any>(value?: string | null): T | null {
  if (value === null) {
    return null;
  }
  try {
    return JSON.parse(value ?? '');
  } catch (e) {
    return value as unknown as T;
  }
}

export const safeJsonParse = memoize(safeJsonParseWithoutMemoize);

export function safeJsonStringifyWithoutMemoize<T = any>(value: T): string {
  try {
    return JSON.stringify(value);
  } catch (e) {
    return value as unknown as string;
  }
}

export const safeJsonStringify = memoize(safeJsonStringifyWithoutMemoize);
