import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {OnboardingLoadingState, OnboardingState, OnboardingStep} from './interfaces';
import {stateWithLoading} from '../../shared/redux/util/state-with-loading';
import {getApiActionBuilder} from '../../shared/redux/util/build-api-action';
import * as apiActions from './onboarding.actions';
import _unset from 'lodash/unset';
import coerceArray from '../../shared/util/coerceArray';

const initialState = stateWithLoading<OnboardingState, OnboardingLoadingState>({
  steps: [],
});

export const onboardingSlice = createSlice<
  OnboardingState,
  {
    unsetOnboardingState: CaseReducer<OnboardingState, PayloadAction<string | string[]>>;
  }
>({
  name: 'onboarding',
  initialState,
  reducers: {
    unsetOnboardingState(state, action) {
      const keys = coerceArray(action.payload);
      keys.forEach((key) => {
        _unset(state, key);
      });
    },
  },
  extraReducers: (builder) => {
    const buildApiAction = getApiActionBuilder<OnboardingState>(builder, apiActions);

    buildApiAction<PayloadAction<OnboardingStep[]>>('getOnboardingSteps', {
      fulfilled: (state, action) => {
        state.steps = action.payload;
      },
    });
  },
});

export const {unsetOnboardingState} = onboardingSlice.actions;

export default onboardingSlice.reducer;
