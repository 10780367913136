import {ILifeScoresRecommendation} from '../../../features/life-scores/interfaces';
import {SidebarCalendarEntriesBE} from '../../../features/sidebar-calendar/interface';
import {ScoreType} from '../../components/life-scores/interfaces';
import {LifeScoresMonthHistory} from '../../services_deprecated/model/lifeScoreHistory';
import {HistoryCollectionType} from '../../util/userConstants';
import {axiosGrowthDay} from '../index';

export const lifescoreApi = {
  chartData: (fromDate: string, type: string, habit?: string) => {
    return axiosGrowthDay
      .get<LifeScoresMonthHistory[]>(
        `/lifeScores/monthlyChart/${encodeURIComponent(String(fromDate))}/${encodeURIComponent(String(type))}/${
          habit ?? 'none'
        }`
      )
      .then((res) => res.data);
  },
  milestones: (fromDate: string, toDate: string) => {
    return axiosGrowthDay
      .get<SidebarCalendarEntriesBE[]>(
        `/lifeScores/milestones/${encodeURIComponent(String(fromDate))}/${encodeURIComponent(String(toDate))}`
      )
      .then((res) => res.data);
  },
  markAsInteracted: (type: ScoreType, recommendationId: number) => {
    return axiosGrowthDay.put(`/lifeScores/recommendation/${type}/${recommendationId}`).then((res) => res.data);
  },
  getAllLifeScoreRecommendation: (type: ScoreType) => {
    return axiosGrowthDay
      .get<ILifeScoresRecommendation[]>(`/lifeScores/allRecommendation/${type}`)
      .then((res) => res.data);
  },
  getLifeScoreRecommendation: (type?: ScoreType | HistoryCollectionType | string) => {
    return axiosGrowthDay
      .get<ILifeScoresRecommendation[]>(`/lifeScores/recommendation${type ? `/${type}` : ''}`)
      .then((res) => res.data);
  },
  getLifeScores: (habitType: string, filter: number) => {
    return axiosGrowthDay.get(`/lifeScores/${habitType}/${filter}`).then((res) => res.data);
  },
};
