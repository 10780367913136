import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import {Breakpoint, responsiveArray, ScreenMap} from 'antd/lib/_util/responsiveObserve';
import debounce from 'lodash/debounce';
import {useEffect, useMemo, useState} from 'react';

export type GDBreakpoint = Breakpoint | 'xxxl';
export type GDScreenMap = ScreenMap & {xxxl?: boolean};

export const gdResponsiveArray: GDBreakpoint[] = ['xxxl', ...responsiveArray];

export const sortGDBreakpoints = (breakpoints: GDBreakpoint[], order?: 'asc' | 'desc') =>
  breakpoints.slice().sort((a, b) => {
    const aIndex = gdResponsiveArray.indexOf(a);
    const bIndex = gdResponsiveArray.indexOf(b);
    return order === 'desc' ? aIndex - bIndex : bIndex - aIndex;
  });

const checkSize = () => window.innerWidth > 2048;

const useGDBreakpoint = (): GDScreenMap => {
  const [xxxl, setXxxl] = useState(checkSize);
  const breakpoint = useBreakpoint();
  useEffect(() => {
    const callback = debounce(() => setXxxl(checkSize), 300);
    window.addEventListener('resize', callback);
    return () => window.removeEventListener('resize', callback);
  }, []);
  return useMemo(() => ({...breakpoint, xxxl, xs: true}), [breakpoint, xxxl]);
};

export default useGDBreakpoint;
