/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {CmsMedia} from './cms-media';
// May contain unused imports in some cases
// @ts-ignore
import {UserCourseNoteResponse} from './user-course-note-response';

/**
 *
 * @export
 * @interface NoteData
 */
export interface NoteData {
  /**
   *
   * @type {string}
   * @memberof NoteData
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof NoteData
   */
  noteCategory?: NoteDataNoteCategoryEnum;
  /**
   *
   * @type {number}
   * @memberof NoteData
   */
  updatedTime?: number;
  /**
   *
   * @type {string}
   * @memberof NoteData
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof NoteData
   */
  titleUid?: string;
  /**
   *
   * @type {number}
   * @memberof NoteData
   */
  topicId?: number;
  /**
   *
   * @type {CmsMedia}
   * @memberof NoteData
   */
  background?: CmsMedia;
  /**
   *
   * @type {string}
   * @memberof NoteData
   */
  noteContent?: string;
  /**
   *
   * @type {boolean}
   * @memberof NoteData
   */
  pinned?: boolean;
  /**
   *
   * @type {string}
   * @memberof NoteData
   */
  courseTitleUid?: string;
  /**
   *
   * @type {number}
   * @memberof NoteData
   */
  courseId?: number;
  /**
   *
   * @type {string}
   * @memberof NoteData
   */
  videoId?: string;
  /**
   *
   * @type {Array<UserCourseNoteResponse>}
   * @memberof NoteData
   */
  notes?: Array<UserCourseNoteResponse>;
  /**
   *
   * @type {string}
   * @memberof NoteData
   */
  vimeoChatUrl?: string;
  /**
   *
   * @type {string}
   * @memberof NoteData
   */
  vimeoUrl?: string;
  /**
   *
   * @type {string}
   * @memberof NoteData
   */
  wistiaId?: string;
  /**
   *
   * @type {string}
   * @memberof NoteData
   */
  wistiaUrl?: string;
  /**
   *
   * @type {CmsMedia}
   * @memberof NoteData
   */
  customIcon?: CmsMedia;
}

export const NoteDataNoteCategoryEnum = {
  Live: 'LIVE',
  Challenge: 'CHALLENGE',
  Lesson: 'LESSON',
  GrowthGroup: 'GROWTH_GROUP',
} as const;

export type NoteDataNoteCategoryEnum = (typeof NoteDataNoteCategoryEnum)[keyof typeof NoteDataNoteCategoryEnum];
