import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from '../../utils/api';
import {IReminder, IReminderRequestFilter} from '../../types/reminders';
import {remindersApiReducerPath, remindersEndpoint, reminderTags} from './reminders.constant';
import urlJoin from 'proper-url-join';

export const remindersApi = createApi({
  reducerPath: remindersApiReducerPath,
  tagTypes: [reminderTags.type],
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getReminders: build.query<IReminder[], IReminderRequestFilter | void | undefined>({
      query: (arg) =>
        urlJoin(remindersEndpoint, arg?.type ? `type/${arg.type}` : '', arg?.entityId ? `entity/${arg.entityId}` : ''),
      providesTags: (result, _, _args) => [
        ...(result?.map(({id}) => reminderTags.detail(id)) ?? []),
        reminderTags.list(),
      ],
    }),
    getReminder: build.query<IReminder, string>({
      query: (id) => `${remindersEndpoint}/${id}`,
      providesTags: (result, error, id) => [reminderTags.detail(id)],
      // async onQueryStarted(arg, api) {
      //   const { data } = await api.queryFulfilled;
      //   api.dispatch(
      //     remindersApi.util.updateQueryData('getReminders', undefined, (draft) => {
      //       const index = draft?.findIndex((reminder) => reminder.id === data.id) ?? -1;
      //       if (index > -1) {
      //         draft[index] = data;
      //       }
      //     })
      //   );
      // },
    }),
    addReminder: build.mutation<IReminder, Partial<IReminder>>({
      query: (body) => ({
        url: remindersEndpoint,
        method: 'POST',
        body,
      }),
      // async onQueryStarted(arg, api) {
      //   const { data } = await api.queryFulfilled;
      //   api.dispatch(
      //     remindersApi.util.updateQueryData('getReminders', undefined, (draft) => {
      //       draft?.push(data);
      //     })
      //   );
      //   api.dispatch(
      //     remindersApi.util.updateQueryData('getReminder', data.id, () => {
      //       return data;
      //     })
      //   );
      // },
      invalidatesTags: (result) => [reminderTags.list(), ...(result ? [reminderTags.detail(result.id)] : [])],
    }),
    deleteReminder: build.mutation<void, string>({
      query: (id) => ({
        url: `${remindersEndpoint}/${id}`,
        method: 'DELETE',
      }),
      // async onQueryStarted(id, api) {
      //   const patch = api.dispatch(
      //     remindersApi.util.updateQueryData('getReminders', undefined, (draft) => {
      //       return draft?.filter((reminder) => reminder.id === id) ?? [];
      //     })
      //   );
      //   api.queryFulfilled.catch(() => {
      //     patch.undo();
      //   });
      // },
      // invalidatesTags: (result, error, id) => [reminderTags.detail(id)],
      invalidatesTags: (result, error, id) => [reminderTags.list(), reminderTags.detail(id)],
    }),
    updateReminder: build.mutation<IReminder, Partial<IReminder>>({
      query: (body) => ({
        url: `${remindersEndpoint}/${body.id}`,
        method: 'PUT',
        body,
      }),
      // async onQueryStarted(arg, api) {
      //   const patch = api.dispatch(
      //     remindersApi.util.updateQueryData('getReminders', undefined, (draft) => {
      //       const index = draft?.findIndex((reminder) => reminder.id === arg.id) ?? -1;
      //       if (index > -1) {
      //         draft[index] = arg;
      //       }
      //     })
      //   );
      //   const patch2 = api.dispatch(
      //     remindersApi.util.updateQueryData('getReminder', arg.id, () => {
      //       return arg;
      //     })
      //   );
      //   try {
      //     await api.queryFulfilled;
      //   } catch {
      //     patch.undo();
      //     patch2.undo();
      //   }
      // },
      invalidatesTags: (result) => [reminderTags.list(), ...(result ? [reminderTags.detail(result.id)] : [])],
    }),
    updateActivation: build.mutation<IReminder, Partial<IReminder>>({
      query: (body) => ({
        url: `${remindersEndpoint}/state/${body.id}/${body.active}`,
        method: 'PUT',
      }),
      invalidatesTags: (result) => [reminderTags.list(), ...(result ? [reminderTags.detail(result.id)] : [])],
    }),
  }),
});
