import {axiosGrowthDay} from '../index';
import {CoinsGroupByResponse} from './interfaces';

export const growthDayCoinApi = {
  numCoins: () => {
    return axiosGrowthDay.get('/growthDayCoin/numCoins').then((res) => res.data);
  },
  maxCoinsPerDay: () => {
    return axiosGrowthDay.get('/growthDayCoin/maxCoins').then((res) => res.data);
  },
  groupByEvent: () => {
    return axiosGrowthDay.get<CoinsGroupByResponse>('/growthcoin/group-by-event').then((res) => res.data);
  },
};
