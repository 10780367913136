import {loadStripe} from '@stripe/stripe-js';
import config from '@/config';
import {sortBy} from 'lodash';

export type StripeIntent = {
  intentId: string;
  itemStrapiId: string;
  intentType: 'setup' | 'payment';
  clientSecret: string;
  stripePaymentId: string;
  type: 'plan' | 'offer';
  confirmed?: boolean;
};

/**
 * Confirms a Stripe intent (either a setup or payment intent).
 *
 * @param stripe - The Stripe object used to confirm the intent.
 * @param intent - The Stripe intent to confirm.
 * @returns A promise that resolves once the intent is confirmed, with the intent's confirmation status updated.
 */
async function confirmIntent(stripe: any, intent: StripeIntent) {
  const confirmMethod = intent.intentType === 'setup' ? stripe.confirmSetup : stripe.confirmPayment;

  try {
    const {error} = await confirmMethod({
      elements: undefined,
      clientSecret: intent.clientSecret,
      confirmParams: {
        return_url: `${window.location.origin}`,
      },
      redirect: 'if_required',
    });

    intent.confirmed = !error;
  } catch (e) {
    intent.confirmed = false;
  }
}

/**
 * Confirms a list of Stripe intents by first loading the Stripe object and then confirming each intent in order.
 *
 * Intents of type 'plan' are given priority in the confirmation process.
 *
 * @param intents - An array of Stripe intents to confirm.
 * @returns A promise that resolves to an array of confirmed intents.
 */
export default async function stripeConfirmIntent(intents: StripeIntent[]) {
  const stripe = await loadStripe(config.env.stripePublishKey);
  const sortedIntents = sortBy(intents, [(item) => item.type !== 'plan']);

  if (stripe) {
    for (const intent of sortedIntents) {
      await confirmIntent(stripe, intent);
      if (!intent.confirmed) break;
    }
  }

  return sortedIntents.filter((intent) => intent.confirmed);
}
