/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Reminders for goal but not in use
 * @export
 * @interface EmbeddedReminderRequest
 */
export interface EmbeddedReminderRequest {
  /**
   *
   * @type {string}
   * @memberof EmbeddedReminderRequest
   */
  reminderType?: EmbeddedReminderRequestReminderTypeEnum;
}

export const EmbeddedReminderRequestReminderTypeEnum = {
  AWeekBefore: 'A week before',
  _2DaysBefore: '2 days before',
  _1DayBefore: '1 day before',
  OnDueDate: 'On due date',
} as const;

export type EmbeddedReminderRequestReminderTypeEnum =
  (typeof EmbeddedReminderRequestReminderTypeEnum)[keyof typeof EmbeddedReminderRequestReminderTypeEnum];
