import config from '@/config';
import {HTMLAttributeAnchorTarget} from 'react';

/**
 * Validates whether a given string is a well-formed URL.
 *
 * This function uses a regular expression to test the provided string against common URL patterns.
 * It supports various formats, including URLs with or without protocols (e.g., http://, https://)
 * and subdomains. The function returns a boolean indicating if the provided string conforms
 * to standard URL formats.
 *
 * @param url - The string to be validated as a URL.
 * @returns True if the string is a valid URL format, false otherwise.
 */
export function isValidURL(url: string): boolean {
  const urlPattern = new RegExp(
    /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/i
  );
  return urlPattern.test(url);
}

/**
 * Determines the full protocol of a URL.
 *
 * This function checks if a given URL is valid and if it lacks a protocol (http or https).
 * If the URL is valid but lacks a protocol, it prepends the current window location's protocol
 * to the URL. Otherwise, it returns the URL as-is.
 *
 * @param inputUrl - The URL string to process.
 * @returns The processed URL string with the protocol determined.
 */
function determineProtocol(inputUrl: string): string {
  const urlWithoutProtocol = isValidURL(inputUrl) && !inputUrl.startsWith('http');
  return urlWithoutProtocol ? `${window.location.protocol}//${inputUrl}` : inputUrl;
}

/**
 * Safely composes a URL object from a given string.
 *
 * Attempts to create a URL object using the provided string. If the string is not a valid URL,
 * it catches the error and returns a new URL object with an empty string, effectively creating
 * an empty URL.
 *
 * @param url - The URL string to be converted into a URL object.
 * @returns A URL object.
 */
function composeURL(url: string): URL {
  try {
    return new URL(url, window.location.origin);
  } catch (_) {
    // Create a URL to current window origin
    return new URL('', window.location.origin);
  }
}

/**
 * Decomposes a URL into its components, determining its locality and target attributes.
 *
 * Analyzes a given URL to extract its components, such as path, and determines whether it's
 * local to the application or an external link. It also sets the appropriate target attribute
 * for the URL, which can be optionally specified. The function handles special protocols
 * (e.g., mailto:, tel:) and normalizes protocol-relative URLs.
 *
 * @param inputUrl - The URL to decompose. Defaults to an empty string if not provided.
 * @param inputTarget - Optional. The target attribute for the anchor element (e.g., '_blank', '_self').
 * @returns An object containing properties: isLocal (boolean), target (string),
 *          path (string), and href (string).
 */
export function decomposeUrl(inputUrl: string = '', inputTarget?: HTMLAttributeAnchorTarget) {
  try {
    const shareOrigin = new URL(config.env.shareUrl).origin;
    const origin = window.location.origin;

    // Special protocols
    const specialProtocols = ['mailto:', 'tel:', 'data:', 'blob:', 'javascript:', 'ftp:', 'file:'];
    if (specialProtocols.some((protocol) => inputUrl.startsWith(protocol))) {
      return {isLocal: false, target: '_blank', path: inputUrl, href: inputUrl};
    }

    // Special URL parts
    const specialUrlParts = ['?', '#'];
    if (specialUrlParts.some((parts) => inputUrl.startsWith(parts))) {
      return {isLocal: true, target: '_self', path: inputUrl, href: inputUrl};
    }

    let href = determineProtocol(inputUrl);

    if (href.startsWith(shareOrigin)) {
      href = href.replace(shareOrigin, origin);
    }

    const url = composeURL(href);

    const isLocal = url.origin === origin || url.origin === shareOrigin;
    const path = url.pathname + url.search + url.hash;

    return {
      isLocal,
      target: inputTarget || (isLocal ? '_self' : '_blank'),
      path,
      href: url.href,
    };
  } catch {
    // Send the default URL in case of an error
    return {
      isLocal: false,
      target: '_blank',
      path: '',
      href: window.origin,
    };
  }
}
