import {AchievementsQueryEndpointOptions} from '../../../types/achievements';
import {IAchievements, IChallenges} from '../../../types/strapi';
import {axiosStrapiBaseQuery} from '../../../utils/api';
import {keyBy, uniq} from 'lodash';

export const getStrapiAchievementsOptions: AchievementsQueryEndpointOptions<number[], IAchievements[]> = {
  queryFn: async (ids, api, extraOptions) => {
    const idString = uniq(ids)
      .map((id) => `id_in=${id}`)
      .join('&');
    const achievementsResponse = idString.length
      ? await axiosStrapiBaseQuery<IAchievements[]>()(`achievements?${idString}`, api, extraOptions)
      : {data: []};
    if (achievementsResponse.error) {
      return achievementsResponse;
    }

    const challengeIDs: number[] = [];
    for (const entry of achievementsResponse.data) {
      for (const e of entry.type) {
        if (e.__component === 'achievements.achievement-challenge') {
          e.challenge?.id && challengeIDs.push(e.challenge.id);
        }
      }
    }

    if (!challengeIDs.length) {
      return achievementsResponse;
    }
    const challengeIdString = uniq(challengeIDs)
      .map((id) => `id_in=${id}`)
      .join('&');
    const challengesResponse = challengeIdString.length
      ? await axiosStrapiBaseQuery<IChallenges[]>()(`challenges?${challengeIdString}`, api, extraOptions)
      : {data: []};

    if (challengesResponse.error) {
      return {error: challengesResponse.error};
    }

    const challengeMap = keyBy(challengesResponse.data, (e) => e.id);
    achievementsResponse.data = achievementsResponse.data.map((entry) => {
      return {
        ...entry,
        type: entry.type.map((e) => {
          if (e.__component === 'achievements.achievement-challenge' && e.challenge) {
            return {...e, challenge: challengeMap[e.challenge.id]};
          }
          return e;
        }),
      };
    });
    return achievementsResponse;
  },
};
