/*
 * This file was automatically generated.
 * DO NOT EDIT THIS FILE DIRECTLY.
 * To Update:
 * Add STATSIG_API_KEY to the env
 * and then Run `yarn fetch-statsig-config`
 */

export enum FeatureGateEnum {
  StrapiCoursesLimitedFields = 'strapi_courses_limited_fields',
  TeamsV2 = 'teams_v2',
  BodiSubscriptionUpgrade = 'bodi_subscription_upgrade',
  ChimeChatOneClickUpgrade = 'chime_chat_one_click_upgrade',
  KeynoteUpsellOverlay = 'keynote_upsell_overlay',
  GrowthGroupPaywallSkipStripeConnect = 'growth_group_paywall_skip_stripe_connect',
  MobileInappBrowserUpgrade = 'mobile_inapp_browser_upgrade',
  OptimizedUpsellFlow = 'optimized_upsell_flow',
  SearchChallenges = 'search_challenges',
  SearchLinks = 'search_links',
  DatadogWebsocketSessions = 'datadog_websocket_sessions',
  AuditTransactionRenewType = 'audit_transaction_renew_type',
  TeamWebhook = 'team_webhook',
  StreakReminderNotification = 'streak_reminder_notification',
  ElrondTest = 'elrond_test',
  ElrondFeatureGateTest = 'elrond_feature_gate_test',
  RestrictAiBotOnLicense = 'restrict_ai_bot_on_license',
  RestrictCommunityOnLicense = 'restrict_community_on_license',
  DailyFireReminderNotification = 'daily_fire_reminder_notification',
  UniversalSearch = 'universal_search',
  Teams = 'teams',
  ChimeChatBanSender = 'chime_chat_ban_sender',
  KeynoteChimeChatNewPinnedUx = 'keynote_chime_chat_new_pinned_ux',
  OnboardingReturnLatestFlow = 'onboarding_return_latest_flow',
  ChimeChatRichText = 'chime_chat_rich_text',
  SearchEvents = 'search_events',
  SearchGrowthGroup = 'search_growth_group',
  SearchCourses = 'search_courses',
  WelcomeScreenRedirectionWithoutOnboarding = 'welcome_screen_redirection_without_onboarding',
  StrapiScalingMinimal = 'strapi_scaling_minimal',
  FullPageEditGgScheduler = 'full_page_edit_gg_scheduler',
  SearchIndexStrapi = 'search_index_strapi',
  WebLoginDeeplink = 'web_login_deeplink',
  StripeDefaultPaymentMethodFix = 'stripe_default_payment_method_fix',
  OnboardingWelcomeVideoImprovements = 'onboarding_welcome_video_improvements',
  NewLoggedUsersBar = 'new_logged_users_bar',
  OnboardingValidateWelcomeVideoContent = 'onboarding_validate_welcome_video_content',
  MobileAppCrashSimulate = 'mobile_app_crash_simulate',
  GrowthGroupAppsync = 'growth_group_appsync',
  FullPageNewGgScheduler = 'full_page_new_gg_scheduler',
  NewLoginPage = 'new_login_page',
  RefactorWebhook = 'refactor_webhook',
  KeynoteV2Enabled = 'keynote_v2_enabled',
  SwitchToRudderstack = 'switch_to_rudderstack',
  OnboardingLiteUserUpgrade = 'onboarding_lite_user_upgrade',
  SkipAppleIdValidation = 'skip_apple_id_validation',
  NewBuyFlow = 'new_buy_flow',
  PushNotificationPrimer = 'push_notification_primer',
  NewCampaignFlow = 'new_campaign_flow',
  SignupAppLogout = 'signup_app_logout',
  NewResetPasswordFlow = 'new_reset_password_flow',
  GrowthGroupHostTransferPreviousAmount = 'growth_group_host_transfer_previous_amount',
  BodiIntegrationApi = 'bodi_integration_api',
  GrowthGroupRecordAutoStart = 'growth_group_record_auto_start',
  HyvorV3 = 'hyvor_v3',
  KeynoteChimeAvatar = 'keynote_chime_avatar',
  LiteUserConfiguration = 'lite_user_configuration',
  KeynotesHyvorChat = 'keynotes_hyvor_chat',
  BodiIntegration = 'bodi_integration',
  VideojsPlayer = 'videojs_player',
  OptimizedOnboardingFlow = 'optimized_onboarding_flow',
  CacheStrapiDataInRedis = 'cache_strapi_data_in_redis',
  NewSignupFlow = 'new_signup_flow',
  Firstpromoter = 'firstpromoter',
  Gtag = 'gtag',
  FacebookPixel = 'facebook_pixel',
  Mixpanel = 'mixpanel',
  MonthlyGiftGivingEmail = 'monthly_gift_giving_email',
  GrowthGroupPaywall = 'growth_group_paywall',
  GoogleRecaptcha = 'google_recaptcha',
  CloudfrontImageCompression = 'cloudfront_image_compression',
  CancelFlowV1 = 'cancel_flow_v1',
  GrowthGroupChimeChat = 'growth_group_chime_chat',
  GrowthGroupSessionStore = 'growth_group_session_store',
  GrowthGroupAudioShare = 'growth_group_audio_share',
  AnalyticsUseRudderstackForMixpanel = 'analytics_use_rudderstack_for_mixpanel',
  AnalyticsUseRudderstackForGtag = 'analytics_use_rudderstack_for_gtag',
  GrowthGroupDownloadRecording = 'growth_group_download_recording',
  AnalyticsUseDatadogUserMonitoring = 'analytics_use_datadog_user_monitoring',
  GrowthGroupPinUnpin = 'growth_group_pin_unpin',
  GrowthGroupMicroservice = 'growth_group_microservice',
  StrapiV4 = 'strapi_v4',
  GrowthGroupNerdStats = 'growth_group_nerd_stats',
  GrowthGroupHd = 'growth_group_hd',
  GrowthGroupRecording = 'growth_group_recording',
  GrowthGroupHls = 'growth_group_hls',
  VideoCourseUpsellToMastery = 'video_course_upsell_to_mastery',
  KeynoteOneClickUpgrade = 'keynote_one_click_upgrade',
  ReplayOneClickUpgrade = 'replay_one_click_upgrade',
  GrowthGroupCreateOneClickUpgrade = 'growth_group_create_one_click_upgrade',
  GrowthGroupOneClickUpgrade = 'growth_group_one_click_upgrade',
  SCA_FLow = 'sca_flow',
}

export enum ExperimentEnum {
  StreakReminder = 'streak_reminder',
  DailyFireReminder = 'daily_fire_reminder',
  AllAccessAnnualCampaignFlow = 'all-access-annual_campaign_flow',
  ProMonthlyCampaignFlow = 'pro-monthly_campaign_flow',
  PmcCampaignFlow = 'pmc_campaign_flow',
  CancelFlowExperiment = 'cancel_flow_experiment',
}

export enum DynamicConfigEnum {
  Elrond = 'elrond',
  ElrondMobile = 'elrond_mobile',
  TeamsConfig = 'teams_config',
  BlockedRegistrationDomains = 'blocked_registration_domains',
  SearchConfig = 'search_config',
  NotificationsConfig = 'notifications_config',
  AppConfigV3 = 'app_config_v3',
  Strapicollections = 'strapicollections',
  AiBotConfig = 'ai_bot_config',
  SignupConfig = 'signup_config',
  HeroCarouselConfig = 'hero_carousel_config',
  PushPrimerIntervalsConfig = 'push_primer_intervals_config',
  OptimizedOnboarding = 'optimized_onboarding',
  CancelFlowScenario = 'cancel_flow_scenario',
  NavbarMenuItemsConfig = 'navbar_menu_items_config',
  GgKeynoteChatSmoothScroll = 'gg_keynote_chat_smooth_scroll',
  WorkshopConfig = 'workshop_config',
  FirstpromoterConfig = 'firstpromoter_config',
  GtagConfig = 'gtag_config',
  MixpanelConfig = 'mixpanel_config',
  FacebookPixelConfig = 'facebook_pixel_config',
  EmbedOptInConfig = 'embed_opt-in_config',
  SharedConstants = 'shared_constants',
  GoogleRecaptchaConfig = '_google_recaptcha_config',
  UiAwsCredentials = 'ui_aws_credentials',
  BlockedDomains = 'blocked_domains',
  CloudfrontImageCompressionConfig = 'cloudfront_image_compression_config',
  ContentProgressReportInterval = 'content_progress_report_interval',
  AnalyticsRudderstack = 'analytics_rudderstack',
  AnalyticsDatadogRumConfig = 'analytics_datadog_rum_config',
  GrowthGroups = 'growth_groups',
  GrowthGroupConstants = 'growth_group_constants',
}

export interface ElrondDynamicConfig {
  rudderstackDataPlaneUrl: string;
  rudderstackiOSWriteKey: string;
  rudderstackAndroidWriteKey: string;
  rudderstackWebWriteKey: string;
  datadogAppIDiOS: string;
  datadogClientTokeniOS: string;
  datadogAppIDAndroid: string;
  datadogClientTokenAndroid: string;
}

export interface ElrondMobileDynamicConfig {
  rudderstackDataPlaneUrl: string;
  rudderstackWriteKey: string;
}

export interface TeamsConfigDynamicConfig {
  bulkUploadLimit: number;
  teamsDefaultCourse: string;
  minimumSeats: number;
}

export interface BlockedRegistrationDomainsDynamicConfig {
  domains: string[];
}

export interface SearchConfigDynamicConfig {
  collections: Collections;
  fields: Fields;
  collectionsEmptyQuery: CollectionsEmptyQuery;
  fuzzy: Fuzzy;
}

interface Fuzzy {
  fuzziness: string;
}

interface CollectionsEmptyQuery {
  course: number;
  'search-link': number;
}

interface Fields {
  title: number;
  description: number;
  contentTag: number;
  coach: number;
}

interface Collections {
  challenge: number;
  course: number;
  'course-lesson': number;
  event: number;
  'search-link': number;
}

export interface NotificationsConfigDynamicConfig {
  dailyFirePlaybackThresholdPercentage: number;
  dailyFireReminderImageUrl: string;
  keynoteRsvpNotificationEnabled: boolean;
  keynoteRsvpNotificationSendBefore: number;
  workshopRsvpNotificationEnabled: boolean;
  workshopRsvpNotificationSendBefore: number;
  workshopKeynoteReminderNotificationEnabled: boolean;
}

export interface AppConfigV3DynamicConfig {
  ui: Ui;
  metadata: Metadata;
  images: Images;
}

interface Images {
  background: string;
  logo: string;
}

interface Metadata {
  base: string;
  favicon: string;
  appleIcon: string;
  socialImage: string;
  appleAppId: string;
}

interface Ui {
  primaryColor: string;
  secondaryColor: string;
  successColor: string;
  fontFamily: string;
}

export interface StrapicollectionsDynamicConfig {
  strapiNoCacheModelList: string[];
  strapiPublicModelList: string[];
}

export interface AiBotConfigDynamicConfig {
  bots: Bot[];
}

interface Bot {
  name: string;
  url: string;
}

export interface SignupConfigDynamicConfig {
  signupUrl: string;
  loginQueryParam: boolean;
}

export interface HeroCarouselConfigDynamicConfig {
  autoplay: boolean;
  autoplaySpeed: number;
}

export interface PushPrimerIntervalsConfigDynamicConfig {
  days: number[];
  rec_days: number;
  rec_mins: number;
}

export interface OptimizedOnboardingDynamicConfig {
  defaultFlowId: number;
  intentsOnlyFlowId: number;
  userTypeSurveyId: number;
  onboardingFlowId: number;
}

export interface CancelFlowScenarioDynamicConfig {
  scenarioId: number;
}

export interface NavbarMenuItemsConfigDynamicConfig {
  menuItems: MenuItem[];
}

interface MenuItem {
  href: string;
  icon: string;
  id: string;
  title: string;
  url: string;
  type: string;
  iframeAttributes: IframeAttributes;
}

interface IframeAttributes {
  allow: string;
}

export interface GgKeynoteChatSmoothScrollDynamicConfig {
  maxRSVPCount: number;
}

export interface WorkshopConfigDynamicConfig {
  redirectToEventTimeInMins: number;
}

export interface FirstpromoterConfigDynamicConfig {
  firstPromoterId: string;
  firstPromoterDomain: string;
}

export interface GtagConfigDynamicConfig {
  gTagEventsId: GTagEventsId;
  gTagId: string;
}

interface GTagEventsId {
  completeRegistrationPlan: string;
  completeRegistrationOffer: string;
  completeRegistrationChallenge: string;
  completeRegistrationGift: string;
  startTrial: string;
  subscribe: string;
}

export interface MixpanelConfigDynamicConfig {
  mixPanelToken: string;
}

export interface FacebookPixelConfigDynamicConfig {
  fbPixelId: string;
}

export interface EmbedOptInConfigDynamicConfig {
  strapiUrl: string;
  strapiV4Url: string;
  apiUrl: string;
  appUrl: string;
  signAppUrl: string;
}

export interface SharedConstantsDynamicConfig {
  ui: Ui;
}

interface Ui {
  subscriptionPlan: SubscriptionPlan;
}

interface SubscriptionPlan {
  showYearlyPlanOnly: boolean;
}

export interface GoogleRecaptchaConfigDynamicConfig {
  recaptchaKey: string;
}

export interface UiAwsCredentialsDynamicConfig {
  AWS_ACCESS_KEY_ID: string;
  AWS_SECRET_ACCESS_KEY: string;
  AWS_REGION: string;
}

export interface BlockedDomainsDynamicConfig {
  domains: string[];
}

export interface CloudfrontImageCompressionConfigDynamicConfig {
  defaultFormat: string;
  defaultQuality: number;
  defaultCompressionRatio: number;
  defaultExts: string[];
  rewriteUrls: RewriteUrl[];
}

interface RewriteUrl {
  imageS3Url: string;
  imageCloudfrontUrl: string;
}

export interface ContentProgressReportIntervalDynamicConfig {
  interval: number;
}

export interface AnalyticsRudderstackDynamicConfig {
  rudderStackKey: string;
  rudderStackUrl: string;
  rudderStackLoadOptions: RudderStackLoadOptions;
  methods: string[];
}

interface RudderStackLoadOptions {
  dataPlaneUrl: string;
  flushQueueSize: number;
  sleepTimeOut: number;
  trackAppLifecycleEvents: boolean;
}

export interface AnalyticsDatadogRumConfigDynamicConfig {
  applicationId: string;
  clientToken: string;
  site: string;
  service: string;
  env: string;
  sessionSampleRate: number;
  traceSampleRate: number;
  telemetrySampleRate: number;
  sessionReplaySampleRate: number;
  trackUserInteractions: boolean;
  trackFrustrations: boolean;
  trackResources: boolean;
  trackLongTasks: boolean;
  defaultPrivacyLevel: string;
  allowedTracingUrls: string[];
  enableExperimentalFeatures: string[];
  batchSize: string;
  firstPartyHosts: any[];
  initializationMode: string;
  longTaskThresholdMs: number;
  nativeCrashReportEnabled: boolean;
  nativeInteractionTracking: boolean;
  nativeLongTaskThresholdMs: number;
  nativeViewTracking: boolean;
  proxyConfig: string;
  resourceTracingSamplingRate: number;
  serviceName: string;
  sessionSamplingRate: number;
  trackBackgroundEvents: boolean;
  trackErrors: boolean;
  trackInteractions: boolean;
  trackingConsent: string;
  uploadFrequency: string;
  verbosity: string;
  vitalsUpdateFrequency: string;
}

export interface GrowthGroupsDynamicConfig {
  hlsLimit: number;
}

export interface GrowthGroupConstantsDynamicConfig {
  cancelMeetingLimit: number;
  startsSoonLimit: number;
  bufferForUpdateChatMinutes: number;
  maxCoHostPerInstance: number;
  maxSpeakerPerInstance: number;
  maxInstanceLimit: number;
  bufferOfStartMinutes: number;
  minWorkshopDuration: number;
  maxWorkshopDuration: number;
  schedulerWindowMins: number;
  rsvpEmailSendLimit: number;
  topParticipantLimit: number;
  participantLimitForFreeGroups: number;
  instanceLimitForProAndAllAccessUser: number;
  instanceLimitForStarterUser: number;
  instanceLimitForTrialUser: number;
}

export interface DynamicConfig {
  elrond: ElrondDynamicConfig;
  elrondMobile: ElrondMobileDynamicConfig;
  teamsConfig: TeamsConfigDynamicConfig;
  blockedRegistrationDomains: BlockedRegistrationDomainsDynamicConfig;
  searchConfig: SearchConfigDynamicConfig;
  notificationsConfig: NotificationsConfigDynamicConfig;
  appConfigV3: AppConfigV3DynamicConfig;
  strapicollections: StrapicollectionsDynamicConfig;
  aiBotConfig: AiBotConfigDynamicConfig;
  signupConfig: SignupConfigDynamicConfig;
  heroCarouselConfig: HeroCarouselConfigDynamicConfig;
  pushPrimerIntervalsConfig: PushPrimerIntervalsConfigDynamicConfig;
  optimizedOnboarding: OptimizedOnboardingDynamicConfig;
  cancelFlowScenario: CancelFlowScenarioDynamicConfig;
  navbarMenuItemsConfig: NavbarMenuItemsConfigDynamicConfig;
  ggKeynoteChatSmoothScroll: GgKeynoteChatSmoothScrollDynamicConfig;
  workshopConfig: WorkshopConfigDynamicConfig;
  firstpromoterConfig: FirstpromoterConfigDynamicConfig;
  gtagConfig: GtagConfigDynamicConfig;
  mixpanelConfig: MixpanelConfigDynamicConfig;
  facebookPixelConfig: FacebookPixelConfigDynamicConfig;
  embedOptInConfig: EmbedOptInConfigDynamicConfig;
  sharedConstants: SharedConstantsDynamicConfig;
  googleRecaptchaConfig: GoogleRecaptchaConfigDynamicConfig;
  uiAwsCredentials: UiAwsCredentialsDynamicConfig;
  blockedDomains: BlockedDomainsDynamicConfig;
  cloudfrontImageCompressionConfig: CloudfrontImageCompressionConfigDynamicConfig;
  contentProgressReportInterval: ContentProgressReportIntervalDynamicConfig;
  analyticsRudderstack: AnalyticsRudderstackDynamicConfig;
  analyticsDatadogRumConfig: AnalyticsDatadogRumConfigDynamicConfig;
  growthGroups: GrowthGroupsDynamicConfig;
  growthGroupConstants: GrowthGroupConstantsDynamicConfig;
}
