import {isEqual, omitBy, values} from 'lodash';
import qs from 'qs';
import {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

export function SanitizeSearchParams() {
  const history = useHistory();
  const {search, pathname} = useLocation();

  useEffect(() => {
    const ignoreList = ['/search'];
    if (search && !ignoreList.includes(pathname)) {
      const searchParams = qs.parse(search, {ignoreQueryPrefix: true});
      let updatedSearchParams = {};

      if (values(searchParams).length) {
        updatedSearchParams = omitBy(searchParams, (value) => typeof value === 'string' && value?.includes?.('*'));
      }

      if (!isEqual(searchParams, updatedSearchParams)) {
        history.replace({search: qs.stringify(updatedSearchParams, {addQueryPrefix: true})});
      }
    }
  }, [history, pathname, search]);

  return null;
}
