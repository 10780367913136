import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  IGrowthCoinTableEntry,
  IPaymentMethod,
  IProfileLoadingState,
  IProfileState,
  IUserAnalytics,
  SocialParams,
  TriggerInfo,
} from './interfaces';
import {stateWithLoading} from '../../shared/redux/util/state-with-loading';
import {resetState} from '../../shared/redux/actions';
import {getAccessToken, setAccessToken} from './routes/Profile/utils';
import {getApiActionBuilder} from '../../shared/redux/util/api-action-builder';
import {LoginResponse} from '../../shared/services_deprecated/model/loginResponse';
import {CoinsScores} from '../../shared/util/userConstants';
import {NumeratedSubscriptionLevels} from './enums';
import storage from 'redux-persist/lib/storage/session';
import {persistReducer} from 'redux-persist';
import {StatusGDPREnum, StatusResponse} from '../../shared/api/gdpr/interfaces';
import authApiActions from './auth.actions';
import {CoinsGroupByResponse} from '../../shared/api/growthDayCoin/interfaces';
import {IStrapiChallenge} from '../challenges/interfaces';
import {ISignupPage} from '@growthday/ui-core/src/types/strapi';
import {IStrapiLiveEvent} from '../../shared/services_deprecated/model/strapi/live-event';
import {OrganizationResponse, PaymentTransaction} from '@growthday/ui-core/src/types/api';
import {getAnalyticsActions} from '@/features/analytics/hooks/useAnalyticsActions';
import {setRefreshAccessToken} from './routes/Profile/utils/refreshToken';
import {setExpireTime} from './routes/Profile/utils/expireTime';
import {IOffersNonNested} from '@growthday/ui-core/src/types/offers';

const persistConfig = {
  key: 'hpx:auth',
  storage: storage,
  whitelist: ['authRedirectUrl', 'socialParams'],
};

const initialState = stateWithLoading<IProfileState, IProfileLoadingState>({
  growthCoinTransactions: [],
  accessToken: getAccessToken(),
  userTransactions: [],
  downloadInProgress: false,
});

export const authReducer = createSlice<
  IProfileState,
  {
    setUserState: CaseReducer<IProfileState, PayloadAction<LoginResponse>>;
    setNoMembershipModalShown: CaseReducer<IProfileState>;
    setOrganization: CaseReducer<IProfileState, PayloadAction<OrganizationResponse>>;
    setSocialParams: CaseReducer<IProfileState, PayloadAction<SocialParams | undefined>>;
    updateAuthRedirectUrl: CaseReducer<IProfileState, PayloadAction<string | null | undefined>>;
    toggleSubscriptionUpgradeModal: CaseReducer<IProfileState, PayloadAction<NumeratedSubscriptionLevels | undefined>>;
    toggleTriggerInfo: CaseReducer<IProfileState, PayloadAction<TriggerInfo>>;
    toggleOffersModal: CaseReducer<IProfileState, PayloadAction<IProfileState['offerModalState'] | undefined>>;
    updateErrorMsg: CaseReducer<IProfileState, PayloadAction<string | null | undefined>>;
    setLoggedOut: CaseReducer<IProfileState, PayloadAction<boolean>>;
  }
>({
  name: 'auth',
  initialState,
  reducers: {
    setUserState(state, action) {
      const {authenticationToken, refreshToken, expireTimeInUtc} = action.payload;
      if (authenticationToken) {
        state.accessToken = authenticationToken;
        setAccessToken(authenticationToken);
      }
      if (refreshToken) {
        setRefreshAccessToken(refreshToken);
      }
      if (expireTimeInUtc) {
        setExpireTime(expireTimeInUtc.toString());
      }
    },
    setNoMembershipModalShown(state) {
      state.noMembershipModalShown = true;
    },
    setSocialParams(state, action) {
      state.socialParams = action.payload;
    },
    updateErrorMsg(state, action) {
      state.errorMsg = action.payload;
    },
    updateAuthRedirectUrl(state, action) {
      state.authRedirectUrl = action.payload;
    },
    toggleSubscriptionUpgradeModal(state, action) {
      state.subscriptionUpgradeRequired = action.payload;
    },
    toggleTriggerInfo(state, action) {
      state.triggerInfo = action.payload;
    },
    toggleOffersModal(state, action) {
      state.offerModalState = action.payload;
    },
    setOrganization(state, action) {
      state.organization = action.payload;
      if (state.organization) {
        getAnalyticsActions().traits({
          'Organization Name': state.organization.name,
          'Organization Id': state.organization.organizationId,
        });
      }
    },
    setLoggedOut(state, action) {
      state.loggedOut = action.payload;
    },
  },
  extraReducers: (builder) => {
    const apiBuilder = getApiActionBuilder<IProfileState>(builder, authApiActions);

    apiBuilder.build<PayloadAction<CoinsGroupByResponse>>(authApiActions.getGrowthCoinTransactions, {
      fulfilled: (state, action) => {
        const transactionsMap = new Map<string, IGrowthCoinTableEntry>();
        if (action.payload.totalCoins && action.payload.totalCoins > 0) {
          action.payload.list?.forEach((event, index) => {
            transactionsMap.set(event.event!, {
              event: event.event!,
              frequency: event.frequency!,
              growthCoins: event.growthCoins!,
              activity: event.event!,
              key: index.toString(),
            });
          });
        }
        state.growthCoinTransactions = Array.from(transactionsMap.values());
      },
    });

    apiBuilder.build<PayloadAction<ISignupPage>>(authApiActions.getSignupPageContent, {
      fulfilled: (state, action) => {
        state.signupPageContent = action.payload;
      },
    });

    apiBuilder.build<PayloadAction<IPaymentMethod>>(authApiActions.getStripePaymentMethod, {
      fulfilled: (state, action) => {
        state.paymentMethod = action.payload;
      },
    });

    apiBuilder.build<PayloadAction<IOffersNonNested>>(authApiActions.getOfferBySlug, {
      fulfilled: (state, action) => {
        if (action.payload) {
          state.signupOffer = action.payload;
        }
      },
    });

    apiBuilder.build<PayloadAction<IStrapiChallenge>>(authApiActions.getChallengeByTitleUid, {
      fulfilled: (state, action) => {
        if (action.payload) {
          state.signupChallenge = action.payload;
        }
      },
    });

    apiBuilder.build<PayloadAction<IStrapiLiveEvent>>(authApiActions.getLiveEventByNameUid, {
      fulfilled: (state, action) => {
        if (action.payload) {
          state.signupLiveEvent = action.payload;
        }
      },
    });

    apiBuilder.build<PayloadAction<LoginResponse | null>>(
      [authApiActions.register, authApiActions.redeemGiftSubscription, authApiActions.loginKeyCloak],
      {
        fulfilled: (state, action) => {
          if (action.payload) {
            const {authenticationToken, refreshToken, expireTimeInUtc} = action.payload;
            if (authenticationToken) {
              state.accessToken = authenticationToken;
              setAccessToken(authenticationToken);
            }
            if (refreshToken) {
              setRefreshAccessToken(refreshToken);
            }
            if (expireTimeInUtc) {
              setExpireTime(expireTimeInUtc.toString());
            }
          }
        },
      }
    );

    apiBuilder.build<PayloadAction<LoginResponse | null>>([authApiActions.marketingCampaignSignup], {
      fulfilled: (state, action) => {
        if (action.payload) {
          const {authenticationToken, refreshToken, expireTimeInUtc} = action.payload;
          if (authenticationToken) {
            setAccessToken(authenticationToken);
          }
          if (refreshToken) {
            setRefreshAccessToken(refreshToken);
          }
          if (expireTimeInUtc) {
            setExpireTime(expireTimeInUtc.toString());
          }
        }
      },
    });

    apiBuilder.build<PayloadAction<CoinsScores>>(authApiActions.getNumCoinsPerEvents, {
      fulfilled: (state, action) => {
        state.coinScores = action.payload;
      },
    });

    apiBuilder.build<PayloadAction<PaymentTransaction[]>>(authApiActions.listTransactions, {
      fulfilled: (state, action) => {
        state.userTransactions = action.payload;
      },
    });

    apiBuilder.build<PayloadAction<IUserAnalytics>>(authApiActions.getAnalytics, {
      fulfilled: (state, action) => {
        state.analytics = action.payload;
      },
    });

    apiBuilder.build<PayloadAction<StatusResponse>>(authApiActions.requestDownloadData, {
      fulfilled: (state, action) => {
        state.downloadInProgress = action.payload.status === StatusGDPREnum.SUBMITTED;
      },
    });

    apiBuilder.build<PayloadAction<StatusResponse>>(authApiActions.checkDownloadData, {
      fulfilled: (state, action) => {
        state.downloadInProgress = action.payload.status === StatusGDPREnum.SUBMITTED;
      },
    });

    apiBuilder.defaults();

    builder.addCase(resetState, () => {
      return {
        ...initialState,
        accessToken: undefined,
        loggedOut: true,
      };
    });
  },
});

export const {
  setOrganization,
  setUserState,
  toggleSubscriptionUpgradeModal,
  toggleOffersModal,
  toggleTriggerInfo,
  updateAuthRedirectUrl,
  updateErrorMsg,
  setSocialParams,
  setNoMembershipModalShown,
  setLoggedOut,
} = authReducer.actions;

export default persistReducer(persistConfig, authReducer.reducer);
