import Form from 'antd/es/form';
import classNames from 'classnames';
import {omit} from 'lodash';
import React, {ReactNode, useEffect, useMemo} from 'react';
import useCountryState from '@growthday/ui-core/src/hooks/useCountryState';
import GDInput from '../../../../shared/components/ui/Form/Input';
import GDCountryInput from '../../../../shared/components/ui/Form/Input/CountryInput';
import GDPhoneInput from '../../../../shared/components/ui/Form/Input/PhoneInput';
import GDStateInput from '../../../../shared/components/ui/Form/Input/StateInput';
import GDFormItem from '../../../../shared/components/ui/Form/Item';
import useDefaultRegion from '../../../../shared/hooks/useDefaultRegion';
import useForceUpdate from '../../../../shared/hooks/useForceUpdate';
import {authValidations} from '../../../auth/validations';
import useStripePayment, {StripeCardAddValue, StripeCardSubmitValue} from '../../hooks/useStripePayment';
import CardElement from '../CardElement/CardElement';
import styles from './StripeCardForm.module.less';
import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';

export type StripeCardProps<T extends StripeCardSubmitValue = StripeCardSubmitValue> = {
  onSubmit?: (values: T) => void | Promise<void>;
  onLoading?: (loading: boolean) => void;
  onError?: (error: any) => void;
  children?: ReactNode;
  initialValues?: any;
  className?: string;
  skipUpdatingPaymentMethod?: boolean;
  skipRegionFields?: boolean;
  id?: string;
};

function StripeCardForm<T extends StripeCardSubmitValue = StripeCardSubmitValue>({
  onSubmit,
  children,
  onLoading,
  initialValues = {},
  className,
  onError,
  skipUpdatingPaymentMethod,
  skipRegionFields,
  id,
}: StripeCardProps<T>) {
  const forceUpdate = useForceUpdate();
  const {user} = useUser();
  const {addPaymentMethod} = useStripePayment();
  const {data, isLoading} = useDefaultRegion();
  const [form] = Form.useForm<StripeCardAddValue>();
  const {getStates} = useCountryState();

  const canSkipRegionFields = Boolean(skipRegionFields && user?.automaticTaxEnabled);

  const handleSubmit = async (value: StripeCardAddValue) => {
    try {
      onLoading?.(true);
      const result = await addPaymentMethod(
        {...omit(initialValues, 'region'), ...value},
        skipUpdatingPaymentMethod,
        canSkipRegionFields
      );
      await onSubmit?.(result as unknown as T);
      onLoading?.(false);
    } catch (e: any) {
      onError?.(e);
      onLoading?.(false);
    }
  };

  const formInitialValues = {...user, ...initialValues};
  const country = form.getFieldValue('country') || formInitialValues.country;
  const states = useMemo(() => getStates(country), [country, getStates]);

  const handleCountryChange = () => {
    form.setFieldsValue({
      region: '',
    });
    forceUpdate();
  };

  useEffect(() => {
    if (data) {
      const toUpdate = {
        ...(!form.getFieldValue('country') && data.country ? {country: data.country} : {}),
        ...(!form.getFieldValue('region') && data.region ? {region: data.region} : {}),
      };
      if (Object.keys(toUpdate).length) {
        form.setFieldsValue(toUpdate);
        forceUpdate();
      }
    }
  }, [forceUpdate, data, form]);

  return (
    <Form
      id={id}
      className={classNames(className, styles.form)}
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      scrollToFirstError
      requiredMark={false}
      initialValues={formInitialValues}
    >
      <GDFormItem
        label="Name on card"
        name="fullName"
        rules={[authValidations.fullNameRequired, authValidations.fullNameValid]}
      >
        <GDInput size="large" placeholder="Enter your name as it appears on your card" />
      </GDFormItem>
      <GDFormItem label="Card details" name="card" rules={[authValidations.cardRequired]}>
        <CardElement />
      </GDFormItem>
      {!canSkipRegionFields && (
        <>
          <GDFormItem label="Country" name="country" rules={[authValidations.countryRequired]}>
            <GDCountryInput onChange={handleCountryChange} />
          </GDFormItem>
          {states.length > 0 && (
            <GDFormItem label="State" name="region" rules={[authValidations.stateRequired]}>
              <GDStateInput country={country} />
            </GDFormItem>
          )}
          <GDFormItem
            label="ZIP/postcode"
            name="zipCode"
            rules={[authValidations.zipCodeRequired, authValidations.zipCodeValid]}
          >
            <GDInput />
          </GDFormItem>
        </>
      )}
      {!isLoading && (
        <GDFormItem
          label="Phone number"
          name="phoneNumber"
          rules={[authValidations.phoneRequired, authValidations.phoneValid]}
        >
          <GDPhoneInput
            disableCountryDropdown
            country={country}
            defaultCountry={formInitialValues.iso2 || data?.country}
          />
        </GDFormItem>
      )}
      {children}
    </Form>
  );
}

export default StripeCardForm;
