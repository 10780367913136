/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ReminderRequest
 */
export interface ReminderRequest {
  /**
   *
   * @type {string}
   * @memberof ReminderRequest
   */
  until?: string;
  /**
   *
   * @type {string}
   * @memberof ReminderRequest
   */
  startTime?: string;
  /**
   *
   * @type {string}
   * @memberof ReminderRequest
   */
  endTime?: string;
  /**
   *
   * @type {string}
   * @memberof ReminderRequest
   */
  rrule: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ReminderRequest
   */
  mediums: Array<ReminderRequestMediumsEnum>;
  /**
   *
   * @type {string}
   * @memberof ReminderRequest
   */
  type: ReminderRequestTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ReminderRequest
   */
  header?: string;
  /**
   *
   * @type {string}
   * @memberof ReminderRequest
   */
  body: string;
  /**
   *
   * @type {string}
   * @memberof ReminderRequest
   */
  entityId?: string;
  /**
   *
   * @type {boolean}
   * @memberof ReminderRequest
   */
  active?: boolean;
}

export const ReminderRequestMediumsEnum = {
  InApp: 'IN_APP',
  Push: 'PUSH',
} as const;

export type ReminderRequestMediumsEnum = (typeof ReminderRequestMediumsEnum)[keyof typeof ReminderRequestMediumsEnum];
export const ReminderRequestTypeEnum = {
  PlanSubTask: 'PLAN_SUB_TASK',
  PlanAction: 'PLAN_ACTION',
  PlanActionInsideChallenge: 'PLAN_ACTION_INSIDE_CHALLENGE',
  Plan: 'PLAN',
  PlanInsideChallenge: 'PLAN_INSIDE_CHALLENGE',
  Custom: 'CUSTOM',
} as const;

export type ReminderRequestTypeEnum = (typeof ReminderRequestTypeEnum)[keyof typeof ReminderRequestTypeEnum];
