import i18n, {InitOptions} from 'i18next';
import {initReactI18next} from 'react-i18next';
import backend from 'i18next-http-backend';

/**
 * Determines if the application is running in a development environment.
 */
const isDevelopmentEnv = process.env.NODE_ENV === 'development';

/**
 * Represents the default configuration options for the i18next library.
 */
export const defaultI18nextOptions: InitOptions = {
  backend: {
    loadPath: '/translations/{{lng}}/{{ns}}.json',
    addPath: '/translations/add/{{lng}}/{{ns}}',
  },

  saveMissing: isDevelopmentEnv,
  saveMissingTo: 'all',

  fallbackLng: 'en',
  supportedLngs: ['en'],
  lng: 'en',
  lowerCaseLng: true,

  debug: isDevelopmentEnv,
  load: 'currentOnly',

  // TODO: To consider preloading translations file or to lazy load on demand
  // defaultNS: 'common',
  // fallbackNS: 'common',
  // ns: namespaces,
  defaultNS: false,
  ns: [],

  interpolation: {
    escapeValue: false,
  },
};

/**
 * Initializes and configures the i18next instance for internationalization in a React application.
 *
 * - Utilizes `i18next-http-backend` for loading translation resources.
 * - Integrates with `react-i18next` for seamless integration with React components.
 * - Configures backend paths for loading and adding translations.
 * - Enables saving of missing translations in development environment.
 * - Sets up language and namespace defaults and fallbacks.
 * - Configures interpolation settings.
 *
 * The configuration is especially tailored for development, with features like debug logging and
 * saving missing translations enabled only in development mode.
 */
i18n.use(backend).use(initReactI18next).init(defaultI18nextOptions);

export {i18n};
