import {Action} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import {ThunkAction} from 'redux-thunk';
import {useMemo} from 'react';

interface ThunkDispatch<TBasicAction extends Action> {
  <TReturnType, TState, TExtraThunkArg>(
    thunkAction: ThunkAction<TReturnType, TState, TExtraThunkArg, TBasicAction>
  ): TReturnType;
}

const useCustomDispatch = <TBasicAction extends Action = any>() => {
  const dispatch = useDispatch();

  return useMemo(
    () => ({
      dispatch,
      asyncDispatch: dispatch as ThunkDispatch<TBasicAction>,
    }),
    [dispatch]
  );
};

export default useCustomDispatch;
