import {useInfiniteQuery, UseInfiniteQueryOptions} from 'react-query';
import {
  GrowthGroupFilters,
  GrowthGroupInstancePaginatedResponse,
  GrowthGroupQueryKey,
} from '../../../types/growth-groups';
import {axiosGrowthDay} from '../../../utils/api';
import {makeFilterSearchParams} from '../util/makeFilterSearchParams';
import {useWebSocket} from '../../../hooks/useWebSocket';
import {GG_Create_Topic} from '../../../Provider/WebSocketProvider/topics';

export const ExploreQueryKey = [GrowthGroupQueryKey, 'Explore'] as const;
export const ExploreQueryKeyWithFilters = (filters?: GrowthGroupFilters) =>
  [...ExploreQueryKey, filters ?? {}] as const;
const defaultQueryKey = ExploreQueryKeyWithFilters({});

export function useGetExploreGroups(
  options: Omit<
    UseInfiniteQueryOptions<
      GrowthGroupInstancePaginatedResponse,
      unknown,
      GrowthGroupInstancePaginatedResponse,
      GrowthGroupInstancePaginatedResponse,
      typeof defaultQueryKey
    >,
    'queryFn' | 'queryKey'
  > & {filters?: GrowthGroupFilters; enableWebsocket?: boolean} = {}
) {
  const {filters, enableWebsocket, ...rest} = options;
  const queryKeyWithFilters = ExploreQueryKeyWithFilters(filters);

  const query = useInfiniteQuery(
    queryKeyWithFilters,
    async ({pageParam = 1}) => {
      const result = await axiosGrowthDay('/groups/explore', {
        params: {
          limit: 24,
          offset: pageParam,
          isParticipant: false,
          type: 'PUBLIC',
          sortBy: 'startTime',
          order: 'asc',
          ...makeFilterSearchParams(filters),
        },
      });
      const data = result.data as Omit<GrowthGroupInstancePaginatedResponse, '__currentCountForPagination'>;
      // Data might be filtered to remove cancelled values, use current count for pagination
      return {
        ...data,
        __currentCountForPagination: data.growthGroupInstanceList?.length ?? 0,
      } as GrowthGroupInstancePaginatedResponse;
    },
    {
      getNextPageParam(lastPage, pages) {
        const loaded = pages.reduce((prev, current) => prev + current.__currentCountForPagination, 0);
        const total = lastPage.count ?? 0;

        return loaded < total ? pages.length + 1 : undefined;
      },
      keepPreviousData: true,
      staleTime: 0,
      ...rest,
    }
  );

  const updateExploreGroups = () => {
    query.refetch();
  };

  useWebSocket(['GROWTH_GROUP', 'EXPLORE', GG_Create_Topic], GG_Create_Topic, updateExploreGroups, enableWebsocket);

  return query;
}
