import {useQuery} from 'react-query';
import {UseQueryOptions} from 'react-query';
import {axiosStrapi} from '../utils/api';
import {useAuthData} from '../hooks/useAuthData';
import {QueryKey} from 'react-query/types/core/types';
import qs from 'qs';
import {StrapiNestedObjectPaths} from '../types/utility/strapiNestedObject';

/**
 * Type of nested paths of a strapi collection that can be sent for query
 * Adjusts for array fields
 */
export type PartialPopulationPaths<T> = ReadonlyArray<StrapiNestedObjectPaths<T>>;

/**
 * Generates the React Query function key for a partially populated query.
 */
export function getPartiallyPopulatedQueryFnKey<K>(collectionName: string, id: number | undefined, fieldKeys: K) {
  return ['GROWTHDAY', 'QUERY', 'DEEP_POPULATED_QUERY', collectionName, id, {fieldKeys}] as QueryKey;
}

/**
 * Builds the query parameters for a partially populated query.
 * If a single length array is passed, we request all relations to be populated to 1 level
 * Otherwise the list of fields sent is requested is sent directly
 */
export function buildPartiallyPopulateQuery<T>(fieldKeys: PartialPopulationPaths<T>) {
  const queryParams: Record<string, any> = {
    fields: ['id'],
    populate: {},
  };
  if (fieldKeys.length > 1) {
    queryParams.populate = fieldKeys;
  } else {
    fieldKeys.forEach((fieldKey) => {
      queryParams.populate[fieldKey] = {populate: '*'};
    });
  }

  return qs.stringify(queryParams, {
    encodeValuesOnly: true,
  });
}

/**
 * Fetches data for a deep-populated query.
 */
export async function fetchPartiallyPopulatedQuery<T>(
  collectionName: string,
  id: number,
  fieldKeys: PartialPopulationPaths<T>
) {
  const res = await axiosStrapi.get<T>(`/${collectionName}/${id}?${buildPartiallyPopulateQuery(fieldKeys)}`);
  return res.data;
}

/**
 * Custom React Query hook for using partially populated queries.
 * Should be invoked for one field (and subfields if needed)
 * By default, populates relations to one level deep. If that does not work, request subfields separately (Strapi limitation)
 * To keep things simple, we assume return type is as per strapi
 * fields outside the query will be undefined
 *
 * ```typescript
 *
 *   const {data: offerTooltips} = usePartiallyPopulatedCollection<IOffers>(
 *     'offers',
 *     offerForTooltip.id,
 *     ['tooltips', 'tooltips.items', 'tooltips.items.image'],
 *   );
 *
 *   // Return offer.tooltips with items and items.image populated
 *
 *   const {data: offerNestedData} = usePartiallyPopulatedCollection<IOffers>('offers', offer?.id ?? 0, ['bulletItems'], {
 *     enabled: Boolean(offer?.id),
 *   });
 *
 *  // Return offer.bulletItems with nested objects upto first level (using * query). offer.bulletItems.course is present but offer.bulletItems.course.image is missing
 * ```
 */
export function usePartiallyPopulatedCollection<T>(
  collectionName: string,
  id: number | undefined,
  fieldKeys: PartialPopulationPaths<T>,
  options?: Omit<UseQueryOptions<T, unknown, T>, 'queryKey' | 'queryFn'>
) {
  const {isLoggedIn} = useAuthData();
  return useQuery(
    getPartiallyPopulatedQueryFnKey(collectionName, id, fieldKeys),
    () => fetchPartiallyPopulatedQuery<T>(collectionName, id!, fieldKeys),
    {
      ...options,
      enabled: isLoggedIn && !!id && options?.enabled,
    }
  );
}
