import {IOffers, IOnboarding, IOnboardingUpsells, ISubscriptionPlans, ITooltips} from './strapi';
import {NestedObjectByDepth} from './utility/nestedObjectByDepth';

export const DEFAULT_SUBSCRIPTION_PLANS_DEPTH = 2;

export type ISubscriptionPlansNonNested = Omit<
  ISubscriptionPlans,
  'freeOffers' | 'orderBump' | 'onboardingUpsells' | 'onboarding' | 'tooltips'
> & {
  freeOffers?: NestedObjectByDepth<IOffers, 1>[];
  orderBump?: NestedObjectByDepth<IOffers, 1>;
  onboarding?: NestedObjectByDepth<IOnboarding, 1>;
  onboardingUpsells?: NestedObjectByDepth<IOnboardingUpsells, 1>[];
  tooltips?: NestedObjectByDepth<ITooltips, 1>[];
};
