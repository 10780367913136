/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface UserSubscriptionPlanUpdateRequest
 */
export interface UserSubscriptionPlanUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof UserSubscriptionPlanUpdateRequest
   */
  email?: string;
  /**
   *
   * @type {number}
   * @memberof UserSubscriptionPlanUpdateRequest
   */
  planId?: number;
  /**
   *
   * @type {string}
   * @memberof UserSubscriptionPlanUpdateRequest
   */
  planFrequency?: UserSubscriptionPlanUpdateRequestPlanFrequencyEnum;
  /**
   *
   * @type {string}
   * @memberof UserSubscriptionPlanUpdateRequest
   */
  paymentSystem?: UserSubscriptionPlanUpdateRequestPaymentSystemEnum;
  /**
   *
   * @type {string}
   * @memberof UserSubscriptionPlanUpdateRequest
   */
  subscriptionExpiryDate?: string;
}

export const UserSubscriptionPlanUpdateRequestPlanFrequencyEnum = {
  Month: 'MONTH',
  Year: 'YEAR',
} as const;

export type UserSubscriptionPlanUpdateRequestPlanFrequencyEnum =
  (typeof UserSubscriptionPlanUpdateRequestPlanFrequencyEnum)[keyof typeof UserSubscriptionPlanUpdateRequestPlanFrequencyEnum];
export const UserSubscriptionPlanUpdateRequestPaymentSystemEnum = {
  Card: 'CARD',
  Paypal: 'PAYPAL',
  Apple: 'APPLE',
} as const;

export type UserSubscriptionPlanUpdateRequestPaymentSystemEnum =
  (typeof UserSubscriptionPlanUpdateRequestPaymentSystemEnum)[keyof typeof UserSubscriptionPlanUpdateRequestPaymentSystemEnum];
