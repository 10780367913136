import {IAssessmentCategories} from './assessment-categories';
import {ICourseLessons} from './course-lessons';
import {IFile} from './file';

/**
 * Model definition for improvement-recommendation
 */
export interface IImprovementRecommendations {
  id: number;
  improvementTypeOrder?: number;
  improvementType?: EnumImprovementRecommendationsImprovementType;
  improvementTypeLink?: string;
  course_lesson?: ICourseLessons;
  improvementDescription?: string;
  improvementTitle?: string;
  wistiaLink?: string;
  wistiaThumbnail?: IFile;
  assessment_categories?: IAssessmentCategories[];
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumImprovementRecommendationsImprovementType {
  Video = 'Video',
  Audio = 'Audio',
  Text = 'Text',
}
