import {useQuery} from 'react-query';
import {UseQueryOptions} from 'react-query/types/react/types';
import {OrganizationResponse, PagedResultOrganizationResponse} from '../../../types/api';
import {axiosGrowthDay} from '../../../utils/api';

export const ORGANIZATION_QUERY_KEY = ['GROWTHDAY', 'QUERY', 'ORGANIZATION'];

const useOrganizationQuery = (
  options: Omit<
    UseQueryOptions<OrganizationResponse, unknown, OrganizationResponse, typeof ORGANIZATION_QUERY_KEY>,
    'queryKey' | 'queryFn'
  > = {}
) => {
  return useQuery(
    ORGANIZATION_QUERY_KEY,
    () =>
      axiosGrowthDay
        .get<PagedResultOrganizationResponse>('/organizations/me')
        .then((res) => res.data.results?.[0] as OrganizationResponse),
    options
  );
};

export default useOrganizationQuery;
