/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {CmsMedia} from './cms-media';
// May contain unused imports in some cases
// @ts-ignore
import {CmsShareContent} from './cms-share-content';

/**
 *
 * @export
 * @interface Achievement
 */
export interface Achievement {
  /**
   *
   * @type {string}
   * @memberof Achievement
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof Achievement
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof Achievement
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof Achievement
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof Achievement
   */
  uuid?: string;
  /**
   *
   * @type {string}
   * @memberof Achievement
   */
  achievementType?: string;
  /**
   *
   * @type {string}
   * @memberof Achievement
   */
  achievementName?: string;
  /**
   *
   * @type {number}
   * @memberof Achievement
   */
  achievementLevel?: number;
  /**
   *
   * @type {number}
   * @memberof Achievement
   */
  achievementCount?: number;
  /**
   *
   * @type {{ [key: string]: object; }}
   * @memberof Achievement
   */
  extraInfo?: {[key: string]: object};
  /**
   *
   * @type {string}
   * @memberof Achievement
   */
  rewardType?: AchievementRewardTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Achievement
   */
  url?: string;
  /**
   *
   * @type {number}
   * @memberof Achievement
   */
  strapiId?: number;
  /**
   *
   * @type {number}
   * @memberof Achievement
   */
  tierId?: number;
  /**
   *
   * @type {string}
   * @memberof Achievement
   */
  trigger?: string;
  /**
   *
   * @type {string}
   * @memberof Achievement
   */
  groupId?: string;
  /**
   *
   * @type {boolean}
   * @memberof Achievement
   */
  unread?: boolean;
  /**
   *
   * @type {CmsMedia}
   * @memberof Achievement
   */
  image?: CmsMedia;
  /**
   *
   * @type {number}
   * @memberof Achievement
   */
  coinsEarned?: number;
  /**
   *
   * @type {CmsShareContent}
   * @memberof Achievement
   */
  shareContent?: CmsShareContent;
}

export const AchievementRewardTypeEnum = {
  Badge: 'BADGE',
  Certificate: 'CERTIFICATE',
  Coin: 'COIN',
} as const;

export type AchievementRewardTypeEnum = (typeof AchievementRewardTypeEnum)[keyof typeof AchievementRewardTypeEnum];
