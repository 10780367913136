/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {CmsTooltipItem} from './cms-tooltip-item';

/**
 *
 * @export
 * @interface CmsTooltip
 */
export interface CmsTooltip {
  /**
   *
   * @type {string}
   * @memberof CmsTooltip
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof CmsTooltip
   */
  trigger?: CmsTooltipTriggerEnum;
  /**
   *
   * @type {Array<CmsTooltipItem>}
   * @memberof CmsTooltip
   */
  items?: Array<CmsTooltipItem>;
  /**
   *
   * @type {boolean}
   * @memberof CmsTooltip
   */
  disabled?: boolean;
}

export const CmsTooltipTriggerEnum = {
  ChallengeLoad: 'ON_CHALLENGE_LOAD',
  ChallengeFirstTaskComplete: 'ON_CHALLENGE_FIRST_TASK_COMPLETE',
  ChallengeDayOneComplete: 'ON_CHALLENGE_DAY_ONE_COMPLETE',
  ChallengeDayTwoComplete: 'ON_CHALLENGE_DAY_TWO_COMPLETE',
  ChallengeDayThreeComplete: 'ON_CHALLENGE_DAY_THREE_COMPLETE',
  ChallengeDayFourComplete: 'ON_CHALLENGE_DAY_FOUR_COMPLETE',
  ChallengeDayFiveComplete: 'ON_CHALLENGE_DAY_FIVE_COMPLETE',
  DashboardLoad: 'ON_DASHBOARD_LOAD',
  EnterpriseDashboardLoad: 'ON_ENTERPRISE_DASHBOARD_LOAD',
} as const;

export type CmsTooltipTriggerEnum = (typeof CmsTooltipTriggerEnum)[keyof typeof CmsTooltipTriggerEnum];
