// @ts-nocheck
import {isObject, omitBy, pickBy} from 'lodash';
import {createRef, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import useScript from 'react-script-hook';
import {cleanApiMixpanelData, useApiMixpanel} from '@growthday/ui-core/src/hooks/useApiMixpanel';
import {sleep} from '../../../shared/util/sleep';
import * as allDeviceInfo from 'react-device-detect';
import {useIsCaptchaEnabled} from './useIsCaptchaEnabled';

declare global {
  interface Window {
    errorCallback: () => void;
    verifyCallback: (response: string) => void;
  }
}

window.verifyCallback = () => undefined;
window.errorCallback = () => undefined;
const shouldResetRef = createRef<boolean>() as MutableRefObject<boolean>;

export function useRecaptcha() {
  const {isCaptchaEnabled: enabled} = useIsCaptchaEnabled();
  const [loading, setLoading] = useState(false);
  const observerRef = useRef<MutationObserver>();
  const {mutateAsync} = useApiMixpanel();

  const onVisibilityChangeCallback = useCallback(
    async (trackingData: Record<string, any> = {}, callback: () => void) => {
      if (observerRef.current) {
        observerRef.current?.disconnect();
      }
      await sleep();
      const iframes = document.getElementsByTagName('iframe');
      const recaptchaElement = Array.from(iframes).find((e) => e.src.includes('google.com/recaptcha/api2/bframe'))
        ?.parentNode?.parentNode as HTMLElement;

      function checkVisibility() {
        return Boolean(+recaptchaElement?.style?.opacity ?? 0);
      }

      function onVisibilityChange(visible: boolean) {
        if (visible) {
          try {
            mutateAsync({
              ...cleanApiMixpanelData(trackingData),
              ...pickBy(omitBy(allDeviceInfo, isObject), (value) => value),
              ...(trackingData.context ? {context: trackingData.context} : {}),
              page_href: window.location.href,
              page_location: window.page_location,
              source: 'WEB',
            });
          } catch (e) {}
        }
      }

      let isVisible = checkVisibility();
      onVisibilityChange(isVisible);
      observerRef.current = new MutationObserver(() => {
        const changedVisibility = checkVisibility();
        if (isVisible !== changedVisibility && !changedVisibility) {
          callback();
        }
        isVisible = changedVisibility;
        onVisibilityChange(isVisible);
      });
      observerRef.current.observe(recaptchaElement, {
        attributes: true,
        attributeFilter: ['style'],
      });
    },
    [mutateAsync]
  );

  const reset = useCallback(() => {
    if (enabled && window.grecaptcha) {
      if (shouldResetRef.current) {
        window.grecaptcha.reset();
        shouldResetRef.current = false;
      }
    }
  }, [enabled]);

  useEffect(
    () => () => {
      if (observerRef.current) {
        observerRef.current?.disconnect();
      }
      reset();
    },
    [reset]
  );

  const execute = useCallback(
    (trackingData: Record<string, any> = {}) =>
      new Promise<string>(async (resolve, reject) => {
        if (enabled && window.grecaptcha) {
          setLoading(true);
          window.verifyCallback = (response) => {
            resolve(response);
            setLoading(false);
          };
          window.errorCallback = () => {
            reject('Captcha expired or error. Please retry!');
            setLoading(false);
          };
          reset();
          await window.grecaptcha.execute();
          onVisibilityChangeCallback(trackingData, window.errorCallback);
          shouldResetRef.current = true;
        } else {
          resolve('');
        }
      }),
    [reset, onVisibilityChangeCallback, enabled]
  );

  return useMemo(() => ({execute, loading, reset}), [execute, loading, reset]);
}

export function CaptchaProvider() {
  const {isCaptchaEnabled: enabled, recaptchaKey: siteKey} = useIsCaptchaEnabled();
  useScript({
    src: enabled ? 'https://www.google.com/recaptcha/api.js?render=onload' : null,
    async: true,
    defer: true,
  });
  return (
    <div
      className="g-recaptcha"
      data-sitekey={siteKey}
      data-size="invisible"
      data-callback="verifyCallback"
      data-expired-callback="errorCallback"
      data-error-callback="errorCallback"
    />
  );
}
