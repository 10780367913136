import {
  CompletedPlansInput,
  CompletedPlansResponse,
  FlattenedPlans,
  Plan,
  PlanQueryEndpointOptions,
} from '../../../types/plans';
import {flattenPlansToMap} from '../utils/flattenPlansToMap';
import plansApi, {completedPlansTagKey, plansTagKey} from '../plans.api';
import {endpoints} from '../endpoints';

export const getPlansOptions: PlanQueryEndpointOptions<void, FlattenedPlans> = {
  query: () => endpoints.plans,
  transformResponse(response: Plan[]) {
    return flattenPlansToMap(response);
  },
  providesTags: (result) =>
    result
      ? [
          ...result.plansArr.tasks.map(
            (id) =>
              ({
                type: plansTagKey,
                id,
              } as {type: typeof plansTagKey; id: string})
          ),
          plansTagKey,
        ]
      : [plansTagKey],
};

export const getPlanOptions: PlanQueryEndpointOptions<string, Plan> = {
  query: (id) => `${endpoints.plans}/${id}`,
  providesTags: (result) => (result ? [{type: plansTagKey, id: result.id}, plansTagKey] : [plansTagKey]),
  async onQueryStarted(arg, api) {
    const {data} = await api.queryFulfilled;
    if (!data) return;
    api.dispatch(
      plansApi.util.updateQueryData('getPlans', undefined, (draft) => {
        draft.records[data.id] = {
          ...data,
          parentID: '',
          tasks: data.tasks?.map((t) => t.id) ?? [],
        };
      })
    );
  },
};

export const getCompletedPlans: PlanQueryEndpointOptions<CompletedPlansInput, CompletedPlansResponse> = {
  query(arg) {
    const params: Record<string, number> = {
      page: 1,
      size: 10000,
      ...arg,
    };
    return {
      url: endpoints.completedPlans,
      params,
    };
  },
  providesTags: (result) =>
    result
      ? [
          ...result.list.map(
            ({id}) =>
              ({
                type: completedPlansTagKey,
                id: id,
              } as const)
          ),
          completedPlansTagKey,
        ]
      : [completedPlansTagKey],
};
