import {IDailyFires} from '@growthday/ui-core/src/types/strapi';
import {IUserAnalytics} from '../../../features/auth/interfaces';
import {DailyFireProgress, DashboardData} from '../../../features/dashboard/interfaces';
import {ILifeScoresDashboard} from '../../../features/life-scores/interfaces';
import {axiosGrowthDay} from '../index';

export const dashboardApi = {
  getAnalytics: (): Promise<IUserAnalytics> => {
    return axiosGrowthDay.get('/dashboard/analytics').then((res) => res.data);
  },

  getLifeScoreDashboardData: (): Promise<ILifeScoresDashboard> => {
    return axiosGrowthDay.get(`/dashboard/lifeScore`).then((res) => res.data);
  },

  getDailyFire: (): Promise<IDailyFires> => {
    return axiosGrowthDay.get('/userDailyFires/cms').then((res) => res.data);
  },

  getDailyFireProgress: (url: string): Promise<DailyFireProgress> => {
    return axiosGrowthDay.get(`/userDailyFires/${url}`).then((res) => res.data);
  },

  updateDailyFireProgress: (url: string, progress: number): Promise<DailyFireProgress> => {
    return axiosGrowthDay.put(`/userDailyFires/${url}/${progress}`).then((res) => res.data);
  },

  getDashboardData: (): Promise<DashboardData> => {
    return axiosGrowthDay.get('/dashboard').then((res) => res.data);
  },
};
