import {IConfigEnv} from '../../../config/config.interface';
import {EnvironmentsEnum} from '../../../config/enums';
import {GDSharedEnvironmentConstants} from './shared-constants';

const env: IConfigEnv = {
  androidAppUrl: 'https://play.google.com/store/apps/details?id=com.growthday&hl=en',
  apiUrl: 'https://api.growthday.com',
  appScheme: 'growthday',
  authClientId: 'growth-day-main-client',
  authRealm: 'GrowthDayRealm',
  authUrl: 'https://auth.growthday.com',
  communityUrl: 'https://community.growthday.com',
  fbPixelId: '1039673462719736',
  firstPromoterId: '4lrh8cqc',
  gTagEventsId: {
    completeRegistrationPlan: 'AW-1010317991/G0D4CK-_j9ICEKf14OED',
    completeRegistrationOffer: 'AW-1010317991/j8jUCNGdjtICEKf14OED',
    completeRegistrationChallenge: 'AW-1010317991/z5v6CLKo_-sCEKf14OED',
    completeRegistrationGift: 'AW-1010317991/mp-YCNLfn_oCEKf14OED',
    startTrial: 'AW-1010317991/HkO4CIemtvkBEKf14OED',
    subscribe: 'AW-1010317991/R6UQCMjxkNICEKf14OED',
  },
  gTagId: 'AW-1010317991',
  gtmId: 'GTM-PSW9SFB',
  hyvorWebsiteId: 3879,
  iosAppUrl: 'https://apps.apple.com/us/app/growthday/id1543291315?itsct=apps_box_link&itscg=30200',
  name: EnvironmentsEnum.PROD,
  searchApiUrl: 'https://search-api.prod.gday-stack.com',
  shareUrl: 'https://share.growthday.com',
  statsigSdkKey: 'client-4WF9oeu63DQd6CoIIUEEK93uUUkDzhLH7N9FAIjEcpL',
  stripePublishKey:
    'pk_live_51I1G0AFBHm5Si8uC6z8dO4o0uwwcmlmIRtfzP4ZC802fFdEqOxsViK7qcQlXygitOVyBoLo6uJL4PHqkqIxuhtFg00B6t0xh8u',
  useproofId: 'VKE8WSTSxrg6pXncelT968M1zV53',
  userback: '11316|23641|WaPCGDTmDHrZwTnbda3eNCUaW',
  ...GDSharedEnvironmentConstants,
};

export default env;
