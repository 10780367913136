import {FlattenedPlans, Plan, PlanWithoutNesting} from '../../../types/plans';
import {chain, cloneDeep} from 'lodash';

/**
 * @internal
 * @see {@link Plan} {@link PlanWithoutNesting}
 * A function to take a plan list and flatten it by removing nesting. Maintains immutability of data by cloning
 * @param plansWithParent - An array of plans with each entry having a key called parentID which is the id of parent
 * of plan. For top level plan, this should be an empty string
 *@returns [flattenedPlan, nextLevel] - A tuple with flattened plan and an array of plans with parentID in next level
 */
function flattenPlan(
  plansWithParent: (Plan & {parentID: PlanWithoutNesting['parentID']})[]
): [{[key: string]: PlanWithoutNesting}, (Plan & {parentID: PlanWithoutNesting['parentID']})[]] {
  plansWithParent = cloneDeep(plansWithParent);
  const flattenedTasks = chain(plansWithParent)
    .flatMap((plan) => {
      return plan.tasks?.map((t) => ({...t, parentID: plan.id}));
    })
    .compact()
    .value();

  plansWithParent = cloneDeep(plansWithParent);
  const flattenedEntity = chain(plansWithParent)
    .keyBy('id')
    .mapValues((plan) => ({
      ...plan,
      tasks: plan.tasks?.map(({id}) => id) ?? [],
    }))
    .value();
  return [flattenedEntity, flattenedTasks];
}

/**
 *A function to take plan and parse it into a map.
 * @param plansArr - An array of plans
 * @returns flattenedPlan - A flattened map of plans, their tasks and subtasks
 * @see {@link FlattenedPlans} for the structure of end result
 */
export function flattenPlansToMap(plansArr: Plan[]): FlattenedPlans {
  const [plans, tasksArr] = flattenPlan(plansArr.map((p) => ({...p, parentID: ''})));
  const [tasks, subtaskArr] = flattenPlan(tasksArr);
  const [subtasks] = flattenPlan(subtaskArr);
  return {
    records: {
      ...plans,
      ...subtasks,
      ...tasks,
    },
    plansArr: {tasks: plansArr.map((p) => p.id)},
  };
}
