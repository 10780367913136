import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {CampaignLoadingState, CampaignState, CampaignValidationResponse} from './interfaces';
import {stateWithLoading} from '../../shared/redux/util/state-with-loading';
import {getApiActionBuilder} from '../../shared/redux/util/build-api-action';
import * as apiActions from './campaign.actions';
import {IMarketingCampaigns} from '@growthday/ui-core/src/types/strapi';

const initialState = stateWithLoading<CampaignState, CampaignLoadingState>({
  marketingCampaign: null,
  marketingValidation: null,
});

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    resetMarketingValidation: (state) => {
      state.marketingValidation = null;
    },
  },
  extraReducers: (builder) => {
    const buildApiAction = getApiActionBuilder<CampaignState>(builder, apiActions);
    buildApiAction<PayloadAction<IMarketingCampaigns>>(['getMarketingCampaignBySlug', 'getMarketingCampaignById'], {
      fulfilled: (state, action) => {
        state.marketingCampaign = action.payload;
      },
    });
    buildApiAction<PayloadAction<CampaignValidationResponse>>(['validateMarketingCampaign'], {
      fulfilled: (state, action) => {
        state.marketingValidation = action.payload;
      },
    });
  },
});

export const {resetMarketingValidation} = campaignSlice.actions;

export default campaignSlice.reducer;
