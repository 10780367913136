import {useLayoutEffect} from 'react';
import {useDispatch} from 'react-redux';
import {resetState} from '../redux/actions';
import {
  clearAccessTokenCache,
  clearStorage,
  getAccessToken,
  setAccessToken,
} from '@/features/auth/routes/Profile/utils';
import {axiosGrowthDay, axiosRenewTokenClient, axiosPublicStrapi, axiosSearch, axiosStrapi} from '../api';
import parseError from '../util/parse-error';
import {appendQueryString} from '@/utils/query-string';
import {
  clearRefreshTokenCache,
  getRefreshAccessToken,
  setRefreshAccessToken,
} from '@/features/auth/routes/Profile/utils/refreshToken';
import mem from 'mem';
import {LoginResponse} from '../services_deprecated/model/loginResponse';
import {clearExpireTimeCache, getExpireTimeToken, setExpireTime} from '@/features/auth/routes/Profile/utils/expireTime';
import {AxiosRequestConfig} from 'axios';
import {isPlainObject, keys} from 'lodash';
import getAuthUrl from '@/features/auth/routes/KeyCloakLogin/utils/getAuthUrl';
import {DEFAULT_OFFERS_DEPTH} from '@growthday/ui-core/src/types/offers';
import {CustomAxiosResponse} from '@growthday/ui-core/src/types';
import {DEFAULT_SUBSCRIPTION_PLANS_DEPTH} from '@growthday/ui-core/src/types/subscription-plans';

declare global {
  interface Window {
    pendingXhrRequestCount: number;
  }
}

window.pendingXhrRequestCount = 0;

const routeMaps: Record<string, string> = {
  'empty-state-messages': 'empty-state-message',
  'gift-subscriptions': 'gift-subscription',
  'growth-coins': 'growth-coin',
  upsells: 'upsell',
};

const customDeepPopulateRoutes: Record<string, number> = {
  upsell: 5,
  offers: DEFAULT_OFFERS_DEPTH,
  achievements: 5,
  'subscription-plans': DEFAULT_SUBSCRIPTION_PLANS_DEPTH,
  'marketing-campaigns': 5,
};

function commonReqModifiers(req: AxiosRequestConfig) {
  // We need to check the url considering only the path, without query string before trying to replace it
  const urlWithoutParams = req.url?.split('?')[0];
  const route = keys(routeMaps).find((path) => urlWithoutParams?.includes(path));

  if (route) {
    req.url = req.url?.replace(route, routeMaps[route]);
  }

  if (!req.params) {
    req.params = {};
  }

  if (!req.params.populate && !req.url?.includes('populate')) {
    const deepPopulateRoute = Object.keys(customDeepPopulateRoutes).find((path) => req.url?.includes(path));
    if (deepPopulateRoute) {
      req.params.populate = `deep,${customDeepPopulateRoutes[deepPopulateRoute]}`;
    }
  }

  req.url = appendQueryString(req.url, {...req.params, v3Support: true});
  req.params = {};
}

const useAxiosInterceptors = () => {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    [axiosGrowthDay, axiosStrapi, axiosSearch].forEach((axiosApi) =>
      axiosApi.interceptors.response.use(undefined, (error) => {
        if (error.response.status === 401) {
          // Token has expired or invalid token
          dispatch(resetState());
          clearStorage();
        }
        window.pendingXhrRequestCount--;
        return Promise.reject(
          Object.create(error, {message: {value: parseError(error), writable: true, configurable: true}})
        );
      })
    );
    [axiosGrowthDay, axiosStrapi, axiosSearch].forEach((axiosApi) =>
      axiosApi.interceptors.request.use(async (req) => {
        if (!req.headers) {
          req.headers = {};
        }
        let accessToken = getAccessToken();
        if (accessToken) {
          accessToken = await refreshTokenFromUser();
          req.headers['Authorization'] = `Bearer ${accessToken}`;
        }
        window.pendingXhrRequestCount++;
        return req;
      })
    );
    [axiosStrapi, axiosPublicStrapi].forEach((axiosApi) =>
      axiosApi.interceptors.request.use((req) => {
        commonReqModifiers(req);
        return req;
      })
    );
    [axiosPublicStrapi, axiosStrapi].forEach((axiosApi) => {
      axiosApi.interceptors.response.use((res: CustomAxiosResponse) => {
        if (isPlainObject(res.data) && 'data' in res.data) {
          if (res.data?.total) {
            res.metadata = {total: res.data.total};
          }
          res.data = res.data.data;
        }
        return res;
      });
    });
  }, [dispatch]);
};

const memoizedRefreshTokenFromUser = async () => {
  const expTime = Number(getExpireTimeToken());

  // Check if token is expired
  if (expTime && Date.now() >= expTime) {
    const refreshToken = getRefreshAccessToken();
    if (refreshToken) {
      // Try to get a new token, if it fails, clear the cache to force login again
      try {
        const response = await axiosRenewTokenClient.put<LoginResponse>(
          '/login/refreshToken',
          {refreshToken},
          {headers: {Authorization: `Bearer ${getAccessToken() ?? ''}`}}
        );
        const accessToken = response.data.authenticationToken;
        setAccessToken(accessToken ?? '');
        setRefreshAccessToken(response.data.refreshToken ?? '');
        setExpireTime(response.data.expireTimeInUtc?.toString() ?? '');
        clearAccessTokenCache();
        clearRefreshTokenCache();
        clearExpireTimeCache();
        return accessToken;
      } catch (e) {
        clearStorage();
        window.location.href = getAuthUrl();
      }
    }
  }

  return getAccessToken();
};

// 10 seconds
const maxAge = 10000;

const refreshTokenFromUser = mem(memoizedRefreshTokenFromUser, {
  maxAge,
});

export default useAxiosInterceptors;
