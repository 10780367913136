export enum DataCollectionEnum {
  DAILY_JOURNAL = 'DailyJournal',
  CHALLENGES = 'Challenges',
  WEEKLY_HABITS = 'WeeklyHabits',
  MONTHLY_HABITS = 'MonthlyHabits',
  USER_COURSE = 'UserCourse',
  PLANS = 'Plans',
  DAILY_HABITS = 'DailyHabits',
  COMPANY_SCORECARDS = 'CompanyScorecards',
}
