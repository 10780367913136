/**
 * Model definition for daily-growth-prompt
 */
export interface IDailyGrowthPrompts {
  id: number;
  promptText?: string;
  scoreLowerBound?: number;
  scoreUpperBound?: number;
  type?: EnumDailyGrowthPromptsType;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumDailyGrowthPromptsType {
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
  history = 'history',
  dailyHistory = 'dailyHistory',
  weeklyHistory = 'weeklyHistory',
  monthlyHistory = 'monthlyHistory',
  default = 'default',
}
