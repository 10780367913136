import React, {FC} from 'react';
import {Form} from 'antd';
import {FormItemProps} from 'antd/es/form';

export type GDFormItemProps = {} & FormItemProps;

const GDFormItem: FC<GDFormItemProps> = ({children, ...restProps}) => {
  return <Form.Item {...restProps}>{children}</Form.Item>;
};

export default GDFormItem;
