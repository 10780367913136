/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface MarketingRegistrationValidationRequest
 */
export interface MarketingRegistrationValidationRequest {
  /**
   *
   * @type {string}
   * @memberof MarketingRegistrationValidationRequest
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof MarketingRegistrationValidationRequest
   */
  campaignType?: MarketingRegistrationValidationRequestCampaignTypeEnum;
  /**
   *
   * @type {number}
   * @memberof MarketingRegistrationValidationRequest
   */
  campaignId?: number;
}

export const MarketingRegistrationValidationRequestCampaignTypeEnum = {
  LiveEvent: 'LIVE_EVENT',
  Challenge: 'CHALLENGE',
  Plan: 'PLAN',
  Offer: 'OFFER',
} as const;

export type MarketingRegistrationValidationRequestCampaignTypeEnum =
  (typeof MarketingRegistrationValidationRequestCampaignTypeEnum)[keyof typeof MarketingRegistrationValidationRequestCampaignTypeEnum];
