/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {ScorecardSettings} from './scorecard-settings';

/**
 *
 * @export
 * @interface OrganizationResponse
 */
export interface OrganizationResponse {
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  organizationSubscribeLink?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  logoUrl?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  invitationVideoUrl?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  plan?: OrganizationResponsePlanEnum;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  planName?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  planFrequency?: OrganizationResponsePlanFrequencyEnum;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationResponse
   */
  subscriptionCancelled?: boolean;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  region?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  zipCode?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  organizationId?: string;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  paymentFlow?: OrganizationResponsePaymentFlowEnum;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  subscriptionLinkVisibility?: OrganizationResponseSubscriptionLinkVisibilityEnum;
  /**
   *
   * @type {string}
   * @memberof OrganizationResponse
   */
  stripePriceId?: string;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationResponse
   */
  growthGroupEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationResponse
   */
  communityEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof OrganizationResponse
   */
  growthGroupHostingEnabled?: boolean;
  /**
   *
   * @type {ScorecardSettings}
   * @memberof OrganizationResponse
   */
  scorecardSettings?: ScorecardSettings;
}

export const OrganizationResponsePlanEnum = {
  Basic: 'BASIC',
  Starter: 'STARTER',
  Growth: 'GROWTH',
  Mastery: 'MASTERY',
  Trial: 'TRIAL',
  None: 'NONE',
  Offer: 'OFFER',
  Challenge: 'CHALLENGE',
  Enterprise: 'ENTERPRISE',
  EnterpriseStarter: 'ENTERPRISE_STARTER',
  AllAccess: 'ALL_ACCESS',
} as const;

export type OrganizationResponsePlanEnum =
  (typeof OrganizationResponsePlanEnum)[keyof typeof OrganizationResponsePlanEnum];
export const OrganizationResponsePlanFrequencyEnum = {
  Month: 'MONTH',
  Year: 'YEAR',
} as const;

export type OrganizationResponsePlanFrequencyEnum =
  (typeof OrganizationResponsePlanFrequencyEnum)[keyof typeof OrganizationResponsePlanFrequencyEnum];
export const OrganizationResponsePaymentFlowEnum = {
  Organization: 'ORGANIZATION',
  Employee: 'EMPLOYEE',
} as const;

export type OrganizationResponsePaymentFlowEnum =
  (typeof OrganizationResponsePaymentFlowEnum)[keyof typeof OrganizationResponsePaymentFlowEnum];
export const OrganizationResponseSubscriptionLinkVisibilityEnum = {
  Public: 'PUBLIC',
  Private: 'PRIVATE',
} as const;

export type OrganizationResponseSubscriptionLinkVisibilityEnum =
  (typeof OrganizationResponseSubscriptionLinkVisibilityEnum)[keyof typeof OrganizationResponseSubscriptionLinkVisibilityEnum];
