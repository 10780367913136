import {useDispatch, useSelector} from 'react-redux';
import {useCallback, useMemo} from 'react';
import {reJoinGrowthGroupState, resetGrowthGroupState, updateGrowthGroupState} from '../../redux/growthgroup.slice';
import {GrowthGroupLayout, GrowthGroupState, ModalPosition} from '../../../../types/growth-groups';
import {GrowthGroupInstance} from '../../../../types/api';
import dayjs from 'dayjs';

const useGrowthGroupsState = () => {
  const rootState = useSelector<{growthGroups: GrowthGroupState}, GrowthGroupState>((state) => state.growthGroups);
  const dispatch = useDispatch();

  const setLoading = useCallback(
    (loading: boolean, instanceId?: GrowthGroupInstance['id']) => {
      dispatch(updateGrowthGroupState({loading}));
      if (instanceId) {
        dispatch(updateGrowthGroupState({loadingInstanceId: loading ? instanceId : null}));
      }
    },
    [dispatch]
  );

  const setLoadingInstanceId = useCallback(
    (instanceId: GrowthGroupState['loadingInstanceId']) => {
      dispatch(updateGrowthGroupState({loadingInstanceId: instanceId}));
    },
    [dispatch]
  );

  const setToken = useCallback(
    (token: string) => {
      dispatch(updateGrowthGroupState({token, joinedTime: dayjs().toISOString()}));
    },
    [dispatch]
  );

  const setLayout = useCallback(
    (layout: GrowthGroupLayout) => {
      dispatch(updateGrowthGroupState({layout}));
    },
    [dispatch]
  );
  const setSlug = useCallback(
    (slug: string) => {
      dispatch(updateGrowthGroupState({slug}));
    },
    [dispatch]
  );

  const setShowAdmissionModal = useCallback(
    (showAdmissionModal: boolean) => {
      dispatch(updateGrowthGroupState({showAdmissionModal}));
    },
    [dispatch]
  );

  const setState = useCallback(
    (state: Partial<GrowthGroupState>) => {
      dispatch(updateGrowthGroupState(state));
    },
    [dispatch]
  );

  const setBackstageBannerActive = useCallback(
    (backstageBannerActive: boolean) => {
      dispatch(updateGrowthGroupState({backstageBannerActive}));
    },
    [dispatch]
  );

  const setShowManageAttendeesModal = useCallback(
    (showManageAttendeesModal: boolean) => {
      dispatch(updateGrowthGroupState({showManageAttendeesModal}));
    },
    [dispatch]
  );

  const setManageAttendeesModalPosition = useCallback(
    (manageAttendeesModalPosition: ModalPosition) => {
      dispatch(updateGrowthGroupState({manageAttendeesModalPosition}));
    },
    [dispatch]
  );

  const setPaymentSidebarOpen = useCallback(
    (paymentSidebarOpen: boolean) => {
      dispatch(updateGrowthGroupState({paymentSidebarOpen}));
    },
    [dispatch]
  );

  const resetState = useCallback(() => dispatch(resetGrowthGroupState()), [dispatch]);

  const reJoinGroupState = useCallback(
    (state: Partial<GrowthGroupState>) => dispatch(reJoinGrowthGroupState(state)),
    [dispatch]
  );

  return useMemo(
    () => ({
      ...rootState,
      setLayout,
      setLoading,
      setLoadingInstanceId,
      setToken,
      setState,
      setSlug,
      setShowAdmissionModal,
      setBackstageBannerActive,
      setShowManageAttendeesModal,
      setManageAttendeesModalPosition,
      setPaymentSidebarOpen,
      resetState,
      reJoinGroupState,
    }),
    [
      rootState,
      setLayout,
      setLoading,
      setLoadingInstanceId,
      setToken,
      setState,
      setSlug,
      setShowAdmissionModal,
      setBackstageBannerActive,
      setShowManageAttendeesModal,
      setManageAttendeesModalPosition,
      setPaymentSidebarOpen,
      resetState,
      reJoinGroupState,
    ]
  );
};

export default useGrowthGroupsState;
