/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {ReminderTitles} from './reminder-titles';

/**
 *
 * @export
 * @interface Reminder
 */
export interface Reminder {
  /**
   *
   * @type {string}
   * @memberof Reminder
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof Reminder
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof Reminder
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof Reminder
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof Reminder
   */
  uuid: string;
  /**
   *
   * @type {string}
   * @memberof Reminder
   */
  until?: string;
  /**
   *
   * @type {string}
   * @memberof Reminder
   */
  startTime?: string;
  /**
   *
   * @type {string}
   * @memberof Reminder
   */
  endTime?: string;
  /**
   *
   * @type {string}
   * @memberof Reminder
   */
  rrule: string;
  /**
   *
   * @type {Array<string>}
   * @memberof Reminder
   */
  mediums: Array<ReminderMediumsEnum>;
  /**
   *
   * @type {string}
   * @memberof Reminder
   */
  type: ReminderTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Reminder
   */
  header?: string;
  /**
   *
   * @type {string}
   * @memberof Reminder
   */
  body: string;
  /**
   *
   * @type {string}
   * @memberof Reminder
   */
  entityId?: string;
  /**
   *
   * @type {boolean}
   * @memberof Reminder
   */
  active?: boolean;
  /**
   *
   * @type {ReminderTitles}
   * @memberof Reminder
   */
  titles?: ReminderTitles;
  /**
   *
   * @type {boolean}
   * @memberof Reminder
   */
  state?: boolean;
}

export const ReminderMediumsEnum = {
  InApp: 'IN_APP',
  Push: 'PUSH',
} as const;

export type ReminderMediumsEnum = (typeof ReminderMediumsEnum)[keyof typeof ReminderMediumsEnum];
export const ReminderTypeEnum = {
  PlanSubTask: 'PLAN_SUB_TASK',
  PlanAction: 'PLAN_ACTION',
  PlanActionInsideChallenge: 'PLAN_ACTION_INSIDE_CHALLENGE',
  Plan: 'PLAN',
  PlanInsideChallenge: 'PLAN_INSIDE_CHALLENGE',
  Custom: 'CUSTOM',
} as const;

export type ReminderTypeEnum = (typeof ReminderTypeEnum)[keyof typeof ReminderTypeEnum];
