/**
 * Model definition for marketing-plan-trial
 */
export interface IMarketingPlanTrial {
  id: number;
  frequency?: EnumMarketingPlanTrialFrequency;
  duration?: number;
  skipCreditCardCollection?: boolean;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumMarketingPlanTrialFrequency {
  day = 'day',
  month = 'month',
  year = 'year',
}
