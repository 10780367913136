import {IStrapiSubscriptionPlan, ISubscriptionPlans} from '@/features/auth/interfaces';
import {SubscriptionIntervalsEnum} from '@/shared/services_deprecated/enums';
import {EnumSubscriptionPlansLevel} from '@growthday/ui-core/src/types/strapi';

export function getSubscriptionPlanInterval(
  plan: ISubscriptionPlans,
  subscriptionInterval?: SubscriptionIntervalsEnum
) {
  if (subscriptionInterval === SubscriptionIntervalsEnum.MONTH && plan.month.amount !== 0) {
    return SubscriptionIntervalsEnum.MONTH;
  }
  return plan.year.amount !== 0 ? SubscriptionIntervalsEnum.YEAR : SubscriptionIntervalsEnum.MONTH;
}

export function getIntervalFromOfferPlan(input?: string) {
  return input?.toLowerCase().endsWith('_month') ? SubscriptionIntervalsEnum.MONTH : SubscriptionIntervalsEnum.YEAR;
}

export function getLevelFromOfferPlan(input?: string) {
  return input?.toUpperCase().replace(/(_MONTH|_YEAR)$/, '') as EnumSubscriptionPlansLevel;
}

export function readablePlanInterval(interval: SubscriptionIntervalsEnum) {
  return interval.toUpperCase() === SubscriptionIntervalsEnum.YEAR.toUpperCase() ? 'Yearly' : 'Monthly';
}

export function readablePlanNameInterval(interval: SubscriptionIntervalsEnum) {
  return interval.toUpperCase() === SubscriptionIntervalsEnum.YEAR.toUpperCase() ? 'Annual' : 'Monthly';
}

export function getPlanAnnualAmount(plan: IStrapiSubscriptionPlan | ISubscriptionPlans) {
  if (SubscriptionIntervalsEnum.YEAR in plan && SubscriptionIntervalsEnum.MONTH in plan) {
    const p = plan as ISubscriptionPlans;
    return p.year.amount || p.month.amount * 12;
  } else {
    const p = plan as IStrapiSubscriptionPlan;
    return p.yearlyAmount || (p.monthlyAmount ?? 0) * 12;
  }
}
