import {keys, pickBy} from 'lodash';
import qs from 'qs';
import urlJoin from 'proper-url-join';

const CAMPAIGN_STORAGE_KEY = 'campaign-params';

declare global {
  interface Window {
    page_path: string;
    page_location: string;
  }
}

export const getCampaignParams = (): Record<string, string> | undefined => {
  const sessionData = sessionStorage.getItem(CAMPAIGN_STORAGE_KEY);
  if (sessionData) {
    try {
      return JSON.parse(sessionData);
    } catch (e: any) {}
  }
};

export const setCampaignParams = () => {
  let storedParams: Record<string, string> | undefined = getCampaignParams();

  if (!storedParams) {
    const queryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    }) as Record<string, string>;
    const requestedParams = [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_content',
      'gclid',
      'fbclid',
      'dclid',
      'gclsrc',
      'msclkid',
      '_gl',
    ];
    const neededParams = pickBy(
      queryParams,
      (paramValue, paramName) => paramValue && requestedParams.includes(paramName)
    );
    if (keys(neededParams).length) {
      storedParams = neededParams;
      sessionStorage.setItem(CAMPAIGN_STORAGE_KEY, JSON.stringify(storedParams));
    }
  }

  return storedParams;
};

export const getPagePathParams = () => {
  const storedParams: Record<string, string> | undefined = getCampaignParams();
  return {
    page_location: urlJoin(window.location.href, {query: storedParams ?? {}}),
    page_path: urlJoin(window.location.pathname, {query: storedParams ?? {}}),
  };
};

export const updateGlobalPathParams = () => {
  const {page_path, page_location} = getPagePathParams();
  window.page_path = page_path;
  window.page_location = page_location;
};
