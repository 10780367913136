/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface OrganizationUpdatePaymentTypeRequest
 */
export interface OrganizationUpdatePaymentTypeRequest {
  /**
   *
   * @type {string}
   * @memberof OrganizationUpdatePaymentTypeRequest
   */
  paymentFlow: OrganizationUpdatePaymentTypeRequestPaymentFlowEnum;
  /**
   *
   * @type {string}
   * @memberof OrganizationUpdatePaymentTypeRequest
   */
  stripePriceId: string;
  /**
   *
   * @type {number}
   * @memberof OrganizationUpdatePaymentTypeRequest
   */
  seats?: number;
}

export const OrganizationUpdatePaymentTypeRequestPaymentFlowEnum = {
  Organization: 'ORGANIZATION',
  Employee: 'EMPLOYEE',
} as const;

export type OrganizationUpdatePaymentTypeRequestPaymentFlowEnum =
  (typeof OrganizationUpdatePaymentTypeRequestPaymentFlowEnum)[keyof typeof OrganizationUpdatePaymentTypeRequestPaymentFlowEnum];
