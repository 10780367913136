import {ReactNode} from 'react';
import {createPortal} from 'react-dom';

export type PortalProps = {
  children?: ReactNode;
};

const container = document.createElement('div');
container.id = 'overlay-portal-container';
document.body.appendChild(container);
export function Portal({children}: PortalProps) {
  return createPortal(children, container);
}
