import {useQueryClient} from 'react-query';
import {produce} from 'immer';
import {IUser} from '@/shared/services_deprecated/model/user';
import {UserMeDataQueryKey} from './useGetMeData';
import useStableCallback from '@growthday/ui-core/src/hooks/useStableCallback';

/**
 *
 * A custom hook for updating the user object in react query cache
 */
export function useUpdateMeData() {
  const queryClient = useQueryClient();

  return useStableCallback((updater?: (old: IUser) => void, data?: IUser) => {
    queryClient.setQueryData<IUser | null | undefined>(UserMeDataQueryKey, (old) => {
      // If we have and want to update the full data, just set it
      if (data) {
        return data;
      }
      // If we want to update using immer, but old doesn't exist, just return old
      if (!old || !updater) {
        return old;
      }

      return produce(old, updater);
    });
  });
}
