import {configureStore} from '@reduxjs/toolkit';
import {persistStore} from 'redux-persist';

import reducer from './reducer';
import {middlewares as coreMiddlewares} from '@growthday/ui-core/src/redux/reducer';

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(...coreMiddlewares),
});

export const persistor = persistStore(store);

export default store;
export {reducer as combinedReducer};
