import plansApi from '../plans.api';
import {endpoints} from '../endpoints';
import {DeleteTaskInput, MutationEndpointOptions, PlanTypes} from '../../../types/plans';
import {reminderTags} from '../../reminders/reminders.constant';
import {remindersApi} from '../../reminders/reminders.api';

/**
 * @internal
 * Generates options for creating delete endpoints for plans API. Can delete plans,tasks and subtasks with
 * optimistic updates
 * @param endpointForChange - The URL for deleting/ It is appended with id during network request.
 * @param type - Type of entity being deleted
 */
function generateDeleteOptions(
  endpointForChange: string,
  type: Extract<PlanTypes, 'plans'>
): MutationEndpointOptions<string, void>;
function generateDeleteOptions(
  endpointForChange: string,
  type: Exclude<PlanTypes, 'plans'>
): MutationEndpointOptions<DeleteTaskInput, void>;
function generateDeleteOptions(
  endpointForChange: string,
  _type: PlanTypes
): MutationEndpointOptions<string | DeleteTaskInput, void> {
  return {
    query(id) {
      let url = endpointForChange;
      if (typeof id === 'string') url += `/${id}`;
      else url += `/${id.parentID}/${id.id}`;
      return {
        url,
        method: 'DELETE',
      };
    },
    async onQueryStarted(_id, api) {
      const id: string = typeof _id === 'string' ? _id : _id.id;
      const patch = api.dispatch(
        plansApi.util.updateQueryData('getPlans', undefined, (draft) => {
          if (!(id in draft.records)) return;
          const {parentID} = draft.records[id];
          delete draft.records[id];
          if (parentID.length) {
            const parent = draft.records[parentID];
            parent.tasks = parent.tasks.filter((childrenID) => childrenID !== id);
          } else draft.plansArr.tasks = draft.plansArr.tasks.filter((planID) => planID !== id);
        })
      );
      const patch2 = api.dispatch(
        plansApi.util.updateQueryData('getCompletedPlans', {}, (draft) => {
          draft.count--;
          draft.list = draft.list.filter((t) => t.id !== id);
        })
      );
      api.queryFulfilled
        .then(() => {
          api.dispatch(remindersApi.util.invalidateTags([reminderTags.type, reminderTags.list()]));
        })
        .catch(() => {
          patch.undo();
          patch2.undo();
        });
    },
  };
}

// Options for delete plans mutation
export const deletePlanOptions: MutationEndpointOptions<string, void> = generateDeleteOptions(endpoints.plans, 'plans');
// Options for delete task mutation
export const deleteTaskOptions: MutationEndpointOptions<DeleteTaskInput, void> = generateDeleteOptions(
  endpoints.tasks,
  'tasks'
);
// Options for delete subtask mutation
export const deleteSubTaskOptions: MutationEndpointOptions<DeleteTaskInput, void> = generateDeleteOptions(
  endpoints.subtasks,
  'subtasks'
);
