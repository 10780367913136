import {createAsyncThunk} from '@reduxjs/toolkit';
import {IMarketingCampaigns} from '@growthday/ui-core/src/types/strapi';
import {campaignApi} from '../../shared/api/campaign/campaign.api';
import {CampaignValidationRequest, CampaignValidationResponse} from './interfaces';

export const getMarketingCampaignBySlug = createAsyncThunk<IMarketingCampaigns, string>(
  'campaign/getMarketingCampaignBySlug',
  async (slug) => {
    try {
      return await campaignApi.getMarketingCampaignBySlug(slug);
    } catch (err: any) {
      throw err;
    }
  }
);

export const getMarketingCampaignById = createAsyncThunk<IMarketingCampaigns, number>(
  'campaign/getMarketingCampaignById',
  async (id) => {
    try {
      return await campaignApi.getMarketingCampaignById(id);
    } catch (err: any) {
      throw err;
    }
  }
);

export const validateMarketingCampaign = createAsyncThunk<CampaignValidationResponse, CampaignValidationRequest>(
  'campaign/validateMarketingCampaign',
  async (request) => {
    try {
      return await campaignApi.validateMarketingCampaign(request);
    } catch (err: any) {
      return err?.response?.data;
    }
  }
);
