import {IFile} from './file';

/**
 * Model definition for growth-group-package
 */
export interface IGrowthGroupPackage {
  id: number;
  name?: string;
  title?: string;
  description?: string;
  thumbnail?: IFile;
  helpText?: string;
  image?: IFile;
  subtitle?: string;
  purchaseButtonText?: string;
  bannerTitle?: string;
  bannerSubtitle?: string;
  bannerPurchaseButtonText?: string;
  planFrequency?: EnumGrowthGroupPackagePlanFrequency;
  maxParticipantsAllowed?: number;
  price?: number;
  stripePriceId?: string;
  applePriceId?: string;
  meetingsAllowed?: number;
  tooltipDescription?: string;
  deprecated?: boolean;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumGrowthGroupPackagePlanFrequency {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}
