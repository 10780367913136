/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface GrowthGroupWorkshopFilter
 */
export interface GrowthGroupWorkshopFilter {
  /**
   *
   * @type {string}
   * @memberof GrowthGroupWorkshopFilter
   */
  type?: GrowthGroupWorkshopFilterTypeEnum;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupWorkshopFilter
   */
  channel?: GrowthGroupWorkshopFilterChannelEnum;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupWorkshopFilter
   */
  duration?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupWorkshopFilter
   */
  startTime?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupWorkshopFilter
   */
  endTime?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupWorkshopFilter
   */
  offset: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupWorkshopFilter
   */
  limit: number;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupWorkshopFilter
   */
  isParticipant?: boolean;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupWorkshopFilter
   */
  sortBy?: GrowthGroupWorkshopFilterSortByEnum;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupWorkshopFilter
   */
  order?: GrowthGroupWorkshopFilterOrderEnum;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupWorkshopFilter
   */
  dateRange?: GrowthGroupWorkshopFilterDateRangeEnum;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupWorkshopFilter
   */
  upcoming?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupWorkshopFilter
   */
  includeKeynotes?: boolean;
}

export const GrowthGroupWorkshopFilterTypeEnum = {
  Private: 'PRIVATE',
  Public: 'PUBLIC',
} as const;

export type GrowthGroupWorkshopFilterTypeEnum =
  (typeof GrowthGroupWorkshopFilterTypeEnum)[keyof typeof GrowthGroupWorkshopFilterTypeEnum];
export const GrowthGroupWorkshopFilterChannelEnum = {
  Audio: 'AUDIO',
  Video: 'VIDEO',
} as const;

export type GrowthGroupWorkshopFilterChannelEnum =
  (typeof GrowthGroupWorkshopFilterChannelEnum)[keyof typeof GrowthGroupWorkshopFilterChannelEnum];
export const GrowthGroupWorkshopFilterSortByEnum = {
  CreatedOn: 'createdOn',
  StartTime: 'startTime',
  Id: 'id',
} as const;

export type GrowthGroupWorkshopFilterSortByEnum =
  (typeof GrowthGroupWorkshopFilterSortByEnum)[keyof typeof GrowthGroupWorkshopFilterSortByEnum];
export const GrowthGroupWorkshopFilterOrderEnum = {
  Asc: 'asc',
  Desc: 'desc',
} as const;

export type GrowthGroupWorkshopFilterOrderEnum =
  (typeof GrowthGroupWorkshopFilterOrderEnum)[keyof typeof GrowthGroupWorkshopFilterOrderEnum];
export const GrowthGroupWorkshopFilterDateRangeEnum = {
  Live: 'LIVE',
  Today: 'TODAY',
  Week: 'WEEK',
  Month: 'MONTH',
} as const;

export type GrowthGroupWorkshopFilterDateRangeEnum =
  (typeof GrowthGroupWorkshopFilterDateRangeEnum)[keyof typeof GrowthGroupWorkshopFilterDateRangeEnum];
