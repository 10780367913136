/**
 * Model definition for Growthgroups-upsell-content-level
 */
export interface IGrowthgroupsUpsellContentLevel {
  id: number;
  value?: EnumGrowthgroupsUpsellContentLevelValue;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumGrowthgroupsUpsellContentLevelValue {
  BASIC = 'BASIC',
  STARTER = 'STARTER',
  MASTERY = 'MASTERY',
  ALL_ACCESS = 'ALL_ACCESS',
  ENTERPRISE = 'ENTERPRISE',
  ENTERPRISE_STARTER = 'ENTERPRISE_STARTER',
  OFFER = 'OFFER',
  TRIAL = 'TRIAL',
  NONE = 'NONE',
  CHALLENGE = 'CHALLENGE',
  LIVE_EVENT = 'LIVE_EVENT',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}
