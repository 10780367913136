/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import {Role} from './role';
import {PaymentSystemEnum, SubscriptionLevelsEnum} from '../../../features/auth/enums';
import {SubscriptionIntervalsEnum} from '../enums';
import {Platform, PlatformInformation} from '../../util/types';
import {User} from '@growthday/ui-core/src/types/api';

export enum UserSignupType {
  CHALLENGE = 'CHALLENGE',
  OFFER = 'OFFER',
  LIVE_EVENT = 'LIVE_EVENT',
  PLAN = 'PLAN',
}

export interface NotificationInfo {
  planToBeCreated: boolean;
}

export interface UserChatProfile {
  id: string;
  name: string;
  email: string;
  picture: string;
}

export interface IUser extends Pick<User, 'stripeConnect' | 'isTeamUser'> {
  createTimestamp?: number;
  currentCoinCount?: number;
  dailyScore?: {[key: string]: number};
  deleteTimestamp?: number;
  deletedBy?: string;
  email?: string;
  firstPromoterAuthKey?: string;
  firstPromoterCustomerId?: string;
  forcePasswordChange?: boolean;
  fullName?: string;
  firstName?: string;
  lastName?: string;
  id?: string;
  isActive?: boolean;
  isPartnerUser?: boolean;
  automaticTaxEnabled?: boolean;
  onboarded?: boolean;
  demoUser?: boolean;
  kajabiUser?: boolean;
  level?: SubscriptionLevelsEnum;
  partnerId?: string;
  paymentMethodId?: string;
  paymentSystem?: PaymentSystemEnum;
  paypalEmail?: string;
  paypalSubscriptionId?: string;
  paypalPlanId?: string;
  phoneNumber?: string;
  iso2?: string;
  country?: string;
  region?: string;
  countryCode?: string;
  profileImageKey?: string;
  stripeCustomerId?: string;
  stripePriceId?: string;
  stripeSubscriptionId?: string;
  applePlanId?: string;
  appleSubscriptionId?: string;
  subscriptionPlanName?: string;
  updateTimestamp?: number;
  updatedBy?: string;
  userAttributes?: Array<string>;
  userProperties?: {[key: string]: string};
  userChatProfile?: UserChatProfile;
  userProfileHash?: string;
  uuid?: string;
  monthlyScore?: {[key: string]: number};
  password?: string;
  roles?: Array<Role>;
  username?: string;
  weeklyScore?: {[key: string]: number};
  ianaTimezone?: string;
  zipCode?: string;
  subscriptionCancelled?: boolean;
  subscriptionExpiry?: number;
  planFrequency?: SubscriptionIntervalsEnum;
  trialPeriodEnd?: string;
  oldSubscription?: 'STRIPE' | 'PAYPAL';
  platformInformation?: PlatformInformation;
  partnerSubscriptionPlanId?: number;
  subscriptionPlatform?: Platform;
  isGiftBuyer?: boolean;
  giftSubscriptionBuyerEmail?: string;
  signupType?: UserSignupType;
  signupId?: string | number;
  signupPlanId?: number;
  signupPlanName?: string;
  signupOfferId?: number;
  signupOfferName?: string;
  signupChallengeId?: number;
  signupChallengeName?: string;
  signupLiveEventId?: number;
  signupLiveEventName?: string;
  signupGrowthGroupInstanceId?: number | string;
  needPasswordSet?: boolean;
  campaignId?: number;
  signupSocialEventId?: number;
  currentBadgeCount?: number;
  currentCertificateCount?: number;
  oneSignalPlayerId?: string;
  partnerAccountUrl?: string;
  enterpriseUser?: boolean;
  giftBuyer?: boolean;
  giftSubscriptionSignupToken?: string | null;
  keycloakUserUuid?: string | null;

  emailStartsWith?: string;
  registrationReferralCode?: string | null;
  registrationReferralUserId?: string | null;
  notificationInfo?: NotificationInfo;
  preferences?: any;
  utcOffsetInMinutes?: number | null;
  reportedIssues?: number;
  reportedFeedback?: number;
  trialReminderCount?: number;
  paymentAbandonmentReminderCount?: number;
  optInForResearch?: boolean;
  paypalNewSubscriptionPlanId?: string | null;
  hasAccessToMastermind?: boolean;
  profile?: any;
  giftSubscriptionRedemptionCount?: number;
  shakeToFeedback?: boolean;
  ipAddress?: string;
  joinedFirstEverLiveEvent?: boolean;
  paidUser?: boolean;
  accountNonExpired?: boolean;
  accountNonLocked?: boolean;
  credentialsNonExpired?: boolean;
  authorizedForLiveEvents?: boolean;
  profileComplete?: boolean;
  uuidAsUuid?: string;
  invalidSubscription?: boolean;
  webSubscriptionPlatform?: boolean;
  webPlatform?: boolean;
  planId?: string;
  allowedToCreateGrowthGroup?: boolean;
  proUser?: boolean;
  expired?: boolean;
  enabled?: boolean;
  isSubscriptionCancelled?: boolean;
}

export type IUpdateUser = Partial<
  Pick<
    IUser,
    | 'firstName'
    | 'lastName'
    | 'fullName'
    | 'email'
    | 'countryCode'
    | 'country'
    | 'iso2'
    | 'region'
    | 'zipCode'
    | 'phoneNumber'
    | 'onboarded'
    | 'ianaTimezone'
    | 'paymentMethodId'
    | 'profileImageKey'
    | 'platformInformation'
    | 'paypalEmail'
    | 'stripeConnect'
  >
>;

export type IUpdateUserAddress = Partial<
  Pick<IUser, 'countryCode' | 'country' | 'iso2' | 'region' | 'zipCode' | 'phoneNumber'>
>;
