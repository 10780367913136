import {IMarketingChallenge} from './marketing-challenge';
import {IMarketingLiveEvent} from './marketing-live-event';
import {IMarketingOffer} from './marketing-offer';
import {IMarketingPlan} from './marketing-plan';
import {IOnboardFlow} from './onboard-flow';
import {IOnboarding} from './onboarding';
import {ISignup} from './signup';
import {ISignupFlow} from './signup-flow';
import {ISocialEvents} from './social-events';
import {IWebhooks} from './webhooks';

/**
 * Model definition for marketing-campaign
 */
export interface IMarketingCampaigns {
  id: number;
  title?: string;
  slug?: string;
  active?: boolean;
  startDate?: string;
  endDate?: string;
  type?: EnumMarketingCampaignsType;
  membersContent?: ISignup;
  nonMembersContent?: ISignup;
  events: ISocialEvents[];
  webhooks: IWebhooks[];
  onboarding?: IOnboarding;
  component: (
    | ({__component: 'marketing.marketing-plan'} & IMarketingPlan)
    | ({__component: 'marketing.marketing-offer'} & IMarketingOffer)
    | ({__component: 'marketing.marketing-live-event'} & IMarketingLiveEvent)
    | ({__component: 'marketing.marketing-challenge'} & IMarketingChallenge)
  )[];
  signup_flow?: ISignupFlow;
  onboard_flow?: IOnboardFlow;
  isFree?: boolean;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumMarketingCampaignsType {
  CHALLENGE = 'CHALLENGE',
  PLAN = 'PLAN',
  OFFER = 'OFFER',
  LIVE_EVENT = 'LIVE_EVENT',
}
