import {GateResult, useGate} from 'statsig-react';
import {FeatureGateEnum} from '../types';

type FeatureGateReturn<T extends boolean> = T extends true ? GateResult : boolean;
// Keep interface same as mobile
export function useFeatureGate<T extends boolean = false>(
  name: FeatureGateEnum,
  returnRawResults?: T,
  ignoreOverrides?: boolean
): FeatureGateReturn<T> {
  const result = useGate(name, ignoreOverrides);
  if (returnRawResults) {
    return result as FeatureGateReturn<T>;
  }
  return result.value as FeatureGateReturn<T>;
}
