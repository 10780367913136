import {createAsyncThunk} from '@reduxjs/toolkit';
import {IStrapiCourse, IStrapiCourseChapter} from '../../../../shared/services_deprecated/model/strapi/course';
import {IUserCourse} from '../../../../shared/services_deprecated/model/user-course';
import message from 'antd/es/message';
import {IStrapiCourseLesson} from '../../../../shared/services_deprecated/model/strapi/course-lesson';
import {strapiApi, userCourseApi} from '../../../../shared/api';
import {StrapiCollectionEnum} from '../../../../shared/api/strapi/enums';
import displayApiError from '../../../../shared/util/display-api-error';
import {IUserCourseNote, IUserCourseProgress} from '../../../../shared/services_deprecated/model/data-collection';
import {showAchievementCelebration} from '../../../auth/routes/Profile/routes/MyProfile/components/Achievements/achievements.action';
import {isPlainObject} from 'lodash';
import {AchievementInfo} from 'packages/core/src/types/achievements';

export const getCourse = createAsyncThunk<IStrapiCourse, string | number>(
  'learn/getCourse',
  async (courseIdentifier) => {
    try {
      const courses = await strapiApi.getCollection<IStrapiCourse[]>(
        StrapiCollectionEnum.COURSES,
        !isNaN(courseIdentifier as number) ? `?id=${courseIdentifier}` : `?titleUid=${courseIdentifier}`
      );
      const [course] = courses;
      course.course_lessons?.sort((a, b) => {
        return a.order - b.order;
      });

      const aggregatedLessons: IStrapiCourse['aggregatedLessons'] = [];
      course.course_lessons?.forEach((cl) => {
        const chapter = course.course_chapters?.find((cc) => {
          const lessonChapterId =
            typeof cl.course_chapter === 'number'
              ? cl.course_chapter
              : isPlainObject(cl.course_chapter)
                ? (cl.course_chapter as any)?.id
                : '';
          return cc.id && lessonChapterId && cc.id === lessonChapterId;
        });

        if (!chapter) {
          aggregatedLessons.push(cl);
        } else {
          const existingAggregate = aggregatedLessons.find((al) => {
            return Array.isArray(al) && al[0].id === chapter.id;
          }) as [IStrapiCourseChapter, IStrapiCourseLesson[]];

          if (existingAggregate) {
            existingAggregate[1].push(cl);
          } else {
            aggregatedLessons.push([chapter, [cl]]);
          }
        }
      });
      course.aggregatedLessons = aggregatedLessons;
      return course;
    } catch (err: any) {
      message.error('Failed fetching the course');
      throw err;
    }
  }
);

export const getUserCourse = createAsyncThunk<IUserCourse, number>('learn/getUserCourse', async (courseId) => {
  // eslint-disable-next-line no-useless-catch
  try {
    let courseFromDB;
    try {
      courseFromDB = await userCourseApi.userCourseGet(courseId);
    } catch (e: any) {}
    if (!courseFromDB) {
      await userCourseApi.registerOnCourse(courseId);
      courseFromDB = await userCourseApi.userCourseGet(courseId);
    }
    return courseFromDB;
  } catch (err: any) {
    throw err;
  }
});

export const getLesson = createAsyncThunk<IStrapiCourseLesson, string>('learn/getLesson', async (lessonTitleUid) => {
  try {
    const [lesson] = await strapiApi.getCollection<IStrapiCourseLesson[]>(
      StrapiCollectionEnum.COURSE_LESSONS,
      `?titleUid=${lessonTitleUid}`
    );
    return lesson;
  } catch (err: any) {
    displayApiError(err, 'Failed fetching the lesson');
    throw err;
  }
});

export const saveLessonNote = createAsyncThunk<
  IUserCourse | void,
  IUserCourseNote & {
    courseId: string | number;
    lessonId: string | number;
  }
>('learn/saveLessonNote', async ({courseId, lessonId, ...note}) => {
  try {
    return await userCourseApi.addNoteForLesson({
      ...note,
      courseId: Number(courseId),
      lessonId: Number(lessonId),
    });
  } catch (err: any) {
    message.error('Failed saving note for the lesson');
  }
});

export const deleteLessonNote = createAsyncThunk<
  void,
  {
    courseId: string | number;
    lessonId: string | number;
    noteId: string;
  }
>('learn/deleteLessonNote', async (arg) => {
  try {
    await userCourseApi.deleteNoteForLesson(arg);
  } catch (err: any) {}
});

export const registerOnCourse = createAsyncThunk<void, string>('learn/registerOnCourse', async (courseId) => {
  try {
    await userCourseApi.registerOnCourse(Number(courseId));
  } catch (err: any) {
    message.error('Failed saving note for the course');
    throw err;
  }
});

export const updateLessonProgress = createAsyncThunk<
  void,
  {
    courseId: string | number;
    lessonId: string | number;
    progress: number;
  }
>('learn/updateLessonProgress', async ({progress, courseId, lessonId}) => {
  // eslint-disable-next-line no-useless-catch
  try {
    await userCourseApi.userCourseProgress(Number(courseId), Number(lessonId), progress);
  } catch (err: any) {
    throw err;
  }
});

export const markLessonAsCompleted = createAsyncThunk<
  IUserCourseProgress,
  {
    courseId: string | number;
    lessonId: string | number;
  }
>('learn/markLessonAsCompleted', async ({courseId, lessonId}) => {
  // eslint-disable-next-line no-useless-catch
  try {
    return await userCourseApi.userCourseLessonMarkAsCompleted(Number(courseId), Number(lessonId));
  } catch (err: any) {
    throw err;
  }
});

export const markCourseAsCompleted = createAsyncThunk<
  {userCourse: IUserCourse; achievement?: (AchievementInfo & {coinsEarned?: number}) | null},
  {
    courseId: string | number;
  }
>('learn/markCourseAsCompleted', async ({courseId}, thunkAPI) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await userCourseApi.userCourseMarkAsCompleted(Number(courseId));
    response.achievement && thunkAPI.dispatch(showAchievementCelebration(response.achievement));
    return response;
  } catch (err: any) {
    throw err;
  }
});
