import {useDispatch} from 'react-redux';
import {OrganizationResponse} from '@growthday/ui-core/src/types/api';
import {setOrganization} from '../../auth/auth.slice';
import useAuthState from '../../auth/hooks/useAuthState';
import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';

export const useGetAuthData = () => {
  const dispatch = useDispatch();
  const {accessToken} = useAuthState();
  const {user} = useUser();
  const handleSuccessUpdateDate = (data: OrganizationResponse) => {
    dispatch(setOrganization(data));
  };

  return {
    enabled: Boolean(user?.enterpriseUser && accessToken),
    handleSuccessUpdateDate,
  };
};
