import {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {isAndroid, isFirefox, isMobile} from 'react-device-detect';
import deeplinks from '../../deeplinks.json';
import {generatePath, matchPath} from 'react-router';
import qs from 'qs';
import config from '../../config';
import {isUndefined, omitBy} from 'lodash';
import * as H from 'history';

const isEnabled = Boolean(isAndroid ? config.env.androidAppUrl : config.env.iosAppUrl);

export const navigateToMobileApp = (location: H.Location<H.LocationState>, history?: H.History<H.LocationState>) => {
  if (!isMobile || !isEnabled || !config.env.deepLinkingEnabled) {
    return;
  }

  const matchedPath = deeplinks.find((deeplink) =>
    matchPath(location.pathname, {path: deeplink, exact: true, strict: false})
  );

  if (matchedPath) {
    const match = matchPath(location.pathname, {path: matchedPath, exact: true, strict: false});
    const query = qs.parse(location.search, {ignoreQueryPrefix: true}) as Record<string, string>;
    const mergedQuery = {...query, ...omitBy(match?.params, isUndefined)};
    const generatedPath = generatePath(matchedPath, mergedQuery);

    if (history && generatedPath !== location.pathname) {
      history.replace({
        pathname: generatedPath,
        search: location.search,
      });
    }

    const webLink = window.location.href;
    const appLink = `${config.env.appScheme}://${window.location.host}${window.location.pathname}${qs.stringify(
      mergedQuery,
      {addQueryPrefix: true}
    )}`;
    const androidId = config.env.androidAppUrl ? new URL(config.env.androidAppUrl).searchParams.get('id') : '';

    let appUri = appLink;

    if (isAndroid && !isFirefox && androidId) {
      const matches = appLink.match(/([^:]+):\/\/(.+)$/i);
      if (matches) {
        const uriArr = ['intent://' + matches[2] + '#Intent', 'scheme=' + matches[1], 'package=' + androidId];
        if (
          (function () {
            const matched = navigator.userAgent.match(/Chrome\/(\d+)/);
            try {
              return matched && parseInt(matched[1]) >= 42;
            } catch (e) {
              return false;
            }
          })()
        ) {
          uriArr.push('S.browser_fallback_url=' + encodeURIComponent(webLink));
        }
        appUri = uriArr.join(';') + ';end';
      }
    }

    const iframe = document.createElement('iframe');
    iframe.id = 'deeplink_iframe';
    iframe.style.border = 'none';
    iframe.style.width = '0px';
    iframe.style.height = '0px';
    iframe.style.overflow = 'hidden';
    iframe.style.position = 'fixed';
    iframe.style.zIndex = '-10000';
    iframe.onload = function () {
      document.body.removeChild(iframe);
      window.location.href = appUri;
    };
    iframe.src = appUri;
    document.body.appendChild(iframe);
  }
};

const useDeepLinking = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    navigateToMobileApp(location, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useDeepLinking;
