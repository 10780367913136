import {KeyCloakLoginRequest} from '../../../features/auth/interfaces';
import {LoginResponse} from '../../services_deprecated/model/loginResponse';
import {axiosGrowthDay} from '../index';

export const loginApi = {
  loginKeyCloak: (request: KeyCloakLoginRequest): Promise<LoginResponse> => {
    return axiosGrowthDay.post<LoginResponse>('/login', request).then((res) => res.data);
  },
  setKeyCloakCookie: () => {
    return axiosGrowthDay.get('/me/keycloak');
  },
};
