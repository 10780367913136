import {DependencyList, useEffect} from 'react';

export type UseScrollToTopOptions = {
  el?: HTMLElement | Element | null;
  smooth?: boolean;
};

const useScrollToTop = (options?: UseScrollToTopOptions, deps: DependencyList = []) => {
  useEffect(() => {
    const _el = options?.el || document.getElementById('scroll-content') || document.body;

    if (options?.smooth) {
      _el.scroll({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      _el.scrollTop = 0;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useScrollToTop;
