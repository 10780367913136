import mem, {memClear} from 'mem';

const REFRESH_TOKEN_KEY = 'GD_REFRESH_TOKEN';

export const setRefreshAccessToken = (refreshToken: string) => {
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
};

const memoizedGetRefreshToken = () => {
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  if (refreshToken) {
    return refreshToken;
  }
};

export const clearStorageRefreshToken = () => {
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const memoRefreshAccessToken = mem(memoizedGetRefreshToken);

export const getRefreshAccessToken = () => {
  const refreshToken = memoRefreshAccessToken();
  if (refreshToken) {
    return refreshToken;
    // If there is no token, then clear cache and try again (this usually happens during login)
  } else {
    clearRefreshTokenCache();
    return memoRefreshAccessToken();
  }
};

export const clearRefreshTokenCache = () => {
  memClear(memoRefreshAccessToken);
};
