/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {SignupRequestPassword} from './signup-request-password';

/**
 *
 * @export
 * @interface SignupRequest
 */
export interface SignupRequest {
  /**
   *
   * @type {string}
   * @memberof SignupRequest
   */
  slug?: string;
  /**
   *
   * @type {string}
   * @memberof SignupRequest
   */
  campaignSlug?: string;
  /**
   *
   * @type {boolean}
   * @memberof SignupRequest
   */
  buyFlow?: boolean;
  /**
   *
   * @type {number}
   * @memberof SignupRequest
   */
  flowId?: number;
  /**
   *
   * @type {number}
   * @memberof SignupRequest
   */
  currentStepOrder?: number;
  /**
   *
   * @type {string}
   * @memberof SignupRequest
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof SignupRequest
   */
  skipCreditCardCollection?: boolean;
  /**
   *
   * @type {string}
   * @memberof SignupRequest
   */
  paymentMethodId?: string;
  /**
   *
   * @type {string}
   * @memberof SignupRequest
   */
  stripePriceId?: string;
  /**
   *
   * @type {string}
   * @memberof SignupRequest
   */
  zipCode?: string;
  /**
   *
   * @type {string}
   * @memberof SignupRequest
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof SignupRequest
   */
  subscriptionPlatform?: SignupRequestSubscriptionPlatformEnum;
  /**
   *
   * @type {string}
   * @memberof SignupRequest
   */
  transactionId?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof SignupRequest
   */
  offerIds?: Array<number>;
  /**
   *
   * @type {string}
   * @memberof SignupRequest
   */
  fullName?: string;
  /**
   *
   * @type {string}
   * @memberof SignupRequest
   */
  playerId?: string;
  /**
   *
   * @type {string}
   * @memberof SignupRequest
   */
  phoneNumber?: string;
  /**
   *
   * @type {SignupRequestPassword}
   * @memberof SignupRequest
   */
  password?: SignupRequestPassword;
  /**
   *
   * @type {string}
   * @memberof SignupRequest
   */
  captchaToken?: string;
}

export const SignupRequestSubscriptionPlatformEnum = {
  Web: 'WEB',
  Ios: 'IOS',
  Android: 'ANDROID',
} as const;

export type SignupRequestSubscriptionPlatformEnum =
  (typeof SignupRequestSubscriptionPlatformEnum)[keyof typeof SignupRequestSubscriptionPlatformEnum];
