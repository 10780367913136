/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {InvoiceItem} from './invoice-item';

/**
 *
 * @export
 * @interface PaymentTransaction
 */
export interface PaymentTransaction {
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentTransaction
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentTransaction
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof PaymentTransaction
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  receiptId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  paymentSystem?: PaymentTransactionPaymentSystemEnum;
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  lastFourDigit?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  cardType?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentTransaction
   */
  paidOn?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  currency?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentTransaction
   */
  totalAmountInCents?: number;
  /**
   *
   * @type {Array<InvoiceItem>}
   * @memberof PaymentTransaction
   */
  items?: Array<InvoiceItem>;
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  subscriptionPlanTitle?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  subscriptionPlanFrequency?: PaymentTransactionSubscriptionPlanFrequencyEnum;
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  uuid?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  consumerEmail?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentTransaction
   */
  taxAmountInCents?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  organizationId?: string;
  /**
   *
   * @type {number}
   * @memberof PaymentTransaction
   */
  balanceUsedInCents?: number;
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  teamId?: string;
  /**
   *
   * @type {string}
   * @memberof PaymentTransaction
   */
  paymentTransactionType?: PaymentTransactionPaymentTransactionTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof PaymentTransaction
   */
  gifted?: boolean;
}

export const PaymentTransactionPaymentSystemEnum = {
  Card: 'CARD',
  Paypal: 'PAYPAL',
  Apple: 'APPLE',
} as const;

export type PaymentTransactionPaymentSystemEnum =
  (typeof PaymentTransactionPaymentSystemEnum)[keyof typeof PaymentTransactionPaymentSystemEnum];
export const PaymentTransactionSubscriptionPlanFrequencyEnum = {
  Month: 'MONTH',
  Year: 'YEAR',
} as const;

export type PaymentTransactionSubscriptionPlanFrequencyEnum =
  (typeof PaymentTransactionSubscriptionPlanFrequencyEnum)[keyof typeof PaymentTransactionSubscriptionPlanFrequencyEnum];
export const PaymentTransactionPaymentTransactionTypeEnum = {
  Subscribed: 'SUBSCRIBED',
  Renewed: 'RENEWED',
  Offer: 'OFFER',
  OfferRenewed: 'OFFER_RENEWED',
} as const;

export type PaymentTransactionPaymentTransactionTypeEnum =
  (typeof PaymentTransactionPaymentTransactionTypeEnum)[keyof typeof PaymentTransactionPaymentTransactionTypeEnum];
