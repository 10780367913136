/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ScorecardSettingsRequest
 */
export interface ScorecardSettingsRequest {
  /**
   *
   * @type {boolean}
   * @memberof ScorecardSettingsRequest
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof ScorecardSettingsRequest
   */
  reportFrequency?: ScorecardSettingsRequestReportFrequencyEnum;
  /**
   *
   * @type {boolean}
   * @memberof ScorecardSettingsRequest
   */
  emailReminder?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ScorecardSettingsRequest
   */
  pushReminder?: boolean;
}

export const ScorecardSettingsRequestReportFrequencyEnum = {
  Monthly: 'MONTHLY',
  Quarterly: 'QUARTERLY',
} as const;

export type ScorecardSettingsRequestReportFrequencyEnum =
  (typeof ScorecardSettingsRequestReportFrequencyEnum)[keyof typeof ScorecardSettingsRequestReportFrequencyEnum];
