/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface PlatformInformation
 */
export interface PlatformInformation {
  /**
   *
   * @type {string}
   * @memberof PlatformInformation
   */
  idfa?: string;
  /**
   *
   * @type {string}
   * @memberof PlatformInformation
   */
  platform?: PlatformInformationPlatformEnum;
  /**
   *
   * @type {string}
   * @memberof PlatformInformation
   */
  advertising_id?: string;
}

export const PlatformInformationPlatformEnum = {
  Web: 'WEB',
  Ios: 'IOS',
  Android: 'ANDROID',
} as const;

export type PlatformInformationPlatformEnum =
  (typeof PlatformInformationPlatformEnum)[keyof typeof PlatformInformationPlatformEnum];
