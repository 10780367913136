/**
 * This file contains the code for handling signup redirects based on certain conditions.
 * If the new campaign flow is enabled and the URL matches the campaign pattern, the user is redirected to the campaign signup page.
 * If the URL matches the buy pattern, the user is redirected to the buy page.
 * If the new sign flow is enabled and the URL matches the signup pattern, the user is redirected to the general signup page.
 */
import {useIsNewCampaignFlowEnabled} from '@/features/auth/hooks/useIsNewCampaignFlowEnabled';
import {useIsNewBuyFlowEnabled} from '@/features/auth/hooks/useIsNewBuyFlowEnabled';
import {ACCESS_TOKEN_KEY} from '@/features/auth/routes/Profile/utils';
import useEffectOnMount from '@/shared/hooks/useEffectOnMount';
import {useDynamicConfig} from '@growthday/ui-core/src/features/feature-gate/hooks/useDynamicConfig';
import {DynamicConfigEnum, SignupConfigDynamicConfig} from '@growthday/ui-core/src/features/feature-gate/types';
import urlJoin, {Options as UrlJoinOptions} from 'proper-url-join';
import {useState} from 'react';
import {matchPath} from 'react-router';
import {Statsig} from 'statsig-react';
import qs from 'qs';

export const signupPatterns = ['/sign-up', '/signup'];
export const buyPatterns = ['/subscription/:slug', '/signup/:slug', '/buy/:slug', '/buy-subscription/:slug'];
export const campaignPatterns = ['/campaign/:campaignSlug'];
export const negativePatterns = [
  '/signup/group/:slug',
  '/signup/challenge/:slug',
  '/signup/plan',
  '/signup/info',
  '/signup/payment/credit-card',
  '/signup/payment',
];

/**
 * `useSignupRedirect` hook handles signup redirections
 *
 * @returns {boolean} - loading status during the redirection logic execution
 */
export function useSignupRedirect(): boolean {
  const [isLoading, setIsLoading] = useState(true);
  const isNewCampaignFlowEnabled = useIsNewCampaignFlowEnabled();
  const isNewBuyFlowEnabled = useIsNewBuyFlowEnabled();
  const dynamicConfig = useDynamicConfig<SignupConfigDynamicConfig>(DynamicConfigEnum.SignupConfig);
  const {signupUrl, loginQueryParam} = dynamicConfig?.config?.value ?? {};
  const queryParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  useEffectOnMount(() => {
    if (signupUrl) {
      const redirectToPath = redirectToSignup({
        isNewCampaignFlowEnabled,
        isNewBuyFlowEnabled,
        signupUrl,
        loginQueryParam,
        queryParams,
      });
      if (redirectToPath) {
        window.location.href = redirectToPath;
        return;
      }
    }
    setIsLoading(false);
  });

  return isLoading;
}

/**
 * Type definition for options passed to `redirectToSignup` function.
 *
 * @property {string} signupUrl - The base URL for signup.
 * @property {boolean} loginQueryParam - Flag to indicate if login query parameters should be included.
 * @property {boolean} isNewCampaignFlowEnabled - Flag indicating if the new campaign flow is enabled.
 * @property {boolean} isNewBuyFlowEnabled - Flag indicating if the new buy flow flow is enabled.
 */
export type RedirectToSignupOptions = {
  signupUrl: string;
  loginQueryParam: boolean;
  isNewCampaignFlowEnabled: boolean;
  isNewBuyFlowEnabled: boolean;
  queryParams?: qs.ParsedQs;
};

/**
 * The `redirectToSignup` function return the appropriate redirection path.
 * @param {RedirectToSignupOptions} options - Options containing parameters required to determine the redirect URL.
 * @returns {string | null} The URL to redirect the user to, or null if no redirect is necessary.
 */
export function redirectToSignup({
  signupUrl,
  loginQueryParam,
  isNewCampaignFlowEnabled,
  isNewBuyFlowEnabled,
  queryParams = {},
}: RedirectToSignupOptions): string | null {
  const negativeMatch = matchPath<{slug?: string}>(window.location.pathname, negativePatterns);
  if (negativeMatch) {
    return null;
  }
  const options: UrlJoinOptions =
    loginQueryParam && isLoggedIn()
      ? {query: {...queryParams, isLoggedIn: 'true'}}
      : queryParams?.token
        ? {query: {...queryParams, token: queryParams?.token.toString()}}
        : ({query: queryParams} as UrlJoinOptions);
  const campaignMatch = matchPath<{campaignSlug?: string}>(window.location.pathname, campaignPatterns);
  if (campaignMatch && campaignMatch.isExact && campaignMatch.params.campaignSlug) {
    const campaignFlowExperiment = Statsig.getExperiment(`${campaignMatch.params.campaignSlug}_campaign_flow`, true);
    const isNewFlowActiveForCampaign = campaignFlowExperiment?.get?.('isNewFlowActive', false);
    if (isNewFlowActiveForCampaign || isNewCampaignFlowEnabled || queryParams?.newflow === 'true') {
      return urlJoin(signupUrl, 'campaign', campaignMatch.params.campaignSlug, options);
    }
    return null;
  }
  if (isNewBuyFlowEnabled) {
    const matchSlug = matchPath<{slug?: string}>(window.location.pathname, buyPatterns);
    const signupMatch = matchPath<{slug?: string}>(window.location.pathname, signupPatterns);
    if (matchSlug?.params?.slug && !signupMatch?.isExact && !isLoggedIn()) {
      return urlJoin(signupUrl, 'buy', matchSlug.params.slug, options);
    }
  }

  const match = matchPath<{slug?: string}>(window.location.pathname, signupPatterns);
  if (match && match.isExact) {
    return urlJoin(signupUrl, options);
  }
  return null;
}

/**
 * Checks if the user is currently logged in.
 * @returns  {boolean} True if the user is logged in, otherwise false.
 */
function isLoggedIn(): boolean {
  try {
    const isLoggedIn = localStorage.getItem(ACCESS_TOKEN_KEY);
    return !!isLoggedIn;
  } catch (e) {
    return false;
  }
}
