import isEnvironment from '../config/util/isEnvironment';
import {EnvironmentsEnum} from '../config/enums';
import config from '../config';
import {useLayoutEffect} from 'react';
import {isEqual, omitBy} from 'lodash';
import {useLocation} from 'react-router-dom';
import {IUser} from '../shared/services_deprecated/model/user';
import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';

declare global {
  interface Window {
    fbq?: (...args: Array<unknown>) => void;
  }
}

export interface AdvancedMatching {
  ct?: string;
  country?: string;
  db?: string;
  em?: string;
  fn?: string;
  ge?: string;
  ln?: string;
  ph?: string;
  st?: string;
  zp?: string;
  external_id?: string;
}

type Data = Record<string, any>;

const isDevOrUat = isEnvironment(EnvironmentsEnum.UAT, EnvironmentsEnum.DEV);

const isTrackingEnabled =
  isEnvironment(EnvironmentsEnum.PROD, EnvironmentsEnum.UAT, EnvironmentsEnum.DEV) && config.env.fbPixelId;

const pixelId = config.env.fbPixelId!;
let installed = false;
let initialized = false;
let lastAdvancedMatchingData: AdvancedMatching = {};

const verifyInstall = () => installed;
const verifyInit = () => initialized;

const getLastAdvancedMatchingData = () => lastAdvancedMatchingData;
const standardEvents = [
  'AddPaymentInfo',
  'AddToCart',
  'AddToWishlist',
  'CompleteRegistration',
  'Contact',
  'CustomizeProduct',
  'Donate',
  'FindLocation',
  'InitiateCheckout',
  'Lead',
  'Purchase',
  'Schedule',
  'Search',
  'StartTrial',
  'SubmitApplication',
  'Subscribe',
  'ViewContent',
];

export const fbPixelActions = {
  match(user?: IUser) {
    if (user) {
      const advancedMatching: AdvancedMatching = omitBy(
        {
          em: user.email,
          ph: user.phoneNumber,
          ln: user.lastName,
          fn: user.firstName,
          zp: user.zipCode,
          country: user.iso2?.toLowerCase(),
          external_id: user.uuid,
        },
        (value) => !value
      );
      if (Object.keys(advancedMatching).length) {
        const _getLastAdvancedMatchingData = getLastAdvancedMatchingData();
        if (!isEqual(_getLastAdvancedMatchingData, advancedMatching)) {
          this._fbq('init', pixelId, advancedMatching);
          initialized = true;
          lastAdvancedMatchingData = advancedMatching;
        }
      }
    } else {
      if (!verifyInit()) {
        this._fbq('init', pixelId);
        initialized = true;
      }
    }
  },
  init(user?: IUser, autoConfig?: boolean) {
    installed = typeof window !== 'undefined' && !!window.fbq;

    if (pixelId) {
      if (!installed) {
        /* eslint-disable */
        (function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          // @ts-ignore
          n = f.fbq = function () {
            // @ts-ignore
            n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
          };
          // @ts-ignore
          if (!f._fbq) f._fbq = n;
          // @ts-ignore
          n.push = n;
          // @ts-ignore
          n.loaded = !0;
          // @ts-ignore
          n.version = '2.0';
          // @ts-ignore
          n.queue = [];
          // @ts-ignore
          t = b.createElement(e);
          // @ts-ignore
          t.async = !0;
          // @ts-ignore
          t.src = v;
          // @ts-ignore
          s = b.getElementsByTagName(e)[0];
          // @ts-ignore
          s.parentNode.insertBefore(t, s);
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        /* eslint-enable */
      }

      installed = true;

      if (autoConfig === false) {
        this._fbq('set', 'autoConfig', false, pixelId);
      }

      this.match(user);

      this.pageView();
    }
  },
  pageView() {
    if (!verifyInit()) {
      return;
    }
    this.fbq('track', 'PageView');
  },
  track(title: string, data?: Data | any, eventID?: string | any, user?: IUser) {
    this.match(user);
    if (!verifyInit()) {
      return;
    }
    this.fbq(standardEvents.includes(title) ? 'track' : 'trackCustom', title, data, {eventID});
  },
  _fbq(...args: Array<unknown>) {
    if (!verifyInstall()) {
      return;
    }
    try {
      window.fbq?.(...args);
    } catch (e: any) {
      if (isDevOrUat) {
        console.log('fb pixel error', e);
      }
    }
  },
  fbq(...args: Array<unknown>) {
    if (!verifyInit()) {
      return;
    }
    setTimeout(() => {
      try {
        if (isEnvironment(EnvironmentsEnum.DEV, EnvironmentsEnum.UAT)) {
          console.log('fb pixel event', args);
        }
        window.fbq?.(...args);
      } catch (e: any) {
        if (isDevOrUat) {
          console.log('fb pixel error', e);
        }
      }
    });
  },
};

const useFBPixel = () => {
  const {user} = useUser();
  const {pathname} = useLocation();
  useLayoutEffect(() => {
    if (isTrackingEnabled) {
      fbPixelActions.pageView();
    }
  }, [pathname]);
  useLayoutEffect(() => {
    if (isTrackingEnabled) {
      fbPixelActions.init(user, true);
    }
  }, [user]);
};

export default useFBPixel;
