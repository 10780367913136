import {createAsyncThunk} from '@reduxjs/toolkit';
import {message} from 'antd';
import {ActionCreators} from 'redux-undo';
import {userChallengesApi} from '../../shared/api';
import isChallengeFinished from './utils/is-challenge-finished';
import {UserChallengeStatusEnum} from './enums';
import {IChallenge, ICustomChallenge, ICustomChallengeTask, IUserChallenge} from './interfaces';

export const getFutureChallenges = createAsyncThunk<IChallenge[]>('challenges/getFutureChallenges', async () => {
  try {
    const futureChallenges = await userChallengesApi.retrieveInformationsFromStrapi();
    const challenges: IChallenge[] = futureChallenges.map((ouc) => {
      return {
        strapiChallenge: ouc,
        metadata: {
          numberOfParticipants: ouc.registrationCount,
        },
      };
    });

    return challenges;
  } catch (err: any) {
    message.error('Failed fetching future challenges');
    throw err;
  }
});

export const getChallenge = createAsyncThunk<
  IChallenge,
  {
    titleUid: number | string;
    idToFilter?: string | null;
    activeChallenges?: IChallenge[];
  }
>('challenges/getChallenge', async (challengeInfo) => {
  try {
    const strapiChallenge =
      typeof challengeInfo.titleUid === 'number'
        ? await userChallengesApi.getCmsChallenge(challengeInfo.titleUid)
        : await userChallengesApi.getCmsChallengeByTitleUid(challengeInfo.titleUid);

    if (!strapiChallenge) {
      return {
        strapiChallenge: strapiChallenge,
        userChallenge: undefined,
      };
    }

    let userChallenge: IUserChallenge | undefined;
    try {
      if (
        challengeInfo.activeChallenges?.find((ch) => ch.strapiChallenge?.titleUid === strapiChallenge.titleUid) ||
        challengeInfo.idToFilter ||
        !challengeInfo.activeChallenges
      ) {
        const apiUserChallenge = await userChallengesApi.getChallenge(strapiChallenge.id);
        if (apiUserChallenge) {
          const getLastElement = challengeInfo.idToFilter
            ? apiUserChallenge.find((ch) => ch.id === challengeInfo.idToFilter)
            : apiUserChallenge[apiUserChallenge.length - 1];
          userChallenge =
            getLastElement?.status === UserChallengeStatusEnum.IN_PROGRESS ||
            getLastElement?.status === UserChallengeStatusEnum.COMPLETED ||
            getLastElement?.status === UserChallengeStatusEnum.CLOSED
              ? getLastElement
              : undefined;
          if (
            isChallengeFinished(userChallenge) &&
            userChallenge?.cmsChallenge?.evergreenChallenge &&
            !challengeInfo.idToFilter
          ) {
            userChallenge = undefined;
          }
        }
      }
    } catch (e: any) {}
    const numberOfParticipants = await userChallengesApi.getRegistrations([strapiChallenge.id]);
    const challenge: IChallenge = {
      strapiChallenge: strapiChallenge,
      userChallenge: userChallenge,
      metadata: {
        numberOfParticipants: numberOfParticipants[strapiChallenge.id],
      },
    };

    return challenge;
  } catch (err: any) {
    if (err && err.response && err.response.status === 404) {
      return {
        strapiChallenge: undefined,
        userChallenge: undefined,
      };
    }
    message.error('Failed to fetch challenge');
    throw err;
  }
});

export const saveChallengeNote = createAsyncThunk<
  void,
  {
    userChallengeId: string;
    noteContent: string | null;
  }
>('challenges/saveChallengeNote', async ({noteContent, userChallengeId}) => {
  try {
    await userChallengesApi.saveNote(userChallengeId, noteContent);
  } catch (err: any) {
    message.error('Failed saving note for the challenge');
    throw err;
  }
});

export const getActiveChallenges = createAsyncThunk<IChallenge[]>('challenges/getActiveChallenges', async () => {
  // eslint-disable-next-line no-useless-catch
  try {
    const openUserChallenges = await userChallengesApi.listActiveChallenges();

    const challenges: IChallenge[] = openUserChallenges.map((ouc) => {
      return {
        strapiChallenge: ouc.cmsChallenge,
        userChallenge: ouc,
        metadata: {
          numberOfParticipants: ouc.cmsChallenge?.registrationCount ?? 0,
        },
      };
    });

    return challenges;
  } catch (err: any) {
    // message.error('Failed fetching active challenges');
    throw err;
  }
});

export const getCompletedChallenges = createAsyncThunk<IChallenge[]>('challenges/getCompletedChallenges', async () => {
  // eslint-disable-next-line no-useless-catch
  try {
    const completedUserChallenges = await userChallengesApi.getCompletedUserChallenges();

    const query = completedUserChallenges.reduce((prevVal, elem) => prevVal + `${elem.challengeId},`, '');

    if (!query || query.length === 0) {
      return [];
    }

    const strapiChallenges = await userChallengesApi.loadChallengesFromStrapi(`cms?challengeIds=${query}`);

    const challenges: IChallenge[] = completedUserChallenges.map((cuc) => {
      const sc = strapiChallenges.find((sc) => sc.id === cuc.challengeId)!;
      return {
        strapiChallenge: sc,
        userChallenge: cuc,
        metadata: {
          numberOfParticipants: cuc.cmsChallenge?.registrationCount ?? 0,
        },
      };
    });

    return challenges;
  } catch (err: any) {
    // message.error('Failed fetching completed challenges');
    throw err;
  }
});

export const joinChallenge = createAsyncThunk<IUserChallenge | void, number>(
  'challenges/joinChallenge',
  async (challengeId) => {
    try {
      const createdUserChallenge = await userChallengesApi.enrollInChallenge(challengeId);
      message.success('Successfully enrolled in the challenge!');
      return createdUserChallenge;
    } catch (err: any) {
      message.error('Failed to join the challenge');
    }
  }
);

export const leaveChallenge = createAsyncThunk<void, string>('challenges/leaveChallenge', async (userChallengeId) => {
  try {
    await userChallengesApi.leaveChallenge(userChallengeId);
    message.success('Successfully left the challenge!');
  } catch (err: any) {
    message.error('Failed to leave the challenge');
  }
});

export const markCustomChallengeItemAsComplete = createAsyncThunk<
  ReturnType<typeof userChallengesApi.markCustomChallengeItemAsComplete>,
  {
    userChallengeId: string;
    ccId: ICustomChallenge['id'];
    cctId: ICustomChallengeTask['id'];
  }
>('challenges/markCustomChallengeItemAsComplete', async ({cctId, ccId, userChallengeId}, {dispatch}) => {
  try {
    const response = await userChallengesApi.markCustomChallengeItemAsComplete(userChallengeId, {
      id: ccId,
      customChallengeTasks: [
        {
          id: cctId,
        },
      ],
    });
    return response;
  } catch (err: any) {
    dispatch(ActionCreators.undo());
    message.error('Failed to complete the task, please try again later');
    throw err;
  }
});

export const markCustomChallengeItemAsCompleteUndo = createAsyncThunk<
  {
    ccId: ICustomChallenge['id'];
    taskUndo: number;
  },
  {
    userChallengeId: string;
    ccId: ICustomChallenge['id'];
    taskUndo: number;
    tasks: number[];
  }
>('challenges/markCustomChallengeItemAsCompleteUndo', async ({tasks, ccId, userChallengeId, taskUndo}, {dispatch}) => {
  try {
    await userChallengesApi.markCustomChallengeItemAsCompleteUndo(userChallengeId, ccId, tasks);
    return {
      ccId: ccId,
      taskUndo: taskUndo,
    };
  } catch (err: any) {
    dispatch(ActionCreators.undo());
    message.error('Failed to undo the task completed, please try again later');
    throw err;
  }
});

export const markChallengesCompletedAsRead = createAsyncThunk<IUserChallenge[]>(
  'challenges/markChallengesCompletedAsRead',
  async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      await userChallengesApi.markChallengesAsRead();
      return [];
    } catch (err: any) {
      throw err;
    }
  }
);

export const getUnreadChallenges = createAsyncThunk<IUserChallenge[]>('challenges/getUnreadChallenges', async () => {
  // eslint-disable-next-line no-useless-catch
  try {
    const unreadChallenges = await userChallengesApi.getUnreadChallenges();
    return unreadChallenges;
  } catch (err: any) {
    throw err;
  }
});
