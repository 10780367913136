import {IAccomplishments} from './accomplishments';
import {ICancelConfirmation} from './cancel-confirmation';
import {ICancelCta} from './cancel-cta';
import {ICancelFlowOffer} from './cancel-flow-offer';
import {IDescription} from './description';
import {IDowngradeUpgrade} from './downgrade-upgrade';
import {IFlowMedia} from './flow-media';
import {IHeader} from './header';
import {IOkCta} from './ok-cta';

/**
 * Model definition for Cancel-flow-modal
 */
export interface ICancelFlowModal {
  id: number;
  name?: string;
  contentComponents: (
    | ({__component: 'cancel-flow.accomplishments'} & IAccomplishments)
    | ({__component: 'cancel-flow.cancel-confirmation'} & ICancelConfirmation)
    | ({__component: 'cancel-flow.cancel-cta'} & ICancelCta)
    | ({__component: 'cancel-flow.downgrade-upgrade'} & IDowngradeUpgrade)
    | ({__component: 'cancel-flow.cancel-flow-offer'} & ICancelFlowOffer)
    | ({__component: 'cancel-flow.ok-cta'} & IOkCta)
    | ({__component: 'flow.description'} & IDescription)
    | ({__component: 'flow.header'} & IHeader)
    | ({__component: 'flow.flow-media'} & IFlowMedia)
  )[];
  onAcceptComponents: (
    | ({__component: 'cancel-flow.accomplishments'} & IAccomplishments)
    | ({__component: 'cancel-flow.cancel-cta'} & ICancelCta)
    | ({__component: 'cancel-flow.cancel-flow-offer'} & ICancelFlowOffer)
    | ({__component: 'cancel-flow.ok-cta'} & IOkCta)
    | ({__component: 'flow.description'} & IDescription)
    | ({__component: 'flow.header'} & IHeader)
    | ({__component: 'flow.flow-media'} & IFlowMedia)
  )[];
  modalType?: EnumCancelFlowModalModalType;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumCancelFlowModalModalType {
  accomplishments = 'accomplishments',
  offer = 'offer',
  downgrade = 'downgrade',
  upgrade = 'upgrade',
  cancelConfirmation = 'cancelConfirmation',
}
