/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {CmsAccess} from './cms-access';
// May contain unused imports in some cases
// @ts-ignore
import {CmsMedia} from './cms-media';
// May contain unused imports in some cases
// @ts-ignore
import {Hms} from './hms';
// May contain unused imports in some cases
// @ts-ignore
import {TopParticipant} from './top-participant';

/**
 *
 * @export
 * @interface GrowthGroupInstance
 */
export interface GrowthGroupInstance {
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupInstance
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupInstance
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupInstance
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  groupId: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  rrule?: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  rruleString?: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  channel: GrowthGroupInstanceChannelEnum;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  uuid?: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  ianaTimezone?: string;
  /**
   *
   * @type {Array<TopParticipant>}
   * @memberof GrowthGroupInstance
   */
  topParticipants?: Array<TopParticipant>;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  authToken?: string;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupInstance
   */
  strapiId?: number;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupInstance
   */
  isWorkshop?: boolean;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupInstance
   */
  rsvpCount?: number;
  /**
   *
   * @type {Hms}
   * @memberof GrowthGroupInstance
   */
  hms?: Hms;
  /**
   *
   * @type {Set<string>}
   * @memberof GrowthGroupInstance
   */
  participantsUuids?: Set<string>;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  date: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  time: string;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupInstance
   */
  startTime: number;
  /**
   *
   * @type {CmsMedia}
   * @memberof GrowthGroupInstance
   */
  image?: CmsMedia;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  agenda: string;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupInstance
   */
  duration?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupInstance
   */
  endTime: number;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  type: GrowthGroupInstanceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  slug: string;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupInstance
   */
  canGuestInvite?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupInstance
   */
  recording?: boolean;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupInstance
   */
  actualStartTime?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupInstance
   */
  actualEndTime?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupInstance
   */
  admitAllTime?: number;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  reason?: GrowthGroupInstanceReasonEnum;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupInstance
   */
  cancelled?: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof GrowthGroupInstance
   */
  pinnedUuids?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupInstance
   */
  previewChatCleaned?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupInstance
   */
  admitAll?: boolean;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  hostFirstPromoterRefId?: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  redirectTo?: string;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupInstance
   */
  hlsGroup?: boolean;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  channelArn?: string;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupInstance
   */
  premium?: boolean;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupInstance
   */
  packageCostInCents?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupInstance
   */
  instanceCostInCents?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupInstance
   */
  maxParticipantLimit?: number;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  currency?: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  groupType?: GrowthGroupInstanceGroupTypeEnum;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  keynoteState?: GrowthGroupInstanceKeynoteStateEnum;
  /**
   *
   * @type {CmsMedia}
   * @memberof GrowthGroupInstance
   */
  liveMedia?: CmsMedia;
  /**
   *
   * @type {CmsMedia}
   * @memberof GrowthGroupInstance
   */
  replayMedia?: CmsMedia;
  /**
   *
   * @type {CmsAccess}
   * @memberof GrowthGroupInstance
   */
  liveAccess?: CmsAccess;
  /**
   *
   * @type {CmsAccess}
   * @memberof GrowthGroupInstance
   */
  replayAccess?: CmsAccess;
  /**
   *
   * @type {Set<string>}
   * @memberof GrowthGroupInstance
   */
  moderatorUuids?: Set<string>;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  keynoteRedirectSlug?: string;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupInstance
   */
  amountTransferredToHost?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupInstance
   */
  startedRecording?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupInstance
   */
  reminderNotificationSent?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupInstance
   */
  stopPayout?: boolean;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstance
   */
  state?: GrowthGroupInstanceStateEnum;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupInstance
   */
  free?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupInstance
   */
  isFree?: boolean;
}

export const GrowthGroupInstanceChannelEnum = {
  Audio: 'AUDIO',
  Video: 'VIDEO',
} as const;

export type GrowthGroupInstanceChannelEnum =
  (typeof GrowthGroupInstanceChannelEnum)[keyof typeof GrowthGroupInstanceChannelEnum];
export const GrowthGroupInstanceTypeEnum = {
  Private: 'PRIVATE',
  Public: 'PUBLIC',
} as const;

export type GrowthGroupInstanceTypeEnum =
  (typeof GrowthGroupInstanceTypeEnum)[keyof typeof GrowthGroupInstanceTypeEnum];
export const GrowthGroupInstanceReasonEnum = {
  CancelledByHost: 'CANCELLED_BY_HOST',
  NotStartedOnTime: 'NOT_STARTED_ON_TIME',
  NotEnoughRsvp: 'NOT_ENOUGH_RSVP',
  SubscriptionCanceled: 'SUBSCRIPTION_CANCELED',
  DowngradeSubscription: 'DOWNGRADE_SUBSCRIPTION',
  HostDeleted: 'HOST_DELETED',
} as const;

export type GrowthGroupInstanceReasonEnum =
  (typeof GrowthGroupInstanceReasonEnum)[keyof typeof GrowthGroupInstanceReasonEnum];
export const GrowthGroupInstanceGroupTypeEnum = {
  GrowthGroup: 'GROWTH_GROUP',
  Workshop: 'WORKSHOP',
  Keynote: 'KEYNOTE',
} as const;

export type GrowthGroupInstanceGroupTypeEnum =
  (typeof GrowthGroupInstanceGroupTypeEnum)[keyof typeof GrowthGroupInstanceGroupTypeEnum];
export const GrowthGroupInstanceKeynoteStateEnum = {
  StartsInFuture: 'STARTS_IN_FUTURE',
  Live: 'LIVE',
  Ended: 'ENDED',
  Replay: 'REPLAY',
  RedirectToEvent: 'REDIRECT_TO_EVENT',
} as const;

export type GrowthGroupInstanceKeynoteStateEnum =
  (typeof GrowthGroupInstanceKeynoteStateEnum)[keyof typeof GrowthGroupInstanceKeynoteStateEnum];
export const GrowthGroupInstanceStateEnum = {
  StartsInFuture: 'STARTS_IN_FUTURE',
  StartsSoon: 'STARTS_SOON',
  WaitingForHost: 'WAITING_FOR_HOST',
  Cancelled: 'CANCELLED',
  LiveNow: 'LIVE_NOW',
  Ended: 'ENDED',
} as const;

export type GrowthGroupInstanceStateEnum =
  (typeof GrowthGroupInstanceStateEnum)[keyof typeof GrowthGroupInstanceStateEnum];
