import {createAction, createAsyncThunk} from '@reduxjs/toolkit';
import displayApiError from '../../shared/util/display-api-error';
import {noteDetails, userNotesApi} from './userNotes/userNotes.api';
import {IUserNote} from '@growthday/ui-core/src/types/notes';

export const getAllNotes = createAsyncThunk<any>('auth/GetAllNotes', async () => {
  try {
    return await userNotesApi.getNotes();
  } catch (err: any) {
    displayApiError(err, 'Failed request');
    throw err;
  }
});

export const pinOneOfNotes = createAsyncThunk('notes/pinOneOfNotes', async (value: noteDetails) => {
  try {
    await userNotesApi.pinSomeNote(value);
    return value;
  } catch (err: any) {
    err.response.data.debugMessage === 'Note already pinned'
      ? displayApiError(err, err.response.data.debugMessage)
      : displayApiError(err, 'Failed request');
    throw err;
  }
});

export const unpinOneOfNotes = createAsyncThunk('notes/unpinOneOfNotes', async (value: noteDetails) => {
  try {
    await userNotesApi.unpinSomeNote(value);
    return value;
  } catch (err: any) {
    displayApiError(err, 'Failed request');
    throw err;
  }
});

export const updateLessonNote = createAsyncThunk(
  'notes/updateLessonNote',
  async (noteData: {courseId: number; lessonId: number; noteContent: string; noteId: string}) => {
    try {
      await userNotesApi.saveLessonNote(noteData);
      return noteData;
    } catch (err: any) {
      displayApiError(err, 'Failed saving note for an event');
      throw err;
    }
  }
);

export const updateLiveEventNote = createAsyncThunk<any, {eventId: string; note: string | null}>(
  'learn/updateLiveEventNote',
  async ({eventId, note}) => {
    try {
      await userNotesApi.saveLiveEventNote(eventId, note);
      return {id: eventId, noteContent: note};
    } catch (err: any) {
      displayApiError(err, 'Failed saving note for an event');
      throw err;
    }
  }
);

export const updateChallengeNote = createAsyncThunk<any, {eventId: string; note: string | null}>(
  'learn/updateChallengeNote',
  async ({eventId, note}) => {
    try {
      await userNotesApi.saveChallengeNote(eventId, note);
      return {id: eventId, noteContent: note};
    } catch (err: any) {
      displayApiError(err, 'Failed saving note for an event');
      throw err;
    }
  }
);

export const deleteSomeNote = createAsyncThunk<any, {courseId: string; lessonId: string; noteId: string}>(
  'learn/deleteSomeNote',
  async ({courseId, lessonId, noteId}) => {
    try {
      await userNotesApi.deleteSomeNote(courseId, lessonId, noteId);
      return {courseId, lessonId, noteId};
    } catch (err: any) {
      displayApiError(err, 'Failed saving note for an event');
      throw err;
    }
  }
);

export const deleteGroupNote = createAction<IUserNote['id']>('learn/deleteGroupNote');
export const updateGroupNote = createAction<Pick<IUserNote, 'id' | 'content'>>('learn/updateGroupNote');
