import {Checkbox} from 'antd';
import Form from 'antd/es/form';
import React, {FC, useState} from 'react';
import {useHistory} from 'react-router-dom';
import useGetUserMetadata from '@growthday/ui-core/src/features/usermetadata/useGetUserMetadata';
import GDButton from '../../../../shared/components/ui/Button';
import GDInput from '../../../../shared/components/ui/Form/Input';
import GDFormItem from '../../../../shared/components/ui/Form/Item';
import commonStyles from '../../../../shared/style/commonStyles.module.less';
import useAuthState from '../../../auth/hooks/useAuthState';
import useMyProfile from '../../../auth/routes/Profile/hooks/useMyProfile';
import {authValidations} from '../../../auth/validations';
import {LicenseRoutesEnum} from '../../../license/interfaces';
import useTransferAccountMutation from '../../api/useTransferAccountMutation';
import styles from './Terms.module.less';
import EmptyLayout from '../../../../shared/components/EmptyLayout/EmptyLayout';
import ShadowCard from '../../../../shared/components/ui/ShadowCard/ShadowCard';
import GDTypography from '../../../../shared/components/ui/Typography/Typography';
import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';
import config from '@/config';

const initialValues = {
  email: '',
};

type FormValues = typeof initialValues;

const Terms: FC = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [form] = Form.useForm<FormValues>();
  const {mutateAsync, isLoading} = useTransferAccountMutation();
  const [errorMsg, setErrMsg] = useState('');
  const {refetchMyProfile} = useMyProfile();
  const {accessToken} = useAuthState();
  const {user} = useUser();
  const {data: userMetadata} = useGetUserMetadata(Boolean(user && accessToken));
  const history = useHistory();

  const transfer = userMetadata?.transferEnterpriseAccount;

  const handleSubmit = async (info: FormValues) => {
    if (isChecked) {
      try {
        await mutateAsync(info.email);
        await refetchMyProfile();
        history.push(`/license/${LicenseRoutesEnum.SUBSCRIPTION_OFFERS}`);
      } catch (e: any) {
        if (e?.message) {
          setErrMsg(e.message);
        }
      }
    }
  };
  return (
    <EmptyLayout withBg>
      <div className={styles.container}>
        <ShadowCard className={styles.card}>
          <Form
            form={form}
            layout="vertical"
            onFinish={(value) => handleSubmit(value)}
            scrollToFirstError
            requiredMark={false}
            initialValues={initialValues}
          >
            <GDTypography type="w500" subType="s" gutterBottom block>
              You’ve been removed from your organization
            </GDTypography>
            <GDTypography type="w100" className={commonStyles.lightText2} gutterBottom block>
              You may use GrowthDay until the existing subscription from your previous employer expires. Once it
              expires, you will not be able to renew with them. However, you can purchase your own subscription at that
              point in time.
            </GDTypography>
            {transfer && (
              <>
                <GDTypography type="w100" className={commonStyles.lightText2} gutterBottom block>
                  To continue, please enter a new email to transfer your enterprise subscription to a personal account.
                </GDTypography>
                <GDFormItem name="email" rules={[authValidations.emailRequired]}>
                  <GDInput size="middle" onChange={() => setErrMsg('')} placeholder="Email Address" />
                </GDFormItem>
                {errorMsg && <div className={styles.errMessage}>{errorMsg}</div>}
              </>
            )}
            <Checkbox
              onChange={(e) => setIsChecked(e.target.checked)}
              checked={isChecked}
              className={commonStyles.checkbox}
            >
              <GDTypography gutterBottom block type="w50" className={styles.lightText}>
                By checking this box, you agree to our{' '}
                <a href={config.env.settings.privacy.termsOfServiceLink} target="_blank" rel="noopener noreferrer">
                  Terms of Service
                </a>{' '}
                and{' '}
                <a href={config.env.settings.privacy.privacyPolicyLink} target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </GDTypography>
            </Checkbox>
            <GDButton
              disabled={!isChecked}
              block
              size="large"
              htmlType="submit"
              key="submit"
              type="primary"
              loading={isLoading}
            >
              Continue
            </GDButton>
          </Form>
        </ShadowCard>
      </div>
    </EmptyLayout>
  );
};

export default Terms;
