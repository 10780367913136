/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ChatRequest
 */
export interface ChatRequest {
  /**
   *
   * @type {string}
   * @memberof ChatRequest
   */
  chatId: string;
  /**
   *
   * @type {string}
   * @memberof ChatRequest
   */
  message?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof ChatRequest
   */
  recipientRoles?: Array<ChatRequestRecipientRolesEnum>;
  /**
   *
   * @type {string}
   * @memberof ChatRequest
   */
  sender?: string;
  /**
   *
   * @type {string}
   * @memberof ChatRequest
   */
  senderName?: string;
  /**
   *
   * @type {string}
   * @memberof ChatRequest
   */
  senderRole?: ChatRequestSenderRoleEnum;
  /**
   *
   * @type {string}
   * @memberof ChatRequest
   */
  time?: string;
  /**
   *
   * @type {string}
   * @memberof ChatRequest
   */
  senderUserId?: string;
  /**
   *
   * @type {boolean}
   * @memberof ChatRequest
   */
  pinMessage?: boolean;
}

export const ChatRequestRecipientRolesEnum = {
  Host: 'host',
  HostBackstage: 'host-backstage',
  Attendee: 'attendee',
  CoHost: 'co-host',
  CoHostBackstage: 'co-host-backstage',
  Speaker: 'speaker',
  Preview: 'preview',
  WaitingRoom: 'waiting-room',
  Beam: 'beam',
} as const;

export type ChatRequestRecipientRolesEnum =
  (typeof ChatRequestRecipientRolesEnum)[keyof typeof ChatRequestRecipientRolesEnum];
export const ChatRequestSenderRoleEnum = {
  Host: 'host',
  HostBackstage: 'host-backstage',
  Attendee: 'attendee',
  CoHost: 'co-host',
  CoHostBackstage: 'co-host-backstage',
  Speaker: 'speaker',
  Preview: 'preview',
  WaitingRoom: 'waiting-room',
  Beam: 'beam',
} as const;

export type ChatRequestSenderRoleEnum = (typeof ChatRequestSenderRoleEnum)[keyof typeof ChatRequestSenderRoleEnum];
