/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface UserNoteQueryParam
 */
export interface UserNoteQueryParam {
  /**
   *
   * @type {string}
   * @memberof UserNoteQueryParam
   */
  entityType?: UserNoteQueryParamEntityTypeEnum;
  /**
   *
   * @type {string}
   * @memberof UserNoteQueryParam
   */
  entityId?: string;
  /**
   *
   * @type {string}
   * @memberof UserNoteQueryParam
   */
  subEntityId?: string;
}

export const UserNoteQueryParamEntityTypeEnum = {
  GrowthGroup: 'GROWTH_GROUP',
  LiveEvent: 'LIVE_EVENT',
  Course: 'COURSE',
  Challenge: 'CHALLENGE',
} as const;

export type UserNoteQueryParamEntityTypeEnum =
  (typeof UserNoteQueryParamEntityTypeEnum)[keyof typeof UserNoteQueryParamEntityTypeEnum];
