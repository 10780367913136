import {useMemo} from 'react';
import {useAuthState} from '../external';
import useUser from '../features/user/hooks/useUser';

export function useAuthData() {
  const {accessToken} = useAuthState();
  const {user} = useUser();

  return useMemo(() => ({accessToken, user, isLoggedIn: Boolean(accessToken && user)}), [accessToken, user]);
}
