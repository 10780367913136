import {isEqual} from 'lodash';
import {useRef} from 'react';

/**
 * A custom hook that performs a deep comparison between the current and the new value of a given variable.
 * It uses lodash's `isEqual` method for deep comparison. If the values are different, the hook updates
 * its internal reference to the new value. This is particularly useful in scenarios where you need
 * to compare complex objects or arrays and react to changes in their properties, not just reference changes.
 *
 * @typeParam T - The type of the value being compared.
 * @param value - The value to be compared and potentially stored in the hook.
 * @returns The latest value that passed the deep comparison. If the new value is different from the current,
 *          it updates the internal reference to the new value, otherwise, it retains the current value.
 */
export default function useDeepCompare<T>(value: T): T {
  const latestValue = useRef(value);
  if (!isEqual(latestValue.current, value)) {
    latestValue.current = value;
  }
  return latestValue.current;
}
