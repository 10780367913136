import {
  getCourse,
  getLesson,
  saveLessonNote,
  updateLessonProgress,
  deleteLessonNote,
  markLessonAsCompleted,
  markCourseAsCompleted,
  getUserCourse,
} from './routes/Course/api.actions';

export const apiActions = {
  getUserCourse,
  getCourse,
  saveLessonNote,
  updateLessonProgress,
  getLesson,
  deleteLessonNote,
  markLessonAsCompleted,
  markCourseAsCompleted,
};
