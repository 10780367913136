/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface InvoiceItem
 */
export interface InvoiceItem {
  /**
   *
   * @type {string}
   * @memberof InvoiceItem
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceItem
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceItem
   */
  currency?: string;
  /**
   *
   * @type {number}
   * @memberof InvoiceItem
   */
  amountInCents?: number;
  /**
   *
   * @type {string}
   * @memberof InvoiceItem
   */
  itemType?: InvoiceItemItemTypeEnum;
  /**
   *
   * @type {number}
   * @memberof InvoiceItem
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof InvoiceItem
   */
  frequency?: InvoiceItemFrequencyEnum;
  /**
   *
   * @type {number}
   * @memberof InvoiceItem
   */
  periodStart?: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceItem
   */
  periodEnd?: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceItem
   */
  discountInCents?: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceItem
   */
  taxInCents?: number;
  /**
   *
   * @type {number}
   * @memberof InvoiceItem
   */
  quantity?: number;
  /**
   *
   * @type {string}
   * @memberof InvoiceItem
   */
  paymentTransactionId?: string;
  /**
   *
   * @type {string}
   * @memberof InvoiceItem
   */
  itemReferenceId?: string;
}

export const InvoiceItemItemTypeEnum = {
  SubscriptionPlan: 'SUBSCRIPTION_PLAN',
  Offer: 'OFFER',
  GgPackage: 'GG_PACKAGE',
  GrowthGroupAttendee: 'GROWTH_GROUP_ATTENDEE',
} as const;

export type InvoiceItemItemTypeEnum = (typeof InvoiceItemItemTypeEnum)[keyof typeof InvoiceItemItemTypeEnum];
export const InvoiceItemFrequencyEnum = {
  Month: 'MONTH',
  Year: 'YEAR',
  OneTime: 'ONE_TIME',
} as const;

export type InvoiceItemFrequencyEnum = (typeof InvoiceItemFrequencyEnum)[keyof typeof InvoiceItemFrequencyEnum];
