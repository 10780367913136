import getRedirectUrl from './getRedirectUrl';
import config from '../../../../../config';
import urlJoin from 'proper-url-join';

/**
 * Utility to get auth redirection URL
 */
const getAuthUrl = (error?: string, encryptedEmail?: string) => {
  // Query parameters needed for keycloak
  const query: Record<string, string> = {
    client_id: config.env.authClientId,
    redirect_uri: getRedirectUrl(),
    response_type: 'code',
  };
  // If redirected to login page with error
  if (error) {
    query.error = error;
  }
  // If email autofill is enabled, pass the token to keycloak
  if (encryptedEmail && config.env.login.emailAutofill) {
    query.loginId = encryptedEmail;
  }

  return urlJoin(config.env.authUrl, '/auth/realms/', config.env.authRealm, '/protocol/openid-connect/auth', {query});
};

export default getAuthUrl;
