/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface UserTourRequest
 */
export interface UserTourRequest {
  /**
   *
   * @type {string}
   * @memberof UserTourRequest
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof UserTourRequest
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof UserTourRequest
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof UserTourRequest
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof UserTourRequest
   */
  trigger: UserTourRequestTriggerEnum;
  /**
   *
   * @type {string}
   * @memberof UserTourRequest
   */
  triggerId: string;
  /**
   *
   * @type {string}
   * @memberof UserTourRequest
   */
  triggerType: UserTourRequestTriggerTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof UserTourRequest
   */
  started?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserTourRequest
   */
  completed?: boolean;
  /**
   *
   * @type {number}
   * @memberof UserTourRequest
   */
  progress?: number;
}

export const UserTourRequestTriggerEnum = {
  ChallengeLoad: 'ON_CHALLENGE_LOAD',
  ChallengeFirstTaskComplete: 'ON_CHALLENGE_FIRST_TASK_COMPLETE',
  ChallengeDayOneComplete: 'ON_CHALLENGE_DAY_ONE_COMPLETE',
  ChallengeDayTwoComplete: 'ON_CHALLENGE_DAY_TWO_COMPLETE',
  ChallengeDayThreeComplete: 'ON_CHALLENGE_DAY_THREE_COMPLETE',
  ChallengeDayFourComplete: 'ON_CHALLENGE_DAY_FOUR_COMPLETE',
  ChallengeDayFiveComplete: 'ON_CHALLENGE_DAY_FIVE_COMPLETE',
  DashboardLoad: 'ON_DASHBOARD_LOAD',
  EnterpriseDashboardLoad: 'ON_ENTERPRISE_DASHBOARD_LOAD',
} as const;

export type UserTourRequestTriggerEnum = (typeof UserTourRequestTriggerEnum)[keyof typeof UserTourRequestTriggerEnum];
export const UserTourRequestTriggerTypeEnum = {
  Challenge: 'CHALLENGE',
  Plan: 'PLAN',
  Offer: 'OFFER',
  Campaign: 'CAMPAIGN',
  EnterpriseAdmin: 'ENTERPRISE_ADMIN',
} as const;

export type UserTourRequestTriggerTypeEnum =
  (typeof UserTourRequestTriggerTypeEnum)[keyof typeof UserTourRequestTriggerTypeEnum];
