export const endpoints = {
  plans: 'plans',
  tasks: 'plans/tasks',
  subtasks: 'plans/subtasks',
  updatePlanOrder: 'plans/updateOrder',
  updateTaskOrder: 'plans/tasks/updateOrder',
  updateSubTaskOrder: 'plans/tasks/updateOrder',
  completedPlans: 'plans/completed',
  completePlan: 'plans/complete',
  completeTask: 'plans/complete',
  completeSubTask: 'plans/complete/subtask',
  recoverPlan: 'plans/recover',
  recoverTask: 'plans/recover',
  recoverSubTask: 'plans/recover/subtask',
};
