import qs from 'qs';
import urlJoin from 'proper-url-join';

export function parseQueryString(url: string = '') {
  const [path = '', query = ''] = url?.split('?') ?? [];
  return {
    path,
    params: qs.parse(query),
  };
}

export function appendQueryString(url: string = '', inputParams: Record<string, any> = {}, replace?: boolean) {
  const {path, params} = parseQueryString(url);
  const newParams = replace ? inputParams : {...params, ...inputParams};
  const query = qs.stringify(newParams, {encodeValuesOnly: true});
  return urlJoin(path, query ? `?${query}` : '', {leadingSlash: 'keep'});
}
