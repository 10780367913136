import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IChallenge, IChallengesLoadingState, IChallengesState, ICustomChallenge, IUserChallenge} from './interfaces';
import {stateWithLoading} from '../../shared/redux/util/state-with-loading';
import {getApiActionBuilder} from '../../shared/redux/util/build-api-action';
import * as apiActions from './challenges.actions';
import _unset from 'lodash/unset';
import _remove from 'lodash/remove';
import dayjs from 'dayjs';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';
import {pickBy} from 'lodash';

const persistConfig = {
  key: 'hpx:challenge',
  storage: storage,
  whitelist: ['welcomeModal'],
};

const initialState = stateWithLoading<IChallengesState, IChallengesLoadingState>({
  activeChallenges: [],
  futureChallenges: [],
  completedChallenges: [],
  unreadChallenges: [],
  welcomeModal: {},
  alreadyFetchedActiveChallenges: false,
});

export const challengesSlice = createSlice<
  IChallengesState,
  {
    unsetChallengesState: CaseReducer<IChallengesState, PayloadAction<string | string[]>>;
    updateChallengeWelcomeModal: CaseReducer<IChallengesState, PayloadAction<Record<number, boolean>>>;
  }
>({
  name: 'challenges',
  initialState,
  reducers: {
    unsetChallengesState(state, action) {
      const keys = Array.isArray(action.payload) ? action.payload : [action.payload];
      keys.forEach((key) => {
        _unset(state, key);
      });
    },
    updateChallengeWelcomeModal(state, action) {
      state.welcomeModal = pickBy({...state.welcomeModal, ...action.payload}, (value) => value);
    },
  },
  extraReducers: (builder) => {
    const buildApiAction = getApiActionBuilder<IChallengesState>(builder, apiActions);

    buildApiAction<PayloadAction<IChallenge[]>>('getFutureChallenges', {
      fulfilled: (state, action) => {
        state.futureChallenges = action.payload;
      },
    });

    buildApiAction<PayloadAction<IChallenge[]>>('getActiveChallenges', {
      fulfilled: (state, action) => {
        state.activeChallenges = action.payload;
        state.alreadyFetchedActiveChallenges = true;
      },
    });

    buildApiAction<PayloadAction<IChallenge[]>>('getCompletedChallenges', {
      fulfilled: (state, action) => {
        state.completedChallenges = action.payload;
      },
    });

    buildApiAction<PayloadAction<IUserChallenge, string, {arg: number}>>('joinChallenge', {
      pending: (state, action) => {
        state.loading.joinChallenge = action.meta!.arg;
      },
      fulfilled: (state, action) => {
        const challengeId = action.meta!.arg;
        const [removedActiveChallenge] = _remove(
          state.futureChallenges,
          (fc) => fc.strapiChallenge!.id === challengeId
        );

        if (removedActiveChallenge) {
          removedActiveChallenge.userChallenge = action.payload;
          removedActiveChallenge.metadata!.numberOfParticipants++;
          state.activeChallenges.push(removedActiveChallenge);
          state.activeChallenges.sort((a, b) => {
            return dayjs(a.strapiChallenge!.startDate).isAfter(dayjs(b.strapiChallenge!.startDate)) ? 1 : -1;
          });
        }
      },
    });

    buildApiAction<PayloadAction<string, string, {arg: string}>>('leaveChallenge', {
      pending: (state, action) => {
        state.loading.leaveChallenge = action.meta!.arg;
      },
      fulfilled: (state, action) => {
        const userChallengeId = action.meta!.arg;
        const [removedActiveChallenge] = _remove(
          state.activeChallenges,
          (ac) => ac.userChallenge?.id === userChallengeId
        );

        if (removedActiveChallenge) {
          _unset(removedActiveChallenge, 'userChallenge');
          removedActiveChallenge.metadata!.numberOfParticipants--;
          state.futureChallenges.push(removedActiveChallenge);
          state.futureChallenges.sort((a, b) => {
            return dayjs(a.strapiChallenge!.startDate).isAfter(dayjs(b.strapiChallenge!.startDate)) ? 1 : -1;
          });
        }
      },
    });

    buildApiAction<PayloadAction<IChallenge>>('getChallenge', {
      pending: (state, action) => {
        state.loading.getChallenge = true;
      },
      fulfilled: (state, action) => {
        state.challenge = action.payload;
      },
    });

    buildApiAction<PayloadAction<IUserChallenge>>('markCustomChallengeItemAsComplete', {
      fulfilled: (state, action) => {
        if (!state.challenge?.userChallenge) {
          return;
        }

        state.challenge.userChallenge = action.payload;
      },
    });

    buildApiAction<
      PayloadAction<{
        ccId: ICustomChallenge['id'];
        taskUndo: number;
      }>
    >('markCustomChallengeItemAsCompleteUndo', {
      fulfilled: (state, action) => {
        if (!state.challenge?.userChallenge) {
          return;
        }

        const userCustomChallengeUpdate = state.challenge!.userChallenge!.userCustomChallenges![
          action.payload.ccId
        ].filter((ch) => ch !== action.payload.taskUndo);

        state.challenge.userChallenge!.userCustomChallenges![action.payload.ccId] = userCustomChallengeUpdate;
      },
    });

    buildApiAction<PayloadAction<string, string, {arg: {noteContent: string}}>>('saveChallengeNote', {
      pending: (state, action) => {
        if (state?.challenge?.userChallenge) {
          state.challenge.userChallenge.challengeNote = action.meta.arg.noteContent;
        }
      },
    });

    buildApiAction<PayloadAction<IUserChallenge[]>>(['getUnreadChallenges', 'markChallengesCompletedAsRead'], {
      fulfilled: (state, action) => {
        state.unreadChallenges = action.payload;
      },
    });
  },
});

export const {unsetChallengesState, updateChallengeWelcomeModal} = challengesSlice.actions;

export default persistReducer(persistConfig, challengesSlice.reducer);
