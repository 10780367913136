import {useMutation, UseMutationOptions} from 'react-query';
import {mapKeys, pick, startCase} from 'lodash';
import {axiosGrowthDay} from '../utils/api';

export type ApiMixpanelPayload = {
  name: string;
  data: Record<string, any>;
};

const keys: string[] = [
  'id',
  'email',
  'firstName',
  'lastName',
  'fullName',
  'iso2',
  'country',
  'zipCode',
  'buyerEmail',
  'buyerFullName',
];

export function cleanApiMixpanelData(data: Record<string, any> = {}) {
  return pick(data, keys);
}

export function useApiMixpanel(options: UseMutationOptions<void, unknown, Record<string, any>, unknown> = {}) {
  return useMutation(
    'API_MIXPANEL_TRACK',
    async (data: Record<string, any> = {}) => {
      await axiosGrowthDay.post<void>('/mixpanel/track', {
        name: 'Captcha Shown',
        data: mapKeys(data, (value, key) => startCase(key)),
      } as ApiMixpanelPayload);
    },
    options
  );
}
