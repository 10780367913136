import {getAccessToken} from '@/features/auth/routes/Profile/utils';
import {axiosGrowthDay} from '../index';

export const logoutApi = {
  logout: () => {
    const accessToken = getAccessToken();
    if (!accessToken) return;
    return axiosGrowthDay.post('/signout').then((res) => res.data);
  },
};
