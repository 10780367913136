/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ApiError
 */
export interface ApiError {
  /**
   *
   * @type {string}
   * @memberof ApiError
   */
  status?: ApiErrorStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ApiError
   */
  timestamp?: string;
  /**
   *
   * @type {string}
   * @memberof ApiError
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof ApiError
   */
  debugMessage?: string;
}

export const ApiErrorStatusEnum = {
  _100Continue: '100 CONTINUE',
  _101SwitchingProtocols: '101 SWITCHING_PROTOCOLS',
  _102Processing: '102 PROCESSING',
  _103EarlyHints: '103 EARLY_HINTS',
  _103Checkpoint: '103 CHECKPOINT',
  _200Ok: '200 OK',
  _201Created: '201 CREATED',
  _202Accepted: '202 ACCEPTED',
  _203NonAuthoritativeInformation: '203 NON_AUTHORITATIVE_INFORMATION',
  _204NoContent: '204 NO_CONTENT',
  _205ResetContent: '205 RESET_CONTENT',
  _206PartialContent: '206 PARTIAL_CONTENT',
  _207MultiStatus: '207 MULTI_STATUS',
  _208AlreadyReported: '208 ALREADY_REPORTED',
  _226ImUsed: '226 IM_USED',
  _300MultipleChoices: '300 MULTIPLE_CHOICES',
  _301MovedPermanently: '301 MOVED_PERMANENTLY',
  _302Found: '302 FOUND',
  _302MovedTemporarily: '302 MOVED_TEMPORARILY',
  _303SeeOther: '303 SEE_OTHER',
  _304NotModified: '304 NOT_MODIFIED',
  _305UseProxy: '305 USE_PROXY',
  _307TemporaryRedirect: '307 TEMPORARY_REDIRECT',
  _308PermanentRedirect: '308 PERMANENT_REDIRECT',
  _400BadRequest: '400 BAD_REQUEST',
  _401Unauthorized: '401 UNAUTHORIZED',
  _402PaymentRequired: '402 PAYMENT_REQUIRED',
  _403Forbidden: '403 FORBIDDEN',
  _404NotFound: '404 NOT_FOUND',
  _405MethodNotAllowed: '405 METHOD_NOT_ALLOWED',
  _406NotAcceptable: '406 NOT_ACCEPTABLE',
  _407ProxyAuthenticationRequired: '407 PROXY_AUTHENTICATION_REQUIRED',
  _408RequestTimeout: '408 REQUEST_TIMEOUT',
  _409Conflict: '409 CONFLICT',
  _410Gone: '410 GONE',
  _411LengthRequired: '411 LENGTH_REQUIRED',
  _412PreconditionFailed: '412 PRECONDITION_FAILED',
  _413PayloadTooLarge: '413 PAYLOAD_TOO_LARGE',
  _413RequestEntityTooLarge: '413 REQUEST_ENTITY_TOO_LARGE',
  _414UriTooLong: '414 URI_TOO_LONG',
  _414RequestUriTooLong: '414 REQUEST_URI_TOO_LONG',
  _415UnsupportedMediaType: '415 UNSUPPORTED_MEDIA_TYPE',
  _416RequestedRangeNotSatisfiable: '416 REQUESTED_RANGE_NOT_SATISFIABLE',
  _417ExpectationFailed: '417 EXPECTATION_FAILED',
  _418IAmATeapot: '418 I_AM_A_TEAPOT',
  _419InsufficientSpaceOnResource: '419 INSUFFICIENT_SPACE_ON_RESOURCE',
  _420MethodFailure: '420 METHOD_FAILURE',
  _421DestinationLocked: '421 DESTINATION_LOCKED',
  _422UnprocessableEntity: '422 UNPROCESSABLE_ENTITY',
  _423Locked: '423 LOCKED',
  _424FailedDependency: '424 FAILED_DEPENDENCY',
  _425TooEarly: '425 TOO_EARLY',
  _426UpgradeRequired: '426 UPGRADE_REQUIRED',
  _428PreconditionRequired: '428 PRECONDITION_REQUIRED',
  _429TooManyRequests: '429 TOO_MANY_REQUESTS',
  _431RequestHeaderFieldsTooLarge: '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
  _451UnavailableForLegalReasons: '451 UNAVAILABLE_FOR_LEGAL_REASONS',
  _500InternalServerError: '500 INTERNAL_SERVER_ERROR',
  _501NotImplemented: '501 NOT_IMPLEMENTED',
  _502BadGateway: '502 BAD_GATEWAY',
  _503ServiceUnavailable: '503 SERVICE_UNAVAILABLE',
  _504GatewayTimeout: '504 GATEWAY_TIMEOUT',
  _505HttpVersionNotSupported: '505 HTTP_VERSION_NOT_SUPPORTED',
  _506VariantAlsoNegotiates: '506 VARIANT_ALSO_NEGOTIATES',
  _507InsufficientStorage: '507 INSUFFICIENT_STORAGE',
  _508LoopDetected: '508 LOOP_DETECTED',
  _509BandwidthLimitExceeded: '509 BANDWIDTH_LIMIT_EXCEEDED',
  _510NotExtended: '510 NOT_EXTENDED',
  _511NetworkAuthenticationRequired: '511 NETWORK_AUTHENTICATION_REQUIRED',
} as const;

export type ApiErrorStatusEnum = (typeof ApiErrorStatusEnum)[keyof typeof ApiErrorStatusEnum];
