import {plansApi} from '../../shared/api/plans/plans.api';
import displayApiError from '../../shared/util/display-api-error';
import {TodoEntry} from '../../shared/services_deprecated/model/generic/todo';
import {message} from 'antd';
import {PaginatedEntity} from '../../shared/services_deprecated/model/generic/paginatedEntity';
import {createAsyncThunk} from '@reduxjs/toolkit';

export const updateTaskNote = createAsyncThunk<
  TodoEntry,
  {
    planId: string;
    taskId: string;
    notes: string;
  }
>('projects/updateTaskNote', async ({planId, taskId, notes}) => {
  try {
    return await plansApi.updateTaskNote(planId, taskId, notes);
  } catch (err: any) {
    displayApiError(err, 'Failed to login');
    throw err;
  }
});
export const updateNote = createAsyncThunk<
  TodoEntry,
  {
    planId: string;
    notes: string;
  }
>('projects/updateNote', async ({planId, notes}) => {
  try {
    return await plansApi.updateNote(planId, notes);
  } catch (err: any) {
    displayApiError(err, 'Failed to login');
    throw err;
  }
});

export const updatePlan = createAsyncThunk<TodoEntry, {plan: Partial<TodoEntry>; notify?: boolean}>(
  'projects/updatePlan',
  async ({plan, notify}) => {
    try {
      const entryUpdated = await plansApi.updatePlan(plan);
      if (notify) {
        message.success('Plan updated successfully');
      }
      return entryUpdated;
    } catch (err: any) {
      displayApiError(err, 'Failed to login');
      throw err;
    }
  }
);

export const retrieveCompletedPlans = createAsyncThunk<PaginatedEntity, {page: number; size: number}>(
  'projects/retrieveCompletedPlans',
  async ({page, size}) => {
    try {
      return await plansApi.getCompletedPlans(page, size);
    } catch (err: any) {
      displayApiError(err, 'Failed to login');
      throw err;
    }
  }
);
