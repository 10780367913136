import {TriggerInfo} from '../../../features/auth/interfaces';
import {IGiftSubscriptionSavedForm} from '../../../features/gift-subscriptions/interface';
import {LoginResponse} from '../../services_deprecated/model/loginResponse';
import {Platform} from '../../util/types';
import {axiosGrowthDay} from '../index';
import {GiftSubscriptionPurchaseRequest, UserInviteGiftSubscriptionRequest} from '@growthday/ui-core/src/types/api';

export const giftSubscriptionsApi = {
  purchase: (request: GiftSubscriptionPurchaseRequest, triggerInfo?: TriggerInfo): Promise<boolean> => {
    const params: Record<string, string | boolean> = {};
    if (triggerInfo) {
      params.trigger = triggerInfo.triggerFlow ?? '';
      params.button = triggerInfo.triggeredFromSection ?? '';
      params.content = triggerInfo.triggeredContentName ?? '';
      params.mixpanel = true;
    }
    return axiosGrowthDay
      .post(`/giftSubscription/initiatepurchase/${Platform.WEB}`, request, {params})
      .then((res) => res.data);
  },
  redeem: (request: UserInviteGiftSubscriptionRequest): Promise<LoginResponse> => {
    return axiosGrowthDay.post('/users/userInviteByGS', request).then((res) => res.data);
  },
  apiCancelGiftSubscription(subscriptionId: string, triggerInfo?: TriggerInfo): Promise<void> {
    const params: Record<string, string | boolean> = {};
    if (triggerInfo) {
      params.trigger = triggerInfo.triggerFlow ?? '';
      params.button = triggerInfo.triggeredFromSection ?? '';
      params.content = triggerInfo.triggeredContentName ?? '';
      params.mixpanel = true;
    }
    return axiosGrowthDay.post(`/stripe/cancelSubscriptionByBuyer/${Platform.WEB}`, subscriptionId, {params});
  },
  apiRenewGiftSubscription(subscriptionId: string, triggerInfo?: TriggerInfo): Promise<void> {
    const params: Record<string, string | boolean> = {};
    if (triggerInfo) {
      params.trigger = triggerInfo.triggerFlow ?? '';
      params.button = triggerInfo.triggeredFromSection ?? '';
      params.content = triggerInfo.triggeredContentName ?? '';
      params.mixpanel = true;
    }
    return axiosGrowthDay.post(`/stripe/renewSubscriptionByBuyer/${Platform.WEB}`, subscriptionId, {params});
  },
  saveGiftSubscriptionForm(form: IGiftSubscriptionSavedForm): Promise<void> {
    return axiosGrowthDay.post('/giftSubscription/save', form);
  },
  getSavedGiftSubscriptionForm(token: string): Promise<IGiftSubscriptionSavedForm> {
    return axiosGrowthDay
      .get<IGiftSubscriptionSavedForm>('/giftSubscription/form', {params: {token}})
      .then((res) => res.data);
  },
};
