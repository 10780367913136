import {PaymentSystemEnum, SubscriptionLevelsEnum} from '@/features/auth/enums';
import {useGetMeData} from './useGetMeData';
import {SubscriptionIntervalsEnum} from '@/shared/services_deprecated/enums';
import {IUser} from '@/shared/services_deprecated/model/user';
import {tail} from 'lodash';
import {useCallback, useMemo} from 'react';
import {Country, parsePhoneNumber} from 'react-phone-number-input';
import {useDispatch} from 'react-redux';
import {setLoggedOut, setSocialParams} from '@/features/auth/auth.slice';
import {AxiosError} from 'axios';
import {useAuthState} from '../../../external';

const useUser = () => {
  const dispatch = useDispatch();
  const {accessToken, organization} = useAuthState();

  /**
   * Transforms user object.
   *
   * @param {IUser} user - The user object containing user-related information to be transformed.
   * @returns {IUser} Transformed user object with applied changes.
   *
   * @remarks
   * This function modifies the user object by performing several transformations:
   * - Converts the `level` property to uppercase as per SubscriptionLevelsEnum.
   * - Converts the `planFrequency` property to lowercase as per SubscriptionIntervalsEnum.
   * - Sets organization-specific properties if available, such as `level`, `planFrequency`, `subscriptionPlanName`, etc.
   * - Parses and formats the phone number, updating related properties (`phoneNumber`, `iso2`, `countryCode`).
   * - Splits the `fullName` property into `firstName` and `lastName`.
   * - Resets social parameters and updates the `giftSubscriptionBuyerEmail` property.
   */
  const transformUserData = useCallback(
    (user: IUser) => {
      dispatch(setSocialParams(undefined));
      user = {
        ...user,
        level: user.level?.toUpperCase() as SubscriptionLevelsEnum,
        planFrequency: user.planFrequency?.toLowerCase() as SubscriptionIntervalsEnum,
      };
      if (organization && user) {
        user = {
          ...user,
          ...(organization.plan ? {level: organization.plan.toUpperCase() as SubscriptionLevelsEnum} : {}),
          ...(organization.planFrequency
            ? {planFrequency: organization.planFrequency?.toLowerCase() as SubscriptionIntervalsEnum}
            : {}),
          ...(organization.planName ? {subscriptionPlanName: organization.planName} : {}),
          ...(organization.subscriptionCancelled ? {subscriptionCancelled: organization.subscriptionCancelled} : {}),
          ...(organization.stripePriceId
            ? {stripePriceId: organization.stripePriceId, paymentSystem: PaymentSystemEnum.STRIPE}
            : {}),
          giftSubscriptionBuyerEmail: organization.name,
        };
      }
      const parts = user.fullName?.split(' ') ?? [];
      user.firstName = (parts[0] || '').trim();
      user.lastName = (tail(parts).join(' ') || '').trim();
      if (user.phoneNumber) {
        try {
          const parsedPhoneNumber = parsePhoneNumber(user.phoneNumber, {
            defaultCallingCode: user.countryCode,
            defaultCountry: user.iso2 as Country,
          });
          if (parsedPhoneNumber?.number) {
            user.phoneNumber = parsedPhoneNumber.number;
          }
          if (!user.iso2 && parsedPhoneNumber?.country) {
            user.iso2 = parsedPhoneNumber.country;
          }
          if (!user.countryCode && parsedPhoneNumber?.countryCallingCode) {
            user.countryCode = parsedPhoneNumber.countryCallingCode;
          }
        } catch {}
      }
      if (user.iso2 && !user.country) {
        user.country = user.iso2;
      }
      dispatch(setLoggedOut(false));
      return user;
    },
    [organization, dispatch]
  );

  const {isLoading, data, error, refetch} = useGetMeData({
    enabled: !!accessToken,
    select: transformUserData,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
  const errorMsg = useMemo(
    () => ((error as AxiosError)?.response?.status !== 401 ? (error as AxiosError)?.message : undefined),
    [error]
  );
  return useMemo(
    () => ({
      isLoading,
      user: data,
      error,
      errorMsg,
      refetch,
      transformUserData,
    }),
    [isLoading, data, error, errorMsg, refetch, transformUserData]
  );
};

export default useUser;
