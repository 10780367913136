import {IUserLiveEvent} from '../../services_deprecated/interfaces/user-live-event.interface';
import {IStrapiLiveEvent} from '../../services_deprecated/model/strapi/live-event';
import {axiosGrowthDay, axiosPublicStrapi} from '../index';
import {AchievementInfo} from '@growthday/ui-core/src/types/achievements';

export const userLiveEventApi = {
  getLiveEventByNameUid(nameUid: string, params?: Record<string, any>) {
    return this.getLiveEvents({
      nameUid,
      ...params,
    }).then((res) => res[0]);
  },
  userLiveEventMarkAsCompletedPut(eventId: number) {
    return axiosGrowthDay
      .put<{userLiveEvent: IUserLiveEvent; achievement?: AchievementInfo | null}>(`/userLiveEvents/complete/${eventId}`)
      .then((res) => res.data);
  },
  getLiveEventById: (eventIds: string) => {
    return axiosGrowthDay
      .get<IUserLiveEvent[]>(`/userLiveEvents/findByEventId?eventIds=${eventIds}`)
      .then((res) => res.data);
  },

  getRegistrationsById: (eventIds: string) => {
    return axiosGrowthDay.get<any>(`/userLiveEvents/registrations?eventIds=${eventIds}`).then((res) => res.data);
  },

  createLiveEvent: (eventId: string) => {
    return axiosGrowthDay.post<string>(`/userLiveEvents/enroll/${eventId}`).then((res) => res.data);
  },

  updateLiveEventNote: (eventId: string, note: string) => {
    return axiosGrowthDay
      .put<void>(`/userLiveEvents/notes/${eventId}`, note, {
        headers: {
          'Content-Type': 'text/plain',
        },
      })
      .then((res) => res.data);
  },

  updateProgress: (userEventId: string, progress: number) => {
    return axiosGrowthDay.put<void>(`/userLiveEvents/progress/${userEventId}/${progress}`, {}).then((res) => res.data);
  },

  getLiveEvents: (params?: Record<string, any>) => {
    return axiosPublicStrapi.get<IStrapiLiveEvent[]>('events', {params}).then((res) => res.data);
  },
};
