/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface GrowthGroupInstanceChat
 */
export interface GrowthGroupInstanceChat {
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstanceChat
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupInstanceChat
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupInstanceChat
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupInstanceChat
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstanceChat
   */
  groupId: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstanceChat
   */
  groupInstanceId: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstanceChat
   */
  chatId: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstanceChat
   */
  message?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof GrowthGroupInstanceChat
   */
  recipientRoles?: Array<GrowthGroupInstanceChatRecipientRolesEnum>;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstanceChat
   */
  sender?: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstanceChat
   */
  senderName?: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstanceChat
   */
  senderRole?: GrowthGroupInstanceChatSenderRoleEnum;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstanceChat
   */
  time?: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupInstanceChat
   */
  senderUserId?: string;
}

export const GrowthGroupInstanceChatRecipientRolesEnum = {
  Host: 'host',
  HostBackstage: 'host-backstage',
  Attendee: 'attendee',
  CoHost: 'co-host',
  CoHostBackstage: 'co-host-backstage',
  Speaker: 'speaker',
  Preview: 'preview',
  WaitingRoom: 'waiting-room',
  Beam: 'beam',
} as const;

export type GrowthGroupInstanceChatRecipientRolesEnum =
  (typeof GrowthGroupInstanceChatRecipientRolesEnum)[keyof typeof GrowthGroupInstanceChatRecipientRolesEnum];
export const GrowthGroupInstanceChatSenderRoleEnum = {
  Host: 'host',
  HostBackstage: 'host-backstage',
  Attendee: 'attendee',
  CoHost: 'co-host',
  CoHostBackstage: 'co-host-backstage',
  Speaker: 'speaker',
  Preview: 'preview',
  WaitingRoom: 'waiting-room',
  Beam: 'beam',
} as const;

export type GrowthGroupInstanceChatSenderRoleEnum =
  (typeof GrowthGroupInstanceChatSenderRoleEnum)[keyof typeof GrowthGroupInstanceChatSenderRoleEnum];
