import {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import useGDBreakpoint from '../../../shared/hooks/useBreakpoint';
import {useLocation} from 'react-router-dom';
import useShellState from './useShellState';

export const EXPANDED_MENU_WIDTH = 200;
export const WRAPPED_MENU_WIDTH = 120;
export const COLLAPSE_MENU_WIDTH = 72;
export const WINDOW_WRAP_HEIGHT = 824;
export const shouldWrapTitle = () => window.innerHeight > WINDOW_WRAP_HEIGHT;

export type UseSiderProps = {
  disabled?: boolean;
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
};

const useSider = (props: UseSiderProps) => {
  const {siderMinimized} = useShellState();
  const [isSiderOverlay, setIsSiderOverlay] = useState(false);
  const breakpoint = useGDBreakpoint();
  const [_wrapTitle, setWrapTitle] = useState(shouldWrapTitle());
  const {pathname} = useLocation();

  const onWindowResize = useCallback(() => {
    const changed = shouldWrapTitle();
    if (_wrapTitle !== changed) {
      setWrapTitle(changed);
    }
  }, [setWrapTitle, _wrapTitle]);

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => window.removeEventListener('resize', onWindowResize);
  }, [onWindowResize]);

  useEffect(() => {
    if (isSiderOverlay) props.setCollapsed(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const expandedMenu = isSiderOverlay || (!siderMinimized && breakpoint.xl);
  const wrapTitle = expandedMenu && _wrapTitle;
  const siderWidth = expandedMenu ? (wrapTitle ? WRAPPED_MENU_WIDTH : EXPANDED_MENU_WIDTH) : COLLAPSE_MENU_WIDTH;

  useEffect(() => {
    // Make sider width available everywhere. Set to body so modals, dialogs, tooltips etc can also use
    document.documentElement.style.setProperty('--sider-width', `${isSiderOverlay ? 0 : siderWidth}px`);
  }, [isSiderOverlay, siderWidth]);

  return {
    siderMinimized,
    siderWidth,
    wrapTitle,
    expandedMenu,
    isSiderOverlay,
    setIsSiderOverlay,
  };
};

export default useSider;
