import {Action} from 'redux';
import {
  ActionReducerMapBuilder,
  AsyncThunk,
  AsyncThunkPayloadCreator,
  CaseReducer,
  createAsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';
import coerceArray from '../../util/coerceArray';

type ApiActionCallbacks<S = any, A extends Action = PayloadAction> = {
  pending?: CaseReducer<S, A>;
  fulfilled?: CaseReducer<S, A>;
  rejected?: CaseReducer<S, A>;
};
type AnyAsyncThunk = AsyncThunk<any, any, {}>;

export const getApiActionBuilder = <S = any>(
  builder: ActionReducerMapBuilder<S>,
  actions: Record<string, AnyAsyncThunk>
) => {
  const builtActions: AnyAsyncThunk[] = [];
  const builderFn = <A extends Action = PayloadAction>(
    targetAction: AnyAsyncThunk | AnyAsyncThunk[],
    callbacks: ApiActionCallbacks<S, A> = {}
  ) => {
    const targetActions = coerceArray(targetAction);

    targetActions.forEach((action) => {
      builtActions.push(action);
      const actionEntry = Object.entries(actions).find(([, a]) => a === action);

      if (!actionEntry) {
        return console.error('No action found', action);
      }

      const actionName = actionEntry[0];

      builder.addCase(action.pending, (state: any, action: any) => {
        state.loading[actionName] = true;

        if (callbacks.pending) {
          callbacks.pending(state, action);
        }
      });

      builder.addCase(action.rejected, (state: any, action: any) => {
        if (callbacks.rejected) {
          callbacks.rejected(state, action);
        }

        state.loading[actionName] = false;
      });

      builder.addCase(action.fulfilled, (state: any, action: any) => {
        if (callbacks.fulfilled) {
          callbacks.fulfilled(state, action);
        }

        state.loading[actionName] = false;
      });
    });
  };

  return {
    build: builderFn,
    // Builds all actions which were not explicitly built to add default loading state
    defaults: () => {
      Object.values(actions).forEach((action) => {
        if (!builtActions.find((a) => a === action)) {
          builderFn(action);
        }
      });
    },
  };
};

export const getThunkCreator = <Returned, ThunkArg = void>(
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg>
) => {
  return (prefix: string, actionName: string) => {
    return createAsyncThunk<Returned, ThunkArg>(`${prefix}/${actionName}`, payloadCreator);
  };
};

export type ApiActionThunkCreator<Returned, ThunkArg> = (
  prefix: string,
  actionName: string
) => AsyncThunk<Returned, ThunkArg, {}>;
export type ApiActionThunk<Returned = any, ThunkArg = void> = ReturnType<ApiActionThunkCreator<Returned, ThunkArg>>;

export const getApiActionCreator = <ApiActions>(prefix: string) => {
  const apiActions: ApiActions = {} as ApiActions;

  return {
    create: (actions: {[A in keyof ApiActions]: any}) => {
      for (const key in actions) {
        // eslint-disable-next-line no-prototype-builtins
        if (actions.hasOwnProperty(key)) {
          apiActions[key] = actions[key](prefix, key);
        }
      }
    },
    thunk: getThunkCreator,
    apiActions,
  };
};
