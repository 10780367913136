/**
 * Model definition for LifeScores
 */
export interface ILifeScores {
  id: number;
  type: EnumLifeScoresType;
  inputRequired: boolean;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumLifeScoresType {
  Monthly = 'Monthly',
  Weekly = 'Weekly',
  Daily = 'Daily',
  Main = 'Main',
  TeamAssessment = 'TeamAssessment',
}
