import {IStrapiQuery} from '../../services_deprecated/interfaces/strapi-pagination-query.interface';

const convertToUrlParams = (query: IStrapiQuery): URLSearchParams => {
  const params = new URLSearchParams();
  Object.entries(query).forEach(([name, value]) => {
    if (name !== '_author') {
      if (Array.isArray(value)) {
        if (value.length) {
          value.forEach((v) => params.append(name, v));
        }
      } else {
        params.set(name, value);
      }
    }
  });

  return params;
};

export default convertToUrlParams;
