import {createAsyncThunk} from '@reduxjs/toolkit';
import {growthDayCoinApi, strapiApi, userApi} from '../../shared/api';
import {DailyJournalEntry} from '../../shared/services_deprecated/model/generic/dailyJournal';
import {PromptCategory} from '../../shared/services_deprecated/model/strapi/promptCategory';
import {journalApi} from '../../shared/api/journal/journal.api';
import {ImagesIdsInterface} from '../../shared/api/journal/interfaces';
import dayjs from 'dayjs';
import {StrapiCollectionEnum} from '../../shared/api/strapi/enums';

export const uploadJournalPicture = createAsyncThunk<any, {fileList: any; dailyEntry: DailyJournalEntry}>(
  'dailyJournal/uploadJournalPicture',
  async (payload) => {
    let i = 0;
    do {
      const fileForm = new FormData();
      fileForm.append('file', payload.fileList[i].originFileObj);
      await journalApi.uploadJournalPicture(payload.dailyEntry.id, payload.fileList[i].uid, fileForm);
      i++;
    } while (i < payload.fileList.length);
  }
);

export const deleteJournalPicture = createAsyncThunk<any, {objectId: string; index: ImagesIdsInterface}>(
  'dailyJournal/deleteJournalPicture',
  async (payload) => {
    await journalApi.deleteJournalPictures(payload.objectId, payload.index);
  }
);

export const getPromptCategories = createAsyncThunk<PromptCategory[]>('dailyJournal/getPromptCategories', async () => {
  return strapiApi
    .getCollection(StrapiCollectionEnum.PROMPT_CATEGORIES)
    .then((obj) => {
      const arr = obj as PromptCategory[];
      return arr.sort((a, b) =>
        a.order && b.order && a.order > b.order ? 1 : a.order && b.order && b.order > a.order ? -1 : 0
      );
    })
    .catch((err: any) => {
      throw err;
    });
});

export const getMaxCoinsPerDay = createAsyncThunk<number>('dailyJournal/getMaxCoinsPerDay', async () => {
  return growthDayCoinApi.maxCoinsPerDay();
});

export const getTodayCoins = createAsyncThunk<number>('dailyJournal/getTodayCoins', async () => {
  return userApi
    .getCoinsPerDay({date: dayjs().format('YYYY-MM-DD'), eventType: 'DAILY_JOURNAL_CREATE'})
    .then((obj) => obj['coins'])
    .catch((err: any) => {
      throw err;
    });
});
