import {EnvironmentsEnum} from '../enums';
import {IConfigEnv} from '../config.interface';
import {environments} from '@customization/environment';

export const getCurrentEnvironmentKey = (): EnvironmentsEnum => {
  return (process.env.REACT_APP_ENV as EnvironmentsEnum) || EnvironmentsEnum.DEV;
};

export const getCurrentEnvironment = (): IConfigEnv => {
  return environments[getCurrentEnvironmentKey()];
};
