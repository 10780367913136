/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface TeamMember
 */
export interface TeamMember {
  /**
   *
   * @type {string}
   * @memberof TeamMember
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof TeamMember
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof TeamMember
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof TeamMember
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof TeamMember
   */
  uuid?: string;
  /**
   *
   * @type {string}
   * @memberof TeamMember
   */
  teamId?: string;
  /**
   *
   * @type {string}
   * @memberof TeamMember
   */
  signupToken?: string;
  /**
   *
   * @type {number}
   * @memberof TeamMember
   */
  invitationSentOn?: number;
  /**
   *
   * @type {number}
   * @memberof TeamMember
   */
  invitationUpdatedOn?: number;
  /**
   *
   * @type {string}
   * @memberof TeamMember
   */
  invitationState?: TeamMemberInvitationStateEnum;
  /**
   *
   * @type {string}
   * @memberof TeamMember
   */
  role?: TeamMemberRoleEnum;
  /**
   *
   * @type {string}
   * @memberof TeamMember
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof TeamMember
   */
  email?: string;
  /**
   *
   * @type {boolean}
   * @memberof TeamMember
   */
  deactivated?: boolean;
  /**
   *
   * @type {number}
   * @memberof TeamMember
   */
  deactivatedOn?: number;
  /**
   *
   * @type {number}
   * @memberof TeamMember
   */
  inviteReminderCount?: number;
  /**
   *
   * @type {boolean}
   * @memberof TeamMember
   */
  cancelled?: boolean;
  /**
   *
   * @type {number}
   * @memberof TeamMember
   */
  cancelledOn?: number;
}

export const TeamMemberInvitationStateEnum = {
  Invited: 'INVITED',
  Accepted: 'ACCEPTED',
  Declined: 'DECLINED',
} as const;

export type TeamMemberInvitationStateEnum =
  (typeof TeamMemberInvitationStateEnum)[keyof typeof TeamMemberInvitationStateEnum];
export const TeamMemberRoleEnum = {
  Admin: 'ADMIN',
  Member: 'MEMBER',
} as const;

export type TeamMemberRoleEnum = (typeof TeamMemberRoleEnum)[keyof typeof TeamMemberRoleEnum];
