import {TagKeys} from '../../types/core';

export const reminderTags: TagKeys = {
  type: 'reminders',
  list: () => ({type: reminderTags.type, id: 'LIST'}),
  detail: (id: string) => ({type: reminderTags.type, id}),
};

export const remindersApiReducerPath = 'remindersApi';

export const remindersEndpoint = '/reminders';
