import {IFile} from './file';

/**
 * Model definition for notification-template
 */
export interface INotificationTemplates {
  id: number;
  notificationId?: string;
  notificationHeader?: string;
  notificationBody?: string;
  callToActionButtonLabels?: string;
  eventTimeFormatting?: string;
  type?: EnumNotificationTemplatesType;
  image?: IFile;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumNotificationTemplatesType {
  PLAN = 'PLAN',
  PLAN_ACTION = 'PLAN_ACTION',
  PLAN_SUB_TASK = 'PLAN_SUB_TASK',
  CHALLENGES = 'CHALLENGES',
  ACHIEVEMENTS = 'ACHIEVEMENTS',
  ACHIEVEMENTS_CERTIFICATE = 'ACHIEVEMENTS_CERTIFICATE',
  GOALS = 'GOALS',
  MILESTONESWELCOME = 'MILESTONESWELCOME',
  WELCOME = 'WELCOME',
  TRIAL_REMINDER = 'TRIAL_REMINDER',
  GIFT_INVITE = 'GIFT_INVITE',
  GIFT_SUBSCRIPTION_ABANDON = 'GIFT_SUBSCRIPTION_ABANDON',
  GROWTH_GROUPS = 'GROWTH_GROUPS',
  GROWTH_GROUP_RSVP = 'GROWTH_GROUP_RSVP',
  GROWTH_GROUP_RECURRING_RSVP = 'GROWTH_GROUP_RECURRING_RSVP',
  ENTERPRISE_SCORECARD = 'ENTERPRISE_SCORECARD',
  DAILY_FIRE = 'DAILY_FIRE',
  KEYNOTE = 'KEYNOTE',
  WORKSHOP = 'WORKSHOP',
  STREAK = 'STREAK',
}
