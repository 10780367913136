import {IMarketingCampaigns} from './marketing-campaigns';
import {ISteps} from './steps';

/**
 * Model definition for Signup-flow
 */
export interface ISignupFlow {
  id: number;
  name?: string;
  isActive?: boolean;
  type?: EnumSignupFlowType;
  campaign?: IMarketingCampaigns;
  steps: ISteps[];
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumSignupFlowType {
  DEFAULT = 'DEFAULT',
  CAMPAIGN = 'CAMPAIGN',
  GROWTH_GROUP = 'GROWTH_GROUP',
  CHALLENGE = 'CHALLENGE',
  OFFER = 'OFFER',
  DEFAULT_PAID_CAMPAIGN = 'DEFAULT_PAID_CAMPAIGN',
  DEFAULT_FREE_CAMPAIGN = 'DEFAULT_FREE_CAMPAIGN',
  PARTNER_FLOW = 'PARTNER_FLOW',
  DEFAULT_BUY_FLOW = 'DEFAULT_BUY_FLOW',
  TEAM = 'TEAM',
}
