import {AxiosError} from 'axios';
import {BaseQueryFn, FetchBaseQueryError, FetchBaseQueryMeta} from '@reduxjs/toolkit/query';
import {QueryInput} from '../types/axios';
import {axiosGrowthDay, axiosSearch, axiosPublicStrapi, axiosStrapi} from '@/shared/api';

export function axiosStrapiBaseQuery<ResultType = unknown>(): BaseQueryFn<
  any,
  ResultType,
  FetchBaseQueryError,
  Record<string, never>,
  FetchBaseQueryMeta
> {
  return async function (input) {
    const url = typeof input === 'string' ? input : input.url;
    const opts: Omit<QueryInput, 'url'> = typeof input === 'string' ? {method: 'GET'} : input;
    try {
      const result = await axiosStrapi({url, ...opts, data: opts.body});
      return {data: result.data};
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {status: err.response?.status ?? 400, data: err.response?.data},
      };
    }
  };
}

export function axiosBaseQuery<ResultType = unknown>(): BaseQueryFn<any, ResultType, FetchBaseQueryError> {
  return async function (input) {
    const url = typeof input === 'string' ? input : input.url;
    const opts: Omit<QueryInput, 'url'> = typeof input === 'string' ? {method: 'GET'} : input;
    try {
      const result = await axiosGrowthDay({url, ...opts, data: opts.body});
      return {data: result.data};
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {status: err.response?.status ?? 0, data: err.response?.data},
      };
    }
  };
}

export {axiosGrowthDay, axiosSearch, axiosPublicStrapi, axiosStrapi};
