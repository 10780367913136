import {FC, MouseEvent} from 'react';
import styles from './ShadowCard.module.less';
import {Card} from 'antd';
import {CardProps} from 'antd/lib/card';
import classNames from 'classnames';
import {useHistory} from 'react-router';
import {LocationDescriptor} from 'history';
import {HistoryState} from '../../../../features/shell/history';

export type ShadowCardProps = CardProps & {
  to?: LocationDescriptor<HistoryState>;
};
const ShadowCard: FC<ShadowCardProps> = ({className, to, ...props}) => {
  const history = useHistory();

  const isClickable = Boolean(props.onClick || to);
  const handleClick = (ev: MouseEvent<HTMLDivElement>) => {
    if (to) {
      history.push(to);
    }
    props.onClick?.(ev);
  };

  return (
    <Card
      bordered={false}
      {...props}
      hoverable={isClickable}
      {...(isClickable ? {tabIndex: 0, onClick: handleClick} : {})}
      className={classNames(styles.root, 'shadow-card', className)}
    />
  );
};

export default ShadowCard;
