export enum ChallengesDashboardType {
  AVAILABLE = 'available',
  COMPLETED = 'completed',
}

export enum StrapiChallengeTypesEnum {
  JOURNAL = 'Journal',
  DAILY_HABIT = 'DailyHabit',
  CUSTOM = 'Custom',
  WEEKLY_HABIT = 'WeeklyHabit',
  MONTHLY_HABIT = 'MonthlyHabit',
}

export enum StrapiChallengeTiersEnum {
  GOLD = 'gold',
  SILVER = 'silver',
  BRONZE = 'bronze',
}

export enum UserChallengeStatusEnum {
  LEFT = 'LEFT',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CLOSED = 'CLOSED',
}

export enum UserCustomChallengeTaskTypeEnum {
  IMAGE = 'Image',
  VIDEO = 'Video',
  AUDIO = 'Audio',
  JOURNAL = 'Journal',
  PLAN = 'Plan',
  GOAL = 'Goal',
}

export enum RemainingTimeEnum {
  EXPIRED = 'EXPIRED',
  IN_PROGRESS = 'IN_PROGRESS',
}
