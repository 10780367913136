/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface StreakDetails
 */
export interface StreakDetails {
  /**
   *
   * @type {string}
   * @memberof StreakDetails
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof StreakDetails
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof StreakDetails
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof StreakDetails
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof StreakDetails
   */
  uuid?: string;
  /**
   *
   * @type {string}
   * @memberof StreakDetails
   */
  streakType?: StreakDetailsStreakTypeEnum;
  /**
   *
   * @type {string}
   * @memberof StreakDetails
   */
  timeUnit?: StreakDetailsTimeUnitEnum;
  /**
   *
   * @type {number}
   * @memberof StreakDetails
   */
  length?: number;
  /**
   *
   * @type {boolean}
   * @memberof StreakDetails
   */
  complete?: boolean;
  /**
   *
   * @type {string}
   * @memberof StreakDetails
   */
  userIanaTimezone?: string;
}

export const StreakDetailsStreakTypeEnum = {
  LifeScoreDaily: 'LIFE_SCORE_DAILY',
  LifeScoreWeekly: 'LIFE_SCORE_WEEKLY',
  DailyJournal: 'DAILY_JOURNAL',
  WeeklyJournal: 'WEEKLY_JOURNAL',
  MonthlyJournal: 'MONTHLY_JOURNAL',
  Journal: 'JOURNAL',
  DailyGrowthHabits: 'DAILY_GROWTH_HABITS',
  WeeklyGrowthHabits: 'WEEKLY_GROWTH_HABITS',
  WholeLifeAssessment: 'WHOLE_LIFE_ASSESSMENT',
  MonthlyGrowthHabits: 'MONTHLY_GROWTH_HABITS',
  WeeklyPerformanceReview: 'WEEKLY_PERFORMANCE_REVIEW',
  LifeScoreMonthly: 'LIFE_SCORE_MONTHLY',
} as const;

export type StreakDetailsStreakTypeEnum =
  (typeof StreakDetailsStreakTypeEnum)[keyof typeof StreakDetailsStreakTypeEnum];
export const StreakDetailsTimeUnitEnum = {
  Days: 'DAYS',
  Months: 'MONTHS',
  Weeks: 'WEEKS',
} as const;

export type StreakDetailsTimeUnitEnum = (typeof StreakDetailsTimeUnitEnum)[keyof typeof StreakDetailsTimeUnitEnum];
