/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {CmsImage} from './cms-image';
// May contain unused imports in some cases
// @ts-ignore
import {CmsMarketingWebhook} from './cms-marketing-webhook';
// May contain unused imports in some cases
// @ts-ignore
import {CmsMedia} from './cms-media';
// May contain unused imports in some cases
// @ts-ignore
import {CmsOfferLicense} from './cms-offer-license';
// May contain unused imports in some cases
// @ts-ignore
import {CmsShareContent} from './cms-share-content';
// May contain unused imports in some cases
// @ts-ignore
import {CmsTier} from './cms-tier';
// May contain unused imports in some cases
// @ts-ignore
import {CmsTooltip} from './cms-tooltip';
// May contain unused imports in some cases
// @ts-ignore
import {CustomChallenge} from './custom-challenge';
// May contain unused imports in some cases
// @ts-ignore
import {PremiereDateCountdown} from './premiere-date-countdown';

/**
 *
 * @export
 * @interface CmsChallenge
 */
export interface CmsChallenge {
  /**
   *
   * @type {number}
   * @memberof CmsChallenge
   */
  id?: number;
  /**
   *
   * @type {string}
   * @memberof CmsChallenge
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof CmsChallenge
   */
  titleUid?: string;
  /**
   *
   * @type {string}
   * @memberof CmsChallenge
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof CmsChallenge
   */
  type?: CmsChallengeTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CmsChallenge
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof CmsChallenge
   */
  endDate?: string;
  /**
   *
   * @type {Array<CmsTier>}
   * @memberof CmsChallenge
   */
  tiers?: Array<CmsTier>;
  /**
   *
   * @type {number}
   * @memberof CmsChallenge
   */
  registrationCount?: number;
  /**
   *
   * @type {boolean}
   * @memberof CmsChallenge
   */
  evergreenChallenge?: boolean;
  /**
   *
   * @type {CmsMedia}
   * @memberof CmsChallenge
   */
  customIcon?: CmsMedia;
  /**
   *
   * @type {CmsMedia}
   * @memberof CmsChallenge
   */
  completedBadge?: CmsMedia;
  /**
   *
   * @type {CmsImage}
   * @memberof CmsChallenge
   */
  image?: CmsImage;
  /**
   *
   * @type {CmsOfferLicense}
   * @memberof CmsChallenge
   */
  licenses?: CmsOfferLicense;
  /**
   *
   * @type {Array<CmsTooltip>}
   * @memberof CmsChallenge
   */
  tooltips?: Array<CmsTooltip>;
  /**
   *
   * @type {CmsMedia}
   * @memberof CmsChallenge
   */
  dashboardThumbnail?: CmsMedia;
  /**
   *
   * @type {CmsMedia}
   * @memberof CmsChallenge
   */
  dashboardThumbnailVertical?: CmsMedia;
  /**
   *
   * @type {string}
   * @memberof CmsChallenge
   */
  signupDescription?: string;
  /**
   *
   * @type {string}
   * @memberof CmsChallenge
   */
  signupVideoUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CmsChallenge
   */
  welcomeVideoUrl?: string;
  /**
   *
   * @type {string}
   * @memberof CmsChallenge
   */
  welcomeDescription?: string;
  /**
   *
   * @type {string}
   * @memberof CmsChallenge
   */
  welcomeTitle?: string;
  /**
   *
   * @type {string}
   * @memberof CmsChallenge
   */
  welcomePrimaryButtonText?: string;
  /**
   *
   * @type {CmsMedia}
   * @memberof CmsChallenge
   */
  welcomeImage?: CmsMedia;
  /**
   *
   * @type {string}
   * @memberof CmsChallenge
   */
  signupHeading?: string;
  /**
   *
   * @type {string}
   * @memberof CmsChallenge
   */
  shortDescription?: string;
  /**
   *
   * @type {string}
   * @memberof CmsChallenge
   */
  upgradeTitle?: string;
  /**
   *
   * @type {string}
   * @memberof CmsChallenge
   */
  upgradeSubtitle?: string;
  /**
   *
   * @type {string}
   * @memberof CmsChallenge
   */
  upgradeDescription?: string;
  /**
   *
   * @type {string}
   * @memberof CmsChallenge
   */
  upgradeOffer?: CmsChallengeUpgradeOfferEnum;
  /**
   *
   * @type {string}
   * @memberof CmsChallenge
   */
  upgradeButtonText?: string;
  /**
   *
   * @type {CmsShareContent}
   * @memberof CmsChallenge
   */
  shareContent?: CmsShareContent;
  /**
   *
   * @type {Array<CmsMarketingWebhook>}
   * @memberof CmsChallenge
   */
  webhooks?: Array<CmsMarketingWebhook>;
  /**
   *
   * @type {string}
   * @memberof CmsChallenge
   */
  challengeEndDate?: string;
  /**
   *
   * @type {boolean}
   * @memberof CmsChallenge
   */
  beforePremiereDate?: boolean;
  /**
   *
   * @type {number}
   * @memberof CmsChallenge
   */
  numberOfDaysInChallenge?: number;
  /**
   *
   * @type {number}
   * @memberof CmsChallenge
   */
  numberOfDaysRemaining?: number;
  /**
   *
   * @type {PremiereDateCountdown}
   * @memberof CmsChallenge
   */
  premiereDateCountdown?: PremiereDateCountdown;
  /**
   *
   * @type {number}
   * @memberof CmsChallenge
   */
  maxTierValue?: number;
  /**
   *
   * @type {boolean}
   * @memberof CmsChallenge
   */
  free?: boolean;
  /**
   *
   * @type {string}
   * @memberof CmsChallenge
   */
  created_at?: string;
  /**
   *
   * @type {string}
   * @memberof CmsChallenge
   */
  updated_at?: string;
  /**
   *
   * @type {Array<CustomChallenge>}
   * @memberof CmsChallenge
   */
  customChallenge?: Array<CustomChallenge>;
  /**
   *
   * @type {boolean}
   * @memberof CmsChallenge
   */
  isFree?: boolean;
}

export const CmsChallengeTypeEnum = {
  Journal: 'Journal',
  DailyHabit: 'DailyHabit',
  WeeklyHabit: 'WeeklyHabit',
  MonthlyHabit: 'MonthlyHabit',
  Custom: 'Custom',
} as const;

export type CmsChallengeTypeEnum = (typeof CmsChallengeTypeEnum)[keyof typeof CmsChallengeTypeEnum];
export const CmsChallengeUpgradeOfferEnum = {
  Basic: 'BASIC',
  Mastery: 'MASTERY',
  AllAccess: 'ALL_ACCESS',
} as const;

export type CmsChallengeUpgradeOfferEnum =
  (typeof CmsChallengeUpgradeOfferEnum)[keyof typeof CmsChallengeUpgradeOfferEnum];
