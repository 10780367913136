import {CaseReducer, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  DailyFireProgress,
  DashboardData,
  DashboardLoadingState,
  DashboardRecommendation,
  DashboardState,
} from './interfaces';
import {stateWithLoading} from '../../shared/redux/util/state-with-loading';
import {getApiActionBuilder} from '../../shared/redux/util/build-api-action';
import * as apiActions from './dashboard.actions';
import _unset from 'lodash/unset';
import coerceArray from '../../shared/util/coerceArray';
import {IStrapiCourseLesson} from '../../shared/services_deprecated/model/strapi/course-lesson';
import {ILifeScoresRecommendation} from '../life-scores/interfaces';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';
import {TourData} from '../tour/interface';
import {last} from 'lodash';
import {IDailyFires} from '@growthday/ui-core/src/types/strapi';

const persistConfig = {
  key: 'hpx:dashboard',
  storage,
  whitelist: ['restartTour'],
};

export const defaultDashboardOrder = [
  'growthGroups',
  'offers',
  'videoCourses',
  'audioCourses',
  'journal',
  'lifeScore',
  'plan',
  'challenges',
] as const;

export type WidgetOrderType = (typeof defaultDashboardOrder)[number];

const initialState = stateWithLoading<DashboardState, DashboardLoadingState>({
  data: null,
  recommendations: [],
  dailyFire: null,
  lastWatchedCourseLesson: null,
  lifeScoreRecommendation: null,
  dailyFireProgress: undefined,
  tourData: undefined,
  restartTour: false,
});

export const dashboardSlice = createSlice<
  DashboardState,
  {
    unsetDashboardState: CaseReducer<DashboardState, PayloadAction<string | string[]>>;
    updateDashboardTourData: CaseReducer<DashboardState, PayloadAction<Partial<TourData> | undefined>>;
    resetDashboardTourData: CaseReducer<DashboardState>;
    updateStartTour: CaseReducer<DashboardState, PayloadAction<boolean>>;
  }
>({
  name: 'dashboard',
  initialState,
  reducers: {
    unsetDashboardState(state, action) {
      const keys = coerceArray(action.payload);
      keys.forEach((key) => {
        _unset(state, key);
      });
    },
    resetDashboardTourData(state) {
      state.tourData = undefined;
    },
    updateDashboardTourData(state, action) {
      state.tourData = state.tourData ? {...state.tourData, ...(action.payload ?? {})} : undefined;
    },
    updateStartTour(state, action) {
      state.restartTour = action.payload;
      // If any tour data is present, reset it
      if (state.tourData) {
        state.tourData.progress = 0;
        state.tourData.completed = false;
      }
    },
  },
  extraReducers: (builder) => {
    const buildApiAction = getApiActionBuilder<DashboardState>(builder, apiActions);

    buildApiAction('markRecommendationAsInteracted');

    buildApiAction<PayloadAction<DashboardData>>('getDashboardData', {
      fulfilled: (state, action) => {
        state.data = action.payload;
      },
    });
    buildApiAction<PayloadAction<TourData[]>>('getDashboardTourData', {
      fulfilled: (state, action) => {
        state.tourData = last(action.payload);
      },
    });
    buildApiAction<PayloadAction<DashboardRecommendation[]>>('getRecommendations', {
      fulfilled: (state, action) => {
        state.recommendations = action.payload;
      },
    });

    buildApiAction<PayloadAction<IDailyFires>>('getDailyFire', {
      fulfilled: (state, action) => {
        state.dailyFire = action.payload;
      },
    });
    buildApiAction<PayloadAction<IStrapiCourseLesson | null>>('getLastWatchedCourseLesson', {
      fulfilled: (state, action) => {
        state.lastWatchedCourseLesson = action.payload;
      },
    });
    buildApiAction<PayloadAction<ILifeScoresRecommendation>>('getRecommendation', {
      fulfilled: (state, action) => {
        state.lifeScoreRecommendation = action.payload;
      },
    });
    buildApiAction<PayloadAction<DailyFireProgress>>(['getDailyFireProgress', 'updateDailyFireProgress'], {
      fulfilled: (state, action) => {
        state.dailyFireProgress = action.payload;
      },
    });
  },
});

export const {resetDashboardTourData, unsetDashboardState, updateDashboardTourData, updateStartTour} =
  dashboardSlice.actions;

export default persistReducer(persistConfig, dashboardSlice.reducer);
