import {ISubscriptionPlan} from '@/features/auth/interfaces';
import {SubscriptionIntervalsEnum} from '@/shared/services_deprecated/enums';

export function getTrialDaysFromSubscriptionPlan(plan: ISubscriptionPlan) {
  const {trialInDays, trialInDaysMonthly, interval} = plan;
  return interval === SubscriptionIntervalsEnum.MONTH
    ? trialInDaysMonthly
    : interval === SubscriptionIntervalsEnum.YEAR
    ? trialInDays
    : undefined;
}
