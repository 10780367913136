import {IConfigEnv} from '../../../config/config.interface';
import {EnvironmentsEnum} from '../../../config/enums';
import {GDSharedEnvironmentConstants} from './shared-constants';

const env: IConfigEnv = {
  androidAppUrl: 'https://play.google.com/store/apps/details?id=com.growthday&hl=en',
  apiUrl: 'https://api.dev.gday-stack.com',
  appScheme: 'growthday-dev',
  authClientId: 'growth-day-main-client',
  authRealm: 'GrowthDayRealm',
  authUrl: 'https://auth.dev.gday-stack.com',
  communityUrl: 'https://community.uat.gday-stack.com/',
  fbPixelId: '3038447133079286',
  firstPromoterId: '4lrh8cqc',
  gTagEventsId: {
    completeRegistrationPlan: 'AW-1010317991/nPb5CKnIueACEKf14OED',
    completeRegistrationOffer: 'AW-1010317991/nPb5CKnIueACEKf14OED',
    completeRegistrationChallenge: 'AW-1010317991/nPb5CKnIueACEKf14OED',
    completeRegistrationGift: 'AW-1010317991/nPb5CKnIueACEKf14OED',
    startTrial: 'AW-1010317991/y371CLiMweACEKf14OED',
    subscribe: 'AW-1010317991/MJLnCLuPweACEKf14OED',
  },
  gTagId: 'AW-1010317991',
  gtmId: 'GTM-WTLSZQ6',
  hyvorWebsiteId: 3886,
  iosAppUrl: 'https://apps.apple.com/us/app/growthday/id1543291315?itsct=apps_box_link&itscg=30200',
  name: EnvironmentsEnum.DEV,
  searchApiUrl: 'https://search-api.dev.gday-stack.com',
  shareUrl: 'https://share.dev.gday-stack.com',
  statsigSdkKey: 'client-kfzIgqNXb5jesIfYGdEMWIxYcFESgH1O4X8LGIhGYj1',
  stripePublishKey:
    'pk_test_51HTsnxBC62uLybCjUicILdtfF3YuLZeWN8C66a5CkgrkUaSdQv3efFg5c1NXTmY31zMRDbVhHFMDCCWfiaSqrsHv006kZ4kpAS',
  useproofId: 'VKE8WSTSxrg6pXncelT968M1zV53',
  userback: '11316|42469|WaPCGDTmDHrZwTnbda3eNCUaW',
  ...GDSharedEnvironmentConstants,
};

export default env;
