import {Modal, ModalFuncProps, Button} from 'antd';
import dayjs from 'dayjs';
import {sortBy, mapKeys} from 'lodash';
import {GrowthGroupSubscription} from '@growthday/ui-core/src/features/growthgroups/hooks/useUserGGSubscriptionQuery';
import {
  OrganizationPaymentFlowEnum,
  OrganizationResponse,
  UserUpcomingSubscriptionPlan,
} from '@growthday/ui-core/src/types/api';
import {EnumSubscriptionPlansLevel} from '@growthday/ui-core/src/types/strapi';
import GDTypography from '../../../shared/components/ui/Typography/Typography';
import {SubscriptionIntervalsEnum} from '../../../shared/services_deprecated/enums';
import {IUser, UserSignupType} from '../../../shared/services_deprecated/model/user';
import commonStyles from '../../../shared/style/commonStyles.module.less';
import {PaymentSystemEnum, SubscriptionLevelsEnum} from '../../auth/enums';
import {IStrapiSubscriptionPlan, ISubscriptionPlan, ISubscriptionPlans} from '../../auth/interfaces';
import {IPurchasedOffer} from '../../offers/interfaces';
import bgImg from '../assets/incompatible-modal-bg.png';
import styles from './subscription.module.less';
import config from '@/config';
import {getPlanAnnualAmount} from '@/features/auth/utils/plan-interval';
import {getPlanByComparator} from '@/features/offers/utils/getPlanByComparator';
import classNames from 'classnames';
import {Row, Space} from 'antd/es';
import {isTeamsUser} from '@/features/Teams/utilities/isTeamsUser';
import {getAnalyticsActions} from '@/features/analytics/hooks/useAnalyticsActions';

export const isSubscriptionActive = (user?: IUser) =>
  user?.level
    ? (Object.values(EnumSubscriptionPlansLevel) as unknown as SubscriptionLevelsEnum[]).includes(user.level)
    : false;

export const isOfferActive = (user?: IUser) =>
  Boolean(
    user?.level
      ? SubscriptionLevelsEnum.OFFER === user.level && (user.signupOfferId || user.signupType === UserSignupType.OFFER)
      : false
  );

export const isChallengeActive = (user?: IUser) =>
  Boolean(
    user?.level
      ? SubscriptionLevelsEnum.CHALLENGE === user.level &&
          (user.signupChallengeId || user.signupType === UserSignupType.CHALLENGE)
      : false
  );

export const isUserTypeSubscription = (user?: IUser) =>
  Boolean(
    user?.level
      ? [
          SubscriptionLevelsEnum.NONE,
          SubscriptionLevelsEnum.TRIAL,
          ...(Object.values(EnumSubscriptionPlansLevel) as unknown as SubscriptionLevelsEnum[]),
        ].includes(user.level) && !user.signupType
      : true
  );

export const isSubscriptionOngoing = (user?: IUser, upcomingPlan?: UserUpcomingSubscriptionPlan) =>
  user &&
  isSubscriptionActive(user) &&
  ((!user.subscriptionCancelled && user.subscriptionExpiry
    ? dayjs(user.subscriptionExpiry).isAfter(dayjs(), 'second')
    : false) ||
    (upcomingPlan ? dayjs(upcomingPlan?.subscriptionExpiry).isAfter(dayjs(), 'second') : false));

export const isSubscriptionCancelled = (user?: IUser) =>
  user && isSubscriptionActive(user) ? user.subscriptionCancelled : false;

export const isSubscriptionExpired = (user?: IUser) =>
  user
    ? !isSubscriptionActive(user) && user?.subscriptionCancelled && user?.subscriptionExpiry
      ? dayjs(user.subscriptionExpiry).isBefore(dayjs(), 'second')
      : false
    : false;

export const isSubscriptionTrial = (user?: IUser) =>
  user
    ? isSubscriptionActive(user) && user?.trialPeriodEnd
      ? dayjs(user.trialPeriodEnd).isAfter(dayjs(), 'second')
      : false
    : false;

export enum AnalyticsSubscriptionStatus {
  TRIAL = 'TRIAL',
  PAID = 'PAID',
  CANCELED = 'CANCELED',
  EXPIRED = 'EXPIRED',
  UNKNOWN = 'UNKNOWN',
}

export function isSubscriptionPaidByUser(user?: IUser) {
  return Boolean(
    user &&
      ((user.paymentMethodId && user.stripeSubscriptionId) || user.appleSubscriptionId || user.paypalSubscriptionId)
  );
}

export function getAnalyticsSubscriptionStatus(user?: IUser) {
  let subscriptionStatus: AnalyticsSubscriptionStatus = AnalyticsSubscriptionStatus.UNKNOWN;

  if (isSubscriptionExpired(user)) {
    subscriptionStatus = AnalyticsSubscriptionStatus.EXPIRED;
  } else if (isSubscriptionCancelled(user)) {
    subscriptionStatus = AnalyticsSubscriptionStatus.CANCELED;
  } else if (isSubscriptionTrial(user)) {
    subscriptionStatus = AnalyticsSubscriptionStatus.TRIAL;
  } else if (isSubscriptionActive(user)) {
    subscriptionStatus = AnalyticsSubscriptionStatus.PAID;
  }

  return subscriptionStatus;
}

export const getSubscriptionExpiryDate = (user?: IUser) =>
  user && isSubscriptionCancelled(user) && user.subscriptionExpiry ? user.subscriptionExpiry : undefined;

export const getSubscriptionRenewalDate = (user?: IUser, upcomingPlan?: UserUpcomingSubscriptionPlan) =>
  user &&
  isSubscriptionOngoing(user, upcomingPlan) &&
  (upcomingPlan ? upcomingPlan.subscriptionStart : user.subscriptionExpiry ? user.subscriptionExpiry : undefined);

export const getSubscriptionTrialEndDate = (user?: IUser) => user && isSubscriptionTrial(user) && user.trialPeriodEnd;

export const canChangeSubscription = (user?: IUser) => {
  if (!user) return false;
  const {kajabiUser, demoUser, paymentSystem, enterpriseUser} = user;
  const hasEvergreenSubscription = kajabiUser || demoUser;
  const hasAppleSubscription = paymentSystem === PaymentSystemEnum.APPLE;
  // Do not allow subscription change for the following conditions
  return !(
    hasEvergreenSubscription ||
    hasAppleSubscription ||
    enterpriseUser ||
    (isTeamsUser(user) && !isSubscriptionCancelled(user))
  );
};

export const getActiveSubscription = (subscriptionPlans?: ISubscriptionPlans[], user?: IUser) => {
  if (!user || !user.level || !user.planFrequency || !isSubscriptionActive(user) || !subscriptionPlans?.length) {
    return undefined;
  }
  return getSubscriptionPlan(subscriptionPlans, user);
};

const getSubscriptionPlan = (plans: ISubscriptionPlans[], user: IUser): ISubscriptionPlan | undefined => {
  let plan: ISubscriptionPlan | undefined;

  // Match plan for a partner subscription
  if (user.isPartnerUser) {
    plans.forEach((planMap) => {
      mapKeys(planMap, (_plan, planInterval) => {
        const userHasPartnerPlan =
          user.partnerSubscriptionPlanId === _plan.id && user.planFrequency?.toLowerCase() === planInterval;
        userHasPartnerPlan && (plan = _plan);
      });
    });
  }

  // Fallback to matching plan on payment system
  if (!plan) {
    switch (user.paymentSystem) {
      case PaymentSystemEnum.STRIPE:
        plan = filterSubscriptionPlans(
          plans,
          (_plan) => _plan.stripePriceId === user.stripePriceId || _plan.stripeWithTrialPriceId === user.stripePriceId
        );
        break;
      case PaymentSystemEnum.APPLE:
        plan = filterSubscriptionPlans(
          plans,
          (_plan) => _plan.appleProductId === user.applePlanId || _plan.appleWithTrialProductId === user.applePlanId
        );
        break;
      case PaymentSystemEnum.PAYPAL:
        plan = filterSubscriptionPlans(
          plans,
          (_plan) => _plan.paypalPriceId === user.paypalPlanId || _plan.paypalWithTrialPriceId === user.paypalPlanId
        );
    }
  }

  // Fall back to matching on the plan IDs
  if (!plan) {
    plan = filterSubscriptionPlans(plans, (_plan) =>
      [
        _plan.stripePriceId === user.stripePriceId || _plan.stripeWithTrialPriceId === user.stripePriceId,
        _plan.appleProductId === user.applePlanId || _plan.appleWithTrialProductId === user.applePlanId,
        _plan.paypalPriceId === user.paypalPlanId || _plan.paypalWithTrialPriceId === user.paypalPlanId,
      ].some(Boolean)
    );
  }

  return plan;
};

const filterSubscriptionPlans = (plans: ISubscriptionPlans[], callbackFn: (plan: ISubscriptionPlan) => boolean) => {
  for (const planMap of plans) {
    const planIntervals = Object.keys(planMap) as SubscriptionIntervalsEnum[];
    for (const planInterval of planIntervals) {
      const _plan = planMap[planInterval];
      if (callbackFn(_plan)) {
        return _plan;
      }
    }
  }

  return undefined;
};

export const getSubscriptionFromStrapiSubscription = (subscription: IStrapiSubscriptionPlan): ISubscriptionPlans => {
  return {
    [SubscriptionIntervalsEnum.MONTH]: {
      ...subscription,
      amount: subscription.monthlyAmount,

      stripePriceId: subscription.withoutTrialStripeMonthlyPriceId?.trim() || subscription.stripeMonthlyPriceId?.trim(),
      stripeWithTrialPriceId:
        subscription.stripeMonthlyPriceId?.trim() || subscription.withoutTrialStripeMonthlyPriceId?.trim(),

      paypalPriceId: subscription.withoutTrialPaypalMonthlyPriceId?.trim() || subscription.paypalMonthlyPriceId?.trim(),
      paypalWithTrialPriceId:
        subscription.paypalMonthlyPriceId?.trim() || subscription.withoutTrialPaypalMonthlyPriceId?.trim(),

      appleProductId:
        subscription.withoutTrialAppleMonthlyProductId?.trim() || subscription.appleMonthlyProductId?.trim(),
      appleWithTrialProductId:
        subscription.appleMonthlyProductId?.trim() || subscription.withoutTrialAppleMonthlyProductId?.trim(),

      interval: SubscriptionIntervalsEnum.MONTH,
    } as ISubscriptionPlan,
    [SubscriptionIntervalsEnum.YEAR]: {
      ...subscription,
      amount: subscription.yearlyAmount,

      stripePriceId: subscription.withoutTrialStripeYearlyPriceId?.trim() || subscription.stripeYearlyPriceId?.trim(),
      stripeWithTrialPriceId:
        subscription.stripeYearlyPriceId?.trim() || subscription.withoutTrialStripeYearlyPriceId?.trim(),

      paypalPriceId: subscription.withoutTrialPaypalYearlyPriceId?.trim() || subscription.paypalYearlyPriceId?.trim(),
      paypalWithTrialPriceId:
        subscription.paypalYearlyPriceId?.trim() || subscription.withoutTrialPaypalYearlyPriceId?.trim(),

      appleProductId:
        subscription.withoutTrialAppleYearlyProductId?.trim() || subscription.appleYearlyProductId?.trim(),
      appleWithTrialProductId:
        subscription.appleYearlyProductId?.trim() || subscription.withoutTrialAppleYearlyProductId?.trim(),

      interval: SubscriptionIntervalsEnum.YEAR,
    } as ISubscriptionPlan,
  };
};

export const getSubscriptionsFromStrapiSubscriptions = (subscriptions?: IStrapiSubscriptionPlan[]) => {
  const prices =
    subscriptions?.reduce((arr: ISubscriptionPlans[], s: IStrapiSubscriptionPlan) => {
      arr.push(getSubscriptionFromStrapiSubscription(s));
      return arr;
    }, []) ?? [];
  return sortBy(prices, [(plan) => getPlanAnnualAmount(plan), (plan) => plan.month.amount]);
};

export const getRegistrationSubscriptionPlan = (subscriptions?: ISubscriptionPlans[], user?: IUser) => {
  if (user?.signupPlanId || user?.signupType === UserSignupType.PLAN) {
    return (
      subscriptions &&
      getPlanByComparator(subscriptions, (plan) => plan.id === user.signupPlanId || plan.id === user.signupId)
    );
  }
};

export const canPurchaseSubscription = (user?: IUser) => {
  if (!user) return false;
  const {oldSubscription, kajabiUser, paymentSystem} = user;
  const hasActiveSubscription = !isSubscriptionCancelled(user) && !isSubscriptionExpired(user);
  const hasEvergreenSubscription = oldSubscription || kajabiUser;
  const hasAppleSubscription = paymentSystem === PaymentSystemEnum.APPLE && hasActiveSubscription;
  const hasPayPalSubscription = paymentSystem === PaymentSystemEnum.PAYPAL;
  return !((hasEvergreenSubscription || hasAppleSubscription || hasPayPalSubscription) && hasActiveSubscription);
};

export const canCancelSubscription = (
  user?: IUser,
  organization?: OrganizationResponse,
  upcomingPlan?: UserUpcomingSubscriptionPlan,
  isTeamAdmin?: boolean
) => {
  if (!user) return false;
  const hasEnterpriseSubscription = user?.enterpriseUser;
  const hasGiftedSubscription = isSubscriptionGifted(user);
  const hasOngoingSubscription = isSubscriptionOngoing(user, upcomingPlan);
  const hasChangeableSubscription = canChangeSubscription(user);
  const hasEmployeeManagedEnterpriseSubscription = organization?.paymentFlow === OrganizationPaymentFlowEnum.Employee;

  if (hasEnterpriseSubscription) {
    return hasEmployeeManagedEnterpriseSubscription && hasOngoingSubscription;
  }

  return !hasGiftedSubscription && (hasChangeableSubscription || isTeamAdmin) && hasOngoingSubscription;
};

export const showIncompatiblePaymentPopup = (user: IUser) => {
  if (!canPurchaseSubscription(user)) {
    const modalProps: ModalFuncProps = {};

    const {oldSubscription, paymentSystem, kajabiUser, fullName} = user;

    const userType = () => {
      if (oldSubscription || paymentSystem === PaymentSystemEnum.PAYPAL) {
        return 'Paypal';
      } else if (kajabiUser) {
        return 'Kajabi';
      } else if (paymentSystem === PaymentSystemEnum.APPLE) {
        return 'ApplePay';
      }
      return user?.level;
    };

    const handleCloseModalEvent = () => {
      getAnalyticsActions().track('Click', {
        element_id: 'close',
        type: 'BUTTON',
        parent_id: `${userType()}-subscription-manage`,
      });
    };

    // When the user doesn't override the default onCancel and onOk functions they should trigger close event
    modalProps.onCancel = () => {
      handleCloseModalEvent();
    };
    modalProps.onOk = () => {
      handleCloseModalEvent();
    };

    if (oldSubscription || paymentSystem === PaymentSystemEnum.PAYPAL) {
      modalProps.content = (
        <>
          <GDTypography className={commonStyles.mb12} block type="w300">
            Let us help!
          </GDTypography>
          <GDTypography className={commonStyles.mb24} block type="w700" subType="s">
            Hi {fullName?.split(' ')?.[0]}, Looks like you want to modify your subscription.
          </GDTypography>
          <GDTypography block type="w300">
            Contact{' '}
            <GDTypography.Link type="w300" target="_blank" href={`mailto:${config.env.supportEmail}`}>
              {config.env.supportEmail}
            </GDTypography.Link>{' '}
            and share what you need help with.
          </GDTypography>
        </>
      );
      modalProps.okText = `Email ${config.env.productName} Support`;
      modalProps.onOk = () => {
        getAnalyticsActions().track('Click', {
          element_id: 'confirm',
          type: 'BUTTON',
          parent_id: `${userType()}-subscription-manage`,
        });
        window.open(`mailto:${config.env.supportEmail}`, '_blank');
      };
    } else if (kajabiUser) {
      modalProps.content = (
        <>
          <GDTypography className={commonStyles.mb24} block type="w700" subType="s">
            Hi {fullName?.split(' ')?.[0]}, Looks like you purchased on GrowthDay Legacy Platform.
          </GDTypography>
          <GDTypography block type="w300">
            To change your subscription continue{' '}
            <GDTypography.Link
              type="w300"
              target="_blank"
              href="https://growthday.com/login"
              onClick={() => {
                getAnalyticsActions().track('Click', {
                  id: 'confirm',
                  type: 'BUTTON',
                  parent_id: `${userType()}-subscription-manage`,
                });
              }}
            >
              here
            </GDTypography.Link>
          </GDTypography>
        </>
      );
    } else if (paymentSystem === PaymentSystemEnum.APPLE) {
      modalProps.content = (
        <>
          <GDTypography className={commonStyles.mb24} block type="w700" subType="s">
            Hi {fullName?.split(' ')?.[0]}, Looks like you purchased on mobile.
          </GDTypography>
          <GDTypography block type="w300">
            To change your subscription continue{' '}
            <GDTypography.Link
              onClick={() => {
                getAnalyticsActions().track('Click', {
                  element_id: 'confirm',
                  type: 'BUTTON',
                  parent_id: `${userType()}-subscription-manage`,
                });
              }}
              type="w300"
              target="_blank"
              href="https://apple.co/2Th4vqI"
            >
              here
            </GDTypography.Link>
          </GDTypography>
        </>
      );
    }
    getAnalyticsActions().track('Element Visible', {
      element_id: `${userType()}-subscription-manage`,
      type: 'MODAL',
    });
    Modal.warning({
      className: styles.modal,
      icon: null,
      centered: true,
      closable: true,
      width: 480,
      okButtonProps: {
        size: 'large',
      },
      okText: 'Okay',
      ...modalProps,
    });
  }
};

export const showExternalAccountPopup = (partnerAccountUrl?: string, onConfirm?: () => void, onClose?: () => void) => {
  const handleExternalLink = () => {
    window.open(partnerAccountUrl, '_blank');
    modal.destroy();
  };

  const content = (
    <Space direction="vertical" size="large">
      <Row justify="center">
        <GDTypography className={commonStyles.mb24} block type="w600" subType="s">
          You are leaving GrowthDay
        </GDTypography>
        <GDTypography block type="w300">
          Follow the link below to manage your subscription through your provider.
        </GDTypography>
      </Row>
      <Row align="middle">
        <Button
          block
          type="primary"
          shape="round"
          onClick={() => {
            handleExternalLink();
            onConfirm?.();
          }}
        >
          Yes, continue to subscription provider
        </Button>
        <Button
          block
          type="link"
          onClick={() => {
            modal.destroy();
            onClose?.();
          }}
        >
          Go back
        </Button>
      </Row>
    </Space>
  );

  const modal = Modal.info({
    className: classNames(styles.modal, styles.externalAccountPopup),
    icon: null,
    centered: true,
    closable: true,
    width: 480,
    content,
  });
};

export const isOfferPaymentSystemInCompatible = (purchasedOffer: IPurchasedOffer) =>
  purchasedOffer.paymentSystem !== PaymentSystemEnum.STRIPE;

export const showIncompatibleOfferPaymentPopup = (user: IUser, purchasedOffer: IPurchasedOffer) => {
  if (isOfferPaymentSystemInCompatible(purchasedOffer)) {
    const modalProps: ModalFuncProps = {};
    if (purchasedOffer.paymentSystem === PaymentSystemEnum.PAYPAL) {
      modalProps.content = (
        <>
          <GDTypography className={commonStyles.mb12} block type="w300">
            Let us help!
          </GDTypography>
          <GDTypography className={commonStyles.mb24} block type="w700" subType="s">
            Hi {user.fullName?.split(' ')?.[0]}, Looks like you want to modify your subscription.
          </GDTypography>
          <GDTypography block type="w300">
            Contact{' '}
            <GDTypography.Link type="w300" target="_blank" href={`mailto:${config.env.supportEmail}`}>
              {config.env.supportEmail}
            </GDTypography.Link>{' '}
            and share what you need help with.
          </GDTypography>
        </>
      );
      modalProps.okText = 'Email GrowthDay Support';
      modalProps.onOk = () => {
        window.open(`mailto:${config.env.supportEmail}`, '_blank');
      };
    } else if (purchasedOffer.paymentSystem === PaymentSystemEnum.APPLE) {
      modalProps.content = (
        <>
          <GDTypography className={commonStyles.mb24} block type="w700" subType="s">
            Hi {user.fullName?.split(' ')?.[0]}, Looks like you purchased on mobile.
          </GDTypography>
          <GDTypography block type="w300">
            To change your subscription continue{' '}
            <GDTypography.Link type="w300" target="_blank" href="https://apple.co/2Th4vqI">
              here
            </GDTypography.Link>
          </GDTypography>
        </>
      );
    }
    Modal.warning({
      className: styles.modal,
      icon: null,
      centered: true,
      closable: true,
      width: 480,
      title: <img src={bgImg} alt="" className={styles.bgImg} />,
      okButtonProps: {
        size: 'large',
      },
      okText: 'Okay',
      ...modalProps,
    });
  }
};

export const isSubscriptionGifted = (user?: IUser) =>
  !!(user && (user.giftSubscriptionBuyerEmail || (user.demoUser && !user.kajabiUser)));

export const isSubscriptionPurchasable = (plans: ISubscriptionPlans) => {
  return Boolean(plans.month.isPurchasable || plans.year.isPurchasable);
};

export const isSubscriptionGiftable = (plans: ISubscriptionPlans) => {
  return Boolean(plans.month.isGiftable || plans.year.isGiftable);
};

export const isGGAddonPaymentSystemInCompatible = (subscription: GrowthGroupSubscription) =>
  Boolean(
    subscription
      ? subscription.paymentSystem === PaymentSystemEnum.APPLE ||
          subscription.paymentSystem === PaymentSystemEnum.PAYPAL
      : false
  );

export const showGGAddonIncompatiblePaymentPopup = (user?: IUser, subscription?: GrowthGroupSubscription) => {
  const modalProps: ModalFuncProps = {};
  if (subscription?.paymentSystem === PaymentSystemEnum.APPLE) {
    modalProps.content = (
      <>
        <GDTypography className={commonStyles.mb24} block type="w700" subType="s">
          Hi {user?.fullName?.split(' ')?.[0]}, Looks like you purchased on mobile.
        </GDTypography>
        <GDTypography block type="w300">
          To change your subscription continue{' '}
          <GDTypography.Link type="w300" target="_blank" href="https://apple.co/2Th4vqI">
            here
          </GDTypography.Link>
        </GDTypography>
      </>
    );
  }
  Modal.warning({
    className: styles.modal,
    icon: null,
    centered: true,
    closable: true,
    width: 480,
    title: <img src={bgImg} alt="" className={styles.bgImg} />,
    okButtonProps: {
      size: 'large',
    },
    okText: 'Okay',
    ...modalProps,
  });
};
