/**
 * Model definition for Growthgroups-upsell-content-role
 */
export interface IGrowthgroupsUpsellContentRole {
  id: number;
  value?: EnumGrowthgroupsUpsellContentRoleValue;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumGrowthgroupsUpsellContentRoleValue {
  HOST = 'HOST',
  CO_HOST = 'CO_HOST',
  ATTENDEE = 'ATTENDEE',
  SPEAKER = 'SPEAKER',
  WAITING_ROOM = 'WAITING_ROOM',
}
