import {useDispatch} from 'react-redux';
import {useCallback, useMemo} from 'react';
import offersApiActions from '../offers.actions';
import useAuthState from '../../auth/hooks/useAuthState';
import useOffersState from './useOffersState';
import useUserState, {UserStateEnum} from '../../auth/hooks/useUserState';
import {keyBy, uniqBy} from 'lodash';
import isEvergreenUser from '../../auth/utils/isEvergreenUser';
import isOfferSubscriptionType from '../utils/isOfferSubscriptionType';
import {isOfferNotExpired} from '../utils/filterOffers';
import getPlanFromOffer from '../utils/getPlanFromOffer';
import compareSubscriptions from '../../license/components/SubscriptionsModal/utils/compareSubscriptions';
import {IStrapiSubscriptionPlan, ISubscriptionPlan} from '../../auth/interfaces';
import useSubscriptionPlans from '@/features/auth/routes/Profile/hooks/useSubscriptionPlans';
import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';
import {IOffersNonNested} from '@growthday/ui-core/src/types/offers';
import {usePartiallyPopulatedCollection} from '@growthday/ui-core/src/apis/usePartiallyPopulatedCollection';
import {ISubscriptionPlans} from '@growthday/ui-core/src/types/strapi';

const usePurchasedOffers = (plan?: IStrapiSubscriptionPlan | ISubscriptionPlan) => {
  const userState = useUserState();
  const {allStrapiSubscriptionPlans, subscriptionPlans, activeSubscription} = useSubscriptionPlans();
  const {accessToken} = useAuthState();
  const {user} = useUser();
  const {
    loading: {getPurchasedOffers: loading},
    purchasedOffers,
    offers,
  } = useOffersState();
  const dispatch = useDispatch();

  const refetchPurchasedOffers = useCallback(() => {
    if (!accessToken || loading || userState === UserStateEnum.UNAUTHENTICATED) return;
    dispatch(offersApiActions.getPurchasedOffers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, accessToken, userState]);

  const {data: activeSubscriptionWithFreeOffers} = usePartiallyPopulatedCollection<
    Pick<ISubscriptionPlans, 'id' | 'freeOffers'>
  >('subscription-plans', activeSubscription?.id ?? 0, ['freeOffers'], {staleTime: Infinity});

  const activeSubscriptionFreeOffers = activeSubscriptionWithFreeOffers?.freeOffers;

  const allPurchasedOffers = useMemo(() => {
    const _offers = offers.filter((offer) => !isOfferSubscriptionType(offer));
    if (isEvergreenUser(user)) {
      return _offers.filter((offer) => offer.showInMembershipArea);
    }
    const offersMap = keyBy(_offers, 'id');
    const purchased = purchasedOffers.map(({offerId}) => offersMap[offerId]).filter(Boolean);
    const free =
      [...(activeSubscriptionFreeOffers ?? []), ...(plan?.freeOffers ?? [])]
        ?.map(({id}) => offersMap[id])
        ?.filter(Boolean) ?? [];
    return uniqBy([...purchased, ...free], 'id');
  }, [offers, user, purchasedOffers, activeSubscriptionFreeOffers, plan?.freeOffers]);

  const purchasedOffersMap = useMemo(() => {
    const freeOffersMap = keyBy(activeSubscriptionFreeOffers, 'id');
    return keyBy(
      purchasedOffers.filter(({offerId}) => !freeOffersMap[offerId]),
      'offerId'
    );
  }, [activeSubscriptionFreeOffers, purchasedOffers]);

  const canPurchaseOffer = useCallback(
    (offer?: IOffersNonNested) => {
      if (!offer) {
        return false;
      }
      if (isEvergreenUser(user)) {
        return false;
      }

      if (activeSubscription && offer.changeToPlan) {
        const subscription = getPlanFromOffer(allStrapiSubscriptionPlans, subscriptionPlans, offer);
        if (subscription) {
          return compareSubscriptions(subscription, activeSubscription);
        }
      }

      const eligibleOffers = allPurchasedOffers.filter(
        (purchasedOffer) =>
          // ------------------------------------------------------------------------ //

          purchasedOffer.id === offer.id ||
          // if offers match

          (offer.canonical?.id && purchasedOffer.id === offer.canonical.id) ||
          // if purchased offer matches with canonical offer

          (typeof (offer.canonical as unknown) === 'number' &&
            purchasedOffer.id === (offer.canonical as unknown as number)) ||
          // if purchased offer matches with canonical offer (as id)

          // ------------------------------------------------------------------------ //

          (purchasedOffer.canonical?.id && purchasedOffer.canonical.id === offer.id) ||
          // if purchased canonical offer matches with offer

          (purchasedOffer.canonical?.id && offer.canonical?.id && purchasedOffer.canonical.id === offer.canonical.id) ||
          // if purchased canonical offer matches with canonical offer

          (typeof (offer.canonical as unknown) === 'number' &&
            purchasedOffer.canonical?.id &&
            purchasedOffer.canonical.id === (offer.canonical as unknown as number)) ||
          // if purchased canonical offer matches with canonical offer (as id)

          // ------------------------------------------------------------------------ //

          (typeof (purchasedOffer.canonical as unknown) === 'number' &&
            (purchasedOffer.canonical as unknown as number) === offer.id) ||
          // if purchased canonical offer (as id) matches with offer

          (typeof (purchasedOffer.canonical as unknown) === 'number' &&
            offer.canonical?.id &&
            (purchasedOffer.canonical as unknown as number) === offer.canonical.id) ||
          // if purchased canonical offer (as id) matches with canonical offer

          (typeof (purchasedOffer.canonical as unknown) === 'number' &&
            typeof (offer.canonical as unknown) === 'number' &&
            (purchasedOffer.canonical as unknown as number) === (offer.canonical as unknown as number))
        // if purchased canonical offer (as id) matches with canonical offer (as id)

        // ------------------------------------------------------------------------ //
      );

      const freeOffers = eligibleOffers.filter((offer) => !purchasedOffersMap[offer.id]);
      const purchasedOffers = eligibleOffers.map((offer) => purchasedOffersMap[offer.id]).filter(Boolean);

      if (freeOffers.length) {
        return false;
      }

      if (purchasedOffers.length) {
        return !purchasedOffers.some(isOfferNotExpired);
      }

      return true;
    },
    [user, activeSubscription, allPurchasedOffers, allStrapiSubscriptionPlans, subscriptionPlans, purchasedOffersMap]
  );

  return {
    purchasedOffers,
    refetchPurchasedOffers,
    loading,
    allPurchasedOffers,
    canPurchaseOffer,
    purchasedOffersMap,
  };
};

export default usePurchasedOffers;
