import {FC} from 'react';
import {RouteProps} from 'react-router';
import {generatePath, Redirect, Route} from 'react-router-dom';

const RedirectWithParams: FC<
  {
    to: string;
    search?: string;
  } & RouteProps
> = ({to, search, ...rest}) => {
  return (
    <Route {...rest} render={(props) => <Redirect to={`${generatePath(to, props.match.params)}${search || ''}`} />} />
  );
};

export default RedirectWithParams;
