import React, {FC} from 'react';
import {Button, Card, Col, Row, Typography} from 'antd';
import {useHistory} from 'react-router-dom';
import styles from './AuthContainer.module.less';
import Visible from '../../../../shared/components/Visible';
import {getAnalyticsActions} from '@/features/analytics/hooks/useAnalyticsActions';
import classNames from 'classnames';
import {getStaticAssetUrl} from '@/shared/util/get-static-asset-url';
import config from '@/config';

const {Title, Text} = Typography;

type Props = {
  hideSignupCol?: boolean;
  className?: string;
};

const highlightSrc = getStaticAssetUrl('auth-page', 'tagline-highlight.svg');

const AuthContainer: FC<Props> = ({className, children, hideSignupCol}) => {
  const history = useHistory();

  return (
    <Row justify="center" align="middle" className={classNames('auth-container', className, styles.main)}>
      {!hideSignupCol && (
        <Visible md>
          <Col md={0} xl={1} />
          <Col md={10} lg={12} xl={10} className={styles.firstCol}>
            <Card className={styles.firstColumnCard} bordered={false}>
              <Title className={classNames(styles.firstColumnTitle, 'forgot-password-title')}>
                {config.env.ui.branding.tagline}
              </Title>
              <img
                alt={config.env.ui.branding.highlight}
                src={highlightSrc}
                className={classNames(styles.to_growth, 'forgot-password-title-highlight')}
              />

              <div className={styles.firstColumnSignUpContainer}>
                <Text className={styles.firstColumnSignUpTitle}>Don't have an account?</Text>
                <Button
                  type="link"
                  className={styles.firstColumnSignUpButton}
                  onClick={() => {
                    getAnalyticsActions().track('Sign Up Click', {});
                    history.push('/signup');
                  }}
                >
                  <Title className={styles.firstColumnSignUpSubTitle}>
                    Sign up{' '}
                    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.5436 0.520098L10.7769 1.28674C10.5912 1.4724 10.5943 1.77439 10.7837 1.95627L13.9367 4.98432H0.46875C0.209883 4.98432 0 5.1942 0 5.45307V6.54682C0 6.80568 0.209883 7.01557 0.46875 7.01557H13.9367L10.7837 10.0436C10.5943 10.2255 10.5912 10.5275 10.777 10.7131L11.5436 11.4798C11.7266 11.6628 12.0234 11.6628 12.2065 11.4798L17.3549 6.33139C17.538 6.14834 17.538 5.85154 17.3549 5.66846L12.2064 0.520098C12.0234 0.337051 11.7266 0.337051 11.5436 0.520098Z"
                        fill="#0076F7"
                      />
                    </svg>
                  </Title>
                </Button>
              </div>
            </Card>
          </Col>
          <Col md={0} xl={1} />
        </Visible>
      )}
      <Col xs={24} md={hideSignupCol ? 24 : 14} lg={hideSignupCol ? 24 : 12} className={styles.col}>
        {children}
      </Col>
    </Row>
  );
};
export default AuthContainer;
