import {IButton} from './button';
import {ICheckList} from './check-list';
import {IDescription} from './description';
import {IDropDown} from './drop-down';
import {IHeader} from './header';
import {IIntentSelect} from './intent-select';
import {ILifeScores} from './life-scores';
import {ILogo} from './logo';
import {IRadio} from './radio';
import {IShortAnswer} from './short-answer';

/**
 * Model definition for Onboard-modal
 */
export interface IOnboardModal {
  id: number;
  name: string;
  onboardComponents: (
    | ({__component: 'flow.header'} & IHeader)
    | ({__component: 'flow.description'} & IDescription)
    | ({__component: 'onboarding-modal.button'} & IButton)
    | ({__component: 'onboarding-modal.check-list'} & ICheckList)
    | ({__component: 'onboarding-modal.drop-down'} & IDropDown)
    | ({__component: 'onboarding-modal.intent-select'} & IIntentSelect)
    | ({__component: 'onboarding-modal.life-scores'} & ILifeScores)
    | ({__component: 'onboarding-modal.logo'} & ILogo)
    | ({__component: 'onboarding-modal.radio'} & IRadio)
    | ({__component: 'onboarding-modal.short-answer'} & IShortAnswer)
  )[];
  type: EnumOnboardModalType;
  redirectRoute?: EnumOnboardModalRedirectRoute;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumOnboardModalType {
  INTENT = 'INTENT',
  LIFESCORE = 'LIFESCORE',
  SURVEY = 'SURVEY',
  WELCOME = 'WELCOME',
}

export enum EnumOnboardModalRedirectRoute {
  WELCOME = 'WELCOME',
}
