/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {AssessmentPercentage} from './assessment-percentage';

/**
 *
 * @export
 * @interface Assessment
 */
export interface Assessment {
  /**
   *
   * @type {string}
   * @memberof Assessment
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof Assessment
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof Assessment
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof Assessment
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof Assessment
   */
  type: AssessmentTypeEnum;
  /**
   *
   * @type {{ [key: string]: number; }}
   * @memberof Assessment
   */
  scoreCard?: {[key: string]: number};
  /**
   *
   * @type {string}
   * @memberof Assessment
   */
  uuid: string;
  /**
   *
   * @type {Array<AssessmentPercentage>}
   * @memberof Assessment
   */
  assessmentPercentage?: Array<AssessmentPercentage>;
}

export const AssessmentTypeEnum = {
  Daily: 'DAILY',
  Weekly: 'WEEKLY',
  Monthly: 'MONTHLY',
  TeamAssessment: 'TEAM_ASSESSMENT',
} as const;

export type AssessmentTypeEnum = (typeof AssessmentTypeEnum)[keyof typeof AssessmentTypeEnum];
