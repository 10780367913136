import {useQuery, UseQueryOptions} from 'react-query';
import {UserMetadata} from '../../types/api';
import {axiosGrowthDay} from '../../utils/api';

export function GetUserMetaDataKey() {
  return ['USER', 'METADATA'];
}

export default function useGetUserMetadata(
  enabled: boolean,
  options: UseQueryOptions<
    UserMetadata | null,
    unknown,
    UserMetadata | null,
    ReturnType<typeof GetUserMetaDataKey>
  > = {}
) {
  return useQuery(
    GetUserMetaDataKey(),
    async () => {
      const result = await axiosGrowthDay.get(`/users/metadata`);
      return result.data as UserMetadata;
    },
    {
      ...options,
      enabled,
    }
  );
}
