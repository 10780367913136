import useUpdatedRef from '../../../hooks/useUpdatedRef';
import dayjs from 'dayjs';
import {useAuthData} from '../../../hooks/useAuthData';
import React, {createContext, FunctionComponent, useCallback, useContext, useEffect, useMemo, useRef} from 'react';
import {GG_Collection_Status_Topic, GG_Initial_Status_Topic} from '../../../Provider/WebSocketProvider/topics';
import {GrowthGroupInstance, Participant} from '../../../types/api';
import {useWebSocketContext} from '../../../Provider/WebSocketProvider';
import useLazyRef from '../../../hooks/useLazyRef';
import {hashQueryKey} from 'react-query';
import {
  GroupCollectionInstancesField,
  GroupCollectionPayload,
  GrowthGroupCollectionStatusTopic,
  GrowthGroupInstanceInitialStatusTopic,
  GrowthGroupStatusTopicData,
  GrowthGroupStatusTopicEventType,
} from '../../../types/websocket';
import useUpdateGrowthGroupInstance, {
  useUpdateGrowthGroupInstanceParticipants,
} from '../hooks/useUpdateGrowthGroupInstance';
import {keyBy} from 'lodash';
import useRunOnUnMount from '../../../hooks/useRunOnUnMount';
import isGroupLive from '../util/isGroupLive';
import useStableCallback from '../../../hooks/useStableCallback';
import {useActiveCallInfo} from '../hooks/hms/useActiveCallInfo';
import {useKeynoteUpdater} from '../../../keynote/hooks/useKeynoteUpdater';
import {EnumKeynoteState} from '../../../types/strapi';
import {isKeynote} from '../util/isKeynote';

export interface GroupWebSocketUpdateContextValue {
  subscribe(instance: GrowthGroupInstance): void;

  unsubscribe(instance: GrowthGroupInstance): void;

  checkGroupFull(instanceId: GrowthGroupInstance['id']): boolean;
}

const GroupWebSocketUpdateContext = createContext<GroupWebSocketUpdateContextValue>({
  subscribe() {
    //
  },
  unsubscribe() {
    //
  },
  checkGroupFull: () => false,
});

const unsubscribeTimeout = 500;

const GroupWebSocketUpdateProvider: FunctionComponent = ({children}) => {
  const {unsubscribe: wsUnsubscribe, subscribe: wsSubscribe} = useWebSocketContext();
  const {user} = useAuthData();

  // Keep track of topic subscriptions requested for instance/group collection
  //Only one topic of both types exists - so common map can be used
  const connectionCountMap = useLazyRef<Map<GrowthGroupInstance['id'] | GrowthGroupInstance['groupId'], number>>(
    () => new Map()
  );
  const {updater} = useUpdateGrowthGroupInstance();
  const {updater: participantsUpdater} = useUpdateGrowthGroupInstanceParticipants();
  const keynoteUpdater = useKeynoteUpdater();
  const timeoutRef = useRef<Map<string, number>>(new Map());
  const {slug} = useActiveCallInfo();
  const slugRef = useUpdatedRef(slug);

  // Keep track of groups that are full globally. This is because the group instance can be refetched
  // And we lose the isFull flag on instance level that was added from react query
  const fullGroupSet = useRef<Set<GrowthGroupInstance['id']>>(new Set());
  const onGroupFull = useStableCallback((instance: Pick<GrowthGroupInstance, 'id'>) =>
    fullGroupSet.current.add(instance.id)
  );
  const onGroupVacant = useStableCallback((instance: Pick<GrowthGroupInstance, 'id'>) =>
    fullGroupSet.current.delete(instance.id)
  );

  const makeKeynoteLive = useStableCallback((instance: Pick<GrowthGroupInstance, 'id' | 'slug'>) => {
    keynoteUpdater({slug: instance.slug}, (keynote) => {
      keynote.state = EnumKeynoteState.LIVE;
    });
    updater(instance, (old) => {
      if (isKeynote(old)) {
        old.keynoteState = EnumKeynoteState.LIVE;
      }

      return old;
    });
  });

  const markKeynoteEnded = useStableCallback((instance: Pick<GrowthGroupInstance, 'id' | 'slug'>) => {
    keynoteUpdater({slug: instance.slug}, (keynote) => {
      keynote.state = EnumKeynoteState.ENDED;
    });
    updater(instance, (old) => {
      if (isKeynote(old)) {
        old.keynoteState = EnumKeynoteState.ENDED;
      }

      return old;
    });
  });

  const subscribe = useCallback(
    (instance: GrowthGroupInstance) => {
      // Only one subscription requested for one type of topic - instance/group level
      const shouldCreateSubscription = (id: GrowthGroupInstance['groupId'] | GrowthGroupInstance['id']) => {
        // Keep a track of group subscriptions. Create only one subscription per topic
        const connectionCount = connectionCountMap.current.get(id);
        // If subscription already exists, do not create new one
        if (connectionCount) {
          connectionCountMap.current.set(id, connectionCount + 1);
          return false;
        }

        connectionCountMap.current.set(id, 1);
        // If unsubscribe is scheduled, cancel it and keep the subscription
        if (id && timeoutRef.current.has(id)) {
          clearTimeout(timeoutRef.current.get(id));
          timeoutRef.current.delete(id);
          return false;
        }

        // Create subscription, no subscription present
        return true;
      };

      // Initial topic, only for checking if the group is full
      // Should only be checked for live groups
      // Instance level topic
      isGroupLive(instance) &&
        shouldCreateSubscription(instance.id) &&
        instance.id &&
        wsSubscribe(
          hashQueryKey(['GROWTH_GROUP', 'INITIAL_STATUS', GG_Initial_Status_Topic(instance.id)]),
          GG_Initial_Status_Topic(instance.id),
          (event: GrowthGroupInstanceInitialStatusTopic) => {
            const dataFromEvent = event.data as GrowthGroupStatusTopicData;
            if (
              dataFromEvent &&
              [
                GrowthGroupStatusTopicEventType.LIVE_NOW,
                GrowthGroupStatusTopicEventType.LIVE_NOW_FULL,
                GrowthGroupStatusTopicEventType.LIVE_NOW_VACANT,
              ].includes(event?.statusType)
            ) {
              const ggFull = !!(
                dataFromEvent &&
                dataFromEvent.joinCount &&
                dataFromEvent.joinLimit &&
                dataFromEvent.joinCount >= dataFromEvent.joinLimit
              );
              updater(instance, (draft) => {
                //@ts-ignore
                // Force re-render UI
                draft.__ui_isFull = ggFull;
                return draft;
              });
              makeKeynoteLive(instance);
              if (ggFull) {
                onGroupFull(instance);
              }
            }

            // Only for initial topic. Unsubscribe afterwards
            const currentCount = connectionCountMap.current.get(instance.id) ?? 0;
            if (currentCount === 1 && instance.id) {
              const timer = window.setTimeout(() => {
                if (instance.id) {
                  wsUnsubscribe(
                    hashQueryKey(['GROWTH_GROUP', 'INITIAL_STATUS', GG_Initial_Status_Topic(instance.id)]),
                    GG_Initial_Status_Topic(instance.id)
                  );
                  timeoutRef.current.delete(instance.id);
                }
              }, unsubscribeTimeout);
              timeoutRef.current.set(instance.id, timer);
            }
            connectionCountMap.current.set(instance.id, Math.max(0, currentCount - 1));
          }
        );

      // Group level topic
      if (shouldCreateSubscription(instance.groupId))
        wsSubscribe(
          hashQueryKey(['GROWTH_GROUP', 'STATUS', GG_Collection_Status_Topic(instance.groupId)]),
          GG_Collection_Status_Topic(instance.groupId),
          (event: GrowthGroupCollectionStatusTopic) => {
            const updateAllInstances = <T extends GrowthGroupStatusTopicEventType>(
              eventName: T,
              callback: (
                instance: Pick<GrowthGroupInstance, 'id' | 'groupId' | 'slug'>,
                data: GroupCollectionInstancesField<T>
              ) => void
            ) => {
              const evt = event as GroupCollectionPayload<T>;
              const instancesData = evt?.data?.instancesData;
              if (!instancesData) {
                return;
              }
              for (const instanceData of instancesData) {
                const data = instanceData.data;
                const list = instanceData.instances;

                if (data) {
                  for (const entry of list) {
                    callback({...entry, groupId: instance.groupId}, data);
                  }
                }
              }
            };

            switch (event.eventType) {
              case GrowthGroupStatusTopicEventType.ADMIT_ALL:
                updateAllInstances(event.eventType, (_instance, dataFromEvent) => {
                  updater(_instance, (draft) => {
                    draft.admitAll = dataFromEvent?.admitAll;
                    draft.admitAllTime = dayjs().valueOf();
                    return draft;
                  });
                });
                break;
              case GrowthGroupStatusTopicEventType.CANCELLED:
                updateAllInstances(event.eventType, (_instance, dataFromEvent) => {
                  updater(_instance, (draft) => {
                    draft.actualEndTime = dataFromEvent?.actualEndTime;
                    draft.cancelled = true;
                    return draft;
                  });
                });
                break;
              case GrowthGroupStatusTopicEventType.ENDED:
                updateAllInstances(event.eventType, (_instance, dataFromEvent) => {
                  updater(_instance, (draft) => {
                    draft.actualEndTime = dataFromEvent?.actualEndTime;
                    return draft;
                  });
                  markKeynoteEnded(_instance);
                });
                break;
              case GrowthGroupStatusTopicEventType.UPDATED:
                updateAllInstances(event.eventType, (_instance, dataFromEvent) => {
                  const {keynoteVideoOverlay, ...groupUpdate} = dataFromEvent;
                  updater(_instance, (draft) => {
                    Object.assign(draft, groupUpdate);
                    return draft;
                  });

                  keynoteUpdater(instance, (old) => {
                    old.videoOverlay = keynoteVideoOverlay;
                  });
                });
                break;
              case GrowthGroupStatusTopicEventType.ROLES_CHANGED:
                updateAllInstances(event.eventType, (_instance, dataFromEvent) => {
                  participantsUpdater(_instance, (draft) => {
                    const updatedRoleMap = keyBy(dataFromEvent?.participantRoles, 'uuid');
                    draft.forEach((participant) => {
                      if (participant.uuid) {
                        const update = updatedRoleMap[participant.uuid];
                        if (update) {
                          participant.role = update.role;
                        }
                      }
                    });
                  });
                });
                break;
              case GrowthGroupStatusTopicEventType.RSVP:
                updateAllInstances(event.eventType, (_instance, dataFromEvent) => {
                  const isCurrentGroupLiveNow = slugRef.current === _instance.slug;

                  const participant = dataFromEvent?.participant;

                  if (participant) {
                    const groupParticipantsUpdater = (isUserUpdate?: boolean) => (draft: Participant[]) => {
                      if (
                        dataFromEvent?.participant?.uuid &&
                        (!isUserUpdate || (user?.uuid && dataFromEvent.participant.uuid === user.uuid))
                      ) {
                        const index = draft.findIndex(
                          (_participant) => _participant.uuid === dataFromEvent.participant?.uuid
                        );
                        if (index > -1) {
                          draft[index] = dataFromEvent.participant;
                        } else {
                          draft.push(dataFromEvent.participant);
                        }
                      }
                    };

                    participantsUpdater(_instance, groupParticipantsUpdater(), groupParticipantsUpdater(true));
                    if (!isCurrentGroupLiveNow) {
                      updater(_instance, (draft) => {
                        if (draft.topParticipants) {
                          const currentIndex = draft.topParticipants.findIndex(
                            (topParticipant) => topParticipant.uuid === participant.uuid
                          );

                          const topParticipant = {
                            uuid: participant.uuid,
                            image: participant.profileImage,
                            name: `${participant.firstName ?? ''} ${participant.lastName ?? ''}`.trim(),
                          };
                          if (currentIndex === -1) {
                            draft.rsvpCount = (draft.rsvpCount ?? 0) + 1;
                            if (draft.topParticipants.length < 5) {
                              draft.topParticipants.push(topParticipant);
                            }
                          } else {
                            draft.topParticipants[currentIndex] = topParticipant;
                          }
                        }
                        return draft;
                      });
                    }
                  }
                });
                break;
              case GrowthGroupStatusTopicEventType.RSVP_DELETED:
                updateAllInstances(event.eventType, (_instance, dataFromEvent) => {
                  const uuid = dataFromEvent?.participant?.uuid;
                  if (uuid) {
                    participantsUpdater(_instance, (draft) =>
                      draft.filter((_participant) => _participant.uuid !== dataFromEvent?.participant?.uuid)
                    );
                    updater(_instance, (draft) => {
                      draft.rsvpCount = Math.max((draft.rsvpCount ?? 0) - 1, 0);
                      draft.topParticipants = draft.topParticipants?.filter((p) => p.uuid !== uuid);
                      return draft;
                    });
                  }
                });
                break;
              case GrowthGroupStatusTopicEventType.STARTS_SOON:
                updateAllInstances(event.eventType, (_instance, dataFromEvent) => {
                  updater(_instance, (draft) => {
                    if (dataFromEvent?.backstageOpen && !draft.actualStartTime) {
                      draft.actualStartTime = draft.startTime;
                    }
                    return draft;
                  });
                });
                break;
              case GrowthGroupStatusTopicEventType.LIVE_NOW:
                updateAllInstances(event.eventType, (_instance, dataFromEvent) => {
                  updater(_instance, (draft) => {
                    draft.startTime = dataFromEvent?.startTime ?? 0;
                    if (!draft.actualStartTime) {
                      draft.actualStartTime = dataFromEvent?.actualStartTime;
                    }
                    return draft;
                  });
                  makeKeynoteLive(_instance);
                });
                break;
              case GrowthGroupStatusTopicEventType.LIVE_NOW_FULL:
              case GrowthGroupStatusTopicEventType.LIVE_NOW_VACANT:
                updateAllInstances(event.eventType, (_instance, dataFromEvent) => {
                  updater(_instance, (draft) => {
                    const isFull = !!(
                      dataFromEvent &&
                      dataFromEvent.joinCount &&
                      dataFromEvent.joinLimit &&
                      dataFromEvent.joinCount >= dataFromEvent.joinLimit
                    );
                    //@ts-ignore
                    // Force re-render UI
                    draft.__ui_isFull = isFull;
                    isFull ? onGroupFull(_instance) : onGroupVacant(_instance);
                    return draft;
                  });
                });
                break;
            }
          }
        );
    },
    [
      wsSubscribe,
      connectionCountMap,
      updater,
      makeKeynoteLive,
      onGroupFull,
      wsUnsubscribe,
      markKeynoteEnded,
      participantsUpdater,
      slugRef,
      user?.uuid,
      onGroupVacant,
      keynoteUpdater,
    ]
  );

  const unsubscribe = useCallback(
    (instance: GrowthGroupInstance) => {
      // Unsubscribe from group level topic
      const currentCount = connectionCountMap.current.get(instance.groupId) ?? 0;
      if (currentCount === 1) {
        const timer = window.setTimeout(() => {
          wsUnsubscribe(
            hashQueryKey(['GROWTH_GROUP', 'STATUS', GG_Collection_Status_Topic(instance.groupId)]),
            GG_Collection_Status_Topic(instance.groupId)
          );
          timeoutRef.current.delete(instance.groupId);
        }, unsubscribeTimeout);
        timeoutRef.current.set(instance.groupId, timer);
      }
      connectionCountMap.current.set(instance.groupId, Math.max(0, currentCount - 1));
    },
    [connectionCountMap, wsUnsubscribe]
  );

  const checkGroupFull: GroupWebSocketUpdateContextValue['checkGroupFull'] = useCallback(
    (instanceId) => fullGroupSet.current.has(instanceId),
    []
  );

  const values: GroupWebSocketUpdateContextValue = useMemo(
    () => ({subscribe, unsubscribe, checkGroupFull}),
    [subscribe, unsubscribe, checkGroupFull]
  );
  return <GroupWebSocketUpdateContext.Provider value={values}>{children}</GroupWebSocketUpdateContext.Provider>;
};

export function useGroupWebSocketUpdate(instance: GrowthGroupInstance) {
  const {subscribe, unsubscribe} = useContext(GroupWebSocketUpdateContext);
  const {connected} = useWebSocketContext();
  const didSubscribe = useRef(false);

  useEffect(() => {
    if (connected && !didSubscribe.current) {
      didSubscribe.current = true;
      subscribe(instance);
    }
  }, [connected, instance, subscribe]);

  useRunOnUnMount(() => didSubscribe.current && unsubscribe(instance));
}

export function useCheckGroupFull() {
  return useContext(GroupWebSocketUpdateContext).checkGroupFull;
}

export default GroupWebSocketUpdateProvider;
