import {createContext, FunctionComponent, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import ResolvablePromise from 'resolvable-promise';
import authActions from '../../auth.actions';
import useCustomDispatch from '../../../../shared/hooks/useCustomDispatch';
import useRunOnMount from '@growthday/ui-core/src/hooks/useRunOnMount';
import useRunOnUnMount from '@growthday/ui-core/src/hooks/useRunOnUnMount';
import {useQueryClient} from 'react-query';
import {UserMeDataQueryKey} from '@growthday/ui-core/src/features/user/hooks/useGetMeData';

type CallbackID = number;
export interface BeforeLogoutContextValues {
  addId(): null | CallbackID;
  removeId(id: CallbackID): void;
  execute(): Promise<void> | ResolvablePromise<void>;
  activeId: CallbackID | null;
}

export const BeforeLogoutContext = createContext<BeforeLogoutContextValues>({
  addId() {
    return null;
  },
  removeId() {
    //
  },
  async execute() {
    //
  },
  activeId: null,
});

const killTimeout = 2000;

const BeforeLogoutProvider: FunctionComponent = ({children}) => {
  const idCounter = useRef<CallbackID>(0);
  const getNewId = useCallback(() => ++idCounter.current, []);
  const promiseRef = useRef<null | ResolvablePromise<void>>(null);

  const [queue, setQueue] = useState<CallbackID[]>([]);
  const [loading, setLoading] = useState(false);
  // Has value only when executing
  const activeId = !loading ? null : queue[0] ?? null;

  useEffect(() => {
    if (!queue.length) {
      setLoading(false);
      promiseRef.current?.resolve();
      promiseRef.current = null;
    }
  }, [queue.length]);

  const execute: BeforeLogoutContextValues['execute'] = useCallback(() => {
    if (promiseRef.current) {
      return promiseRef.current;
    }

    setLoading(true);
    setTimeout(() => {
      if (promiseRef.current) {
        setLoading(false);
        promiseRef.current?.resolve();
        setQueue([]);
      }
    }, killTimeout);
    return (promiseRef.current = new ResolvablePromise());
  }, []);

  const removeId: BeforeLogoutContextValues['removeId'] = useCallback((id: CallbackID) => {
    setQueue((q) => q.filter((e) => e !== id));
  }, []);

  const addId: BeforeLogoutContextValues['addId'] = useCallback(() => {
    if (!loading) {
      const id = getNewId();
      setQueue((q) => [...q, id]);
      return id;
    }

    return null;
  }, [getNewId, loading]);

  const value = useMemo(() => ({addId, removeId, execute, activeId}), [addId, execute, removeId, activeId]);
  return <BeforeLogoutContext.Provider value={value}>{children}</BeforeLogoutContext.Provider>;
};

export function useRunBeforeLogout(callback: () => void | Promise<void>) {
  const {removeId, addId, activeId} = useContext(BeforeLogoutContext);
  const currentId = useRef<CallbackID | null>(null);

  useRunOnMount(() => {
    currentId.current = addId();
  });

  useRunOnUnMount(() => {
    currentId.current && removeId(currentId.current);
  });
  const cb = useRef(() => {
    //
  });
  cb.current = () => {
    const res = callback();
    if (res instanceof Promise) {
      res.then(() => currentId.current && removeId(currentId.current));
    } else {
      currentId.current && removeId(currentId.current);
    }
  };

  useEffect(() => {
    if (activeId && activeId === currentId.current) {
      cb.current();
    }
  }, [activeId]);
}

export function useLogout() {
  const {asyncDispatch} = useCustomDispatch();
  const {execute} = useContext(BeforeLogoutContext);
  const queryClient = useQueryClient();

  return useCallback(async () => {
    const logoutFromApp = async () => {
      await execute();
      await asyncDispatch(authActions.logout());
      queryClient.removeQueries({queryKey: UserMeDataQueryKey});
    };
    return logoutFromApp();
  }, [asyncDispatch, execute, queryClient]);
}

export default BeforeLogoutProvider;
