import React from 'react';
import SyncedQueueProvider from './SyncedQueueProvider';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {WebSocketProvider} from './WebSocketProvider';
import GroupWebSocketUpdateProvider from '../features/growthgroups/GroupWebSocketUpdateProvider';

interface Props {
  debugReactQuery?: boolean;
}

export const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      retry: false,
      staleTime: 15 * 1000,
      refetchOnWindowFocus: false,
    },
  },
});

const CoreProvider: React.FC<Props> = ({children, debugReactQuery}) => (
  <QueryClientProvider client={reactQueryClient}>
    <SyncedQueueProvider>
      <WebSocketProvider>
        <GroupWebSocketUpdateProvider>{children}</GroupWebSocketUpdateProvider>
        {debugReactQuery && <ReactQueryDevtools initialIsOpen={false} />}
      </WebSocketProvider>
    </SyncedQueueProvider>
  </QueryClientProvider>
);

export default CoreProvider;
