import {EnumSubscriptionPlansLevel as StrapiSubscriptionPlansLevel} from '@growthday/ui-core/src/types/strapi';

export enum ProfileTabsEnum {
  MY_PROFILE = 'my-profile',
  MY_PURCHASES = 'my-purchases',
  MEMBERSHIP_AND_BILLING = 'membership-and-billing',
  COINS = 'coins',
  REFERRAL = 'referral',
  NOTIFICATIONS = 'notifications',
  PRIVACY = 'privacy',
  SIGN_IN_AND_SECURITY = 'sign-in-and-security',
  RECORDINGS = 'recordings',
  GIFT = 'gift',
  PAID_GROUPS = 'paid-groups',
  TEAMS = 'teams',
}

// Extend the subscription levels from strapi to match existing enum
export const SubscriptionLevelsEnum = {
  ...StrapiSubscriptionPlansLevel,
  OFFER: 'OFFER',
  TRIAL: 'TRIAL',
  NONE: 'NONE',
  CHALLENGE: 'CHALLENGE',
  LIVE_EVENT: 'LIVE_EVENT',
} as const;

export type SubscriptionLevelsEnum = (typeof SubscriptionLevelsEnum)[keyof typeof SubscriptionLevelsEnum];

export enum NumeratedSubscriptionLevels {
  BASIC = 1,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  STARTER = 1,
  MASTERY = 2,
  ALL_ACCESS = 3,
}

export enum PaymentSystemEnum {
  STRIPE = 'CARD',
  PAYPAL = 'PAYPAL',
  APPLE = 'APPLE',
}
