import {useMutation, UseMutationOptions, useQueryClient} from 'react-query';
import {UserMetadata} from '@growthday/ui-core/src/types/api';
import {axiosGrowthDay} from '../../../shared/api';
import {GetUserMetaDataKey} from '@growthday/ui-core/src/features/usermetadata/useGetUserMetadata';

export const TRANSFER_ACCOUNT_MUTATION_KEY = ['GROWTHDAY', 'MUTATION', 'TRANSFER_ACCOUNT'];

const useTransferAccountMutation = (
  options: Omit<
    UseMutationOptions<UserMetadata, unknown, string | undefined, typeof TRANSFER_ACCOUNT_MUTATION_KEY>,
    'queryKey' | 'queryFn'
  > = {}
) => {
  const queryClient = useQueryClient();
  return useMutation(
    TRANSFER_ACCOUNT_MUTATION_KEY,
    (email?: string) =>
      axiosGrowthDay
        .post<UserMetadata>(
          email ? '/users/transferEnterpriseAccount' : '/users/metadata/transferAccountTerms',
          email ? {email} : {}
        )
        .then((res) => res.data),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(GetUserMetaDataKey());
      },
      ...options,
    }
  );
};
export default useTransferAccountMutation;
