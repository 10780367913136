import message from 'antd/es/message';

const displayApiError = (err: any, defaultMsg = '') => {
  const msg = typeof err?.message === 'string' ? err.message : defaultMsg;
  if (msg) {
    return message.error(msg);
  }
};

export default displayApiError;
