import {LoadingOutlined} from '@ant-design/icons';
import React, {CSSProperties} from 'react';
import classes from './Loading.module.less';

interface Props {
  style?: CSSProperties;
  className?: string;
  id?: string;
}

export default function Loading(props: Props) {
  return (
    <div
      data-testid={'loader'}
      id={props.id ?? 'loading'}
      className={props.className}
      style={{marginTop: '60px', display: 'flex', alignItems: 'center', flexDirection: 'column'}}
    >
      <LoadingOutlined
        className={classes.loading}
        style={{
          ...props.style,
        }}
        spin
      />
    </div>
  );
}
