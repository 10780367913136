import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {GrowthGroupLayout, GrowthGroupState, ModalPosition} from '../../../types/growth-groups';
import dayjs from 'dayjs';

const initialState: GrowthGroupState = {
  token: '',
  loading: false,
  loadingInstanceId: null,
  layout: GrowthGroupLayout.GALLERY,
  joinedTime: dayjs().toISOString(),
  slug: '',
  showAdmissionModal: true,
  backstageBannerActive: false,
  showManageAttendeesModal: false,
  manageAttendeesModalPosition: {x: 0, y: 0} as ModalPosition,
  groupId: '',
  sessionMetadataInitialized: false,
  paymentSidebarOpen: false,
};

export const growthGroupSlice = createSlice({
  name: 'growthGroups',
  initialState,
  reducers: {
    updateGrowthGroupState: (state, action: PayloadAction<Partial<GrowthGroupState>>) => {
      return {...state, ...action.payload};
    },
    resetGrowthGroupState: () => initialState,
    reJoinGrowthGroupState: (state, action: PayloadAction<Partial<GrowthGroupState>>) => {
      return {...initialState, ...action.payload};
    },
  },
});

export const {updateGrowthGroupState, resetGrowthGroupState, reJoinGrowthGroupState} = growthGroupSlice.actions;

export const growthGroupReducer = {
  [growthGroupSlice.name]: growthGroupSlice.reducer,
};
