import {Action, AnyAction} from 'redux';
import {CaseReducer, Draft} from '@reduxjs/toolkit';
import coerceArray from '../../util/coerceArray';

type ApiActionCallbacks<S = any, A extends Action = AnyAction> = {
  pending?: CaseReducer<Draft<S>, A>;
  fulfilled?: CaseReducer<Draft<S>, A>;
  rejected?: CaseReducer<Draft<S>, A>;
};

export type ApiActionBuilder<S = any> = <A extends Action = AnyAction>(
  actionName: string | string[],
  callbacks?: ApiActionCallbacks<S, A>
) => void;

export const getApiActionBuilder = <S = any>(builder: any, actions: Record<string, any>): ApiActionBuilder<S> => {
  return (actionName, callbacks = {}) => {
    const actionArgs = coerceArray(actionName);

    actionArgs.forEach((actionName: string) => {
      const action = actions[actionName];
      if (action !== undefined && action.pending !== undefined) {
        builder.addCase(action.pending, (state: any, action: any) => {
          state.loading[actionName] = true;

          if (callbacks.pending) {
            callbacks.pending(state, action);
          }
        });
        builder.addCase(action.rejected, (state: any, action: any) => {
          if (callbacks.rejected) {
            callbacks.rejected(state, action);
          }

          state.loading[actionName] = false;
        });
        builder.addCase(action.fulfilled, (state: any, action: any) => {
          if (callbacks.fulfilled) {
            callbacks.fulfilled(state, action);
          }

          state.loading[actionName] = false;
        });
      }
    });
  };
};
