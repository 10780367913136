import {DefaultMessagingSession} from 'amazon-chime-sdk-js';
import {Channel, ChimeSDKMessagingClient} from '@aws-sdk/client-chime-sdk-messaging';
import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';
import {AppInstanceUserSummary} from '@aws-sdk/client-chime-sdk-identity';
import {computed} from 'zustand-computed';
import {GrowthGroupInstance} from '../../../types/api';

/**
 * An enumeration representing different features where chat can be used
 * They are maintained separately
 */
export enum ChatFeaturesEnum {
  GROWTH_GROUPS = 'GrowthGroups',
}

/**
 * Interface defining the values stored in the chat store.
 */
export type ChatStoreValues = {
  // Channel object
  channel: {
    [K in ChatFeaturesEnum]?: Channel | null;
  };
  // Chime Session
  session: DefaultMessagingSession | null;
  // Is channel membership initialised
  membershipInitialised: {
    [K in ChatFeaturesEnum]?: boolean | null;
  };
  // For pagination while loading older messages
  nextToken: {
    [K in ChatFeaturesEnum]?: string | null;
  };
  // If the user gets banned
  isBanned: {
    [K in ChatFeaturesEnum]?: boolean | null;
  };
  // Chime SDK client
  client: ChimeSDKMessagingClient | null;
  // User ARN for Chime
  userArn: AppInstanceUserSummary['AppInstanceUserArn'] | null;
  // Other feature specific data
  metadata: {
    [ChatFeaturesEnum.GROWTH_GROUPS]: {
      instanceId: GrowthGroupInstance['id'] | null;
    };
  };
};

/**
 * Interface defining the actions for updating the chat store.
 */
export type ChatStoreActions = {
  // Set the channel for a given key
  setChannel(key: ChatFeaturesEnum, channel: Channel | null): void;
  // Set metadata for a given key
  setMetadata<T extends ChatFeaturesEnum>(key: T, metadata: ChatStoreValues['metadata'][T]): void;
  // Set the membership initialization status for a given key
  setMembershipInitialized(
    key: ChatFeaturesEnum,
    value: ChatStoreValues['membershipInitialised'][ChatFeaturesEnum]
  ): void;
  // Set the banned status for a given key
  setIsBanned(key: ChatFeaturesEnum, value: ChatStoreValues['isBanned'][ChatFeaturesEnum]): void;
  // Set the chat session - where events are received
  setSession(session: DefaultMessagingSession | null): void;
  // Set the chat client - where commands are sent
  setClient(session: ChimeSDKMessagingClient | null): void;
  // Set the Chime user ARN
  setUserArn(userArn: AppInstanceUserSummary['AppInstanceUserArn'] | null): void;
  // Clear the chat store
  resetStore(key?: string): void;
  // Store the next pagination token
  setNextToken(key: ChatFeaturesEnum, token: ChatStoreValues['nextToken'][ChatFeaturesEnum]): void;
};

/**
 * Interface defining the computed values in the chat store.
 */
export type ComputedChatStore = {
  // If Chime connection is initialised
  // Different from membership initialisation - that is handled on BE
  // Client connects to Chime and sets this flag
  initialized: {
    [K in ChatFeaturesEnum]: boolean;
  };
};

export type ChatStore = ChatStoreValues & ChatStoreActions & ComputedChatStore;

// Initial metadata state for growth groups
const initialMetadata: ChatStoreValues['metadata'] = {
  [ChatFeaturesEnum.GROWTH_GROUPS]: {instanceId: null},
};

/**
 * Type representing the entire chat store combining values, actions, and computed values.
 */
const initialState: ChatStoreValues = {
  session: null,
  client: null,
  userArn: null,
  membershipInitialised: {
    [ChatFeaturesEnum.GROWTH_GROUPS]: false,
  },
  nextToken: {
    [ChatFeaturesEnum.GROWTH_GROUPS]: null,
  },
  channel: {
    [ChatFeaturesEnum.GROWTH_GROUPS]: null,
  },
  isBanned: {
    [ChatFeaturesEnum.GROWTH_GROUPS]: false,
  },
  metadata: initialMetadata,
};

// Store to manage different chat functionality related data
export const useChatStore = create(
  computed(
    immer<ChatStoreValues & ChatStoreActions>((set) => ({
      ...initialState,
      setChannel(key, channel) {
        return set((state) => {
          state.channel[key] = channel;
        });
      },
      setMetadata(key, metadata) {
        return set((state) => {
          state.metadata[key] = metadata;
        });
      },
      setSession(session) {
        return set((state) => {
          state.session = session;
        });
      },
      setClient(session) {
        return set((state) => {
          state.client = session;
        });
      },
      setUserArn(userArn) {
        return set((state) => {
          state.userArn = userArn;
        });
      },
      setNextToken(key, token) {
        return set((state) => {
          state.nextToken[key] = token;
        });
      },
      setMembershipInitialized(key, value) {
        return set((state) => {
          state.membershipInitialised[key] = value;
        });
      },
      setIsBanned(key, value) {
        return set((state) => {
          state.isBanned[key] = value;
        });
      },
      resetStore(key?: ChatFeaturesEnum) {
        if (!key) {
          // Reset store completely, including client and the session
          return set(initialState);
        } else {
          // Reset specific slice of the store
          return set((state) => {
            state.channel[key] = null;
            state.nextToken[key] = null;
            state.session = null;
            state.metadata[key] = initialMetadata[key];
            state.membershipInitialised[key] = false;
            state.isBanned[key] = false;
          });
        }
      },
    })),
    function compute(state): ComputedChatStore {
      // Check if chat is completely initialised - has session, userARN and channel set up
      const initMap = {} as Record<ChatFeaturesEnum, boolean>;
      const hasSessionAndUser = !!state.session && !!state.userArn;
      for (const key of Object.values(ChatFeaturesEnum)) {
        const feature = key as ChatFeaturesEnum;
        initMap[feature] = hasSessionAndUser && !!state.channel[feature];
      }

      return {initialized: initMap};
    }
  )
);
