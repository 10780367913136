/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface UserStripeConnectSetting
 */
export interface UserStripeConnectSetting {
  /**
   *
   * @type {string}
   * @memberof UserStripeConnectSetting
   */
  accountId?: string;
  /**
   *
   * @type {string}
   * @memberof UserStripeConnectSetting
   */
  accountType?: UserStripeConnectSettingAccountTypeEnum;
  /**
   *
   * @type {string}
   * @memberof UserStripeConnectSetting
   */
  accountCurrency?: string;
  /**
   *
   * @type {string}
   * @memberof UserStripeConnectSetting
   */
  country?: string;
  /**
   *
   * @type {string}
   * @memberof UserStripeConnectSetting
   */
  payoutDate?: string;
  /**
   *
   * @type {string}
   * @memberof UserStripeConnectSetting
   */
  connectStatus?: UserStripeConnectSettingConnectStatusEnum;
}

export const UserStripeConnectSettingAccountTypeEnum = {
  Standard: 'STANDARD',
  Express: 'EXPRESS',
  Custom: 'CUSTOM',
} as const;

export type UserStripeConnectSettingAccountTypeEnum =
  (typeof UserStripeConnectSettingAccountTypeEnum)[keyof typeof UserStripeConnectSettingAccountTypeEnum];
export const UserStripeConnectSettingConnectStatusEnum = {
  Connected: 'CONNECTED',
  NotConnected: 'NOT_CONNECTED',
  NotCompleted: 'NOT_COMPLETED',
} as const;

export type UserStripeConnectSettingConnectStatusEnum =
  (typeof UserStripeConnectSettingConnectStatusEnum)[keyof typeof UserStripeConnectSettingConnectStatusEnum];
