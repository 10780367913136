import {IMarketingLinkedOffers} from './marketing-linked-offers';
import {IMarketingPlanTrial} from './marketing-plan-trial';
import {IOffers} from './offers';
import {ISubscriptionPlans} from './subscription-plans';

/**
 * Model definition for marketing-plan
 */
export interface IMarketingPlan {
  id: number;
  trial?: boolean;
  plan?: ISubscriptionPlans;
  orderBump?: IOffers;
  onboardingUpsells: IMarketingLinkedOffers[];
  frequency?: EnumMarketingPlanFrequency;
  planTrial?: IMarketingPlanTrial;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumMarketingPlanFrequency {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
}
