/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface SavedContent
 */
export interface SavedContent {
  /**
   *
   * @type {string}
   * @memberof SavedContent
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof SavedContent
   */
  contentType: SavedContentContentTypeEnum;
  /**
   *
   * @type {number}
   * @memberof SavedContent
   */
  recommendationId?: number;
  /**
   *
   * @type {string}
   * @memberof SavedContent
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof SavedContent
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof SavedContent
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof SavedContent
   */
  assessment?: string;
  /**
   *
   * @type {number}
   * @memberof SavedContent
   */
  assessmentScore?: number;
  /**
   *
   * @type {string}
   * @memberof SavedContent
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof SavedContent
   */
  wistiaUrl?: string;
  /**
   *
   * @type {number}
   * @memberof SavedContent
   */
  lessonId?: number;
  /**
   *
   * @type {number}
   * @memberof SavedContent
   */
  courseId?: number;
  /**
   *
   * @type {string}
   * @memberof SavedContent
   */
  titleUid?: string;
  /**
   *
   * @type {string}
   * @memberof SavedContent
   */
  lessonThumbnail?: string;
  /**
   *
   * @type {string}
   * @memberof SavedContent
   */
  wistiaThumbnail?: string;
  /**
   *
   * @type {string}
   * @memberof SavedContent
   */
  assessmentCategoryType?: SavedContentAssessmentCategoryTypeEnum;
}

export const SavedContentContentTypeEnum = {
  LifeScore: 'LIFE_SCORE',
} as const;

export type SavedContentContentTypeEnum =
  (typeof SavedContentContentTypeEnum)[keyof typeof SavedContentContentTypeEnum];
export const SavedContentAssessmentCategoryTypeEnum = {
  Daily: 'DAILY',
  Weekly: 'WEEKLY',
  Monthly: 'MONTHLY',
  TeamAssessment: 'TEAM_ASSESSMENT',
} as const;

export type SavedContentAssessmentCategoryTypeEnum =
  (typeof SavedContentAssessmentCategoryTypeEnum)[keyof typeof SavedContentAssessmentCategoryTypeEnum];
