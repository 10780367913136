/**
 * Model definition for Cancel-Cta
 */
export interface ICancelCta {
  id: number;
  label: string;
  onClick?: EnumCancelCtaOnClick;
  createdAt?: string | null;
  updatedAt?: string | null;
  publishedAt?: string | null;
}

export enum EnumCancelCtaOnClick {
  ok = 'ok',
  cancel = 'cancel',
  closeModal = 'closeModal',
  nextModal = 'nextModal',
}
