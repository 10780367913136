import axios from 'axios';
import config from '../../config';
import urlJoin from 'proper-url-join';
export * from './dashboard/dashboard.api';
export * from './gift-subscriptions/gift-subscriptions.api';
export * from './growthDayCoin/growthDayCoin.api';
export * from './login/login.api';
export * from './logout/logout.api';
export * from './me/me.api';
export * from './register/register.api';
export * from './strapi/strapi.api';
export * from './tour/tour.api';
export * from './stripe/stripe.api';
export * from './offers/offers.api';
export * from './notifications/notifications.api';
export * from './user/user.api';
export * from './userChallenges/userChallenges.api';
export * from './userCourse/userCourse.api';

export const axiosGrowthDay = axios.create({
  withCredentials: true,
  baseURL: config.env.apiUrl,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

export const axiosStrapi = axios.create({
  withCredentials: true,
  baseURL: urlJoin(config.env.apiUrl, 'strapi'),
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

export const axiosPublicStrapi = axios.create({
  baseURL: urlJoin(config.env.apiUrl, 'strapi', 'public'),
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

export const axiosSearch = axios.create({
  withCredentials: true,
  baseURL: config.env.searchApiUrl,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

export const axiosRenewTokenClient = axios.create({
  baseURL: config.env.apiUrl,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});
