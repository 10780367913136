import {useEffect} from 'react';
import useScript from 'react-script-hook';
import config from '../config';
import {useRebrandText} from '@/features/feature-disable/hooks/useRebrandText';

export interface FirstPromoterData {
  cid: string;
  domain: string;
  ref_id: string | null;
  referrer: string | null;
  signed_up: string | null;
  subscribed: string | null;
  tid: string;
  url: string;
  url_ref_id: string | null;
}

export interface FirstPromoterSignupParam {
  email: string;
}

declare global {
  interface Window {
    $FPROM: {
      init: (key: string, domain: string) => void;
      data: FirstPromoterData;
      trackSignup: (param: FirstPromoterSignupParam, callback?: () => void) => void;
    };
  }
}

const useFirstPromoter = () => {
  const [loading, error] = useScript({
    src: config.env.firstPromoterId ? 'https://cdn.firstpromoter.com/fprom.js' : null,
    checkForExisting: true,
  });
  const {rebrandTextFromEnv} = useRebrandText();
  const productWebsite = rebrandTextFromEnv('productWebsite');

  useEffect(() => {
    if (!loading && !error && config.env.firstPromoterId) {
      try {
        const hostname = new URL(productWebsite).hostname;
        const domain = hostname.split('.').slice(-2).join('.');
        setTimeout(() => {
          window.$FPROM.init(config.env.firstPromoterId!, `.${domain}`);
        });
      } catch (e: any) {}
    }
  }, [loading, error, productWebsite]);
};

export default useFirstPromoter;
