import React, {FC} from 'react';
import {RouteProps} from 'react-router';
import {Route} from 'react-router-dom';
import RedirectWithParams from '../../../../../../shared/components/RedirectWithParams';
import useAuthState from '../../../../hooks/useAuthState';
import useUserState from '../../../../hooks/useUserState';
import isUnauthenticatedState from '../../../../utils/inUnauthenticatedState';
import useUser from '@growthday/ui-core/src/features/user/hooks/useUser';

type UnAuthRouteProps = {
  redirectTo?: string;
  search?: string;
} & RouteProps;

const UnAuthRoute: FC<UnAuthRouteProps> = ({children, redirectTo = '/', search, ...rest}) => {
  const {authRedirectUrl} = useAuthState();
  const {user} = useUser();
  const userState = useUserState();
  const unauthenticated = isUnauthenticatedState(userState);

  if (user && !unauthenticated) {
    return <RedirectWithParams {...rest} to={authRedirectUrl || redirectTo} search={search} />;
  }

  return <Route {...rest}>{children}</Route>;
};

export default UnAuthRoute;
