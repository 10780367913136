/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import {AssessmentPercentage} from './assessment-percentage';

/**
 *
 * @export
 * @interface TeamAssessment
 */
export interface TeamAssessment {
  /**
   *
   * @type {string}
   * @memberof TeamAssessment
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof TeamAssessment
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof TeamAssessment
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof TeamAssessment
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof TeamAssessment
   */
  type: TeamAssessmentTypeEnum;
  /**
   *
   * @type {{ [key: string]: { [key: string]: number; }; }}
   * @memberof TeamAssessment
   */
  scoreCard?: {[key: string]: {[key: string]: number}};
  /**
   *
   * @type {string}
   * @memberof TeamAssessment
   */
  uuid: string;
  /**
   *
   * @type {Array<AssessmentPercentage>}
   * @memberof TeamAssessment
   */
  assessmentPercentage?: Array<AssessmentPercentage>;
  /**
   *
   * @type {number}
   * @memberof TeamAssessment
   */
  overallPercentage?: number;
  /**
   *
   * @type {string}
   * @memberof TeamAssessment
   */
  organizationId?: string;
  /**
   *
   * @type {string}
   * @memberof TeamAssessment
   */
  departmentId?: string;
}

export const TeamAssessmentTypeEnum = {
  Daily: 'DAILY',
  Weekly: 'WEEKLY',
  Monthly: 'MONTHLY',
  TeamAssessment: 'TEAM_ASSESSMENT',
} as const;

export type TeamAssessmentTypeEnum = (typeof TeamAssessmentTypeEnum)[keyof typeof TeamAssessmentTypeEnum];
