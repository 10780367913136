import {default as classNamesFn} from 'classnames';

export type PrefixedClsFn = (...names: (string | Record<string, boolean>)[]) => string;

const prefixedCls = (prefix: string): PrefixedClsFn => {
  return (...classNames: (string | Record<string, boolean>)[]) => {
    if (typeof classNames[0] !== 'string') {
      const classNamesObj = classNames[0];
      const prefixedClassNamesObj = Object.fromEntries(
        Object.entries(classNamesObj).map(([className, active]) => {
          return [`${prefix}-${className}`, active];
        })
      );
      return classNamesFn(prefixedClassNamesObj);
    }

    return classNamesFn(...classNames.map((className) => `${prefix}-${className}`));
  };
};

export default prefixedCls;
