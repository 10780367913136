import plansApi from '../plans.api';
import {FlattenedPlans, MutationEndpointOptions} from '../../../types/plans';
import {endpoints} from '../endpoints';
import {FetchBaseQueryError} from '@reduxjs/toolkit/query';

const getUpdateURL = (parentID: string, records: FlattenedPlans['records']) =>
  parentID
    ? records[parentID].parentID
      ? `${endpoints.updateSubTaskOrder}/${records[parentID].parentID}/${parentID}`
      : `${endpoints.updateTaskOrder}/${parentID}`
    : endpoints.updatePlanOrder;

export const reorderOptions: MutationEndpointOptions<Record<string, number>, unknown> = {
  async queryFn(arg, api, extra, fetchWithBaseQuery) {
    const plansData = plansApi.endpoints.getPlans.select()(api.getState() as any)!;
    if (plansData.error) return {error: plansData.error as FetchBaseQueryError};
    const plansMap: FlattenedPlans = plansData.data!;
    const keys = Object.keys(arg);
    if (!keys.length)
      return {
        error: {
          status: 'CUSTOM_ERROR',
          error: 'Cannot update order for empty map',
          data: null,
        },
      };
    return fetchWithBaseQuery({
      url: getUpdateURL(plansMap.records[keys[0]].parentID, plansMap.records),
      body: arg,
      method: 'POST',
    });
  },

  async onQueryStarted(arg, api) {
    const keys = Object.keys(arg);
    if (!keys.length) return;
    const patch = api.dispatch(
      plansApi.util.updateQueryData('getPlans', undefined, (draft) => {
        const {records} = draft;
        const parentTaskArr = records[keys[0]].parentID
          ? records[records[keys[0]].parentID].tasks
          : draft.plansArr.tasks;

        for (const id of parentTaskArr) records[id].order = arg[id]?.toString() ?? null;
      })
    );

    api.queryFulfilled.catch(patch.undo);
  },
};
