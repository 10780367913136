/* tslint:disable */
/* eslint-disable */
/**
 * Api Service
 * Growthday API service.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface GrowthGroupSummary
 */
export interface GrowthGroupSummary {
  /**
   *
   * @type {string}
   * @memberof GrowthGroupSummary
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupSummary
   */
  createTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupSummary
   */
  updateTimestamp?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupSummary
   */
  deleteTimestamp?: number;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupSummary
   */
  growthGroupId?: string;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupSummary
   */
  growthGroupInstanceId?: string;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupSummary
   */
  totalLiveAttendees?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupSummary
   */
  totalRSVPAttendees?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupSummary
   */
  leads?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupSummary
   */
  newAttendees?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupSummary
   */
  earningInCents?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupSummary
   */
  avgWatchTime?: number;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupSummary
   */
  hostUuid?: string;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupSummary
   */
  ggActualStartTime?: number;
  /**
   *
   * @type {number}
   * @memberof GrowthGroupSummary
   */
  ggActualEndTime?: number;
  /**
   *
   * @type {string}
   * @memberof GrowthGroupSummary
   */
  type?: GrowthGroupSummaryTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof GrowthGroupSummary
   */
  hmsSessionIds?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof GrowthGroupSummary
   */
  premium?: boolean;
}

export const GrowthGroupSummaryTypeEnum = {
  Private: 'PRIVATE',
  Public: 'PUBLIC',
} as const;

export type GrowthGroupSummaryTypeEnum = (typeof GrowthGroupSummaryTypeEnum)[keyof typeof GrowthGroupSummaryTypeEnum];
