/* eslint-disable react/no-unstable-nested-components */
import {Tooltip} from '@/components/tooltip';
import {imageUrlFromMedia} from '@growthday/ui-core/src/features/media-player/utils/image-url-from-media';
import {decomposeUrl} from '@/utils/decompose-url';
import {CSSProperties, FC, lazy, PropsWithChildren, ReactElement, Suspense, useMemo} from 'react';
import {Options as ReactMarkdownOptions} from 'react-markdown';
import {Link} from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import styles from './MarkdownHtml.module.less';
import {matchPath} from 'react-router';

export type CustomLocalLinkProps = PropsWithChildren<{
  path: string;
  href: string;
}>;

// Add support for custom component for certain local links
export type CustomLocalLink = {
  checkPath: (path: CustomLocalLinkProps['path']) => boolean;
  renderComponent: (props: CustomLocalLinkProps) => ReactElement;
};

export type MarkdownHtmlProps = Partial<ReactMarkdownOptions> & {
  hideImages?: string[];
  style?: CSSProperties;
  ignoreBlankTargetForLocalLink?: boolean;
  // Do not render react-router links
  ignoreLocalLink?: boolean;
  customLocalLink?: CustomLocalLink;
  // Ignore local link if it is for signup
  // Since sign up is under same domain but different app
  ignoreSignupLocalLink?: boolean;
};

const ReactMarkdown = lazy(() => import('react-markdown'));
const rehypePlugins: ReactMarkdownOptions['rehypePlugins'] = [rehypeRaw];

const MarkdownHtml: FC<MarkdownHtmlProps> = ({
  hideImages,
  ignoreBlankTargetForLocalLink,
  ignoreLocalLink,
  style,
  className,
  customLocalLink,
  ignoreSignupLocalLink = true,
  ...props
}) => {
  const components: ReactMarkdownOptions['components'] = useMemo(
    () => ({
      a: (element) => {
        const {path, target: _target, href, isLocal: _isLocal} = decomposeUrl(element.href, element.target);
        // In app link
        // Is the link a signup link that should not be treated as local/in-app
        const isSignupLink = ignoreSignupLocalLink
          ? _isLocal && !!matchPath(path, {path: '/signup', exact: false})
          : true;
        // For signup links, target should be blank. Since on parsing markdown
        // the target is self generated so the link open in same tab
        const target = isSignupLink ? '_blank' : _target;
        // A local/in-app link is local non signup link
        const isLocal = _isLocal && !isSignupLink;
        if (!ignoreLocalLink && isLocal && (ignoreBlankTargetForLocalLink || target !== '_blank')) {
          // Render a React Router link
          // Or a custom link
          if (customLocalLink?.checkPath(path)) {
            return customLocalLink?.renderComponent({path, href, children: element.children});
          } else {
            return (
              <Tooltip placement="top" title={href}>
                <Link to={path} className={styles.anchorLinks}>
                  {element.children}
                </Link>
              </Tooltip>
            );
          }
        }
        // Anchor tag
        return (
          <Tooltip placement="top" title={href}>
            <a href={href} target={target} className={styles.anchorLinks}>
              {element.children}
            </a>
          </Tooltip>
        );
      },
      img: ({src, ...props}) => {
        if (src && hideImages?.includes(src)) {
          return null;
        }
        return <img alt="" {...props} src={imageUrlFromMedia(src)} />;
      },
    }),
    [customLocalLink, ignoreSignupLocalLink, hideImages, ignoreBlankTargetForLocalLink, ignoreLocalLink]
  );

  return (
    <Suspense fallback={null}>
      <div {...{style, className}}>
        <ReactMarkdown rehypePlugins={rehypePlugins} components={components} {...props}>
          {props.children ?? ''}
        </ReactMarkdown>
      </div>
    </Suspense>
  );
};

export default MarkdownHtml;
