import dayjs from 'dayjs';
import {EnumOffersType} from '@growthday/ui-core/src/types/strapi';
import coerceArray from '../../../shared/util/coerceArray';
import {IPurchasedOffer} from '../interfaces';
import isOfferSubscriptionType from './isOfferSubscriptionType';
import {IOffersNonNested} from '@growthday/ui-core/src/types/offers';

export enum EnumOffersExpiry {
  ALL = 'ALL',
  EXPIRED = 'EXPIRED',
  NOT_EXPIRED = 'NOT_EXPIRED',
}

export const isOfferNotExpired = (purchasedOffer: IPurchasedOffer) =>
  purchasedOffer.expiry ? purchasedOffer.expiry > dayjs().valueOf() : true;

export const filterOffersByType = (
  offers: IOffersNonNested[] = [],
  type?: EnumOffersType | EnumOffersType[] | null
) => {
  if (!type) {
    return offers.filter((offer) => !isOfferSubscriptionType(offer));
  }
  const types = coerceArray(type || []);
  if (types.length) {
    return offers.filter((offer) => offer.type && types.includes(offer.type));
  }
  return offers;
};

export const filterOffersByIds = (offers: IOffersNonNested[] = [], id?: number | number[] | null) => {
  const ids = coerceArray(id || []);
  if (ids.length) {
    return offers.filter((offer) => ids.includes(offer.id)).sort((a, b) => ids.indexOf(a.id) - ids.indexOf(b.id));
  }
  return offers;
};
