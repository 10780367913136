import {IUserCourseNote, IUserCourseProgress} from '../../services_deprecated/model/data-collection';
import {IUserCourse} from '../../services_deprecated/model/user-course';
import {axiosGrowthDay} from '../index';
import {AchievementInfo} from '@growthday/ui-core/src/types/achievements';

export const userCourseApi = {
  userCourseProgress(courseId: number, lessonId: number, progress: number) {
    return axiosGrowthDay.post(`/userCourse/progress/${courseId}/${lessonId}/${progress}`).then((res) => res.data);
  },
  userCourseLessonComments(courseId: number, lessonId: number) {
    return axiosGrowthDay
      .get(`/userCourse/lesson/comments/${courseId}/${lessonId}`)
      .then((res) => res.data?.list ?? []);
  },
  userCourseLessonMarkAsCompleted(courseId: number, lessonId: number) {
    return axiosGrowthDay
      .put<IUserCourseProgress>(`/userCourse/complete/${courseId}/lessons/${lessonId}`)
      .then((res) => res.data);
  },
  userCourseMarkAsCompleted(courseId: number) {
    return axiosGrowthDay
      .put<{userCourse: IUserCourse; achievement?: (AchievementInfo & {coinsEarned?: number}) | null}>(
        `/userCourse/complete/${courseId}`
      )
      .then((res) => res.data);
  },
  userCourseGet(courseId: number) {
    return axiosGrowthDay.get<IUserCourse>(`/userCourse/${courseId}`).then((res) => res.data);
  },
  userCoursesByIdPost(idCourses: number[]): Promise<IUserCourse[]> {
    return axiosGrowthDay
      .post(`/userCourse/by-id-list?page=1&size=${idCourses.length}`, {
        idList: idCourses,
      })
      .then((res) => res.data?.list ?? []);
  },
  userCourseGetRecommendations: (): Promise<IUserCourse[]> => {
    return axiosGrowthDay.get<IUserCourse[]>('/userCourse?page=1&size=10').then((res) => res.data as IUserCourse[]);
  },

  addNoteForLesson: (
    request: IUserCourseNote & {
      courseId: string | number;
      lessonId: string | number;
    }
  ) => {
    return axiosGrowthDay.post<IUserCourse>(`/userCourse/notes`, request).then((res) => res.data);
  },

  deleteNoteForLesson: (request: {courseId: string | number; lessonId: string | number; noteId: string}) => {
    return axiosGrowthDay
      .delete<void>(`/userCourse/notes/${request.courseId}/${request.lessonId}/${request.noteId}`)
      .then((res) => res.data);
  },

  registerOnCourse: (courseId: number) => {
    return axiosGrowthDay.post<boolean>(`/userCourse/register/${courseId}`).then((res) => res.data);
  },
};
