import {FC, useEffect, useRef} from 'react';
import {ErrorBoundary as ErrBoundary, FallbackProps} from 'react-error-boundary';
import useUserState, {UserStateEnum} from '../../../auth/hooks/useUserState';
import ErrorPage from '../ErrorPage/ErrorPage';
import GDTypography from '../../../../shared/components/ui/Typography/Typography';
import classes from '../RouteNotFound/RouteNotFound.module.less';
import {toggleFeedback} from '../../../shell/shell.slice';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import useShellState from '../../../shell/hooks/useShellState';
import classNames from 'classnames';

const FallbackRender = ({resetErrorBoundary}: FallbackProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {feedbackVisible} = useShellState();
  const didMountedRef = useRef(false);
  const userState = useUserState();

  useEffect(() => {
    if (!feedbackVisible && didMountedRef.current) {
      resetErrorBoundary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackVisible]);

  useEffect(() => {
    didMountedRef.current = true;
    return () => {
      didMountedRef.current = false;
    };
  }, []);

  return (
    <ErrorPage errorCode={500}>
      <GDTypography type="w800" className={classNames(classes.title, 'error-page-crash-title')}>
        This was unexpected
      </GDTypography>
      <GDTypography type="w300" className={classNames(classes.subTitle, 'error-page-crash-subtitle')}>
        Let's get you back on the right path
      </GDTypography>
      <div className={classes.links}>
        {userState === UserStateEnum.AUTHENTICATED && (
          <div
            className={classNames(classes.homeLink, 'error-page-crash-link')}
            onClick={() => dispatch(toggleFeedback(true))}
          >
            <GDTypography type="w100" subType="s">
              Share what happened
            </GDTypography>
          </div>
        )}
        <div
          className={classNames(classes.homeLink, 'error-page-crash-link')}
          onClick={() => {
            history.push('/');
            resetErrorBoundary();
          }}
        >
          <GDTypography type="w100" subType="s">
            Continue
          </GDTypography>
        </div>
      </div>
    </ErrorPage>
  );
};

const ErrorBoundary: FC = ({children}) => {
  return <ErrBoundary FallbackComponent={FallbackRender}>{children}</ErrBoundary>;
};

export default ErrorBoundary;
