import {Layout as AntLayout} from 'antd';
import classNames from 'classnames';
import React, {FC, useState} from 'react';
import useSider from '../../../features/shell/hooks/useSider';
import useGDBreakpoint from '../../../shared/hooks/useBreakpoint';
import styles from '../../../features/shell/Shell.module.less';
import EmptyLayoutMenu from './EmptyLayoutMenu';

const {Sider: AntSider} = AntLayout;

const EmptyLayoutSider: FC = () => {
  const [collapsed, setCollapsed] = useState(true);
  const gdBreakpoint = useGDBreakpoint();
  const {siderWidth, wrapTitle, expandedMenu} = useSider({
    collapsed,
    setCollapsed,
  });

  return (
    <AntSider
      trigger={null}
      collapsed={gdBreakpoint.md ? false : collapsed}
      breakpoint="md"
      collapsedWidth={0}
      onCollapse={setCollapsed}
      width={siderWidth}
      className={classNames(styles.sider, {
        [styles.disabled]: true,
      })}
    >
      <EmptyLayoutMenu setCollapsed={setCollapsed} expanded={expandedMenu} wrapTitle={wrapTitle} />
    </AntSider>
  );
};

export default EmptyLayoutSider;
