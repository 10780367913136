import {remindersApi} from './reminders.api';

export const {
  useAddReminderMutation,
  useDeleteReminderMutation,
  useUpdateReminderMutation,
  useGetReminderQuery,
  useGetRemindersQuery,
  useUpdateActivationMutation,
} = remindersApi;
